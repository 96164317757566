import React from "react";
// Project imports
import {WEBPAGES} from "../../routes/webpages";
import {Webpage} from "../webpage/Webpage";

export const Partners: React.FC = () => {
    return <Webpage webpageId={WEBPAGES.names.partners} footer={false} />;
};

export default Partners;
