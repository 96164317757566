import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {useTranslation} from "react-i18next";
import {Box, Checkbox, FormControlLabel} from "@material-ui/core";

interface ConfirmationDialogBaseProps {
    title: string;
    text: string;
    open: boolean;
    id: string;

    checkbox?: string;
}

interface ConfirmationDialogProps extends ConfirmationDialogBaseProps {
    onAgree: () => void;
    onDisagree: () => void;
}

interface CustomizableConfirmationDialogProps extends ConfirmationDialogProps {
    agree: string;
    disagree: string;
    children?: React.ReactNode;
}

export const CustomizableConfirmationDialog: React.FC<CustomizableConfirmationDialogProps> = (
    props: CustomizableConfirmationDialogProps
) => {
    const requiresCheckbox = !!props.checkbox;
    const [checkboxChecked, setCheckboxChecked] = React.useState(!requiresCheckbox);
    const handleAgree = (): void => {
        if (requiresCheckbox && !checkboxChecked) return;
        props.onAgree();
    };
    return (
        <Dialog
            open={props.open}
            onClose={props.onDisagree}
            aria-labelledby={`${props.id}-title`}
            aria-describedby={`${props.id}-text`}
            id={`${props.id}`}
        >
            <DialogTitle id={`${props.id}-title`}>{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id={`${props.id}-text`}>{props.text}</DialogContentText>
                {props.children}
                {requiresCheckbox ? (
                    <Box>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={checkboxChecked}
                                    onChange={(e): void => setCheckboxChecked(e.target.checked)}
                                    name="confirmed"
                                />
                            }
                            label={props.checkbox}
                        />
                    </Box>
                ) : null}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onDisagree} color="primary">
                    {props.disagree}
                </Button>
                <Button
                    onClick={handleAgree}
                    disabled={requiresCheckbox && !checkboxChecked}
                    color="primary"
                    variant="contained"
                    autoFocus
                >
                    {props.agree}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = (props: ConfirmationDialogProps) => {
    //== Init =================================================================
    const [t] = useTranslation("common");
    //== Render ===============================================================
    return (
        <CustomizableConfirmationDialog
            {...props}
            agree={t("dialogs.confirm.agree")}
            disagree={t("dialogs.confirm.disagree")}
        />
    );
};

interface DeleteConfirmationDialogProps extends ConfirmationDialogBaseProps {
    children?: React.ReactNode;

    onDelete: () => void;
    onCancel: () => void;
}

export const DeleteConfirmationDialog: React.FC<DeleteConfirmationDialogProps> = (
    props: DeleteConfirmationDialogProps
) => {
    //== Init =================================================================
    const [t] = useTranslation("common");
    //== Render ===============================================================
    return (
        <CustomizableConfirmationDialog
            {...props}
            onAgree={props.onDelete}
            onDisagree={props.onCancel}
            agree={t("dialogs.confirm.delete")}
            disagree={t("dialogs.confirm.cancel")}
        />
    );
};

interface InformationDialogBaseProps {
    title: string;
    text: string;
    open: boolean;
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

export const InformationDialog: React.FC<InformationDialogBaseProps> = (props: InformationDialogBaseProps) => {
    //== Init =================================================================
    const [t] = useTranslation("common");
    //== Render ===============================================================
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby={`${props.id}-title`}
            aria-describedby={`${props.id}-text`}
            id={`${props.id}`}
            maxWidth="sm"
        >
            <DialogTitle id={`${props.id}-title`}>{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id={`${props.id}-text`}>{props.text}</DialogContentText>
                {props.children}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="primary" variant="contained" autoFocus>
                    {t("dialogs.close")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
