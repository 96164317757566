import React, {useState} from "react";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
// Material UI imports
import {Box, Link, Table, TableBody, TableCell, TableFooter, TableHead, TableRow} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
// Project imports
import {
    AcceptActionButton,
    DeleteActionButton,
    EditActionButton,
    RefreshActionButton,
    SaveActionButton,
    SendActionButton,
} from "../../../common/buttons";
import {ErrorNotification, InfoNotification, SuccessNotification} from "../../../common/notifications";
import {ServerCommunicationAlert} from "../../../common/errors";
import {Loading, LoadingRow} from "../../../common/Loading";
import API, {createApiConfig} from "../../../../utils/API";
import {AxiosError} from "axios";
import {
    MembershipDTO,
    MembershipInvitationAdminDTO,
    MembershipInvitationCreateDTO,
    MembershipInvitationDTO,
    MembershipInvitationUpdateDTO,
    MembershipPositionSimpleDTO,
} from "../../../../models/members";
import {OrganizationDTO} from "../../../../models/organization";
import Pagination from "@material-ui/lab/Pagination";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {isAdmin} from "../../../../utils/auth";
import {makeFullName} from "../../../../models/user";
import ROUTES from "../../../../routes/routes";
import Utils from "../../../../utils";
import {CustomizableConfirmationDialog, DeleteConfirmationDialog} from "../../../common/dialogs";
import {Page} from "../../../../models/pagination";
import {PositionsSelect} from "./PositionsSelect";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            tableLayout: "fixed",
        },
        actionsColumnCell: {
            width: "131px",
            textAlign: "right",
        },
        adminActionsColumnCell: {
            width: "183px",
            textAlign: "right",
        },
        awaitingAcceptanceNameColumn: {
            color: theme.palette.grey["600"],
            fontStyle: "italic",
        },
        awaitingAcceptanceColumn: {
            fontStyle: "italic",
        },
    })
);

interface MembershipInvitationRowProps {
    membershipInvitation: MembershipInvitationDTO | MembershipInvitationAdminDTO;
    availablePositions: MembershipPositionSimpleDTO[];

    onAccept?(membership: MembershipDTO): void;
}

interface MembershipInvitationNewRowProps {
    onSave: (invitation: MembershipInvitationDTO) => void;
    availablePositions: MembershipPositionSimpleDTO[];
    organizationId: string;
}

interface MembershipInvitationRowState {
    message: string | null;
    positions: MembershipPositionSimpleDTO[];
    name:
        | "init"
        | "editing"
        | "saving"
        | "confirmingDelete"
        | "deleting"
        | "deleted"
        | "saved"
        | "failed"
        | "confirmingAdminAccept"
        | "adminAccepting"
        | "adminAccepted";
    action: "init" | "edit" | "delete" | "accept";
}

const MembershipInvitationRow: React.FC<MembershipInvitationRowProps> = (props: MembershipInvitationRowProps) => {
    //== Init ===================================================================
    const classes = useStyles();
    const [t] = useTranslation(["organizationMembers", "common"]);
    const {keycloak, initialized} = useKeycloak();
    const [state, setState] = React.useState<MembershipInvitationRowState>({
        positions: props.membershipInvitation.positions,
        message: props.membershipInvitation.message,
        name: "init",
        action: "init",
    });
    const [formData, setFormData] = useState({
        positions: state.positions,
        message: state.message || "",
    });
    //== Effects ================================================================
    React.useEffect(() => {
        const changed =
            JSON.stringify(formData.positions.map((position) => position.id).sort()) !==
            JSON.stringify(state.positions.map((position) => position.id).sort());
        if (state.name === "deleting") {
            API.delete<void>(
                `/membership-invitations/${props.membershipInvitation.id}`,
                createApiConfig(keycloak, initialized)
            )
                .then((res) => {
                    setState({...state, name: "deleted"});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed", action: "delete"});
                    return;
                });
        } else if (state.name === "saving" && changed) {
            console.log("saving");
            const membershipInvitation: MembershipInvitationUpdateDTO = {
                ...props.membershipInvitation,
                message: Utils.emptyToNull(formData.message),
                positionIds: formData.positions.map((position) => position.id),
            };
            API.put<MembershipInvitationDTO>(
                `/membership/${props.membershipInvitation.id}`,
                membershipInvitation,
                createApiConfig(keycloak, initialized)
            )
                .then((res) => {
                    setState({...state, positions: res.data.positions, name: "saved", action: "edit"});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed", action: "edit"});
                });
        } else if (state.name === "saving") {
            console.log("nothing to save");
            setState({...state, name: "saved"});
        } else if (state.name === "adminAccepting") {
            API.post<MembershipDTO>(
                `/membership-invitations/accept/${props.membershipInvitation.publicId}`,
                null,
                createApiConfig(keycloak, initialized)
            )
                .then((res) => {
                    console.log("res:");
                    console.log(res);
                    props.onAccept?.(res.data);
                    setState({
                        ...state,
                        name: "adminAccepted",
                        action: "accept",
                    });
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed", action: "accept"});
                });
        }
    }, [keycloak, initialized, state, setState, formData, props.membershipInvitation]);
    //== Handlers ===============================================================
    const handleCancelDelete = (): void => {
        setState({...state, name: "init"});
    };
    const handleConfirmDelete = (): void => {
        setState({...state, name: "deleting"});
    };
    const handleDelete = (): void => {
        setState({...state, name: "confirmingDelete"});
    };
    const handleEdit = (): void => {
        setState({...state, name: "editing"});
    };
    const handleSave = (): void => {
        setState({...state, name: "saving"});
    };
    const handleCancelAdminAccept = (): void => {
        setState({...state, name: "init"});
    };
    const handleConfirmAdminAccept = (): void => {
        setState({...state, name: "adminAccepting"});
    };
    const handleAccept = (): void => {
        setState({...state, name: "confirmingAdminAccept"});
    };

    const handlePositionsChange = (newPositionIds: string[]): void => {
        const newPositions: MembershipPositionSimpleDTO[] = newPositionIds.map((id) => {
            const position = props.availablePositions.find((position) => position.id === id);
            if (position) return position;
            else {
                console.log("ERROR: selected position that does not exist");
                setState({...state, name: "failed"});
                return {} as MembershipPositionSimpleDTO;
            }
        });
        setFormData({...formData, positions: newPositions});
    };
    //== Render =================================================================
    if (state.name === "deleted") {
        return <SuccessNotification message={t(`notifications.invitations.delete_ok`)} />;
    }
    if (state.name === "adminAccepted")
        return <SuccessNotification message={t(`notifications.invitations.accept_ok`)} />;
    if (state.name === "saving" || state.name === "deleting") {
        return <LoadingRow key={props.membershipInvitation.id} />;
    }
    const person =
        "person" in props.membershipInvitation && isAdmin(keycloak) ? props.membershipInvitation.person : null;
    const personNameCell = isAdmin(keycloak) ? (
        <TableCell>
            {person ? <Link href={ROUTES.person.create(person.publicId)}>{makeFullName(person)}</Link> : null}
        </TableCell>
    ) : null;
    const emailCell = <TableCell>{props.membershipInvitation.email}</TableCell>;
    if (state.name === "editing") {
        return (
            <TableRow key={props.membershipInvitation.id}>
                <TableCell>
                    <PositionsSelect
                        currentPositions={formData.positions}
                        availablePositions={props.availablePositions}
                        handlePositionsChange={handlePositionsChange}
                    />
                </TableCell>
                {personNameCell}
                {emailCell}
                <TableCell>
                    <TextField
                        id="message"
                        label={t("membershipInvitation.message")}
                        value={formData.message}
                        fullWidth
                        onChange={(e): void => setFormData({...formData, message: e.target.value})}
                    />
                </TableCell>
                <TableCell className={isAdmin(keycloak) ? classes.adminActionsColumnCell : classes.actionsColumnCell}>
                    {person ? <AcceptActionButton onClick={handleAccept} /> : null}
                    <SaveActionButton onClick={handleSave} />
                    <DeleteActionButton onClick={handleDelete} />
                </TableCell>
            </TableRow>
        );
    }
    // - default
    let notification: JSX.Element | null = null;
    if (state.name === "failed") {
        notification = <ErrorNotification message={t(`notifications.invitations.${state.action}_fail`)} />;
    }
    if (state.name === "saved") {
        notification = <SuccessNotification message={t(`notifications.invitations.edit_ok`)} />;
    }
    return (
        <TableRow key={props.membershipInvitation.id}>
            {personNameCell}
            <TableCell>
                {state.positions.map((position) => position.name).join(", ")}
                {notification}
            </TableCell>
            {emailCell}
            <TableCell>{props.membershipInvitation.message}</TableCell>
            <TableCell className={isAdmin(keycloak) ? classes.adminActionsColumnCell : classes.actionsColumnCell}>
                {person ? (
                    <>
                        <AcceptActionButton onClick={handleAccept} />{" "}
                        <CustomizableConfirmationDialog
                            title={t("ui.invitations.adminAcceptConfirmDialog.title")}
                            text={t("ui.invitations.adminAcceptConfirmDialog.text", {
                                fullName: makeFullName(props.membershipInvitation.inviter),
                            })}
                            open={state.name === "confirmingAdminAccept"}
                            id={"accept-dialog-" + props.membershipInvitation.id}
                            onAgree={handleConfirmAdminAccept}
                            onDisagree={handleCancelAdminAccept}
                            agree={t("common:dialogs.confirm.agree")}
                            disagree={t("common:dialogs.confirm.disagree")}
                        />
                    </>
                ) : null}
                <EditActionButton onClick={handleEdit} />
                <DeleteActionButton onClick={handleDelete} />
                <DeleteConfirmationDialog
                    title={t("ui.invitations.deleteConfirmDialog.title")}
                    text={t("ui.invitations.deleteConfirmDialog.text", {
                        fullName: makeFullName(props.membershipInvitation.inviter),
                    })}
                    open={state.name === "confirmingDelete"}
                    id={"delete-dialog-" + props.membershipInvitation.id}
                    onDelete={handleConfirmDelete}
                    onCancel={handleCancelDelete}
                />
            </TableCell>
        </TableRow>
    );
};
const MembershipInvitationNewRow: React.FC<MembershipInvitationNewRowProps> = (
    props: MembershipInvitationNewRowProps
) => {
    //== Init ===================================================================
    const classes = useStyles();
    const [t] = useTranslation("organizationMembers");
    const {keycloak, initialized} = useKeycloak();
    const initState = {
        positions: [] as MembershipPositionSimpleDTO[],
        email: "",
        message: "" as string,
        name: "init" as "init" | "saving" | "failed",
    };
    const [state, setState] = React.useState(initState);
    //== Effects ================================================================
    React.useEffect(() => {
        if (state.name === "saving") {
            const membershipInvitation: MembershipInvitationCreateDTO = {
                email: state.email,
                message: Utils.emptyToNull(state.message),
                positionIds: state.positions.map((position) => position.id),
            };
            API.post<MembershipInvitationDTO>(
                `/membership-invitations`,
                membershipInvitation,
                createApiConfig(keycloak, initialized)
            )
                .then((res) => {
                    props.onSave(res.data);
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [keycloak, initialized, state, setState, initState, props]);
    //== Handlers ===============================================================
    const handleSend = (): void => {
        setState({...state, name: "saving"});
    };
    //== Render =================================================================
    if (state.name === "saving") {
        return <LoadingRow />;
    }
    let notification: JSX.Element | null = null;
    if (state.name === "failed") {
        notification = <ErrorNotification message={t("notifications.invitations.create_fail")} />;
    }
    const handlePositionsChange = (newPositionIds: string[]): void => {
        console.log("newPositionIds:");
        console.log(newPositionIds);
        const newPositions: MembershipPositionSimpleDTO[] = newPositionIds.map((id) => {
            const position = props.availablePositions.find((position) => position.id === id);
            if (position) return position;
            else {
                console.log("ERROR: selected position that does not exist");
                setState({...state, name: "failed"});
                return {} as MembershipPositionSimpleDTO;
            }
        });
        console.log("newPositions:");
        console.log(newPositions);
        setState({...state, positions: newPositions});
    };
    // - default
    return (
        <TableRow key="new">
            {isAdmin(keycloak) ? <TableCell /> : null}
            <TableCell>
                <PositionsSelect
                    currentPositions={state.positions}
                    availablePositions={props.availablePositions}
                    handlePositionsChange={handlePositionsChange}
                />
            </TableCell>
            <TableCell>
                <TextField
                    id="email"
                    label={t("membershipInvitation.email")}
                    value={state.email}
                    fullWidth
                    required
                    onChange={(e): void => setState({...state, email: e.target.value})}
                />
            </TableCell>
            <TableCell>
                <TextField
                    id="message"
                    label={t("membershipInvitation.message")}
                    value={state.message}
                    fullWidth
                    onChange={(e): void => setState({...state, message: e.target.value})}
                />
            </TableCell>
            <TableCell className={isAdmin(keycloak) ? classes.adminActionsColumnCell : classes.actionsColumnCell}>
                <SendActionButton onClick={handleSend} />
                {notification}
            </TableCell>
        </TableRow>
    );
};

interface MembershipInvitationsProps {
    organization: OrganizationDTO;

    onAccept?(membership: MembershipDTO): void;
}

const MembershipInvitations: React.FC<MembershipInvitationsProps> = (props: MembershipInvitationsProps) => {
    //== Init ===================================================================
    const classes = useStyles();
    const [t] = useTranslation("organizationMembers");
    const {keycloak, initialized} = useKeycloak();
    const initState = {
        page: {
            size: 20,
            totalElements: 0,
            totalPages: 0,
            number: 0,
        },
        membershipInvitations: [] as (MembershipInvitationDTO | MembershipInvitationAdminDTO)[],
        positions: [] as MembershipPositionSimpleDTO[],
        name: "loading" as "loading" | "loaded" | "failed",
        action: "init" as "init" | "refresh" | "new",
    };
    const [state, setState] = React.useState(initState);
    //== Effects ================================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<Page<MembershipInvitationDTO | MembershipInvitationAdminDTO>>(
                `/membership-invitations${isAdmin(keycloak) ? "/with-persons" : ""}?organization=${
                    props.organization.publicId
                }&page=${state.page.number}&size=${state.page.size}`,
                createApiConfig(keycloak, initialized)
            )
                .then((membershipInvitationsRes) => {
                    if (state.name === "loading") {
                        API.get<MembershipPositionSimpleDTO[]>(
                            `/positions/simple?organization=${props.organization.publicId}`,
                            createApiConfig(keycloak, initialized)
                        )
                            .then((positionsRes) => {
                                setState({
                                    ...state,
                                    page: membershipInvitationsRes.data,
                                    positions: positionsRes.data === undefined ? [] : positionsRes.data,
                                    membershipInvitations:
                                        membershipInvitationsRes.data.content === undefined
                                            ? []
                                            : membershipInvitationsRes.data.content,
                                    name: "loaded",
                                });
                            })
                            .catch((err: AxiosError) => {
                                setState({...state, name: "failed"});
                            });
                    }
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [keycloak, initialized, state, setState, props.organization]);
    //== Handlers ===============================================================
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number): void => {
        setState({
            ...state,
            page: {...state.page, number: value - 1},
            name: "loading",
            action: "init",
        });
    };
    //== Render =================================================================
    if (state.name === "loading") {
        return <Loading />;
    }
    if (state.name === "failed") {
        return <ServerCommunicationAlert />;
    }
    // - default
    let notification: JSX.Element | null = null;
    if (state.name === "loaded" && state.action === "refresh") {
        notification = <InfoNotification message={t("notifications.invitations.refresh_ok")} />;
    }
    if (state.name === "loaded" && state.action === "new") {
        notification = <SuccessNotification message={t("notifications.invitations.create_ok")} />;
    }
    const rows = state.membershipInvitations.map(
        (membershipInvitation: MembershipInvitationDTO | MembershipInvitationAdminDTO) => (
            <MembershipInvitationRow
                key={membershipInvitation.id}
                membershipInvitation={membershipInvitation}
                availablePositions={state.positions}
                onAccept={props.onAccept}
            />
        )
    );
    return (
        <Box>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        {isAdmin(keycloak) ? <TableCell>{t("membership.fullname")}</TableCell> : null}
                        <TableCell>{t("membership.positions")}</TableCell>
                        <TableCell>{t("membershipInvitation.email")}</TableCell>
                        <TableCell>{t("membershipInvitation.message")}</TableCell>
                        <TableCell
                            className={isAdmin(keycloak) ? classes.adminActionsColumnCell : classes.actionsColumnCell}
                        >
                            <RefreshActionButton
                                onClick={(): void =>
                                    setState({
                                        ...initState,
                                        action: "refresh",
                                    })
                                }
                            />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{rows}</TableBody>
                <TableFooter>
                    <MembershipInvitationNewRow
                        availablePositions={state.positions}
                        onSave={(): void => setState({...initState, action: "new"})}
                        organizationId={props.organization.publicId}
                    />
                </TableFooter>
            </Table>
            {state.page.totalPages > 1 && (
                <Pagination
                    style={{marginTop: "1em"}}
                    count={state.page.totalPages}
                    page={state.page.number + 1}
                    onChange={handlePageChange}
                />
            )}
            {notification}
        </Box>
    );
};

export default MembershipInvitations;
