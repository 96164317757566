import React from "react";
import {useParams} from "react-router-dom";
import {CommunicationError, NotFound} from "../common/errors";
import {Container, Divider} from "@material-ui/core";
import {LibraryDTO} from "../../models/library";
import {API, createApiConfig} from "../../utils";
import {useKeycloak} from "@react-keycloak/web";
import {LoadingBackdrop} from "../common/Loading";
import {AxiosError, AxiosResponse} from "axios";
import {LibraryInfo} from "./browser/LibraryInfo";
import {LibraryBrowser} from "./browser/LibraryBrowser";
import {PageHeader, SecondaryPageAction} from "../common/headers";
import ROUTES from "../../routes/routes";
import ListIcon from "@material-ui/icons/List";
import {useTranslation} from "react-i18next";
import BusinessIcon from "@material-ui/icons/Business";
import BuildIcon from "@material-ui/icons/Build";
import EditIcon from "@material-ui/icons/Edit";
import {BreadcrumbItem, BreadcrumbsRow} from "../common/breadcrumbs";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";

interface LibraryDetailState {
    library: LibraryDTO | null;
    name: string;
}

interface LibraryParams {
    libraryId: string;
}

export const LibraryDetail: React.FC = () => {
    // TODO: permissions
    //== Init =================================================================
    const [t] = useTranslation("libraries");
    const {libraryId} = useParams<LibraryParams>();
    const {keycloak, initialized} = useKeycloak();
    const initState: LibraryDetailState = {
        library: null,
        name: "loading",
    };
    const [state, setState] = React.useState(initState);
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<LibraryDTO>(`/libraries/${libraryId}`, createApiConfig(keycloak, initialized))
                .then((res: AxiosResponse<LibraryDTO>) => {
                    setState({...state, library: res.data, name: "loaded"});
                })
                .catch((err: AxiosError) => {
                    if (err?.response?.status === 404) {
                        setState({...state, name: "not_found"});
                    } else {
                        setState({...state, name: "failed"});
                    }
                });
        }
    }, [state, setState, libraryId, keycloak, initialized]);
    //== Render ===============================================================
    if (state.name === "loading") {
        return <LoadingBackdrop />;
    }
    if (state.name === "not_found") {
        return <NotFound />;
    }
    if (state.name === "failed") {
        return <CommunicationError />;
    }
    if (state.library !== null) {
        return (
            <Container maxWidth="lg">
                <BreadcrumbsRow>
                    <BreadcrumbItem name={t("library.titles.libraries")} route={ROUTES.libraries.path} />
                    <BreadcrumbItem name={state.library.name} />
                </BreadcrumbsRow>
                <PageHeader title={state.library.name}>
                    <SecondaryPageAction title={t("library.actions.to_list")} to={ROUTES.libraries.path}>
                        <ListIcon />
                    </SecondaryPageAction>
                    <SecondaryPageAction
                        title={t("library.actions.organization")}
                        to={ROUTES.organization.create(state.library.organization.publicId)}
                    >
                        <BusinessIcon />
                    </SecondaryPageAction>
                    <SecondaryPageAction
                        title={t("library.actions.clone")}
                        to={ROUTES.libraryClone.create(state.library.id)}
                    >
                        <LibraryAddIcon />
                    </SecondaryPageAction>
                    <SecondaryPageAction
                        title={t("library.actions.editor")}
                        to={ROUTES.libraryEditor.create(state.library.id)}
                    >
                        <BuildIcon />
                    </SecondaryPageAction>
                    <SecondaryPageAction
                        title={t("library.actions.edit")}
                        to={ROUTES.libraryEdit.create(state.library.id)}
                    >
                        <EditIcon />
                    </SecondaryPageAction>
                </PageHeader>
                <LibraryInfo library={state.library} />
                <Divider />
                <h2>{t("library.titles.content")}</h2>
                <LibraryBrowser library={state.library} />
            </Container>
        );
    }
    return <CommunicationError />;
};

export default LibraryDetail;
