import React, {ChangeEvent} from "react";
import {OptionDTO, UnitSimpleDTO} from "../../models/library";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
} from "@material-ui/core";
import {API, createApiConfig} from "../../utils";
import {AxiosError} from "axios";
import {useTranslation} from "react-i18next";
import {useKeycloak} from "@react-keycloak/web";
import {ServerCommunicationAlert} from "./errors";
import {ValueSimpleDTO} from "../../models/values";

interface ValueSimpleFormProps {
    id?: string;
    present: boolean;
    value: number;
    unitUUID: string | null;
    variant?: "outlined" | "standard" | "filled";
    label: string;
    required?: boolean;
    placeholder?: string;
    fullWidth?: boolean;
    className?: string;

    onChange(value: ValueSimpleDTO | null): void;
}

interface ValueSimpleFormState {
    units: UnitSimpleDTO[];
    lastUnitUUID: string | null;
    name: "loading" | "loaded" | "failed";
}

export const ValueSimpleForm: React.FC<ValueSimpleFormProps> = (props: ValueSimpleFormProps) => {
    const [t] = useTranslation("libraries");
    const {keycloak, initialized} = useKeycloak();
    // STATE ONLY FOR UNITS! VALUE IS IN HIGHER STATE
    const initState: ValueSimpleFormState = {
        units: [],
        lastUnitUUID: null,
        name: props.unitUUID === null ? "loaded" : "loading",
    };
    const [state, setState] = React.useState(initState);
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "loading" || state.lastUnitUUID !== props.unitUUID) {
            API.get<UnitSimpleDTO[]>(`/units/list/group/${props.unitUUID}`, createApiConfig(keycloak, initialized))
                .then((res) => {
                    setState({
                        ...initState,
                        units: res.data,
                        name: "loaded",
                        lastUnitUUID: props.unitUUID,
                    });
                })
                .catch((err: AxiosError) => {
                    setState({...initState, name: "failed"});
                });
        }
    }, [state, setState, initState, props, initialized, keycloak]);
    //== Handlers =============================================================
    const currentUnitIndex = state.units.findIndex((u) => u.id === props.unitUUID);
    const currentUnit = currentUnitIndex >= 0 ? state.units[currentUnitIndex] : null;
    const handlePresentChange = (event: ChangeEvent<HTMLInputElement>, present: boolean): void => {
        if (present) {
            // base unit?
            const unit = state.units.length > 0 ? state.units[0] : null;
            props.onChange({value: 0, unit: unit});
        } else {
            props.onChange(null);
        }
    };
    const handleValueChange = (value: number): void => {
        props.onChange({value: value, unit: currentUnit});
    };
    //eslint-disable-next-line @typescript-eslint/ban-types
    const handleUnitChange = (event: ChangeEvent<{}>, unit: UnitSimpleDTO | null): void => {
        props.onChange({value: props.value ?? 0, unit: unit || null});
    };
    //== Render ===============================================================
    if (state.name === "failed") {
        return <ServerCommunicationAlert />;
    }
    return (
        <FormGroup row className={props.className}>
            <FormControlLabel
                control={<Switch color="primary" checked={props.present} onChange={handlePresentChange} />}
                label={props.label}
                className={props.className}
            />
            {props.present && (
                <FormGroup row style={props.fullWidth ? {flexGrow: 1, justifyContent: "flex-end"} : {}}>
                    <TextField
                        label={t("value.value")}
                        value={props.value ?? 0}
                        onChange={(e): void => {
                            const newValue = parseFloat(e.target.value);
                            handleValueChange(isNaN(newValue) ? 0 : newValue);
                        }}
                        variant={props.variant}
                        type="number"
                    />
                    {state.units.length > 0 && (
                        <Autocomplete
                            disabled={state.units.length === 1}
                            noOptionsText={t("value.unit_select.no_units")}
                            openText={t("value.unit_select.open")}
                            closeText={t("value.unit_select.close")}
                            clearText={t("value.unit_select.clear")}
                            options={state.units}
                            getOptionLabel={(unit: UnitSimpleDTO): string => unit.abbreviation}
                            getOptionSelected={(option, value): boolean => {
                                return option.id === value.id;
                            }}
                            value={currentUnit}
                            filterSelectedOptions
                            onChange={handleUnitChange}
                            style={{minWidth: "8em", marginLeft: "0.5em"}}
                            renderInput={(params): JSX.Element => (
                                <TextField
                                    {...params}
                                    variant={props.variant}
                                    label={t("value.unit")}
                                    placeholder={props.placeholder}
                                />
                            )}
                        />
                    )}
                </FormGroup>
            )}
        </FormGroup>
    );
};

interface UnitsSelectProps {
    disabled?: boolean;
    units: UnitSimpleDTO[];
    unitUUID: string | null;

    onChange(unitUUID: string): void;
}

export const UnitsSelect: React.FC<UnitsSelectProps> = (props: UnitsSelectProps) => {
    const [t] = useTranslation(["values"]);
    if (props.units.length === 0) {
        return null;
    }
    const value = props.unitUUID === null ? (props.units.length === 1 ? props.units[0].id : undefined) : props.unitUUID;
    return (
        <FormControl variant="outlined">
            <InputLabel>{t("values_input.unit")}</InputLabel>
            <Select
                disabled={props.disabled || props.units.length === 1}
                style={{minWidth: "10em"}}
                value={value}
                required
                onChange={(e): void => {
                    const unitUUID = e.target.value as string;
                    props.onChange(unitUUID);
                }}
                label={t("values_input.unit")}
            >
                {props.units.map((u, i) => {
                    return (
                        <MenuItem value={u.id} key={i} title={u.name}>
                            {u.abbreviation}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};

interface OptionsSelectProps {
    disabled?: boolean;
    options: OptionDTO[];
    value: number;

    onChange(value: number): void;
}

export const OptionsSelect: React.FC<OptionsSelectProps> = (props: OptionsSelectProps) => {
    const [t] = useTranslation(["values"]);
    return (
        <FormControl variant="outlined">
            <InputLabel>{t("values_input.value")}</InputLabel>
            <Select
                disabled={props.disabled}
                style={{minWidth: "10em"}}
                value={props.value}
                required
                onChange={(e): void => {
                    const value = e.target.value as number;
                    props.onChange(value);
                }}
                label={t("values_input.value")}
            >
                {props.options.map((o, i) => {
                    return (
                        <MenuItem value={o.value.value} key={i} title={o.description}>
                            {o.name}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};
