import React from "react";
import {
    Box,
    Button,
    Checkbox,
    Container,
    FormControlLabel,
    Icon,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from "@material-ui/core";
import {API, createApiConfig, DateUtils, Utils, ValueUtils} from "../../utils";
import {AxiosError, AxiosResponse} from "axios";
import {useKeycloak} from "@react-keycloak/web";
import Loading from "../common/Loading";
import {
    TrackedIndicatorHistoryDTO,
    TrackedIndicatorRecomputeAnswerDTO,
    TrackedIndicatorValueInfoDTO,
    TrackedIndicatorValueSimpleDTO,
} from "../../models/values";
import {useTranslation} from "react-i18next";
import {Alert} from "@material-ui/lab";
import {TrackedIndicatorInGroupDTO} from "../../models/trackedIndicators";
import {CustomActionButton} from "../common/buttons";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import {CSVUtils} from "../../utils/CSV";
import {SinceUntilPicker} from "../common/forms";
import {DataTablePagination} from "../common/tables";
import {UnitsSelect} from "../common/values";
import IndicatorTargetValue from "../organization/TrackedIndicators/browser/IndicatorTargetValue";
import IndicatorValuesInput from "../organization/TrackedIndicators/browser/IndicatorValuesInput";
import {
    adminOrganizationPermissions,
    anonymousOrganizationPermissions,
    OrganizationPermissionsDTO,
} from "../../models/members";
import {isAdmin, isUser} from "../../utils/auth";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {ErrorNotification, SuccessNotification} from "../common/notifications";
import {IndicatorHistoryGraph} from "./IndicatorHistoryGraph";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        validRow: {},
        invalidRow: {
            textDecoration: "line-through",
        },
        warningRow: {
            maxWidth: "50em",
            margin: "1em auto",
        },
    })
);

interface IndicatorHistoryTableProps {
    valueFn: (v: number) => string;
    values: TrackedIndicatorValueSimpleDTO[];
}

const IndicatorHistoryTable: React.FC<IndicatorHistoryTableProps> = (props: IndicatorHistoryTableProps) => {
    //== Init =================================================================
    const [t] = useTranslation(["values"]);
    const invalidNote: string = t("indicator.invalidNote");
    const classes = useStyles();
    const invalidIndicator = (
        <Tooltip title={invalidNote}>
            <div>
                <IconButton disabled size="small">
                    <ErrorOutlineIcon />
                </IconButton>
            </div>
        </Tooltip>
    );
    //== Render ===============================================================
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell align="right">{t("indicator.relevantSince")}</TableCell>
                        <TableCell align="right">{t("indicator.value")}</TableCell>
                        <TableCell align="right">{t("indicator.computedAt")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.values.map((v: TrackedIndicatorValueSimpleDTO) => (
                        <TableRow key={v.id} className={v.state === "valid" ? classes.validRow : classes.invalidRow}>
                            <TableCell align="left">{v.state === "invalid" && invalidIndicator}</TableCell>
                            <TableCell align="right">{DateUtils.dateString(v.relevantSince)}</TableCell>
                            <TableCell align="right">{props.valueFn(v.value)}</TableCell>
                            <TableCell align="right">{DateUtils.datetimeString(v.createdAt)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

interface IndicatorCalculationWarningProps {
    info: TrackedIndicatorValueInfoDTO;
    disabled: boolean;
    onRefresh: () => void;
}

const IndicatorCalculationWarning: React.FC<IndicatorCalculationWarningProps> = (
    props: IndicatorCalculationWarningProps
) => {
    //== Init =================================================================
    const [t] = useTranslation(["values"]);
    const classes = useStyles();
    //== Render ===============================================================
    if (!props.info.hasCalculationTask) {
        return null;
    }
    return (
        <Box className={classes.warningRow}>
            <Alert
                severity="warning"
                action={
                    <Button color="inherit" size="small" onClick={props.onRefresh} disabled={props.disabled}>
                        {t("indicator.info.refresh")}
                    </Button>
                }
            >
                {t("indicator.info.hasCalculationTask")}
            </Alert>
        </Box>
    );
};

interface IndicatorRecomputeActionProps {
    info: TrackedIndicatorValueInfoDTO;
    disabled: boolean;
    onRefresh: () => void;
}

interface IndicatorRecomputeActionState {
    timestamp: string;
    name: "init" | "working" | "done" | "failed";
}

const IndicatorRecomputeAction: React.FC<IndicatorRecomputeActionProps> = (props: IndicatorRecomputeActionProps) => {
    //== Init =================================================================
    const [t] = useTranslation(["values"]);
    const initState: IndicatorRecomputeActionState = {
        name: "init",
        timestamp: "",
    };
    const [state, setState] = React.useState(initState);
    const {keycloak, initialized} = useKeycloak();
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "working") {
            const url = `/tracked-indicator-values/${props.info.trackedIndicatorId}/recompute`;
            API.post(url, null, createApiConfig(keycloak, initialized))
                .then((r: AxiosResponse<TrackedIndicatorRecomputeAnswerDTO>) => {
                    setState({...state, name: "done"});
                    props.onRefresh();
                })
                .catch((e: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        } else if (state.timestamp !== props.info.infoAt) {
            setState({timestamp: props.info.infoAt, name: "init"});
        }
    }, [props, state, setState, keycloak, initialized]);
    //== Handlers =============================================================
    const handleRecompute = () => {
        setState({...state, name: "working"});
    };
    //== Render ===============================================================
    if (!props.info.recomputeEligible) {
        return null;
    }
    let notification = null;
    if (state.name === "done") {
        notification = <SuccessNotification message={t("indicator.recompute.done")} />;
    } else if (state.name === "failed") {
        notification = <ErrorNotification message={t("indicator.recompute.error")} />;
    }
    return (
        <>
            <CustomActionButton
                title={t("indicator.recompute.action")}
                icon={<Icon>calculate</Icon>}
                onClick={handleRecompute}
                disabled={props.disabled || state.name === "working" || props.info.hasCalculationTask}
            />
            {notification}
        </>
    );
};

interface IndicatorHistoryProps {
    indicator: TrackedIndicatorInGroupDTO;
    organizationId: string;
    permissions?: OrganizationPermissionsDTO;
    graphOnly?: boolean;
}

interface IndicatorHistoryState {
    name: "loading" | "refreshing" | "paging" | "loaded" | "failed";
    history: TrackedIndicatorHistoryDTO | null;
    pageNumber: number;
    pageSize: number;
    unitUUID: string | undefined;
    sinceDate: string | undefined;
    untilDate: string | undefined;
    validOnly: boolean;
    permissions: OrganizationPermissionsDTO;
}

function filterValues(
    history: TrackedIndicatorHistoryDTO,
    sinceDate: string | undefined,
    untilDate: string | undefined,
    validOnly: boolean
): TrackedIndicatorValueSimpleDTO[] {
    const filterFn = (v: TrackedIndicatorValueSimpleDTO) => {
        if (sinceDate && v.relevantSince < sinceDate) {
            return false;
        }
        if (untilDate && v.relevantSince > untilDate) {
            return false;
        }
        return !(validOnly && v.state !== "valid");
    };
    return history.values.filter(filterFn);
}

export const IndicatorHistory: React.FC<IndicatorHistoryProps> = (props: IndicatorHistoryProps) => {
    // TODO: set number of decimal places?
    // TODO: incorporate options as for InputValues?
    // TODO: chart of value (need units selection first)
    //== Init =================================================================
    const initState: IndicatorHistoryState = {
        name: "loading",
        pageNumber: 0,
        pageSize: 5,
        history: null,
        unitUUID: undefined,
        sinceDate: undefined,
        untilDate: undefined,
        validOnly: true,
        permissions: props.permissions || anonymousOrganizationPermissions(props.organizationId),
    };
    const [state, setState] = React.useState(initState);
    const {keycloak, initialized} = useKeycloak();
    const [t] = useTranslation(["values"]);
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "loading" || state.name === "refreshing") {
            const loadHistory = (permissions: OrganizationPermissionsDTO) => {
                const id = props.indicator.id;
                let url = `/tracked-indicator-values/${id}/history`;
                if (state.unitUUID) {
                    url = url + `?unit=${state.unitUUID}`;
                }
                API.get(url, createApiConfig(keycloak, initialized))
                    .then((res: AxiosResponse<TrackedIndicatorHistoryDTO>) => {
                        setState({
                            ...state,
                            name: "loaded",
                            history: res.data,
                            unitUUID: res.data.unit == null ? undefined : res.data.unit?.id,
                            permissions: permissions,
                        });
                    })
                    .catch((err: AxiosError) => {
                        setState({...state, name: "failed", permissions: permissions});
                    });
            };
            if (state.name === "refreshing" || props.permissions) {
                loadHistory(state.permissions);
            } else if (state.name === "loading") {
                if (isAdmin(keycloak)) {
                    loadHistory(adminOrganizationPermissions(props.organizationId));
                } else if (isUser(keycloak)) {
                    API.get<OrganizationPermissionsDTO>(
                        `/organization-permissions/${props.organizationId}/current-user`,
                        createApiConfig(keycloak, initialized)
                    )
                        .then((res) => {
                            if (!res.data) return setState({...state, name: "failed"});
                            loadHistory(res.data);
                        })
                        .catch((err) => {
                            console.log(err);
                            return setState({...state, name: "failed"});
                        });
                } else {
                    loadHistory(anonymousOrganizationPermissions(props.organizationId));
                }
            }
        }
    }, [props, state, setState, keycloak, initialized]);
    //== Helpers ==============================================================
    const unit = state.history?.unit ?? null;
    const displayValue = (v: number): string => {
        return ValueUtils.formatValueUnit(v, unit);
    };
    const csvConvert = (v: TrackedIndicatorValueSimpleDTO): string[] => {
        return [
            DateUtils.datetimeString(v.relevantSince),
            v.value.toString(),
            unit?.abbreviation ?? "",
            DateUtils.datetimeString(v.createdAt),
            t(`indicator.export.validity.${v.state}`),
            props.indicator.targetValue?.value?.toString() ?? "",
            props.indicator.targetValue?.unit?.name ?? "",
        ];
    };
    const csvHeaders = [
        t("indicator.export.relevantSince"),
        t("indicator.export.value"),
        t("indicator.export.unit_name"),
        t("indicator.export.createdAt"),
        t("indicator.export.valid"),
        t("indicator.export.target.value"),
        t("indicator.export.target.unit_name"),
    ];
    /*
    console.log("IndicatorHistory - props:");
    console.log(props);
    console.log("IndicatorHistory - state:");
    console.log(state);
    */
    //== Handlers =============================================================
    const values =
        state.history == null ? [] : filterValues(state.history, state.sinceDate, state.untilDate, state.validOnly);
    const handleRefresh = () => {
        setState({...state, name: "refreshing"});
    };
    const handleChangePage = (event: unknown, newPage: number) => {
        setState({...state, pageNumber: newPage});
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newSize = parseInt(event.target.value, 10);
        setState({...state, pageSize: newSize, pageNumber: 0});
    };
    const handleDownload = (): void => {
        // TODO: better filename?
        const csv = CSVUtils.generateCSV(values, csvConvert, csvHeaders);
        Utils.download("export.csv", "text/csv;charset=utf-8", [csv]).click();
    };
    const handleSinceUntilChange = (since: string | undefined, until: string | undefined): void => {
        setState({...state, sinceDate: since, untilDate: until});
    };
    const handleUnitChange = (unitUUID: string): void => {
        setState({...state, unitUUID: unitUUID, name: "loading"});
    };
    const handleValidOnlyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({...state, validOnly: event.target.checked});
    };
    //== Render ===============================================================
    if (state.name === "failed") {
        return (
            <Box style={{margin: "1em"}}>
                <Alert severity="error">{t("values_input.errors.history")}</Alert>
            </Box>
        );
    }
    if (state.name === "loading" || state.history == null) {
        return (
            <Box style={{margin: "1em"}}>
                <Loading />
            </Box>
        );
    }
    const isFiltered = state.sinceDate !== undefined || state.untilDate !== undefined;
    const actPage = Utils.createPage(values, state.pageNumber, state.pageSize);
    return (
        <Box style={{margin: "1em"}}>
            <IndicatorCalculationWarning
                info={state.history.info}
                disabled={state.name === "refreshing"}
                onRefresh={handleRefresh}
            />
            {!props.graphOnly && (
                <Box display="flex">
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.validOnly}
                                    onChange={handleValidOnlyChange}
                                    color="secondary"
                                />
                            }
                            label={t("indicator.validOnly")}
                        />
                        <SinceUntilPicker
                            since={state.sinceDate}
                            until={state.untilDate}
                            onChange={handleSinceUntilChange}
                        />
                    </Box>
                    {state.unitUUID !== undefined && (
                        <Box style={{marginLeft: "1em"}}>
                            <UnitsSelect
                                units={state.history?.possibleUnits}
                                unitUUID={state.unitUUID}
                                onChange={handleUnitChange}
                            />
                        </Box>
                    )}
                    <Box flexGrow={1}>
                        <Box justifyContent="flex-end" display="flex">
                            {state.permissions.valuesPermissionLevel !== "NONE" ||
                            state.permissions.valueReadWriteTrackedDomains.some(
                                (trackedDomain) => trackedDomain.id == props.indicator.id
                            ) ? (
                                <IndicatorValuesInput
                                    organizationId={props.organizationId}
                                    trackedIndicator={props.indicator}
                                    onClose={handleRefresh}
                                />
                            ) : null}
                            <CustomActionButton
                                title={t("indicator.download_csv")}
                                icon={<SaveAltIcon />}
                                onClick={handleDownload}
                                disabled={state.name === "refreshing"}
                            />
                            <IndicatorRecomputeAction
                                info={state.history.info}
                                disabled={state.name === "refreshing"}
                                onRefresh={handleRefresh}
                            />
                        </Box>
                        <div style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                            <IndicatorTargetValue
                                trackedIndicator={props.indicator}
                                organizationId={props.organizationId}
                                permissions={state.permissions}
                                onChange={() => setState({...state, name: "refreshing"})}
                            />
                        </div>
                    </Box>
                </Box>
            )}
            {state.name === "refreshing" || state.name === "paging" ? (
                <Loading />
            ) : values.length > 0 ? (
                <Container maxWidth="md" style={{marginTop: "1em"}}>
                    {!props.graphOnly && (
                        <>
                            <IndicatorHistoryTable values={actPage.content} valueFn={displayValue} />
                            <DataTablePagination
                                page={actPage}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </>
                    )}
                    <IndicatorHistoryGraph
                        values={values}
                        valueDisplayFn={displayValue}
                        targetValue={state.history.targetValue}
                        indicatorMinimum={state.history.indicatorMinimum}
                        indicatorMaximum={state.history.indicatorMaximum}
                        unit={unit}
                    />
                </Container>
            ) : (
                <Container maxWidth="md" style={{marginTop: "1em"}}>
                    <Alert severity="info">
                        {isFiltered ? t("indicator.no_values_date") : t("indicator.no_values")}
                    </Alert>
                </Container>
            )}
        </Box>
    );
};
