import React, {useEffect, useState} from "react";
import {useKeycloak} from "@react-keycloak/web";
// Project Imports
import API, {createApiConfig, emptyState, failedState, successState} from "../../../utils/API";
import Loading from "../../common/Loading";
import {CommunicationError} from "../../common/errors";
import {OrganizationProfileDTO} from "../../../models/organization";
import OrganizationProfile from "../OrganizationProfile/OrganizationProfile";
// Material-UI imports
import {Box, Divider} from "@material-ui/core";
import {adminOrganizationPermissions} from "../../../models/members";
import {AxiosError} from "axios";

export interface OrganizationProfileAdminLoaderProps {
    organizationId: string;
    edit: boolean;
}

const OrganizationProfileAdminLoader: React.FC<OrganizationProfileAdminLoaderProps> = (
    props: OrganizationProfileAdminLoaderProps
) => {
    const {keycloak, initialized} = useKeycloak();
    const [data, setData] = useState(emptyState);
    useEffect(() => {
        if (props.organizationId) {
            API.get<OrganizationProfileDTO>(
                `/organizations/profile/${props.organizationId}`,
                createApiConfig(keycloak, initialized)
            )
                .then((res) => {
                    setData(successState(res.data));
                })
                .catch((err: AxiosError) => {
                    setData(failedState);
                });
        } else {
            setData(
                successState({
                    organization: {
                        name: "",
                        type: "",
                    },
                })
            );
        }
    }, [keycloak, props.organizationId, initialized]);

    if (data === emptyState) {
        return <Loading />;
    } else if (data === failedState) {
        return <CommunicationError />;
    } else {
        const organizationProfile: OrganizationProfileDTO = data.data;
        return (
            <Box>
                <OrganizationProfile
                    organizationProfile={organizationProfile}
                    organizationPermissions={adminOrganizationPermissions(props.organizationId)}
                    editFirst={props.edit}
                />
                <Divider style={{margin: "1em"}} />
            </Box>
        );
    }
};
export default OrganizationProfileAdminLoader;
