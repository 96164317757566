import React, {ChangeEvent} from "react";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
// Material UI imports
import {TextField} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
// Project imports
import {ServerCommunicationAlert} from "../errors";
import {UnitSimpleDTO} from "../../../models/library";
import {API, createApiConfig} from "../../../utils";
import {AxiosError} from "axios";

interface UnitInputProps {
    query: "all" | "base" | "related";
    relatedTo?: string;
    value?: UnitSimpleDTO | null;
    valueUuid?: string | null;
    excludeUuids?: string[];
    variant?: "outlined" | "standard" | "filled";
    label: string;
    placeholder?: string;
    fullWidth?: boolean;
    className?: string;
    helperText?: string;
    margin?: "normal" | "dense" | "none";

    onChange?(unit: UnitSimpleDTO): void;
}

interface UnitInputState {
    units: UnitSimpleDTO[];
    value: UnitSimpleDTO | null;
    valueUuid: string | null;
    name: string;
}

export const UnitInput: React.FC<UnitInputProps> = (props: UnitInputProps) => {
    //== Init =================================================================
    const initState: UnitInputState = {
        units: [],
        value: props.value ?? null,
        valueUuid: props.valueUuid ?? null,
        name: "loading",
    };
    const [t] = useTranslation("libraries");
    const [state, setState] = React.useState(initState);
    const {keycloak, initialized} = useKeycloak();
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<UnitSimpleDTO[]>(`/units/list/${props.query}`, createApiConfig(keycloak, initialized))
                .then((res) => {
                    const units: UnitSimpleDTO[] = res.data ?? [];
                    const excludes = new Set(props.excludeUuids ?? []);
                    const filteredUnits = units.filter((unit) => !excludes.has(unit.id));
                    if (typeof state.valueUuid === "string") {
                        const value = filteredUnits.find((unit) => unit.id === state.valueUuid) ?? null;
                        setState({...state, value: value, units: filteredUnits, name: "loaded"});
                    } else {
                        setState({...state, units: filteredUnits, name: "loaded"});
                    }
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [state, setState, keycloak, initialized, props]);
    //== Handlers =============================================================
    //eslint-disable-next-line @typescript-eslint/ban-types
    const handleChange = (event: ChangeEvent<{}>, value: UnitSimpleDTO | null): void => {
        if (props.onChange && value != null) {
            props.onChange(value);
        }
        setState({...state, value: value, valueUuid: value?.id ?? null});
    };
    //== Render ===============================================================
    if (state.name === "failed") {
        return <ServerCommunicationAlert />;
    }
    return (
        <Autocomplete
            loading={state.name === "loading"}
            loadingText={t("definitions.units.select.loading")}
            noOptionsText={t("definitions.units.select.empty")}
            openText={t("definitions.units.select.open")}
            closeText={t("definitions.units.select.close")}
            clearText={t("definitions.units.select.clear")}
            options={state.units}
            getOptionLabel={(unit: UnitSimpleDTO): string => `${unit.name} [${unit.abbreviation}]`}
            getOptionSelected={(option, value): boolean => {
                return option.id === value.id;
            }}
            value={state.value}
            filterSelectedOptions
            onChange={handleChange}
            renderInput={(params): JSX.Element => (
                <TextField
                    {...params}
                    variant={props.variant}
                    label={props.label}
                    helperText={props.helperText}
                    placeholder={props.placeholder}
                    fullWidth={props.fullWidth}
                    className={props.className}
                    margin={props.margin}
                />
            )}
        />
    );
};
