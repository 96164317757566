import React from "react";
import {useKeycloak} from "@react-keycloak/web";
import {Redirect, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
// Material UI imports
import {Box, Container, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
// Local imports
import UnitForm from "./UnitForm";
// Project imports
import {
    BackLinkActionButton,
    CustomActionButton,
    DeleteActionButton,
    EditActionButton,
    IdCopyButton,
} from "../../common/buttons";
import {CommunicationError, NotFound} from "../../common/errors";
import Loading from "../../common/Loading";
import {ErrorNotification, SuccessNotification} from "../../common/notifications";
import {UnitCreateDTO, UnitDTO} from "../../../models/library";
import ROUTES from "../../../routes/routes";
import Utils, {API, createApiConfig} from "../../../utils";
import {AxiosError} from "axios";
import {UnitDelete} from "./UnitDelete";

interface UnitRowProps {
    unit: UnitDTO;
    onClick: () => void;
}

const UnitRow: React.FC<UnitRowProps> = (props: UnitRowProps) => {
    return (
        <TableRow>
            <TableCell align="center">
                <IdCopyButton text={props.unit.id} size="small" />
            </TableCell>
            <TableCell>{props.unit.abbreviation}</TableCell>
            <TableCell>{props.unit.name}</TableCell>
            <TableCell>{props.unit.description}</TableCell>
            <TableCell align="right">
                <EditActionButton onClick={props.onClick} />
            </TableCell>
        </TableRow>
    );
};

interface UnitDetailTableProps {
    units: UnitDTO[];

    switchDetail(unitUUID: string): void;
}

const UnitDetailTable: React.FC<UnitDetailTableProps> = (props: UnitDetailTableProps) => {
    const [t] = useTranslation("libraries");
    const rows = props.units.map((unit) => (
        <UnitRow key={unit.id} unit={unit} onClick={(): void => props.switchDetail(unit.id)} />
    ));
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell align="center">{t("definitions.units.id")}</TableCell>
                    <TableCell align="center">{t("definitions.units.abbreviation")}</TableCell>
                    <TableCell align="center">{t("definitions.units.name")}</TableCell>
                    <TableCell align="center">{t("definitions.units.description")}</TableCell>
                    <TableCell />
                </TableRow>
            </TableHead>
            <TableBody>{rows}</TableBody>
        </Table>
    );
};

interface UnitDetailInnerState {
    unitId: string;
    unit: UnitDTO | null;
    unitEdit: UnitCreateDTO;
    name: string;
    action: string;
}

interface UnitParams {
    unitId: string;
}

export const UnitDetail: React.FC = () => {
    //== Init =================================================================
    const [t] = useTranslation("libraries");
    const {keycloak, initialized} = useKeycloak();
    const {unitId} = useParams<UnitParams>();
    const initState: UnitDetailInnerState = {
        unitId: `${unitId}`,
        unit: null,
        unitEdit: {
            abbreviation: "",
            name: "",
            description: "",
            formulaFromBase: "",
            formulaToBase: "",
            baseUnitUUID: null,
        },
        name: "loading",
        action: "none",
    };
    const [state, setState] = React.useState(initState);
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<UnitDTO>(`/units/${state.unitId}`, createApiConfig(keycloak, initialized))
                .then((res) => {
                    const edit: UnitCreateDTO = {
                        abbreviation: res.data.abbreviation,
                        name: res.data.name,
                        description: res.data.description,
                        formulaFromBase: res.data?.formulaFromBase ?? "",
                        formulaToBase: res.data?.formulaToBase ?? "",
                        baseUnitUUID: res.data?.baseUnit?.id ?? null,
                    };
                    setState({...state, unit: res.data, unitEdit: edit, name: "loaded"});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
        if (state.name === "saving") {
            API.put<UnitDTO>(`/units/${state.unitId}`, state.unitEdit, createApiConfig(keycloak, initialized))
                .then((res) => {
                    const edit: UnitCreateDTO = {
                        abbreviation: res.data.abbreviation,
                        name: res.data.name,
                        description: res.data.description,
                        formulaFromBase: res.data?.formulaFromBase ?? "",
                        formulaToBase: res.data?.formulaToBase ?? "",
                        baseUnitUUID: state.unit?.baseUnit?.id ?? null,
                    };
                    setState({...state, unit: res.data, unitEdit: edit, name: "saved"});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [keycloak, initialized, state, setState]);
    //== Handlers =============================================================
    const act: UnitCreateDTO = {
        abbreviation: state.unit?.abbreviation ?? "",
        name: state.unit?.name ?? "",
        description: state.unit?.description ?? "",
        formulaFromBase: state.unit?.formulaFromBase ?? "",
        formulaToBase: state.unit?.formulaToBase ?? "",
        baseUnitUUID: state.unit?.baseUnit?.id ?? null,
    };
    const isNotChanged = Utils.objEq(act, state.unitEdit, [
        "abbreviation",
        "name",
        "description",
        "formulaFromBase",
        "formulaToBase",
        "baseUnitUUID",
    ]);
    const handleSwitchDetail = (unitUUID: string): void => {
        setState({...initState, unitId: unitUUID});
    };
    const handleChange = (unit: UnitCreateDTO): void => {
        setState({...state, unitEdit: unit});
    };
    const handleSubmit = (): void => {
        if (!isNotChanged) {
            setState({...state, name: "saving", action: "edit"});
        }
    };
    const handleDelete = (): void => {
        setState({...state, name: "deleting", action: "delete"});
    };
    const handleDeleteDone = (): void => {
        setState({...state, name: "deleted", action: "none"});
    };
    const handleDeleteCancel = (): void => {
        setState({...state, name: "loaded", action: "none"});
    };
    const handleDeleteFailed = (): void => {
        setState({...state, name: "failed"});
    };
    //== Render ===============================================================
    let appendix: JSX.Element | null = null;
    if (state.name === "loading") {
        return <Loading />;
    }
    if (state.name === "failed") {
        if (state.action === "none") {
            return <NotFound />;
        }
        appendix = <ErrorNotification message={t(`definitions.units.notifications.${state.action}_fail`)} />;
    }
    if (state.name === "saved") {
        appendix = <SuccessNotification message={t(`definitions.units.notifications.edit_ok`)} />;
    }
    if (state.name === "deleted") {
        return <Redirect to={ROUTES.units.path} />;
    }
    const alts = state.unit?.alternativeUnits ?? [];
    if (state.unit !== null) {
        const unit: UnitDTO = state.unit;
        return (
            <Container maxWidth="md">
                <Box style={{clear: "both"}}>
                    <Box style={{float: "left"}}>
                        <h1>
                            {unit.name} [{unit.abbreviation}]
                        </h1>
                    </Box>
                    <Box style={{float: "right"}}>
                        <BackLinkActionButton to={ROUTES.units.path} />
                        {unit.baseUnit?.id !== null && (
                            <CustomActionButton
                                onClick={(): void => {
                                    handleSwitchDetail(unit.baseUnit?.id ?? "");
                                }}
                                title={t("definitions.units.go_to_base")}
                                icon={<ArrowUpwardIcon />}
                            />
                        )}
                        <DeleteActionButton onClick={handleDelete} />
                    </Box>
                </Box>
                <UnitForm
                    unit={state.unitEdit}
                    unitUUID={state.unit.id}
                    onChange={handleChange}
                    onSubmit={handleSubmit}
                />
                {alts.length > 0 && (
                    <Box>
                        <h2>{t("definitions.units.alternative_units")}</h2>
                        <UnitDetailTable units={alts} switchDetail={handleSwitchDetail} />
                    </Box>
                )}
                <UnitDelete
                    entity={state.name === "deleting" ? state.unit : null}
                    onDeleted={handleDeleteDone}
                    onCancel={handleDeleteCancel}
                    onFailed={handleDeleteFailed}
                />
                {appendix}
            </Container>
        );
    }
    return <CommunicationError />;
};

export default UnitDetail;
