import React from "react";
import {useKeycloak} from "@react-keycloak/web";
import {Redirect} from "react-router-dom";
import {useTranslation} from "react-i18next";
// Material UI imports
import {Container} from "@material-ui/core";
// Local imports
import InputVariableForm from "./InputVariableForm";
// Project imports
import {LoadingBackdrop} from "../../common/Loading";
import {ErrorNotification} from "../../common/notifications";
import {InputVariableCreateDTO, InputVariableDTO} from "../../../models/library";
import ROUTES from "../../../routes/routes";
import {API, createApiConfig} from "../../../utils";
import {AxiosError} from "axios";

interface InputVariableNewState {
    inputVariable: InputVariableDTO | null;
    inputVariableCreate: InputVariableCreateDTO;
    name: string;
}

export const InputVariableNew: React.FC = () => {
    //== Init =================================================================
    const [t] = useTranslation("libraries");
    const {keycloak, initialized} = useKeycloak();
    const initState: InputVariableNewState = {
        inputVariable: null,
        inputVariableCreate: {
            name: "",
            type: "qualitative",
            description: "",
            definition: "",
            preferredUnitUUID: null,
            minimumValueUpdate: null,
            maximumValueUpdate: null,
            tags: [],
        },
        name: "init",
    };
    const [state, setState] = React.useState(initState);
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "saving") {
            API.post<InputVariableDTO>(
                `/input-variables`,
                state.inputVariableCreate,
                createApiConfig(keycloak, initialized)
            )
                .then((res) => {
                    setState({...state, inputVariable: res.data, name: "saved"});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [keycloak, initialized, state, setState]);
    //== Handlers =============================================================
    const handleChange = (inputVariable: InputVariableCreateDTO): void => {
        setState({...state, inputVariableCreate: inputVariable});
    };
    const handleSubmit = (): void => {
        setState({...state, name: "saving"});
    };
    //== Render ===============================================================
    let appendix: JSX.Element | null = null;
    if (state.name === "saving") {
        appendix = <LoadingBackdrop />;
    }
    if (state.name === "failed") {
        appendix = <ErrorNotification message={t("definitions.input_variables.notifications.create_fail")} />;
    }
    if (state.name === "saved") {
        return <Redirect to={ROUTES.inputVariable.create(state.inputVariable?.id ?? "unknown")} />;
    }
    return (
        <Container maxWidth="md">
            <h1>{t("definitions.input_variables.new")}</h1>
            <Container maxWidth="md">
                <InputVariableForm
                    inputVariable={state.inputVariableCreate}
                    qualitativeInfo={t("definitions.input_variables.qualitative.new")}
                    onChange={handleChange}
                    onSubmit={handleSubmit}
                />
            </Container>
            {appendix}
        </Container>
    );
};

export default InputVariableNew;
