import React from "react";
import {LibraryDomainUseDTO, LibraryDTO, LibraryGoalUseDTO, LibraryIndicatorUseDTO} from "../../../models/library";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";
import {ExpandMoreActionButton, InfoActionButton} from "../../common/buttons";
import {IndicatorDialog} from "../../values/dialogs/IndicatorDialog";

interface LibraryIndicatorBrowserProps {
    library: LibraryDTO;
    libraryDomainUse: LibraryDomainUseDTO;
    libraryGoalUse: LibraryGoalUseDTO;
    libraryIndicatorUse: LibraryIndicatorUseDTO;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        indicatorRow: {
            borderWidth: 0,
            borderStyle: "solid",
            borderBottomWidth: 1,
            borderColor: "rgb(0,125,180)",
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(0.5),
            paddingLeft: theme.spacing(1),
            marginLeft: theme.spacing(4),
            display: "flex",
            alignItems: "center",
        },
        indicatorItemName: {
            marginLeft: theme.spacing(0.5),
            fontSize: "140%",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
        },
        infoButton: {
            flexGrow: 1,
        },
    })
);

export const LibraryIndicatorBrowser: React.FC<LibraryIndicatorBrowserProps> = (
    props: LibraryIndicatorBrowserProps
) => {
    //== Init =================================================================
    const classes = useStyles();
    const [infoOpen, setInfoOpen] = React.useState(false);
    //== Handlers =============================================================
    const handleInfoOpen = () => {
        setInfoOpen(true);
    };
    const handleInfoClose = () => {
        setInfoOpen(false);
    };
    //== Render ===============================================================
    return (
        <Box>
            <Box className={classes.indicatorRow}>
                <Box style={{visibility: "hidden"}}>
                    {
                        <ExpandMoreActionButton
                            onClick={() => {
                                /*do nothing*/
                            }}
                        />
                    }
                </Box>
                <Box className={classes.indicatorItemName}>{props.libraryIndicatorUse.indicator.name}</Box>
                <Box className={classes.infoButton}>
                    <InfoActionButton onClick={handleInfoOpen} />
                </Box>
                <IndicatorDialog
                    indicator={props.libraryIndicatorUse.indicator}
                    handleClose={handleInfoClose}
                    open={infoOpen}
                />
            </Box>
        </Box>
    );
};
