import {ReactChild, ReactPortal} from "react";
import {ManualSubsection} from "./ManualSubsection";
import {ManualChapter} from "./ManualChapter";

export interface ManualSection {
    key: string;
    title: string;
    intro?: ReactChild | ReactPortal;
    index?: number;
    chapter?: ManualChapter;
    subsections: Map<string, ManualSubsection>;
}

interface ManualSectionProps {
    key: string;
    title: string;
    intro?: ReactChild | ReactPortal;
    subsections?: ManualSubsection[];
}

export const manualSection = (props: ManualSectionProps): ManualSection => {
    const section = {
        key: props.key,
        title: props.title,
        intro: props.intro || undefined,
        subsections: new Map(),
    };
    section.subsections = new Map(
        (props.subsections || []).map((subsection, index) => {
            subsection.index = index + 1;
            subsection.section = section;
            return [subsection.key, subsection];
        })
    );
    return section;
};

export default manualSection;
