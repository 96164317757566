import React from "react";
import {
    EMPTY_CONST_NODE,
    FormulaNode,
    getDefaultOperands,
    getDefaultOperationValue,
} from "../../../../../models/formulaEditor";
import {makeStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";
import {OperationType} from "../../../../../models/formulas";
import {ConversionConstForm} from "./ConversionConstForm";
import {ConversionOperationSelect} from "./ConversionOperationSelect";
import {ConversionBaseVarForm} from "./ConversionBaseVarForm";
import {useTranslation} from "react-i18next";

interface ConversionNodeFormProps {
    index: number;
    node: FormulaNode;
    onChange: (index: number, node: FormulaNode) => void;
}

const useStyles = makeStyles((theme) => ({
    formRow: {
        marginLeft: "1em",
        marginTop: "0.4em",
    },
}));

export const ConversionNodeForm: React.FC<ConversionNodeFormProps> = (props: ConversionNodeFormProps) => {
    //== Init =================================================================
    const classes = useStyles();
    const [t] = useTranslation("libraries");
    //== Handlers =============================================================
    const handleOperationChange = (operation: OperationType) => {
        const defaultOperands = getDefaultOperands(operation);
        props.onChange(props.index, {
            ...props.node,
            opType: operation,
            value: getDefaultOperationValue(operation),
            operands: [...props.node.operands, ...defaultOperands].slice(0, defaultOperands.length),
        });
    };
    const handleChildChange = (index: number, childNode: FormulaNode) => {
        const children = [...props.node.operands];
        children[index] = childNode;
        if (childNode.opType === OperationType.VAR && childNode.value !== "X") {
            children[index].value = "X";
        }
        props.onChange(props.index, {
            ...props.node,
            operands: children,
        });
    };
    const handleNodeChange = (node: FormulaNode) => {
        props.onChange(props.index, node);
    };
    //== Render ===============================================================
    if (props.node.type === "empty" || props.node.opType === undefined) {
        props.onChange(props.index, EMPTY_CONST_NODE);
        return null;
    } else if (props.node.type === "invalid") {
        return <Box className={classes.formRow}>{t("formula.error.invalid")}</Box>;
    } else if (props.node.opType === OperationType.CONST) {
        return (
            <Box className={classes.formRow}>
                <ConversionConstForm node={props.node} onChange={handleNodeChange} />
            </Box>
        );
    } else if (props.node.opType === OperationType.VAR) {
        return (
            <Box className={classes.formRow}>
                <ConversionBaseVarForm node={props.node} onChange={handleNodeChange} />
            </Box>
        );
    } else {
        return (
            <Box className={classes.formRow}>
                <ConversionOperationSelect value={props.node.opType} onChange={handleOperationChange} />
                {props.node.operands.map((o, i) => (
                    <ConversionNodeForm key={i} index={i} node={o} onChange={handleChildChange} />
                ))}
            </Box>
        );
    }
};
