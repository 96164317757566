import React, {CSSProperties} from "react";
import MUIRichTextEditor from "mui-rte";
import {useTranslation} from "react-i18next";
// Material-UI imports
import {Box, Switch, TextField} from "@material-ui/core";
import {DateUtils} from "../../utils";
import {convertToRaw, EditorState} from "draft-js";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {CommunicationError, NotFound} from "./errors";

interface SinceUntilPickerProps {
    since: string | undefined;
    until: string | undefined;

    onChange(since: string | undefined, until: string | undefined): void;
}

export const SinceUntilPicker: React.FC<SinceUntilPickerProps> = (props: SinceUntilPickerProps) => {
    //== Init =================================================================
    const [t] = useTranslation(["values"]);
    const defaultSince = DateUtils.firstOfYearDateString();
    const defaultUntil = DateUtils.todayDateString();
    const valueSince = props.since ?? defaultSince;
    const valueUntil = props.until ?? defaultUntil;
    const isActive = props.since !== undefined || props.until !== undefined;
    const error: boolean =
        props.since !== undefined && props.until !== undefined && Date.parse(valueSince) > Date.parse(valueUntil);
    //== Handlers =============================================================
    const handleSwitch = (enabled: boolean): void => {
        console.log("[SinceUntilPicker] handleSwitch - enabled:");
        console.log(enabled);
        props.onChange(enabled ? valueSince : undefined, enabled ? valueUntil : undefined);
    };
    const handleSinceChange = (since: string): void => {
        props.onChange(since, props.until);
    };
    const handleUntilChange = (until: string): void => {
        props.onChange(props.since, until);
    };
    //== Render ===============================================================
    console.log("[SinceUntilPicker] valueSince:");
    console.log(valueSince);
    console.log("[SinceUntilPicker] valueUntil:");
    console.log(valueUntil);
    console.log("[SinceUntilPicker] isActive:");
    console.log(isActive);
    return (
        <Box>
            <Box display="flex" justifyContent="center" alignItems="center" style={{marginBottom: "1em"}}>
                <Box style={isActive ? {fontWeight: "bold", color: "gray"} : {fontWeight: "bold"}}>
                    {t("ui.allDates")}
                </Box>
                <Box>
                    <Switch
                        checked={isActive}
                        onChange={(e): void => {
                            handleSwitch(e.target.checked);
                        }}
                    />
                </Box>
                <Box style={isActive ? {fontWeight: "bold"} : {fontWeight: "bold", color: "gray"}}>
                    {t("ui.customDateRange")}
                </Box>
            </Box>
            <Box display="flex" alignItems="center" visibility={isActive ? "visible" : "hidden"}>
                <TextField
                    name="since"
                    label={t("indicator.since")}
                    type="date"
                    variant="outlined"
                    value={valueSince}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e): void => {
                        handleSinceChange(e.target.value);
                    }}
                    error={error}
                    style={{marginRight: "1em"}}
                />
                <TextField
                    name="until"
                    label={t("indicator.until")}
                    type="date"
                    variant="outlined"
                    value={valueUntil}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e): void => {
                        handleUntilChange(e.target.value);
                    }}
                    error={error}
                />
            </Box>
        </Box>
    );
};

const useDescriptionMdFieldStyles = makeStyles((theme: Theme) =>
    createStyles({
        editor: {
            border: 1,
            borderRadius: 4,
            borderStyle: "solid",
            borderColor: "rgba(0,0,0,0.23)",
            padding: "1px 11px 1px 11px",
            "&:hover": {
                borderColor: "black",
            },
            "& #mui-rte-container div": {
                position: "inherit",
            },
            "& .public-DraftEditor-content": {
                minHeight: "6em",
            },
        },
        editorFocused: {
            border: 2,
            borderRadius: 4,
            borderStyle: "solid",
            borderColor: theme.palette.primary.main,
            padding: "0px 10px 0px 10px",
            "& .public-DraftEditor-content": {
                minHeight: "6em",
            },
        },
    })
);

const cmsControls: string[] = [
    "title",
    "bold",
    "italic",
    "underline",
    "link",
    "media",
    "numberList",
    "bulletList",
    "quote",
    "undo",
    "redo",
];

interface CMSPageRTEProps {
    value: string;
    label: string;
    className?: string;
    style?: CSSProperties;
    onChange: (value: string) => void;
}

interface CMSPageRTEState {
    content: string;
    focused: boolean;
}

export const CMSPageRTE: React.FC<CMSPageRTEProps> = (props: CMSPageRTEProps) => {
    // TODO: add label so it is visible even when value is filled
    // TODO: avoid stealing focus by onBlur event
    //== Init =================================================================
    const classes = useDescriptionMdFieldStyles();
    const initState: CMSPageRTEState = {
        content: props.value,
        focused: false,
    };
    const [state, setState] = React.useState(initState);
    //== Handlers =============================================================
    const handleChange = (editorState: EditorState): void => {
        props.onChange(JSON.stringify(convertToRaw(editorState.getCurrentContent())));
    };
    //== Render ===============================================================
    return (
        <Box className={props.className} style={props.style}>
            <Box className={state.focused ? classes.editorFocused : classes.editor}>
                <MUIRichTextEditor
                    label={props.label}
                    value={state.content}
                    controls={cmsControls}
                    onChange={handleChange}
                    onFocus={() => setState({...state, focused: true})}
                    onBlur={() => setState({...state, focused: false})}
                />
            </Box>
        </Box>
    );
};

interface CMSPageRendererProps {
    value: string;
    className?: string;
    style?: CSSProperties;
}

export const CMSPageRenderer: React.FC<CMSPageRendererProps> = (props: CMSPageRendererProps) => {
    if (props.value === "") {
        return <NotFound />;
    }
    try {
        const x = JSON.parse(props.value);
        return (
            <Box className={props.className} style={props.style}>
                <MUIRichTextEditor readOnly value={JSON.stringify(x)} controls={[]} toolbar={false} />
            </Box>
        );
    } catch (e) {
        console.log(e);
        return <CommunicationError />;
    }
};
