import React from "react";
// Material-UI imports
import {Button, FormControl, TextField} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {TagsInput} from "../common/tags";
import {TagDTO} from "../../models/admin";
import {OrganizationInput} from "../common/organizations";
import {OrganizationDTO} from "../../models/organization";
// Project imports
import {LibraryCreateDTO} from "../../models/library";
import {MarkdownTextField} from "../common/markdown";

interface LibraryFormProps {
    library: LibraryCreateDTO;
    allowOrganization?: boolean;
    organization?: OrganizationDTO;

    onChange(library: LibraryCreateDTO, organization: OrganizationDTO | null): void;

    onSubmit(): void;
}

interface LibraryFormState {
    organization: OrganizationDTO | null;
}

export const LibraryForm: React.FC<LibraryFormProps> = (props: LibraryFormProps) => {
    const [t] = useTranslation("libraries");
    const initState: LibraryFormState = {organization: props.organization ?? null};
    const [state, setState] = React.useState(initState);
    return (
        <FormControl fullWidth>
            <TextField
                label={t("library.name")}
                value={props.library.name}
                onChange={(e): void => {
                    props.onChange({...props.library, name: e.target.value}, state.organization);
                }}
                fullWidth
                required
                variant="outlined"
                margin="normal"
            />
            <TextField
                label={t("library.version")}
                value={props.library.version}
                onChange={(e): void => {
                    props.onChange({...props.library, version: e.target.value}, state.organization);
                }}
                fullWidth
                required
                variant="outlined"
                margin="normal"
            />
            <TextField
                label={t("library.license")}
                value={props.library.license ?? ""}
                onChange={(e): void => {
                    props.onChange({...props.library, license: e.target.value}, state.organization);
                }}
                fullWidth
                required
                variant="outlined"
                margin="normal"
            />
            {props.allowOrganization === true && (
                <OrganizationInput
                    query="own"
                    label={t("library.organization")}
                    value={state.organization}
                    onChange={(organization): void => {
                        setState({...state, organization: organization});
                        props.onChange(
                            {
                                ...props.library,
                                organizationUUID: organization.id ?? "",
                            },
                            organization
                        );
                    }}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                />
            )}
            <TagsInput
                label={t("library.tags")}
                value={props.library.tags}
                onChange={(tags: TagDTO[]): void => {
                    props.onChange({...props.library, tags: tags}, state.organization);
                }}
                fullWidth
                variant="outlined"
                margin="normal"
            />
            <MarkdownTextField
                label={t("library.description")}
                value={props.library.description ?? ""}
                onChange={(value) => {
                    props.onChange({...props.library, description: value}, state.organization);
                }}
                margin="normal"
            />
            <Button onClick={props.onSubmit} variant="contained" color="primary">
                {t("admin.save")}
            </Button>
        </FormControl>
    );
};
