import React from "react";
import {Container, LinearProgress} from "@material-ui/core";
import {useParams} from "react-router-dom";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
import {InputValuesHistoryDTO} from "../../../models/values";
import {API, createApiConfig} from "../../../utils/API";
import {AxiosError, AxiosResponse} from "axios";
import {BreadcrumbItem, BreadcrumbsRow} from "../../common/breadcrumbs";
import {ROUTES} from "../../../routes/routes";
import {PageHeader} from "../../common/headers";
import {LinkActionButton, RefreshActionButton} from "../../common/buttons";
import BusinessIcon from "@material-ui/icons/Business";
import TimelineIcon from "@material-ui/icons/Timeline";
import {CommunicationError, NotFound} from "../../common/errors";
import KeyboardIcon from "@material-ui/icons/Keyboard";
import {InputValuesSummaryTable} from "./InputValuesSummaryTable";
import {DateUtils} from "../../../utils";

interface InputValuesSummaryParams {
    organizationId: string;
}

interface InputValuesSummaryState {
    name: "loading" | "loaded" | "failed" | "not_found";
    data: InputValuesHistoryDTO | null;
    desiredDate: Date;
}

export const InputValuesSummary: React.FC = () => {
    //== Init =================================================================
    const {organizationId} = useParams<InputValuesSummaryParams>();
    const {keycloak, initialized} = useKeycloak();
    const [t] = useTranslation(["values"]);
    const initState: InputValuesSummaryState = {
        name: "loading",
        data: null,
        desiredDate: new Date(),
    };
    const [state, setState] = React.useState(initState);
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            const dateParam = `referenceDate=${DateUtils.toDateISOString(state.desiredDate)}`;
            API.get<InputValuesHistoryDTO>(
                `/values-input/all/${organizationId}?${dateParam}`,
                createApiConfig(keycloak, initialized)
            )
                .then((res: AxiosResponse<InputValuesHistoryDTO>) => {
                    setState({...state, data: res.data, name: "loaded"});
                })
                .catch((err: AxiosError) => {
                    if (err.response?.status === 404) {
                        setState({...state, name: "not_found"});
                    } else {
                        setState({...state, name: "failed"});
                    }
                });
        }
    }, [state.name, organizationId]);
    //== Handlers =============================================================
    const handleRefresh = () => {
        setState({...state, name: "loading"});
    };
    const handleDateChange = (desiredDate: Date) => {
        setState({...state, name: "loading", desiredDate: desiredDate});
    };
    //== Render ===============================================================
    return (
        <Container maxWidth="lg">
            <BreadcrumbsRow>
                <BreadcrumbItem
                    name={state.data?.municipality?.name || t("organization:organization.municipality")}
                    route={ROUTES.organization.create(organizationId)}
                />
                <BreadcrumbItem name={t("actions.valuesInput")} route={ROUTES.valuesInput.create(organizationId)} />
                <BreadcrumbItem name={t("values_input.summary.title")} />
            </BreadcrumbsRow>
            <PageHeader title={t("values_input.summary.title")}>
                <LinkActionButton
                    to={ROUTES.organization.create(organizationId)}
                    icon={<BusinessIcon />}
                    title={t("actions.organization")}
                />
                <LinkActionButton
                    title={t("actions.trackedIndicatorsBrowser")}
                    to={ROUTES.trackedIndicatorGroups.create(organizationId)}
                    icon={<TimelineIcon />}
                />
                <LinkActionButton
                    to={ROUTES.valuesInput.create(organizationId)}
                    icon={<KeyboardIcon />}
                    title={t("actions.valuesInput")}
                />
                <RefreshActionButton onClick={handleRefresh} />
            </PageHeader>
            {state.name === "loading" && (
                <Container maxWidth="sm" style={{textAlign: "center"}}>
                    <h2>{t("values_input.summary.loading")}</h2>
                    <LinearProgress style={{margin: "1em", height: "1em"}} />
                </Container>
            )}
            {state.name === "not_found" && <NotFound />}
            {state.name === "failed" && <CommunicationError />}
            {state.name === "loaded" && state.data !== null && (
                <InputValuesSummaryTable
                    items={state.data.items}
                    desiredDate={state.desiredDate}
                    onDateChange={handleDateChange}
                />
            )}
        </Container>
    );
};
