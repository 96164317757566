import React from "react";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
// Material UI imports
import {Box, Button, DialogActions, DialogContent} from "@material-ui/core";
// Project imports
import {ServerCommunicationAlert} from "../../../../common/errors";
import {Loading} from "../../../../common/Loading";
import API, {createApiConfig} from "../../../../../utils/API";
import {AxiosError} from "axios";
import Pagination from "@material-ui/lab/Pagination";
import {LibraryDTO} from "../../../../../models/library";
import TrackedIndicatorGroupEditorAddFromLibrary from "./TrackedIndicatorGroupEditorAddFromLibrary";
import {fromLibraryUseToAddingDomains} from "./TrackedIndicatorGroupEditorAddingInterfaces";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {TrackedIndicatorGroupEditorAddDialogProps} from "./TrackedIndicatorGroupEditorAddProps";
import {Page} from "../../../../../models/pagination";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        libraries: {
            display: "table",
        },
        row: {
            display: "table-row",
        },
        name: {
            display: "table-cell",
        },
        buttons: {
            display: "table-cell",
        },
        button: {
            display: "inline-block",
        },
    })
);

const TrackedIndicatorGroupEditorAddFromLibrariesContent: React.FC<TrackedIndicatorGroupEditorAddDialogProps> = (
    props: TrackedIndicatorGroupEditorAddDialogProps
) => {
    const [t] = useTranslation(["trackedIndicators", "common"]);
    const {keycloak, initialized} = useKeycloak();
    const initState = {
        page: {
            size: 50,
            totalElements: 0,
            totalPages: 0,
            number: 0,
        },
        name: "loading" as "loading" | "loaded" | "closed" | "failed",
        libraries: [] as LibraryDTO[],
        selectedLibrary: null as LibraryDTO | null,
    };
    const classes = useStyles();
    const [state, setState] = React.useState(initState);
    const domainUsesInGroup = props.trackedIndicatorGroup.trackedDomains;
    const domainIdsInGroup = new Set(domainUsesInGroup.map((domainUseInGroup) => domainUseInGroup.domain.id));
    const goalUsesInGroup = domainUsesInGroup.flatMap((domainUseInGroup) => domainUseInGroup.trackedGoals);
    const goalIdsInGroup = new Set(goalUsesInGroup.map((goalUseInGroup) => goalUseInGroup.goal.id));
    const indicatorUsesInGroup = goalUsesInGroup.flatMap((goalUseInGroup) => goalUseInGroup.trackedIndicators);
    const indicatorIdsInGroup = new Set(
        indicatorUsesInGroup.map((indicatorUseInGroup) => indicatorUseInGroup.indicator.id)
    );
    //== Effects ================================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            if (state.libraries.length > 0) {
                setState({...state, name: "loaded"});
            } else {
                const urlType =
                    props.type === "public"
                        ? "public"
                        : `of-organization/${props.trackedIndicatorGroup.organization.id}`;
                API.get<Page<LibraryDTO>>(
                    `/libraries/${urlType}/detailed?page=${state.page.number}&size=${state.page.size}`,
                    createApiConfig(keycloak, initialized)
                )
                    .then((res) => {
                        setState({
                            ...state,
                            page: res.data,
                            libraries: res.data?.content || [],
                            name: "loaded",
                        });
                    })
                    .catch((err: AxiosError) => {
                        setState({...state, name: "failed"});
                    });
            }
        }
    }, [keycloak, initialized, state, setState, props]);
    //== Handlers ===============================================================
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number): void => {
        setState({
            ...state,
            page: {...state.page, number: value - 1},
            name: "loading",
        });
    };
    const handleBackToLibraries = () => {
        setState({...state, selectedLibrary: null});
    };
    const handleSelect = (library: LibraryDTO) => {
        setState({...state, selectedLibrary: library});
    };
    //== Render =================================================================
    const notification: JSX.Element | null = null;
    const actions = (
        <DialogActions>
            <Button variant="outlined" color="secondary" onClick={props.onCancel}>
                {t("common:actions.close")}
            </Button>
        </DialogActions>
    );
    if (state.name === "failed")
        return (
            <>
                <ServerCommunicationAlert />
                {actions}
            </>
        );
    if (state.name === "closed") {
        return null;
    }
    if (state.name === "loading")
        return (
            <>
                <DialogContent>
                    <Loading />
                </DialogContent>
                {actions}
            </>
        );
    if (state.selectedLibrary)
        return (
            <TrackedIndicatorGroupEditorAddFromLibrary
                {...props}
                onCancel={handleBackToLibraries}
                library={state.selectedLibrary}
                domainIdsInGroup={domainIdsInGroup}
                goalIdsInGroup={goalIdsInGroup}
                indicatorIdsInGroup={indicatorIdsInGroup}
            />
        );
    return (
        <>
            <DialogContent>
                <Box className={classes.libraries}>
                    {state.libraries.length === 0 && <p>{t(`library.empty.${props.type}`)}</p>}
                    {state.libraries.map((library: LibraryDTO) => (
                        <Box className={classes.row} key={library.id}>
                            <Box className={classes.name}>{library.name}</Box>
                            <Box className={classes.buttons}>
                                <Button
                                    className={classes.button}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        props.onAdd(fromLibraryUseToAddingDomains(library));
                                    }}
                                    style={{margin: "0.5em"}}
                                >
                                    {t("actions.addAll")}
                                </Button>
                                <Button
                                    className={classes.button}
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {
                                        handleSelect(library);
                                    }}
                                    style={{margin: "0.5em"}}
                                >
                                    {t("actions.pickItems")}
                                </Button>
                            </Box>
                        </Box>
                    ))}
                </Box>
                {state.page.totalPages > 1 && (
                    <Pagination
                        style={{margin: "auto", marginTop: "1em"}}
                        count={state.page.totalPages}
                        page={state.page.number + 1}
                        onChange={handlePageChange}
                    />
                )}
                {notification}
            </DialogContent>
            {actions}
        </>
    );
};

export default TrackedIndicatorGroupEditorAddFromLibrariesContent;
