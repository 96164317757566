import React from "react";
import {CommunicationError, ErrorPage, NotAuthorized, NotFound} from "../common/errors";
import {useParams} from "react-router-dom";
import {LibrarySimpleDTO} from "../../models/library";
import {LoadingBackdrop} from "../common/Loading";
import {API, createApiConfig} from "../../utils";
import {AxiosError} from "axios";
import {useKeycloak} from "@react-keycloak/web";
import {Box, Container} from "@material-ui/core";
import {BreadcrumbItem, BreadcrumbsRow} from "../common/breadcrumbs";
import {PageHeader, PrimaryPageAction} from "../common/headers";
import {hasUserRole, ROLES} from "../../utils/auth";
import ROUTES from "../../routes/routes";
import AddIcon from "@material-ui/icons/Add";
import {LibrariesList} from "./list/LibrariesList";
import {OrganizationDTO} from "../../models/organization";
import {EditLinkActionButton, EditorLinkActionButton, InfoActionButton, VisitLinkActionButton} from "../common/buttons";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import EmptySVG from "../../graphics/undraw/empty_xct9.svg";
import {LibraryDialog} from "../values/dialogs/LibraryDialog";

const LibrariesEmpty: React.FC = () => {
    //== Init =================================================================
    const [t] = useTranslation("libraries");
    //== Render ===============================================================
    return (
        <ErrorPage
            title={t("library.empty.org.title")}
            description={t("library.empty.org.description")}
            image={EmptySVG}
        />
    );
};

interface LibrariesOrgItemProps {
    library: LibrarySimpleDTO;
    canEdit: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        libraryRow: {
            borderWidth: 0,
            borderStyle: "solid",
            borderBottomWidth: 2,
            borderColor: theme.palette.primary.main,
            padding: theme.spacing(1),
            display: "flex",
            alignItems: "center",
        },
        libraryTitle: {
            fontSize: "125%",
            fontWeight: "bold",
        },
    })
);

const LibrariesOrgItem: React.FC<LibrariesOrgItemProps> = (props: LibrariesOrgItemProps) => {
    //== Init =================================================================
    const classes = useStyles();
    const [infoOpen, setInfoOpen] = React.useState(false);
    //== Render ===============================================================
    return (
        <Box className={classes.libraryRow}>
            <LibraryDialog library={props.library} open={infoOpen} handleClose={() => setInfoOpen(false)} />
            <Box flexGrow="1">
                <Box className={classes.libraryTitle}>{props.library.name}</Box>
            </Box>
            {props.canEdit && (
                <Box>
                    <InfoActionButton onClick={() => setInfoOpen(true)} />
                    <VisitLinkActionButton to={ROUTES.library.create(props.library.id)} />
                    <EditorLinkActionButton to={ROUTES.libraryEditor.create(props.library.id)} />
                    <EditLinkActionButton to={ROUTES.libraryEdit.create(props.library.id)} />
                </Box>
            )}
        </Box>
    );
};

interface LibrariesOrgParams {
    organizationId: string;
}

interface LibrariesOrgState {
    libraries: LibrarySimpleDTO[];
    organization: OrganizationDTO | null;
    name: "loading" | "loaded" | "failed" | "not_found" | "unauthorized";
}

export const LibrariesOrg: React.FC = () => {
    // TODO: privileges
    //== Init =================================================================
    const [t] = useTranslation("libraries");
    const {organizationId} = useParams<LibrariesOrgParams>();
    const {keycloak, initialized} = useKeycloak();
    const initState: LibrariesOrgState = {
        libraries: [],
        organization: null,
        name: "loading",
    };
    const [state, setState] = React.useState(initState);
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<OrganizationDTO>(`/organizations/${organizationId}`, createApiConfig(keycloak, initialized))
                .then((res1) => {
                    API.get<LibrarySimpleDTO[]>(
                        `/libraries/of-organization/${organizationId}`,
                        createApiConfig(keycloak, initialized)
                    )
                        .then((res2) => {
                            setState({...state, organization: res1.data, libraries: res2.data, name: "loaded"});
                        })
                        .catch((err: AxiosError) => {
                            if (err?.response?.status === 404) {
                                setState({...state, name: "not_found"});
                            } else if (err?.response?.status === 403 || err?.response?.status === 401) {
                                setState({...state, name: "unauthorized"});
                            } else {
                                setState({...state, name: "failed"});
                            }
                        });
                })
                .catch((err: AxiosError) => {
                    if (err?.response?.status === 404) {
                        setState({...state, name: "not_found"});
                    } else if (err?.response?.status === 403 || err?.response?.status === 401) {
                        setState({...state, name: "unauthorized"});
                    } else {
                        setState({...state, name: "failed"});
                    }
                });
        }
    }, [state, setState, organizationId, keycloak, initialized]);
    //== Render ===============================================================
    if (state.name === "loading") {
        return <LoadingBackdrop />;
    }
    if (state.name === "not_found") {
        return <NotFound />;
    }
    if (state.name === "unauthorized") {
        return <NotAuthorized />;
    }
    if (state.name === "failed") {
        return <CommunicationError />;
    }
    if (state.organization === null) {
        return <LoadingBackdrop />;
    }
    return (
        <Container>
            <BreadcrumbsRow>
                <BreadcrumbItem
                    name={state.organization.name}
                    route={ROUTES.organization.create(state.organization.publicId)}
                />
                <BreadcrumbItem name={t("library.titles.libraries")} />
            </BreadcrumbsRow>
            <PageHeader title={t("library.titles.libraries")}>
                {/* TODO: check privileges */}
                {hasUserRole(keycloak, ROLES.user) && (
                    <PrimaryPageAction
                        title={t("library.titles.new_library")}
                        to={ROUTES.organizationLibraryNew.create(state.organization.publicId)}
                    >
                        <AddIcon />
                    </PrimaryPageAction>
                )}
            </PageHeader>
            {state.libraries.length > 0 && (
                <LibrariesList libraries={state.libraries}>
                    {(lib) => <LibrariesOrgItem key={lib.id} library={lib} canEdit={true} />}
                </LibrariesList>
            )}
            {state.libraries.length === 0 && <LibrariesEmpty />}
        </Container>
    );
};
