import React, {useState} from "react";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
// Material UI imports
import {
    Box,
    Button,
    Container,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
// Project imports
import {
    CopyButton,
    DeleteActionButton,
    EditActionButton,
    OpenInfoActionButton,
    RefreshActionButton,
} from "../../common/buttons";
import {ErrorNotification, InfoNotification, SuccessNotification} from "../../common/notifications";
import {ServerCommunicationAlert} from "../../common/errors";
import {Loading} from "../../common/Loading";
import {OrganizationDTO} from "../../../models/organization";
import API, {createApiConfig} from "../../../utils/API";
import {AxiosError} from "axios";
import OrganizationProfileAdminLoader from "./OrganizationProfileAdminLoader";
import Utils from "../../../utils";
import {useStyles} from "../../../styles";
import {ConfirmationDialog} from "../../common/dialogs";
import {Page} from "../../../models/pagination";
import {BreadcrumbItem, BreadcrumbsRow} from "../../common/breadcrumbs";
import ROUTES from "../../../routes/routes";
import {PageHeader, PrimaryPageAction} from "../../common/headers";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import SyncIcon from "@material-ui/icons/Sync";
import {isDeveloper} from "../../../utils/auth";
import {DevelopersOnlyBlock} from "../../common/DevelopersOnlyBlock";
import {OrganizationDelete} from "./OrganizationDelete";

interface OrganizationRowProps {
    organization: OrganizationDTO;
    switchMainState: (statename: StateNames, organization: OrganizationDTO) => void;
}

interface OrganizationRowState {
    organization: OrganizationDTO;
    name: "init" | "deleting" | "deleted" | "failed";
    action: "init" | "delete";
}

const OrganizationRow: React.FC<OrganizationRowProps> = (props: OrganizationRowProps) => {
    //== Init ===================================================================
    const [t] = useTranslation(["organization", "common"]);
    const classes = useStyles();
    const [state, setState] = useState<OrganizationRowState>({
        organization: props.organization,
        name: "init",
        action: "init",
    });
    //== Handlers ===============================================================
    const handleDeleteFailed = (): void => {
        setState({...state, name: "failed", action: "delete"});
    };
    const handleDeleteDone = (): void => {
        setState({...state, name: "deleted", action: "delete"});
    };
    const handleDeleteCancel = (): void => {
        setState({...state, name: "init", action: "init"});
    };
    const handleDelete = (): void => {
        setState({...state, name: "deleting"});
    };
    //== Render =================================================================
    // - default
    let notification: JSX.Element | null = null;
    if (state.name === "failed") {
        const a = state.action;
        notification = <ErrorNotification message={t(`notifications.${a}_fail`)} />;
    }
    if (state.name === "deleted") {
        return (
            <TableRow className={classes.deletedRowOrCell} key={state.name}>
                <TableCell className={classes.deletedRowOrCell} colSpan={7}>
                    <SuccessNotification message={t(`notifications.delete_ok`)} />
                </TableCell>
            </TableRow>
        );
    }
    const handleInfo = (): void => {
        props.switchMainState("openinfo", state.organization);
    };
    const handleEdit = (): void => {
        props.switchMainState("editing", state.organization);
    };
    return (
        <TableRow key={state.organization.id}>
            <TableCell align="center">
                {state.organization.publicId}
                <CopyButton text={Utils.nullToEmpty(state.organization.publicId)} size="small" />
            </TableCell>
            <TableCell className={classes.clickable} onClick={handleInfo} align="center">
                {state.organization.name}
            </TableCell>
            <TableCell align="center">
                {state.organization.stateID}
                {state.organization.stateID ? <CopyButton text={state.organization.stateID} size="small" /> : null}
            </TableCell>
            <TableCell align="center">{t("type." + state.organization.type)}</TableCell>
            <TableCell align="center">
                {state.organization.municipality?.lau2}
                {state.organization.municipality?.lau2 && Utils.notEmpty(state.organization.municipality?.lau2) ? (
                    <CopyButton text={state.organization.municipality?.lau2} size="small" />
                ) : null}
            </TableCell>
            <TableCell align="center">
                {state.organization.municipality
                    ? i18n.exists("organization:county." + state.organization.municipality?.lau2.substring(0, 6))
                        ? t("county." + state.organization.municipality?.lau2.substring(0, 6))
                        : t("placeholders.unknown")
                    : null}
            </TableCell>
            <TableCell style={{whiteSpace: "nowrap"}} align="right">
                <OpenInfoActionButton onClick={handleInfo} />
                <EditActionButton onClick={handleEdit} />
                <DeleteActionButton onClick={handleDelete} />
                <OrganizationDelete
                    entity={state.name === "deleting" ? state.organization : null}
                    onDeleted={handleDeleteDone}
                    onCancel={handleDeleteCancel}
                    onFailed={handleDeleteFailed}
                />
                {notification}
            </TableCell>
        </TableRow>
    );
};

type StateNames =
    | "loading"
    | "loaded"
    | "failed"
    | "editing"
    | "openinfo"
    | "creatingFromWikiDialog"
    | "creatingFromWiki";

const Organizations: React.FC = () => {
    //== Init ===================================================================
    const [t] = useTranslation("organization");
    const {keycloak, initialized} = useKeycloak();
    const initState = {
        page: {
            size: 50,
            totalElements: 0,
            totalPages: 0,
            number: 0,
        },
        displayedQuery: "",
        filledQuery: "",
        organizations: [] as OrganizationDTO[],
        name: "loading" as StateNames,
        action: "init",
        openOrganization: null as OrganizationDTO | null,
    };
    const [state, setState] = React.useState(initState);
    //== Effects ================================================================
    React.useEffect(() => {
        if (state.name === "creatingFromWiki" && isDeveloper(keycloak)) {
            API.post<number>(`/municipalities-update`, "", createApiConfig(keycloak, initialized))
                .then((res) => {
                    console.log("Updated " + res.data);
                    setState({...state, name: "loaded"});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "loaded"});
                });
        }
        if (state.name === "loading") {
            let url = `/organizations?page=${state.displayedQuery == state.filledQuery ? state.page.number : 0}&size=${
                state.page.size
            }&sort=name,asc`;
            if (state.filledQuery !== "") {
                url += `&query=${state.filledQuery}`;
            }
            API.get<Page<OrganizationDTO>>(url, createApiConfig(keycloak, initialized))
                .then((res) => {
                    setState({
                        ...state,
                        page: res.data,
                        organizations: res.data?.content ?? [],
                        displayedQuery: state.filledQuery,
                        name: "loaded",
                    });
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [keycloak, initialized, state, setState]);
    //== Handlers ===============================================================
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number): void => {
        setState({
            ...state,
            page: {...state.page, number: value - 1},
            name: "loading",
            action: "init",
        });
    };
    const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setState({...state, filledQuery: event.target.value});
    };
    const handleSearchSubmit = (): void => {
        setState({...state, name: "loading"});
    };
    //== Render =================================================================
    if (state.name === "loading" || state.name === "creatingFromWiki") {
        return <Loading />;
    }
    if (state.name === "failed") {
        return <ServerCommunicationAlert />;
    }
    // - default
    let notification: JSX.Element | null = null;
    if (state.name === "loaded" && state.action === "refresh") {
        notification = <InfoNotification message={t("users.notifications.refresh_ok")} />;
    }
    if (state.name === "loaded" && state.action === "new") {
        notification = <SuccessNotification message={t("users.notifications.create_ok")} />;
    }
    if (state.openOrganization && (state.name === "editing" || state.name === "openinfo")) {
        return (
            <Container>
                <BreadcrumbsRow>
                    <BreadcrumbItem
                        name={t("organization:organization.organizations")}
                        onClick={(): void => setState({...state, name: "loading", action: "init"})}
                    />
                    <BreadcrumbItem
                        name={state.openOrganization.name}
                        route={ROUTES.organization.create(state.openOrganization.publicId)}
                    />
                </BreadcrumbsRow>
                <OrganizationProfileAdminLoader
                    organizationId={state.openOrganization.publicId}
                    edit={state.name === "editing"}
                />
            </Container>
        );
    } else if (state.name === "editing") {
        return (
            <Container>
                <BreadcrumbsRow>
                    <BreadcrumbItem
                        name={t("organization:organization.organizations")}
                        onClick={(): void => setState({...state, name: "loading", action: "init"})}
                    />
                    <BreadcrumbItem name={t("ui.newOrganization")} />
                </BreadcrumbsRow>
                <OrganizationProfileAdminLoader organizationId="" edit={true} />
            </Container>
        );
    } else {
        const rows = state.organizations.map((organization: OrganizationDTO) => (
            <OrganizationRow
                key={organization.id}
                organization={organization}
                switchMainState={(statename: StateNames, organization: OrganizationDTO): void => {
                    setState({
                        ...state,
                        name: statename,
                        openOrganization: organization,
                    });
                }}
            />
        ));
        return (
            <Container>
                <BreadcrumbsRow>
                    <BreadcrumbItem
                        name={t("organization:organization.organizations")}
                        route={ROUTES.organizations.create()}
                    />
                </BreadcrumbsRow>
                <PageHeader title={t("organization:organization.organizations")}>
                    <PrimaryPageAction
                        title={t("common:actions.create")}
                        onClick={() => setState({...state, name: "editing", openOrganization: null})}
                    >
                        <AddIcon />
                    </PrimaryPageAction>
                </PageHeader>
                <Box>
                    <Box display="flex" justifyContent="center">
                        <Box flexGrow={1}>
                            {state.page.totalPages > 1 && (
                                <Pagination
                                    style={{marginTop: "1em"}}
                                    count={state.page.totalPages}
                                    page={state.page.number + 1}
                                    onChange={handlePageChange}
                                />
                            )}
                        </Box>
                        <form onSubmit={handleSearchSubmit} style={{width: "auto"}}>
                            <Box display="flex" alignItems="center" justifyContent="center">
                                <TextField
                                    value={state.filledQuery}
                                    onChange={handleQueryChange}
                                    label={t("organization:organization.query")}
                                    margin="dense"
                                    variant="outlined"
                                    style={{minWidth: "20em", marginRight: "1em"}}
                                />
                                <IconButton onClick={handleSearchSubmit} size="small">
                                    <SearchIcon />
                                </IconButton>
                            </Box>
                        </form>
                    </Box>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">{t("organization.publicId")}</TableCell>
                                <TableCell align="center">{t("organization.name")}</TableCell>
                                <TableCell align="center">{t("organization.stateID")}</TableCell>
                                <TableCell align="center">{t("organization.type")}</TableCell>
                                <TableCell align="center">{t("organization.lau2")}</TableCell>
                                <TableCell align="center">{t("type.COUNTY")}</TableCell>
                                <TableCell align="right">
                                    <RefreshActionButton
                                        onClick={(): void => setState({...initState, action: "refresh"})}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{rows}</TableBody>
                    </Table>
                    <Box style={{margin: "1em"}}>
                        <DevelopersOnlyBlock>
                            <Box display="flex" justifyContent="flex-end">
                                <Button
                                    style={{margin: "1em"}}
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<SyncIcon />}
                                    onClick={(e) => {
                                        setState({...state, name: "creatingFromWikiDialog"});
                                    }}
                                >
                                    {t("dev.createWiki")}
                                </Button>
                                <ConfirmationDialog
                                    title={t("ui.adminOrganizations.createFromWikiConfirmDialog.title")}
                                    text={t("ui.adminOrganizations.createFromWikiConfirmDialog.text")}
                                    open={state.name === "creatingFromWikiDialog"}
                                    id="wiki-dialog"
                                    onAgree={() => setState({...state, name: "creatingFromWiki"})}
                                    onDisagree={() => setState({...state, name: "loaded"})}
                                />
                            </Box>
                        </DevelopersOnlyBlock>
                        {state.page.totalPages > 1 && (
                            <Pagination
                                style={{margin: "auto", marginTop: "1em"}}
                                count={state.page.totalPages}
                                page={state.page.number + 1}
                                onChange={handlePageChange}
                            />
                        )}
                    </Box>
                    {notification}
                </Box>
            </Container>
        );
    }
};

export default Organizations;
