import React from "react";
import {useKeycloak} from "@react-keycloak/web";
// Project imports
import {CommunicationError, ErrorPage} from "../common/errors";
import {LoadingBackdrop} from "../common/Loading";
import {LibrarySimpleDTO} from "../../models/library";
import ROUTES from "../../routes/routes";
import {API, createApiConfig} from "../../utils";
import {AxiosError} from "axios";
// Material-UI imports
import {Box, Container} from "@material-ui/core";
import {PageHeader, PrimaryPageAction, SecondaryPageAction} from "../common/headers";
import {hasUserRole, ROLES} from "../../utils/auth";
import AddIcon from "@material-ui/icons/Add";
import {LibrariesList} from "./list/LibrariesList";
import {BreadcrumbItem, BreadcrumbsRow} from "../common/breadcrumbs";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import BusinessIcon from "@material-ui/icons/Business";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import EmptySVG from "../../graphics/undraw/empty_xct9.svg";
import {InfoActionButton, VisitLinkActionButton} from "../common/buttons";
import {LibraryDialog} from "../values/dialogs/LibraryDialog";
import KeyboardIcon from "@material-ui/icons/Keyboard";

const LibrariesEmpty: React.FC = () => {
    //== Init =================================================================
    const [t] = useTranslation("libraries");
    //== Render ===============================================================
    return (
        <ErrorPage
            title={t("library.empty.public.title")}
            description={t("library.empty.public.description")}
            image={EmptySVG}
        />
    );
};

interface LibrariesPublicItemProps {
    library: LibrarySimpleDTO;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        libraryRow: {
            borderWidth: 0,
            borderStyle: "solid",
            borderBottomWidth: 2,
            borderColor: theme.palette.primary.main,
            padding: theme.spacing(1),
            display: "flex",
            alignItems: "center",
        },
        libraryTitle: {
            fontSize: "125%",
            fontWeight: "bold",
        },
        libraryOrg: {
            fontSize: "110%",
            display: "flex",
            alignItems: "center",
        },
        libraryLink: {
            marginLeft: theme.spacing(1),
            padding: theme.spacing(0.5),
            color: theme.palette.grey.A400,
            textDecoration: "none",
        },
    })
);

const LibrariesPublicItem: React.FC<LibrariesPublicItemProps> = (props: LibrariesPublicItemProps) => {
    //== Init =================================================================
    const classes = useStyles();
    const [infoOpen, setInfoOpen] = React.useState(false);
    //== Render ===============================================================
    return (
        <Box className={classes.libraryRow}>
            <LibraryDialog library={props.library} open={infoOpen} handleClose={() => setInfoOpen(false)} />
            <Box flexGrow="1">
                <Box className={classes.libraryTitle}>{props.library.name}</Box>
                <Box className={classes.libraryOrg}>
                    <BusinessIcon fontSize="small" />
                    <Link
                        className={classes.libraryLink}
                        to={ROUTES.organization.create(props.library.organization.publicId)}
                    >
                        {props.library.organization.name}
                    </Link>
                </Box>
            </Box>
            <Box>
                <InfoActionButton onClick={() => setInfoOpen(true)} />
                <VisitLinkActionButton to={ROUTES.library.create(props.library.id)} />
            </Box>
        </Box>
    );
};

interface LibrariesListState {
    libraries: LibrarySimpleDTO[];
    name: "loading" | "loaded" | "failed";
}

export const LibrariesPublic: React.FC = () => {
    //== Init ===================================================================
    const [t] = useTranslation("libraries");
    const [t2] = useTranslation("values");
    const {keycloak, initialized} = useKeycloak();
    const initState: LibrariesListState = {
        libraries: [],
        name: "loading",
    };
    const [state, setState] = React.useState(initState);
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<LibrarySimpleDTO[]>(`/libraries/public`, createApiConfig(keycloak, initialized))
                .then((res) => {
                    setState({...state, libraries: res.data, name: "loaded"});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [keycloak, initialized, state, setState]);
    //== Render =================================================================
    if (state.name === "loading") {
        return <LoadingBackdrop />;
    }
    if (state.name === "failed") {
        return <CommunicationError />;
    }
    return (
        <Container>
            <BreadcrumbsRow>
                <BreadcrumbItem name={t("library.titles.libraries")} />
            </BreadcrumbsRow>
            <PageHeader title={t("library.titles.public_libraries")}>
                <SecondaryPageAction
                    title={t2("input_variable.overview.titles.input_variables")}
                    to={ROUTES.inputVariablesOverview.path}
                >
                    <KeyboardIcon />
                </SecondaryPageAction>
                {hasUserRole(keycloak, ROLES.user) && (
                    <PrimaryPageAction title={t("library.titles.new_library")} to={ROUTES.libraryNew.path}>
                        <AddIcon />
                    </PrimaryPageAction>
                )}
            </PageHeader>
            {state.libraries.length > 0 && (
                <LibrariesList libraries={state.libraries}>
                    {(lib) => <LibrariesPublicItem key={lib.id} library={lib} />}
                </LibrariesList>
            )}
            {state.libraries.length === 0 && <LibrariesEmpty />}
        </Container>
    );
};
