import React from "react";
import {DataSourceDTO} from "../../../models/library";
import {Link} from "@material-ui/core";
import ROUTES from "../../../routes/routes";
import {GenericEntityDeleteContentProps, GenericEntityForceDelete} from "../../common/delete";
import {compareByAttr} from "../../../utils/compare";

const DataSourceRenderDelete: React.FC<GenericEntityDeleteContentProps> = (props: GenericEntityDeleteContentProps) => {
    return (
        <ul>
            {props.error.relatedEntities
                .filter((item) => item.type === "InputVariable")
                .sort(compareByAttr("displayName", "ascending"))
                .map((item) => (
                    <li key={item.id}>
                        <Link href={ROUTES.inputVariable.create(item.id)} target="_blank">
                            {item.displayName}
                        </Link>
                    </li>
                ))}
        </ul>
    );
};

interface DataSourceDeleteProps {
    entity: DataSourceDTO | null;
    onDeleted: () => void;
    onCancel: () => void;
    onFailed: () => void;
}

export const DataSourceDelete: React.FC<DataSourceDeleteProps> = (props: DataSourceDeleteProps) => {
    return (
        <GenericEntityForceDelete
            tNs="libraries"
            tPrefix="definitions.data_sources"
            urlSlug="data-sources"
            RenderContent={DataSourceRenderDelete}
            {...props}
        />
    );
};
