import React from "react";
import {useTranslation} from "react-i18next";
import {GenericEntityDeleteContentProps, GenericEntityNoLinkedDelete} from "../../common/delete";
import {OrganizationDTO} from "../../../models/organization";
import {extractEntitiesByType} from "../../../models/error";
import {Link} from "@material-ui/core";
import ROUTES from "../../../routes/routes";

const OrganizationRenderDelete: React.FC<GenericEntityDeleteContentProps> = (
    props: GenericEntityDeleteContentProps
) => {
    //== Init =================================================================
    const [t] = useTranslation("organization");
    const positions = extractEntitiesByType(props.error, "MembershipPosition");
    const libraries = extractEntitiesByType(props.error, "Library");
    const groups = extractEntitiesByType(props.error, "TrackedIndicatorGroup");
    const subjects = extractEntitiesByType(props.error, "SubjectMunicipality");
    const variables = extractEntitiesByType(props.error, "MunicipalityInputVariable");
    const tPrefix = "organization.confirm_delete.render";
    //== Render ===============================================================
    return (
        <ul>
            {positions.length > 0 && (
                <li>
                    {t(`${tPrefix}.positions`, {count: positions.length})}
                    <ul>
                        {positions.map((item) => (
                            <li key={item.id}>{item.displayName}</li>
                        ))}
                    </ul>
                </li>
            )}
            {libraries.length > 0 && (
                <li>
                    {t(`${tPrefix}.libraries`, {count: libraries.length})}
                    <ul>
                        {libraries.map((item) => (
                            <li key={item.id}>
                                <Link href={ROUTES.library.create(item.id)} target="_blank">
                                    {item.displayName}
                                    {item.extra["public"] === "true" && " " + t(`${tPrefix}.public`)}
                                    {item.extra["shared"] === "true" && " " + t(`${tPrefix}.shared`)}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </li>
            )}
            {groups.length > 0 && (
                <li>
                    {t(`${tPrefix}.groups`, {count: groups.length})}
                    <ul>
                        {groups.map((item) => (
                            <li key={item.id}>
                                <Link
                                    href={ROUTES.trackedIndicatorsBrowser.create(props.entity.id, item.id)}
                                    target="_blank"
                                >
                                    {item.displayName}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </li>
            )}
            {subjects.length > 0 && (
                <li>
                    {t(`${tPrefix}.subjects`, {count: subjects.length})}
                    <ul>
                        {subjects.map((item) => (
                            <li key={item.id}>
                                <Link href={ROUTES.organization.create(item.id)} target="_blank">
                                    {item.displayName}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </li>
            )}
            {variables.length > 0 && (
                <li>
                    {t(`${tPrefix}.variables`, {count: variables.length})}
                    <ul>
                        {variables.map((item) => {
                            const values: string = item.extra["values"] ?? "0";
                            return (
                                <li key={item.id}>
                                    {t(`${tPrefix}.variable`, {
                                        values: values,
                                        title: item.displayName,
                                    })}
                                </li>
                            );
                        })}
                    </ul>
                </li>
            )}
        </ul>
    );
};

interface OrganizationDeleteProps {
    entity: OrganizationDTO | null;
    onDeleted: () => void;
    onCancel: () => void;
    onFailed: () => void;
}

export const OrganizationDelete: React.FC<OrganizationDeleteProps> = (props: OrganizationDeleteProps) => {
    // TODO: allow admin to force-delete some organizations/municipalities
    //       based on actual contents
    return (
        <GenericEntityNoLinkedDelete
            tNs="organization"
            tPrefix="organization"
            urlSlug="organizations"
            RenderContent={OrganizationRenderDelete}
            {...props}
        />
    );
};
