import {ProfileUpdateDTO} from "../../../models/user";
import React from "react";
import {useForm} from "react-hook-form";
import {Button, Grid, TextField} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useStyles} from "../../../styles";
import {MarkdownTextField} from "../../common/markdown";

interface ProfileFormsProps {
    profile: ProfileUpdateDTO;

    onSubmit(profile: ProfileUpdateDTO): void;

    onCancel(): void;
}

export const ProfileForm: React.FC<ProfileFormsProps> = (props: ProfileFormsProps) => {
    const [t] = useTranslation("user");
    const classes = useStyles();
    const [state, setState] = React.useState(props.profile);
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<ProfileUpdateDTO>();
    //== Handlers =============================================================
    const onSubmit = (): void => {
        props.onSubmit(state);
    };
    //== Render ===============================================================
    const firstnameRegister = register("firstname", {
        required: {value: true, message: t("validation.required")},
        maxLength: {
            value: 250,
            message: t("validation.maxLength", {name: t("profile.firstname"), value: "250"}),
        },
    });
    const lastnameRegister = register("lastname", {
        required: {value: true, message: t("validation.required")},
        maxLength: {
            value: 250,
            message: t("validation.maxLength", {name: t("profile.lastname"), value: "250"}),
        },
    });
    const titlesBeforeRegister = register("titlesBefore", {
        maxLength: {
            value: 100,
            message: t("validation.maxLength", {name: t("people.titlesBefore"), value: "100"}),
        },
    });
    const titlesAfterRegister = register("titlesAfter", {
        maxLength: {
            value: 100,
            message: t("validation.maxLength", {name: t("people.titlesAfter"), value: "100"}),
        },
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Grid item xs={12}>
                <TextField
                    {...firstnameRegister}
                    label={t("profile." + firstnameRegister.name)}
                    value={state.firstname}
                    inputRef={firstnameRegister.ref}
                    fullWidth
                    required
                    variant="outlined"
                    margin="normal"
                    onChange={(e): void => setState({...state, firstname: e.target.value})}
                    error={errors.firstname !== undefined}
                    helperText={errors.firstname && errors.firstname.message}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    {...lastnameRegister}
                    label={t("profile." + lastnameRegister.name)}
                    value={state.lastname}
                    inputRef={lastnameRegister.ref}
                    fullWidth
                    required
                    variant="outlined"
                    margin="normal"
                    onChange={(e): void => setState({...state, lastname: e.target.value})}
                    error={errors.lastname !== undefined}
                    helperText={errors.lastname && errors.lastname.message}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    {...titlesBeforeRegister}
                    label={t("profile." + titlesBeforeRegister.name)}
                    value={state.titlesBefore}
                    inputRef={titlesBeforeRegister.ref}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    onChange={(e): void => setState({...state, titlesBefore: e.target.value})}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    {...titlesAfterRegister}
                    label={t("profile." + titlesAfterRegister.name)}
                    value={state.titlesAfter}
                    inputRef={titlesAfterRegister.ref}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    onChange={(e): void => setState({...state, titlesAfter: e.target.value})}
                />
            </Grid>
            <Grid item xs={12}>
                <MarkdownTextField
                    label={t("profile.bioPlaceholder")}
                    value={state.bio}
                    onChange={(value): void => setState({...state, bio: value})}
                    margin="normal"
                />
            </Grid>
            <Button color="primary" variant="contained" type="submit" className={classes.spaceAround}>
                {t("profile.save")}
            </Button>
            <Button color="secondary" variant="contained" className={classes.spaceAround} onClick={props.onCancel}>
                {t("profile.cancel")}
            </Button>
        </form>
    );
};
