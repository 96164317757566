import manualSubsection, {ManualSubsection} from "../../model/ManualSubsection";
import manualSection, {ManualSection} from "../../model/ManualSection";
import manualChapter, {ManualChapter} from "../../model/ManualChapter";
import {csMunicipalityMembersSection} from "./CsMunicipalityManualChapter";
import {csOrganizationLibrariesSection} from "./CsOrganizationManualChapter";
import ROUTES from "../../../routes/routes";
import React from "react";
import SettingsIcon from "@material-ui/icons/Settings";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import InfoIcon from "@material-ui/icons/Info";
import {Link} from "@material-ui/core";
import ManualLink from "../../components/ManualLink";

const csAdminUsersKeycloakSubsection: ManualSubsection = manualSubsection({
    key: "keycloak",
    title: "Nástroj Keycloak pro správu uživatelských účtů",
    intro: (
        <>
            <p>
                V&nbsp;levém menu po přihlášení do „Administration Console“ aplikace Keycloak klikněte na položku
                „Users“. Poté můžete spravovat uživatele – buď využijte vyhledávací pole, nebo klikněte na „View all
                users“. Pro úpravu klikněte vpravo na „Edit“.
            </p>
            <p>
                Na kartě „Detail“ můžete upravit základní údaje o uživateli včetně přihlašovacího e-mailu. Na kartě
                „Credentials“ je možné spravovat heslo uživatele (odstranit nebo nastavit nové). Na kartě „Role
                Mappings“ můžete u „Client Roles“ vybrat položku „sccompass“ a uvidíte, které role má uživatel
                přiřazeny. Běžný uživatel by měl mít roli „USER“ (výchozí) a správce roli „ADMIN“ (resp. obě). Změnu
                provedete výběrem dané role a kliknutím na „Add selected“ nebo „Remove selected“.
            </p>
            <p>
                Místo kliknutí na „Edit“ můžete také provést ze seznamu uživatelů operaci „Impersonate“, tím se
                přihlásíte jako daný uživatel bez nutnosti znát jeho přihlašovací údaje. Případně lze také uživatele
                zcela odstranit tlačítkem „Delete“. Smazáním se uživatel již nebude moci přihlásit, ale jeho profil
                v&nbsp;aplikaci Smart City Compass zůstane nezměněn.
            </p>
        </>
    ),
});

const csAdminUsersSCCSubsection: ManualSubsection = manualSubsection({
    key: "compass",
    title: "Vytvoření a úprava uživatele ve Smart City Compass",
    intro: (
        <p>
            V&nbsp;levém rozbalovacím menu aplikace jako administrátor klikněte na položku „Uživatelé“. Na kartě „Účty“
            můžete spravovat propojení uživatelských účtů s&nbsp;účty v&nbsp;aplikaci Keycloak. Vlastnost „UUID
            (Keycloak)“ je shodná s&nbsp;ID na seznamu uživatelů v&nbsp;Keycloak (viz&nbsp;
            <ManualLink subsection={csAdminUsersKeycloakSubsection} />
            ). Na kartě „Lidé“ můžete vidět jednotlivé uživatelské profily a kliknutím na ikonu&nbsp;
            <InfoIcon style={{verticalAlign: "bottom"}} /> zobrazit detail s&nbsp;možností úprav. Na kartě „Emaily“
            můžete spravovat všechny e-mailové adresy, které uživatelé přidali ke svým profilům.
        </p>
    ),
});

const csAdminUsersRemovingSubsection: ManualSubsection = manualSubsection({
    key: "odstraneni",
    title: "Odstranění uživatele",
    intro: (
        <p>
            Uživatele lze odstranit pouze, pokud s&nbsp;ním již nesouvisí žádné jiné entity v&nbsp;aplikaci. Nejprve je
            nutné mu odstranit všechna oprávnění do organizací a obcí, poté je možné uživatelský účet smazat. Rovněž by
            správce měl smazat i účet v&nbsp;aplikaci Keycloak (viz&nbsp;
            <ManualLink section={csMunicipalityMembersSection} />
            ). V opačném případě se bude uživatel moci opět přihlásit a začne „od nuly“.
        </p>
    ),
});

export const csAdminUsersSection: ManualSection = manualSection({
    key: "uzivatele",
    title: "Správa uživatelů",
    intro: (
        <p>
            Uživatelské účty jsou spravovány v&nbsp;aplikaci Keycloak. Během prvního přihlášení uživatele dojde ke
            spárování s&nbsp;uživatelským profilem v&nbsp;aplikaci Smart City Compass. Do správy účtů v&nbsp;Keycloak je
            nutné vyšší oprávnění (speciálně nastavený účet).
        </p>
    ),
    subsections: [csAdminUsersKeycloakSubsection, csAdminUsersSCCSubsection, csAdminUsersRemovingSubsection],
});

const csAdminOrganizationsCreationSubsection: ManualSubsection = manualSubsection({
    key: "vytvoreni",
    title: "Vytvoření organizace/obce",
    intro: (
        <p>
            Obce může vytvářet vždy jen správce. Organizace může vytvářet i jakýkoliv jiný registrovaný uživatel (pozn.:
            neplatí pro pilotní provoz, kde i organizace může vytvářet jen správce).
        </p>
    ),
});
const csAdminOrganizationsMaintainerAcceptingSubsection: ManualSubsection = manualSubsection({
    key: "schvaleni-spravce",
    title: "Schválení žádosti o správcovství obce",
    intro: (
        <>
            <p>
                Jakýkoliv registrovaný uživatel může zažádat o správcovství takových obcí, které žádného správce zatím
                nemají. Tyto žádosti se zobrazí na přehledu „Můj Compass“.
            </p>
            <p>
                Na tomto místě se nachází seznam žádostí s&nbsp;možností žádost schválit nebo zamítnout. Spolu
                s&nbsp;tím se pro každou žádost zobrazí jméno osoby, která o správcovství žádá, ověřená e-mailová adresa
                (na rozdíl od jména, které může být smyšlené nebo nepravé), název obce a navrhovaný název pozice správce
                v&nbsp;dané obci. Tento název pozice je možné před schválením žádosti upravit.
            </p>
        </>
    ),
});
const csAdminOrganizationsMaintainersSubsection: ManualSubsection = manualSubsection({
    key: "prirazeni-spravce",
    title: "Přiřazení prvního správce obce/organizace",
    intro: (
        <p>
            Prvního správce obce můžete přiřadit dvěma možnými způsoby, správce organizace pouze jedním. První možností,
            která funguje u obcí i organizací, je ruční přidání pozice a člena přes správu členů přes profil
            organizace/obce. V&nbsp;takovém případě použijte tlačítko „Správa členů“ na profilu organizace/obce a dále
            postupujte dle návodu pro obce nebo organizace (viz&nbsp;
            <ManualLink section={csMunicipalityMembersSection} />
            ). Druhým způsobem u obcí je pak schválení žádosti o správcovství obcí (viz{" "}
            <ManualLink subsection={csAdminOrganizationsMaintainerAcceptingSubsection} />
            ).
        </p>
    ),
});
const csAdminOrganizationsRemovingSubsection: ManualSubsection = manualSubsection({
    key: "odstraneni",
    title: "Odstranění profilu organizace/obce",
    intro: (
        <p>
            Organizaci nebo obec je možné odstranit použitím tlačítka&nbsp;
            <DeleteIcon style={{verticalAlign: "bottom"}} /> v&nbsp;seznamu organizací. Toto je možné však udělat jen
            v&nbsp;případě, že obec nemá zveřejněné indikátorové sady, sledované indikátory nebo vyplněné vstupní
            hodnoty.
        </p>
    ),
});

export const csAdminOrganizationsSection: ManualSection = manualSection({
    key: "organizace",
    title: "Správa obcí a organizací",
    intro: (
        <p>
            Zde se nachází seznam veškerých obcí a organizací, které jsou registrované v aplikaci Smart City Compass. Je
            zde možné přejít na profil obce/organizace, profil upravit nebo organizaci zcela odstranit. Také je zde
            možnost přidání nové organizace a obce.
        </p>
    ),
    subsections: [
        csAdminOrganizationsCreationSubsection,
        csAdminOrganizationsMaintainersSubsection,
        csAdminOrganizationsMaintainerAcceptingSubsection,
        csAdminOrganizationsRemovingSubsection,
    ],
});

const csAdminDefinitionsTagsSubsection: ManualSubsection = manualSubsection({
    key: "stitky",
    title: "Štítky a jejich kategorie",
    intro: (
        <>
            <p>
                V&nbsp;levém menu klikněte na položku „Štítky“. Na kartě „Štítky“ můžete procházet seznam všech štítků
                (případně filtrovat dle kategorie). V&nbsp;dolní části se nachází formulář pro snadné vytvoření nového
                štítku. Kliknete-li na ikonu&nbsp;
                <EditIcon style={{verticalAlign: "bottom"}} /> pro úpravu štítku, formulář bude sloužit pro úpravu
                tohoto štítku. Štítek se můžete pokusit odstranit kliknutím na ikonu&nbsp;
                <DeleteIcon style={{verticalAlign: "bottom"}} />.
            </p>
            <p>
                Kategorie štítků lze spravovat obdobným způsobem na kartě „Kategorie štítků“. Definici barvy lze zadávat
                anglickým názvem (např. „red“), RGB trojicí desítkových čísel (např, „rgb(255,0,0)“) nebo hexadecimálním
                zápisem (např. „#FF0000“). Ikony můžete vyhledávat na stránkách{" "}
                <Link href="https://material-ui.com/components/material-icons/">
                    https://material-ui.com/components/material-icons/
                </Link>
                . Barvy vybírejte tak, aby byla ikona i text dobře čitelný, jak ukazuje „Ukázkový štítek“ vedle tlačítka
                „Uložit“. Pro kontrolu čitelnosti můžete použít například{" "}
                <Link href="https://webaim.org/resources/contrastchecker/">
                    https://webaim.org/resources/contrastchecker/
                </Link>
                . Kategorii štítků opět nelze odstranit, pokud je v&nbsp;ní nějaký štítek.
            </p>
        </>
    ),
});

const csAdminDefinitionsUnitsSubsection: ManualSubsection = manualSubsection({
    key: "jednotky",
    title: "Jednotky",
    intro: (
        <p>
            Veškeré hodnoty v&nbsp;aplikaci mohou mít přiřazenu jednotku dle proměnné (vstupní proměnné nebo
            indikátoru), ke kterému se vážou. V&nbsp;levém menu klikněte na položku „Definice“ a přejděte na kartu
            „Jednotky“. Kliknutím na tlačítko&nbsp;
            <AddIcon style={{verticalAlign: "bottom"}} /> můžete přidat novou jednotku, kliknutím na ikonu&nbsp;
            <EditIcon style={{verticalAlign: "bottom"}} /> můžete upravit (a odstranit) existující jednotku. Každá
            jednotka má svůj název, zkratku a případně i popis (pro správce). Navíc jednotka může mít vybranou základní
            jednotku (např. kilometry mají základní jednotku metry). V&nbsp;takovém případě je nutné definovat vzorce
            pro převod ze základní jednotky a na základní jednotku. Pokud je jednotka použita jako základní, už ji nelze
            nastavit další základní jednotku. Stejně tak odvozenou jednotku nelze používat jinde jako základní.
        </p>
    ),
});

const csAdminDefinitionsDataSourcesSubsection: ManualSubsection = manualSubsection({
    key: "datove-zdroje",
    title: "Datové zdroje",
    intro: (
        <p>
            Informace o datovém zdroji se využívají k&nbsp;popisu možností získání údajů pro zadávání do aplikace. Každý
            datový zdroj má svůj název a popis. Pro jejich správu klikněte v&nbsp;levém menu na „Definice“ a kartu
            „Datové zdroje“. Kliknutím na tlačítko&nbsp;
            <AddIcon style={{verticalAlign: "bottom"}} /> můžete definovat nový datový zdroj, kliknutím na ikonu &nbsp;
            <EditIcon style={{verticalAlign: "bottom"}} /> upravit a případně odstranit (není-li použit) existující
            datový zdroj.
        </p>
    ),
});

const csAdminDefinitionsInputVariablesSubsection: ManualSubsection = manualSubsection({
    key: "vstupni-promenne",
    title: "Vstupní proměnné",
    intro: (
        <>
            <p>
                Vstupní proměnné slouží na jedné straně k&nbsp;zadávání hodnot (např. počtu obyvatel obce) a na druhé
                straně k&nbsp;definici vzorů indikátorů, které se z&nbsp;těchto hodnot automaticky vypočítávají.
                Z&nbsp;důvodu zamezení vzniku duplicit je stejně jako jednotky a datové zdroje může vytvářet pouze
                administrátor. Pokud tedy někdo potřebuje pro svoje indikátory novou vstupní proměnnou, která
                v&nbsp;aplikaci zatím není, musí kontaktovat správce – ten musí odborně posoudit vhodnost nové vstupní
                proměnné a případně ji vytvořit (obdobně pro požadované úpravy).
            </p>
            <p>
                V&nbsp;levém menu klikněte na položku „Definice“ a kartu „Vstupní proměnné“. Kliknutím na tlačítko&nbsp;
                <AddIcon style={{verticalAlign: "bottom"}} />
                můžete definovat novou vstupní proměnnou, kliknutím na ikonu&nbsp;
                <EditIcon style={{verticalAlign: "bottom"}} /> upravit a případně odstranit (není-li použita) existující
                vstupní proměnnou. Každá vstupní proměnná má název, popis a definici. Poté je možné vybrat preferovanou
                jednotku vstupní proměnné (např. kilometry). Při zadávání hodnot bude mít uživatel na výběr tuto
                preferovanou jednotku a všechny další, které lze převést (např. kilometry a metry). Dále lze
                specifikovat minimální a maximální možnou hodnotu.
            </p>
            <p>
                Existující vstupní proměnnou lze propojit s&nbsp;datovými zdroji. Na úpravě vstupní proměnné se
                v&nbsp;dolní části nachází „Možnosti sběru dat“, kde lze vybrat zdroj dat a do popisu napsat, jak sběr
                dat z&nbsp;daného zdroje pro tuto vstupní proměnnou probíhá. Obdobně lze specifikovat, jak často by se
                měly hodnoty pro danou vstupní proměnnou zadávat pomocí „Nastavení periody vyplnění“.
            </p>
        </>
    ),
});

export const csAdminDefinitionsSection: ManualSection = manualSection({
    key: "stitky-definice",
    title: "Štítky a definice",
    intro: (
        <>
            <p>
                Štítek je doplňkový atribut (označení) indikátoru či cíle. Slouží například pro třídění indikátorů podle
                jiných parametrů než pouze dle příslušnosti k dané indikátorové sadě. Je tedy možné pomocí jednoho
                štítku označit indikátory napříč indikátorovými sadami. Administrátor může například takto označit
                indikátory z několika sad jako příslušné k vybranému dotačnímu titulu nebo průnikové strategii
                využívající vlastní členění oblastí.
            </p>
            <p>
                Štítky a další definice (jednotky, datové zdroje a vstupní proměnné) tvoří sdílený obsah, který mohou
                ostatní uživatelé aplikace používat. Je potřeba vzít na vědomí, že pokud někdo použije danou definici,
                už ji nebude možné odstranit, aniž by se toto použití opět zrušilo. Například, je-li štítek využit
                v&nbsp;nějaké indikátorové sadě není možné jej odstranit, pouze upravit.
            </p>
        </>
    ),
    subsections: [
        csAdminDefinitionsTagsSubsection,
        csAdminDefinitionsUnitsSubsection,
        csAdminDefinitionsDataSourcesSubsection,
        csAdminDefinitionsInputVariablesSubsection,
    ],
});

const csAdminLibrariesOverviewSubsection: ManualSubsection = manualSubsection({
    key: "prehled",
    title: "Přehled indikátorových sad",
    intro: (
        <p>
            V&nbsp;hlavním menu v&nbsp;sekci administrace klikněte na „Indikátorové sady“. Na této stránce můžete
            spravovat veškeré indikátorové sady, včetně neveřejných. Mimo veškerých úprav, které mohou organizace dělat
            ve svých sadách (změny názvů, popisů, oblastí, cílů a indikátorů) lze řídit zveřejňování a převod
            vlastnictví.
        </p>
    ),
});

const csAdminLibrariesEditSubsection: ManualSubsection = manualSubsection({
    key: "uprava",
    title: "Úprava indikátorové sady",
    intro: (
        <p>
            Veškeré úpravy indikátorové sady fungují pro správce stejně jako je popsáno v kapitole{" "}
            <ManualLink section={csOrganizationLibrariesSection} />. Stále platí, že úpravy a odstraňování částí sad,
            které jsou využívané jinde v&nbsp;aplikaci (v jiných sadách nebo v&nbsp;rámci skupin sledovaných
            indikátorů), jsou omezeny. Z&nbsp;pohledu správce lze tato omezení vyřešit odstraněním rovněž závislých
            částí (například skupin sledovaných indikátorů, které sadu využívají).
        </p>
    ),
});

const csAdminLibrariesPublishingSubsection: ManualSubsection = manualSubsection({
    key: "zverejneni",
    title: "Zveřejnění, sdílení a převod vlastnictví indikátorové sady",
    intro: (
        <>
            <p>
                Správce může v&nbsp;
                <Link href={ROUTES.librariesAdmin.path} target="_blank">
                    Přehledu sad
                </Link>{" "}
                v&nbsp;administraci kliknout na ikonu&nbsp;
                <SettingsIcon style={{verticalAlign: "bottom"}} />. Na následující stránce má tři možnosti:
            </p>
            <ul>
                <li>
                    Zveřejnit indikátorovou sadu = tato sada je v&nbsp;seznamu veřejných sad, kdokoliv ji může
                    procházet, obce ji mohou používat ve skupinách sledovaných indikátorů.
                </li>
                <li>Sdílet indikátorovou sadu = takovou sadu lze používat v&nbsp;rámci jiných indikátorových sad.</li>
                <li>
                    Převod vlastnictví indikátorové sady = indikátorová sada vždy patří nějaké organizaci, touto funkcí
                    lze změnit organizaci, která vlastní (a může upravovat) indikátorovou sadu.
                </li>
            </ul>
        </>
    ),
});

export const csAdminLibrariesSection: ManualSection = manualSection({
    key: "indikatorove-sady",
    title: "Indikátorové sady",
    intro: (
        <p>
            Administrátor může vytvářet, měnit a odstraňovat indikátorové sady za jakoukoliv organizaci či obec stejně
            jak je popsáno v kapitole <ManualLink section={csOrganizationLibrariesSection} />. Navíc má však možnost
            procházet souhrnně všechny (i nezveřejněné) indikátorové sady a měnit jejich nastavení.
        </p>
    ),
    subsections: [
        csAdminLibrariesOverviewSubsection,
        csAdminLibrariesEditSubsection,
        csAdminLibrariesPublishingSubsection,
    ],
});

const csAdminPagesSpecialSubsection: ManualSubsection = manualSubsection({
    key: "specialni",
    title: "Speciální stránky",
    intro: (
        <>
            <p>
                Pomocí nastavení veřejného ID lze vytvářet tři speciální stránky, které jsou přístupné přímo
                z&nbsp;levého menu:
            </p>
            <ul>
                <li>
                    <strong>landing</strong> = úvodní stránka aplikace, dostupná přes „Úvod“,
                </li>
                <li>
                    <strong>partners</strong> = stránka s&nbsp;partnery aplikace, dostupná přes „Partneři“,
                </li>
                <li>
                    <strong>contacts</strong> = stránka s&nbsp;kontaktními údaji na správce aplikace, dostupná přes
                    „Konktakty“.
                </li>
            </ul>
        </>
    ),
});

const csAdminPagesEditSubsection: ManualSubsection = manualSubsection({
    key: "vytvareni-uprava",
    title: "Vytváření a úprava stránek",
    intro: (
        <p>
            V&nbsp;levém menu klikněte na položku „Stránky“. V&nbsp;tabulce můžete pro každou existující stránku
            zkopírovat její odkaz, zobrazit ji nebo ji upravit. Pomocí tlačítka{" "}
            <AddIcon style={{verticalAlign: "bottom"}} /> můžete vytvořit novou stránku. Každá stránka má svůj titulek,
            veřejné ID, popis, klíčová slova (oddělená čárkami) a může mít přiřazené i štítky. Obsah stránky lze
            upravovat pomocí tzv. WYSIWYG editoru se základním formátováním (nadpis, tučné písmo, italika, podtržení,
            odkazy, obrázky, seznamy, citace). Veřejné ID slouží k&nbsp;identifikaci stránky a musí obsahovat pouze malá
            písmena, číslice, pomlčky a podtržítka. S&nbsp;výjimkou speciálních stránek (viz kapitola&nbsp;
            <ManualLink subsection={csAdminPagesSpecialSubsection} />) se veřejné ID stává součástí URL adresy dané
            stránky. Odstranění stránky je možné, ale neprobíhají žádné kontroly, zda je stránka odkazována
            z&nbsp;jiných stránek či dokonce externě.
        </p>
    ),
});

export const csAdminPagesSection: ManualSection = manualSection({
    key: "stranky",
    title: "Veřejné stránky",
    intro: (
        <p>
            V&nbsp;aplikaci lze snadno upravovat obsahové veřejné stránky včetně speciálních, jako je „Úvod“ nebo
            „Kontakt“. Všechny tyto stránky jsou veřejné a lze jich vytvářet libovolné množství a mezi sebou je
            provazovat pomocí odkazů.
        </p>
    ),
    subsections: [csAdminPagesEditSubsection, csAdminPagesSpecialSubsection],
});

export const csAdminArchitectureSection: ManualSection = manualSection({
    key: "architektura",
    title: "Základ architektury aplikace",
    intro: (
        <>
            <p>Smart City Compass se skládá ze tří hlavních komponent:</p>
            <ul>
                <li>
                    Frontend = Aplikace pro komunikaci s&nbsp;uživateli – pro prezentaci a zadávání dat. Zjednodušeně
                    řešeno je to to, co vidí uživatel ve webovém prohlížeči.
                </li>
                <li>
                    Backend = Aplikace obstarávající ukládání dat a jejich zpracování včetně výpočtů. Vystavuje své REST
                    API pro frontend aplikaci (ale i případně jiné další aplikace). Pro ověření uživatele se používá
                    token z&nbsp;aplikace Keycloak (OpenID).
                </li>
                <li>
                    Keycloak = Aplikace spravující uživatelské účty pro přihlašování, samotné přihlašování, obnovu hesla
                    apod. Ověření uživatelů a jejich oprávnění probíhá standardním protokolem OpenID.
                </li>
            </ul>
        </>
    ),
});

export const csAdminManualChapter: ManualChapter = manualChapter({
    key: "admin",
    title: "Administrátorská příručka pro správu Smart City Compass",
    intro: (
        <p>
            Administrátor neboli správce aplikace má přístup ke všem jejím částem, a to včetně všech obcí a organizací.
            Může tedy provádět veškeré operace zmíněné v&nbsp;předchozích částech manuálu bez nutnosti být členem dané
            organizace/obce. Navíc může spravovat uživatele, stránky, profily organizací a obcí, definice a štítky.
        </p>
    ),
    sections: [
        csAdminUsersSection,
        csAdminOrganizationsSection,
        csAdminDefinitionsSection,
        csAdminLibrariesSection,
        csAdminPagesSection,
        csAdminArchitectureSection,
    ],
    requiresAdmin: true, //note that it just hides the chapter, but is still accessible in code
});

export default csAdminManualChapter;
