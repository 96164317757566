import React from "react";
import {ManualChapter} from "../model/ManualChapter";
import {ManualSection} from "../model/ManualSection";
import {ManualSubsection} from "../model/ManualSubsection";
import {ManualSubSubsection} from "../model/ManualSubSubsection";
import ManualQueryLink, {manualLinkStyles} from "./ManualQueryLink";
import {manuals} from "./ManualDisplay";

interface ManualLinkChapterProps {
    chapter: ManualChapter;
    section?: null;
    subsection?: null;
    subSubsection?: null;
}

interface ManualLinkSectionProps {
    chapter?: null;
    section: ManualSection;
    subsection?: null;
    subSubsection?: null;
}

interface ManualLinkSubsectionProps {
    chapter?: null;
    section?: null;
    subsection: ManualSubsection;
    subSubsection?: null;
}

interface ManualLinkSubSubsectionProps {
    chapter?: null;
    section?: null;
    subsection?: null;
    subSubsection: ManualSubSubsection;
}

type ManualLinkItemProps =
    | ManualLinkChapterProps
    | ManualLinkSectionProps
    | ManualLinkSubsectionProps
    | ManualLinkSubSubsectionProps;

export const ManualLink: (props: ManualLinkItemProps) => JSX.Element = (props: ManualLinkItemProps) => {
    const classes = manualLinkStyles();

    const [state, setState] = React.useState(props);

    React.useEffect(() => {
        if (props.subSubsection) return setState({subSubsection: props.subSubsection});
        if (props.subsection) return setState({subsection: props.subsection});
        if (props.section) return setState({section: props.section});
        if (props.chapter) return setState({chapter: props.chapter});
    }, [manuals, props]);

    const subSubsection = state.subSubsection;
    const subsection = state.subsection || subSubsection?.subsection;
    const section = state.section || subsection?.section;
    const chapter = state.chapter || section?.chapter;

    if (!chapter || !chapter.index)
        return (
            <span className={classes.manualLink}>
                <span className={classes.manualLinkNumber}></span>
                <span className={classes.manualLinkTitle}>
                    {(subSubsection || subsection || section || chapter)?.title}
                </span>
            </span>
        );

    let index = "" + chapter.index;
    if (section) {
        index = index + "." + section.index;
        if (subsection) {
            index = index + "." + subsection.index;
            if (subSubsection) {
                index = index + "." + subSubsection.index;
            }
        }
    }

    return (
        <ManualQueryLink
            chapter={chapter.key}
            section={section?.key}
            subsection={subsection?.key}
            subSubsection={subSubsection?.key}
            number={index}
            title={(subSubsection || subsection || section || chapter).title}
        />
    );
};

export default ManualLink;
