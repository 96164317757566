import {useEffect} from "react";

/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/ban-ts-comment */
export const useSMap: (cb: any) => void = (cb: any) => {
    useEffect(() => {
        const onload = () => {
            // @ts-ignore
            window.Loader.async = true;
            // @ts-ignore
            window.Loader.load(null, null, cb);
        };

        const script = document.createElement("script");
        script.src = "https://api.mapy.cz/loader.js";
        script.async = true;
        script.onload = onload;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [cb]);
};
