import React from "react";
import {
    ImprovementDirection,
    IndicatorCreateDTO,
    IndicatorDTO,
    OptionDTO,
    UnitSimpleDTO,
    VariableSimpleDTO,
    VariableType,
} from "../../../../models/library";
import {Box, Button, Container, Divider, FormControl, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";
import {TagsInput} from "../../../common/tags";
import {TagDTO} from "../../../../models/admin";
import {useStyles} from "../../../../styles";
import {useTranslation} from "react-i18next";
import {ValueSimpleForm} from "../../../common/values";
import {UnitInput} from "../../../common/libraries/units";
import {valueToUpdate} from "../../../../models/values";
import {FormulaDialog} from "./FormulaDialog";
import {FormulaDisplay} from "../../formulas/FormulaDisplay";
import {MarkdownTextField} from "../../../common/markdown";
import {VariableTypeInput} from "../../../common/variables";
import {Alert} from "@material-ui/lab";
import {OptionsDialog} from "./OptionsDialog";
import {compareByAttr} from "../../../../utils/compare";

interface IndicatorFormProps {
    original?: IndicatorDTO;
    indicator: IndicatorCreateDTO;
    optionsHandle: "allow" | "after_save" | "forbid";
    usedVariables: VariableSimpleDTO[];
    published: boolean;
    onChange: (indicator: IndicatorCreateDTO, usedVariables: VariableSimpleDTO[]) => void;
}

export const IndicatorForm: React.FC<IndicatorFormProps> = (props: IndicatorFormProps) => {
    // TODO: validations
    //== Init =================================================================
    const [t] = useTranslation("libraries");
    const classes = useStyles();
    const [editFormula, setEditFormula] = React.useState(false);
    const [editOptions, setEditOptions] = React.useState(false);
    const [options, setOptions] = React.useState<OptionDTO[]>(
        props.original?.options?.sort(compareByAttr("value", "ascending")) ?? []
    );
    //== Handlers =============================================================
    const handleChange = (indicator: IndicatorCreateDTO) => {
        props.onChange(indicator, props.usedVariables);
    };
    const handleFormulaChange = (formula: string, usedVariables: VariableSimpleDTO[]) => {
        setEditFormula(false);
        props.onChange(
            {...props.indicator, formula: formula, usedVariables: usedVariables.map((v) => v.id)},
            usedVariables
        );
    };
    const handleFormulaClose = () => {
        setEditFormula(false);
    };
    const handleFormulaOpen = () => {
        setEditFormula(true);
    };
    const handleOptionsClose = () => {
        setEditOptions(false);
    };
    const handleOptionsOpen = () => {
        setEditOptions(true);
    };
    const handlePreferredUnitChange = (unit: UnitSimpleDTO) => {
        props.onChange(
            {
                ...props.indicator,
                preferredUnitUUID: unit.id,
                minimumValueUpdate:
                    props.indicator.minimumValueUpdate !== null
                        ? {...props.indicator.minimumValueUpdate, unitUUID: unit.id}
                        : null,
                maximumValueUpdate:
                    props.indicator.minimumValueUpdate !== null
                        ? {...props.indicator.minimumValueUpdate, unitUUID: unit.id}
                        : null,
            },
            props.usedVariables
        );
    };
    //== Render ===============================================================
    return (
        <Container>
            {props.published && <Alert severity="warning">{t("indicator.published_info")}</Alert>}
            <FormulaDialog
                open={editFormula}
                indicator={props.indicator}
                usedVariables={props.usedVariables}
                onSubmit={handleFormulaChange}
                onClose={handleFormulaClose}
            />
            <TextField
                label={t("indicator.name")}
                value={props.indicator.name}
                onChange={(e): void => {
                    handleChange({...props.indicator, name: e.target.value});
                }}
                required
                fullWidth
                variant="outlined"
                margin="normal"
            />
            <TagsInput
                label={t("indicator.tags")}
                value={props.indicator.tags}
                onChange={(tags: TagDTO[]): void => {
                    handleChange({...props.indicator, tags: tags});
                }}
                fullWidth
                variant="outlined"
                margin="normal"
            />
            <MarkdownTextField
                label={t("indicator.description")}
                value={props.indicator.description}
                onChange={(value): void => {
                    handleChange({...props.indicator, description: value});
                }}
                required
                margin="normal"
            />
            <MarkdownTextField
                label={t("indicator.definition")}
                value={props.indicator.definition}
                onChange={(value): void => {
                    handleChange({...props.indicator, definition: value});
                }}
                required
                margin="normal"
            />
            {/*
            <MarkdownTextField
                label={t("indicator.processMethod")}
                value={props.indicator.processMethod}
                onChange={(value): void => {
                    handleChange({...props.indicator, processMethod: value});
                }}
                required
                margin="normal"
            />
            */}
            <MarkdownTextField
                label={t("indicator.validityComment")}
                value={props.indicator.validityComment}
                onChange={(value): void => {
                    handleChange({...props.indicator, validityComment: value});
                }}
                required
                margin="normal"
            />
            <MarkdownTextField
                label={t("indicator.presentationRecommendation")}
                value={props.indicator.presentationRecommendation}
                onChange={(value): void => {
                    handleChange({...props.indicator, presentationRecommendation: value});
                }}
                required
                margin="normal"
            />
            <Divider style={{margin: "1em"}} />
            <Box>
                <FormulaDisplay formula={props.indicator.formula} variables={props.usedVariables} showVariables />
                <Box display="flex" justifyContent="flex-end">
                    <Button onClick={handleFormulaOpen} disabled={props.published}>
                        {t("formula.actions.edit")}
                    </Button>
                </Box>
            </Box>
            <Divider style={{margin: "1em"}} />
            <Box>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel id="direction-label">{t("indicator.direction.label")}</InputLabel>
                    <Select
                        id="direction"
                        labelId="direction-label"
                        label={t("indicator.direction.label")}
                        value={props.indicator.direction ?? "undecidable"}
                        variant="outlined"
                        required
                        fullWidth
                        className={classes.spaceAfter}
                        onChange={(e): void => {
                            props.onChange(
                                {
                                    ...props.indicator,
                                    direction: e.target.value as ImprovementDirection,
                                },
                                props.usedVariables
                            );
                        }}
                    >
                        <MenuItem value="undecidable">{t("indicator.direction.undecidable")}</MenuItem>
                        <MenuItem value="increasing">{t("indicator.direction.increasing")}</MenuItem>
                        <MenuItem value="decreasing">{t("indicator.direction.decreasing")}</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <VariableTypeInput
                disabled={props.published}
                value={props.indicator.type}
                onChange={(value: VariableType) => handleChange({...props.indicator, type: value})}
            />
            {props.indicator.type === "quantitative" && (
                <>
                    <UnitInput
                        query="all"
                        label={t("indicator.preferred_unit")}
                        helperText={t("indicator.unit_helper")}
                        valueUuid={props.indicator.preferredUnitUUID}
                        onChange={handlePreferredUnitChange}
                        fullWidth
                        variant="outlined"
                        className={classes.spaceAfter}
                    />
                    <ValueSimpleForm
                        label={t("indicator.minimum_value")}
                        fullWidth
                        variant="outlined"
                        className={classes.spaceAfter}
                        present={props.indicator.minimumValueUpdate !== null}
                        value={props.indicator.minimumValueUpdate?.value ?? 0}
                        unitUUID={props.indicator.minimumValueUpdate?.unitUUID ?? props.indicator.preferredUnitUUID}
                        onChange={(value) =>
                            handleChange({
                                ...props.indicator,
                                minimumValueUpdate: valueToUpdate(value),
                            })
                        }
                    />
                    <ValueSimpleForm
                        label={t("indicator.maximum_value")}
                        fullWidth
                        variant="outlined"
                        className={classes.spaceAfter}
                        present={props.indicator.maximumValueUpdate !== null}
                        value={props.indicator.maximumValueUpdate?.value ?? 0}
                        unitUUID={props.indicator.maximumValueUpdate?.unitUUID ?? props.indicator.preferredUnitUUID}
                        onChange={(value) =>
                            handleChange({
                                ...props.indicator,
                                maximumValueUpdate: valueToUpdate(value),
                            })
                        }
                    />
                </>
            )}
            {props.indicator.type === "qualitative" && props.optionsHandle === "after_save" && (
                <Alert severity="info">{t("indicator.not_options_yet")}</Alert>
            )}
            {props.indicator.type === "qualitative" && props.optionsHandle === "allow" && props.original && (
                <Box>
                    <ul>
                        {options.map((option: OptionDTO) => (
                            <li key={option.id}>
                                {option.value.value} = {option.name}
                            </li>
                        ))}
                    </ul>
                    <Box display="flex" justifyContent="flex-end">
                        <Button onClick={handleOptionsOpen} disabled={props.published}>
                            {t("indicator.actions.optionsEdit")}
                        </Button>
                    </Box>
                    <OptionsDialog
                        open={editOptions}
                        variableUUID={props.original.variableUUID}
                        options={options}
                        onChange={setOptions}
                        onClose={handleOptionsClose}
                    />
                </Box>
            )}
        </Container>
    );
};
