import * as CSV from "csv-string";

export const CSVUtils = {
    guessSeparator(): "," | ";" {
        const decimalSeparator = (0.1).toLocaleString().substring(1, 2);
        return decimalSeparator === "." ? "," : ";";
    },
    generateCSV<T>(arr: T[], rowConvertFn: (obj: T) => string[], headers?: string[]): string {
        const universalBOM = "\uFEFF";
        const sep = this.guessSeparator();
        const header = headers ? CSV.stringify(headers, sep) : "";
        const data = arr.map((obj) => CSV.stringify(rowConvertFn(obj), sep));
        return universalBOM + header + data.join("");
    },
};
