import React from "react";
import {DomainCreateDTO} from "../../../../models/library";
import {Container, TextField} from "@material-ui/core";
import {TagsInput} from "../../../common/tags";
import {TagDTO} from "../../../../models/admin";
import {useTranslation} from "react-i18next";
import {MarkdownTextField} from "../../../common/markdown";
import {Alert} from "@material-ui/lab";

interface DomainFormProps {
    domain: DomainCreateDTO;
    published: boolean;
    onChange: (domain: DomainCreateDTO) => void;
}

export const DomainForm: React.FC<DomainFormProps> = (props: DomainFormProps) => {
    //TODO: validations
    //== Init =================================================================
    const [t] = useTranslation("libraries");
    //== Render ===============================================================
    return (
        <Container>
            {props.published && <Alert severity="warning">{t("domain.published_info")}</Alert>}
            <TextField
                label={t("domain.name")}
                value={props.domain.name}
                onChange={(e): void => {
                    props.onChange({...props.domain, name: e.target.value});
                }}
                required
                fullWidth
                variant="outlined"
                margin="normal"
            />
            <TagsInput
                label={t("domain.tags")}
                value={props.domain.tags}
                onChange={(tags: TagDTO[]): void => {
                    props.onChange({...props.domain, tags: tags});
                }}
                fullWidth
                variant="outlined"
                margin="normal"
            />
            <MarkdownTextField
                label={t("domain.description")}
                value={props.domain.description}
                onChange={(value): void => {
                    props.onChange({...props.domain, description: value});
                }}
                required
                margin="normal"
            />
        </Container>
    );
};
