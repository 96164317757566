import React from "react";
import {useTranslation} from "react-i18next";
import {Box, Divider, Typography} from "@material-ui/core";
import {DashboardWidgetProps} from "./index";
import {IndicatorsDashboard} from "../../organization/TrackedIndicators/dashboard/IndicatorsDashboard";

export const IndicatorsDashboardWidget: React.FC<DashboardWidgetProps> = (props: DashboardWidgetProps) => {
    const [t] = useTranslation("trackedIndicators");
    return (
        <Box style={{}}>
            <Typography variant="h5" component="h2" style={{marginBottom: "1.5em"}}>
                {props.dashboard.memberships.length != 1
                    ? t("dashboard.ui.personalTitle")
                    : t("dashboard.ui.personalTitleSingle", {name: props.dashboard.memberships[0].organization.name})}
            </Typography>
            <Divider />
            <IndicatorsDashboard permissions={null} simpleMunicipality={null} dashboard={props.dashboard} />
        </Box>
    );
};
