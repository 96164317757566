import React from "react";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
// Material-UI imports
import {
    Box,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TableRow,
    TextField,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
// Project imports
import {
    CreateActionButton,
    DeleteActionButton,
    DetailLinkActionButton,
    IdCopyButton,
    RefreshActionButton,
} from "../../common/buttons";
import {ErrorNotification, InfoNotification, SuccessNotification} from "../../common/notifications";
import {Loading, LoadingRow} from "../../common/Loading";
import {ServerCommunicationAlert} from "../../common/errors";
import {makeFullName, PersonDTO} from "../../../models/user";
import {API, createApiConfig, DateUtils, Utils} from "../../../utils";
import {ROUTES} from "../../../routes/routes";
import {emptyPage, Page} from "../../../models/pagination";
import {AxiosError, AxiosResponse} from "axios";
import {DeleteConfirmationDialog} from "../../common/dialogs";
import SearchIcon from "@material-ui/icons/Search";

interface PersonRowProps {
    person: PersonDTO;
}

interface PersonNewRowProps {
    onSave: (person: PersonDTO) => void;
}

const PersonRow: React.FC<PersonRowProps> = (props: PersonRowProps) => {
    //== Init ===================================================================
    const [t] = useTranslation("user");
    const {keycloak, initialized} = useKeycloak();
    const [state, setState] = React.useState({
        person: props.person,
        name: "init" as "init" | "confirmingDelete" | "deleting" | "deleted" | "failed",
        action: "init",
    });
    //== Effects ================================================================
    React.useEffect(() => {
        if (state.name === "deleting") {
            API.delete<void>(`/people/${state.person.id}`, createApiConfig(keycloak, initialized))
                .then(() => {
                    setState({...state, name: "deleted", action: "delete"});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed", action: "delete"});
                });
        }
    }, [keycloak, initialized, state, setState]);
    //== Handlers ===============================================================
    const handleCancelDelete = (): void => {
        setState({...state, name: "init"});
    };
    const handleConfirmDelete = (): void => {
        setState({...state, name: "deleting"});
    };
    const handleDelete = (): void => {
        setState({...state, name: "confirmingDelete"});
    };
    //== Render =================================================================
    if (state.name === "deleted") {
        return <SuccessNotification message={t(`people.notifications.delete_ok`)} />;
    }
    if (state.name === "deleting") {
        return <LoadingRow key={Utils.nullToEmpty(state.person.id)} />;
    }
    // - default
    let notification: JSX.Element | null = null;
    if (state.name === "failed") {
        const a = state.action;
        notification = <ErrorNotification message={t(`people.notifications.${a}_fail`)} />;
    }
    return (
        <TableRow>
            <TableCell align="center">{state.person.lastname}</TableCell>
            <TableCell align="center">{state.person.firstname}</TableCell>
            <TableCell align="center">{state.person.titlesBefore}</TableCell>
            <TableCell align="center">{state.person.titlesAfter}</TableCell>
            <TableCell align="center">{DateUtils.datetimeString(state.person.createdAt)}</TableCell>
            <TableCell align="right">
                <IdCopyButton text={Utils.nullToEmpty(state.person.id)} size="small" />
                <DetailLinkActionButton to={ROUTES.person.create(state.person.publicId)} size="small" />
                <DeleteActionButton onClick={handleDelete} size="small" />
                <DeleteConfirmationDialog
                    title={t("ui.people.deleteConfirmDialog.title")}
                    text={t("ui.people.deleteConfirmDialog.text", {fullName: makeFullName(state.person)})}
                    checkbox={t("ui.accounts.deleteConfirmDialog.checkbox")}
                    open={state.name === "confirmingDelete"}
                    id={"delete-dialog-" + props.person.id}
                    onDelete={handleConfirmDelete}
                    onCancel={handleCancelDelete}
                />
                {notification}
            </TableCell>
        </TableRow>
    );
};

const PersonNewRow: React.FC<PersonNewRowProps> = (props: PersonNewRowProps) => {
    //== Init ===================================================================
    const [t] = useTranslation("user");
    const {keycloak, initialized} = useKeycloak();
    const initState = {
        formFirstname: "",
        formLastname: "",
        formTitlesBefore: "",
        formTitlesAfter: "",
        name: "init",
        onSave: props.onSave,
    };
    const [state, setState] = React.useState(initState);
    //== Effects ================================================================
    React.useEffect(() => {
        if (state.name === "saving") {
            const person: PersonDTO = {
                id: null,
                firstname: state.formFirstname,
                lastname: state.formLastname,
                titlesBefore: state.formTitlesBefore,
                titlesAfter: state.formTitlesAfter,
                publicId: "", // TODO?,
                profileImage: null,
            };
            API.post<PersonDTO>(`/people`, person, createApiConfig(keycloak, initialized))
                .then((res) => {
                    state.onSave(res.data);
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [keycloak, initialized, state, setState, initState]);
    //== Handlers ===============================================================
    const handleCreate = (): void => {
        setState({...state, name: "saving"});
    };
    //== Render =================================================================
    if (state.name === "saving") {
        return <LoadingRow />;
    }
    let notification: JSX.Element | null = null;
    if (state.name === "failed") {
        notification = <ErrorNotification message={t("people.notifications.create_fail")} />;
    }
    // - default
    return (
        <TableRow key="new">
            <TableCell align="center">
                <TextField
                    label={t("people.firstname")}
                    value={state.formFirstname}
                    fullWidth
                    required
                    onChange={(e): void => setState({...state, formFirstname: e.target.value})}
                />
            </TableCell>
            <TableCell align="center">
                <TextField
                    label={t("people.lastname")}
                    value={state.formLastname}
                    fullWidth
                    required
                    onChange={(e): void => setState({...state, formLastname: e.target.value})}
                />
            </TableCell>
            <TableCell align="center" colSpan={2}>
                <TextField
                    label={t("people.titlesBefore")}
                    value={state.formTitlesBefore}
                    fullWidth
                    required
                    onChange={(e): void => setState({...state, formTitlesBefore: e.target.value})}
                />
            </TableCell>
            <TableCell align="center" colSpan={2}>
                <TextField
                    label={t("people.titlesAfter")}
                    value={state.formTitlesAfter}
                    fullWidth
                    required
                    onChange={(e): void => setState({...state, formTitlesAfter: e.target.value})}
                />
            </TableCell>
            <TableCell align="right">
                <CreateActionButton onClick={handleCreate} />
                {notification}
            </TableCell>
        </TableRow>
    );
};

interface PeopleState {
    page: Page<PersonDTO>;
    query: string;
    name: "loading" | "loaded" | "failed";
    action: "init" | "refresh" | "new";
}

const People: React.FC = () => {
    //== Init ===================================================================
    const [t] = useTranslation("user");
    const {keycloak, initialized} = useKeycloak();
    const initState: PeopleState = {
        page: emptyPage<PersonDTO>([]),
        query: "",
        name: "loading",
        action: "init",
    };
    const [state, setState] = React.useState(initState);
    //== Effects ================================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            let url = `/people?page=${state.page.number}&size=${state.page.size}&sort=lastname,asc`;
            if (state.query !== "") {
                url += `&query=${state.query}`;
            }
            API.get<Page<PersonDTO>>(url, createApiConfig(keycloak, initialized))
                .then((res: AxiosResponse<Page<PersonDTO>>) => {
                    setState({...state, page: res.data, name: "loaded"});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [keycloak, initialized, state, setState]);
    //== Handlers ===============================================================
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number): void => {
        setState({
            ...state,
            page: {...state.page, number: value - 1},
            name: "loading",
            action: "init",
        });
    };
    const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setState({...state, query: event.target.value});
    };
    const handleSearchSubmit = (): void => {
        setState({...state, name: "loading"});
    };
    //== Render =================================================================
    if (state.name === "loading") {
        return <Loading />;
    }
    if (state.name === "failed") {
        return <ServerCommunicationAlert />;
    }
    // - default
    let notification: JSX.Element | null = null;
    if (state.name === "loaded" && state.action === "refresh") {
        notification = <InfoNotification message={t("people.notifications.refresh_ok")} />;
    }
    if (state.name === "loaded" && state.action === "new") {
        notification = <SuccessNotification message={t("people.notifications.create_ok")} />;
    }
    const rows = state.page.content.map((person: PersonDTO) => (
        <PersonRow key={Utils.nullToEmpty(person.id)} person={person} />
    ));
    return (
        <Box>
            <Box display="flex" justifyContent="center">
                <Box flexGrow={1}>
                    {state.page.totalPages > 1 && (
                        <Pagination
                            style={{marginTop: "1em"}}
                            count={state.page.totalPages}
                            page={state.page.number + 1}
                            onChange={handlePageChange}
                        />
                    )}
                </Box>
                <form onSubmit={handleSearchSubmit} style={{width: "auto"}}>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <TextField
                            value={state.query}
                            onChange={handleQueryChange}
                            label={t("people.query")}
                            margin="dense"
                            variant="outlined"
                            style={{minWidth: "20em", marginRight: "1em"}}
                        />
                        <IconButton onClick={handleSearchSubmit} size="small">
                            <SearchIcon />
                        </IconButton>
                    </Box>
                </form>
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">{t("people.lastname")}</TableCell>
                        <TableCell align="center">{t("people.firstname")}</TableCell>
                        <TableCell align="center">{t("people.titlesBefore")}</TableCell>
                        <TableCell align="center">{t("people.titlesAfter")}</TableCell>
                        <TableCell align="center">{t("people.createdAt")}</TableCell>
                        <TableCell align="right">
                            <RefreshActionButton
                                onClick={(): void =>
                                    setState({
                                        ...initState,
                                        action: "refresh",
                                    })
                                }
                            />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{rows}</TableBody>
                <TableFooter>
                    <PersonNewRow onSave={(): void => setState({...initState, action: "new"})} />
                </TableFooter>
            </Table>
            {state.page.totalPages > 1 && (
                <Pagination
                    style={{marginTop: "1em"}}
                    count={state.page.totalPages}
                    page={state.page.number + 1}
                    onChange={handlePageChange}
                />
            )}
            {notification}
        </Box>
    );
};

export default People;
