import React from "react";
import {useTranslation} from "react-i18next";
// Material UI imports
import {Box, Checkbox, FormControlLabel} from "@material-ui/core";
// Project imports
import {DeleteActionButton, InfoActionButton, SwapVerticallyActionButton} from "../../../common/buttons";
import {TrackedGoalInGroupDTO, TrackedIndicatorInGroupDTO} from "../../../../models/trackedIndicators";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {GoalDialog} from "../../../values/dialogs/GoalDialog";
import TrackedIndicator from "./TrackedIndicator";
import {DeleteConfirmationDialog} from "../../../common/dialogs";
import ItemPositionInLibrary, {GoalPositionInLibrary} from "../../../library/editor/util/LibraryItemPosition";
import LibraryItemMoveTarget from "../../../library/editor/util/LibraryItemMoveTarget";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        goalRow: {
            borderWidth: 0,
            borderStyle: "solid",
            borderBottomWidth: 1,
            borderColor: "rgb(145,145,145)",
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(0.5),
            marginLeft: theme.spacing(2),
            paddingLeft: theme.spacing(1),
            display: "flex",
        },
        dragHandle: {},
        goalItemName: {
            fontSize: "120%",
            fontWeight: "bold",
            marginTop: theme.spacing(1.5),
        },
        infoButton: {
            flexGrow: 1,
        },
        publicCheckbox: {
            marginTop: theme.spacing(0.4),
        },
        indicatorShiftTarget: {
            marginLeft: theme.spacing(5),
        },
    })
);

interface TrackedGoalCommonProps {
    trackedGoal: TrackedGoalInGroupDTO;
    itemPosition: GoalPositionInLibrary;
    shiftingItemPosition: ItemPositionInLibrary | null;

    handleShiftStart(itemPosition: GoalPositionInLibrary): void;

    handleRemoveItem(itemPosition: GoalPositionInLibrary): void;
}

interface TrackedGoalRowProps extends TrackedGoalCommonProps {
    handlePublicSwitch(): void;
}

interface TrackedGoalProps extends TrackedGoalCommonProps {
    handleShiftEnd(position: GoalPositionInLibrary): void;

    handlePublicSwitch(modifiedItem: TrackedGoalInGroupDTO): void;
}

const TrackedGoalRow: React.FC<TrackedGoalRowProps> = (props: TrackedGoalRowProps) => {
    //== Init ===================================================================
    const classes = useStyles();
    const [t] = useTranslation("trackedIndicators");
    const [state, setState] = React.useState({deleteConfirmationOpen: false, infoOpen: false});

    //== Handlers ===============================================================
    const handleInfoOpen = (): void => {
        setState({...state, infoOpen: true});
    };
    const handleInfoClose = (): void => {
        setState({...state, infoOpen: false});
    };
    const handleCancelDelete = (): void => {
        setState({...state, deleteConfirmationOpen: false});
    };
    const handleConfirmDelete = (): void => {
        props.handleRemoveItem(props.itemPosition);
        setState({...state, deleteConfirmationOpen: false});
    };
    const handleDelete = (): void => {
        if (!props.trackedGoal.trackedIndicators.length) return handleConfirmDelete();
        setState({...state, deleteConfirmationOpen: true, infoOpen: false});
    };
    const isShiftingOtherThanThisOne =
        props.shiftingItemPosition &&
        (props.shiftingItemPosition.domainIndex !== props.itemPosition.domainIndex ||
            props.shiftingItemPosition.goalIndex !== props.itemPosition.goalIndex ||
            props.shiftingItemPosition.indicatorIndex !== props.itemPosition.indicatorIndex);
    return (
        <>
            <div className={classes.goalRow}>
                <div className={classes.dragHandle} style={isShiftingOtherThanThisOne ? {visibility: "hidden"} : {}}>
                    <SwapVerticallyActionButton onClick={() => props.handleShiftStart(props.itemPosition)} />
                </div>
                <div className={classes.goalItemName}>
                    {t("library.goal") +
                        " " +
                        (props.itemPosition.domainIndex + 1) +
                        "." +
                        (props.itemPosition.goalIndex + 1) +
                        ": " +
                        props.trackedGoal.goal.name}
                </div>
                <Box className={classes.infoButton}>
                    <InfoActionButton onClick={handleInfoOpen} />
                </Box>
                <div className={classes.publicCheckbox}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={props.trackedGoal.public}
                                disabled={!containsGoalIndicators(props.trackedGoal)}
                                onChange={(e): void => props.handlePublicSwitch()}
                                name="public"
                            />
                        }
                        label={t("use.public")}
                    />
                </div>
                <div>
                    <DeleteActionButton onClick={handleDelete} />
                    <DeleteConfirmationDialog
                        title={t("ui.editor.deleteGoalWithIndicatorDialog.title")}
                        text={t("ui.editor.deleteGoalWithIndicatorDialog.text", {name: props.trackedGoal.goal.name})}
                        open={state.deleteConfirmationOpen}
                        id={"delete-dialog-" + props.trackedGoal.id}
                        onDelete={handleConfirmDelete}
                        onCancel={handleCancelDelete}
                    />
                </div>
            </div>
            <GoalDialog goal={props.trackedGoal.goal} handleClose={handleInfoClose} open={state.infoOpen} />
        </>
    );
};

export const trackedGoalWithPublicContent = (
    goalUse: TrackedGoalInGroupDTO,
    publicValue: boolean
): TrackedGoalInGroupDTO => {
    return {
        ...goalUse,
        public: publicValue,
        trackedIndicators: goalUse.trackedIndicators.map((indicatorInUse) =>
            indicatorInUse.public === publicValue ? indicatorInUse : {...indicatorInUse, public: publicValue}
        ),
    };
};

export const containsGoalIndicators = (goalUse: TrackedGoalInGroupDTO): boolean => {
    return goalUse.trackedIndicators.length > 0;
};

const TrackedGoal: React.FC<TrackedGoalProps> = (props: TrackedGoalProps) => {
    const classes = useStyles();
    const handlePublicSwitch = () => {
        props.handlePublicSwitch(trackedGoalWithPublicContent(props.trackedGoal, !props.trackedGoal.public));
        return;
    };
    const handleContentPublicSwitch = (modifiedItem: TrackedIndicatorInGroupDTO) => {
        let goalUsePublic = props.trackedGoal.public;
        if (modifiedItem.public && !goalUsePublic) {
            goalUsePublic = true;
        } else if (
            !modifiedItem.public &&
            goalUsePublic &&
            props.trackedGoal.trackedIndicators.every(
                (indicatorUse) => indicatorUse.indicator.id === modifiedItem.indicator.id || !indicatorUse.public
            )
        ) {
            goalUsePublic = false;
        }
        const newTrackedIndicators = props.trackedGoal.trackedIndicators.map((indicatorInUse) =>
            indicatorInUse.indicator.id === modifiedItem.indicator.id ? modifiedItem : indicatorInUse
        );
        props.handlePublicSwitch({
            ...props.trackedGoal,
            public: goalUsePublic,
            trackedIndicators: newTrackedIndicators,
        });
    };
    const isShiftingIndicator =
        props.shiftingItemPosition !== null && props.shiftingItemPosition.indicatorIndex !== null;
    return (
        <>
            <TrackedGoalRow
                key={props.trackedGoal.id + "-row"}
                trackedGoal={props.trackedGoal}
                itemPosition={props.itemPosition}
                shiftingItemPosition={props.shiftingItemPosition}
                handleShiftStart={props.handleShiftStart}
                handleRemoveItem={props.handleRemoveItem}
                handlePublicSwitch={handlePublicSwitch}
            />
            {props.trackedGoal.trackedIndicators
                .map((indicatorInUse: TrackedIndicatorInGroupDTO, index) => {
                    const position = {
                        domainIndex: props.itemPosition.domainIndex,
                        goalIndex: props.itemPosition.goalIndex,
                        indicatorIndex: index,
                    };
                    const isShiftingAdjacentIndicator =
                        props.shiftingItemPosition &&
                        props.shiftingItemPosition.goalIndex !== null &&
                        props.shiftingItemPosition.indicatorIndex !== null &&
                        props.shiftingItemPosition.domainIndex == props.itemPosition.domainIndex &&
                        props.shiftingItemPosition.goalIndex == props.itemPosition.goalIndex &&
                        (props.shiftingItemPosition.indicatorIndex == index ||
                            props.shiftingItemPosition.indicatorIndex + 1 == index);
                    return (
                        <>
                            <LibraryItemMoveTarget
                                key={indicatorInUse.id + "-move-target"}
                                className={classes.indicatorShiftTarget}
                                active={isShiftingIndicator && !isShiftingAdjacentIndicator}
                                onAction={() => props.handleShiftEnd(position)}
                            />
                            <TrackedIndicator
                                key={indicatorInUse.id}
                                trackedIndicator={indicatorInUse}
                                itemPosition={position}
                                shiftingItemPosition={props.shiftingItemPosition}
                                handleShiftStart={props.handleShiftStart}
                                handleShiftEnd={props.handleShiftEnd}
                                handleRemoveItem={props.handleRemoveItem}
                                handlePublicSwitch={handleContentPublicSwitch}
                            />
                        </>
                    );
                })
                .concat([
                    <LibraryItemMoveTarget
                        key={props.trackedGoal.id + "-end-move-target"}
                        className={classes.indicatorShiftTarget}
                        active={
                            isShiftingIndicator &&
                            !(
                                props.shiftingItemPosition &&
                                props.shiftingItemPosition.goalIndex !== null &&
                                props.shiftingItemPosition.indicatorIndex !== null &&
                                props.shiftingItemPosition.domainIndex == props.itemPosition.domainIndex &&
                                props.shiftingItemPosition.goalIndex == props.itemPosition.goalIndex &&
                                props.shiftingItemPosition.indicatorIndex + 1 ==
                                    props.trackedGoal.trackedIndicators.length
                            )
                        }
                        onAction={() =>
                            props.handleShiftEnd({
                                domainIndex: props.itemPosition.domainIndex,
                                goalIndex: props.itemPosition.goalIndex,
                                indicatorIndex: props.trackedGoal.trackedIndicators.length,
                            })
                        }
                    />,
                ])}
        </>
    );
};

export default TrackedGoal;
