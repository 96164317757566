import React from "react";
import {OptionDTO, UnitDTO, UnitSimpleDTO} from "../../models/library";
import {ValueDTO} from "../../models/values";
import {Box, TextField} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {CancelActionButton, DeleteActionButton, SaveActionButton} from "../common/buttons";
import {OptionsSelect, UnitsSelect} from "../common/values";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {UnitUtils} from "../../utils";

interface ValueFormProps {
    formClassName?: string;
    contentClassName?: string;
    disabled?: boolean;
    options: OptionDTO[];
    preferredUnit: UnitDTO | null;
    minimumValue: ValueDTO | null;
    maximumValue: ValueDTO | null;
    value: number | null;
    unit: UnitSimpleDTO | null;

    onSubmit(value: number, unit: UnitSimpleDTO | null): void;

    onDelete?(): void;

    onCancel(): void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        singleUnit: {
            margin: theme.spacing(1),
        },
    })
);

export const ValueForm: React.FC<ValueFormProps> = (props: ValueFormProps) => {
    // TODO: solve problem with min/max value with respect to units!
    // TODO: nice loading overlay for buttons when disabled?
    //== Init =================================================================
    const classes = useStyles();
    const [t] = useTranslation(["values"]);
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm({mode: "onBlur"});
    const minValue = props.minimumValue?.value;
    const maxValue = props.maximumValue?.value;
    const possibleUnits = UnitUtils.possibleUnitsFromUnit(props.preferredUnit);
    const [state, setState] = React.useState({
        value: props.value,
        unit: props.unit || props.preferredUnit,
    });
    const valueDiffers =
        (props.value != state.value && state.value != null && (props.preferredUnit == null || state.unit != null)) ||
        (props.unit?.id != state.unit?.id &&
            (props.preferredUnit == null || state.unit != null) &&
            state.value != null);
    //== Handlers =============================================================
    const handleValueSubmit = (): void => {
        if (state.value === null) return;
        props.onSubmit(state.value, state.unit);
    };
    const handleValueDelete = (): void => {
        if (props.onDelete === undefined) return;
        props.onDelete();
    };
    const handleFormCancel = (): void => {
        props.onCancel();
    };
    //== Render ===============================================================
    const valueRegister = register("value", {required: true, min: minValue, max: maxValue});
    return (
        <form className={props.formClassName} onSubmit={handleSubmit(handleValueSubmit)}>
            <Box className={props.contentClassName} display="flex" flexWrap="wrap">
                <Box display="flex" alignItems="baseline">
                    {props.options.length ? (
                        <OptionsSelect
                            options={props.options}
                            value={state.value || props.options[0].value.value}
                            onChange={(newValue): void => setState({...state, value: newValue})}
                        />
                    ) : (
                        <TextField
                            {...valueRegister}
                            label={t("values_input." + valueRegister.name)}
                            value={state.value == null || isNaN(state.value) ? "" : state.value}
                            type="number"
                            variant="outlined"
                            InputProps={{inputProps: {min: minValue, max: maxValue}}}
                            onChange={(e): void => {
                                setState({
                                    ...state,
                                    value: e.target.value === "" ? null : parseFloat(e.target.value),
                                });
                            }}
                            inputRef={valueRegister.ref}
                            error={errors.value !== undefined}
                            helperText={errors.value && errors.value.message}
                        />
                    )}
                    {state.unit && possibleUnits.length <= 1 ? (
                        <span className={classes.singleUnit}>{state.unit?.abbreviation}</span>
                    ) : null}
                </Box>
                {possibleUnits.length > 1 ? (
                    <Box>
                        <UnitsSelect
                            unitUUID={(state.unit || possibleUnits[0]).id}
                            units={possibleUnits}
                            onChange={(unitUUID): void =>
                                setState({
                                    ...state,
                                    unit: possibleUnits.find((unit) => unit.id === unitUUID) || null,
                                })
                            }
                        />
                    </Box>
                ) : null}
                <Box>
                    <SaveActionButton disabled={!valueDiffers} key="submit" onClick={handleSubmit(handleValueSubmit)} />
                    {props.onDelete && (
                        <DeleteActionButton disabled={props.value === null} key="delete" onClick={handleValueDelete} />
                    )}
                    <CancelActionButton key="cancel" onClick={handleFormCancel} />
                </Box>
            </Box>
        </form>
    );
};

export default ValueForm;
