import {compareByAttr} from "../utils/compare";

export interface ErrorDeleteDTO {
    message: string;
    reasons: string[];
    relatedEntities: ErrorEntityDTO[];
}

export interface ErrorEntityDTO {
    id: string;
    parentId: string | null;
    type: string;
    displayName: string;
    extra: Record<string, string>;
}

export function rectifyErrorDelete(error?: ErrorDeleteDTO): ErrorDeleteDTO {
    return {
        message: "",
        reasons: [],
        relatedEntities: [],
        ...error,
    };
}

export function extractEntitiesByType(error: ErrorDeleteDTO, type: string): ErrorEntityDTO[] {
    return error.relatedEntities.filter((item) => item.type === type).sort(compareByAttr("displayName", "ascending"));
}
