import React from "react";
import {useTranslation} from "react-i18next";
// Material-UI imports
import {Button, FormControl, TextField} from "@material-ui/core";
import {PeriodCreateDTO} from "../../../models/library";
import {useStyles} from "../../../styles";

// Project imports

interface PeriodFormProps {
    period: PeriodCreateDTO;

    onChange(period: PeriodCreateDTO): void;

    onSubmit(): void;
}

export const PeriodForm: React.FC<PeriodFormProps> = (props: PeriodFormProps) => {
    const [t] = useTranslation("libraries");
    const classes = useStyles();
    return (
        <FormControl fullWidth>
            <TextField
                label={t("period.referenceDate")}
                value={props.period.referenceDate}
                onChange={(e): void => {
                    props.onChange({...props.period, referenceDate: e.target.value});
                }}
                required
                fullWidth
                variant="outlined"
                type="date"
                className={classes.spaceAfter}
            />
            <TextField
                label={t("period.repeatDay")}
                value={props.period.repeatDay}
                onChange={(e): void => {
                    props.onChange({...props.period, repeatDay: parseInt(e.target.value)});
                }}
                multiline
                fullWidth
                variant="outlined"
                type="number"
                className={classes.spaceAfter}
            />
            <TextField
                label={t("period.repeatMonth")}
                value={props.period.repeatMonth}
                onChange={(e): void => {
                    props.onChange({...props.period, repeatMonth: parseInt(e.target.value)});
                }}
                multiline
                fullWidth
                variant="outlined"
                type="number"
                className={classes.spaceAfter}
            />
            <TextField
                label={t("period.repeatYear")}
                value={props.period.repeatYear}
                onChange={(e): void => {
                    props.onChange({...props.period, repeatYear: parseInt(e.target.value)});
                }}
                multiline
                fullWidth
                variant="outlined"
                type="number"
                className={classes.spaceAfter}
            />
            <Button onClick={props.onSubmit} variant="contained" color="primary">
                {t("admin.save")}
            </Button>
        </FormControl>
    );
};
