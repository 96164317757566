import React, {ChangeEvent} from "react";
import {VariableSimpleDTO} from "../../../../../models/library";
import {MenuItem, Select, TextField} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {Autocomplete} from "@material-ui/lab";
import {compareByAttr} from "../../../../../utils/compare";

interface VariableSelectProps {
    value: string;
    variables: VariableSimpleDTO[];
    usedVariables: VariableSimpleDTO[];
    onChange: (value: string) => void;
}

interface VariableOption {
    id: string;
    title: string;
    group: "used" | "available";
}

export const VariableSelect: React.FC<VariableSelectProps> = (props: VariableSelectProps) => {
    //== Init =================================================================
    const [t] = useTranslation("libraries");
    //== Handlers =============================================================
    //eslint-disable-next-line @typescript-eslint/ban-types
    const handleChange = (event: ChangeEvent<{}>, value: VariableOption | null) => {
        props.onChange(value === null ? "" : value.id);
    };
    //== Render ===============================================================
    if (props.variables.length === 0) {
        return (
            <Select value={""} disabled displayEmpty>
                <MenuItem>{t("formula.variables.empty")}</MenuItem>
            </Select>
        );
    }
    const titleComparator = compareByAttr("title", "ascending");
    const usedIds = new Set<string>(props.usedVariables.map((v) => v.id));
    const options: VariableOption[] = props.variables
        .map((v): VariableOption => {
            return {
                id: v.id,
                title: v.name,
                group: usedIds.has(v.id) ? "used" : "available",
            };
        })
        .sort((a, b): number => {
            if (a.group === "used" && b.group === "available") {
                return -1;
            }
            if (b.group === "used" && a.group === "available") {
                return 0;
            }
            return titleComparator(a, b);
        });
    const counts = {
        used: options.filter((o) => o.group === "used").length,
        available: options.filter((o) => o.group === "available").length,
    };
    const value: VariableOption | undefined = options.find((v) => v.id === props.value);
    const groups = {
        used: t(`formula.variables.used`, {count: counts.used}),
        available: t(`formula.variables.available`, {count: counts.available}),
    };
    return (
        <Autocomplete
            value={value}
            options={options}
            groupBy={(option) => groups[option.group]}
            getOptionLabel={(option) => option.title}
            getOptionSelected={(option, value): boolean => {
                return option.id === value.id;
            }}
            renderInput={(params) => <TextField {...params} />}
            onChange={handleChange}
            disableClearable
        />
    );
};
