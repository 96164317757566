import React from "react";
import {useTranslation} from "react-i18next";
import {Container} from "@material-ui/core";
import {BreadcrumbItem, BreadcrumbsRow} from "../common/breadcrumbs";
import ROUTES from "../../routes/routes";
import {PageHeader} from "../common/headers";
import {LibraryFormCreate} from "./LibraryFormCreate";
import {useParams} from "react-router-dom";
import {OrganizationDTO} from "../../models/organization";
import {useKeycloak} from "@react-keycloak/web";
import {API, createApiConfig} from "../../utils/API";
import {AxiosError} from "axios";
import {LoadingBackdrop} from "../common/Loading";
import {CommunicationError, NotAuthorized, NotFound} from "../common/errors";

interface LibrariesOrgParams {
    organizationId: string;
}

interface LibraryNewOrgState {
    organization: OrganizationDTO | null;
    name: "loading" | "loaded" | "failed" | "not_found" | "unauthorized";
}

export const LibraryNewOrg: React.FC = () => {
    //== Init =================================================================
    const {organizationId} = useParams<LibrariesOrgParams>();
    const {keycloak, initialized} = useKeycloak();
    const [t] = useTranslation("libraries");
    const initState: LibraryNewOrgState = {
        name: "loading",
        organization: null,
    };
    const [state, setState] = React.useState(initState);
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<OrganizationDTO>(`/organizations/${organizationId}`, createApiConfig(keycloak, initialized))
                .then((res) => {
                    setState({...state, organization: res.data, name: "loaded"});
                })
                .catch((err: AxiosError) => {
                    if (err?.response?.status === 404) {
                        setState({...state, name: "not_found"});
                    } else if (err?.response?.status === 403 || err?.response?.status === 401) {
                        setState({...state, name: "unauthorized"});
                    } else {
                        setState({...state, name: "failed"});
                    }
                });
        }
    }, [state, setState, organizationId, keycloak, initialized]);
    //== Render ===============================================================
    if (state.name === "loading") {
        return <LoadingBackdrop />;
    }
    if (state.name === "not_found") {
        return <NotFound />;
    }
    if (state.name === "unauthorized") {
        return <NotAuthorized />;
    }
    if (state.name === "failed") {
        return <CommunicationError />;
    }
    if (state.organization === null) {
        return <LoadingBackdrop />;
    }
    return (
        <Container>
            <BreadcrumbsRow>
                <BreadcrumbItem
                    name={state.organization.name}
                    route={ROUTES.organization.create(state.organization.publicId)}
                />
                <BreadcrumbItem
                    name={t("library.titles.libraries")}
                    route={ROUTES.organizationLibraries.create(state.organization.publicId)}
                />
                <BreadcrumbItem name={t("library.titles.new_library")} />
            </BreadcrumbsRow>
            <PageHeader title={t("library.titles.new_library")} />
            <LibraryFormCreate organizationId={state.organization.id} />
        </Container>
    );
};
