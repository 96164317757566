interface BuildInfoInternal {
    version: string;
    builtAt: string;
}

interface BuildInfo {
    version: string;
    builtAt: Date;
}

declare global {
    interface Window {
        buildInfo: BuildInfoInternal;
    }
}

export const BUILD_INFO: BuildInfo = {
    version: window.buildInfo.version,
    builtAt: new Date(window.buildInfo.builtAt),
};
