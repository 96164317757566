import React from "react";
import {FormulaNode, getDefaultOperands, getDefaultOperationValue} from "../../../../../models/formulaEditor";
import {OperationType} from "../../../../../models/formulas";
import {Box} from "@material-ui/core";
import {ConversionOperationSelect} from "./ConversionOperationSelect";

interface ConversionBaseVarFormProps {
    node: FormulaNode;
    onChange: (node: FormulaNode) => void;
}

export const ConversionBaseVarForm: React.FC<ConversionBaseVarFormProps> = (props: ConversionBaseVarFormProps) => {
    //== Handlers =============================================================
    const handleOperationChange = (operation: OperationType) => {
        props.onChange({
            ...props.node,
            opType: operation,
            value: getDefaultOperationValue(operation),
            operands: getDefaultOperands(operation),
        });
    };
    //== Render ===============================================================
    return (
        <Box display="flex">
            <ConversionOperationSelect value={OperationType.VAR} onChange={handleOperationChange} />
            <Box flexGrow="1" />
        </Box>
    );
};
