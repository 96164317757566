import React from "react";
import {InputVariableDTO} from "../../../models/library";
import {InputVariableDialog} from "./InputVariableDialog";
import {API, createApiConfig} from "../../../utils/API";
import {useKeycloak} from "@react-keycloak/web";
import {AxiosError, AxiosResponse} from "axios";
import {useTranslation} from "react-i18next";
import {ErrorNotification} from "../../common/notifications";

interface InputVariableInfoProps {
    variableId: string | null;
    handleClose: () => void;
}

interface InputVariableInfoState {
    inputVariableId: string | null;
    inputVariable: InputVariableDTO | null;
    errored: boolean;
}

export const InputVariableInfo: React.FC<InputVariableInfoProps> = (props: InputVariableInfoProps) => {
    //== Init =================================================================
    const initState: InputVariableInfoState = {
        inputVariableId: null,
        inputVariable: null,
        errored: false,
    };
    const [state, setState] = React.useState(initState);
    const {keycloak, initialized} = useKeycloak();
    const [t] = useTranslation(["values"]);
    //== Effects ==============================================================
    React.useEffect(() => {
        if (props.variableId === null && props.variableId !== state.inputVariableId) {
            setState({inputVariableId: null, inputVariable: null, errored: false});
        } else if (props.variableId !== state.inputVariableId) {
            API.get<InputVariableDTO>(
                `/input-variables/by-variable/${props.variableId}`,
                createApiConfig(keycloak, initialized)
            )
                .then((res: AxiosResponse<InputVariableDTO>) => {
                    setState({inputVariableId: props.variableId, inputVariable: res.data, errored: false});
                })
                .catch((err: AxiosError) => {
                    setState({inputVariableId: props.variableId, inputVariable: null, errored: true});
                });
        }
    }, [props.variableId, state, setState, keycloak, initialized]);
    //== Render ===============================================================
    if (state.inputVariable === null) {
        if (state.errored) {
            return <ErrorNotification message={t("input_variable.failed_get")} />;
        }
        return null;
    }
    return (
        <InputVariableDialog
            inputVariable={state.inputVariable}
            open={props.variableId !== null}
            handleClose={props.handleClose}
        />
    );
};
