import React from "react";
import {useTranslation} from "react-i18next";
import {GenericEntityDeleteContentProps, GenericEntityNoLinkedDelete} from "../../../common/delete";
import {
    TrackedDomainInGroupDTO,
    TrackedIndicatorGroupSimpleDTO,
    TrackedIndicatorGroupWithContentDTO,
} from "../../../../models/trackedIndicators";
import {useKeycloak} from "@react-keycloak/web";
import {API, createApiConfig} from "../../../../utils";
import {AxiosError, AxiosResponse} from "axios";

interface TrackedIndicatorGroupRenderDeleteState {
    name: "loading" | "loaded" | "failed";
    group: TrackedIndicatorGroupWithContentDTO | null;
}

const TrackedIndicatorGroupRenderDelete: React.FC<GenericEntityDeleteContentProps> = (
    props: GenericEntityDeleteContentProps
) => {
    // TODO: count also computed values for indicators?
    //== Init =================================================================
    const [t] = useTranslation("trackedIndicators");
    const tPrefix = "trackedIndicatorGroup.confirm_delete.render";
    const {keycloak, initialized} = useKeycloak();
    const initState: TrackedIndicatorGroupRenderDeleteState = {
        name: "loading",
        group: null,
    };
    const [state, setState] = React.useState(initState);
    const groupId = props.entity.id;
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get(`/tracked-indicator-groups/with-content/${groupId}`, createApiConfig(keycloak, initialized))
                .then((r: AxiosResponse<TrackedIndicatorGroupWithContentDTO>) => {
                    setState({...state, name: "loaded", group: r.data});
                })
                .catch((e: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [state.name, keycloak, initialized]);
    //== Render ===============================================================
    const domains: TrackedDomainInGroupDTO[] = state.group?.trackedDomains ?? [];
    return (
        <ul>
            {domains.map((domain) => {
                const goals = domain.trackedGoals;
                const indicators = goals.map((g) => g.trackedIndicators).flat();
                return (
                    <li key={domain.id}>
                        {t(`${tPrefix}.domain`, {
                            title: domain.domain.name,
                            goals: goals.length,
                            indicators: indicators.length,
                        })}
                    </li>
                );
            })}
        </ul>
    );
};

interface TrackedIndicatorGroupDeleteProps {
    entity: TrackedIndicatorGroupSimpleDTO | null;
    onDeleted: () => void;
    onCancel: () => void;
    onFailed: () => void;
}

export const TrackedIndicatorGroupDelete: React.FC<TrackedIndicatorGroupDeleteProps> = (
    props: TrackedIndicatorGroupDeleteProps
) => {
    return (
        <GenericEntityNoLinkedDelete
            tNs="trackedIndicators"
            tPrefix="trackedIndicatorGroup"
            urlSlug="tracked-indicator-groups"
            RenderContent={TrackedIndicatorGroupRenderDelete}
            {...props}
        />
    );
};
