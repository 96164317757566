import {KeycloakInstance} from "keycloak-js";

export const ROLES = {
    admin: "ADMIN",
    user: "USER",
    developer: "DEV",
};

export function hasUserRole(keycloak: KeycloakInstance, role: string): boolean {
    if (!keycloak.authenticated) {
        console.log("User is not authenticated.");
        return false;
    }
    //console.log("keycloak.tokenParsed:");
    //console.log(keycloak.tokenParsed);
    const roles = keycloak.tokenParsed?.resource_access?.sccompass?.roles;
    if (roles === undefined) {
        console.log("User is not a valid SC-Compass user (no roles in token).");
    }
    return keycloak.tokenParsed?.resource_access?.sccompass?.roles?.includes(role) ?? false;
}

export function isUser(keycloak: KeycloakInstance): boolean {
    return hasUserRole(keycloak, ROLES.user);
}

export function isAdmin(keycloak: KeycloakInstance): boolean {
    return hasUserRole(keycloak, ROLES.admin);
}

export function isDeveloper(keycloak: KeycloakInstance): boolean {
    return hasUserRole(keycloak, ROLES.developer);
}
