import React from "react";
import {DomainCreateDTO, emptyDomainCreateDTO, LibraryDTO, nextOrderNumber} from "../../../../models/library";
import {useKeycloak} from "@react-keycloak/web";
import {API, createApiConfig} from "../../../../utils/API";
import {AxiosError} from "axios";
import {Box, Button} from "@material-ui/core";
import {FormDialog} from "../forms/FormDialog";
import {DomainForm} from "../forms/DomainForm";
import AddIcon from "@material-ui/icons/Add";
import {useTranslation} from "react-i18next";

interface LibraryDomainCreateRowProps {
    library: LibraryDTO;
    onReload: (cause: string) => void;
    onUpdate: (library: LibraryDTO) => void;
}

interface LibraryDomainCreateRowState {
    name: "loaded" | "saving" | "failed";
    opened: boolean;
    newDomain: DomainCreateDTO;
}

export const LibraryDomainCreateRow: React.FC<LibraryDomainCreateRowProps> = (props: LibraryDomainCreateRowProps) => {
    // TODO: improve style when nested library
    //== Init =================================================================
    const [t] = useTranslation("libraries");
    const {keycloak, initialized} = useKeycloak();
    const nextOrder = nextOrderNumber(props.library.libraryDomainUses);
    console.log(`Next order number for DOMAIN is: ${nextOrder}`);
    const emptyDomain: DomainCreateDTO = {
        ...emptyDomainCreateDTO,
        libraryUUID: props.library.id,
        orderNumber: nextOrder,
    };
    const initState: LibraryDomainCreateRowState = {
        name: "loaded",
        opened: false,
        newDomain: emptyDomain,
    };
    const [state, setState] = React.useState(initState);
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "saving") {
            API.post<LibraryDTO>(`/domains/in-library`, state.newDomain, createApiConfig(keycloak, initialized))
                .then((res) => {
                    setState({...state, name: "loaded", newDomain: emptyDomain, opened: false});
                    props.onUpdate(res.data);
                })
                .catch((err: AxiosError) => {
                    console.log(err);
                    setState({...state, name: "failed"});
                });
        }
    }, [state, setState]);
    //== Handlers =============================================================
    const handleNewChange = (domain: DomainCreateDTO): void => {
        setState({...state, newDomain: domain});
    };
    const handleNewClose = (): void => {
        setState({...state, opened: false});
    };
    const handleNewClick = (): void => {
        setState({...state, opened: true});
    };
    const handleNewSubmit = (): void => {
        setState({...state, name: "saving", opened: true});
    };
    //== Render ===============================================================
    return (
        <Box display="flex" justifyContent="flex-end" style={{padding: "0.4em"}}>
            <Button onClick={handleNewClick} startIcon={<AddIcon />} variant="outlined" color="primary">
                {t("domain.new.name")}
            </Button>
            <FormDialog
                id="new-domain"
                title={t("domain.new.name")}
                open={state.opened}
                onClose={handleNewClose}
                actions={[
                    <Button key="submit" onClick={handleNewSubmit}>
                        {t("domain.new.button")}
                    </Button>,
                ]}
            >
                <DomainForm domain={state.newDomain} published={false} onChange={handleNewChange} />
            </FormDialog>
        </Box>
    );
};
