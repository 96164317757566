import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {IndicatorDTO} from "../../../models/library";
import {Tags} from "../../common/tags";
import {Alert} from "@material-ui/lab";
import {ValueUtils} from "../../../utils";
import {LibrariesWithElement} from "./LibrariesWithElement";
import {FormulaDisplay} from "../../library/formulas/FormulaDisplay";
import {FormulaVariables} from "../../library/formulas/FormulaVariables";
import {MarkdownRender} from "../../common/markdown";
import {compareByAttr} from "../../../utils/compare";

interface IndicatorDialogProps {
    indicator: IndicatorDTO;
    open: boolean;

    handleClose(): void;
}

export const IndicatorDialog: React.FC<IndicatorDialogProps> = (props: IndicatorDialogProps) => {
    const [t] = useTranslation(["values"]);
    //== Render ===============================================================
    return (
        <Dialog open={props.open} onClose={props.handleClose} scroll="paper" maxWidth="md" fullWidth>
            <DialogTitle>{t("indicator.dialog.title", {name: props.indicator.name})}</DialogTitle>
            <DialogContent>
                <h3>{t("indicator.description")}</h3>
                {props.indicator.description ? (
                    <MarkdownRender value={props.indicator.description} />
                ) : (
                    <Alert severity="info">{t("indicator.dialog.missing.description")}</Alert>
                )}
                <h3>{t("indicator.definition")}</h3>
                {props.indicator.definition ? (
                    <MarkdownRender value={props.indicator.definition} />
                ) : (
                    <Alert severity="info">{t("indicator.dialog.missing.definition")}</Alert>
                )}
                {/*
                {props.indicator.processMethod && <h3>{t("indicator.processMethod")}</h3>}
                {props.indicator.processMethod && <MarkdownRender value={props.indicator.processMethod} />}
                */}
                {props.indicator.validityComment && <h3>{t("indicator.validityComment")}</h3>}
                {props.indicator.validityComment && <MarkdownRender value={props.indicator.validityComment} />}
                {props.indicator.presentationRecommendation && <h3>{t("indicator.presentationRecommendation")}</h3>}
                {props.indicator.presentationRecommendation && (
                    <MarkdownRender value={props.indicator.presentationRecommendation} />
                )}
                <LibrariesWithElement elementType="indicator" id={props.indicator.id} />
                <h3>{t("indicator.tags")}</h3>
                {props.indicator.tags.length > 0 ? (
                    <Tags tags={props.indicator.tags} />
                ) : (
                    <Alert severity="info">{t("indicator.dialog.missing.tags")}</Alert>
                )}
                <h3>{t("indicator.computation")}</h3>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell variant="head">{t("indicator.formula")}</TableCell>
                            <TableCell>
                                <FormulaDisplay
                                    formula={props.indicator.formula}
                                    variables={props.indicator.usedVariables}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">{t("indicator.usedVariables")}</TableCell>
                            <TableCell>
                                <FormulaVariables
                                    formula={props.indicator.formula}
                                    variables={props.indicator.usedVariables}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">{t("indicator.direction")}</TableCell>
                            <TableCell>{t(`direction_label.${props.indicator.direction}`)}</TableCell>
                        </TableRow>
                        {props.indicator.type === "quantitative" && (
                            <>
                                <TableRow>
                                    <TableCell variant="head">{t("indicator.minimum_value")}</TableCell>
                                    <TableCell>
                                        {props.indicator.minimumValue === null
                                            ? t("indicator.unlimited")
                                            : ValueUtils.formatValue(props.indicator.minimumValue)}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell variant="head">{t("indicator.maximum_value")}</TableCell>
                                    <TableCell>
                                        {props.indicator.maximumValue === null
                                            ? t("indicator.unlimited")
                                            : ValueUtils.formatValue(props.indicator.maximumValue)}
                                    </TableCell>
                                </TableRow>
                            </>
                        )}
                    </TableBody>
                </Table>
                {props.indicator.type === "qualitative" && (
                    <>
                        <h3>{t("indicator.options")}</h3>
                        <ul>
                            {props.indicator.options.sort(compareByAttr("value", "ascending")).map((option) => (
                                <li key={option.id}>
                                    {option.value.value} = {option.name}
                                </li>
                            ))}
                        </ul>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>{t("indicator.dialog.close")}</Button>
            </DialogActions>
        </Dialog>
    );
};
