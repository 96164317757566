import React, {useState} from "react";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
// Material UI imports
import {Box, Link, Table, TableBody, TableCell, TableFooter, TableHead, TableRow} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
// Project imports
import {
    CreateActionButton,
    DeleteActionButton,
    EditActionButton,
    RefreshActionButton,
    SaveActionButton,
} from "../../../common/buttons";
import {ErrorNotification, InfoNotification, SuccessNotification} from "../../../common/notifications";
import {ServerCommunicationAlert} from "../../../common/errors";
import {Loading, LoadingRow} from "../../../common/Loading";
import API, {createApiConfig} from "../../../../utils/API";
import {AxiosError} from "axios";
import {
    MembershipCreateDTO,
    MembershipDTO,
    MembershipPositionSimpleDTO,
    MembershipUpdateDTO,
} from "../../../../models/members";
import {OrganizationDTO} from "../../../../models/organization";
import Pagination from "@material-ui/lab/Pagination";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {isAdmin} from "../../../../utils/auth";
import {makeFullName, PersonDTO} from "../../../../models/user";
import ROUTES from "../../../../routes/routes";
import {CustomizableConfirmationDialog, DeleteConfirmationDialog} from "../../../common/dialogs";
import {Page} from "../../../../models/pagination";
import {PositionsSelect} from "./PositionsSelect";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            tableLayout: "fixed",
        },
        actionsColumnCell: {
            width: "131px",
            textAlign: "right",
        },
        awaitingAcceptanceNameColumn: {
            color: theme.palette.grey["600"],
            fontStyle: "italic",
        },
        awaitingAcceptanceColumn: {
            fontStyle: "italic",
        },
    })
);

interface MembershipRowProps {
    membership: MembershipDTO;
    availablePositions: MembershipPositionSimpleDTO[];
    isOnlyOneWithFullPermissions: boolean;
}

interface MembershipNewRowProps {
    onSave: (membership: MembershipDTO) => void;
    availablePositions: MembershipPositionSimpleDTO[];
    organizationId: string;
}

interface MembershipRowState {
    person: PersonDTO;
    positions: MembershipPositionSimpleDTO[];
    name:
        | "init"
        | "editing"
        | "confirmingDelete"
        | "deleting"
        | "deleted"
        | "confirmingSave"
        | "saving"
        | "saved"
        | "failed";
    action: "init" | "edit" | "delete";
}

const MembershipRow: React.FC<MembershipRowProps> = (props: MembershipRowProps) => {
    //== Init ===================================================================
    const classes = useStyles();
    const [t] = useTranslation("organizationMembers");
    const {keycloak, initialized} = useKeycloak();
    const [state, setState] = React.useState<MembershipRowState>({
        person: props.membership.person,
        positions: props.membership.positions,
        name: "init",
        action: "init",
    });
    const [formData, setFormData] = useState({
        positions: state.positions,
    });
    //== Effects ================================================================
    React.useEffect(() => {
        const changed =
            JSON.stringify(formData.positions.map((position) => position.id).sort()) !==
            JSON.stringify(state.positions.map((position) => position.id).sort());
        if (state.name === "deleting") {
            API.delete<void>(`/memberships/${props.membership.id}`, createApiConfig(keycloak, initialized))
                .then((res) => {
                    setState({...state, name: "deleted"});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed", action: "delete"});
                    return;
                });
        } else if (state.name === "saving" && changed) {
            console.log("saving");
            const membership: MembershipUpdateDTO = {
                positionIds: formData.positions.map((position) => position.id),
            };
            API.put<MembershipDTO>(
                `/memberships/${props.membership.id}`,
                membership,
                createApiConfig(keycloak, initialized)
            )
                .then((res) => {
                    setState({
                        ...state,
                        person: res.data.person,
                        positions: res.data.positions,
                        name: "saved",
                        action: "edit",
                    });
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed", action: "edit"});
                });
        } else if (state.name === "saving") {
            console.log("nothing to save");
            setState({...state, name: "saved"});
        }
    }, [keycloak, initialized, state, setState, formData, props.membership]);
    //== Handlers ===============================================================
    const handleCancelDelete = (): void => {
        setState({...state, name: "init"});
    };
    const handleConfirmDelete = (): void => {
        setState({...state, name: "deleting"});
    };
    const handleDelete = (): void => {
        setState({...state, name: "confirmingDelete"});
    };
    const handleEdit = (): void => {
        setState({...state, name: "editing"});
    };
    const handleCancelSave = (): void => {
        setState({...state, name: "editing"});
    };
    const handleConfirmSave = (): void => {
        setState({...state, name: "saving"});
    };
    const handleSave = (): void => {
        setState({
            ...state,
            name: !formData.positions.some((position) => position.profilePermissionLevel === "FULL")
                ? "confirmingSave"
                : "saving",
        });
    };

    const handlePositionsChange = (newPositionIds: string[]): void => {
        const newPositions: MembershipPositionSimpleDTO[] = newPositionIds.map((id) => {
            const position = props.availablePositions.find((position) => position.id === id);
            if (position) return position;
            else {
                console.log("ERROR: selected position that does not exist");
                setState({...state, name: "failed"});
                return {} as MembershipPositionSimpleDTO;
            }
        });
        setFormData({...formData, positions: newPositions});
    };
    //== Render =================================================================
    if (state.name === "deleted") {
        return <SuccessNotification message={t(`notifications.memberships.delete_ok`)} />;
    }
    if (state.name === "saving" || state.name === "deleting") {
        return <LoadingRow key={props.membership.id} />;
    }
    const personNameCell = (
        <TableCell>
            <Link href={(isAdmin(keycloak) ? ROUTES.person : ROUTES.profile).create(state.person.publicId)}>
                {makeFullName(state.person)}
            </Link>
        </TableCell>
    );
    const deleteActionButton = <DeleteActionButton onClick={handleDelete} />;
    if (state.name === "editing") {
        return (
            <TableRow key={props.membership.id}>
                {personNameCell}
                <TableCell>
                    <PositionsSelect
                        currentPositions={formData.positions}
                        availablePositions={props.availablePositions}
                        handlePositionsChange={handlePositionsChange}
                    />
                </TableCell>
                <TableCell className={classes.actionsColumnCell}>
                    <SaveActionButton onClick={handleSave} />
                    {deleteActionButton}
                </TableCell>
            </TableRow>
        );
    }
    // - default
    let notification: JSX.Element | null = null;
    if (state.name === "failed") {
        notification = <ErrorNotification message={t(`notifications.memberships.${state.action}_fail`)} />;
    }
    if (state.name === "saved") {
        notification = <SuccessNotification message={t(`notifications.memberships.edit_ok`)} />;
    }
    return (
        <TableRow key={props.membership.id}>
            {personNameCell}
            <TableCell>{state.positions.map((position) => position.name).join(", ")}</TableCell>
            <TableCell className={classes.actionsColumnCell}>
                <EditActionButton onClick={handleEdit} />
                <DeleteConfirmationDialog
                    {...(props.isOnlyOneWithFullPermissions
                        ? {
                              title: t("ui.memberships.editConfirmDialog.onlyWithFullPermissions.title"),
                              text: t("ui.memberships.editConfirmDialog.onlyWithFullPermissions.text"),
                              checkbox: t("ui.memberships.editConfirmDialog.onlyWithFullPermissions.checkbox"),
                          }
                        : props.membership.isOwn
                        ? {
                              title: t("ui.memberships.editConfirmDialog.own.title"),
                              text: t("ui.memberships.editConfirmDialog.own.text"),
                              checkbox: t("ui.memberships.editConfirmDialog.own.checkbox"),
                          }
                        : {
                              title: t("ui.memberships.editConfirmDialog.title"),
                              text: t("ui.memberships.editConfirmDialog.text", {
                                  fullName: makeFullName(state.person),
                              }),
                          })}
                    open={state.name === "confirmingSave"}
                    id={"delete-dialog-" + props.membership.id}
                    onDelete={handleConfirmSave}
                    onCancel={handleCancelSave}
                />
                {deleteActionButton}
                <DeleteConfirmationDialog
                    {...(props.isOnlyOneWithFullPermissions
                        ? {
                              title: t("ui.memberships.deleteConfirmDialog.onlyWithFullPermissions.title"),
                              text: t("ui.memberships.deleteConfirmDialog.onlyWithFullPermissions.text"),
                              checkbox: t("ui.memberships.deleteConfirmDialog.onlyWithFullPermissions.checkbox"),
                          }
                        : props.membership.isOwn
                        ? {
                              title: t("ui.memberships.deleteConfirmDialog.own.title"),
                              text: t("ui.memberships.deleteConfirmDialog.own.text"),
                              checkbox: t("ui.memberships.deleteConfirmDialog.own.checkbox"),
                          }
                        : {
                              title: t("ui.memberships.deleteConfirmDialog.title"),
                              text: t("ui.memberships.deleteConfirmDialog.text", {
                                  fullName: makeFullName(state.person),
                              }),
                          })}
                    open={state.name === "confirmingDelete"}
                    id={"delete-dialog-" + props.membership.id}
                    onDelete={handleConfirmDelete}
                    onCancel={handleCancelDelete}
                />
                {notification}
            </TableCell>
        </TableRow>
    );
};
const MembershipNewRow: React.FC<MembershipNewRowProps> = (props: MembershipNewRowProps) => {
    //== Init ===================================================================
    const classes = useStyles();
    const [t] = useTranslation("organizationMembers");
    const {keycloak, initialized} = useKeycloak();
    if (!isAdmin(keycloak)) return null;
    const initState = {
        positions: [] as MembershipPositionSimpleDTO[],
        email: "",
        note: "" as "" | null,
        name: "init" as "init" | "confirmingCreate" | "creating" | "failed",
    };
    const [state, setState] = React.useState(initState);
    //== Effects ================================================================
    React.useEffect(() => {
        if (state.name === "creating") {
            const membership: MembershipCreateDTO = {
                email: state.email,
                positionIds: state.positions.map((position) => position.id),
            };
            API.post<MembershipDTO>(`/memberships`, membership, createApiConfig(keycloak, initialized))
                .then((res) => {
                    props.onSave(res.data);
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [keycloak, initialized, state, setState, initState, props]);
    //== Handlers ===============================================================
    const handleCancelCreate = (): void => {
        setState({...state, name: "init"});
    };
    const handleConfirmCreate = (): void => {
        setState({...state, name: "creating"});
    };
    const handleCreate = (): void => {
        setState({...state, name: "confirmingCreate"});
    };
    //== Render =================================================================
    if (state.name === "creating") {
        return <LoadingRow />;
    }
    let notification: JSX.Element | null = null;
    if (state.name === "failed") {
        notification = <ErrorNotification message={t("notifications.memberships.create_fail")} />;
    }
    const handlePositionsChange = (newPositionIds: string[]): void => {
        console.log("newPositionIds:");
        console.log(newPositionIds);
        const newPositions: MembershipPositionSimpleDTO[] = newPositionIds.map((id) => {
            const position = props.availablePositions.find((position) => position.id === id);
            if (position) return position;
            else {
                console.log("ERROR: selected position that does not exist");
                setState({...state, name: "failed"});
                return {} as MembershipPositionSimpleDTO;
            }
        });
        console.log("newPositions:");
        console.log(newPositions);
        setState({...state, positions: newPositions});
    };
    // - default
    return (
        <TableRow key="new">
            <TableCell>
                <TextField
                    id="email"
                    label={t("membership.email")}
                    value={state.email}
                    fullWidth
                    required
                    onChange={(e): void => setState({...state, email: e.target.value})}
                />
            </TableCell>
            <TableCell>
                <PositionsSelect
                    currentPositions={state.positions}
                    availablePositions={props.availablePositions}
                    handlePositionsChange={handlePositionsChange}
                />
            </TableCell>
            <TableCell className={classes.actionsColumnCell}>
                <CreateActionButton onClick={handleCreate} />
                <CustomizableConfirmationDialog
                    title={t("ui.memberships.adminCreateConfirmDialog.title")}
                    text={t("ui.memberships.adminCreateConfirmDialog.text", {email: state.email})}
                    open={state.name === "confirmingCreate"}
                    id={"confirm-dialog-new"}
                    onAgree={handleConfirmCreate}
                    onDisagree={handleCancelCreate}
                    agree={t("common:dialogs.confirm.agree")}
                    disagree={t("common:dialogs.confirm.disagree")}
                />
                {notification}
            </TableCell>
        </TableRow>
    );
};

interface MembershipsProps {
    organization: OrganizationDTO;
    membershipToEnsureRender?: MembershipDTO | null;
}

const Memberships: React.FC<MembershipsProps> = (props: MembershipsProps) => {
    //== Init ===================================================================
    const classes = useStyles();
    const [t] = useTranslation("organizationMembers");
    const {keycloak, initialized} = useKeycloak();
    const initState = {
        page: {
            size: 20,
            totalElements: 0,
            totalPages: 0,
            number: 0,
        },
        memberships: [] as MembershipDTO[],
        positions: [] as MembershipPositionSimpleDTO[],
        lastProcessedMembershipToEnsureRender: props.membershipToEnsureRender || null,
        name: "loading" as "loading" | "loaded" | "failed",
        action: "init" as "init" | "refresh" | "new",
    };
    const [state, setState] = React.useState(initState);
    //== Effects ================================================================
    React.useEffect(() => {
        if (
            props.membershipToEnsureRender &&
            state.lastProcessedMembershipToEnsureRender?.id !== props.membershipToEnsureRender.id
        ) {
            setState({
                ...state,
                lastProcessedMembershipToEnsureRender: props.membershipToEnsureRender,
                name: "loading",
                action: "refresh",
            });
        } else if (state.name === "loading") {
            const pageParams = `&page=${state.page.number}&size=${state.page.size}`;
            API.get<Page<MembershipDTO>>(
                `/memberships?organization=${props.organization.publicId}&${pageParams}`,
                createApiConfig(keycloak, initialized)
            )
                .then((membershipsRes) => {
                    if (state.name === "loading" && isAdmin(keycloak)) {
                        API.get<MembershipPositionSimpleDTO[]>(
                            `/positions/simple?organization=${props.organization.publicId}`,
                            createApiConfig(keycloak, initialized)
                        )
                            .then((positionsRes) => {
                                setState({
                                    ...state,
                                    page: membershipsRes.data,
                                    positions: positionsRes.data === undefined ? [] : positionsRes.data,
                                    memberships: membershipsRes.data === undefined ? [] : membershipsRes.data.content,
                                    name: "loaded",
                                });
                            })
                            .catch((err: AxiosError) => {
                                setState({...state, name: "failed"});
                            });
                    } else {
                        setState({
                            ...state,
                            page: membershipsRes.data,
                            memberships: membershipsRes.data === undefined ? [] : membershipsRes.data.content,
                            name: "loaded",
                        });
                    }
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [keycloak, initialized, state, setState, props.organization, props.membershipToEnsureRender]);
    //== Handlers ===============================================================
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number): void => {
        setState({
            ...state,
            page: {...state.page, number: value - 1},
            name: "loading",
            action: "init",
        });
    };
    //== Render =================================================================
    if (state.name === "loading") {
        return <Loading />;
    }
    if (state.name === "failed") {
        return <ServerCommunicationAlert />;
    }
    // - default
    let notification: JSX.Element | null = null;
    if (state.name === "loaded" && state.action === "refresh") {
        notification = <InfoNotification message={t("notifications.memberships.refresh_ok")} />;
    }
    if (state.name === "loaded" && state.action === "new") {
        notification = <SuccessNotification message={t("notifications.memberships.create_ok")} />;
    }
    const membershipsWithFullPermissions = state.memberships.filter((membership) =>
        membership.positions.some((position) => position.profilePermissionLevel === "FULL")
    );
    const rows = state.memberships.map((membership: MembershipDTO) => (
        <MembershipRow
            key={membership.id}
            membership={membership}
            availablePositions={state.positions}
            isOnlyOneWithFullPermissions={
                membershipsWithFullPermissions.length === 1 && membershipsWithFullPermissions[0].id === membership.id
            }
        />
    ));
    return (
        <Box>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            {t("membership.fullname") +
                                (isAdmin(keycloak) ? " (" + t("ui.orEmailInCaseOfCreating") + ")" : "")}
                        </TableCell>
                        <TableCell>{t("membership.positions")}</TableCell>
                        <TableCell className={classes.actionsColumnCell}>
                            <RefreshActionButton
                                onClick={(): void =>
                                    setState({
                                        ...initState,
                                        action: "refresh",
                                    })
                                }
                            />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{rows}</TableBody>
                {isAdmin(keycloak) ? (
                    <TableFooter>
                        <MembershipNewRow
                            availablePositions={state.positions}
                            onSave={(): void => setState({...initState, action: "new"})}
                            organizationId={props.organization.publicId}
                        />
                    </TableFooter>
                ) : null}
            </Table>
            {state.page.totalPages > 1 && (
                <Pagination
                    style={{marginTop: "1em"}}
                    count={state.page.totalPages}
                    page={state.page.number + 1}
                    onChange={handlePageChange}
                />
            )}
            {notification}
        </Box>
    );
};

export default Memberships;
