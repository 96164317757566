import React from "react";
import {TagCategoryDTO} from "../../models/admin";
import {GenericEntityDeleteContentProps, GenericEntityForceDelete} from "../common/delete";
import {compareByAttr} from "../../utils/compare";

const TagCategoryRenderDelete: React.FC<GenericEntityDeleteContentProps> = (props: GenericEntityDeleteContentProps) => {
    return (
        <ul>
            {props.error.relatedEntities
                .filter((item) => item.type === "Tag")
                .sort(compareByAttr("displayName", "ascending"))
                .map((item) => (
                    <li key={item.id}>{item.displayName}</li>
                ))}
        </ul>
    );
};

interface TagCategoryDeleteProps {
    entity: TagCategoryDTO | null;
    onDeleted: () => void;
    onCancel: () => void;
    onFailed: () => void;
}

export const TagCategoryDelete: React.FC<TagCategoryDeleteProps> = (props: TagCategoryDeleteProps) => {
    return (
        <GenericEntityForceDelete
            tNs="common"
            tPrefix="tags.categories"
            urlSlug="tag-categories"
            RenderContent={TagCategoryRenderDelete}
            {...props}
        />
    );
};
