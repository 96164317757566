import React from "react";
import {
    emptyGoalCreateDTO,
    GoalCreateDTO,
    LibraryDomainUseDTO,
    LibraryDTO,
    nextOrderNumber,
} from "../../../../models/library";
import {useKeycloak} from "@react-keycloak/web";
import {API, createApiConfig} from "../../../../utils/API";
import {AxiosError} from "axios";
import {Box, Button} from "@material-ui/core";
import {FormDialog} from "../forms/FormDialog";
import AddIcon from "@material-ui/icons/Add";
import {useTranslation} from "react-i18next";
import {GoalForm} from "../forms/GoalForm";

interface LibraryGoalCreateRowProps {
    library: LibraryDTO;
    libraryDomainUse: LibraryDomainUseDTO;
    onReload: (cause: string) => void;
    onUpdate: (library: LibraryDTO) => void;
}

interface LibraryGoalCreateRowState {
    name: "loaded" | "saving" | "failed";
    opened: boolean;
    newGoal: GoalCreateDTO;
}

export const LibraryGoalCreateRow: React.FC<LibraryGoalCreateRowProps> = (props: LibraryGoalCreateRowProps) => {
    // TODO: improve style when nested library
    //== Init =================================================================
    const [t] = useTranslation("libraries");
    const {keycloak, initialized} = useKeycloak();
    const nextOrder = nextOrderNumber(props.libraryDomainUse.libraryGoalUses);
    const emptyGoal: GoalCreateDTO = {
        ...emptyGoalCreateDTO,
        libraryUUID: props.library.id,
        libraryDomainUseUUID: props.libraryDomainUse.id,
        orderNumber: nextOrder,
    };
    const initState: LibraryGoalCreateRowState = {
        name: "loaded",
        opened: false,
        newGoal: emptyGoal,
    };
    const [state, setState] = React.useState(initState);
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "saving") {
            API.post<LibraryDTO>(`/goals/in-library`, state.newGoal, createApiConfig(keycloak, initialized))
                .then((res) => {
                    setState({...state, name: "loaded", newGoal: emptyGoal, opened: false});
                    props.onUpdate(res.data);
                })
                .catch((err: AxiosError) => {
                    console.log(err);
                    setState({...state, name: "failed"});
                });
        }
    }, [state, setState]);
    //== Handlers =============================================================
    const handleNewChange = (goal: GoalCreateDTO): void => {
        setState({...state, newGoal: goal});
    };
    const handleNewClose = (): void => {
        setState({...state, opened: false});
    };
    const handleNewClick = (): void => {
        setState({...state, opened: true});
    };
    const handleNewSubmit = (): void => {
        setState({...state, name: "saving", opened: true});
    };
    //== Render ===============================================================
    return (
        <Box display="flex" justifyContent="flex-end" style={{padding: "0.4em"}}>
            <Button onClick={handleNewClick} startIcon={<AddIcon />} variant="outlined" color="primary">
                {t("goal.new.name")}
            </Button>
            <FormDialog
                id={`new-domain-${props.libraryDomainUse.id}`}
                title={t("goal.new.name")}
                open={state.opened}
                onClose={handleNewClose}
                actions={[
                    <Button key="submit" onClick={handleNewSubmit}>
                        {t("goal.new.button")}
                    </Button>,
                ]}
            >
                <GoalForm goal={state.newGoal} published={false} onChange={handleNewChange} />
            </FormDialog>
        </Box>
    );
};
