import React from "react";
import {Box} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {DashboardIndicatorsAddingDomain, DashboardIndicatorsAddingGroup} from "./DashboardIndicatorsAddingInterfaces";
import DashboardIndicatorsDomainAdd from "./DashboardIndicatorsDomainAdd";
import {useTranslation} from "react-i18next";
import {TrackedIndicatorInGroupDTO} from "../../../../../models/trackedIndicators";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        row: {
            borderWidth: 0,
            borderStyle: "solid",
            borderBottomWidth: 3,
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(0.5),
            marginLeft: theme.spacing(0),
            paddingLeft: theme.spacing(1),
            display: "flex",
        },
        selectionCheckbox: {},
        itemName: {
            fontSize: "150%",
            fontWeight: "bold",
            marginTop: theme.spacing(1.75),
        },
        infoButton: {
            flexGrow: 1,
        },
    })
);

interface DashboardIndicatorsGroupAddProps {
    index: number; //TODO temporary solution until libraryXXXUse are not added to LibraryDTO,
    group: DashboardIndicatorsAddingGroup;

    handleChecked(trackedIndicator: TrackedIndicatorInGroupDTO): void;

    handleUnchecked(trackedIndicator: TrackedIndicatorInGroupDTO): void;
}

const DashboardIndicatorsGroupAdd: React.FC<DashboardIndicatorsGroupAddProps> = (
    props: DashboardIndicatorsGroupAddProps
) => {
    const classes = useStyles();
    const [t] = useTranslation("trackedIndicators");

    return (
        <Box>
            <Box className={classes.row}>
                <Box className={classes.itemName}>{t("trackedIndicatorGroup.group") + ": " + props.group.name}</Box>
            </Box>
            <Box>
                {props.group.trackedDomains.map((domain: DashboardIndicatorsAddingDomain, index) => (
                    <DashboardIndicatorsDomainAdd
                        key={domain.id}
                        index={index}
                        trackedDomain={domain}
                        handleChecked={props.handleChecked}
                        handleUnchecked={props.handleUnchecked}
                    />
                ))}
            </Box>
        </Box>
    );
};

export default DashboardIndicatorsGroupAdd;
