import {WebpageDTO} from "../../models/admin";
import React from "react";
import {useStyles} from "../../styles";
import {useTranslation} from "react-i18next";
import useMetaTags from "react-metatags-hook";
import {Box, Container, Divider} from "@material-ui/core";
import {API, createApiConfig, DateUtils} from "../../utils";
import {Tags} from "../common/tags";
import {useKeycloak} from "@react-keycloak/web";
import {LoadingBackdrop} from "../common/Loading";
import {CommunicationError, NotFound} from "../common/errors";
import {isAdmin} from "../../utils/auth";
import {EditLinkActionButton} from "../common/buttons";
import ROUTES from "../../routes/routes";
import {makeFullName} from "../../models/user";
import {CMSPageRenderer} from "../common/forms";

interface WebpageContentProps {
    webpage: WebpageDTO;
    footer: boolean;
}

const WebpageFooter: React.FC<WebpageContentProps> = (props: WebpageContentProps) => {
    //== Init =================================================================
    const [t] = useTranslation("webpages");
    const classes = useStyles();
    //== Render ===============================================================
    if (!props.footer) {
        return null;
    }
    return (
        <Box style={{clear: "both"}}>
            <Divider className={classes.spaceAfter} />
            <Box style={{float: "left"}}>
                {props.webpage.author ? (
                    <Box>
                        <span>{t("webpage.author")}</span>:&nbsp;
                        <span>{makeFullName(props.webpage.author)}</span>
                    </Box>
                ) : null}
                {props.webpage.updatedAt ? (
                    <Box>
                        <span>{t("webpage.updatedAt")}</span>:&nbsp;
                        <span>{DateUtils.datetimeString(props.webpage.author?.updatedAt)}</span>
                    </Box>
                ) : null}
            </Box>
            <Box style={{float: "right"}}>
                <Tags tags={props.webpage.tags} />
            </Box>
        </Box>
    );
};

const WebpageHeader: React.FC<WebpageContentProps> = (props: WebpageContentProps) => {
    //== Init =================================================================
    const classes = useStyles();
    const {keycloak, initialized} = useKeycloak();
    //== Render ===============================================================
    const actions: JSX.Element[] = [];
    if (isAdmin(keycloak) && initialized) {
        actions.push(<EditLinkActionButton to={ROUTES.cmsPageEdit.create(props.webpage.publicId)} key="edit" />);
    }
    return (
        <Box style={{clear: "both"}}>
            <Box style={{float: "left"}}>
                <h1 className={classes.spaceAfter}>{props.webpage.title}</h1>
            </Box>
            <Box style={{float: "right"}}>{actions}</Box>
        </Box>
    );
};

const WebpageContent: React.FC<WebpageContentProps> = (props: WebpageContentProps) => {
    //== Init =================================================================
    useMetaTags({
        title: props.webpage.title,
        description: props.webpage.description ?? "",
        metas: [
            {
                name: "keywords",
                content: props.webpage.keywords ?? "",
            },
        ],
    });
    //== Render ===============================================================
    return (
        <Container maxWidth="lg">
            <WebpageHeader {...props} />
            <Box style={{clear: "both"}}>
                <CMSPageRenderer value={props.webpage.content} />
            </Box>
            <WebpageFooter {...props} />
        </Container>
    );
};

interface WebpageProps {
    webpageId: string;
    footer?: boolean;
}

interface WebpageState {
    webpage: WebpageDTO | null;
    name: string;
}

export const Webpage: React.FC<WebpageProps> = (props: WebpageProps) => {
    //== Init =================================================================
    const webpageId = props.webpageId;
    const {keycloak, initialized} = useKeycloak();
    const initState: WebpageState = {
        webpage: null,
        name: "loading",
    };
    const [state, setState] = React.useState(initState);
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<WebpageDTO>(`/cms/webpages/${webpageId}`, createApiConfig(keycloak, initialized))
                .then((res) => {
                    setState({...state, webpage: res.data, name: "loaded"});
                })
                .catch((err) => {
                    if (err?.response?.status === 404) {
                        setState({...state, name: "not_found"});
                    } else {
                        setState({...state, name: "failed"});
                    }
                });
        }
    }, [state, setState, webpageId, keycloak, initialized]);
    //== Render ===============================================================
    if (state.name === "loading") {
        return <LoadingBackdrop />;
    }
    if (state.name === "not_found") {
        return <NotFound />;
    }
    if (state.webpage != null) {
        return <WebpageContent webpage={state.webpage} footer={props.footer ?? true} />;
    }
    return <CommunicationError />;
};
