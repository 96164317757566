import {Box, Button, Container, List, ListItem, makeStyles, Typography} from "@material-ui/core";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {isAdmin} from "../../utils/auth";
import csManual from "../content/cs/CsManual";
import {createStyles, Theme} from "@material-ui/core/styles";
import {PrintActionButton} from "../../components/common/buttons";
import ROUTES from "../../routes/routes";
import {Link as ReactLink} from "react-router-dom";
import {ManualChapter} from "../model/ManualChapter";
import {ManualSection} from "../model/ManualSection";
import {ManualSubsection} from "../model/ManualSubsection";
import {ManualSubSubsection} from "../model/ManualSubSubsection";
import {LoadingBackdrop} from "../../components/common/Loading";
import {manualLinkStyles} from "./ManualQueryLink";

export const manuals = new Map([csManual].map((manual) => [manual.language, manual]));

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        contentWithTable: {},
        sideBar: {
            float: "right",
            marginLeft: "2em",
            marginBottom: "2em",
            maxHeight: "100%",
            "@media(max-width: 720px)": {
                display: "none",
            },
        },
        actions: {
            float: "right",
        },
        tableOfContent: {
            border: "1px solid lightGray",
            borderRadius: "0.5em",
            paddingRight: "1em",
            paddingBottom: "1em",
            paddingLeft: "0.1em",
            paddingTop: "0.1em",
        },
        tableOfContentList: {
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: 0,
            width: "100%",
        },
        tableOfContentItem: {
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            maxWidth: "22em",
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: 0,
        },
        tableOfContentLink: {
            width: "100%",
            justifyContent: "flex-start",
            textAlign: "left",
        },
        tableOfContentManualLink: {
            color: theme.palette.text.primary,
            fontWeight: "bold",
            textTransform: "none",
            fontSize: "1rem",
        },
        tableOfContentChapterLink: {
            color: theme.palette.text.secondary,
            fontWeight: "bold",
            textTransform: "uppercase",
        },
        tableOfContentSectionLink: {
            color: theme.palette.text.primary,
            fontWeight: "bold",
            textTransform: "none",
        },
        tableOfContentSubsectionLink: {
            color: theme.palette.text.primary,
            textTransform: "none",
        },
        manualContent: {
            minWidth: "560px",
            "@media(max-width: 750px)": {
                minWidth: "auto",
            },
        },
        embeddedManualContent: {},
        manualHeaderNumber: {
            display: "none",
            marginRight: "1em",
        },
        manualHeaderPrintedNumber: {
            display: "inline",
            marginRight: "1em",
        },
        contentH1: {
            fontSize: "1.8rem",
            lineHeight: 1.8,
        },
        contentH2: {
            fontSize: "1.75rem",
            lineHeight: 1.75,
        },
        contentH3: {
            fontSize: "1.5rem",
            lineHeight: 1.5,
        },
        contentH4: {
            fontSize: "1.3rem",
            lineHeight: 1.3,
        },
        contentH5: {
            fontSize: "1.1rem",
            lineHeight: 1.1,
        },
        contentSegment: {
            marginTop: "1em",
        },
        contentBody: {
            textAlign: "justify",
        },
    })
);

interface ManualPageSingleOpenChapterState {
    chapter: ManualChapter;
    section?: null;
    subsection?: null;
    subSubsection?: null;
}

interface ManualPageSingleOpenSectionState {
    chapter?: null;
    section: ManualSection;
    subsection?: null;
    subSubsection?: null;
}

interface ManualPageSingleOpenSubsectionState {
    chapter?: null;
    section?: null;
    subsection: ManualSubsection;
    subSubsection?: null;
}

interface ManualPageSingleOpenSubSubsectionState {
    chapter?: null;
    section?: null;
    subsection?: null;
    subSubsection: ManualSubSubsection;
}

interface ManualPageAllItemsOpenState {
    chapter?: null;
    section?: null;
    subsection?: null;
    subSubsection?: null;
}

type ManualPageSingleOpenItemState =
    | ManualPageSingleOpenChapterState
    | ManualPageSingleOpenSectionState
    | ManualPageSingleOpenSubsectionState
    | ManualPageSingleOpenSubSubsectionState
    | ManualPageAllItemsOpenState;

interface ManualDisplayChapterProps {
    onlyChapter: ManualChapter;
    onlySection?: null;
    onlySubsection?: null;
    onlySubSubsection?: null;
    embeddedView?: boolean;
}

interface ManualDisplaySectionProps {
    onlyChapter?: null;
    onlySection: ManualSection;
    onlySubsection?: null;
    onlySubSubsection?: null;
    embeddedView?: boolean;
}

interface ManualDisplaySubsectionProps {
    onlyChapter?: null;
    onlySection?: null;
    onlySubsection: ManualSubsection;
    onlySubSubsection?: null;
    embeddedView?: boolean;
}

interface ManualDisplaySubSubsectionProps {
    onlyChapter?: null;
    onlySection?: null;
    onlySubsection?: null;
    onlySubSubsection: ManualSubSubsection;
    embeddedView?: boolean;
}

interface ManualDisplayAllProps {
    onlyChapter?: null;
    onlySection?: null;
    onlySubsection?: null;
    onlySubSubsection?: null;
    embeddedView?: boolean;
}

type ManualDisplayProps =
    | ManualDisplayChapterProps
    | ManualDisplaySectionProps
    | ManualDisplaySubsectionProps
    | ManualDisplaySubSubsectionProps
    | ManualDisplayAllProps;

export const ManualDisplay: React.FC<ManualDisplayProps> = (props: ManualDisplayProps) => {
    const classes = useStyles();
    const manualLinkClasses = manualLinkStyles();
    const [t] = useTranslation("common");
    const {keycloak} = useKeycloak();

    const [singleOpenItem, setSingleOpenItem] = useState<ManualPageSingleOpenItemState>(
        props.onlySubSubsection
            ? {subSubsection: props.onlySubSubsection}
            : props.onlySubsection
            ? {subsection: props.onlySubsection}
            : props.onlySection
            ? {section: props.onlySection}
            : props.onlyChapter
            ? {chapter: props.onlyChapter}
            : {}
    );

    const manual =
        singleOpenItem.chapter?.manual || props.onlyChapter?.manual || manuals.get(t("language.code")) || csManual;

    if (!manual) return <LoadingBackdrop />;

    const scrollToTop = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    };
    const isAllowedChapter = (chapter: ManualChapter) => !chapter.requiresAdmin || isAdmin(keycloak);
    const isAllowedSection = (section: ManualSection) => section.chapter && isAllowedChapter(section.chapter);
    const isAllowedSubsection = (subsection: ManualSubsection) =>
        subsection.section && isAllowedSection(subsection.section);
    const setAllOpen = () => {
        setSingleOpenItem({});
        scrollToTop();
    };
    const setOnlyChapter = (chapter: ManualChapter) => {
        if (!isAllowedChapter(chapter)) return setAllOpen();
        setSingleOpenItem({chapter: chapter});
        scrollToTop();
    };
    const setOnlySection = (section: ManualSection) => {
        if (!isAllowedSection(section)) return setAllOpen();
        setSingleOpenItem({section: section});
        scrollToTop();
    };
    const setOnlySubsection = (subsection: ManualSubsection) => {
        if (!isAllowedSubsection(subsection)) return setAllOpen();
        setSingleOpenItem({subsection: subsection});
        scrollToTop();
    };

    const print = () => {
        const manualContent = document.getElementById("manual-content");
        if (!manualContent) return;
        const linkNumbers = Array.prototype.slice.call(
            manualContent.getElementsByClassName(manualLinkClasses.manualLinkNumber)
        );
        for (const linkNumber of linkNumbers) {
            linkNumber.className = manualLinkClasses.manualLinkPrintedNumber;
        }
        const headerNumbers = Array.prototype.slice.call(
            manualContent.getElementsByClassName(classes.manualHeaderNumber)
        );
        for (const headerNumber of headerNumbers) {
            headerNumber.className = classes.manualHeaderPrintedNumber;
        }
        const root = document.getElementById("root");
        if (!root) return;
        root.style.display = "none";
        const tooltip = document.getElementsByClassName("MuiTooltip-popper").item(0);
        tooltip && tooltip.setAttribute("style", "display: none");
        const contentToPrint = document.createElement("div");
        contentToPrint.style.padding = "10%";
        contentToPrint.appendChild(manualContent.cloneNode(true));
        document.body.appendChild(contentToPrint);
        window.print();
        const lastItem = document.body.children.item(document.body.children.length - 1);
        lastItem === null
            ? console.log("cannot remove just added element from document body - it is not there anymore")
            : lastItem.remove();
        root.style.display = "block";
        for (const linkNumber of linkNumbers) {
            linkNumber.className = manualLinkClasses.manualLinkNumber;
        }
        for (const headerNumber of headerNumbers) {
            headerNumber.className = classes.manualHeaderNumber;
        }
    };

    const singleOpenSubSubsection = singleOpenItem.subSubsection;
    const singleOpenSubsection = singleOpenItem.subsection || singleOpenSubSubsection?.subsection;
    const singleOpenSection = singleOpenItem.section || singleOpenSubsection?.section;
    const singleOpenChapter = singleOpenItem.chapter || singleOpenSection?.chapter;

    console.log("[ManualDisplay] props:");
    console.log(props);
    console.log("[ManualDisplay] singleOpenItem:");
    console.log(singleOpenItem);

    //== Render ===============================================================
    const openChapters = (
        singleOpenChapter && manual.chapters.has(singleOpenChapter.key)
            ? [singleOpenChapter]
            : Array.from(manual.chapters.values())
    ).filter((chapter) => isAllowedChapter(chapter));
    const content = (
        <Box id="manual-content" className={props.embeddedView ? classes.embeddedManualContent : classes.manualContent}>
            <Typography variant="h1" className={classes.contentH1}>
                {manual.title}
            </Typography>
            {openChapters.map((chapter) => {
                const openSections =
                    singleOpenSection && chapter.sections.has(singleOpenSection.key)
                        ? [singleOpenSection]
                        : Array.from(chapter.sections.values());
                return (
                    <Box key={chapter.key} className={classes.contentSegment}>
                        <Typography variant="h2" className={classes.contentH2}>
                            <span className={classes.manualHeaderNumber}>{chapter.index}</span>
                            {chapter.title}
                        </Typography>
                        <Box className={classes.contentBody}>{chapter.intro}</Box>
                        {openSections.map((section) => {
                            const openSubsections =
                                singleOpenSubsection && section.subsections.has(singleOpenSubsection.key)
                                    ? [singleOpenSubsection]
                                    : Array.from(section.subsections.values());
                            return (
                                <Box key={section.key} className={classes.contentSegment}>
                                    <Typography variant="h3" className={classes.contentH3}>
                                        <span className={classes.manualHeaderNumber}>
                                            {chapter.index + "." + section.index}
                                        </span>
                                        {section.title}
                                    </Typography>
                                    <Box className={classes.contentBody}>{section.intro}</Box>
                                    {openSubsections.map((subsection) => {
                                        const openSubSubsections =
                                            singleOpenSubSubsection &&
                                            subsection.subSubsections.has(singleOpenSubSubsection.key)
                                                ? [singleOpenSubSubsection]
                                                : Array.from(subsection.subSubsections.values());
                                        return (
                                            <Box key={subsection.key} className={classes.contentSegment}>
                                                <Typography variant="h4" className={classes.contentH4}>
                                                    <span className={classes.manualHeaderNumber}>
                                                        {chapter.index + "." + section.index + "." + subsection.index}
                                                    </span>
                                                    {subsection.title}
                                                </Typography>
                                                <Box className={classes.contentBody}>{subsection.intro}</Box>
                                                {openSubSubsections.map((subSubsection) => (
                                                    <Box key={subSubsection.key} className={classes.contentSegment}>
                                                        <Typography variant="h5" className={classes.contentH5}>
                                                            <span className={classes.manualHeaderNumber}>
                                                                {chapter.index +
                                                                    "." +
                                                                    section.index +
                                                                    "." +
                                                                    subsection.index +
                                                                    "." +
                                                                    subSubsection.index}
                                                            </span>
                                                            {subSubsection.title}
                                                        </Typography>
                                                        <Box className={classes.contentBody}>
                                                            {subSubsection.content}
                                                        </Box>
                                                    </Box>
                                                ))}
                                            </Box>
                                        );
                                    })}
                                </Box>
                            );
                        })}
                    </Box>
                );
            })}
        </Box>
    );

    if (props.embeddedView)
        return (
            <>
                {content}
                {singleOpenItem.chapter ? (
                    <Button
                        to={ROUTES.manual.path}
                        target="_blank"
                        component={ReactLink}
                        variant="outlined"
                        color="primary"
                        style={{float: "right", marginTop: "1em", marginBottom: "3em"}}
                    >
                        {t("manual.showEverything")}
                    </Button>
                ) : null}
            </>
        );
    const sidebar = (
        <Box className={classes.sideBar}>
            <Box className={classes.tableOfContent}>
                <Box className={classes.tableOfContentItem}>
                    <Button
                        onClick={setAllOpen}
                        className={`${classes.tableOfContentLink} ${classes.tableOfContentManualLink}`}
                    >
                        {manual.title}
                    </Button>
                    <List className={classes.tableOfContentList}>
                        {Array.from(manual.chapters.values())
                            //note that admin requirement just hides the chapter, but is still accessible in code
                            .filter((chapter) => !chapter.requiresAdmin || isAdmin(keycloak))
                            .map((chapter) => (
                                <ListItem key={chapter.key} className={classes.tableOfContentItem}>
                                    <Button
                                        onClick={() => setOnlyChapter(chapter)}
                                        className={`${classes.tableOfContentLink} ${classes.tableOfContentChapterLink}`}
                                    >
                                        {chapter.title}
                                    </Button>

                                    <List className={classes.tableOfContentList}>
                                        {Array.from(chapter.sections.values()).map((section) => (
                                            <ListItem key={section.key} className={classes.tableOfContentItem}>
                                                <Button
                                                    onClick={() => setOnlySection(section)}
                                                    className={
                                                        classes.tableOfContentLink +
                                                        " " +
                                                        classes.tableOfContentSectionLink
                                                    }
                                                >
                                                    {section.title}
                                                </Button>

                                                <List className={classes.tableOfContentList}>
                                                    {Array.from(section.subsections.values()).map((subsection) => (
                                                        <ListItem
                                                            key={subsection.key}
                                                            className={classes.tableOfContentItem}
                                                        >
                                                            <Button
                                                                onClick={() => setOnlySubsection(subsection)}
                                                                className={
                                                                    classes.tableOfContentLink +
                                                                    " " +
                                                                    classes.tableOfContentSubsectionLink
                                                                }
                                                            >
                                                                {subsection.title}
                                                            </Button>
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </ListItem>
                                        ))}
                                    </List>
                                </ListItem>
                            ))}
                    </List>
                </Box>
            </Box>
        </Box>
    );
    return (
        <Box className={classes.contentWithTable}>
            {sidebar}
            <Container maxWidth="md">
                <Box className={classes.actions}>
                    <PrintActionButton onClick={print} />
                </Box>
                {content}
                {singleOpenChapter ? (
                    <Button
                        onClick={setAllOpen}
                        color="primary"
                        variant="contained"
                        style={{float: "right", marginTop: "1em", marginBottom: "3em"}}
                    >
                        {t("manual.showEverything")}
                    </Button>
                ) : null}
            </Container>
        </Box>
    );
};

export default ManualDisplay;
