import React from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from "@material-ui/core";
import {BUILD_INFO} from "../../../utils/buildInfo";
import {API, createApiConfig, DateUtils} from "../../../utils";
import {useKeycloak} from "@react-keycloak/web";
import {AxiosError, AxiosResponse} from "axios";
import Loading from "../../common/Loading";
import {useTranslation} from "react-i18next";

interface RemoteBuildInfo {
    version: string;
    builtAt: string;
}

interface VersionInfoTableProps {
    version: string;
    builtAt: string;
}

const VersionInfoTable: React.FC<VersionInfoTableProps> = (props: VersionInfoTableProps) => {
    const [t] = useTranslation("common");
    //== Render ===============================================================
    return (
        <Table>
            <TableBody>
                <TableRow>
                    <TableCell>{t("dev.versionInfo.version")}</TableCell>
                    <TableCell align="right">
                        <code>{props.version}</code>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>{t("dev.versionInfo.builtAt")}</TableCell>
                    <TableCell align="right">{props.builtAt}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};

const ClientAppVersionInfo: React.FC = () => {
    return <VersionInfoTable version={BUILD_INFO.version} builtAt={DateUtils.toDateTimeString(BUILD_INFO.builtAt)} />;
};

interface ServerAppVersionInfoState {
    name: "loading" | "loaded" | "failed";
    info: RemoteBuildInfo;
}

const ServerAppVersionInfo: React.FC = () => {
    //== Init =================================================================
    const initState: ServerAppVersionInfoState = {
        name: "loading",
        info: {
            version: "",
            builtAt: "",
        },
    };
    const [state, setState] = React.useState(initState);
    const {keycloak, initialized} = useKeycloak();
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get("/info/build", createApiConfig(keycloak, initialized))
                .then((r: AxiosResponse<RemoteBuildInfo>) => {
                    setState({...state, name: "loaded", info: r.data});
                })
                .catch((e: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [state.name, setState]);
    //== Render ===============================================================
    if (state.name === "loading") {
        return (
            <Box display="flex" justifyContent="center" style={{padding: "2em"}}>
                <Loading />
            </Box>
        );
    }
    return <VersionInfoTable version={state.info.version} builtAt={DateUtils.datetimeString(state.info.builtAt)} />;
};

interface VersionInfoDialogProps {
    open: boolean;
    onClose: () => void;
}

export const VersionInfoDialog: React.FC<VersionInfoDialogProps> = (props: VersionInfoDialogProps) => {
    const [t] = useTranslation("common");
    //== Render ===============================================================
    return (
        <Dialog open={props.open} onClose={props.onClose} scroll="paper" maxWidth="sm" fullWidth>
            <DialogTitle>{t("dev.versionInfo.versionInfo")}</DialogTitle>
            <DialogContent>
                <h3>{t("dev.versionInfo.client")}</h3>
                <ClientAppVersionInfo />
                <Divider />
                <h3>{t("dev.versionInfo.server")}</h3>
                <ServerAppVersionInfo />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>{t("dev.versionInfo.close")}</Button>
            </DialogActions>
        </Dialog>
    );
};
