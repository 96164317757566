import React from "react";
// Project imports
import {WEBPAGES} from "../../routes/webpages";
import {Webpage} from "../webpage/Webpage";

export const Contacts: React.FC = () => {
    return <Webpage webpageId={WEBPAGES.names.contacts} footer={false} />;
};

export default Contacts;
