import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {GoalDTO} from "../../../models/library";
import {Tags} from "../../common/tags";
import {Alert} from "@material-ui/lab";
import {LibrariesWithElement} from "./LibrariesWithElement";
import {MarkdownRender} from "../../common/markdown";

interface GoalDialogProps {
    goal: GoalDTO;

    open: boolean;

    handleClose(): void;
}

export const GoalDialog: React.FC<GoalDialogProps> = (props: GoalDialogProps) => {
    const [t] = useTranslation(["values"]);
    //== Render ===============================================================
    return (
        <Dialog open={props.open} onClose={props.handleClose} scroll="paper" maxWidth="md" fullWidth>
            <DialogTitle>{t("goal.dialog.title", {name: props.goal.name})}</DialogTitle>
            <DialogContent>
                <h3>{t("goal.description")}</h3>
                {props.goal.description ? (
                    <MarkdownRender value={props.goal.description} />
                ) : (
                    <Alert severity="info">{t("goal.dialog.missing.description")}</Alert>
                )}
                <h3>{t("goal.methodicalInstruction")}</h3>
                {props.goal.methodicalInstruction ? (
                    <MarkdownRender value={props.goal.methodicalInstruction} />
                ) : (
                    <Alert severity="info">{t("goal.dialog.missing.methodicalInstruction")}</Alert>
                )}
                <LibrariesWithElement elementType="goal" id={props.goal.id} />
                <h3>{t("goal.tags")}</h3>
                {props.goal.tags.length > 0 ? (
                    <Tags tags={props.goal.tags} />
                ) : (
                    <Alert severity="info">{t("goal.dialog.missing.tags")}</Alert>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>{t("goal.dialog.close")}</Button>
            </DialogActions>
        </Dialog>
    );
};
