import {OrganizationDTO} from "./organization";
import {MembershipDTO} from "./members";
import {ImageDTO} from "./images";

export interface ProfileDTO {
    user?: UserDTO;
    person: PersonDTO;
    emails: EmailDTO[];
    newUser: boolean;
}

export interface PublicProfileDTO {
    person: PersonDTO;
    emails: EmailDTO[];
}

export interface DashboardDTO {
    profile: ProfileDTO;
    memberships: DashboardMembershipDTO[];
}

export interface DashboardMembershipDTO {
    organization: OrganizationDTO;
    memberships: MembershipDTO[];
}

export interface UserDTO {
    id: string;
    username: string;
    personUuid: string | null;
}

export interface PersonDTO {
    id: string | null;
    lastname: string;
    firstname: string;
    titlesBefore: string;
    titlesAfter: string;
    publicId: string;
    profileImage: ImageDTO | null;
    bio?: string;
    userUuid?: string;
    createdAt?: string;
    updatedAt?: string;
}

export interface ProfileUpdateDTO {
    lastname: string;
    firstname: string;
    titlesBefore: string;
    titlesAfter: string;
    bio: string;
}

export function toPersonUpdateDTO(person: PersonDTO): ProfileUpdateDTO {
    return {
        lastname: person.lastname,
        firstname: person.firstname,
        titlesAfter: person.titlesAfter,
        titlesBefore: person.titlesBefore,
        bio: person.bio ?? "",
    };
}

export function makeFullName(person: PersonDTO | ProfileUpdateDTO): string {
    const fullname = [person.titlesBefore, person.firstname, person.lastname].join(" ");
    if (person.titlesAfter.length > 0) {
        return `${fullname}, ${person.titlesAfter}`;
    }
    return fullname;
}

export interface EmailDTO {
    id: string | null;
    email: string;
    verified: boolean;
    personUuid: string | null;
}

export interface EmailVerificationDTO {
    code: string;
}
