import {UnitDTO, UnitSimpleDTO} from "../models/library";
import {ValueDTO, ValueSimpleDTO} from "../models/values";
import {Page} from "../models/pagination";

interface ObjectWithId {
    id: string;
}

export const Utils = {
    nullToEmpty: (str?: string | null): string => {
        return str === undefined || str === null ? "" : str;
    },
    emptyToNull: (str: string): string | null => {
        return str === "" ? null : str;
    },
    notEmpty: (str: string | null | undefined): boolean => {
        return str !== "" && str !== null && str !== undefined;
    },
    ifUndefined: <X>(def: X, x: X | undefined): X => {
        return x === undefined ? def : x;
    },
    objEq: <X>(a: X, b: X, props: (keyof X)[]): boolean => {
        return props.every((prop) => a[prop] === b[prop]);
    },
    entityArraysEq: <X extends ObjectWithId>(a: X[], b: X[]): boolean => {
        const bIds = b.map((element) => element.id);
        return a.length == b.length && a.every((element) => bIds.includes(element.id));
    },
    download: (filename: string, contentType: string, parts: BlobPart[]): HTMLElement => {
        const element = document.createElement("a");
        const file = new Blob(parts, {type: contentType});
        element.href = URL.createObjectURL(file);
        element.download = filename;
        document.body.appendChild(element);
        return element;
    },
    hashCode: (str: string): number => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            const char = str.charCodeAt(i);
            hash = (hash << 5) - hash + char;
            hash = hash & hash;
        }
        return hash;
    },
    uniq: <T>(lst: T[]): T[] => {
        const seen = new Set<T>();
        return lst.filter((item) => {
            if (seen.has(item)) {
                return false;
            }
            seen.add(item);
            return true;
        });
    },
    createPage: <T>(lst: T[], pageNumber: number, pageSize: number): Page<T> => {
        // First page has pageNumber = 1
        const sublist = lst.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize);
        return {
            content: sublist,
            empty: sublist.length == 0,
            first: pageNumber == 1,
            last: sublist.length < pageSize,
            number: pageNumber,
            numberOfElements: sublist.length,
            size: pageSize,
            totalElements: lst.length,
            totalPages: Math.ceil(lst.length / pageSize),
        };
    },
};

export const DateUtils = {
    dateFormString: (str: string | undefined): string => {
        if (str === undefined) return "";
        const date = new Date(str);
        return date.toISOString().slice(0, 10);
    },
    dateString: (str: string | undefined): string => {
        if (str === undefined) return "";
        const date = new Date(str);
        return date.toLocaleDateString();
    },
    timeString: (str: string | undefined): string => {
        if (str === undefined) return "";
        const date = new Date(str);
        return date.toLocaleTimeString();
    },
    datetimeString: (str: string | undefined): string => {
        if (str === undefined) return "";
        const date = new Date(str);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    },
    toDateTimeString: (date: Date): string => {
        if (date === undefined) return "";
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    },
    toDateISOString: (date: Date): string => {
        if (date === undefined) return "";
        return date.toISOString().slice(0, 10);
    },
    firstOfYearDateString: (): string => {
        return new Date(new Date().getFullYear(), 0, 1).toJSON().slice(0, 10);
    },
    todayDateString: (): string => {
        return new Date().toJSON().slice(0, 10);
    },
    stringToDate(str: string): Date {
        return new Date(str);
    },
};

const roundToSignificantTwo = (value: number): number =>
    parseFloat(value.toExponential(Math.max(1, 2 + Math.log10(Math.abs(value)))));

export const ValueUtils = {
    formatNumber: (value: number): string => {
        const roundedToSignificantTwo =
            value > 99.99 && value < 100.0 ? 100 - roundToSignificantTwo(100 - value) : roundToSignificantTwo(value);
        return roundedToSignificantTwo.toLocaleString(undefined, {
            minimumSignificantDigits: 2,
        });
    },
    formatSimpleValue: (v: ValueSimpleDTO): string => {
        const rounded = ValueUtils.formatNumber(v.value);
        return v.unit ? `${rounded} ${v.unit.abbreviation}` : `${rounded}`;
    },
    formatValue: (value: ValueDTO): string => {
        if (value.unit !== null) {
            const unit = {id: value.unit.id, name: value.unit.name, abbreviation: value.unit.abbreviation};
            return ValueUtils.formatValueUnit(value.value, unit);
        }
        return ValueUtils.formatValueUnit(value.value);
    },
    formatValueUnit: (value: number, unit?: UnitSimpleDTO | undefined | null): string => {
        const rounded = ValueUtils.formatNumber(value);
        return unit ? `${rounded} ${unit.abbreviation}` : `${rounded}`;
    },
};

const possibleUnitsWithNewOnesFromUnit = (knownUnits: UnitDTO[], checkedUnit: UnitDTO | null): UnitDTO[] => {
    if (checkedUnit == null || knownUnits.findIndex((eachUnit) => eachUnit.id == checkedUnit.id) >= 0)
        return knownUnits;
    let newKnownUnits = knownUnits.concat([checkedUnit]);
    newKnownUnits = possibleUnitsWithNewOnesFromUnit(newKnownUnits, checkedUnit.baseUnit);
    if (checkedUnit.alternativeUnits == null) return newKnownUnits;
    checkedUnit.alternativeUnits.forEach(
        (eachAltUnit) => (newKnownUnits = possibleUnitsWithNewOnesFromUnit(newKnownUnits, eachAltUnit))
    );
    return newKnownUnits;
};

export const UnitUtils = {
    possibleUnitsFromUnit: (checkedUnit: UnitDTO | null): UnitDTO[] => {
        return possibleUnitsWithNewOnesFromUnit([], checkedUnit);
    },
};

export default Utils;
export {API, createApiConfig} from "./API";
