import React, {ChangeEvent} from "react";
import {Link, useParams} from "react-router-dom";
import {CommunicationError, NotFound} from "../../../common/errors";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
import {LoadingBackdrop} from "../../../common/Loading";
import {Box, Button, Container, Grid, TextField} from "@material-ui/core";
import {
    ExpandLessActionButton,
    ExpandMoreActionButton,
    InfoActionButton,
    LinkActionButton,
    RefreshActionButton,
} from "../../../common/buttons";
import {ROUTES} from "../../../../routes/routes";
import {API, createApiConfig} from "../../../../utils";
import {
    TrackedDomainInGroupDTO,
    TrackedGoalInGroupDTO,
    TrackedIndicatorGroupWithContentDTO,
    TrackedIndicatorInGroupDTO,
} from "../../../../models/trackedIndicators";
import {defaultPalette} from "../../../../styles";
import {DomainDialog} from "../../../values/dialogs/DomainDialog";
import {GoalDialog} from "../../../values/dialogs/GoalDialog";
import {IndicatorDialog} from "../../../values/dialogs/IndicatorDialog";
import SearchingSVG from "../../../../graphics/undraw/searching_re_stk8.svg";
import WorkingSVG from "../../../../graphics/undraw/factory_dy0a.svg";
import {InfoNotification} from "../../../common/notifications";
import {IndicatorHistory} from "../../../values/IndicatorHistory";
import BusinessIcon from "@material-ui/icons/Business";
import KeyboardIcon from "@material-ui/icons/Keyboard";
import EditIcon from "@material-ui/icons/Edit";
import {isAdmin, isUser} from "../../../../utils/auth";
import {
    adminOrganizationPermissions,
    anonymousOrganizationPermissions,
    OrganizationPermissionsDTO,
} from "../../../../models/members";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import AssessmentIcon from "@material-ui/icons/Assessment";
import {BreadcrumbItem, BreadcrumbsRow} from "../../../common/breadcrumbs";
import {PageHeader} from "../../../common/headers";
import {TagDTO} from "../../../../models/admin";
import {TagsInput} from "../../../common/tags";
import {ManualSidebar} from "../../../../manual/components/ManualSidebar";
import {csPublicIndicatorsSection} from "../../../../manual/content/cs/CsPublicManualChapter";
import {csMunicipalityIndicatorBrowsingSection} from "../../../../manual/content/cs/CsMunicipalityManualChapter";

interface WithOrder {
    orderNumber: number;
}

function compareDesc(a: WithOrder, b: WithOrder): number {
    return a.orderNumber - b.orderNumber;
}

//=============================================================================

interface TrackedItemBrowserProps {
    humanIdentifier: string;
    organizationId: string;
    permissions?: OrganizationPermissionsDTO;
}

interface TrackedIndicatorBrowserProps extends TrackedItemBrowserProps {
    indicator: TrackedIndicatorInGroupDTO;
}

interface TrackedIndicatorBrowserState {
    open: boolean;
    info: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        editButtonWrapper: {
            display: "flex",
            justifyContent: "flex-end",
        },
        editButton: {
            marginLeft: "0.5em",
        },
        withInfo: {
            display: "inline-block",
        },
    })
);

const TrackedIndicatorBrowser: React.FC<TrackedIndicatorBrowserProps> = (props: TrackedIndicatorBrowserProps) => {
    // TODO: display goal value if is set
    //== Init =================================================================
    const classes = useStyles();
    const initState: TrackedIndicatorBrowserState = {
        open: false,
        info: false,
    };
    const [state, setState] = React.useState(initState);
    //== Handlers =============================================================
    const handleExpand = (): void => {
        setState({...state, open: true});
    };
    const handleCollapse = (): void => {
        setState({...state, open: false});
    };
    const handleInfoOpen = (): void => {
        setState({...state, info: true});
    };
    const handleInfoClose = (): void => {
        setState({...state, info: false});
    };
    //== Render ===============================================================
    return (
        <Box borderBottom={1} borderColor="primary.main" style={{borderColor: defaultPalette.primary.main}}>
            <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                style={{paddingTop: "0.5em", paddingBottom: "0,5em"}}
            >
                {" "}
                <Box>
                    {state.open ? (
                        <ExpandLessActionButton key="less" onClick={handleCollapse} />
                    ) : (
                        <ExpandMoreActionButton key="more" onClick={handleExpand} />
                    )}
                </Box>
                <Box>
                    <h3 className={classes.withInfo}>
                        <span>{props.humanIdentifier}</span> {props.indicator.indicator.name}
                    </h3>
                </Box>
                <Box flexGrow="1">
                    <InfoActionButton onClick={handleInfoOpen} />
                </Box>
            </Box>
            <IndicatorDialog indicator={props.indicator.indicator} handleClose={handleInfoClose} open={state.info} />
            {state.open && (
                <IndicatorHistory
                    indicator={props.indicator}
                    organizationId={props.organizationId}
                    permissions={props.permissions}
                />
            )}
        </Box>
    );
};

interface TrackedGoalBrowserProps extends TrackedItemBrowserProps {
    goal: TrackedGoalInGroupDTO;
}

interface TrackedGoalBrowserState {
    open: boolean;
    info: boolean;
}

const TrackedGoalBrowser: React.FC<TrackedGoalBrowserProps> = (props: TrackedGoalBrowserProps) => {
    //== Init =================================================================
    const classes = useStyles();
    const sortedIndicators = props.goal.trackedIndicators.sort(compareDesc);
    const initState: TrackedGoalBrowserState = {
        open: false,
        info: false,
    };
    const [state, setState] = React.useState(initState);
    //== Handlers =============================================================
    const handleExpand = (): void => {
        setState({...state, open: true});
    };
    const handleCollapse = (): void => {
        setState({...state, open: false});
    };
    const handleInfoOpen = (): void => {
        setState({...state, info: true});
    };
    const handleInfoClose = (): void => {
        setState({...state, info: false});
    };
    //== Render ===============================================================
    return (
        <Box>
            <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                style={{
                    paddingTop: "0.5em",
                    paddingBottom: "0,5em",
                    borderColor: defaultPalette.primary.main,
                }}
                borderBottom={2}
                borderColor="primary.main"
            >
                {" "}
                <Box>
                    {state.open ? (
                        <ExpandLessActionButton key="less" onClick={handleCollapse} />
                    ) : (
                        <ExpandMoreActionButton
                            key="more"
                            onClick={handleExpand}
                            disabled={props.goal.trackedIndicators.length === 0}
                        />
                    )}
                </Box>
                <Box>
                    <h3 className={classes.withInfo}>
                        <span>{props.humanIdentifier}</span> {props.goal.goal.name}
                    </h3>
                </Box>
                <Box flexGrow="1">
                    <InfoActionButton onClick={handleInfoOpen} />
                </Box>
            </Box>
            {state.open && (
                <Box>
                    {sortedIndicators.map((i, index) => (
                        <TrackedIndicatorBrowser
                            key={i.id}
                            humanIdentifier={`${props.humanIdentifier}${index + 1}.`}
                            indicator={i}
                            organizationId={props.organizationId}
                            permissions={props.permissions}
                        />
                    ))}
                </Box>
            )}
            <GoalDialog goal={props.goal.goal} handleClose={handleInfoClose} open={state.info} />
        </Box>
    );
};

interface TrackedDomainBrowserProps extends TrackedItemBrowserProps {
    domain: TrackedDomainInGroupDTO;
}

interface TrackedDomainBrowserState {
    open: boolean;
    info: boolean;
}

const TrackedDomainBrowser: React.FC<TrackedDomainBrowserProps> = (props: TrackedDomainBrowserProps) => {
    //== Init =================================================================
    const classes = useStyles();
    const sortedGoals = props.domain.trackedGoals.sort(compareDesc);
    const initState: TrackedDomainBrowserState = {
        open: false,
        info: false,
    };
    const [state, setState] = React.useState(initState);
    //== Handlers =============================================================
    const handleExpand = (): void => {
        setState({...state, open: true});
    };
    const handleCollapse = (): void => {
        setState({...state, open: false});
    };
    const handleInfoOpen = (): void => {
        setState({...state, info: true});
    };
    const handleInfoClose = (): void => {
        setState({...state, info: false});
    };
    //== Render ===============================================================
    return (
        <Box>
            <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                style={{
                    paddingTop: "0.5em",
                    paddingBottom: "0,5em",
                    borderColor: defaultPalette.primary.main,
                }}
                borderBottom={3}
                borderColor="primary.main"
            >
                <Box>
                    {state.open ? (
                        <ExpandLessActionButton key="less" onClick={handleCollapse} />
                    ) : (
                        <ExpandMoreActionButton
                            key="more"
                            onClick={handleExpand}
                            disabled={props.domain.trackedGoals.length === 0}
                        />
                    )}
                </Box>
                <Box>
                    <h3 className={classes.withInfo}>
                        <span>{props.humanIdentifier}</span> {props.domain.domain.name}
                    </h3>
                </Box>
                <Box flexGrow="1">
                    <InfoActionButton onClick={handleInfoOpen} />
                </Box>
            </Box>
            {state.open && (
                <Box>
                    {sortedGoals.map((g, index) => (
                        <TrackedGoalBrowser
                            key={g.id}
                            humanIdentifier={`${props.humanIdentifier}${index + 1}.`}
                            goal={g}
                            organizationId={props.organizationId}
                            permissions={props.permissions}
                        />
                    ))}
                </Box>
            )}
            <DomainDialog domain={props.domain.domain} handleClose={handleInfoClose} open={state.info} />
        </Box>
    );
};

interface TrackedIndicatorsBrowserProps {
    trackedIndicatorGroup: TrackedIndicatorGroupWithContentDTO;
    permissions?: OrganizationPermissionsDTO;
}

interface TrackedIndicatorGroupRootBrowserState {
    domains: TrackedDomainInGroupDTO[];
    filter: string;
    tags: TagDTO[];
    tagIds: Set<string>;
}

const useGroupRootStyles = makeStyles((theme: Theme) =>
    createStyles({
        filterRow: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            alignItems: "flex-end",
        },
        filterString: {
            padding: theme.spacing(1),
        },
        filterTags: {
            padding: theme.spacing(1),
        },
    })
);

function matchTags(tags: TagDTO[], tagIds: Set<string>): boolean {
    return tagIds.size === 0 || tags.some((tag) => tagIds.has(tag.id));
}

function matchIndicator(indicator: TrackedIndicatorInGroupDTO, filter: string, tagIds: Set<string>): boolean {
    return indicator.indicator.name.toLowerCase().includes(filter) && matchTags(indicator.indicator.tags, tagIds);
}

function filterIndicators(
    indicators: TrackedIndicatorInGroupDTO[],
    filter: string,
    tagIds: Set<string>
): TrackedIndicatorInGroupDTO[] {
    return indicators.filter((indicator) => matchIndicator(indicator, filter, tagIds)).sort(compareDesc);
}

function matchGoal(goal: TrackedGoalInGroupDTO, filter: string, tagIds: Set<string>): boolean {
    return goal.goal.name.toLowerCase().includes(filter) && matchTags(goal.goal.tags, tagIds);
}

function filterGoals(goals: TrackedGoalInGroupDTO[], filter: string, tagIds: Set<string>): TrackedGoalInGroupDTO[] {
    return goals
        .map((goal): TrackedGoalInGroupDTO => {
            return {...goal, trackedIndicators: filterIndicators(goal.trackedIndicators, filter, tagIds)};
        })
        .filter((goal) => goal.trackedIndicators.length > 0 || matchGoal(goal, filter, tagIds))
        .sort(compareDesc);
}

function matchDomain(domain: TrackedDomainInGroupDTO, filter: string, tagIds: Set<string>): boolean {
    return domain.domain.name.toLowerCase().includes(filter) && matchTags(domain.domain.tags, tagIds);
}

function filterDomains(
    domains: TrackedDomainInGroupDTO[],
    filter: string,
    tagIds: Set<string>
): TrackedDomainInGroupDTO[] {
    return domains
        .map((domain): TrackedDomainInGroupDTO => {
            return {...domain, trackedGoals: filterGoals(domain.trackedGoals, filter, tagIds)};
        })
        .filter((domain) => domain.trackedGoals.length > 0 || matchDomain(domain, filter, tagIds))
        .sort(compareDesc);
}

const TrackedIndicatorGroupRootBrowser: React.FC<TrackedIndicatorsBrowserProps> = (
    props: TrackedIndicatorsBrowserProps
) => {
    //== Init =================================================================
    const [t] = useTranslation(["trackedIndicators"]);
    const classes = useGroupRootStyles();
    const [state, setState] = React.useState<TrackedIndicatorGroupRootBrowserState>({
        domains: props.trackedIndicatorGroup.trackedDomains.sort(compareDesc),
        filter: "",
        tags: [],
        tagIds: new Set<string>(),
    });
    //== Handlers =============================================================
    const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
        const filter = event.target.value.toLowerCase();
        setState({
            ...state,
            filter: filter,
            domains: filterDomains(props.trackedIndicatorGroup.trackedDomains, filter, state.tagIds),
        });
    };
    const handleTagsChange = (tags: TagDTO[]) => {
        const tagIds = new Set(tags.map((t) => t.id));
        setState({
            ...state,
            tags: tags,
            tagIds: tagIds,
            domains: filterDomains(props.trackedIndicatorGroup.trackedDomains, state.filter, tagIds),
        });
    };
    //== Render ===============================================================
    const noDomain = props.trackedIndicatorGroup.trackedDomains.length === 0;
    const allFiltered = !noDomain && state.domains.length === 0;
    return (
        <Container maxWidth="xl">
            <Grid container className={classes.filterRow}>
                <Grid item container md={6} sm={12} className={classes.filterString}>
                    <TextField
                        label={t("browser.filters.byName")}
                        value={state.filter}
                        onChange={handleFilterChange}
                        fullWidth
                    />
                </Grid>
                <Grid item container md={6} sm={12} className={classes.filterTags}>
                    <TagsInput
                        label={t("browser.filters.byTags")}
                        value={state.tags}
                        onChange={handleTagsChange}
                        fullWidth
                    />
                </Grid>
            </Grid>
            {state.domains.map((d, index) => (
                <TrackedDomainBrowser
                    key={d.id}
                    humanIdentifier={`${index + 1}.`}
                    domain={d}
                    organizationId={props.trackedIndicatorGroup.organization.publicId}
                    permissions={props.permissions}
                />
            ))}
            {noDomain && (
                <Box style={{textAlign: "center"}}>
                    <h2>{t("browser.no_domains")}</h2>
                    <img src={WorkingSVG} style={{maxWidth: "40em", margin: "3em"}} alt="no-domains" />
                </Box>
            )}
            {allFiltered && (
                <Box style={{textAlign: "center"}}>
                    <h2>{t("browser.no_domains_filtered")}</h2>
                    <img src={SearchingSVG} style={{maxWidth: "40em", margin: "3em"}} alt="no-domains" />
                </Box>
            )}
        </Container>
    );
};

interface TrackedIndicatorsBrowserState {
    name: "loading" | "loaded" | "refreshing" | "refreshed" | "not_found" | "failed";
    permissions: OrganizationPermissionsDTO;
    trackedIndicatorGroup: TrackedIndicatorGroupWithContentDTO | null;
}

interface TrackedIndicatorsBrowserParams {
    organizationId: string;
    trackedIndicatorGroupId: string;
}

export const TrackedIndicatorsBrowser: React.FC = () => {
    //== Init =================================================================
    const classes = useStyles();
    const {organizationId, trackedIndicatorGroupId} = useParams<TrackedIndicatorsBrowserParams>();
    const initState: TrackedIndicatorsBrowserState = {
        name: "loading",
        permissions: anonymousOrganizationPermissions(organizationId),
        trackedIndicatorGroup: null,
    };
    const [state, setState] = React.useState(initState);
    const {keycloak, initialized} = useKeycloak();
    const [t] = useTranslation(["trackedIndicators"]);
    //== Effects ==============================================================
    const setPermissionsAndTrackedIndicatorGroup = (
        trackedIndicatorGroup: TrackedIndicatorGroupWithContentDTO,
        permissions: OrganizationPermissionsDTO
    ) => {
        setState({
            ...state,
            name: "loaded",
            permissions: permissions,
            trackedIndicatorGroup: trackedIndicatorGroup,
        });
    };
    React.useEffect(() => {
        if (state.name === "loading" || state.name === "refreshing") {
            API.get<TrackedIndicatorGroupWithContentDTO>(
                `/tracked-indicator-groups/with-content/${trackedIndicatorGroupId}`,
                createApiConfig(keycloak, initialized)
            )
                .then((trackedIndicatorGroupRes) => {
                    if (!trackedIndicatorGroupRes.data) return setState({...state, name: "failed"});
                    if (isAdmin(keycloak)) {
                        console.log("permissions: admin");
                        setPermissionsAndTrackedIndicatorGroup(
                            trackedIndicatorGroupRes.data,
                            adminOrganizationPermissions(organizationId)
                        );
                    } else if (isUser(keycloak)) {
                        console.log("permissions: asking server...");
                        API.get<OrganizationPermissionsDTO>(
                            `/organization-permissions/${organizationId}/current-user`,
                            createApiConfig(keycloak, initialized)
                        )
                            .then((res) => {
                                if (!res.data) return setState({...state, name: "failed"});
                                setPermissionsAndTrackedIndicatorGroup(trackedIndicatorGroupRes.data, res.data);
                            })
                            .catch((err) => {
                                console.log("permissions get error:");
                                console.log(err);
                                return setState({...state, name: "failed"});
                            });
                    } else {
                        console.log("permissions: anonymous");
                        setPermissionsAndTrackedIndicatorGroup(
                            trackedIndicatorGroupRes.data,
                            anonymousOrganizationPermissions(organizationId)
                        );
                    }
                })
                .catch((err) => {
                    console.log("tracked indicator group with content get error:");
                    console.log(err);
                    setState({...state, name: "failed"});
                });
        }
    }, [state, setState, keycloak, initialized]);
    //== Handlers =============================================================
    const handleRefresh = (): void => {
        setState({...state, name: "refreshing"});
    };
    //== Permissions ==========================================================
    // TODO: rework with only browsing input values
    console.log("permissions:");
    console.log(state.permissions);
    const canEditPlan = state.permissions && state.permissions.trackedIndicatorsPermissionLevel === "READWRITE";
    const canEnterValues = state.permissions && state.permissions.valuesPermissionLevel === "READWRITE";
    //== Render ===============================================================
    console.log("canEditPlan:" + canEditPlan);
    return (
        <Container>
            <ManualSidebar
                section={
                    state.permissions.trackedIndicatorsPermissionLevel === "NONE"
                        ? csPublicIndicatorsSection
                        : csMunicipalityIndicatorBrowsingSection
                }
            />
            <BreadcrumbsRow>
                <BreadcrumbItem
                    name={state.trackedIndicatorGroup?.organization.name || t("organization:organization.municipality")}
                    route={ROUTES.organization.create(organizationId)}
                />
                <BreadcrumbItem
                    name={t("trackedIndicatorGroup.trackedIndicatorGroups")}
                    route={ROUTES.trackedIndicatorGroups.create(organizationId)}
                />
                <BreadcrumbItem
                    name={state.trackedIndicatorGroup?.name || t("browser.simpleTitle")}
                    route={ROUTES.trackedIndicatorsBrowser.create(organizationId, trackedIndicatorGroupId)}
                />
            </BreadcrumbsRow>
            <PageHeader
                title={
                    state.trackedIndicatorGroup
                        ? state.trackedIndicatorGroup.organization.name + " - " + state.trackedIndicatorGroup.name
                        : t("browser.simpleTitle")
                }
            >
                <LinkActionButton
                    to={ROUTES.organization.create(organizationId)}
                    icon={<BusinessIcon />}
                    title={t("actions.organization")}
                />
                {canEnterValues && (
                    <LinkActionButton
                        to={ROUTES.valuesInput.create(organizationId)}
                        icon={<KeyboardIcon />}
                        title={t("actions.valuesInput")}
                    />
                )}
                <RefreshActionButton onClick={handleRefresh} />
            </PageHeader>
            <Container maxWidth="xl">
                {state.name === "loading" ? (
                    <LoadingBackdrop />
                ) : state.name === "not_found" ? (
                    <NotFound />
                ) : state.name === "failed" ? (
                    <CommunicationError />
                ) : !state.trackedIndicatorGroup ? (
                    <CommunicationError />
                ) : (
                    <>
                        {canEditPlan && (
                            <Box className={classes.editButtonWrapper}>
                                <Button
                                    component={Link}
                                    to={ROUTES.trackedIndicatorSummary.create(organizationId, trackedIndicatorGroupId)}
                                    variant="contained"
                                    className={classes.editButton}
                                    startIcon={<AssessmentIcon />}
                                >
                                    {t("actions.trackedIndicatorSummary")}
                                </Button>
                                <Button
                                    component={Link}
                                    to={ROUTES.trackedIndicatorsEditor.create(organizationId, trackedIndicatorGroupId)}
                                    variant="contained"
                                    color="primary"
                                    className={classes.editButton}
                                    startIcon={<EditIcon />}
                                >
                                    {t("actions.trackedIndicatorGroupEditor")}
                                </Button>
                            </Box>
                        )}

                        <TrackedIndicatorGroupRootBrowser
                            trackedIndicatorGroup={state.trackedIndicatorGroup}
                            permissions={state.permissions}
                        />
                    </>
                )}
                {state.name === "refreshed" && <InfoNotification message={t("browser.notifications.refreshed")} />}
            </Container>
        </Container>
    );
};
