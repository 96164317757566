import React from "react";
import {useTranslation} from "react-i18next";
import {LibraryDTO} from "../../models/library";
import {GenericEntityDeleteContentProps, GenericEntityNoLinkedDelete} from "../common/delete";
import {extractEntitiesByType} from "../../models/error";

const UnitRenderDelete: React.FC<GenericEntityDeleteContentProps> = (props: GenericEntityDeleteContentProps) => {
    //== Init =================================================================
    const [t] = useTranslation("libraries");
    const domainUses = extractEntitiesByType(props.error, "LibraryDomainUse");
    const domains = extractEntitiesByType(props.error, "Domain");
    const goals = extractEntitiesByType(props.error, "Goal");
    const indicators = extractEntitiesByType(props.error, "Indicator");
    const isPublic = props.error.reasons.includes("published");
    const isShared = props.error.reasons.includes("shared");
    const tPrefix = "library.confirm_delete.render";
    //== Render ===============================================================
    return (
        <ul>
            {isPublic && <li>{t(`${tPrefix}.public`)}</li>}
            {isShared && <li>{t(`${tPrefix}.shared`)}</li>}
            {domainUses.length > 0 && <li>{t(`${tPrefix}.domainUses`, {count: domainUses.length})}</li>}
            {domains.length > 0 && <li>{t(`${tPrefix}.domains`, {count: domains.length})}</li>}
            {goals.length > 0 && <li>{t(`${tPrefix}.goals`, {count: goals.length})}</li>}
            {indicators.length > 0 && <li>{t(`${tPrefix}.indicators`, {count: indicators.length})}</li>}
        </ul>
    );
};

interface LibraryDeleteProps {
    entity: LibraryDTO | null;
    onDeleted: () => void;
    onCancel: () => void;
    onFailed: () => void;
}

export const LibraryDelete: React.FC<LibraryDeleteProps> = (props: LibraryDeleteProps) => {
    return (
        <GenericEntityNoLinkedDelete
            tNs="libraries"
            tPrefix="library"
            urlSlug="libraries"
            RenderContent={UnitRenderDelete}
            {...props}
        />
    );
};
