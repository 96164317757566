import React from "react";
import {Snackbar} from "@material-ui/core";
import MuiAlert, {AlertProps} from "@material-ui/lab/Alert";

interface NotificationProps {
    type?: "success" | "error" | "warning" | "info";
    message: string;
    duration?: number;
}

interface SimpleNotificationProps {
    message: string;
}

function Alert(props: AlertProps): JSX.Element {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const Notification: React.FC<NotificationProps> = (props: NotificationProps) => {
    //== Init ===================================================================
    const {type = "info", message, duration = 6000} = props;
    const [open, setOpen] = React.useState(true);
    //== Handlers ===============================================================
    const handleClose = (event?: React.SyntheticEvent, reason?: string): void => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };
    //== Render =================================================================
    return (
        <Snackbar open={open} autoHideDuration={duration} onClose={handleClose}>
            <Alert onClose={handleClose} severity={type}>
                {message}
            </Alert>
        </Snackbar>
    );
};

export const ErrorNotification: React.FC<SimpleNotificationProps> = (props: SimpleNotificationProps) => {
    return <Notification message={props.message} type="error" />;
};

export const SuccessNotification: React.FC<SimpleNotificationProps> = (props: SimpleNotificationProps) => {
    return <Notification message={props.message} type="success" />;
};

export const WarningNotification: React.FC<SimpleNotificationProps> = (props: SimpleNotificationProps) => {
    return <Notification message={props.message} type="warning" />;
};

export const InfoNotification: React.FC<SimpleNotificationProps> = (props: SimpleNotificationProps) => {
    return <Notification message={props.message} type="info" />;
};
