// Definition of special-purpose webpages
import ROUTES from "./routes";
import {WebpageDTO} from "../models/admin";

const SPECIAL_WEBPAGES_NAMES = {
    landing: "landing",
    contacts: "contacts",
    partners: "partners",
};

const SPECIAL_WEBPAGES_ROUTES: Map<string, string> = new Map([
    ["landing", ROUTES.landing.path],
    ["contacts", ROUTES.contacts.path],
    ["partners", ROUTES.partners.path],
]);

export const WEBPAGES = {
    names: SPECIAL_WEBPAGES_NAMES,
    routes: SPECIAL_WEBPAGES_ROUTES,
    isSpecial(webpage: WebpageDTO): boolean {
        return this.routes.has(webpage.publicId);
    },
    getViewPath(webpage: WebpageDTO): string {
        return this.routes.get(webpage.publicId) ?? ROUTES.cmsPageView.create(webpage.publicId);
    },
    getEditPath(webpage: WebpageDTO): string {
        return ROUTES.cmsPageEdit.create(webpage.publicId);
    },
};
