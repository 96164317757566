import React from "react";
import {Redirect} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useKeycloak} from "@react-keycloak/web";
// Project imports
import {WebpageDTO} from "../../models/admin";
// Material-UI imports
import {Box} from "@material-ui/core";
import {API, createApiConfig} from "../../utils";
import {AxiosError} from "axios";
import CMSPageForm from "./CMSPageForm";
import {LoadingBackdrop} from "../common/Loading";
import {ErrorNotification} from "../common/notifications";
import ROUTES from "../../routes/routes";

export const CMSPageNew: React.FC = () => {
    //== Init =================================================================
    const [t] = useTranslation("webpages");
    const {keycloak, initialized} = useKeycloak();
    const emptyWebpage: WebpageDTO = {
        publicId: "",
        title: "",
        description: "",
        keywords: "",
        content: "",
        author: null,
        tags: [],
    };
    const [state, setState] = React.useState({
        webpage: emptyWebpage,
        name: "init",
    });
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "saving") {
            API.post<WebpageDTO>(`/cms/webpages`, state.webpage, createApiConfig(keycloak, initialized))
                .then((res) => {
                    setState({...state, webpage: res.data, name: "saved"});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [keycloak, initialized, state, setState]);
    //== Handlers =============================================================
    const handleChange = (webpage: WebpageDTO): void => {
        setState({...state, webpage: webpage});
    };
    const handleSubmit = (): void => {
        setState({...state, name: "saving"});
    };
    //== Render ===============================================================
    let appendix: JSX.Element | null = null;
    if (state.name === "saving") {
        appendix = <LoadingBackdrop />;
    }
    if (state.name === "failed") {
        appendix = <ErrorNotification message={t("notifications.create_fail")} />;
    }
    if (state.name === "saved") {
        return <Redirect to={ROUTES.cmsPageEdit.create(state.webpage.publicId)} />;
    }
    return (
        <Box>
            <h1>{t("admin.new")}</h1>
            <CMSPageForm webpage={state.webpage} onChange={handleChange} onSubmit={handleSubmit} />
            {appendix}
        </Box>
    );
};

export default CMSPageNew;
