import React from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useKeycloak} from "@react-keycloak/web";
import {TFunction} from "i18next";
// Project imports
import MENU_ITEMS, {MenuItem} from "./menuItems";
import {isAdmin, isUser} from "../../utils/auth";
// Material-UI imports
import {List, ListItem, ListItemIcon, ListItemText, ListSubheader} from "@material-ui/core";
import {OrganizationDTO} from "../../models/organization";
import {API, createApiConfig} from "../../utils";
import {AxiosError} from "axios";
import ROUTES from "../../routes/routes";
import BusinessIcon from "@material-ui/icons/Business";

const createMenuItems = (t: TFunction, lst: MenuItem[]): JSX.Element[] => {
    return lst.map((item: MenuItem) => (
        <ListItem button component={Link} to={item.route} key={item.id}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={t(`menu.${item.id}`) as string} />
        </ListItem>
    ));
};

export const PublicMenu: React.FC = () => {
    const [t] = useTranslation();
    return <List>{createMenuItems(t, MENU_ITEMS.public)}</List>;
};

export const UserMenu: React.FC = () => {
    //== Init =================================================================
    const [t] = useTranslation();
    const {keycloak, initialized} = useKeycloak();
    //== Render ===============================================================
    if (!isUser(keycloak) || !initialized) {
        return null;
    }
    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                    {t("menu.personal")}
                </ListSubheader>
            }
        >
            {createMenuItems(t, MENU_ITEMS.user)}
        </List>
    );
};

interface MunicipalitiesMenuState {
    name: "loading" | "loaded" | "failed";
    municipalities: OrganizationDTO[];
}

export const MunicipalitiesMenu: React.FC = () => {
    //== Init =================================================================
    const initState: MunicipalitiesMenuState = {
        name: "loading",
        municipalities: [],
    };
    const [state, setState] = React.useState(initState);
    const [t] = useTranslation();
    const {keycloak, initialized} = useKeycloak();
    //== Effects ==============================================================
    React.useEffect(() => {
        if (isUser(keycloak) && state.name === "loading") {
            API.get<OrganizationDTO[]>("/profile/municipalities", createApiConfig(keycloak, initialized))
                .then((res) => {
                    const tmp: OrganizationDTO[] = res.data;
                    const ms = tmp.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
                    setState({...state, municipalities: ms, name: "loaded"});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [state, setState, keycloak, initialized]);
    //== Render ===============================================================
    if (state.name === "loaded" && state.municipalities.length > 0) {
        // TODO: use municipality logo/coat of arms as icon?
        const items: JSX.Element[] = state.municipalities.map((o: OrganizationDTO) => (
            <ListItem button component={Link} to={ROUTES.organization.create(o.publicId)} key={o.id}>
                <ListItemIcon>
                    <BusinessIcon />
                </ListItemIcon>
                <ListItemText primary={o.name} />
            </ListItem>
        ));
        return (
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        {t("menu.municipalities")}
                    </ListSubheader>
                }
            >
                {items}
            </List>
        );
    }
    return null;
};

export const AdminMenu: React.FC = () => {
    //== Init =================================================================
    const [t] = useTranslation();
    const {keycloak, initialized} = useKeycloak();
    //== Render ===============================================================
    if (!isAdmin(keycloak) || !initialized) {
        return null;
    }
    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                    {t("menu.administration")}
                </ListSubheader>
            }
        >
            {createMenuItems(t, MENU_ITEMS.admin)}
        </List>
    );
};
