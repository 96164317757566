import React from "react";
import {InputVariableDTO} from "../../../models/library";
import {GenericEntityDeleteContentProps, GenericEntityNoLinkedDelete} from "../../common/delete";
import {useTranslation} from "react-i18next";
import {extractEntitiesByType} from "../../../models/error";
import {Link} from "@material-ui/core";
import ROUTES from "../../../routes/routes";

const InputVariableRenderDelete: React.FC<GenericEntityDeleteContentProps> = (
    props: GenericEntityDeleteContentProps
) => {
    //== Init =================================================================
    const [t] = useTranslation("libraries");
    const inputVariables = extractEntitiesByType(props.error, "MunicipalityInputVariable");
    const indicators = extractEntitiesByType(props.error, "Indicator");
    const tPrefix = "definitions.input_variables.confirm_delete.render";
    //== Render ===============================================================
    return (
        <ul>
            {inputVariables.length > 0 && (
                <li>
                    {t(`${tPrefix}.inputVariables`, {count: inputVariables.length})}
                    <ul>
                        {inputVariables.map((item) => (
                            <li key={item.id}>
                                <Link href={ROUTES.valuesInput.create(item.parentId ?? "not-found")} target="_blank">
                                    {item.displayName}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </li>
            )}
            {indicators.length > 0 && (
                <li>
                    {t(`${tPrefix}.indicators`, {count: indicators.length})}
                    <ul>
                        {indicators.map((item) => (
                            <li key={item.id}>
                                <Link href={ROUTES.library.create(item.parentId ?? "not-found")} target="_blank">
                                    {item.displayName}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </li>
            )}
        </ul>
    );
};

interface InputVariableDeleteProps {
    entity: InputVariableDTO | null;
    onDeleted: () => void;
    onCancel: () => void;
    onFailed: () => void;
}

export const InputVariableDelete: React.FC<InputVariableDeleteProps> = (props: InputVariableDeleteProps) => {
    return (
        <GenericEntityNoLinkedDelete
            tNs="libraries"
            tPrefix="definitions.input_variables"
            urlSlug="input-variables"
            RenderContent={InputVariableRenderDelete}
            {...props}
        />
    );
};
