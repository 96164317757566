import {emptyLibraryCreateDTO, LibraryCreateDTO, LibraryDTO} from "../../models/library";
import React from "react";
import {Redirect} from "react-router";
import {useTranslation} from "react-i18next";
import {useKeycloak} from "@react-keycloak/web";
import {API, createApiConfig} from "../../utils/API";
import {AxiosError} from "axios";
import ROUTES from "../../routes/routes";
import {ErrorNotification} from "../common/notifications";
import {Container} from "@material-ui/core";
import {LibraryForm} from "./LibraryForm";

interface LibraryFormCreateProps {
    organizationId?: string;
}

interface LibraryFormCreateState {
    library: LibraryCreateDTO;
    newId: string;
    name: string;
}

export const LibraryFormCreate: React.FC<LibraryFormCreateProps> = (props: LibraryFormCreateProps) => {
    //== Init =================================================================
    const [t] = useTranslation("libraries");
    const {keycloak, initialized} = useKeycloak();
    const initState: LibraryFormCreateState = {
        library: {
            ...emptyLibraryCreateDTO,
            organizationUUID: props.organizationId ?? "",
        },
        newId: "unknown",
        name: "init",
    };
    const [state, setState] = React.useState(initState);
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "saving") {
            API.post<LibraryDTO>(`/libraries`, state.library, createApiConfig(keycloak, initialized))
                .then((res) => {
                    setState({
                        library: {...res.data, organizationUUID: res.data.organization.id},
                        newId: res.data?.id ?? state.newId,
                        name: "created",
                    });
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [state, setState, keycloak, initialized]);
    //== Handlers =============================================================
    const handleChange = (library: LibraryCreateDTO): void => {
        setState({...state, library: library});
    };
    const handleSubmit = (): void => {
        setState({...state, name: "saving"});
    };
    //== Render ===============================================================
    if (state.name === "created") {
        return <Redirect to={ROUTES.libraryEditor.create(state.newId)} />;
    }
    let notification = null;
    if (state.name === "failed") {
        notification = <ErrorNotification message={t("notifications.create_fail")} />;
    }
    return (
        <Container maxWidth="md">
            <LibraryForm
                library={state.library}
                onChange={handleChange}
                onSubmit={handleSubmit}
                allowOrganization={props.organizationId === undefined}
            />
            {notification}
        </Container>
    );
};
