import React, {ChangeEvent} from "react";
import {LibraryDomainUseDTO, LibraryDTO, LibraryGoalUseDTO, LibraryIndicatorUseDTO} from "../../../models/library";
import {compareByAttr} from "../../../utils/compare";
import {LibraryDomainBrowser} from "./LibraryDomainBrowser";
import {Box, Container, Grid, TextField} from "@material-ui/core";
import {TagDTO} from "../../../models/admin";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {TagsInput} from "../../common/tags";
import {useTranslation} from "react-i18next";
import WorkingSVG from "../../../graphics/undraw/factory_dy0a.svg";
import SearchingSVG from "../../../graphics/undraw/searching_re_stk8.svg";

interface LibraryBrowserProps {
    library: LibraryDTO;
}

interface LibraryBrowserState {
    domains: LibraryDomainUseDTO[];
    filter: string;
    tags: TagDTO[];
    tagIds: Set<string>;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        filterRow: {
            marginBottom: theme.spacing(2),
            alignItems: "flex-end",
        },
        filterString: {
            padding: theme.spacing(1),
        },
        filterTags: {
            padding: theme.spacing(1),
        },
    })
);

function matchTags(tags: TagDTO[], tagIds: Set<string>): boolean {
    if (tagIds.size !== 0 && tags.length === 0) {
        return false;
    }
    return tagIds.size === 0 || tags.some((tag) => tagIds.has(tag.id));
}

function matchIndicator(indicator: LibraryIndicatorUseDTO, filter: string, tagIds: Set<string>): boolean {
    return indicator.indicator.name.toLowerCase().includes(filter) && matchTags(indicator.indicator.tags, tagIds);
}

function filterIndicators(
    indicators: LibraryIndicatorUseDTO[],
    filter: string,
    tagIds: Set<string>
): LibraryIndicatorUseDTO[] {
    return indicators
        .filter((indicator) => matchIndicator(indicator, filter, tagIds))
        .sort(compareByAttr("order", "ascending"));
}

function matchGoal(goal: LibraryGoalUseDTO, filter: string, tagIds: Set<string>): boolean {
    return goal.goal.name.toLowerCase().includes(filter) && matchTags(goal.goal.tags, tagIds);
}

function filterGoals(goals: LibraryGoalUseDTO[], filter: string, tagIds: Set<string>): LibraryGoalUseDTO[] {
    return goals
        .map((goal): LibraryGoalUseDTO => {
            return {
                ...goal,
                libraryIndicatorUses: filterIndicators(goal.libraryIndicatorUses, filter, tagIds),
            };
        })
        .filter((goal) => goal.libraryIndicatorUses.length > 0 || matchGoal(goal, filter, tagIds))
        .sort(compareByAttr("order", "ascending"));
}

function matchDomain(domain: LibraryDomainUseDTO, filter: string, tagIds: Set<string>): boolean {
    return domain.domain.name.toLowerCase().includes(filter) && matchTags(domain.domain.tags, tagIds);
}

function filterDomains(domains: LibraryDomainUseDTO[], filter: string, tagIds: Set<string>): LibraryDomainUseDTO[] {
    return domains
        .map((domain): LibraryDomainUseDTO => {
            return {...domain, libraryGoalUses: filterGoals(domain.libraryGoalUses, filter, tagIds)};
        })
        .filter((domain) => domain.libraryGoalUses.length > 0 || matchDomain(domain, filter, tagIds))
        .sort(compareByAttr("order", "ascending"));
}

export const LibraryBrowser: React.FC<LibraryBrowserProps> = (props: LibraryBrowserProps) => {
    //== Init =================================================================
    const [state, setState] = React.useState<LibraryBrowserState>({
        domains: props.library.libraryDomainUses,
        filter: "",
        tags: [],
        tagIds: new Set<string>(),
    });
    const classes = useStyles();
    const [t] = useTranslation("libraries");
    //== Handlers =============================================================
    const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
        const filter = event.target.value.toLowerCase();
        setState({
            ...state,
            filter: filter,
            domains: filterDomains(props.library.libraryDomainUses, filter, state.tagIds),
        });
    };
    const handleTagsChange = (tags: TagDTO[]) => {
        const tagIds = new Set(tags.map((t) => t.id));
        setState({
            ...state,
            tags: tags,
            tagIds: tagIds,
            domains: filterDomains(props.library.libraryDomainUses, state.filter, tagIds),
        });
    };
    //== Render ===============================================================
    const noDomain = props.library.libraryDomainUses.length === 0;
    const allFiltered = !noDomain && state.domains.length === 0;
    return (
        <Container maxWidth="xl">
            <Grid container className={classes.filterRow}>
                <Grid item container md={6} sm={12} className={classes.filterString}>
                    <TextField
                        label={t("library.filters.byName")}
                        value={state.filter}
                        onChange={handleFilterChange}
                        fullWidth
                    />
                </Grid>
                <Grid item container md={6} sm={12} className={classes.filterTags}>
                    <TagsInput
                        label={t("library.filters.byTags")}
                        value={state.tags}
                        onChange={handleTagsChange}
                        fullWidth
                    />
                </Grid>
            </Grid>
            {state.domains.map((domain) => (
                <LibraryDomainBrowser key={domain.id} libraryDomainUse={domain} {...props} />
            ))}
            {noDomain && (
                <Box style={{textAlign: "center"}}>
                    <h2>{t("library.no_domains")}</h2>
                    <img src={WorkingSVG} style={{maxWidth: "40em", margin: "3em"}} alt="no-domains" />
                </Box>
            )}
            {allFiltered && (
                <Box style={{textAlign: "center"}}>
                    <h2>{t("library.no_domains_filtered")}</h2>
                    <img src={SearchingSVG} style={{maxWidth: "40em", margin: "3em"}} alt="no-domains" />
                </Box>
            )}
        </Container>
    );
};
