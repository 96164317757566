import React from "react";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
// Material UI imports
import {Button, DialogActions, DialogContent} from "@material-ui/core";
// Project imports
import {ServerCommunicationAlert} from "../../../../common/errors";
import {Loading} from "../../../../common/Loading";
import API, {createApiConfig} from "../../../../../utils/API";
import {AxiosError} from "axios";
import DashboardIndicatorsAddFromGroups from "./DashboardIndicatorsAddFromGroups";
import {DashboardIndicatorsAddDialogProps} from "./DashboardIndicatorsAddProps";
import {TrackedIndicatorGroupWithContentDTO} from "../../../../../models/trackedIndicators";
import {DashboardDTO} from "../../../../../models/user";
import {SimpleMunicipalityDTO, simpleMunicipalityFromOrganization} from "../../../../../models/organization";

const DashboardIndicatorsAddFromGroupsContent: React.FC<DashboardIndicatorsAddDialogProps> = (
    props: DashboardIndicatorsAddDialogProps
) => {
    const [t] = useTranslation(["trackedIndicators", "common"]);
    const {keycloak, initialized} = useKeycloak();
    const initState = {
        name: "loading" as "loading" | "loaded" | "closed" | "failed",
        groups: [] as TrackedIndicatorGroupWithContentDTO[],
    };
    const [state, setState] = React.useState(initState);

    //== Effects ================================================================
    function setGroups(
        remainingMunicipalities: SimpleMunicipalityDTO[],
        foundGroups: TrackedIndicatorGroupWithContentDTO[]
    ) {
        if (!remainingMunicipalities.length) {
            return setState({
                ...state,
                groups: foundGroups,
                name: "loaded",
            });
        }
        API.get<TrackedIndicatorGroupWithContentDTO[]>(
            "/tracked-indicator-groups/organization/" + remainingMunicipalities[0].organizationId + "/with-content",
            createApiConfig(keycloak, initialized)
        )
            .then((res) => {
                console.log("[DashboardIndicatorsAddFromGroupsContent] setGroups response:");
                console.log(res);
                setGroups(remainingMunicipalities.slice(1), foundGroups.concat(res.data));
            })
            .catch((err: AxiosError) => {
                console.log("[DashboardIndicatorsAddFromGroupsContent] setGroups error:");
                console.log(err);
                setState({...state, name: "failed"});
            });
    }

    function notNull<T>(val: T | null): val is T {
        return val !== null;
    }

    React.useEffect(() => {
        if (state.name === "loading") {
            if (state.groups.length > 0) {
                setState({...state, name: "loaded"});
            } else {
                if (!props.organizationId) {
                    // personal dashboard
                    if (props.dashboard)
                        return setGroups(
                            props.dashboard.memberships
                                .map((membership) => simpleMunicipalityFromOrganization(membership.organization))
                                .filter(notNull),
                            []
                        );
                    API.get<DashboardDTO>("/dashboard", createApiConfig(keycloak, initialized))
                        .then((res) => {
                            console.log("[DashboardIndicatorsAddFromGroupsContent] useEffect dashboard response:");
                            console.log(res);
                            setGroups(
                                res.data.memberships
                                    .map((membership) => simpleMunicipalityFromOrganization(membership.organization))
                                    .filter(notNull),
                                []
                            );
                        })
                        .catch((err: AxiosError) => {
                            console.log("[DashboardIndicatorsAddFromGroupsContent] useEffect dashboard error:");
                            console.log(err);
                            setState({...state, name: "failed"});
                        });
                    return;
                }
                // municipality public dashboard
                API.get<TrackedIndicatorGroupWithContentDTO[]>(
                    "/tracked-indicator-groups/organization/" + props.organizationId + "/with-content/public",
                    createApiConfig(keycloak, initialized)
                )
                    .then((res) => {
                        setState({
                            ...state,
                            groups: res.data || [],
                            name: "loaded",
                        });
                    })
                    .catch((err: AxiosError) => {
                        console.log(
                            "[DashboardIndicatorsAddFromGroupsContent] useEffect tracked-indicator-groups error:"
                        );
                        console.log(err);
                        setState({...state, name: "failed"});
                    });
            }
        }
    }, [keycloak, initialized, state, setState, props]);
    //== Render =================================================================
    const actions = (
        <DialogActions>
            <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                    props.onCancel();
                }}
                style={{margin: "0.5em"}}
            >
                {t("common:actions.cancel")}
            </Button>
            <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    /*do nothing*/
                }}
                disabled
            >
                {t("common:actions.accept")}
            </Button>
        </DialogActions>
    );
    if (state.name === "failed")
        return (
            <>
                <ServerCommunicationAlert />
                {actions}
            </>
        );
    if (state.name === "closed") {
        return null;
    }
    if (state.name === "loading")
        return (
            <>
                <DialogContent>
                    <Loading />
                </DialogContent>
                {actions}
            </>
        );
    return <DashboardIndicatorsAddFromGroups {...props} groups={state.groups} />;
};

export default DashboardIndicatorsAddFromGroupsContent;
