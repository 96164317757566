import React, {useEffect, useState} from "react";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
// Project imports
import {EmailDTO, EmailVerificationDTO} from "../../../models/user";
import {
    CopyButton,
    CreateActionButton,
    CustomActionButton,
    DeleteActionButton,
    LoadingActionButton,
} from "../../common/buttons";
import {API, createApiConfig} from "../../../utils";
import {AxiosError} from "axios";
// Material-UI imports
import {
    Box,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TableRow,
    TextField,
} from "@material-ui/core";
import {ErrorNotification, SuccessNotification} from "../../common/notifications";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import EmailIcon from "@material-ui/icons/Email";
import {useStyles} from "../../../styles";
import {Alert} from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import {DeleteConfirmationDialog} from "../../common/dialogs";

interface EmailRowProps {
    admin?: boolean;
    own?: boolean;
    email: EmailDTO;
    canDelete: boolean;
    copyable?: boolean;
}

const EmailRow: React.FC<EmailRowProps> = (props: EmailRowProps) => {
    const classes = useStyles();
    const [t] = useTranslation("user");
    const {keycloak, initialized} = useKeycloak();
    const [state, setState] = useState({
        admin: props.admin === true,
        name: "saved",
        action: "none",
        email: props.email,
        verified: props.email.verified,
        code: "",
    });
    //== Effects ================================================================
    if (state.name === "sending") {
        API.post<void>(`/emails/${state.email.id}/send`, {}, createApiConfig(keycloak, initialized))
            .then((res) => {
                setState({...state, name: "saved", action: "send"});
            })
            .catch((err: AxiosError) => {
                setState({...state, name: "failed", action: "send"});
            });
    } else if (state.name === "deleting") {
        API.delete<void>(`/emails/${state.email.id}`, createApiConfig(keycloak, initialized))
            .then(() => {
                setState({...state, name: "deleted", action: "delete"});
            })
            .catch((err: AxiosError) => {
                setState({...state, name: "failed", action: "delete"});
            });
    } else if (state.name === "verifying") {
        const dto: EmailVerificationDTO = {
            code: state.code,
        };
        API.post<EmailDTO>(`/emails/${state.email.id}/verify`, dto, createApiConfig(keycloak, initialized))
            .then((res) => {
                setState({
                    ...state,
                    name: "saved",
                    action: "verify",
                    email: res.data,
                    verified: res.data.verified,
                });
            })
            .catch((err: AxiosError) => {
                setState({...state, name: "failed", action: "verify", code: ""});
            });
    } else if (state.name === "saving" && state.admin) {
        API.put<EmailDTO>(
            `/emails/${state.email.id}`,
            {...state.email, verified: state.verified},
            createApiConfig(keycloak, initialized)
        )
            .then((res) => {
                setState({
                    ...state,
                    name: "saved",
                    action: "edit",
                    email: res.data,
                    verified: res.data.verified,
                });
            })
            .catch((err: AxiosError) => {
                setState({
                    ...state,
                    name: "failed",
                    action: "edit",
                    verified: state.email.verified,
                });
            });
    }
    //== Handlers ===============================================================
    const handleVerifyAdmin = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setState({...state, name: "saving", verified: e.target.checked || false});
    };
    const handleVerifyEmail = (): void => {
        setState({...state, name: "verifying"});
    };
    const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setState({...state, code: e.target.value});
    };
    const handleCancelDelete = (): void => {
        setState({...state, name: "init"});
    };
    const handleConfirmDelete = (): void => {
        setState({...state, name: "deleting"});
    };
    const handleDelete = (): void => {
        setState({
            ...state,
            name: props.canDelete ? (state.email.verified ? "confirmingDelete" : "deleting") : "cannot_delete",
        });
    };
    const handleSend = (): void => {
        setState({...state, name: "sending"});
    };
    //== Render =================================================================
    let notification = null;
    if (state.name === "saved" && state.action !== "none") {
        notification = <SuccessNotification message={t(`emails.notifications.${state.action}_ok`)} />;
    } else if (state.name === "failed") {
        notification = <ErrorNotification message={t(`emails.notifications.${state.action}_fail`)} />;
    } else if (state.name === "deleted") {
        notification = <SuccessNotification message={t(`emails.notifications.delete_ok`)} />;
    } else if (state.name === "cannot_delete") {
        notification = <ErrorNotification message={t(`emails.notifications.cannot_delete`)} />;
    }
    let verification = null;
    if (state.admin) {
        verification = <Checkbox id="newVerified" checked={state.verified} onChange={handleVerifyAdmin} />;
    } else if (props.own) {
        verification = state.verified ? (
            <VerifiedUserIcon />
        ) : (
            <React.Fragment>
                <TextField
                    label={t("emails.verification_code")}
                    value={state.code}
                    onChange={handleCodeChange}
                    style={{minWidth: "20em"}}
                />
                <CustomActionButton
                    title={t("emails.verify")}
                    onClick={handleVerifyEmail}
                    icon={<VerifiedUserIcon />}
                />
            </React.Fragment>
        );
    }
    const actions: JSX.Element[] = [];
    if (!state.email.verified && !state.admin && props.own) {
        if (state.name === "sending") {
            actions.push(<LoadingActionButton title={t("emails.sending_email")} key="sending_email" />);
        } else {
            actions.push(
                <CustomActionButton
                    title={t("emails.send_email")}
                    onClick={handleSend}
                    icon={<EmailIcon />}
                    key="send_email"
                />
            );
        }
    }
    if ((props.own && props.canDelete) || state.admin) {
        actions.push(<DeleteActionButton onClick={handleDelete} key="delete" />);
    }
    if (state.name === "deleted") {
        return (
            <TableRow>
                <TableCell className={classes.strikeThrough}>{props.email.email}</TableCell>
                <TableCell />
                <TableCell align="right">{notification}</TableCell>
            </TableRow>
        );
    }
    // - default
    return (
        <TableRow>
            <TableCell>
                {props.email.email}
                {props.copyable && <CopyButton text={props.email.email} size="small" />}
            </TableCell>
            {props.own || state.admin ? (
                <>
                    <TableCell align="center">{verification}</TableCell>
                    <TableCell align="right">
                        {actions}
                        <DeleteConfirmationDialog
                            title={t("ui.emails.deleteVerifiedConfirmDialog.title")}
                            text={t("ui.emails.deleteVerifiedConfirmDialog.text", {email: state.email})}
                            open={state.name === "confirmingDelete"}
                            id={"delete-dialog-" + props.email.id}
                            onDelete={handleConfirmDelete}
                            onCancel={handleCancelDelete}
                        />
                        {notification}
                    </TableCell>
                </>
            ) : null}
        </TableRow>
    );
};

interface PersonEmailsProps {
    admin?: boolean;
    own?: boolean;
    personUuid: string | null;
    emails: EmailDTO[];
}

const PersonEmails: React.FC<PersonEmailsProps> = (props: PersonEmailsProps) => {
    // TODO: indicate progress when working
    //== Init ===================================================================
    const [t] = useTranslation("user");
    const {keycloak, initialized} = useKeycloak();
    const initState = {
        newEmail: "",
        personUuid: props.personUuid,
        emails: props.emails,
        name: "saved",
        action: "none",
        collapsed: true,
    };
    const [state, setState] = React.useState(initState);
    //== Effects ================================================================
    useEffect(() => {
        if (state.name === "saving") {
            API.post<EmailDTO>(
                `/emails`,
                {email: state.newEmail, personUuid: state.personUuid},
                createApiConfig(keycloak, initialized)
            )
                .then((res) => {
                    const emails = state.emails;
                    emails.push(res.data);
                    setState({
                        ...state,
                        name: "saved",
                        action: "create",
                        newEmail: "",
                        emails: emails,
                        collapsed: true,
                    });
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed", action: "create"});
                });
        }
    }, [keycloak, initialized, state, setState, props]);
    //== Handlers ===============================================================
    const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setState({...state, newEmail: e.target.value});
    };
    const handleCreate = (): void => {
        setState({...state, name: "saving"});
    };
    const handleCreateToggle = (): void => {
        setState({...state, collapsed: false});
    };
    //== Render =================================================================
    let notification = null;
    if (state.name === "saved" && state.action !== "none") {
        notification = <SuccessNotification message={t(`emails.notifications.${state.action}_ok`)} />;
    } else if (state.name === "failed") {
        notification = <ErrorNotification message={t(`emails.notifications.${state.action}_fail`)} />;
    }
    const rows = state.emails.map((email: EmailDTO) => (
        <EmailRow
            key={email.email}
            email={email}
            admin={props.admin}
            own={props.own}
            canDelete={state.emails.length > 1}
        />
    ));
    const someUnverified = state.emails.filter((email: EmailDTO): boolean => !email.verified).length > 0;
    return (
        <Box>
            {!props.admin && props.own && someUnverified && (
                <Alert severity="warning">{t("profile.some_unverified")}</Alert>
            )}
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t("emails.email")}</TableCell>
                        {props.admin || props.own ? (
                            <>
                                <TableCell align="center">{t("emails.verification")}</TableCell>
                                <TableCell />
                            </>
                        ) : null}
                    </TableRow>
                </TableHead>
                <TableBody>{rows}</TableBody>
                {props.admin || props.own ? (
                    <TableFooter>
                        {state.collapsed ? (
                            <TableRow>
                                <TableCell colSpan={3} style={{borderBottom: "0"}}>
                                    <Button onClick={handleCreateToggle} startIcon={<AddCircleOutlineIcon />}>
                                        {t("emails.new_email")}
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ) : (
                            <TableRow>
                                <TableCell colSpan={2}>
                                    <TextField
                                        label={t("emails.new_email")}
                                        value={state.newEmail}
                                        fullWidth
                                        type="email"
                                        variant="outlined"
                                        onChange={handleChangeEmail}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <CreateActionButton onClick={handleCreate} disabled={state.newEmail === ""} />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableFooter>
                ) : null}
            </Table>
            {notification}
        </Box>
    );
};

export default PersonEmails;
