import {TagDTO} from "./admin";
import {OrganizationDTO} from "./organization";
import {ValueDTO, ValueUpdateDTO} from "./values";

export interface LibraryCreateDTO {
    name: string;
    version: string;
    license: string | null;
    description: string | null;
    organizationUUID: string;
    tags: TagDTO[];
}

export interface LibraryManageDTO {
    public: boolean;
    shared: boolean;
    organizationUUID: string;
}

export const emptyLibraryCreateDTO: LibraryCreateDTO = {
    name: "",
    version: "",
    license: null,
    description: null,
    organizationUUID: "",
    tags: [],
};

export function toLibraryCreateDTO(library: LibraryDTO): LibraryCreateDTO {
    return {
        name: library.name,
        version: library.version,
        license: library.license,
        description: library.description,
        organizationUUID: library.organization.id ?? "",
        tags: library.tags,
    };
}

export interface LibraryDTO {
    id: string;
    createdAt: string;
    updatedAt: string;
    name: string;
    version: string;
    license: string | null;
    description: string | null;
    public: boolean;
    publicId: boolean;
    shared: boolean;
    organization: OrganizationDTO;
    tags: TagDTO[];
    libraryDomainUses: LibraryDomainUseDTO[];
}

export interface LibrarySimpleDTO {
    id: string;
    name: string;
    version: string;
    license: string | null;
    description: string | null;
    public: boolean;
    publicId: boolean;
    shared: boolean;
    organization: OrganizationDTO;
    tags: TagDTO[];
}

export interface LibraryDomainUseDTO {
    id: string;
    createdAt: string;
    updatedAt: string;
    orderNumber: number;
    domain: DomainDTO;
    libraryGoalUses: LibraryGoalUseDTO[];
}

export interface LibraryGoalUseDTO {
    id: string;
    createdAt: string;
    updatedAt: string;
    orderNumber: number;
    goal: GoalDTO;
    libraryIndicatorUses: LibraryIndicatorUseDTO[];
}

export interface LibraryIndicatorUseDTO {
    id: string;
    createdAt: string;
    updatedAt: string;
    orderNumber: number;
    indicator: IndicatorDTO;
}

export interface DomainCreateDTO {
    name: string;
    description: string;
    libraryUUID: string;
    tags: TagDTO[];
    orderNumber: number;
}

export const emptyDomainCreateDTO: DomainCreateDTO = {
    name: "",
    description: "",
    libraryUUID: "",
    tags: [],
    orderNumber: 0,
};

export interface DomainDTO {
    id: string;
    createdAt: string;
    updatedAt: string;
    name: string;
    description: string;
    libraryUUID: string;
    tags: TagDTO[];
    libraryDomainUses: string[];
    trackedDomains: string[];
}

export interface LibraryDomainUseCreateDTO {
    domainUUID: string;
    libraryUUID: string;
    orderNumber: number;
}

export interface GoalCreateDTO {
    name: string;
    description: string;
    methodicalInstruction: string;
    libraryUUID: string;
    libraryDomainUseUUID: string;
    tags: TagDTO[];
    orderNumber: number;
}

export const emptyGoalCreateDTO: GoalCreateDTO = {
    name: "",
    description: "",
    methodicalInstruction: "",
    libraryUUID: "",
    libraryDomainUseUUID: "",
    tags: [],
    orderNumber: 0,
};

export interface GoalDTO {
    id: string;
    createdAt: string;
    updatedAt: string;
    name: string;
    description: string;
    methodicalInstruction: string;
    libraryUUID: string;
    domainUUID: string;
    tags: TagDTO[];
    libraryGoalUses: string[];
    trackedGoals: string[];
    projectGoalUses: string[];
}

export interface LibraryGoalUseCreateDTO {
    goalUUID: string;
    libraryDomainUseUUID: string;
    orderNumber: number;
}

export type ImprovementDirection = "undecidable" | "increasing" | "decreasing";

export interface IndicatorCreateDTO {
    name: string;
    definition: string;
    description: string;
    type: VariableType;
    direction: ImprovementDirection;
    preferredUnitUUID: string | null;
    presentationRecommendation: string;
    processMethod: string;
    validityComment: string;
    formula: string;
    libraryUUID: string;
    libraryGoalUseUUID: string;
    tags: TagDTO[];
    usedVariables: string[];
    maximumValueUpdate: ValueUpdateDTO | null;
    minimumValueUpdate: ValueUpdateDTO | null;
    orderNumber: number;
}

export const emptyIndicatorCreateDTO: IndicatorCreateDTO = {
    name: "",
    definition: "",
    description: "",
    type: "qualitative",
    direction: "undecidable",
    preferredUnitUUID: null,
    presentationRecommendation: "",
    processMethod: "",
    validityComment: "",
    formula: "MUL(CONST(0),CONST(100))",
    libraryUUID: "",
    libraryGoalUseUUID: "",
    tags: [],
    usedVariables: [],
    maximumValueUpdate: null,
    minimumValueUpdate: null,
    orderNumber: 0,
};

export interface VariableSimpleDTO {
    id: string;
    name: string;
    type: VariableType;
    description: string;
    definition: string;
}

export function inputVariableToSimple(inputVariable: InputVariableDTO): VariableSimpleDTO {
    return {
        id: inputVariable.variableUUID,
        name: inputVariable.name,
        type: inputVariable.type,
        description: inputVariable.description,
        definition: inputVariable.definition,
    };
}

export interface IndicatorDTO {
    id: string;
    createdAt: string;
    updatedAt: string;
    name: string;
    variableUUID: string;
    definition: string;
    description: string;
    type: VariableType;
    direction: ImprovementDirection;
    preferredUnit: UnitDTO | null;
    presentationRecommendation: string;
    processMethod: string;
    validityComment: string;
    formula: string;
    libraryUUID: string;
    options: OptionDTO[];
    tags: TagDTO[];
    periods: PeriodDTO[];
    maximumValue: ValueDTO | null;
    minimumValue: ValueDTO | null;
    usedVariables: VariableSimpleDTO[];
    libraryGoalUses: string[];
    trackedGoals: string[];
    projectGoalUses: string[];
}

export interface LibraryIndicatorUseCreateDTO {
    indicatorUUID: string;
    libraryGoalUseUUID: string;
    orderNumber: number;
}

export interface LibraryPartsSelectionDTO {
    libraryUUID: string;
    selectedDomains: SelectedDomainDTO[];
}

export interface SelectedDomainDTO {
    domainUUID: string;
    selectedGoals: SelectedGoalDTO[];
}

export interface SelectedGoalDTO {
    goalUUID: string;
    selectedIndicators: SelectedIndicatorDTO[];
}

export interface SelectedIndicatorDTO {
    indicatorUUID: string;
}

//== DEFINITIONS
export type VariableType = "qualitative" | "quantitative";

export interface InputVariableDTO {
    id: string;
    variableUUID: string;
    name: string;
    type: VariableType;
    description: string;
    definition: string;
    preferredUnit: UnitDTO | null;
    maximumValue: ValueDTO | null;
    minimumValue: ValueDTO | null;
    periods: PeriodDTO[];
    options: OptionDTO[];
    tags: TagDTO[];
}

export interface InputVariableCreateDTO {
    name: string;
    type: VariableType;
    description: string;
    definition: string;
    preferredUnitUUID: string | null;
    maximumValueUpdate: ValueUpdateDTO | null;
    minimumValueUpdate: ValueUpdateDTO | null;
    tags: TagDTO[];
}

export interface UnitSimpleDTO {
    id: string;
    abbreviation: string;
    name: string;
}

export interface UnitDTO extends UnitSimpleDTO {
    description: string;
    formulaToBase: string;
    formulaFromBase: string;
    baseUnit: UnitDTO | null;
    alternativeUnits: UnitDTO[] | null;
}

export interface UnitCreateDTO {
    name: string;
    abbreviation: string;
    description: string;
    formulaToBase: string;
    formulaFromBase: string;
    baseUnitUUID: string | null;
}

export interface OptionDTO {
    id: string;
    name: string;
    description: string;
    value: ValueDTO;
    variableUUID: string;
}

export interface OptionCreateDTO {
    name: string;
    description: string;
    value: number;
    variableUUID: string;
}

export interface DataSourceCreateDTO {
    name: string;
    description: string;
}

export interface DataSourceDTO {
    id: string;
    name: string;
    description: string;
}

export interface DataAcquisitionCreateDTO {
    dataSourceUUID: string;
    inputVariableUUID: string;
    description: string;
}

export interface DataAcquisitionDTO {
    id: string;
    dataSource: DataSourceDTO;
    inputVariableUUID: string;
    description: string;
}

export interface PeriodCreateDTO {
    referenceDate: string;
    repeatDay: number;
    repeatMonth: number;
    repeatYear: number;
    variableUUID: string | null;
}

export interface PeriodDTO {
    id: string;
    referenceDate: string;
    repeatDay: number;
    repeatMonth: number;
    repeatYear: number;
    variableUUID: string | null;
}

type LibraryUse = LibraryDomainUseDTO | LibraryGoalUseDTO | LibraryIndicatorUseDTO;

export function compareLibraryUseAsc(a: LibraryUse, b: LibraryUse): number {
    if (a.orderNumber < b.orderNumber) {
        return -1;
    }
    if (b.orderNumber < a.orderNumber) {
        return 1;
    }
    return 0;
}

export function compareLibraryUseDesc(a: LibraryUse, b: LibraryUse): number {
    if (a.orderNumber < b.orderNumber) {
        return 1;
    }
    if (b.orderNumber < a.orderNumber) {
        return -1;
    }
    return 0;
}

export function nextOrderNumber(uses: LibraryUse[]): number {
    if (uses.length === 0) {
        return 0;
    }
    return Math.max(...uses.map((u) => u.orderNumber).values()) + 1;
}
