import React from "react";
import {useTranslation} from "react-i18next";
// Material UI imports
import {Box, Checkbox, FormControlLabel} from "@material-ui/core";
// Project imports
import {DeleteActionButton, InfoActionButton, SwapVerticallyActionButton} from "../../../common/buttons";
import {TrackedIndicatorInGroupDTO} from "../../../../models/trackedIndicators";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {IndicatorDialog} from "../../../values/dialogs/IndicatorDialog";
import {DeleteConfirmationDialog} from "../../../common/dialogs";
import ItemPositionInLibrary, {
    GoalPositionInLibrary,
    IndicatorPositionInLibrary,
} from "../../../library/editor/util/LibraryItemPosition";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        indicatorRow: {
            borderWidth: 0,
            borderStyle: "solid",
            borderBottomWidth: 1,
            borderColor: "rgb(224,224,224)",
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(0.5),
            marginLeft: theme.spacing(4),
            paddingLeft: theme.spacing(1),
            display: "flex",
        },
        dragHandle: {},
        indicatorItemName: {
            fontSize: "110%",
            marginTop: theme.spacing(1.5),
        },
        infoButton: {
            flexGrow: 1,
        },
        publicCheckbox: {
            marginTop: theme.spacing(0.4),
        },
    })
);

interface TrackedIndicatorCommonProps {
    trackedIndicator: TrackedIndicatorInGroupDTO;
    itemPosition: IndicatorPositionInLibrary;
    shiftingItemPosition: ItemPositionInLibrary | null;

    handleShiftStart(itemPosition: IndicatorPositionInLibrary): void;

    handleRemoveItem(itemPosition: IndicatorPositionInLibrary): void;
}

interface TrackedIndicatorRowProps extends TrackedIndicatorCommonProps {
    handlePublicSwitch(): void;
}

interface TrackedIndicatorProps extends TrackedIndicatorCommonProps {
    handleShiftEnd(position: GoalPositionInLibrary): void;

    handlePublicSwitch(modifiedItem: TrackedIndicatorInGroupDTO): void;
}

const TrackedIndicatorRow: React.FC<TrackedIndicatorRowProps> = (props: TrackedIndicatorRowProps) => {
    //== Init ===================================================================
    const classes = useStyles();
    const [t] = useTranslation("trackedIndicators");
    const [state, setState] = React.useState({deleteConfirmationOpen: false, infoOpen: false});

    //== Handlers ===============================================================
    const handleInfoOpen = (): void => {
        setState({...state, infoOpen: true});
    };
    const handleInfoClose = (): void => {
        setState({...state, infoOpen: false});
    };
    const handleCancelDelete = (): void => {
        setState({...state, deleteConfirmationOpen: false});
    };
    const handleConfirmDelete = (): void => {
        props.handleRemoveItem(props.itemPosition);
        setState({...state, deleteConfirmationOpen: false, infoOpen: false});
    };
    const handleDelete = (): void => {
        if (!props.trackedIndicator.targetValue) return handleConfirmDelete();
        setState({...state, deleteConfirmationOpen: true, infoOpen: false});
    };
    const isShiftingOtherThanThisOne =
        props.shiftingItemPosition &&
        (props.shiftingItemPosition.domainIndex !== props.itemPosition.domainIndex ||
            props.shiftingItemPosition.goalIndex !== props.itemPosition.goalIndex ||
            props.shiftingItemPosition.indicatorIndex !== props.itemPosition.indicatorIndex);
    return (
        <>
            <div className={classes.indicatorRow}>
                <div className={classes.dragHandle} style={isShiftingOtherThanThisOne ? {visibility: "hidden"} : {}}>
                    <SwapVerticallyActionButton onClick={() => props.handleShiftStart(props.itemPosition)} />
                </div>
                <div className={classes.indicatorItemName}>
                    {t("library.indicator") +
                        " " +
                        (props.itemPosition.domainIndex + 1) +
                        "." +
                        (props.itemPosition.goalIndex + 1) +
                        "." +
                        (props.itemPosition.indicatorIndex + 1) +
                        ": " +
                        props.trackedIndicator.indicator.name}
                </div>
                <Box className={classes.infoButton}>
                    <InfoActionButton onClick={handleInfoOpen} />
                </Box>
                <div className={classes.publicCheckbox}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={props.trackedIndicator.public}
                                onChange={(e): void => props.handlePublicSwitch()}
                                name="public"
                            />
                        }
                        label={t("use.public")}
                    />
                </div>
                <div>
                    <DeleteActionButton onClick={handleDelete} />
                    <DeleteConfirmationDialog
                        title={t("ui.editor.deleteIndicatorWithTargetDialog.title")}
                        text={t("ui.editor.deleteIndicatorWithTargetDialog.text", {
                            name: props.trackedIndicator.indicator.name,
                        })}
                        open={state.deleteConfirmationOpen}
                        id={"delete-dialog-" + props.trackedIndicator.id}
                        onDelete={handleConfirmDelete}
                        onCancel={handleCancelDelete}
                    />
                </div>
            </div>
            <IndicatorDialog
                indicator={props.trackedIndicator.indicator}
                handleClose={handleInfoClose}
                open={state.infoOpen}
            />
        </>
    );
};

const TrackedIndicator: React.FC<TrackedIndicatorProps> = (props: TrackedIndicatorProps) => {
    const handlePublicSwitch = () => {
        props.handlePublicSwitch({...props.trackedIndicator, public: !props.trackedIndicator.public});
    };
    return (
        <TrackedIndicatorRow
            trackedIndicator={props.trackedIndicator}
            itemPosition={props.itemPosition}
            shiftingItemPosition={props.shiftingItemPosition}
            handleShiftStart={props.handleShiftStart}
            handleRemoveItem={props.handleRemoveItem}
            handlePublicSwitch={handlePublicSwitch}
        />
    );
};

export default TrackedIndicator;
