import React from "react";
import {Box} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {InfoActionButton} from "../../../../common/buttons";
import {DomainDialog} from "../../../../values/dialogs/DomainDialog";
import {DashboardIndicatorsAddingDomain, DashboardIndicatorsAddingGoal} from "./DashboardIndicatorsAddingInterfaces";
import DashboardIndicatorsGoalAdd from "./DashboardIndicatorsGoalAdd";
import {useTranslation} from "react-i18next";
import {TrackedIndicatorInGroupDTO} from "../../../../../models/trackedIndicators";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        row: {
            borderWidth: 0,
            borderStyle: "solid",
            borderBottomWidth: 2,
            borderColor: "rgb(0,125,180)",
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(0.5),
            marginLeft: theme.spacing(2),
            paddingLeft: theme.spacing(1),
            display: "flex",
        },
        alreadyAdded: {
            backgroundColor: "rgb(215,250,215)",
        },
        selectionCheckbox: {},
        itemName: {
            fontSize: "140%",
            fontWeight: "bold",
            marginTop: theme.spacing(1.5),
        },
        infoButton: {
            flexGrow: 1,
        },
    })
);

interface DashboardIndicatorsDomainAddProps {
    index: number; //TODO temporary solution until libraryXXXUse are not added to LibraryDTO,
    trackedDomain: DashboardIndicatorsAddingDomain;

    handleChecked(trackedIndicator: TrackedIndicatorInGroupDTO): void;

    handleUnchecked(trackedIndicator: TrackedIndicatorInGroupDTO): void;
}

const DashboardIndicatorsDomainAdd: React.FC<DashboardIndicatorsDomainAddProps> = (
    props: DashboardIndicatorsDomainAddProps
) => {
    const classes = useStyles();
    const [t] = useTranslation("trackedIndicators");
    const [state, setState] = React.useState({infoOpen: false});
    //== Handlers ===============================================================
    const handleInfoOpen = (): void => {
        setState({...state, infoOpen: true});
    };
    const handleInfoClose = (): void => {
        setState({...state, infoOpen: false});
    };
    return (
        <Box>
            <Box className={classes.row}>
                <Box className={classes.itemName}>{t("library.domain") + ": " + props.trackedDomain.domain.name}</Box>
                <Box className={classes.infoButton}>
                    <InfoActionButton onClick={handleInfoOpen} />
                </Box>
            </Box>
            <DomainDialog domain={props.trackedDomain.domain} handleClose={handleInfoClose} open={state.infoOpen} />
            <Box>
                {props.trackedDomain.trackedGoals.map((goal: DashboardIndicatorsAddingGoal, index) => (
                    <DashboardIndicatorsGoalAdd
                        key={goal.id}
                        index={index}
                        trackedGoal={goal}
                        handleChecked={props.handleChecked}
                        handleUnchecked={props.handleUnchecked}
                    />
                ))}
            </Box>
        </Box>
    );
};

export default DashboardIndicatorsDomainAdd;
