import React from "react";
import {VariableType} from "../../models/library";
import {Box, FormControl, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

interface VariableTypeInputProps {
    value: VariableType;
    disabled?: boolean;
    onChange: (value: VariableType) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        radioGroup: {
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            marginBottom: theme.spacing(1),
        },
        label: {
            marginRight: theme.spacing(3),
        },
    })
);

export const VariableTypeInput: React.FC<VariableTypeInputProps> = (props: VariableTypeInputProps) => {
    //== Init =================================================================
    const [t] = useTranslation("libraries");
    const classes = useStyles();
    //== Handlers =============================================================
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(event.target.value as VariableType);
    };
    //== Render ===============================================================
    return (
        <FormControl component="fieldset">
            <RadioGroup value={props.value} onChange={handleChange} className={classes.radioGroup}>
                <Box className={classes.label}>{t("definitions.variable_type.label")}</Box>
                <FormControlLabel
                    disabled={props.disabled}
                    value="quantitative"
                    control={<Radio />}
                    label={t("definitions.variable_type.quantitative")}
                />
                <FormControlLabel
                    disabled={props.disabled}
                    value="qualitative"
                    control={<Radio />}
                    label={t("definitions.variable_type.qualitative")}
                />
            </RadioGroup>
        </FormControl>
    );
};
