import React from "react";
import {Box, Checkbox, FormControlLabel} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {InfoActionButton} from "../../../../common/buttons";
import {DomainDialog} from "../../../../values/dialogs/DomainDialog";
import {
    TrackedIndicatorGroupEditorAddingDomain,
    TrackedIndicatorGroupEditorAddingGoal,
} from "./TrackedIndicatorGroupEditorAddingInterfaces";
import TrackedIndicatorGroupEditorGoalAdd, {propagateAddingGoalCheck} from "./TrackedIndicatorGroupEditorGoalAdd";
import classNames from "classnames";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        row: {
            borderWidth: 0,
            borderStyle: "solid",
            borderBottomWidth: 2,
            borderColor: "rgb(0,125,180)",
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(0.5),
            marginLeft: theme.spacing(0),
            paddingLeft: theme.spacing(1),
            display: "flex",
        },
        alreadyAdded: {
            backgroundColor: "rgb(215,250,215)",
        },
        selectionCheckbox: {},
        itemName: {
            fontSize: "140%",
            fontWeight: "bold",
            marginTop: theme.spacing(1.5),
        },
        infoButton: {
            flexGrow: 1,
        },
    })
);

interface TrackedIndicatorGroupEditorDomainAddProps {
    index: number; //TODO temporary solution until libraryXXXUse are not added to LibraryDTO,
    domain: TrackedIndicatorGroupEditorAddingDomain;

    handleCheckChanged(addingDomain: TrackedIndicatorGroupEditorAddingDomain): void;
}

export const propagateAddingDomainCheck = (
    addingDomain: TrackedIndicatorGroupEditorAddingDomain
): TrackedIndicatorGroupEditorAddingDomain => {
    return {
        ...addingDomain,
        goals: addingDomain.goals.map((checkedGoal) =>
            checkedGoal.checked === addingDomain.checked
                ? checkedGoal
                : propagateAddingGoalCheck({
                      ...checkedGoal,
                      checked: addingDomain.checked,
                  })
        ),
    };
};

const TrackedIndicatorGroupEditorDomainAdd: React.FC<TrackedIndicatorGroupEditorDomainAddProps> = (
    props: TrackedIndicatorGroupEditorDomainAddProps
) => {
    const classes = useStyles();
    const [t] = useTranslation("trackedIndicators");
    const [state, setState] = React.useState({infoOpen: false});
    //== Handlers ===============================================================
    const handleInfoOpen = (): void => {
        setState({...state, infoOpen: true});
    };
    const handleInfoClose = (): void => {
        setState({...state, infoOpen: false});
    };
    const handleCheckChanged = (checked: boolean) =>
        props.handleCheckChanged(propagateAddingDomainCheck({...props.domain, checked}));
    const handleContentCheckChanged = (addingGoal: TrackedIndicatorGroupEditorAddingGoal) => {
        props.handleCheckChanged({
            ...props.domain,
            checked: props.domain.checked || addingGoal.checked,
            goals: props.domain.goals.map((eachAddingGoal) =>
                eachAddingGoal.id === addingGoal.id ? addingGoal : eachAddingGoal
            ),
        });
    };
    return (
        <Box>
            <Box className={classNames({[classes.row]: true, [classes.alreadyAdded]: props.domain.alreadyAdded})}>
                <Box className={classes.selectionCheckbox}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={props.domain.checked}
                                disabled={props.domain.alreadyAdded}
                                onChange={(e): void => handleCheckChanged(e.target.checked)}
                                name="selected"
                            />
                        }
                        label=""
                    />
                </Box>
                <Box className={classes.itemName}>{t("library.domain") + ": " + props.domain.name}</Box>
                <Box className={classes.infoButton}>
                    <InfoActionButton onClick={handleInfoOpen} />
                </Box>
            </Box>
            <DomainDialog domain={props.domain} handleClose={handleInfoClose} open={state.infoOpen} />
            <Box>
                {props.domain.goals.map((goal: TrackedIndicatorGroupEditorAddingGoal, index) => (
                    <TrackedIndicatorGroupEditorGoalAdd
                        key={goal.id}
                        index={index}
                        goal={goal}
                        handleCheckChanged={handleContentCheckChanged}
                    />
                ))}
            </Box>
        </Box>
    );
};

export default TrackedIndicatorGroupEditorDomainAdd;
