import React, {PropsWithChildren} from "react";
import {Box, Fab, Tooltip} from "@material-ui/core";
import {Link} from "react-router-dom";

interface PageHeaderProps {
    title: string;
}

export const PageHeader: React.FC<PropsWithChildren<PageHeaderProps>> = (props: PropsWithChildren<PageHeaderProps>) => {
    return (
        <Box display="flex">
            <Box flexGrow={1}>
                <h1>{props.title}</h1>
            </Box>
            <Box display="flex" alignItems="center">
                {props.children}
            </Box>
        </Box>
    );
};

interface PageActionProps {
    title: string;
    last?: boolean;
    disabled?: boolean;
    color?: "primary" | "secondary" | "inherit" | "default";
    size?: "small" | "medium" | "large";
    to?: string;
    onClick?: () => void;
    style?: React.CSSProperties;
    children: NonNullable<React.ReactNode>;
    external?: boolean;
}

export const PageAction: React.FC<PageActionProps> = (props: PageActionProps) => {
    const style = {...props.style, marginRight: props.last ? "0" : "1em"};
    let button = null;
    if (props.to === undefined) {
        button = (
            <Fab color={props.color} size={props.size} onClick={props.onClick} style={style} disabled={props.disabled}>
                {props.children}
            </Fab>
        );
    } else if (props.external) {
        button = (
            <Fab color={props.color} size={props.size} href={props.to} style={style} disabled={props.disabled}>
                {props.children}
            </Fab>
        );
    } else {
        button = (
            <Link to={props.to}>
                <Fab color={props.color} size={props.size} style={style} disabled={props.disabled}>
                    {props.children}
                </Fab>
            </Link>
        );
    }
    if (props.disabled) {
        return button;
    }
    return <Tooltip title={props.title}>{button}</Tooltip>;
};

interface PrimaryPageActionProps {
    title: string;
    disabled?: boolean;
    to?: string;
    onClick?: () => void;
    style?: React.CSSProperties;
    children: NonNullable<React.ReactNode>;
    external?: boolean;
}

export const PrimaryPageAction: React.FC<PrimaryPageActionProps> = (props: PrimaryPageActionProps) => {
    return <PageAction color="primary" size="large" last {...props} />;
};

interface SecondaryPageActionProps {
    title: string;
    disabled?: boolean;
    to?: string;
    onClick?: () => void;
    style?: React.CSSProperties;
    children: NonNullable<React.ReactNode>;
    external?: boolean;
}

export const SecondaryPageAction: React.FC<SecondaryPageActionProps> = (props: SecondaryPageActionProps) => {
    return <PageAction color="default" size="medium" {...props} />;
};
