import React from "react";
import {
    emptyIndicatorCreateDTO,
    IndicatorCreateDTO,
    LibraryDTO,
    LibraryGoalUseDTO,
    nextOrderNumber,
    VariableSimpleDTO,
} from "../../../../models/library";
import {useKeycloak} from "@react-keycloak/web";
import {API, createApiConfig} from "../../../../utils/API";
import {AxiosError} from "axios";
import {Box, Button} from "@material-ui/core";
import {FormDialog} from "../forms/FormDialog";
import AddIcon from "@material-ui/icons/Add";
import {useTranslation} from "react-i18next";
import {IndicatorForm} from "../forms/IndicatorForm";

interface LibraryIndicatorCreateRowProps {
    library: LibraryDTO;
    libraryGoalUse: LibraryGoalUseDTO;
    onReload: (cause: string) => void;
    onUpdate: (library: LibraryDTO) => void;
}

interface LibraryIndicatorCreateRowState {
    name: "loaded" | "saving" | "failed";
    opened: boolean;
    newIndicator: IndicatorCreateDTO;
    usedVariables: VariableSimpleDTO[];
}

export const LibraryIndicatorCreateRow: React.FC<LibraryIndicatorCreateRowProps> = (
    props: LibraryIndicatorCreateRowProps
) => {
    // TODO: improve style when nested library
    //== Init =================================================================
    const [t] = useTranslation("libraries");
    const {keycloak, initialized} = useKeycloak();
    const nextOrder = nextOrderNumber(props.libraryGoalUse.libraryIndicatorUses);
    const emptyIndicator: IndicatorCreateDTO = {
        ...emptyIndicatorCreateDTO,
        libraryUUID: props.library.id,
        libraryGoalUseUUID: props.libraryGoalUse.id,
        orderNumber: nextOrder,
    };
    const initState: LibraryIndicatorCreateRowState = {
        name: "loaded",
        opened: false,
        newIndicator: emptyIndicator,
        usedVariables: [],
    };
    const [state, setState] = React.useState(initState);
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "saving") {
            API.post<LibraryDTO>(`/indicators/in-library`, state.newIndicator, createApiConfig(keycloak, initialized))
                .then((res) => {
                    setState({...state, name: "loaded", newIndicator: emptyIndicator, opened: false});
                    props.onUpdate(res.data);
                })
                .catch((err: AxiosError) => {
                    console.log(err);
                    setState({...state, name: "failed"});
                });
        }
    }, [state, setState]);
    //== Handlers =============================================================
    const handleNewChange = (indicator: IndicatorCreateDTO, usedVariables: VariableSimpleDTO[]): void => {
        setState({...state, newIndicator: indicator, usedVariables: usedVariables});
    };
    const handleNewClose = (): void => {
        setState({...state, opened: false});
    };
    const handleNewClick = (): void => {
        setState({...state, opened: true});
    };
    const handleNewSubmit = (): void => {
        setState({...state, name: "saving", opened: true});
    };
    //== Render ===============================================================
    return (
        <Box display="flex" justifyContent="flex-end" style={{padding: "0.4em"}}>
            <Button onClick={handleNewClick} startIcon={<AddIcon />} variant="outlined" color="primary">
                {t("indicator.new.name")}
            </Button>
            <FormDialog
                id={`new-indicator-${props.libraryGoalUse.id}`}
                title={t("indicator.new.name")}
                open={state.opened}
                onClose={handleNewClose}
                actions={[
                    <Button key="submit" onClick={handleNewSubmit}>
                        {t("indicator.new.button")}
                    </Button>,
                ]}
            >
                <IndicatorForm
                    indicator={state.newIndicator}
                    published={false}
                    usedVariables={state.usedVariables}
                    optionsHandle="after_save"
                    onChange={handleNewChange}
                />
            </FormDialog>
        </Box>
    );
};
