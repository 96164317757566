import React from "react";
import {InputVariableDTO} from "../../../../models/library";
import {compareByAttr} from "../../../../utils/compare";
import {Grid, TextField} from "@material-ui/core";
import {TagsInput} from "../../../common/tags";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {TagDTO} from "../../../../models/admin";

export interface InputVariablesFilterContainer {
    name: string;
    tags: TagDTO[];
    tagIds: Set<string>;
}

function matchTags(tags: TagDTO[], tagIds: Set<string>): boolean {
    if (tagIds.size !== 0 && tags.length === 0) {
        return false;
    }
    return tagIds.size === 0 || tags.some((tag) => tagIds.has(tag.id));
}

function matchInputVariable(inputVariable: InputVariableDTO, filter: string, tagIds: Set<string>): boolean {
    return inputVariable.name.toLowerCase().includes(filter) && matchTags(inputVariable.tags, tagIds);
}

export function filterInputVariables(
    vars: InputVariableDTO[],
    filter: InputVariablesFilterContainer
): InputVariableDTO[] {
    const filterName = filter.name.toLowerCase();
    return vars
        .filter((iv) => matchInputVariable(iv, filterName, filter.tagIds))
        .sort(compareByAttr("name", "ascending"));
}

interface InputVariablesFilterProps {
    filter: InputVariablesFilterContainer;
    onChange: (filter: InputVariablesFilterContainer) => void;
    possibleTags?: TagDTO[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        filterRow: {
            marginBottom: theme.spacing(2),
            alignItems: "flex-end",
        },
        filterString: {
            padding: theme.spacing(1),
        },
        filterTags: {
            padding: theme.spacing(1),
        },
    })
);

export const InputVariablesFilter: React.FC<InputVariablesFilterProps> = (props: InputVariablesFilterProps) => {
    //== Init =================================================================
    const classes = useStyles();
    const [t] = useTranslation("values");
    //== Handlers =============================================================
    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange({...props.filter, name: event.target.value});
    };
    const handleTagsChange = (tags: TagDTO[]) => {
        const tagIds = new Set(tags.map((t) => t.id));
        props.onChange({...props.filter, tags: tags, tagIds: tagIds});
    };
    //== Render ===============================================================
    return (
        <Grid container className={classes.filterRow}>
            <Grid item container md={6} sm={12} className={classes.filterString}>
                <TextField
                    label={t("input_variable.filters.byName")}
                    value={props.filter.name}
                    onChange={handleFilterChange}
                    fullWidth
                />
            </Grid>
            <Grid item container md={6} sm={12} className={classes.filterTags}>
                <TagsInput
                    label={t("input_variable.filters.byTags")}
                    value={props.filter.tags}
                    possibleTags={props.possibleTags}
                    onChange={handleTagsChange}
                    fullWidth
                />
            </Grid>
        </Grid>
    );
};
