import React from "react";
import {compareLibraryUseAsc, LibraryDomainUseDTO, LibraryDTO} from "../../../models/library";
import {Box} from "@material-ui/core";
import {LibraryDomainCreateRow} from "./rows/LibraryDomainCreateRow";
import {GoalLevel} from "./GoalLevel";
import {LibraryDomainOwnRow} from "./rows/LibraryDomainOwnRow";
import {LibraryDomainUseRow} from "./rows/LibraryDomainUseRow";
import {API, createApiConfig} from "../../../utils";
import {AxiosError} from "axios";
import {useKeycloak} from "@react-keycloak/web";
import LibraryItemMoveTarget from "./util/LibraryItemMoveTarget";
import ItemPositionInLibrary, {DomainPositionInLibrary} from "./util/LibraryItemPosition";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {LoadingBackdrop} from "../../common/Loading";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        domainShiftTarget: {
            marginLeft: theme.spacing(0),
        },
    })
);

interface LibraryDomainRowProps extends DomainLevelProps {
    libraryDomainUse: LibraryDomainUseDTO;
    shiftingItemPosition: ItemPositionInLibrary | null;
    handleShiftStart: (itemPosition: DomainPositionInLibrary) => void;
    handleShiftEnded: () => void;
}

const LibraryDomainRow: React.FC<LibraryDomainRowProps> = (props: LibraryDomainRowProps) => {
    if (props.library.id === props.libraryDomainUse.domain.libraryUUID) {
        return <LibraryDomainOwnRow {...props} />;
    } else {
        return <LibraryDomainUseRow {...props} />;
    }
};

interface DomainLevelBlockProps extends LibraryDomainRowProps {}

const DomainLevelBlock: React.FC<DomainLevelBlockProps> = (props: DomainLevelBlockProps) => {
    return (
        <div>
            <LibraryDomainRow {...props} />
            <GoalLevel {...props} />
        </div>
    );
};

interface DomainLevelProps {
    library: LibraryDTO;
    onReload: (cause: string) => void;
    onUpdate: (library: LibraryDTO) => void;
}

interface DomainLevelState {
    name: "init" | "failed" | "shifting";
    shiftingItemPosition: ItemPositionInLibrary | null;
    shiftingTargetIndex: number | null;
}

export const DomainLevel: React.FC<DomainLevelProps> = (props: DomainLevelProps) => {
    //== Init =================================================================
    const classes = useStyles();
    const domains = props.library.libraryDomainUses.sort(compareLibraryUseAsc);
    const initState: DomainLevelState = {
        name: "init",
        shiftingItemPosition: null,
        shiftingTargetIndex: null,
    };
    const [state, setState] = React.useState(initState);
    const {keycloak, initialized} = useKeycloak();
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "shifting" && state.shiftingItemPosition !== null) {
            const dto = {
                domainUseUUID: domains[state.shiftingItemPosition.domainIndex].id,
                targetIndex: state.shiftingTargetIndex,
            };
            API.post<LibraryDTO>("/domains/library-use/move", dto, createApiConfig(keycloak, initialized))
                .then((res) => {
                    handleShiftEnded();
                    props.onReload("shift");
                })
                .catch((err: AxiosError) => {
                    console.log(err);
                    setState({...state, shiftingItemPosition: null, shiftingTargetIndex: null, name: "failed"});
                });
        }
    }, [state, setState, domains, props]);
    //== Handlers =============================================================
    const handleShiftStart = (position: ItemPositionInLibrary) => {
        console.log("[DomainLevel] handleShiftStart - position:");
        console.log(position);
        setState({...state, shiftingItemPosition: state.shiftingItemPosition ? null : position});
    };
    const handleShiftEnd = (targetIndex: number) => {
        console.log("[DomainLevel] handleShiftEnd - state.shiftingItemPosition:");
        console.log(state.shiftingItemPosition);
        console.log("[DomainLevel] handleShiftEnd - targetIndex:");
        console.log(targetIndex);
        if (state.shiftingItemPosition === null || state.shiftingItemPosition.goalIndex !== null) {
            return;
        }
        const isShiftingDown = state.shiftingItemPosition.domainIndex < targetIndex;
        setState({
            ...state,
            shiftingTargetIndex: isShiftingDown ? targetIndex - 1 : targetIndex,
            name: "shifting",
        });
    };
    const handleShiftEnded = () => {
        setState({
            ...state,
            shiftingTargetIndex: null,
            shiftingItemPosition: null,
            name: "init",
        });
    };
    const isShiftingDomain = state.shiftingItemPosition !== null && state.shiftingItemPosition.goalIndex === null;
    const libraryPublished = props.library.public || props.library.shared;
    //== Render ===============================================================
    return (
        <Box>
            {domains
                .map((domainUse, index) => {
                    const isShiftingAdjacentDomain =
                        state.shiftingItemPosition &&
                        (state.shiftingItemPosition.domainIndex == index ||
                            state.shiftingItemPosition.domainIndex + 1 == index);

                    return (
                        <Box key={domainUse.id}>
                            <LibraryItemMoveTarget
                                key={domainUse.id + "-move-target"}
                                className={classes.domainShiftTarget}
                                active={isShiftingDomain && !isShiftingAdjacentDomain}
                                onAction={() => handleShiftEnd(index)}
                            />
                            <DomainLevelBlock
                                libraryDomainUse={domainUse}
                                shiftingItemPosition={state.shiftingItemPosition}
                                handleShiftStart={handleShiftStart}
                                handleShiftEnded={handleShiftEnded}
                                {...props}
                            />
                        </Box>
                    );
                })
                .concat([
                    <LibraryItemMoveTarget
                        key={props.library.id + "-end-move-target"}
                        className={classes.domainShiftTarget}
                        active={
                            isShiftingDomain &&
                            !(
                                state.shiftingItemPosition &&
                                state.shiftingItemPosition.domainIndex + 1 == domains.length
                            )
                        }
                        onAction={() => handleShiftEnd(domains.length)}
                    />,
                ])}
            {state.shiftingItemPosition === null && !libraryPublished && <LibraryDomainCreateRow {...props} />}
            {state.name === "shifting" && <LoadingBackdrop />}
        </Box>
    );
};
