import React, {useState} from "react";
import {useTranslation} from "react-i18next";
// Material UI imports
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    Input,
    ListItemText,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
// Project imports
import Utils from "../../../../utils";
import {
    MembershipPermissionLevel,
    MembershipPositionMaintainerDTO,
    MembershipPositionUpdateDTO,
    ProfileMembershipPermissionLevel,
} from "../../../../models/members";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import {TrackedDomainSimpleDTO} from "../../../../models/trackedIndicators";

interface PositionEditDialogProps {
    isMunicipality: boolean;
    position?: MembershipPositionMaintainerDTO;
    trackedDomains: TrackedDomainSimpleDTO[];
    open: boolean;

    onSave(position: MembershipPositionUpdateDTO): void;

    onCancel(): void;
}

const PositionEditDialog: React.FC<PositionEditDialogProps> = (props: PositionEditDialogProps) => {
    //== Init ===================================================================
    const theme = useTheme();
    const [t] = useTranslation(["organizationMembers", "common"]);
    const propsPosition: MembershipPositionUpdateDTO = props.position || {
        name: "",
        description: null,
        profilePermissionLevel: "READONLY",
        librariesPermissionLevel: "NONE",
        trackedIndicatorsPermissionLevel: props.isMunicipality ? "NONE" : null,
        valuesPermissionLevel: props.isMunicipality ? "NONE" : null,
        valueReadWriteTrackedDomains: [],
    };
    const [state, setState] = useState({
        name: "loading" as "loading" | "loaded" | "saving" | "canceling",
        position: propsPosition,
    });

    React.useEffect(() => {
        if (!props.open && state.name !== "loading") setState({...state, name: "loading"});
        else if (props.open && state.name === "loading") {
            setState({...state, name: "loaded", position: propsPosition});
        } else if (state.name === "saving") {
            const positionToSave = {
                ...state.position,
                description: state.position.description ? Utils.emptyToNull(state.position.description) : null,
                valueReadWriteTrackedDomains:
                    state.position.valuesPermissionLevel === "READWRITE" &&
                    state.position.valueReadWriteTrackedDomains.length
                        ? []
                        : state.position.valueReadWriteTrackedDomains,
            };
            props.onSave(positionToSave);
        } else if (state.name === "canceling") {
            props.onCancel();
        }
    }, [props.open, state, setState]);
    const handleSave = (): void => {
        setState({...state, name: "saving"});
    };
    const handleCancel = (): void => {
        setState({...state, name: "canceling"});
    };
    const handleDomainUsesChange = (domainUseIds: string[]): void => {
        const existingDomainUses = state.position.valueReadWriteTrackedDomains.filter(
            (domainUse) => domainUseIds.indexOf(domainUse.id) >= 0
        );
        const newDomainUses: TrackedDomainSimpleDTO[] = domainUseIds
            .filter((id) => existingDomainUses.map((domainUse) => domainUse.id).indexOf(id) < 0)
            .map((id) => {
                const domainUse = props.trackedDomains.find((domainUse) => domainUse.id === id);
                return domainUse === undefined ? ({} as TrackedDomainSimpleDTO) : domainUse;
            });
        setState({
            ...state,
            position: {
                ...state.position,
                valueReadWriteTrackedDomains: existingDomainUses.concat(newDomainUses),
            },
        });
    };
    console.log("[PositionEditDialog] props.trackedDomains:");
    console.log(props.trackedDomains);
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
    //== Render =================================================================
    return (
        <Dialog open={props.open} fullScreen={fullScreen} onClose={handleCancel}>
            <DialogTitle id="responsive-dialog-title">
                {t(props.position ? "ui.positionEdit" : "ui.positionCreation")}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            id="name"
                            label={t("position.name")}
                            value={state.position.name}
                            fullWidth
                            required
                            onChange={(e): void =>
                                setState({
                                    ...state,
                                    position: {
                                        ...state.position,
                                        name: e.target.value,
                                    },
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            multiline={true}
                            id="description"
                            label={t("position.description")}
                            value={state.position.description || ""}
                            fullWidth
                            onChange={(e): void =>
                                setState({
                                    ...state,
                                    position: {
                                        ...state.position,
                                        description: e.target.value,
                                    },
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.position.profilePermissionLevel === "FULL"}
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            position: {
                                                ...state.position,
                                                profilePermissionLevel: e.target.checked ? "FULL" : "READWRITE",
                                                librariesPermissionLevel: "READWRITE",
                                                trackedIndicatorsPermissionLevel: props.isMunicipality
                                                    ? "READWRITE"
                                                    : null,
                                                valuesPermissionLevel: props.isMunicipality ? "READWRITE" : null,
                                            },
                                        });
                                    }}
                                    name="public"
                                />
                            }
                            label={
                                <>
                                    <span>{t("permissionLevels.full")} </span>
                                    <span style={{whiteSpace: "nowrap"}}>{t("ui.includingMembers")}</span>
                                </>
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl
                            required
                            style={{width: "100%"}}
                            disabled={state.position.profilePermissionLevel === "FULL"}
                        >
                            <InputLabel id="profile-permission-level-label">
                                {t("position.profilePermissionLevel")}
                            </InputLabel>
                            <Select
                                multiline={true}
                                id="profile-permission-level"
                                labelId="profile-permission-level-label"
                                value={
                                    state.position.profilePermissionLevel === "FULL"
                                        ? "READWRITE"
                                        : state.position.profilePermissionLevel
                                }
                                fullWidth
                                onChange={(e): void =>
                                    setState({
                                        ...state,
                                        position: {
                                            ...state.position,
                                            profilePermissionLevel: e.target.value as ProfileMembershipPermissionLevel,
                                        },
                                    })
                                }
                            >
                                <MenuItem value={"READONLY"}>{t("permissionLevels.readonly")}</MenuItem>
                                <MenuItem value={"READWRITE"}>{t("permissionLevels.readwrite")}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl
                            required
                            style={{width: "100%"}}
                            disabled={state.position.profilePermissionLevel === "FULL"}
                        >
                            <InputLabel id="libraries-permission-level-label">
                                {t("position.librariesPermissionLevel")}
                            </InputLabel>
                            <Select
                                multiline={true}
                                id="libraries-permission-level"
                                labelId="libraries-permission-level-label"
                                value={state.position.librariesPermissionLevel}
                                fullWidth
                                onChange={(e): void =>
                                    setState({
                                        ...state,
                                        position: {
                                            ...state.position,
                                            librariesPermissionLevel: e.target.value as MembershipPermissionLevel,
                                        },
                                    })
                                }
                            >
                                <MenuItem value={"NONE"}>{t("permissionLevels.none")}</MenuItem>
                                <MenuItem value={"READONLY"}>{t("permissionLevels.readonly")}</MenuItem>
                                <MenuItem value={"READWRITE"}>{t("permissionLevels.readwrite")}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {propsPosition.trackedIndicatorsPermissionLevel && propsPosition.valuesPermissionLevel ? (
                        <>
                            <Grid item xs={12}>
                                <FormControl
                                    required
                                    style={{width: "100%"}}
                                    disabled={state.position.profilePermissionLevel === "FULL"}
                                >
                                    <InputLabel id="tracked-indicators-permission-level-label">
                                        {t("position.trackedIndicatorsPermissionLevel")}
                                    </InputLabel>
                                    <Select
                                        multiline={true}
                                        id="tracked-indicators-permission-level"
                                        labelId="tracked-indicators-permission-level-label"
                                        value={state.position.trackedIndicatorsPermissionLevel}
                                        fullWidth
                                        onChange={(e): void =>
                                            setState({
                                                ...state,
                                                position: {
                                                    ...state.position,
                                                    trackedIndicatorsPermissionLevel: e.target
                                                        .value as MembershipPermissionLevel,
                                                },
                                            })
                                        }
                                    >
                                        <MenuItem value={"NONE"}>{t("permissionLevels.none")}</MenuItem>
                                        <MenuItem value={"READONLY"}>{t("permissionLevels.readonly")}</MenuItem>
                                        <MenuItem value={"READWRITE"}>{t("permissionLevels.readwrite")}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl
                                    required
                                    style={{width: "100%"}}
                                    disabled={state.position.profilePermissionLevel === "FULL"}
                                >
                                    <InputLabel id="values-permission-level-label">
                                        {t("position.valuesPermissionLevel")}
                                    </InputLabel>
                                    <Select
                                        multiline={true}
                                        id="values-permission-level"
                                        labelId="values-permission-level-label"
                                        value={state.position.valuesPermissionLevel}
                                        fullWidth
                                        onChange={(e): void =>
                                            setState({
                                                ...state,
                                                position: {
                                                    ...state.position,
                                                    valuesPermissionLevel: e.target.value as MembershipPermissionLevel,
                                                },
                                            })
                                        }
                                    >
                                        <MenuItem value={"NONE"}>{t("permissionLevels.none")}</MenuItem>
                                        <MenuItem value={"READONLY"}>{t("permissionLevels.readonly")}</MenuItem>
                                        <MenuItem value={"READWRITE"}>{t("permissionLevels.readwrite")}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                {state.position.valuesPermissionLevel === "READWRITE" ||
                                props.trackedDomains.length === 0 ? null : (
                                    <FormControl required style={{width: "100%"}}>
                                        <InputLabel id="domains-select-label">
                                            {t("position.valueReadWriteTrackedDomains")}
                                        </InputLabel>
                                        <Select
                                            labelId="domains-select-label"
                                            id="domains-select"
                                            multiple
                                            value={state.position.valueReadWriteTrackedDomains.map(
                                                (domainUse) => domainUse.id
                                            )}
                                            onChange={(e): void => handleDomainUsesChange(e.target.value as string[])}
                                            input={<Input />}
                                            renderValue={(selected) =>
                                                (selected as string[])
                                                    .map(
                                                        (id) =>
                                                            props.trackedDomains.find(
                                                                (domainUse) => domainUse.id === id
                                                            )?.domainName
                                                    )
                                                    .join(", ")
                                            }
                                        >
                                            {props.trackedDomains.map((possibleDomainUse) => (
                                                <MenuItem key={possibleDomainUse.id} value={possibleDomainUse.id}>
                                                    <Checkbox
                                                        checked={
                                                            state.position.valueReadWriteTrackedDomains
                                                                .map((checkedDomainUse) => checkedDomainUse.id)
                                                                .indexOf(possibleDomainUse.id) > -1
                                                        }
                                                    />
                                                    <ListItemText primary={possibleDomainUse.domainName} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                            </Grid>
                        </>
                    ) : null}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">
                    {t("common:actions.cancel")}
                </Button>
                <Button onClick={handleSave} color="primary">
                    {t("common:actions.save")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PositionEditDialog;
