import React, {ChangeEvent} from "react";
import {CommunicationError, NotAuthorized} from "../common/errors";
import {LibraryDTO, LibraryManageDTO} from "../../models/library";
import {Box, Container, Switch} from "@material-ui/core";
import {BreadcrumbItem, BreadcrumbsRow} from "../common/breadcrumbs";
import ROUTES from "../../routes/routes";
import {LoadingBackdrop} from "../common/Loading";
import {useParams} from "react-router-dom";
import {API, createApiConfig} from "../../utils";
import {AxiosError} from "axios";
import {useKeycloak} from "@react-keycloak/web";
import {PageHeader} from "../common/headers";
import {OrganizationInput} from "../common/organizations";
import {ErrorNotification, SuccessNotification} from "../common/notifications";
import {OrganizationDTO} from "../../models/organization";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";

interface LibraryAdminState {
    library: LibraryDTO | null;
    organization: OrganizationDTO | null;
    manage: LibraryManageDTO;
    name: "loading" | "loaded" | "saving" | "failed" | "not_found" | "unauthorized" | "saved" | "errored";
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        settingRow: {
            borderWidth: 0,
            borderStyle: "solid",
            borderBottomWidth: 2,
            borderColor: theme.palette.primary.main,
            padding: theme.spacing(1),
            display: "flex",
            alignItems: "center",
        },
        settingInput: {
            padding: "1em",
            minWidth: "25em",
            justifyContent: "center",
        },
    })
);

interface LibraryParams {
    libraryId: string;
}

export const LibraryAdmin: React.FC = () => {
    //== Init =================================================================
    const [t] = useTranslation("libraries");
    const classes = useStyles();
    const {libraryId} = useParams<LibraryParams>();
    const {keycloak, initialized} = useKeycloak();
    const initState: LibraryAdminState = {
        library: null,
        organization: null,
        manage: {
            public: false,
            shared: false,
            organizationUUID: "",
        },
        name: "loading",
    };
    const [state, setState] = React.useState(initState);
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<LibraryDTO>(`/libraries/${libraryId}`, createApiConfig(keycloak, initialized))
                .then((res) => {
                    const manage: LibraryManageDTO = {
                        public: res.data.public,
                        shared: res.data.shared,
                        organizationUUID: res.data.organization.id,
                    };
                    setState({
                        ...state,
                        library: res.data,
                        organization: res.data.organization,
                        manage: manage,
                        name: "loaded",
                    });
                })
                .catch((err: AxiosError) => {
                    if (err?.response?.status === 404) {
                        setState({...state, name: "not_found"});
                    } else if (err?.response?.status === 403 || err?.response?.status === 401) {
                        setState({...state, name: "unauthorized"});
                    } else {
                        setState({...state, name: "failed"});
                    }
                });
        } else if (state.name === "saving") {
            API.post<LibraryDTO>(`/libraries/${libraryId}/manage`, state.manage, createApiConfig(keycloak, initialized))
                .then((res) => {
                    const manage: LibraryManageDTO = {
                        public: res.data.public,
                        shared: res.data.shared,
                        organizationUUID: res.data.organization.id,
                    };
                    setState({
                        ...state,
                        library: res.data,
                        organization: res.data.organization,
                        manage: manage,
                        name: "saved",
                    });
                })
                .catch((err: AxiosError) => {
                    if (state.library !== null) {
                        const manage: LibraryManageDTO = {
                            public: state.library.public,
                            shared: state.library.shared,
                            organizationUUID: state.library.organization.id,
                        };
                        setState({...state, manage: manage, name: "errored"});
                    } else {
                        setState({...state, name: "errored"});
                    }
                });
        }
    }, [state, setState, libraryId, keycloak, initialized]);
    //== Handlers =============================================================
    const handlePublicChange = (event: ChangeEvent<HTMLInputElement>) => {
        setState({...state, manage: {...state.manage, public: event.target.checked}, name: "saving"});
    };
    const handleSharedChange = (event: ChangeEvent<HTMLInputElement>) => {
        setState({...state, manage: {...state.manage, shared: event.target.checked}, name: "saving"});
    };
    const handleOrganizationChange = (organization: OrganizationDTO) => {
        setState({...state, manage: {...state.manage, organizationUUID: organization.id}, name: "saving"});
    };
    //== Render ===============================================================
    if (state.name === "loading" || state.library === null) {
        return <LoadingBackdrop />;
    }
    if (state.name === "unauthorized") {
        return <NotAuthorized />;
    }
    if (state.name === "failed") {
        return <CommunicationError />;
    }
    return (
        <Container>
            <BreadcrumbsRow>
                <BreadcrumbItem name={t("library.actions.admin")} />
                <BreadcrumbItem name={t("library.titles.libraries")} route={ROUTES.librariesAdmin.path} />
                <BreadcrumbItem name={state.library.name} />
            </BreadcrumbsRow>
            <PageHeader title={t("library.titles.manage_library", {title: state.library.name})} />
            <Container maxWidth="md">
                <Box className={classes.settingRow}>
                    <Box flexGrow="1">
                        <h2>{t("library.admin.public.title")}</h2>
                        <p>{t("library.admin.public.description")}</p>
                    </Box>
                    <Box className={classes.settingInput}>
                        <Switch
                            checked={state.manage.public}
                            onChange={handlePublicChange}
                            name="libraryPublic"
                            disabled={state.name.endsWith("ing")}
                        />
                    </Box>
                </Box>
                <Box className={classes.settingRow}>
                    <Box flexGrow="1">
                        <h2>{t("library.admin.shared.title")}</h2>
                        <p>{t("library.admin.shared.description")}</p>
                    </Box>
                    <Box className={classes.settingInput}>
                        <Switch
                            checked={state.manage.shared}
                            onChange={handleSharedChange}
                            name="libraryShared"
                            disabled={state.name.endsWith("ing")}
                        />
                    </Box>
                </Box>
                <Box className={classes.settingRow}>
                    <Box flexGrow="1">
                        <h2>{t("library.admin.ownership.title")}</h2>
                        <p>{t("library.admin.ownership.description")}</p>
                    </Box>
                    <Box className={classes.settingInput}>
                        <OrganizationInput
                            query="all"
                            value={state.organization}
                            label={t("library.admin.ownership.owner")}
                            variant="outlined"
                            fullWidth
                            onChange={handleOrganizationChange}
                            disabled={state.name.endsWith("ing")}
                        />
                    </Box>
                </Box>
            </Container>
            {state.name === "saved" && <SuccessNotification message={t("library.notifications.manage_ok")} />}
            {state.name === "errored" && <ErrorNotification message={t("library.notifications.manage_fail")} />}
        </Container>
    );
};
