import {cleanseFormula, OperationType, resolveFormulaOperation} from "./formulas";

export interface FormulaNode {
    type: "empty" | "invalid" | "valid";
    opType?: OperationType;
    operands: FormulaNode[];
    value: string;
}

export const EMPTY_CONST_NODE: FormulaNode = {
    type: "valid",
    opType: OperationType.CONST,
    value: "0",
    operands: [],
};

const nodeOperations: Set<OperationType> = new Set<OperationType>([
    OperationType.POW,
    OperationType.SUB,
    OperationType.ROUND,
    OperationType.SQRT,
    OperationType.ADD,
    OperationType.MUL,
    OperationType.DIV,
    OperationType.CEIL,
    OperationType.FLOOR,
]);

export function reconstructFormula(node: FormulaNode): string {
    if (node.type !== "valid" || node.opType === undefined) {
        return "";
    }
    if (node.opType === OperationType.CONST) {
        return `CONST(${node.value})`;
    }
    if (node.opType === OperationType.VAR) {
        return `VAR(${node.value})`;
    }
    const parts = node.operands.map((o) => reconstructFormula(o));
    const operands = parts.join(",");
    return `${node.opType.valueOf()}(${operands})`;
}

const min2Operands = new Set<OperationType | undefined>([
    OperationType.ADD,
    OperationType.SUB,
    OperationType.DIV,
    OperationType.MUL,
    OperationType.POW,
]);

const min1Operands = new Set<OperationType | undefined>([
    OperationType.FLOOR,
    OperationType.CEIL,
    OperationType.ROUND,
    OperationType.LOG,
]);

export function getDefaultOperands(operation: OperationType): FormulaNode[] {
    if (min2Operands.has(operation)) {
        return [EMPTY_CONST_NODE, EMPTY_CONST_NODE];
    }
    if (min1Operands.has(operation)) {
        return [EMPTY_CONST_NODE];
    }
    return [];
}

export function getDefaultOperationValue(operation: OperationType): string {
    if (operation === OperationType.CONST) {
        return "0";
    }
    return "";
}

export function parseFormulaNodes(formula: string, fixOperands: boolean): FormulaNode[] {
    let toParse = cleanseFormula(formula);
    const items: FormulaNode[] = [];

    while (toParse.length > 0) {
        try {
            const opCtx = resolveFormulaOperation(toParse);
            const opLen = opCtx.operation.length;
            const current = toParse.substring(opLen + 1, opCtx.end);
            toParse = cleanseFormula(toParse.substring(opCtx.end + 1));

            const node: FormulaNode = {
                type: "valid",
                opType: opCtx.operation,
                operands: [],
                value: current,
            };

            if (nodeOperations.has(opCtx.operation)) {
                node.operands = parseFormulaNodes(current, fixOperands);
            }

            if (fixOperands) {
                if (min2Operands.has(node.opType)) {
                    if (node.operands.length == 0) {
                        node.operands = [EMPTY_CONST_NODE, EMPTY_CONST_NODE];
                    } else if (node.operands.length == 1) {
                        node.operands.push(EMPTY_CONST_NODE);
                    }
                } else if (min1Operands.has(node.opType)) {
                    if (node.operands.length == 0) {
                        node.operands = [EMPTY_CONST_NODE];
                    }
                }
            }

            items.push(node);
        } catch (e) {
            return [EMPTY_CONST_NODE];
        }
    }

    return items;
}
