import React from "react";
import {
    LibraryDomainUseDTO,
    LibraryDTO,
    LibraryGoalUseDTO,
    LibraryIndicatorUseDTO,
    LibraryPartsSelectionDTO,
} from "../../../models/library";
import {Box, Checkbox, Container, FormControlLabel} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        domainSelectRow: {
            borderWidth: 0,
            borderStyle: "solid",
            borderBottomWidth: 3,
            borderColor: "rgb(0,125,180)",
            marginLeft: theme.spacing(0),
        },
        goalSelectRow: {
            borderWidth: 0,
            borderStyle: "solid",
            borderBottomWidth: 2,
            borderColor: "rgb(0,125,180)",
            marginLeft: theme.spacing(2),
        },
        indicatorSelectRow: {
            borderWidth: 0,
            borderStyle: "solid",
            borderBottomWidth: 1,
            borderColor: "rgb(0,125,180)",
            marginLeft: theme.spacing(4),
        },
    })
);

interface SelectedDomain {
    libraryDomainUse: LibraryDomainUseDTO;
    goals: SelectedGoal[];
    checked: boolean;
    alreadyUsed: boolean;
    intermediate: boolean;
}

interface SelectedGoal {
    libraryGoalUse: LibraryGoalUseDTO;
    indicators: SelectedIndicator[];
    checked: boolean;
    alreadyUsed: boolean;
    intermediate: boolean;
}

interface SelectedIndicator {
    libraryIndicatorUse: LibraryIndicatorUseDTO;
    checked: boolean;
    alreadyUsed: boolean;
}

interface LibraryPartsSelectorProps {
    library: LibraryDTO;
    usedDomainUUIDs: Set<string>;
    usedGoalUUIDs: Set<string>;
    usedIndicatorUUIDs: Set<string>;

    disabled: boolean;
    onChange: (selection: LibraryPartsSelectionDTO) => void;
}

interface LibraryPartsSelectorState {
    domains: SelectedDomain[];
}

export const LibraryPartsSelector: React.FC<LibraryPartsSelectorProps> = (props: LibraryPartsSelectorProps) => {
    //== Init =================================================================
    const classes = useStyles();
    const initState: LibraryPartsSelectorState = {
        domains: props.library.libraryDomainUses.map((d) => {
            const goals = d.libraryGoalUses.map((g) => {
                const indicators = g.libraryIndicatorUses.map((i) => ({
                    libraryIndicatorUse: i,
                    checked: false,
                    alreadyUsed: props.usedIndicatorUUIDs.has(i.indicator.id),
                }));
                const checkedIndicators = indicators.filter((i) => i.checked || i.alreadyUsed).length;
                return {
                    libraryGoalUse: g,
                    indicators: indicators,
                    checked: false,
                    alreadyUsed: props.usedGoalUUIDs.has(g.goal.id),
                    intermediate: 0 < checkedIndicators && checkedIndicators < indicators.length,
                };
            });
            const checkedGoals = goals.filter((g) => g.checked || g.alreadyUsed).length;
            return {
                libraryDomainUse: d,
                checked: false,
                alreadyUsed: props.usedDomainUUIDs.has(d.domain.id),
                goals: goals,
                intermediate: 0 < checkedGoals && checkedGoals < goals.length,
            };
        }),
    };
    const [state, setState] = React.useState(initState);
    //== Handlers =============================================================
    const handleChange = (domains: SelectedDomain[]) => {
        const selection: LibraryPartsSelectionDTO = {
            libraryUUID: props.library.id,
            selectedDomains: domains
                .filter((d) => d.checked)
                .map((d) => {
                    return {
                        domainUUID: d.libraryDomainUse.domain.id,
                        selectedGoals: d.goals
                            .filter((g) => g.checked)
                            .map((g) => {
                                return {
                                    goalUUID: g.libraryGoalUse.goal.id,
                                    selectedIndicators: g.indicators
                                        .filter((i) => i.checked)
                                        .map((i) => {
                                            return {
                                                indicatorUUID: i.libraryIndicatorUse.indicator.id,
                                            };
                                        }),
                                };
                            }),
                    };
                }),
        };
        props.onChange(selection);
    };
    const handleToggleDomain = (domain: SelectedDomain) => {
        const newState: LibraryPartsSelectorState = {
            ...state,
            domains: state.domains.map((d) => {
                if (domain.libraryDomainUse.id === d.libraryDomainUse.id) {
                    return {
                        ...d,
                        checked: !domain.checked,
                        goals: d.goals.map((g) => {
                            return {
                                ...g,
                                checked: !domain.checked,
                                indicators: g.indicators.map((i) => {
                                    return {
                                        ...i,
                                        checked: !domain.checked,
                                    };
                                }),
                            };
                        }),
                    };
                } else {
                    return d;
                }
            }),
        };
        setState(newState);
        handleChange(newState.domains);
    };
    const handleToggleGoal = (domain: SelectedDomain, goal: SelectedGoal) => {
        const newState: LibraryPartsSelectorState = {
            ...state,
            domains: state.domains.map((d) => {
                if (domain.libraryDomainUse.id === d.libraryDomainUse.id) {
                    return {
                        ...d,
                        checked: !goal.checked || d.checked,
                        goals: d.goals.map((g) => {
                            if (goal.libraryGoalUse.id === g.libraryGoalUse.id) {
                                return {
                                    ...g,
                                    checked: !goal.checked,
                                    indicators: g.indicators.map((i) => {
                                        return {
                                            ...i,
                                            checked: !goal.checked,
                                        };
                                    }),
                                };
                            } else {
                                return g;
                            }
                        }),
                    };
                } else {
                    return d;
                }
            }),
        };
        setState(newState);
        handleChange(newState.domains);
    };
    const handleToggleIndicator = (domain: SelectedDomain, goal: SelectedGoal, indicator: SelectedIndicator) => {
        const newState: LibraryPartsSelectorState = {
            ...state,
            domains: state.domains.map((d) => {
                if (domain.libraryDomainUse.id === d.libraryDomainUse.id) {
                    return {
                        ...d,
                        checked: !indicator.checked || d.checked,
                        goals: d.goals.map((g) => {
                            if (goal.libraryGoalUse.id === g.libraryGoalUse.id) {
                                return {
                                    ...g,
                                    checked: !indicator.checked || g.checked,
                                    indicators: g.indicators.map((i) => {
                                        if (indicator.libraryIndicatorUse.id === i.libraryIndicatorUse.id) {
                                            return {
                                                ...i,
                                                checked: !indicator.checked,
                                            };
                                        } else {
                                            return i;
                                        }
                                    }),
                                };
                            } else {
                                return g;
                            }
                        }),
                    };
                } else {
                    return d;
                }
            }),
        };
        setState(newState);
        handleChange(newState.domains);
    };
    //== Render ===============================================================
    return (
        <Container>
            {state.domains.map((d) => {
                const domainDisabled = d.alreadyUsed;
                const domainChecked = d.checked || d.alreadyUsed;
                return (
                    <Box key={d.libraryDomainUse.id}>
                        <Box className={classes.domainSelectRow}>
                            <FormControlLabel
                                disabled={domainDisabled}
                                control={
                                    <Checkbox
                                        checked={domainChecked}
                                        color="primary"
                                        onClick={() => {
                                            handleToggleDomain(d);
                                        }}
                                    />
                                }
                                label={d.libraryDomainUse.domain.name}
                            />
                        </Box>
                        {d.goals.map((g) => {
                            const goalDisabled = g.alreadyUsed;
                            const goalChecked = g.checked || g.alreadyUsed;
                            return (
                                <Box key={g.libraryGoalUse.id}>
                                    <Box className={classes.goalSelectRow}>
                                        <FormControlLabel
                                            disabled={goalDisabled}
                                            control={
                                                <Checkbox
                                                    checked={goalChecked}
                                                    color="primary"
                                                    onClick={() => {
                                                        handleToggleGoal(d, g);
                                                    }}
                                                />
                                            }
                                            label={g.libraryGoalUse.goal.name}
                                        />
                                    </Box>
                                    {g.indicators.map((i) => {
                                        const indicatorDisabled = i.alreadyUsed;
                                        const indicatorChecked = i.checked || i.alreadyUsed;
                                        return (
                                            <Box key={i.libraryIndicatorUse.id} className={classes.indicatorSelectRow}>
                                                <FormControlLabel
                                                    disabled={indicatorDisabled}
                                                    control={
                                                        <Checkbox
                                                            checked={indicatorChecked}
                                                            color="primary"
                                                            onClick={() => {
                                                                handleToggleIndicator(d, g, i);
                                                            }}
                                                        />
                                                    }
                                                    label={i.libraryIndicatorUse.indicator.name}
                                                />
                                            </Box>
                                        );
                                    })}
                                </Box>
                            );
                        })}
                    </Box>
                );
            })}
        </Container>
    );
};
