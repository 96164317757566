import React, {ChangeEvent} from "react";
// Material-UI import
import {Avatar, Box, Chip, Icon, TextField, Tooltip} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
// Project imports
import {compareTagsByCategory, loadTagStyle, TagCategoryDTO, TagDTO} from "../../models/admin";
import {ServerCommunicationAlert} from "./errors";
import {API, createApiConfig} from "../../utils";
import {AxiosError} from "axios";
import {useKeycloak} from "@react-keycloak/web";
import Loading from "./Loading";
import {useStyles} from "../../styles";
import {useTranslation} from "react-i18next";
import {comparator} from "../../utils/compare";

interface TagProps {
    tag: TagDTO;
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    onDelete?: React.EventHandler<any>;
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    onClick?: React.EventHandler<any>;
    variant?: "default" | "outlined";
    color?: "default" | "primary" | "secondary";
    size?: "medium" | "small";
}

const getCategoryStyle = (tag: TagDTO) => {
    if (tag.category === undefined || tag.category === null) {
        return undefined;
    }
    try {
        return JSON.parse(tag.category.style);
    } catch (e) {
        return undefined;
    }
};

const getCategoryIconStyle = (tag: TagDTO) => {
    if (tag.category === undefined || tag.category === null) {
        return undefined;
    }
    try {
        const style = loadTagStyle(tag.category.style);
        return {color: style.iconColor};
    } catch (e) {
        return undefined;
    }
};

export const Tag: React.FC<TagProps> = (props: TagProps) => {
    const classes = useStyles();
    const style = getCategoryStyle(props.tag);
    const iconStyle = getCategoryIconStyle(props.tag);
    const avatar = props.tag.category?.icon ? undefined : <Avatar>{props.tag.name[0]}</Avatar>;
    const icon = props.tag.category?.icon ? <Icon style={iconStyle}>{props.tag.category.icon}</Icon> : undefined;
    return (
        <Tooltip title={props.tag.description}>
            <Chip
                avatar={avatar}
                icon={icon}
                style={style}
                label={props.tag.name}
                onDelete={props.onDelete}
                onClick={props.onClick}
                clickable={props.onClick !== undefined}
                variant={props.variant}
                color={props.color}
                size={props.size}
                className={classes.spaceAround}
            />
        </Tooltip>
    );
};

interface TagsProps {
    tags: TagDTO[];
    variant?: "default" | "outlined";
    color?: "inherit" | "primary" | "secondary";
    size?: "medium" | "small";
}

export function compareTags(x: TagDTO, y: TagDTO): number {
    const xPriority = x.category?.priority ?? 0;
    const yPriority = y.category?.priority ?? 0;
    if (xPriority === yPriority) {
        return comparator<string>(x.name, y.name);
    }
    return -1 * comparator<number>(xPriority, yPriority);
}

export const Tags: React.FC<TagsProps> = (props: TagsProps) => {
    const tags = props.tags.sort(compareTags);
    return (
        <Box>
            {tags.map((tag) => (
                <Tag tag={tag} key={tag.id} />
            ))}
        </Box>
    );
};

interface TagsInputProps {
    value: TagDTO[];
    possibleTags?: TagDTO[];
    variant?: "outlined" | "standard" | "filled";
    label: string;
    placeholder?: string;
    fullWidth?: boolean;
    className?: string;
    margin?: "normal" | "dense" | "none";

    onChange?(tags: TagDTO[]): void;
}

interface TagsInputState {
    possibleTags: TagDTO[];
    value: TagDTO[];
    name: string;
}

export const TagsInput: React.FC<TagsInputProps> = (props: TagsInputProps) => {
    //== Init =================================================================
    const initState: TagsInputState = {
        possibleTags: props.possibleTags && props.possibleTags.length ? props.possibleTags : [],
        value: props.value,
        name: props.possibleTags && props.possibleTags.length ? "loaded" : "loading",
    };
    const [state, setState] = React.useState(initState);
    const {keycloak, initialized} = useKeycloak();
    const [t] = useTranslation("common");
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<TagDTO[]>(`/tags/list/all`, createApiConfig(keycloak, initialized))
                .then((res) => {
                    const possibleTags: TagDTO[] = res.data;
                    possibleTags.sort(compareTagsByCategory);
                    setState({...state, possibleTags: possibleTags, name: "loaded"});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [state, setState, keycloak, initialized]);
    //== Handlers =============================================================
    //eslint-disable-next-line @typescript-eslint/ban-types
    const handleChange = (event: ChangeEvent<{}>, value: TagDTO[]): void => {
        if (props.onChange) {
            props.onChange(value);
        }
        setState({...state, value: value});
    };
    //== Render ===============================================================
    if (state.name === "loading") {
        return <Loading />;
    }
    if (state.name === "failed") {
        return <ServerCommunicationAlert />;
    }
    return (
        <Autocomplete
            multiple
            groupBy={(option) => option.category?.name ?? t("tags.noCategory")}
            options={state.possibleTags}
            getOptionLabel={(tag: TagDTO): string => tag.name}
            getOptionSelected={(option, value): boolean => {
                return option.id === value.id;
            }}
            value={state.value}
            filterSelectedOptions
            onChange={handleChange}
            fullWidth={props.fullWidth}
            renderInput={(params): JSX.Element => (
                <TextField
                    {...params}
                    variant={props.variant}
                    label={props.label}
                    placeholder={props.placeholder}
                    fullWidth={props.fullWidth}
                    className={props.className}
                    margin={props.margin}
                />
            )}
        />
    );
};

interface TagCategoryInputProps {
    categoryUUID?: string | null;
    variant?: "outlined" | "standard" | "filled";
    label: string;
    placeholder?: string;
    fullWidth?: boolean;
    className?: string;
    extraItems?: TagCategoryDTO[];

    onChange?(category: TagCategoryDTO): void;
}

interface TagCategoryInputState {
    values: TagCategoryDTO[];
    categoryUUID: string | null;
    value: TagCategoryDTO | null;
    name: "loading" | "loaded" | "failed";
}

export const TagCategoryInput: React.FC<TagCategoryInputProps> = (props: TagCategoryInputProps) => {
    //== Init =================================================================
    const initState: TagCategoryInputState = {
        values: [],
        categoryUUID: props.categoryUUID ?? null,
        value: null,
        name: "loading",
    };
    const [t] = useTranslation("common");
    const [state, setState] = React.useState(initState);
    const {keycloak, initialized} = useKeycloak();
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<TagCategoryDTO[]>(`/tag-categories/all`, createApiConfig(keycloak, initialized))
                .then((res) => {
                    const values: TagCategoryDTO[] = res.data ?? [];
                    if (props.extraItems) {
                        values.push(...props.extraItems);
                    }
                    values.sort((a, b) => (a.name < b.name ? 1 : -1));
                    if (typeof state.categoryUUID === "string") {
                        const value = values.find((value) => value.id === state.categoryUUID) ?? null;
                        setState({...state, value: value, values: values, name: "loaded"});
                    } else {
                        setState({...state, values: values, name: "loaded"});
                    }
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [state, setState, keycloak, initialized, props]);
    //== Handlers =============================================================
    //eslint-disable-next-line @typescript-eslint/ban-types
    const handleChange = (event: ChangeEvent<{}>, value: TagCategoryDTO | null): void => {
        if (props.onChange && value != null) {
            props.onChange(value);
        }
        setState({...state, value: value, categoryUUID: value?.id ?? null});
    };
    //== Render ===============================================================
    if (state.name === "failed") {
        return <ServerCommunicationAlert />;
    }
    return (
        <Autocomplete
            loading={state.name === "loading"}
            loadingText={t("tags.categories.select.loading")}
            noOptionsText={t("tags.categories.select.empty")}
            openText={t("tags.categories.select.open")}
            closeText={t("tags.categories.select.close")}
            clearText={t("tags.categories.select.clear")}
            options={state.values}
            getOptionLabel={(value: TagCategoryDTO): string => value.name}
            getOptionSelected={(option, value): boolean => {
                return option.id === value.id;
            }}
            value={state.value}
            filterSelectedOptions
            onChange={handleChange}
            renderInput={(params): JSX.Element => (
                <TextField
                    {...params}
                    variant={props.variant}
                    label={props.label}
                    placeholder={props.placeholder}
                    fullWidth={props.fullWidth}
                    className={props.className}
                />
            )}
        />
    );
};
