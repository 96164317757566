import React from "react";
import {useParams} from "react-router-dom";
// Project imports
import {Webpage} from "./Webpage";

interface WebpageParams {
    webpageId: string;
}

export const CMSPageView: React.FC = () => {
    const {webpageId} = useParams<WebpageParams>();
    return <Webpage webpageId={webpageId ?? ""} footer={true} />;
};

export default CMSPageView;
