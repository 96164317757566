import {LibraryDTO, LibraryPartsSelectionDTO} from "../../../models/library";
import React from "react";
import {useKeycloak} from "@react-keycloak/web";
import {API, createApiConfig} from "../../../utils/API";
import {AxiosError} from "axios";
import {Box, Button, DialogActions, DialogContent} from "@material-ui/core";
import Loading, {LoadingBackdrop} from "../../common/Loading";
import {LibraryPartsSelector} from "./LibraryPartsSelector";
import {useTranslation} from "react-i18next";

interface LibraryAdderProps {
    sourceLibraryUUID: string;
    targetLibrary: LibraryDTO;
    onUpdate: (library: LibraryDTO) => void;
    onBack: () => void;
}

interface LibraryAdderState {
    name: "loading" | "loaded" | "adding" | "failed";
    library: LibraryDTO | null;
    selection: LibraryPartsSelectionDTO;
}

export const LibraryAdder: React.FC<LibraryAdderProps> = (props: LibraryAdderProps) => {
    //== Init =================================================================
    const initState: LibraryAdderState = {
        name: "loading",
        library: null,
        selection: {
            libraryUUID: props.sourceLibraryUUID,
            selectedDomains: [],
        },
    };
    const [state, setState] = React.useState(initState);
    const {keycloak, initialized} = useKeycloak();
    const [t] = useTranslation("libraries");
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<LibraryDTO>(`/libraries/${props.sourceLibraryUUID}`, createApiConfig(keycloak, initialized))
                .then((res) => {
                    console.log(res.data);
                    setState({...state, library: res.data, name: "loaded"});
                })
                .catch((err: AxiosError) => {
                    console.log(err);
                    setState({...state, name: "failed"});
                });
        }
        if (state.name === "adding") {
            API.put<LibraryDTO>(
                `/libraries/${props.targetLibrary.id}/use`,
                state.selection,
                createApiConfig(keycloak, initialized)
            )
                .then((res) => {
                    console.log(res.data);
                    setState({...state, library: res.data, name: "loaded"});
                    props.onUpdate(res.data);
                })
                .catch((err: AxiosError) => {
                    console.log(err);
                    setState({...state, name: "failed"});
                });
        }
    }, [state, setState, props]);
    //== Helpers ==============================================================
    const usedDomainUUIDs = new Set<string>();
    const usedGoalUUIDs = new Set<string>();
    const usedIndicatorUUIDs = new Set<string>();
    props.targetLibrary.libraryDomainUses.forEach((d) => {
        usedDomainUUIDs.add(d.domain.id);
        d.libraryGoalUses.forEach((g) => {
            usedGoalUUIDs.add(g.goal.id);
            g.libraryIndicatorUses.forEach((i) => {
                usedIndicatorUUIDs.add(i.indicator.id);
            });
        });
    });
    //== Handlers =============================================================
    const handleChange = (selection: LibraryPartsSelectionDTO) => {
        console.log("change");
        console.log(selection);
        setState({...state, selection: selection});
    };
    const handleSubmit = () => {
        setState({...state, name: "adding"});
    };
    const handleBack = () => {
        setState(initState);
        props.onBack();
    };
    //== Render ===============================================================
    return (
        <>
            <DialogContent>
                {state.name === "loading" && <Loading />}
                {state.name === "adding" && <LoadingBackdrop />}
                {state.library !== null && (
                    <>
                        <Box style={{marginBottom: "0.5em"}}>
                            {t("library.add_dialog.title.pick_parts", {name: state.library.name})}
                        </Box>
                        <LibraryPartsSelector
                            library={state.library}
                            onChange={handleChange}
                            usedDomainUUIDs={usedDomainUUIDs}
                            usedGoalUUIDs={usedGoalUUIDs}
                            usedIndicatorUUIDs={usedIndicatorUUIDs}
                            disabled={state.name === "adding"}
                        />
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleBack}>{t("library.add_dialog.back")}</Button>
                <Button onClick={handleSubmit}>{t("library.add_dialog.submit")}</Button>
            </DialogActions>
        </>
    );
};
