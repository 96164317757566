import React, {useEffect, useState} from "react";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
// Material-UI imports
import {Box, Container, Divider, Grid} from "@material-ui/core";
// Project imports
import Utils, {API, createApiConfig} from "../../../utils";
import PersonEmails from "../Emails/PersonEmails";
import {LoadingBackdrop} from "../../common/Loading";
import {makeFullName, ProfileDTO, ProfileUpdateDTO, PublicProfileDTO, toPersonUpdateDTO} from "../../../models/user";
import {CommunicationError} from "../../common/errors";
import {PageHeader, PrimaryPageAction, SecondaryPageAction} from "../../common/headers";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import EditIcon from "@material-ui/icons/Edit";
import {UploadableImage} from "../../common/images";
import EmptyAvatar from "../../../graphics/avatar.png";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {AxiosError, AxiosResponse} from "axios";
import {ImageDTO} from "../../../models/images";
import {API_URL} from "../../../utils/API";
import {ProfileForm} from "./ProfileForm";
import {RouteComponentProps} from "react-router-dom";
import {MarkdownRender} from "../../common/markdown";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            "& > *": {
                margin: theme.spacing(1),
            },
        },
        small: {
            width: theme.spacing(3),
            height: theme.spacing(3),
        },
        large: {
            width: theme.spacing(20),
            height: theme.spacing(20),
        },
        spaced: {
            margin: theme.spacing(5),
        },
        spaceBefore: {
            marginTop: theme.spacing(2),
        },
        spaceAfter: {
            marginBottom: theme.spacing(2),
        },
        fullWidth: {
            width: "100%",
        },
        minTableCell: {
            width: "1%",
            whiteSpace: "nowrap",
            border: "0",
        },
        noBorder: {
            border: "0",
        },
        profileImageWrapper: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 160,
            height: 160,
        },
        profileImage: {
            maxWidth: 150,
            maxHeight: 150,
        },
    })
);

type ProfileRouteParams = {
    personId: string;
};

export interface ProfileRouteProps
    extends RouteComponentProps<ProfileRouteParams>,
        React.ClassAttributes<ProfileRouteParams> {}

export interface ProfileProps {
    personId?: string;
}

interface ProfileContentState {
    profile: ProfileDTO | PublicProfileDTO | null;
    name: "loading" | "loaded" | "edit" | "editing" | "saving" | "failed";
    notification: "edit_ok" | "edit_fail" | "avatar_ok" | "avatar_fail" | "no_changes" | null;
    avatar: ImageDTO | null;
    personEdit: ProfileUpdateDTO | null;
}

const ProfileContent: React.FC<ProfileProps> = (props: ProfileProps) => {
    //== Init ===================================================================
    const [t] = useTranslation("user");
    const classes = useStyles();
    const {keycloak, initialized} = useKeycloak();
    console.log("ProfileContent - props:");
    console.log(props);
    const initState: ProfileContentState = {
        profile: null,
        notification: null,
        name: "loading",
        avatar: null,
        personEdit: null,
    };
    const [state, setState] = useState(initState);
    //== Effects ==============================================================
    const isOwnProfile = !props.personId;
    useEffect(() => {
        if (state.name === "loading") {
            API.get<ProfileDTO | PublicProfileDTO>(
                isOwnProfile ? "/profile" : "/public-profile/" + props.personId,
                createApiConfig(keycloak, initialized)
            )
                .then((res) => {
                    const avatar = res.data.person.profileImage;
                    setState({
                        ...state,
                        profile: res.data,
                        personEdit: toPersonUpdateDTO(res.data.person),
                        avatar: avatar,
                        name: "loaded",
                    });
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        } else if (state.name === "saving") {
            console.log(state.personEdit);
            API.put<ProfileDTO>(`/profile`, state.personEdit, createApiConfig(keycloak, initialized))
                .then((res) => {
                    const avatar = res.data.person.profileImage;
                    setState({
                        ...state,
                        profile: res.data,
                        personEdit: toPersonUpdateDTO(res.data.person),
                        avatar: avatar,
                        name: "loaded",
                        notification: "edit_ok",
                    });
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "loaded", notification: "edit_fail"});
                });
        }
    }, [keycloak, initialized, state, setState]);
    //== Handlers =============================================================
    const submitChanges = (person: ProfileUpdateDTO): void => {
        if (state.profile === null || state.personEdit === null) {
            return;
        }
        const changed = Utils.objEq(toPersonUpdateDTO(state.profile.person), state.personEdit, [
            "firstname",
            "lastname",
            "titlesBefore",
            "titlesAfter",
            "bio",
        ]);
        if (changed) {
            setState({...state, name: "saving", personEdit: person});
        } else {
            setState({...state, name: "loaded", notification: "no_changes"});
        }
    };
    const startEdit = (): void => {
        setState({...state, name: "editing"});
    };
    const stopEdit = (): void => {
        setState({...state, name: "loaded"});
    };
    const handleAvatarUpload = (file: File) => {
        const formData = new FormData();
        formData.append("image", file);
        API.post<ImageDTO>(`/profile/avatar`, formData, createApiConfig(keycloak, initialized))
            .then((res: AxiosResponse<ImageDTO>) => {
                setState({...state, name: "loaded", avatar: res.data, notification: "avatar_ok"});
            })
            .catch((err: AxiosError) => {
                setState({...state, name: "failed", notification: "avatar_fail"});
            });
    };
    //== Render ===============================================================
    // TODO: notifications
    const changePassLink = keycloak.createAccountUrl().replace("/account", "/account/password");
    if (state.name === "failed") {
        return <CommunicationError />;
    } else if (state.name === "loading") {
        return <LoadingBackdrop />;
    } else if (state.profile !== null && state.personEdit !== null) {
        const profile = state.profile;
        const avatarUrl = `${API_URL}/profile/avatar?timestamp=${state.avatar?.updatedAt}`;
        const personInfo = (
            <Box className={classes.fullWidth}>
                <Box style={{float: "left"}}>
                    <h2>{makeFullName(state.profile.person)}</h2>
                </Box>
                <Divider style={{clear: "both"}} />
                <MarkdownRender value={state.profile.person.bio ?? ""} />
            </Box>
        );
        const profileForm = <ProfileForm profile={state.personEdit} onSubmit={submitChanges} onCancel={stopEdit} />;
        return (
            <Container maxWidth="md">
                <PageHeader title={t("profile.profile")}>
                    {isOwnProfile ? (
                        <>
                            <SecondaryPageAction title={t("profile.changePassword")} to={changePassLink} external>
                                <VpnKeyIcon />
                            </SecondaryPageAction>
                            <PrimaryPageAction
                                title={t("profile.editProfile")}
                                onClick={startEdit}
                                disabled={state.name === "editing"}
                            >
                                <EditIcon />
                            </PrimaryPageAction>
                        </>
                    ) : null}
                </PageHeader>

                <Grid container style={{marginTop: "1em"}}>
                    <Grid container item md={3} justifyContent="center">
                        <Box className={classes.spaceAfter}>
                            <UploadableImage
                                key={state.avatar?.updatedAt ?? "emptyAvatar"}
                                src={avatarUrl}
                                wrapperClass={classes.profileImageWrapper}
                                imageClass={classes.profileImage}
                                alt={makeFullName(state.profile.person)}
                                onConfirm={handleAvatarUpload}
                                emptyImage={EmptyAvatar}
                                editable={isOwnProfile}
                            />
                        </Box>
                    </Grid>
                    <Grid container item md={9}>
                        {state.name === "editing" ? profileForm : personInfo}
                    </Grid>
                </Grid>
                {state.name === "loaded" && (
                    <Box>
                        <PersonEmails personUuid={profile.person.id} emails={profile.emails} own={isOwnProfile} />
                    </Box>
                )}
                {state.name === "saving" && <LoadingBackdrop />}
            </Container>
        );
    } else {
        return <CommunicationError />;
    }
};

const Profile: React.FC<ProfileRouteProps> = (props: ProfileRouteProps) => {
    console.log("Profile - props:");
    console.log(props);
    return <ProfileContent personId={props.match.params.personId} />;
};

export const OwnProfile: React.FC = () => {
    console.log("OwnProfile");
    return <ProfileContent />;
};

export default Profile;
