import React from "react";
import {InputVariableDTO} from "../../../../models/library";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";
import {InfoActionButton} from "../../../common/buttons";

interface InputVariableItemProps {
    inputVariable: InputVariableDTO;
    onInfoOpen: (variableId: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        itemRow: {
            borderWidth: 0,
            borderStyle: "solid",
            borderBottomWidth: 2,
            borderColor: theme.palette.primary.main,
            padding: theme.spacing(1),
            display: "flex",
            alignItems: "center",
        },
        itemTitle: {
            fontSize: "125%",
            fontWeight: "bold",
        },
    })
);

export const InputVariableItem: React.FC<InputVariableItemProps> = (props: InputVariableItemProps) => {
    //== Init =================================================================
    const classes = useStyles();
    //== Render ===============================================================
    return (
        <Box className={classes.itemRow}>
            <Box flexGrow="1">
                <Box className={classes.itemTitle}>{props.inputVariable.name}</Box>
            </Box>
            <Box>
                <InfoActionButton onClick={() => props.onInfoOpen(props.inputVariable.variableUUID)} />
            </Box>
        </Box>
    );
};
