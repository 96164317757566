import React from "react";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
// Project imports
import API, {createApiConfig, emptyState, failedState, successState} from "../../../../utils/API";
import {AxiosError} from "axios";
import Loading from "../../../common/Loading";
import {CommunicationError} from "../../../common/errors";
import {OrganizationDTO} from "../../../../models/organization";
// Material-UI imports
import {Box} from "@material-ui/core";
import Memberships from "./Memberships";
import MembershipInvitations from "./MembershipInvitations";
import {MembershipDTO} from "../../../../models/members";
import {ManualSidebar} from "../../../../manual/components/ManualSidebar";
import {csMunicipalityMembersSection} from "../../../../manual/content/cs/CsMunicipalityManualChapter";
import {csOrganizationMembersSection} from "../../../../manual/content/cs/CsOrganizationManualChapter";

export interface OrganizationMembersProps {
    organizationId: string;
}

const OrganizationMembersContent: React.FC<OrganizationMembersProps> = (props: OrganizationMembersProps) => {
    const {keycloak, initialized} = useKeycloak();
    const [data, setData] = React.useState(emptyState);
    const [lastAcceptedInvitation, setLastAcceptedInvitation] = React.useState(null as MembershipDTO | null);
    const [t] = useTranslation("organizationMembers");
    React.useEffect(() => {
        console.log("organizationId = " + props.organizationId);
        API.get<OrganizationDTO>(`/organizations/${props.organizationId}`, createApiConfig(keycloak, initialized))
            .then((res) => {
                setData(successState(res.data));
            })
            .catch((err: AxiosError) => {
                setData(failedState);
            });
    }, [keycloak, props.organizationId, initialized]);

    const onInvitationAccept = (membership: MembershipDTO) => {
        setLastAcceptedInvitation(membership);
    };

    if (data === emptyState) {
        return <Loading />;
    } else if (data === failedState) {
        return <CommunicationError />;
    } else {
        const organization: OrganizationDTO = data.data;
        return (
            <Box>
                <ManualSidebar
                    section={organization.municipality ? csMunicipalityMembersSection : csOrganizationMembersSection}
                />
                <h1>{t("position.membershipInvitations")}</h1>
                <MembershipInvitations organization={organization} onAccept={onInvitationAccept} />
                <h1>{t("position.currentMembers")}</h1>
                <Memberships organization={organization} membershipToEnsureRender={lastAcceptedInvitation} />
            </Box>
        );
    }
};

const OrganizationMembersIndex: React.FC<OrganizationMembersProps> = (props: OrganizationMembersProps) => {
    console.log("organizationId = " + props.organizationId);
    return <OrganizationMembersContent organizationId={props.organizationId} />;
};

export default OrganizationMembersIndex;
