import axios, {AxiosRequestConfig} from "axios";
import axiosRetry from "axios-retry";
import {KeycloakInstance} from "keycloak-js";

interface SCCConfig {
    apiUrl: string;
    apiRetries: number | undefined;
}

declare global {
    interface Window {
        scc: SCCConfig;
    }
}

declare const window: Window;

export const API_URL = window.scc.apiUrl;

export const API = axios.create({
    baseURL: API_URL,
    responseType: "json",
});

axiosRetry(API, {retries: window.scc.apiRetries ?? 0});

interface SCCompassAPIState<T> {
    data: T | null;
    pending: boolean;
    error: boolean;
    completed: boolean;
}

export const createApiConfig = (keycloak: KeycloakInstance, initialized: boolean): AxiosRequestConfig => {
    return initialized && keycloak.authenticated
        ? {
              headers: {
                  Authorization: "Bearer " + keycloak.token,
              },
          }
        : {};
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const emptyState: SCCompassAPIState<any> = {
    data: null,
    pending: true,
    error: false,
    completed: false,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const failedState: SCCompassAPIState<any> = {
    data: null,
    pending: false,
    error: true,
    completed: true,
};

export function successState<T>(data: T): SCCompassAPIState<T> {
    return {
        data: data ? data : null,
        pending: false,
        error: false,
        completed: true,
    };
}

export default API;
