import React from "react";
import {LibraryDTO} from "../../../models/library";
import {useStyles} from "../../../styles";
import {useTranslation} from "react-i18next";
import {Container, Table, TableBody, TableCell, TableRow} from "@material-ui/core";
import {Tags} from "../../common/tags";
import {MarkdownRender} from "../../common/markdown";

interface LibraryInfoProps {
    library: LibraryDTO;
}

export const LibraryInfo: React.FC<LibraryInfoProps> = (props: LibraryInfoProps) => {
    const classes = useStyles();
    const [t] = useTranslation("libraries");
    return (
        <Container maxWidth="md">
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell className={classes.minTableCell}>{t("library.version")}</TableCell>
                        <TableCell>{props.library.version}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.minTableCell}>{t("library.license")}</TableCell>
                        <TableCell>{props.library.license}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <MarkdownRender value={props.library.description ?? ""} />
            <Tags tags={props.library.tags} />
        </Container>
    );
};
