import React from "react";
import {useTranslation} from "react-i18next";
import {Box, Tab} from "@material-ui/core";
import {CustomTabs, TabContent} from "../common/tabs";
import {Tags} from "./Tags";
import {TagCategories} from "./TagCategories";

interface TagsAdminProps {
    tab: "tags" | "categories";
}

const tabToValue = (str?: string): number => {
    if (str === "categories") return 1;
    return 0;
};

export const TagsAdmin: React.FC<TagsAdminProps> = (props: TagsAdminProps) => {
    //== Init ===================================================================
    const [t] = useTranslation("common");
    const [value, setValue] = React.useState(tabToValue(props.tab));
    //== Handlers ===============================================================
    const handleChange = (event: React.FormEvent<EventTarget>, newValue: number): void => {
        setValue(newValue);
    };
    //== Render =================================================================
    return (
        <Box>
            <CustomTabs
                style={{marginBottom: "2em"}}
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
                <Tab label={t("tags.tags")} />
                <Tab label={t("tags.categories.title")} />
            </CustomTabs>
            <TabContent index={0} value={value}>
                <Tags />
            </TabContent>
            <TabContent index={1} value={value}>
                <TagCategories />
            </TabContent>
        </Box>
    );
};

export const TagsAdminTags: () => JSX.Element = () => {
    return <TagsAdmin tab="tags" />;
};

export const TagsAdminCategories: () => JSX.Element = () => {
    return <TagsAdmin tab="categories" />;
};
