import {ReactChild, ReactPortal} from "react";
import {ManualSubsection} from "./ManualSubsection";

export interface ManualSubSubsection {
    key: string;
    title: string;
    content?: ReactChild | ReactPortal;
    index?: number;
    subsection?: ManualSubsection;
}

interface ManualSubSubsectionProps {
    key: string;
    title: string;
    content?: ReactChild | ReactPortal;
}

export const manualSubSubsection = (props: ManualSubSubsectionProps): ManualSubSubsection => {
    return {
        key: props.key,
        title: props.title,
        content: props.content || undefined,
    };
};

export default manualSubSubsection;
