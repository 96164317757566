import React from "react";
import {OperationType} from "../../../../../models/formulas";
import {Box} from "@material-ui/core";
import {FormulaOperationSelect} from "./FormulaOperationSelect";
import {FormulaNode, getDefaultOperands, getDefaultOperationValue} from "../../../../../models/formulaEditor";
import {VariableSelect} from "./VariableSelect";
import {VariableSimpleDTO} from "../../../../../models/library";

interface FormulaVariableFormProps {
    usedVariables: VariableSimpleDTO[];
    variables: VariableSimpleDTO[];
    node: FormulaNode;
    onChange: (node: FormulaNode) => void;
}

export const FormulaVariableForm: React.FC<FormulaVariableFormProps> = (props: FormulaVariableFormProps) => {
    //== Handlers =============================================================
    const handleValueChange = (value: string) => {
        props.onChange({...props.node, value: value});
    };
    const handleOperationChange = (operation: OperationType) => {
        props.onChange({
            ...props.node,
            opType: operation,
            value: getDefaultOperationValue(operation),
            operands: getDefaultOperands(operation),
        });
    };
    //== Render ===============================================================
    return (
        <Box display="flex">
            <FormulaOperationSelect value={OperationType.VAR} onChange={handleOperationChange} />
            <Box flexGrow="1">
                <VariableSelect
                    value={props.node.value}
                    usedVariables={props.usedVariables}
                    variables={props.variables}
                    onChange={handleValueChange}
                />
            </Box>
        </Box>
    );
};
