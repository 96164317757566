import React from "react";
import {useTranslation} from "react-i18next";
// Material UI imports
import {Box, Checkbox, FormControlLabel} from "@material-ui/core";
// Project imports
import {DeleteActionButton, InfoActionButton, SwapVerticallyActionButton} from "../../../common/buttons";
import {TrackedDomainInGroupDTO, TrackedGoalInGroupDTO} from "../../../../models/trackedIndicators";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {DomainDialog} from "../../../values/dialogs/DomainDialog";
import TrackedGoal, {containsGoalIndicators, trackedGoalWithPublicContent} from "./TrackedGoal";
import {DeleteConfirmationDialog} from "../../../common/dialogs";
import ItemPositionInLibrary, {
    DomainPositionInLibrary,
    GoalPositionInLibrary,
} from "../../../library/editor/util/LibraryItemPosition";
import LibraryItemMoveTarget from "../../../library/editor/util/LibraryItemMoveTarget";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        domainRow: {
            borderWidth: 0,
            borderStyle: "solid",
            borderBottomWidth: 2,
            borderColor: "rgb(0,125,180)",
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(0.5),
            marginLeft: theme.spacing(0),
            paddingLeft: theme.spacing(1),
            display: "flex",
        },
        dragHandle: {},
        domainItemName: {
            fontSize: "140%",
            fontWeight: "bold",
            marginTop: theme.spacing(1.5),
        },
        infoButton: {
            flexGrow: 1,
        },
        publicCheckbox: {
            marginTop: theme.spacing(0.4),
        },
        goalShiftTarget: {
            marginLeft: theme.spacing(3) + 48,
        },
    })
);

interface TrackedDomainCommonProps {
    trackedDomain: TrackedDomainInGroupDTO;
    itemPosition: DomainPositionInLibrary;
    shiftingItemPosition: ItemPositionInLibrary | null;

    handleShiftStart(itemPosition: DomainPositionInLibrary): void;

    handleRemoveItem(itemPosition: DomainPositionInLibrary): void;
}

interface TrackedDomainRowProps extends TrackedDomainCommonProps {
    handlePublicSwitch(): void;
}

interface TrackedDomainProps extends TrackedDomainCommonProps {
    handleShiftEnd(position: GoalPositionInLibrary): void;

    handlePublicSwitch(modifiedItem: TrackedDomainInGroupDTO): void;
}

const TrackedDomainRow: React.FC<TrackedDomainRowProps> = (props: TrackedDomainRowProps) => {
    //== Init ===================================================================
    const classes = useStyles();
    const [t] = useTranslation("trackedIndicators");
    const [state, setState] = React.useState({deleteConfirmationOpen: false, infoOpen: false});

    //== Handlers ===============================================================
    const handleInfoOpen = (): void => {
        setState({...state, infoOpen: true});
    };
    const handleInfoClose = (): void => {
        setState({...state, infoOpen: false});
    };
    const handleCancelDelete = (): void => {
        setState({...state, deleteConfirmationOpen: false});
    };
    const handleConfirmDelete = (): void => {
        props.handleRemoveItem(props.itemPosition);
        setState({...state, deleteConfirmationOpen: false});
    };
    const handleDelete = (): void => {
        if (!props.trackedDomain.trackedGoals.length) return handleConfirmDelete();
        setState({...state, deleteConfirmationOpen: true, infoOpen: false});
    };
    console.log("props.itemPosition:");
    console.log(props.itemPosition);
    console.log("props.shiftingItemPosition:");
    console.log(props.shiftingItemPosition);
    if (props.shiftingItemPosition) {
        console.log("props.shiftingItemPosition.domainIndex !== props.itemPosition.domainIndex:");
        console.log(props.shiftingItemPosition.domainIndex !== props.itemPosition.domainIndex);
        console.log("props.shiftingItemPosition.goalIndex !== props.itemPosition.goalIndex:");
        console.log(props.shiftingItemPosition.goalIndex !== props.itemPosition.goalIndex);
        console.log("props.shiftingItemPosition.indicatorIndex !== props.itemPosition.indicatorIndex:");
        console.log(props.shiftingItemPosition.indicatorIndex !== props.itemPosition.indicatorIndex);
    }
    const isShiftingOtherThanThisOne =
        props.shiftingItemPosition &&
        (props.shiftingItemPosition.domainIndex !== props.itemPosition.domainIndex ||
            props.shiftingItemPosition.goalIndex !== props.itemPosition.goalIndex ||
            props.shiftingItemPosition.indicatorIndex !== props.itemPosition.indicatorIndex);
    return (
        <>
            <div className={classes.domainRow}>
                <div className={classes.dragHandle} style={isShiftingOtherThanThisOne ? {visibility: "hidden"} : {}}>
                    <SwapVerticallyActionButton onClick={() => props.handleShiftStart(props.itemPosition)} />
                </div>
                <div className={classes.domainItemName}>
                    {t("library.domain") +
                        " " +
                        (props.itemPosition.domainIndex + 1) +
                        ": " +
                        props.trackedDomain.domain.name}
                </div>
                <Box className={classes.infoButton}>
                    <InfoActionButton onClick={handleInfoOpen} />
                </Box>
                <div className={classes.publicCheckbox}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={props.trackedDomain.public}
                                disabled={!containsDomainIndicators(props.trackedDomain)}
                                onChange={(e): void => props.handlePublicSwitch()}
                                name="public"
                            />
                        }
                        label={t("use.public")}
                    />
                </div>
                <div>
                    <DeleteActionButton onClick={handleDelete} />
                    <DeleteConfirmationDialog
                        title={t("ui.editor.deleteDomainWithGoalDialog.title")}
                        text={t("ui.editor.deleteDomainWithGoalDialog.text", {name: props.trackedDomain.domain.name})}
                        open={state.deleteConfirmationOpen}
                        id={"delete-dialog-" + props.trackedDomain.id}
                        onDelete={handleConfirmDelete}
                        onCancel={handleCancelDelete}
                    />
                </div>
            </div>
            <DomainDialog domain={props.trackedDomain.domain} handleClose={handleInfoClose} open={state.infoOpen} />
        </>
    );
};

export const trackedDomainWithPublicContent = (
    domainUse: TrackedDomainInGroupDTO,
    publicValue: boolean
): TrackedDomainInGroupDTO => {
    return {
        ...domainUse,
        public: publicValue,
        trackedGoals: domainUse.trackedGoals.map((goalInUse) =>
            goalInUse.public === publicValue || (publicValue && !containsGoalIndicators(goalInUse))
                ? goalInUse
                : trackedGoalWithPublicContent(goalInUse, publicValue)
        ),
    };
};

export const containsDomainIndicators = (domainUse: TrackedDomainInGroupDTO): boolean => {
    return domainUse.trackedGoals.some((goalUse) => containsGoalIndicators(goalUse));
};

const TrackedDomain: React.FC<TrackedDomainProps> = (props: TrackedDomainProps) => {
    const classes = useStyles();
    //== Init ===================================================================
    const handlePublicSwitch = () => {
        props.handlePublicSwitch(trackedDomainWithPublicContent(props.trackedDomain, !props.trackedDomain.public));
        return;
    };
    const handleContentPublicSwitch = (modifiedItem: TrackedGoalInGroupDTO) => {
        let domainUsePublic = props.trackedDomain.public;
        if (modifiedItem.public && !domainUsePublic) {
            domainUsePublic = true;
        } else if (
            !modifiedItem.public &&
            domainUsePublic &&
            props.trackedDomain.trackedGoals.every(
                (goalUse) => goalUse.goal.id === modifiedItem.goal.id || !goalUse.public
            )
        ) {
            domainUsePublic = false;
        }
        const newTrackedGoals = props.trackedDomain.trackedGoals.map((goalInUse) =>
            goalInUse.goal.id === modifiedItem.goal.id ? modifiedItem : goalInUse
        );
        props.handlePublicSwitch({...props.trackedDomain, public: domainUsePublic, trackedGoals: newTrackedGoals});
    };

    const isShiftingGoal =
        props.shiftingItemPosition !== null &&
        props.shiftingItemPosition.goalIndex !== null &&
        props.shiftingItemPosition.indicatorIndex === null;

    return (
        <>
            <TrackedDomainRow
                key={props.trackedDomain.id + "-row"}
                trackedDomain={props.trackedDomain}
                itemPosition={props.itemPosition}
                shiftingItemPosition={props.shiftingItemPosition}
                handleShiftStart={props.handleShiftStart}
                handleRemoveItem={props.handleRemoveItem}
                handlePublicSwitch={handlePublicSwitch}
            />
            {props.trackedDomain.trackedGoals
                .map((goalInUse: TrackedGoalInGroupDTO, index) => {
                    const position = {
                        domainIndex: props.itemPosition.domainIndex,
                        goalIndex: index,
                        indicatorIndex: null,
                    };
                    const isShiftingAdjacentGoal =
                        props.shiftingItemPosition &&
                        props.shiftingItemPosition.goalIndex !== null &&
                        props.shiftingItemPosition.domainIndex == props.itemPosition.domainIndex &&
                        (props.shiftingItemPosition.goalIndex == index ||
                            props.shiftingItemPosition.goalIndex + 1 == index);
                    return (
                        <>
                            <LibraryItemMoveTarget
                                key={goalInUse.id + "-move-target"}
                                className={classes.goalShiftTarget}
                                active={isShiftingGoal && !isShiftingAdjacentGoal}
                                onAction={() => props.handleShiftEnd(position)}
                            />
                            <TrackedGoal
                                key={goalInUse.id}
                                trackedGoal={goalInUse}
                                itemPosition={position}
                                shiftingItemPosition={props.shiftingItemPosition}
                                handleShiftStart={props.handleShiftStart}
                                handleShiftEnd={props.handleShiftEnd}
                                handleRemoveItem={props.handleRemoveItem}
                                handlePublicSwitch={handleContentPublicSwitch}
                            />
                        </>
                    );
                })
                .concat([
                    <LibraryItemMoveTarget
                        key={props.trackedDomain.id + "-end-move-target"}
                        className={classes.goalShiftTarget}
                        active={
                            isShiftingGoal &&
                            !(
                                props.shiftingItemPosition &&
                                props.shiftingItemPosition.goalIndex !== null &&
                                props.shiftingItemPosition.domainIndex == props.itemPosition.domainIndex &&
                                props.shiftingItemPosition.goalIndex + 1 == props.trackedDomain.trackedGoals.length
                            )
                        }
                        onAction={() =>
                            props.handleShiftEnd({
                                domainIndex: props.itemPosition.domainIndex,
                                goalIndex: props.trackedDomain.trackedGoals.length,
                                indicatorIndex: null,
                            })
                        }
                    />,
                ])}
        </>
    );
};

export default TrackedDomain;
