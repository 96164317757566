import csIntroductionManualChapter from "./CsIntroductionManualChapter";
import csAccountManualChapter from "./CsAccountManualChapter";
import csMunicipalityManualChapter from "./CsMunicipalityManualChapter";
import csOrganizationManualChapter from "./CsOrganizationManualChapter";
import csPublicManualChapter from "./CsPublicManualChapter";
import csAdminManualChapter from "./CsAdminManualChapter";
import manual, {Manual} from "../../model/Manual";

export const csManual: Manual = manual({
    language: "cs",
    title: "Smart City Compass: Manuál",
    chapters: [
        csIntroductionManualChapter,
        csAccountManualChapter,
        csMunicipalityManualChapter,
        csOrganizationManualChapter,
        csPublicManualChapter,
        csAdminManualChapter,
    ],
});

export default csManual;
