import React from "react";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
import {Redirect} from "react-router-dom";
// Material UI imports
import {Container} from "@material-ui/core";
// Local imports
import UnitForm from "./UnitForm";
// Project imports
import {LoadingBackdrop} from "../../common/Loading";
import {ErrorNotification} from "../../common/notifications";
import {UnitCreateDTO, UnitDTO} from "../../../models/library";
import ROUTES from "../../../routes/routes";
import {API, createApiConfig} from "../../../utils";
import {AxiosError, AxiosResponse} from "axios";

interface UnitNewState {
    unit: UnitDTO | null;
    unitCreate: UnitCreateDTO;
    name: string;
}

export const UnitNew: React.FC = () => {
    //== Init =================================================================
    const [t] = useTranslation("libraries");
    const {keycloak, initialized} = useKeycloak();
    const initState: UnitNewState = {
        unit: null,
        unitCreate: {
            abbreviation: "",
            name: "",
            description: "",
            formulaFromBase: "VAR(x)",
            formulaToBase: "VAR(x)",
            baseUnitUUID: null,
        },
        name: "init",
    };
    const [state, setState] = React.useState(initState);
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "saving") {
            API.post<UnitDTO>(`/units`, state.unitCreate, createApiConfig(keycloak, initialized))
                .then((res: AxiosResponse<UnitDTO>) => {
                    setState({...state, unit: res.data, name: "saved"});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [keycloak, initialized, state, setState]);
    //== Handlers =============================================================
    const handleChange = (unit: UnitCreateDTO): void => {
        setState({...state, unitCreate: unit});
    };
    const handleSubmit = (): void => {
        setState({...state, name: "saving"});
    };
    //== Render ===============================================================
    let appendix: JSX.Element | null = null;
    if (state.name === "saving") {
        appendix = <LoadingBackdrop />;
    }
    if (state.name === "failed") {
        appendix = <ErrorNotification message={t("definitions.units.notifications.create_fail")} />;
    }
    if (state.name === "saved") {
        return <Redirect to={ROUTES.unit.create(state.unit?.id ?? "unknown")} />;
    }
    return (
        <Container maxWidth="md">
            <h1>{t("definitions.units.new")}</h1>
            <UnitForm unit={state.unitCreate} onChange={handleChange} onSubmit={handleSubmit} />
            {appendix}
        </Container>
    );
};

export default UnitNew;
