import React from "react";
import {useTranslation} from "react-i18next";
// Material UI imports
import {Box, Button} from "@material-ui/core";
// Project imports
import {DashboardIndicatorsAddProps} from "./DashboardIndicatorsAddProps";
import DashboardIndicatorsAddFromGroupsDialog from "./DashboardIndicatorsAddFromGroupsDialog";
import {DashboardIndicatorsAddingIndicator} from "./DashboardIndicatorsAddingInterfaces";

const DashboardIndicatorsAdd: React.FC<DashboardIndicatorsAddProps> = (props: DashboardIndicatorsAddProps) => {
    const [t] = useTranslation(["trackedIndicators", "common"]);
    const [open, setOpen] = React.useState(false);
    //== Render =================================================================
    const onAdd = (trackedIndicators: DashboardIndicatorsAddingIndicator[]) => {
        setOpen(false);
        if (trackedIndicators.length > 0) props.onAdd(trackedIndicators);
    };
    const onCancel = () => {
        setOpen(false);
    };
    return (
        <Box>
            <Button
                variant="contained"
                color="primary"
                style={{marginBottom: 5}}
                onClick={() => {
                    setOpen(true);
                }}
            >
                {t("dashboard.ui.addIndicators")}
            </Button>
            <DashboardIndicatorsAddFromGroupsDialog {...props} onAdd={onAdd} onCancel={onCancel} open={open} />
        </Box>
    );
};

export default DashboardIndicatorsAdd;
