import React, {ReactElement, useState} from "react";
import {useTranslation} from "react-i18next";
// Material UI imports
// Project imports
import {OrganizationProfileDTO} from "../../../../models/organization";
import {useKeycloak} from "@react-keycloak/web";
import {API, createApiConfig} from "../../../../utils";
import {AxiosError} from "axios";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    MenuItemProps,
    useMediaQuery,
} from "@material-ui/core";
import {ErrorNotification} from "../../../common/notifications";
import {useTheme} from "@material-ui/core/styles";

interface AnonymousFullMembershipRequestControlProps {
    organizationProfile: OrganizationProfileDTO;

    children: ReactElement<MenuItemProps, typeof MenuItem>;
}

const AnonymousFullMembershipRequestControl: React.FC<AnonymousFullMembershipRequestControlProps> = (
    props: AnonymousFullMembershipRequestControlProps
) => {
    //== Init ===================================================================
    const theme = useTheme();
    const {keycloak, initialized} = useKeycloak();
    const [t] = useTranslation(["organizationMembers", "common"]);
    const isMunicipality = !!props.organizationProfile.organization.municipality?.id;
    const [state, setState] = useState({
        name: (isMunicipality ? "loading" : "loaded") as "loading" | "loaded" | "failed",
        canCreate: false,
        isOpen: false,
    });

    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<boolean>(
                "/full-membership-request/could-be-created-for-municipality/" +
                    props.organizationProfile.organization.municipality?.id,
                createApiConfig(keycloak, initialized)
            )
                .then((res) => {
                    setState({...state, name: "loaded", canCreate: res.data});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [props.organizationProfile.organization, state, setState]);
    const handleOpen = (): void => {
        setState({...state, isOpen: true});
    };
    const handleClose = (): void => {
        setState({...state, isOpen: false});
    };
    const handleRegister = (): void => {
        keycloak.register({redirectUri: document.documentURI});
    };
    console.log("[AnonymousFullMembershipRequestControl] state:");
    console.log(state);
    //== Render =================================================================
    let notification: JSX.Element | null = null;
    if (state.name === "failed") notification = <ErrorNotification message={t(`notifications.requests.create_fail`)} />;
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const dialog = (
        <Dialog open={state.isOpen} fullScreen={fullScreen} onClose={handleClose}>
            <DialogTitle id="responsive-dialog-title">{t("request.fullMembershipRequest")}</DialogTitle>
            <>
                <DialogContent>
                    <p>{t("ui.anonymousRequestCreationDialogInfo")}</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {t("common:actions.cancel")}
                    </Button>
                    <Button onClick={handleRegister} color="primary">
                        {t("common:auth.register")}
                    </Button>
                </DialogActions>
            </>
            {notification}
        </Dialog>
    );
    if (!state.canCreate || state.name === "failed") return dialog;

    return (
        <>
            {React.cloneElement(props.children, {
                onClick: handleOpen,
            })}
            {dialog}
        </>
    );
};

export default AnonymousFullMembershipRequestControl;
