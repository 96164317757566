export const ROUTES = {
    landing: {
        path: "/",
    },
    login: {
        path: "/prihlasit",
    },
    municipalities: {
        path: "/obce",
    },
    dashboard: {
        path: "/muj-compass",
    },
    profile: {
        path: "/profil/:personId",
        create: (personId: string): string => `/profil/${personId}`,
    },
    ownProfile: {
        path: "/profil",
    },
    users: {
        path: "/uzivatele",
    },
    people: {
        path: "/uzivatele/lide",
    },
    emails: {
        path: "/uzivatele/emaily",
    },
    emailVerification: {
        path: "/profil/emaily/:emailId/:code",
        create: (emailId: string, code: string): string => `/profil/emaily/${emailId}/${code}`,
    },
    person: {
        path: "/osoba/:personId",
        create: (personId: string): string => `/osoba/${personId}`,
    },
    organizations: {
        path: "/organizace",
        create: (): string => `/organizace`,
    },
    organization: {
        path: ["/organizace/:organizationId", "/obec/:organizationId"],
        create: (organizationId: string): string => `/organizace/${organizationId}`,
    },
    organizationPositions: {
        path: [
            "/organizace/:organizationId/pozice",
            "/obec/:organizationId/pozice",
            "/mesto/:organizationId/pozice",
            "/mestys/:organizationId/pozice",
        ],
        create: (organizationId: string): string => `/organizace/${organizationId}/pozice`,
    },
    organizationMembers: {
        path: [
            "/organizace/:organizationId/clenove",
            "/obec/:organizationId/clenove",
            "/mesto/:organizationId/clenove",
            "/mestys/:organizationId/clenove",
        ],
        create: (organizationId: string): string => `/organizace/${organizationId}/clenove`,
    },
    trackedIndicatorsEditor: {
        path: [
            "/obec/:organizationId/sledovane-indikatory/:trackedIndicatorGroupId/uprava",
            "/mesto/:organizationId/sledovane-indikatory/:trackedIndicatorGroupId/uprava",
            "/mestys/:organizationId/sledovane-indikatory/:trackedIndicatorGroupId/uprava",
        ],
        create: (organizationId: string, trackedIndicatorGroupId: string): string =>
            `/obec/${organizationId}/sledovane-indikatory/${trackedIndicatorGroupId}/uprava`,
    },
    trackedIndicatorsBrowser: {
        path: [
            "/obec/:organizationId/sledovane-indikatory/:trackedIndicatorGroupId",
            "/mesto/:organizationId/sledovane-indikatory/:trackedIndicatorGroupId",
            "/mestys/:organizationId/sledovane-indikatory/:trackedIndicatorGroupId",
        ],
        create: (organizationId: string, trackedIndicatorGroupId: string): string =>
            `/obec/${organizationId}/sledovane-indikatory/${trackedIndicatorGroupId}`,
    },
    trackedIndicatorSummary: {
        path: [
            "/obec/:organizationId/sledovane-indikatory/:trackedIndicatorGroupId/souhrn",
            "/mesto/:organizationId/sledovane-indikatory/:trackedIndicatorGroupId/souhrn",
            "/mestys/:organizationId/sledovane-indikatory/:trackedIndicatorGroupId/souhrn",
        ],
        create: (organizationId: string, trackedIndicatorGroupId: string): string =>
            `/obec/${organizationId}/sledovane-indikatory/${trackedIndicatorGroupId}/souhrn`,
    },
    trackedIndicatorGroups: {
        path: [
            "/obec/:organizationId/sledovane-indikatory",
            "/mesto/:organizationId/sledovane-indikatory",
            "/mestys/:organizationId/sledovane-indikatory",
        ],
        create: (organizationId: string): string => `/obec/${organizationId}/sledovane-indikatory`,
    },
    municipalityDashboard: {
        path: ["/obec/:organizationId/prehled", "/mesto/:organizationId/prehled", "/mestys/:organizationId/prehled"],
        create: (organizationId: string): string => `/obec/${organizationId}/prehled`,
    },
    tags: {
        path: "/stitky",
    },
    tagCategories: {
        path: "/stitky/kategorie",
    },
    cms: {
        path: "/cms",
    },
    cmsPageEdit: {
        path: "/cms/:webpageId",
        create: (webpageId: string): string => `/cms/${webpageId}`,
    },
    cmsPageView: {
        path: "/stranka/:webpageId",
        create: (webpageId: string): string => `/stranka/${webpageId}`,
    },
    organizationLibraries: {
        path: [
            "/organizace/:organizationId/indikatorove-sady",
            "/obec/:organizationId/indikatorove-sady",
            "/mesto/:organizationId/indikatorove-sady",
            "/mestys/:organizationId/indikatorove-sady",
        ],
        create: (organizationId: string): string => `/organizace/${organizationId}/indikatorove-sady`,
        createForOrg: (organizationId: string): string => `/organizace/${organizationId}/indikatorove-sady`,
        createForMun: (organizationId: string): string => `/obec/${organizationId}/indikatorove-sady`,
    },
    organizationLibraryNew: {
        path: [
            "/organizace/:organizationId/indikatorove-sady/nova",
            "/obec/:organizationId/indikatorove-sad/novay",
            "/mesto/:organizationId/indikatorove-sady/nova",
            "/mestys/:organizationId/indikatorove-sady/nova",
        ],
        create: (organizationId: string): string => `/organizace/${organizationId}/indikatorove-sady/nova`,
        createForOrg: (organizationId: string): string => `/organizace/${organizationId}/indikatorove-sady/nova`,
        createForMun: (organizationId: string): string => `/obec/${organizationId}/indikatorove-sady/nova`,
    },
    libraries: {
        path: "/indikatorove-sady",
    },
    libraryNew: {
        path: "/indikatorove-sady/nova",
    },
    librariesAdmin: {
        path: "/admin/indikatorove-sady",
    },
    libraryNewAdmin: {
        path: "/admin/indikatorove-sady/nova",
    },
    libraryAdmin: {
        path: "/admin/indikatorova-sada/:libraryId",
        create: (libraryId: string): string => `/admin/indikatorova-sada/${libraryId}`,
    },
    library: {
        path: "/indikatorova-sada/:libraryId",
        create: (libraryId: string): string => `/indikatorova-sada/${libraryId}`,
    },
    libraryEdit: {
        path: "/indikatorova-sada/:libraryId/upravit",
        create: (libraryId: string): string => `/indikatorova-sada/${libraryId}/upravit`,
    },
    libraryEditor: {
        path: "/indikatorova-sada/:libraryId/editor",
        create: (libraryId: string): string => `/indikatorova-sada/${libraryId}/editor`,
    },
    libraryClone: {
        path: "/indikatorova-sada/:libraryId/klonovat",
        create: (libraryId: string): string => `/indikatorova-sada/${libraryId}/klonovat`,
    },
    definitions: {
        path: "/definice",
    },
    dataSources: {
        path: "/definice/datove-zdroje",
    },
    dataSource: {
        path: "/definice/datove-zdroje/:dataSourceId",
        create: (dataSourceId: string): string => `/definice/datove-zdroje/${dataSourceId}`,
    },
    units: {
        path: "/definice/jednotky",
    },
    unit: {
        path: "/definice/jednotky/:unitId",
        create: (unitId: string): string => `/definice/jednotky/${unitId}`,
    },
    inputVariables: {
        path: "/definice/vstupni-promenne",
    },
    inputVariablesOverview: {
        path: "/vstupni-promenne",
    },
    inputVariable: {
        path: "/definice/vstupni-promenne/:inputVariableId",
        create: (inputVariableId: string): string => `/definice/vstupni-promenne/${inputVariableId}`,
    },
    valuesInput: {
        path: "/obec/:organizationId/zadani-hodnot",
        create: (organizationId: string): string => `/obec/${organizationId}/zadani-hodnot`,
    },
    valuesInputSummary: {
        path: "/obec/:organizationId/zadani-hodnot/souhrn",
        create: (organizationId: string): string => `/obec/${organizationId}/zadani-hodnot/souhrn`,
    },
    partners: {
        path: "/partneri",
    },
    contacts: {
        path: "/kontakt",
    },
    manual: {
        path: "/manual",
    },
};

export default ROUTES;
