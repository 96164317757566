import React from "react";
import {useTranslation} from "react-i18next";
import CopyToClipboard from "react-copy-to-clipboard";
import {Link} from "react-router-dom";
// Material UI imports
import {Fab, IconButton, Tooltip} from "@material-ui/core";
// Material UI icons
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import InfoIcon from "@material-ui/icons/Info";
import BackIcon from "@material-ui/icons/ArrowBack";
import CancelIcon from "@material-ui/icons/Cancel";
import VisibilityIcon from "@material-ui/icons/Visibility";
import FingerprintIcon from "@material-ui/icons/Fingerprint";
import PrintIcon from "@material-ui/icons/Print";
import RefreshIcon from "@material-ui/icons/Refresh";
import SaveIcon from "@material-ui/icons/Save";
import SendIcon from "@material-ui/icons/Send";
import HelpIcon from "@material-ui/icons/Help";
import AssignmentIcon from "@material-ui/icons/Assignment";
import LinkIcon from "@material-ui/icons/Link";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import BuildIcon from "@material-ui/icons/Build";
import DoneIcon from "@material-ui/icons/Done";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import InputIcon from "@material-ui/icons/Input";
// Project imports
import {InfoNotification} from "./notifications";
import CircularProgress from "@material-ui/core/CircularProgress";

/* eslint-disable no-unused-vars */

/* eslint-disable @typescript-eslint/no-unused-vars */
interface ButtonProps {
    onClick: React.MouseEventHandler;
    size?: "small" | "medium";
    disabled?: boolean;
}

interface XLinkButtonProps {
    title: string;
    to: string;
    target?: string;
    size?: "small" | "medium";
    icon: React.ReactNode;
    disabled?: boolean;
}

interface LinkButtonProps {
    to: string;
    target?: string;
    size?: "small" | "medium";
    disabled?: boolean;
}

interface CopyButtonProps {
    text: string;
    size?: "small" | "medium";
}

interface CustomActionButtonProps {
    onClick: React.MouseEventHandler;
    title: string;
    size?: "small" | "medium";
    icon: React.ReactNode;
    disabled?: boolean;
}

interface LoadingActionButton {
    title: string;
}

interface ActionButtonProps {
    onClick: React.MouseEventHandler;
    xkey: string;
    size?: "small" | "medium";
    icon: React.ReactNode;
    disabled?: boolean;
}

interface CopyActionButtonProps {
    text: string;
    xkey: string;
    notify: boolean;
    size?: "small" | "medium";
    icon: React.ReactNode;
}

export const LoadingActionButton: React.FC<LoadingActionButton> = (props: LoadingActionButton) => {
    return (
        <Tooltip title={props.title}>
            <IconButton aria-label={props.title}>
                <CircularProgress size={24} />
            </IconButton>
        </Tooltip>
    );
};

export const CustomActionButton: React.FC<CustomActionButtonProps> = (props: CustomActionButtonProps) => {
    const disabled = props.disabled;
    const button = (
        <IconButton onClick={props.onClick} aria-label={props.title} size={props.size} disabled={disabled}>
            {props.icon}
        </IconButton>
    );
    return disabled ? button : <Tooltip title={props.title}>{button}</Tooltip>;
};

const ActionButton: React.FC<ActionButtonProps> = (props: ActionButtonProps) => {
    const [t] = useTranslation();
    return <CustomActionButton title={t(`actions.${props.xkey}`)} {...props} />;
};

export const InfoActionButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    return <ActionButton xkey="info" icon={<InfoIcon />} {...props} />;
};

export const OpenInfoActionButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    return <ActionButton xkey="openinfo" icon={<InfoIcon />} {...props} />;
};

export const BackActionButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    return <ActionButton xkey="back" icon={<BackIcon />} {...props} />;
};

export const CancelActionButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    return <ActionButton xkey="back" icon={<CancelIcon />} {...props} />;
};

export const DeleteActionButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    return <ActionButton xkey="delete" icon={<DeleteIcon />} {...props} />;
};

export const MoveUpActionButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    return <ActionButton xkey="moveUp" icon={<KeyboardArrowUpIcon />} {...props} />;
};

export const MoveDownActionButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    return <ActionButton xkey="moveDown" icon={<KeyboardArrowDownIcon />} {...props} />;
};

export const AddCircleActionButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    const [t] = useTranslation();
    const title = t(`actions.create`);
    return (
        <Tooltip title={title} key="add">
            <Fab color="primary" size="medium" onClick={props.onClick} aria-label={title}>
                <AddIcon />
            </Fab>
        </Tooltip>
    );
};

export const AddActionButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    return <ActionButton xkey="add" icon={<AddIcon />} {...props} />;
};

export const CreateActionButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    return <ActionButton xkey="create" icon={<AddIcon />} {...props} />;
};

export const AcceptActionButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    return <ActionButton xkey="done" icon={<DoneIcon />} {...props} />;
};

export const DeclineActionButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    return <ActionButton xkey="decline" icon={<NotInterestedIcon />} {...props} />;
};

export const ExpandLessActionButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    return <ActionButton xkey="less" icon={<ExpandLessIcon />} {...props} />;
};

export const ExpandMoreActionButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    return <ActionButton xkey="more" icon={<ExpandMoreIcon />} {...props} />;
};

export const EditActionButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    return <ActionButton xkey="edit" icon={<EditIcon />} {...props} />;
};

export const RefreshActionButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    return <ActionButton xkey="refresh" icon={<RefreshIcon />} {...props} />;
};

export const SaveActionButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    return <ActionButton xkey="save" icon={<SaveIcon />} {...props} />;
};

export const SendActionButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    return <ActionButton xkey="send" icon={<SendIcon />} {...props} />;
};

export const SwapVerticallyActionButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    return <ActionButton xkey="move" icon={<SwapVertIcon />} {...props} />;
};

export const MoveHereActionButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    return <ActionButton xkey="moveHere" icon={<InputIcon />} {...props} />;
};

export const ManualActionButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    return <ActionButton xkey="manual" icon={<HelpIcon />} {...props} />;
};

export const PrintActionButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    return <ActionButton xkey="print" icon={<PrintIcon />} {...props} />;
};

export const LinkActionButton: React.FC<XLinkButtonProps> = (props: XLinkButtonProps) => {
    return (
        <Tooltip title={props.title}>
            <IconButton
                component={Link}
                to={props.to}
                target={props.target}
                size={props.size}
                disabled={props.disabled}
            >
                {props.icon}
            </IconButton>
        </Tooltip>
    );
};

export const AddCircleLinkActionButton: React.FC<LinkButtonProps> = (props: LinkButtonProps) => {
    const [t] = useTranslation();
    const title = t(`actions.create`);
    return (
        <Tooltip title={title} key="add">
            <Fab color="primary" size="medium" component={Link} to={props.to}>
                <AddIcon />
            </Fab>
        </Tooltip>
    );
};

export const OpenInNewLinkActionButton: React.FC<LinkButtonProps> = (props: LinkButtonProps) => {
    const [t] = useTranslation();
    return <LinkActionButton title={t("actions.openInNew")} icon={<OpenInNewIcon />} {...props} />;
};

export const DetailLinkActionButton: React.FC<LinkButtonProps> = (props: LinkButtonProps) => {
    const [t] = useTranslation();
    return <LinkActionButton title={t("actions.detail")} icon={<InfoIcon />} {...props} />;
};

export const BackLinkActionButton: React.FC<LinkButtonProps> = (props: LinkButtonProps) => {
    const [t] = useTranslation();
    return <LinkActionButton title={t("actions.back")} icon={<BackIcon />} {...props} />;
};

export const EditLinkActionButton: React.FC<LinkButtonProps> = (props: LinkButtonProps) => {
    const [t] = useTranslation();
    return <LinkActionButton title={t("actions.edit")} icon={<EditIcon />} {...props} />;
};

export const EditorLinkActionButton: React.FC<LinkButtonProps> = (props: LinkButtonProps) => {
    const [t] = useTranslation();
    return <LinkActionButton title={t("actions.editor")} icon={<BuildIcon />} {...props} />;
};

export const VisitLinkActionButton: React.FC<LinkButtonProps> = (props: LinkButtonProps) => {
    const [t] = useTranslation();
    return <LinkActionButton title={t("actions.visit")} icon={<VisibilityIcon />} {...props} />;
};

export const ShowLinkActionButton: React.FC<LinkButtonProps> = (props: LinkButtonProps) => {
    const [t] = useTranslation();
    return <LinkActionButton title={t("actions.show")} icon={<VisibilityIcon />} {...props} />;
};

const CopyActionButton: React.FC<CopyActionButtonProps> = (props: CopyActionButtonProps) => {
    //== Init ===================================================================
    const [t] = useTranslation();
    const [copied, setCopied] = React.useState(false);
    //== Handlers================================================================
    const handleCopy = (): void => {
        setCopied(true);
    };
    //== Render =================================================================
    const msg = `${t("actions.copied_clipboard")}: ${props.text}`;
    let notification = null;
    if (copied && props.notify) {
        notification = <InfoNotification message={msg} />;
    }
    return (
        <React.Fragment>
            <CopyToClipboard text={props.text}>
                <ActionButton onClick={handleCopy} {...props} />
            </CopyToClipboard>
            {notification}
        </React.Fragment>
    );
};

export const CopyButton: React.FC<CopyButtonProps> = (props: CopyButtonProps) => {
    return <CopyActionButton xkey="copy_clipboard" notify={true} icon={<AssignmentIcon />} {...props} />;
};

export const IdCopyButton: React.FC<CopyButtonProps> = (props: CopyButtonProps) => {
    return <CopyActionButton xkey="copy_id" notify={true} icon={<FingerprintIcon />} {...props} />;
};

export const URLCopyButton: React.FC<CopyButtonProps> = (props: CopyButtonProps) => {
    return <CopyActionButton xkey="copy_url" notify={true} icon={<LinkIcon />} {...props} />;
};
