import React from "react";
import {Box} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {InfoActionButton} from "../../../../common/buttons";
import {GoalDialog} from "../../../../values/dialogs/GoalDialog";
import {DashboardIndicatorsAddingGoal, DashboardIndicatorsAddingIndicator} from "./DashboardIndicatorsAddingInterfaces";
import DashboardIndicatorsIndicatorAdd from "./DashboardIndicatorsIndicatorAdd";
import {useTranslation} from "react-i18next";
import {TrackedIndicatorInGroupDTO} from "../../../../../models/trackedIndicators";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        row: {
            borderWidth: 0,
            borderStyle: "solid",
            borderBottomWidth: 1,
            borderColor: "rgb(145,145,145)",
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(0.5),
            marginLeft: theme.spacing(4),
            paddingLeft: theme.spacing(1),
            display: "flex",
        },
        alreadyAdded: {
            backgroundColor: "rgb(215,250,215)",
        },
        selectionCheckbox: {},
        itemName: {
            fontSize: "120%",
            fontWeight: "bold",
            marginTop: theme.spacing(1.5),
        },
        infoButton: {
            flexGrow: 1,
        },
    })
);

interface DashboardIndicatorsGoalAddProps {
    index: number; //TODO temporary solution until libraryXXXUse are not added to LibraryDTO,
    trackedGoal: DashboardIndicatorsAddingGoal;

    handleChecked(trackedIndicator: TrackedIndicatorInGroupDTO): void;

    handleUnchecked(trackedIndicator: TrackedIndicatorInGroupDTO): void;
}

const DashboardIndicatorsGoalAdd: React.FC<DashboardIndicatorsGoalAddProps> = (
    props: DashboardIndicatorsGoalAddProps
) => {
    const classes = useStyles();
    const [t] = useTranslation("trackedIndicators");
    const [state, setState] = React.useState({infoOpen: false});
    //== Handlers ===============================================================
    const handleInfoOpen = (): void => {
        setState({...state, infoOpen: true});
    };
    const handleInfoClose = (): void => {
        setState({...state, infoOpen: false});
    };
    return (
        <Box>
            <Box className={classes.row}>
                <Box className={classes.itemName}>{t("library.goal") + ": " + props.trackedGoal.goal.name}</Box>
                <Box className={classes.infoButton}>
                    <InfoActionButton onClick={handleInfoOpen} />
                </Box>
            </Box>
            <GoalDialog goal={props.trackedGoal.goal} handleClose={handleInfoClose} open={state.infoOpen} />
            <Box>
                {props.trackedGoal.trackedIndicators.map((indicator: DashboardIndicatorsAddingIndicator, index) => (
                    <DashboardIndicatorsIndicatorAdd
                        key={indicator.id}
                        index={index}
                        trackedIndicator={indicator}
                        handleChecked={props.handleChecked}
                        handleUnchecked={props.handleUnchecked}
                    />
                ))}
            </Box>
        </Box>
    );
};

export default DashboardIndicatorsGoalAdd;
