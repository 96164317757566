import React from "react";
import {Box, Divider, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {DashboardWidgetProps} from "./index";
import ReceivedMembershipInvitations from "../../organization/members/Members/ReceivedMembershipInvitations";

const UserMembershipInvitationsWidget: React.FC<DashboardWidgetProps> = (props: DashboardWidgetProps) => {
    const [t] = useTranslation("organizationMembers");
    return (
        <Box style={{paddingBottom: "0.5em"}}>
            <Typography variant="h5" component="h2" style={{marginBottom: "1.5em"}}>
                {t("position.membershipInvitations")}
            </Typography>
            <Divider />
            <ReceivedMembershipInvitations />
        </Box>
    );
};

export default UserMembershipInvitationsWidget;
