import React, {useRef} from "react";
import {Link, useParams} from "react-router-dom";
import {API, createApiConfig, DateUtils} from "../../utils";
import {AxiosError} from "axios";
import {useKeycloak} from "@react-keycloak/web";
import Loading, {LoadingBackdrop} from "../common/Loading";
import {CommunicationError, NotFound} from "../common/errors";
import {Box, Button, Container} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {
    InputValueCreateDTO,
    InputValueDTO,
    InputValueResultDTO,
    InputVariableTaskDTO,
    ValuesInputTaskDTO,
} from "../../models/values";
import {
    CustomActionButton,
    ExpandLessActionButton,
    ExpandMoreActionButton,
    InfoActionButton,
    LinkActionButton,
} from "../common/buttons";
import {ROUTES} from "../../routes/routes";
import {InputValueForm} from "./InputValueForm";
import {ErrorNotification, InfoNotification, SuccessNotification} from "../common/notifications";
import {InputVariableDialog} from "./dialogs/InputVariableDialog";
import {InputValueHistory} from "./InputValueHistory";
import {defaultPalette} from "../../styles";
import WorkingSVG from "../../graphics/undraw/factory_dy0a.svg";
import BusinessIcon from "@material-ui/icons/Business";
import TimelineIcon from "@material-ui/icons/Timeline";
import WarningRounded from "@material-ui/icons/WarningRounded";
import {
    adminOrganizationPermissions,
    anonymousOrganizationPermissions,
    OrganizationPermissionsDTO,
} from "../../models/members";
import {isAdmin, isUser} from "../../utils/auth";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {PageHeader} from "../common/headers";
import RefreshIcon from "@material-ui/icons/Refresh";
import {BreadcrumbItem, BreadcrumbsRow} from "../common/breadcrumbs";
import AssessmentIcon from "@material-ui/icons/Assessment";
import {TagDTO} from "../../models/admin";
import {
    filterInputVariables,
    InputVariablesFilter,
    InputVariablesFilterContainer,
} from "../definitions/inputvariables/overview/InputVariablesFilter";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        withInfo: {
            display: "inline-block",
        },
        formRow: {
            margin: theme.spacing(1),
        },
        formButton: {
            display: "flex",
            justifyContent: "flex-end",
            minWidth: "14em",
        },
        editButtonWrapper: {
            display: "flex",
            justifyContent: "flex-end",
        },
        editButton: {
            marginLeft: "0.5em",
        },
    })
);

interface ValueInputProps {
    task: InputVariableTaskDTO;
    isOutdated: boolean;
}

interface ValueInputState {
    name: "saved" | "saving";
    notify: "created" | "failed" | null;
    form: boolean;
    history: boolean;
    info: boolean;
    updates: number;
    value: InputValueCreateDTO;
    newValue?: InputValueDTO;
    isOutdated: boolean;
}

const isOutdated = (task: InputVariableTaskDTO, lastGathered: Date | null) => {
    if (!lastGathered) return false;
    if (!task.inputVariable.periods.length)
        return lastGathered < new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate());
    return task.inputVariable.periods.every(
        (period) =>
            lastGathered <
            new Date(
                new Date().getFullYear() -
                    (!period.repeatYear && !period.repeatMonth && !period.repeatDay ? 1 : period.repeatYear),
                new Date().getMonth() - period.repeatMonth,
                new Date().getDate() - period.repeatDay
            )
    );
};

export const ValueInput: React.FC<ValueInputProps> = (props: ValueInputProps) => {
    //== Init =================================================================
    const classes = useStyles();
    const defaultDate: string = DateUtils.todayDateString();
    const defaultValue: InputValueCreateDTO = {
        gatheredAt: defaultDate,
        value: props.task.inputVariable.options.length > 0 ? props.task.inputVariable.options[0].value.value : 0,
        unitUUID: props.task.preferredUnit?.id ?? null,
        note: "",
        municipalityInputVariableUUID: props.task.municipalityInputVariableUUID,
    };
    const initState: ValueInputState = {
        name: "saved",
        notify: null,
        form: false,
        history: false,
        info: false,
        value: defaultValue,
        updates: 0,
        isOutdated: props.isOutdated,
    };
    const [state, setState] = React.useState(initState);
    const {keycloak, initialized} = useKeycloak();
    const [t] = useTranslation(["values", "common"]);
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "saving") {
            API.post<InputValueResultDTO>(`/values-input`, state.value, createApiConfig(keycloak, initialized))
                .then((res) => {
                    setState({
                        ...state,
                        name: "saved",
                        notify: "created",
                        form: false,
                        history: true,
                        value: defaultValue,
                        newValue: res.data.value,
                        updates: state.updates + 1,
                        isOutdated: isOutdated(props.task, DateUtils.stringToDate(res.data.value.gatheredAt)),
                    });
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "saved", notify: "failed"});
                });
        }
    }, [props, state, setState, keycloak, initialized, defaultValue]);
    //== Handlers =============================================================
    const handleAddValue = (): void => {
        setState({...state, form: true, notify: null});
    };
    const handleSendValue = (inputValue: InputValueCreateDTO): void => {
        setState({...state, value: inputValue, name: "saving", notify: null});
    };
    const handleCancelValue = (): void => {
        setState({...state, form: false, notify: null});
    };
    const handleExpand = (): void => {
        setState({...state, history: true, notify: null});
    };
    const handleCollapse = (): void => {
        setState({...state, history: false, notify: null});
    };
    const handleInfoOpen = (): void => {
        setState({...state, info: true, notify: null});
    };
    const handleInfoClose = (): void => {
        setState({...state, info: false, notify: null});
    };
    //== Render ===============================================================
    const notifications: JSX.Element[] = [];
    if (state.notify === "created") {
        notifications.push(
            <SuccessNotification
                key={`created_${state.newValue?.id}`}
                message={t("values_input.notifications.created")}
            />
        );
    }
    if (state.notify === "failed") {
        notifications.push(<ErrorNotification key="failed" message={t("values_input.notifications.failed")} />);
    }
    /*
    console.log("[ValuesInput] name: ");
    console.log(props.task.inputVariable.name);
    console.log("[ValuesInput] periods: ");
    console.log(props.task.inputVariable.periods);
    console.log("[ValuesInput] minValues: ");
    console.log(
        props.task.inputVariable.periods.map(
            (period) =>
                new Date(
                    new Date().getFullYear() -
                        (!period.repeatYear && !period.repeatMonth && !period.repeatDay ? 1 : period.repeatYear),
                    new Date().getMonth() - period.repeatMonth,
                    new Date().getDate() - period.repeatDay
                )
        )
    );
    */
    return (
        <Box borderBottom={2} borderColor="primary.main" style={{borderColor: defaultPalette.primary.main}}>
            <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                style={{paddingTop: "0.5em", paddingBottom: "0,5em"}}
            >
                <Box>
                    {state.history ? (
                        <ExpandLessActionButton key="less" onClick={handleCollapse} />
                    ) : (
                        <ExpandMoreActionButton key="more" onClick={handleExpand} />
                    )}
                </Box>
                <Box>
                    <h3 className={classes.withInfo}>{props.task.inputVariable.name}</h3>
                </Box>
                <Box flexGrow="1">
                    <InfoActionButton onClick={handleInfoOpen} />
                </Box>
                <Box className={"classes.outdatedWarning"}>
                    {state.isOutdated ? (
                        <Box title={t("values_input.outdated")}>
                            <WarningRounded />
                        </Box>
                    ) : null}
                </Box>
                <Box className={classes.formButton}>
                    {state.form ? (
                        <Button variant="outlined" color="primary" onClick={handleCancelValue}>
                            {t("values_input.hideForm")}
                        </Button>
                    ) : (
                        <Button variant="outlined" color="primary" onClick={handleAddValue}>
                            {t("values_input.addValue")}
                        </Button>
                    )}
                </Box>
            </Box>
            {state.form && (
                <Box className={classes.formRow}>
                    <InputValueForm
                        disabled={state.name === "saving"}
                        inputVariable={props.task.inputVariable}
                        municipalityInputVariableUUID={props.task.municipalityInputVariableUUID}
                        preferredUnit={props.task.preferredUnit}
                        possibleUnits={props.task.possibleUnits}
                        value={state.value}
                        onSubmit={handleSendValue}
                    />
                </Box>
            )}
            {state.history && (
                <InputValueHistory
                    key={`${props.task.municipalityInputVariableUUID}-history-${state.updates}`}
                    task={props.task}
                    newValue={state.newValue}
                />
            )}
            {notifications}
            <InputVariableDialog
                inputVariable={props.task.inputVariable}
                open={state.info}
                handleClose={handleInfoClose}
            />
        </Box>
    );
};

export interface ValuesInputState {
    name: "loading" | "loaded" | "refreshing" | "refreshed" | "failed" | "not_found";
    task: ValuesInputTaskDTO | null;
    filteredVariableTasks: InputVariableTaskDTO[];
    filter: InputVariablesFilterContainer;
    allTags: TagDTO[];
    permissions: OrganizationPermissionsDTO;
}

interface ValuesInputProps {
    organizationId: string;
    taskUrl: string;
    simpleLoadingIndicator?: boolean;

    onTaskLoad?(task: ValuesInputTaskDTO, onRefresh: () => void): void;
}

interface TaskWithOrderScore {
    task: InputVariableTaskDTO;
    order: number;
}

export const ValuesInput: React.FC<ValuesInputProps> = (props: ValuesInputProps) => {
    //== Init =================================================================
    const {organizationId, taskUrl} = props;
    const initState: ValuesInputState = {
        name: "loading",
        task: null,
        filteredVariableTasks: [],
        filter: {
            name: "",
            tags: [],
            tagIds: new Set<string>(),
        },
        allTags: [],
        permissions: anonymousOrganizationPermissions(organizationId),
    };
    const [state, setState] = React.useState(initState);
    const {keycloak, initialized} = useKeycloak();
    const [t] = useTranslation(["values", "common"]);
    //== Handlers =============================================================
    const handleRefresh = (): void => {
        setState({...state, name: "refreshing"});
    };

    const filteredTasks = (
        filter: InputVariablesFilterContainer,
        tasks: InputVariableTaskDTO[]
    ): InputVariableTaskDTO[] => {
        const filteredInputVariables = filterInputVariables(
            tasks.map((task) => task.inputVariable),
            filter
        );
        return tasks.filter((task) => filteredInputVariables.includes(task.inputVariable));
    };
    const handleChangeFilter = (filter: InputVariablesFilterContainer) => {
        setState({
            ...state,
            filteredVariableTasks: state.task ? filteredTasks(filter, state.task.tasks) : [],
            filter: filter,
        });
    };
    //== Effects ==============================================================
    const timeOfLastRequest = useRef(Date.now());
    React.useEffect(() => {
        if (state.name === "loading" || state.name === "refreshing") {
            const timeOfThisRequest = Date.now();
            timeOfLastRequest.current = timeOfThisRequest;
            API.get<ValuesInputTaskDTO>(taskUrl, createApiConfig(keycloak, initialized))
                .then((res) => {
                    if (timeOfThisRequest != timeOfLastRequest.current) return;
                    const nextStateName = state.name === "loading" ? "loaded" : "refreshed";
                    const inputTask: ValuesInputTaskDTO = {
                        ...res.data,
                        tasks: res.data.tasks
                            .map<TaskWithOrderScore>((task, index) => {
                                return {
                                    task: task,
                                    order:
                                        index +
                                        (!task.lastGathered ||
                                        isOutdated(task, DateUtils.stringToDate(task.lastGathered))
                                            ? 0
                                            : res.data.tasks.length),
                                };
                            })
                            .sort((a, b) => {
                                return a.order - b.order;
                            })
                            .map((taskWithOrder) => taskWithOrder.task),
                    };
                    const allTags = [...new Set(inputTask.tasks.flatMap((task) => task.inputVariable.tags))];
                    const allTagIds = allTags.map((tag) => tag.id);
                    const filteredTags = state.filter.tags.filter((filteredTag) => allTagIds.includes(filteredTag.id));
                    const nextState: ValuesInputState = {
                        ...state,
                        name: nextStateName,
                        task: inputTask,
                        allTags: allTags,
                        filteredVariableTasks: filteredTasks(state.filter, inputTask.tasks),
                        filter: {
                            ...state.filter,
                            tags: filteredTags,
                            tagIds: new Set(filteredTags.map((tag) => tag.id)),
                        },
                    };
                    if (timeOfThisRequest != timeOfLastRequest.current) return;
                    if (props.onTaskLoad) props.onTaskLoad(inputTask, handleRefresh);
                    if (!isUser(keycloak)) {
                        setState({...nextState, permissions: anonymousOrganizationPermissions(organizationId)});
                    } else if (isAdmin(keycloak)) {
                        setState({...nextState, permissions: adminOrganizationPermissions(organizationId)});
                    } else {
                        API.get<OrganizationPermissionsDTO>(
                            `/organization-permissions/${organizationId}/current-user`,
                            createApiConfig(keycloak, initialized)
                        ).then((permissionsRes) => {
                            if (timeOfThisRequest != timeOfLastRequest.current) return;
                            setState({...nextState, permissions: permissionsRes.data});
                        });
                    }
                })
                .catch((err) => {
                    if (timeOfThisRequest != timeOfLastRequest.current) return;
                    if (err?.response?.status === 404) {
                        setState({...state, name: "not_found"});
                    } else {
                        setState({...state, name: "failed"});
                    }
                });
        }
    }, [state, setState, organizationId, keycloak, initialized]);
    //== Permissions ==========================================================
    // TODO: handle more permissions for input values
    //== Render ===============================================================

    if (state.name === "loading" || state.name === "refreshing") {
        return props.simpleLoadingIndicator ? <Loading /> : <LoadingBackdrop />;
    }
    if (state.name === "not_found") {
        return <NotFound />;
    }
    if (state.name === "failed") {
        return <CommunicationError />;
    }
    if (state.task !== null) {
        return (
            <>
                <InputVariablesFilter
                    filter={state.filter}
                    onChange={handleChangeFilter}
                    possibleTags={state.allTags}
                />

                {state.filteredVariableTasks.map((t) => (
                    <ValueInput
                        key={t.municipalityInputVariableUUID}
                        isOutdated={!t.lastGathered || isOutdated(t, DateUtils.stringToDate(t.lastGathered))}
                        task={t}
                    />
                ))}
                {state.filteredVariableTasks.length === 0 && (
                    <Box style={{textAlign: "center"}}>
                        <h2>{t("values_input.no_tasks")}</h2>
                        <img src={WorkingSVG} style={{maxWidth: "40em", margin: "3em"}} alt="no-input-values" />
                    </Box>
                )}
                {state.name === "refreshed" && <InfoNotification message={t("values_input.notifications.refreshed")} />}
            </>
        );
    }
    return <CommunicationError />;
};

interface MunicipalityParams {
    organizationId: string;
}

export const MunicipalityValuesInput: React.FC = () => {
    //== Init =================================================================
    const {organizationId} = useParams<MunicipalityParams>();
    const [t] = useTranslation(["values", "common"]);
    const [state, setState] = React.useState({
        task: null as ValuesInputTaskDTO | null,
        handleRefresh: () => {
            /* do nothing by default */
        },
    });
    const classes = useStyles();

    //== Handlers =============================================================
    function handleTaskLoad(task: ValuesInputTaskDTO, handleRefresh: () => void) {
        setState({task, handleRefresh});
    }

    //== Render ===============================================================
    return (
        <Container>
            <BreadcrumbsRow>
                <BreadcrumbItem
                    name={state.task?.municipality.name || t("organization:organization.municipality")}
                    route={ROUTES.organization.create(organizationId)}
                />
                <BreadcrumbItem name={t("actions.valuesInput")} />
            </BreadcrumbsRow>
            <PageHeader title={t("values_input.title", {municipality: state.task?.municipality.name || ""})}>
                <LinkActionButton
                    title={t("actions.organization")}
                    to={ROUTES.organization.create(organizationId)}
                    icon={<BusinessIcon />}
                />
                <LinkActionButton
                    title={t("actions.trackedIndicatorsBrowser")}
                    to={ROUTES.trackedIndicatorGroups.create(organizationId)}
                    icon={<TimelineIcon />}
                />
                <CustomActionButton title={t("actions.refresh")} onClick={state.handleRefresh} icon={<RefreshIcon />} />
            </PageHeader>
            <Box className={classes.editButtonWrapper}>
                <Button
                    component={Link}
                    to={ROUTES.valuesInputSummary.create(organizationId)}
                    variant="contained"
                    className={classes.editButton}
                    startIcon={<AssessmentIcon />}
                >
                    {t("actions.inputValuesSummary")}
                </Button>
            </Box>
            <ValuesInput
                organizationId={organizationId}
                taskUrl={`/values-input/task/${organizationId}`}
                onTaskLoad={handleTaskLoad}
            />
        </Container>
    );
};

export default MunicipalityValuesInput;
