import React, {useEffect, useState} from "react";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
// Project imports
import API, {createApiConfig} from "../../../utils/API";
import {AxiosError} from "axios";
import Loading from "../../common/Loading";
import {ServerCommunicationAlert} from "../../common/errors";
import {
    ContactPersonCreateDTO,
    ContactPersonDTO,
    municipalityOrganizationType,
    MunicipalityType,
    municipalityTypes,
    nonMunicipalityOrganizationTypes,
    OrganizationProfileDTO,
    OrganizationType,
    organizationTypes,
    SimpleMunicipalityDTO,
} from "../../../models/organization";
import SccLogo from "../../../graphics/logo/SCC_favicon_COL.png";
// Material-UI imports
import {
    Box,
    Checkbox,
    Container,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    Link,
    MenuItem,
    MenuList,
    Select,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
} from "@material-ui/core";
import {createStyles, makeStyles, Theme, withStyles} from "@material-ui/core/styles";
// Material-UI icons imports
import ListItemIcon from "@material-ui/core/ListItemIcon";
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import PeopleIcon from "@material-ui/icons/PeopleAlt";
import KeyboardIcon from "@material-ui/icons/Keyboard";
import TimelineIcon from "@material-ui/icons/Timeline";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ContactMailIcon from "@material-ui/icons/ContactMail";

// Graphics
import MunicipalitySelect from "./MunicipalitySelect";
import {ROUTES} from "../../../routes/routes";
import {Link as ReactLink} from "react-router-dom";
import {OrganizationPermissionsDTO} from "../../../models/members";
import {UploadableImage} from "../../common/images";
import {ImageDTO} from "../../../models/images";
import {isAdmin, isUser} from "../../../utils/auth";
import ContactPersonFormPart from "./ContactPersonFormPart";
import MapyCzMap from "./MapyCzMap/MapyCzMap";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import CancelIcon from "@material-ui/icons/Cancel";
import FullMembershipRequestControl from "../members/Members/FullMembershipRequestControl";
import AnonymousFullMembershipRequestControl from "../members/Members/AnonymousFullMembershipRequestControl";
import {ManualSidebar} from "../../../manual/components/ManualSidebar";
import {csPublicProfileSection} from "../../../manual/content/cs/CsPublicManualChapter";
import {csMunicipalityProfileSection} from "../../../manual/content/cs/CsMunicipalityManualChapter";
import {csOrganizationProfileSection} from "../../../manual/content/cs/CsOrganizationManualChapter";

export interface OrganizationProfileInfoProps {
    organizationProfile: OrganizationProfileDTO;
    editFirst?: boolean;
    organizationPermissions: OrganizationPermissionsDTO;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            "& > *": {
                margin: theme.spacing(1),
            },
        },
        small: {
            width: theme.spacing(3),
            height: theme.spacing(3),
        },
        large: {
            width: theme.spacing(20),
            height: theme.spacing(20),
        },
        spaced: {
            margin: theme.spacing(5),
        },
        spaceAfter: {
            marginBottom: theme.spacing(2),
        },
        organizationContent: {
            marginTop: theme.spacing(7),
        },
        organizationInfo: {
            width: "100%",
            marginBottom: theme.spacing(2),
        },
        organizationName: {
            margin: 0,
            marginBottom: theme.spacing(1),
        },
        nameDivider: {
            marginBottom: theme.spacing(2),
        },
        minTableCell: {
            width: "1%",
            whiteSpace: "nowrap",
            border: "0",
        },
        noBorder: {
            border: "0",
        },
        contactPersonCell: {
            border: 0,
            paddingLeft: 6,
            paddingTop: 0,
        },
        contactPerson: {
            padding: 10,
            verticalAlign: "top",
            backgroundColor: "#f2f2f2",
            border: "1px solid #dcdcdc",
        },
        contactPersonName: {
            fontWeight: "bold",
            textTransform: "uppercase",
        },
        contactPersonRole: {},
        contactPersonContacts: {
            marginTop: theme.spacing(3),
        },
        contactPersonContact: {},
        leftPanel: {
            width: "16.25em",
            maxWidth: "100%",
            paddingBottom: theme.spacing(3),
        },
        logoWrapper: {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            width: "100%",
            maxWidth: 500,
        },
        logo: {
            width: "100%",
        },
        photoWrapper: {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            width: "100%",
            maxWidth: 500,
        },
        photo: {
            width: "100%",
        },
        profileMenuItemContent: {
            width: "100%",
            height: "100%",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 16,
            paddingRight: 16,
            display: "flex",
            alignItems: "center",
        },
    })
);
const menuItemStyle = {
    backgroundColor: "#b1dcf5",
    borderColor: "#277db3",
    borderWidth: 1,
    borderStyle: "solid",
    marginTop: -1,
};
const activeMenuItemStyle = {
    ...menuItemStyle,
    backgroundColor: "#277db3",
    color: "#ffffff",
};
const ProfileMenuItem = withStyles({
    root: {
        ...menuItemStyle,

        padding: 0,

        "&$selected, &$selected:active, &$selected:hover, &$selected:focus, &:active, &:hover, &:focus": {
            ...activeMenuItemStyle,
            "& svg": {
                fill: activeMenuItemStyle.color,
            },
        },

        "& svg": {
            fill: menuItemStyle.borderColor,
        },

        "& a": {
            textDecoration: "inherit",
            color: "inherit",
        },
    },
    selected: {},
})(MenuItem);
const ProfileMenuItemIcon = withStyles({
    root: {
        minWidth: 36,
    },
})(ListItemIcon);

export type FormValidationType =
    | "min"
    | "more"
    | "max"
    | "less"
    | "required"
    | "maxLength"
    | "exactLength"
    | "requiredEither"
    | "format"
    | "mustContain";

export type OrganizationProfileFormAttribute =
    | "name"
    | "description"
    | "organizationType"
    | "web"
    | "stateID"
    | "lau2"
    | "population"
    | "populationYear"
    | "areaKm2"
    | "level"
    | "municipalityType"
    | "contactPersonFullName"
    | "contactPersonEmail"
    | "contactPersonPhoneNumber"
    | "contactPersonRole"
    | "parentMunicipality";

interface FormError {
    validationType: FormValidationType;
    referencedValue?: string | null;
}

interface FormErrors extends Map<OrganizationProfileFormAttribute, FormError> {}

interface OrganizationProfileFormData {
    name: string;
    description: string | null;
    organizationType: OrganizationType;
    web: string | null;
    stateID: string | null;
    lau2: string;
    population: number | null;
    populationYear: number | null;
    areaKm2: string;
    level: number | null;
    municipalityType: MunicipalityType | null;
    contactPerson: ContactPersonDTO | ContactPersonCreateDTO | null;
    parentMunicipality: SimpleMunicipalityDTO | null;
}

const OrganizationProfile: React.FC<OrganizationProfileInfoProps> = (props: OrganizationProfileInfoProps) => {
    const classes = useStyles();
    const {keycloak, initialized} = useKeycloak();
    const [t] = useTranslation(["organization", "common"]);
    const [state, setState] = useState({
        name: (props.editFirst ? "edit" : "init") as "init" | "saving" | "saved" | "edit" | "failed",
        organizationProfile: props.organizationProfile,
    });

    const organizationInState = state.organizationProfile.organization;

    function formDataFromState(): OrganizationProfileFormData {
        return {
            name: organizationInState.name,
            description: organizationInState.description,
            organizationType: organizationInState.type,
            web: organizationInState.web,
            stateID: organizationInState.stateID,
            lau2: organizationInState.municipality?.lau2 || "",
            population: organizationInState.municipality?.population || null,
            populationYear: organizationInState.municipality?.populationYear || null,
            areaKm2: organizationInState.municipality?.areaKm2?.toFixed(2)?.replace(".", ",") || "",
            level: organizationInState.municipality?.level || 1,
            municipalityType: organizationInState.municipality?.type || "TOWN",
            contactPerson: organizationInState.contactPerson as ContactPersonDTO | ContactPersonCreateDTO | null,
            parentMunicipality: organizationInState.municipality?.parentMunicipality || null,
        };
    }

    const [formData, setFormData] = useState(formDataFromState());
    const [formErrors, setFormErrors] = useState<FormErrors>(new Map());
    const formDataAreaNumeric = formData.areaKm2
        ? parseFloat(parseFloat(formData.areaKm2.replace(",", ".")).toFixed(2))
        : 0;
    const startEdit = (): void => {
        console.log("[OrganizationProfile] startEdit - state.name:");
        console.log(state.name);
        if (state.name === "saving") return;
        setState({...state, name: "edit"});
    };

    const validateFormData = () => {
        const newFormErrors = new Map(formErrors);

        if (!formData.name) newFormErrors.set("name", {validationType: "required"});
        else if (formData.name.length > 250) newFormErrors.set("name", {validationType: "max", referencedValue: "250"});

        if (formData.description && formData.description.length > 2000)
            newFormErrors.set("description", {validationType: "max", referencedValue: "2000"});

        if (!formData.web) newFormErrors.set("web", {validationType: "required"});
        else if (formData.web.length > 250) newFormErrors.set("web", {validationType: "max", referencedValue: "250"});

        if (!formData.organizationType) newFormErrors.set("organizationType", {validationType: "required"});

        if (formData.organizationType === municipalityOrganizationType) {
            if (!formData.lau2) newFormErrors.set("lau2", {validationType: "required"});
            else if (formData.lau2.replace(" ", "").length !== 12)
                newFormErrors.set("lau2", {validationType: "exactLength", referencedValue: "12"});

            if (!formData.population) newFormErrors.set("population", {validationType: "required"});
            else if (formData.population <= 0)
                newFormErrors.set("population", {validationType: "more", referencedValue: "0"});
            else if (formData.population >= 100000000)
                newFormErrors.set("population", {validationType: "less", referencedValue: "100 000 000"});

            if (formData.populationYear) {
                if (formData.populationYear <= 2000)
                    newFormErrors.set("populationYear", {validationType: "more", referencedValue: "2000"});
                else if (formData.populationYear > new Date().getFullYear())
                    newFormErrors.set("populationYear", {
                        validationType: "less",
                        referencedValue: new Date().getFullYear().toString(),
                    });
            }

            if (formDataAreaNumeric < 0) newFormErrors.set("areaKm2", {validationType: "min", referencedValue: "0"});
            else if (formDataAreaNumeric > 10000)
                newFormErrors.set("areaKm2", {validationType: "max", referencedValue: "10 000"});

            if (!formData.stateID) newFormErrors.set("stateID", {validationType: "required"});
            else if (formData.stateID.trim().length !== 8)
                newFormErrors.set("stateID", {validationType: "exactLength", referencedValue: "8"});

            if (formData.contactPerson) {
                if (!formData.contactPerson.fullName)
                    newFormErrors.set("contactPersonFullName", {validationType: "required"});
                else if (formData.contactPerson.fullName.length > 250)
                    newFormErrors.set("contactPersonFullName", {validationType: "max", referencedValue: "250"});

                if (formData.contactPerson.email) {
                    if (formData.contactPerson.email.length > 250)
                        newFormErrors.set("contactPersonEmail", {validationType: "max", referencedValue: "250"});
                    if (!formData.contactPerson.email.includes("@", 1))
                        newFormErrors.set("contactPersonEmail", {validationType: "mustContain", referencedValue: "@"});
                }
                if (formData.contactPerson.phoneNumber) {
                    if (formData.contactPerson.phoneNumber.length > 100)
                        newFormErrors.set("contactPersonPhoneNumber", {validationType: "max", referencedValue: "100"});
                } else if (!formData.contactPerson.email) {
                    newFormErrors.set("contactPersonEmail", {
                        validationType: "requiredEither",
                        referencedValue: t("organization.phoneNumber"),
                    });
                    newFormErrors.set("contactPersonPhoneNumber", {
                        validationType: "requiredEither",
                        referencedValue: t("organization.email"),
                    });
                }

                if (formData.contactPerson.role && formData.contactPerson.role.length > 250)
                    newFormErrors.set("contactPersonRole", {validationType: "max", referencedValue: "250"});
            }
        }
        setFormErrors(newFormErrors);
        console.log("OrganizationProfile - validateFormData - newFormErrors:");
        console.log(newFormErrors);
        return newFormErrors.size == 0;
    };
    const clearFormError = (fieldName: OrganizationProfileFormAttribute) => {
        const newFormErrors = new Map(formErrors);
        newFormErrors.delete(fieldName);
        setFormErrors(newFormErrors);
    };
    const errorHelperText = (fieldName: OrganizationProfileFormAttribute) => {
        const error = formErrors.get(fieldName);
        if (!error) return null;
        return t("validation." + error.validationType, {referencedValue: error.referencedValue});
    };
    const setFormInputData = (fieldName: OrganizationProfileFormAttribute, data: OrganizationProfileFormData) => {
        clearFormError(fieldName);
        setFormData(data);
    };
    const saveEdit = (): void => {
        if (!validateFormData()) return;
        setState({...state, name: "saving"});
    };
    const cancelEdit = (): void => {
        setState({...state, name: "saved"});
        setFormData(formDataFromState());
    };
    const organizationTypesSelectOptions = (
        isAdmin(keycloak) ? organizationTypes : nonMunicipalityOrganizationTypes
    ).map((organizationType: string) => (
        <MenuItem key={organizationType} value={organizationType}>
            {t("type." + organizationType)}
        </MenuItem>
    ));
    const municipalityTypesSelectOptions = municipalityTypes.map((municipalityType: string) => (
        <MenuItem key={municipalityType} value={municipalityType}>
            {t("municipalityType." + municipalityType)}
        </MenuItem>
    ));

    useEffect(() => {
        const changed =
            formData.name !== organizationInState.name ||
            formData.description !== organizationInState.description ||
            formData.organizationType !== organizationInState.type ||
            formData.web !== organizationInState.web ||
            formData.stateID !== organizationInState.stateID ||
            formData.contactPerson !== organizationInState.contactPerson ||
            (formData.organizationType === municipalityOrganizationType &&
                (!organizationInState.municipality ||
                    formData.lau2 !== organizationInState.municipality.lau2 ||
                    formData.level !== organizationInState.municipality.level ||
                    formData.population !== organizationInState.municipality.population ||
                    formData.populationYear !== organizationInState.municipality.populationYear ||
                    formDataAreaNumeric !== organizationInState.municipality.areaKm2 ||
                    formData.municipalityType !== organizationInState.municipality.type ||
                    formData.parentMunicipality?.organizationId !==
                        organizationInState.municipality.parentMunicipality?.organizationId));
        if (state.name === "saving" && changed) {
            console.log("saving");
            if (
                !isAdmin(keycloak) &&
                ((formData.organizationType === municipalityOrganizationType) !==
                    (organizationInState.type === municipalityOrganizationType) ||
                    formData.lau2 !== organizationInState.municipality?.lau2 ||
                    formData.level !== organizationInState.municipality?.level ||
                    formData.municipalityType !== organizationInState.municipality?.type ||
                    formData.parentMunicipality?.organizationId !==
                        organizationInState.municipality?.parentMunicipality?.organizationId)
            ) {
                console.log("unauthorized change of municipality");
                setFormData({
                    ...formData,
                    organizationType: organizationInState.type,
                    lau2: organizationInState.municipality?.lau2 || "",
                    level: organizationInState.municipality?.level || 1,
                    municipalityType: organizationInState.municipality?.type || "TOWN",
                    parentMunicipality: organizationInState.municipality?.parentMunicipality || null,
                });
                return setState({...state, name: "failed"});
            }
            const newMunicipality =
                formData.organizationType === municipalityOrganizationType
                    ? {
                          ...organizationInState.municipality,
                          lau2: formData.lau2,
                          level: formData.level,
                          type: formData.municipalityType,
                          population: formData.population,
                          populationYear: formData.populationYear,
                          areaKm2: formDataAreaNumeric,
                          parentMunicipality: formData.parentMunicipality,
                      }
                    : null;
            const organizationId = organizationInState.id;
            (organizationId ? API.put : API.post)(
                "/organizations/profile/" + (organizationId || ""),
                {
                    ...organizationInState,
                    ...formData,
                    type: formData.organizationType, // TODO: ugly hack but it does not work now...
                    municipality: newMunicipality,
                },
                createApiConfig(keycloak, initialized)
            )
                .then((res) => {
                    setState({...state, name: "saved", organizationProfile: res.data});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        } else if (state.name === "saving") {
            console.log("nothing to save");
            setState({...state, name: "saved"});
        }
    }, [state, organizationInState, formData, setState, keycloak, initialized]);

    const handleLogoUpload = (file: File) => {
        console.log("file:");
        console.log(file);
        const formData = new FormData();
        formData.append("image", file);
        API.post<ImageDTO>(
            "/organizations/" + organizationInState.publicId + "/logo",
            formData,
            createApiConfig(keycloak, initialized)
        )
            .then((res) => {
                setState({
                    ...state,
                    name: "saved",
                    organizationProfile: {
                        ...state.organizationProfile,
                        organization: {...organizationInState, logo: res.data},
                    },
                });
            })
            .catch((err: AxiosError) => {
                setState({...state, name: "failed"});
            });
    };

    const handlePhotoUpload = (file: File) => {
        const formData = new FormData();
        formData.append("image", file);
        API.post<ImageDTO>(
            "/organizations/" + organizationInState.publicId + "/photo",
            formData,
            createApiConfig(keycloak, initialized)
        )
            .then((res) => {
                if (!organizationInState.municipality) {
                    console.log("Assigning photo to non-municipality organization");
                    return;
                }
                setState({
                    ...state,
                    name: "saved",
                    organizationProfile: {
                        ...state.organizationProfile,
                        organization: {
                            ...organizationInState,
                            municipality: {
                                ...organizationInState.municipality,
                                photo: res.data,
                            },
                        },
                    },
                });
            })
            .catch((err: AxiosError) => {
                setState({...state, name: "failed"});
            });
    };

    if (state.name === "saving") {
        return (
            <Grid container item justifyContent="center">
                <Loading />
            </Grid>
        );
    } else if (state.name === "failed") {
        return (
            <Grid container item justifyContent="center">
                <ServerCommunicationAlert />
            </Grid>
        );
    }

    const organizationInfo = (
        <Box className={classes.organizationInfo}>
            <h1 className={classes.organizationName}>
                {(organizationInState.municipality
                    ? t("municipalityType." + organizationInState.municipality.type) + " "
                    : "") + organizationInState.name}
            </h1>
            <Divider className={classes.nameDivider} />
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell component="th" scope="row" className={classes.minTableCell}>
                            {t("organization.municipalityType")}
                        </TableCell>
                        <TableCell className={classes.noBorder}>
                            {organizationInState.municipality ? (
                                organizationInState.municipality.level === 2 ||
                                organizationInState.municipality.level === 3 ? (
                                    organizationInState.municipality.type === "TOWN" ? (
                                        <span>{t("municipalityLevel." + organizationInState.municipality.level)}</span>
                                    ) : (
                                        <>
                                            <span>
                                                {t("municipalityType." + organizationInState.municipality.type)}
                                            </span>
                                            <span>
                                                {" (" +
                                                    t(
                                                        "municipalityLevel." + organizationInState.municipality.level
                                                    ).toLowerCase() +
                                                    ")"}
                                            </span>
                                        </>
                                    )
                                ) : (
                                    <span>{t("municipalityType." + organizationInState.municipality.type)}</span>
                                )
                            ) : (
                                t("type." + organizationInState.type)
                            )}
                        </TableCell>
                    </TableRow>
                    {organizationInState.description ? (
                        <TableRow>
                            <TableCell component="th" scope="row" className={classes.minTableCell}>
                                {t("organization.description")}
                            </TableCell>
                            <TableCell className={classes.noBorder}>{organizationInState.description}</TableCell>
                        </TableRow>
                    ) : null}
                    {organizationInState.web ? (
                        <TableRow>
                            <TableCell component="th" scope="row" className={classes.minTableCell}>
                                {t("organization.web")}
                            </TableCell>
                            <TableCell className={classes.noBorder}>
                                <Link
                                    target="_blank"
                                    rel="noopener"
                                    href={
                                        !organizationInState.web.match(/^[a-zA-Z]+:\/\//)
                                            ? "//" + organizationInState.web
                                            : organizationInState.web
                                    }
                                >
                                    {organizationInState.web}
                                </Link>
                            </TableCell>
                        </TableRow>
                    ) : null}
                    {organizationInState.municipality &&
                    i18n.exists("organization:county." + organizationInState.municipality.lau2.substring(0, 6)) ? (
                        <TableRow>
                            <TableCell component="th" scope="row" className={classes.minTableCell}>
                                {t("type.COUNTY")}
                            </TableCell>
                            <TableCell className={classes.noBorder}>
                                {t("county." + organizationInState.municipality.lau2.substring(0, 6))}
                            </TableCell>
                        </TableRow>
                    ) : null}
                    {organizationInState.municipality &&
                    i18n.exists("organization:region." + organizationInState.municipality.lau2.substring(0, 5)) ? (
                        <TableRow>
                            <TableCell component="th" scope="row" className={classes.minTableCell}>
                                {t("type.REGION")}
                            </TableCell>
                            <TableCell className={classes.noBorder}>
                                {t("region." + organizationInState.municipality.lau2.substring(0, 5))}
                            </TableCell>
                        </TableRow>
                    ) : null}
                    {organizationInState.municipality?.parentMunicipality ? (
                        <TableRow>
                            <TableCell component="th" scope="row" className={classes.minTableCell}>
                                {t("organization.parentMunicipality")}
                            </TableCell>
                            <TableCell className={classes.noBorder}>
                                {organizationInState.municipality?.parentMunicipality.name}
                            </TableCell>
                        </TableRow>
                    ) : null}
                    {organizationInState.municipality?.population ? (
                        <TableRow>
                            <TableCell component="th" scope="row" className={classes.minTableCell}>
                                {t("organization.population")}
                            </TableCell>
                            <TableCell className={classes.noBorder}>
                                {organizationInState.municipality.population.toLocaleString("cs-CZ")}{" "}
                                {organizationInState.municipality.populationYear &&
                                organizationInState.municipality.populationYear != new Date().getFullYear()
                                    ? " (" +
                                      t("organization.inYear").toLowerCase() +
                                      " " +
                                      organizationInState.municipality.populationYear.toString() +
                                      ")"
                                    : null}
                            </TableCell>
                        </TableRow>
                    ) : null}
                    {organizationInState.municipality?.areaKm2 ? (
                        <TableRow>
                            <TableCell component="th" scope="row" className={classes.minTableCell}>
                                {t("organization.area")}
                            </TableCell>
                            <TableCell className={classes.noBorder}>
                                <span>
                                    <span>{organizationInState.municipality.areaKm2.toLocaleString("cs-CZ")}</span>
                                    <span>
                                        &nbsp;km<span style={{verticalAlign: "super"}}>2</span>
                                    </span>
                                </span>
                            </TableCell>
                        </TableRow>
                    ) : null}
                    {organizationInState.municipality && isAdmin(keycloak) ? (
                        <TableRow>
                            <TableCell component="th" scope="row" className={classes.minTableCell}>
                                {t("organization.lau2")}
                            </TableCell>
                            <TableCell className={classes.noBorder}>{organizationInState.municipality.lau2}</TableCell>
                        </TableRow>
                    ) : null}
                    {organizationInState.stateID ? (
                        <TableRow>
                            <TableCell component="th" scope="row" className={classes.minTableCell}>
                                {t("organization.stateID")}
                            </TableCell>
                            <TableCell className={classes.noBorder}>{organizationInState.stateID}</TableCell>
                        </TableRow>
                    ) : null}

                    {organizationInState.contactPerson &&
                    organizationInState.contactPerson.fullName &&
                    (organizationInState.contactPerson.phoneNumber || organizationInState.contactPerson.email) ? (
                        <>
                            <TableRow>
                                <TableCell className={classes.noBorder} colSpan={2}>
                                    {t("organization.contactPerson")}:
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.contactPersonCell} colSpan={2}>
                                    <Grid container>
                                        <Grid item xs={12} md={6} lg={4} className={classes.contactPerson}>
                                            <Box>
                                                <Box className={classes.contactPersonName}>
                                                    {organizationInState.contactPerson.fullName}
                                                </Box>
                                                {organizationInState.contactPerson.role ? (
                                                    <Box className={classes.contactPersonRole}>
                                                        {organizationInState.contactPerson.role}
                                                    </Box>
                                                ) : null}
                                            </Box>
                                            <Box className={classes.contactPersonContacts}>
                                                {organizationInState.contactPerson.phoneNumber ? (
                                                    <Box className={classes.contactPersonContact}>
                                                        {organizationInState.contactPerson.phoneNumber}
                                                    </Box>
                                                ) : null}
                                                {organizationInState.contactPerson.email ? (
                                                    <Box className={classes.contactPersonContact}>
                                                        {organizationInState.contactPerson.email}
                                                    </Box>
                                                ) : null}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        </>
                    ) : null}
                </TableBody>
            </Table>
        </Box>
    );

    const organizationForm = (
        <form autoComplete="off">
            {isAdmin(keycloak) || !organizationInState.municipality ? (
                <>
                    <Grid item xs={12}>
                        <TextField
                            id="name"
                            label={t("organization.name")}
                            value={formData.name || ""}
                            fullWidth
                            required
                            className={classes.spaceAfter}
                            onChange={(e): void =>
                                setFormInputData("name", {
                                    ...formData,
                                    name: e.target.value,
                                })
                            }
                            error={formErrors.has("name")}
                            helperText={errorHelperText("name")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl required style={{width: "100%"}} error={formErrors.has("organizationType")}>
                            <InputLabel id="name-label">{t("organization.type")}</InputLabel>
                            <Select
                                id="type"
                                labelId="name-label"
                                value={formData.organizationType}
                                required
                                className={classes.spaceAfter}
                                fullWidth
                                onChange={(e): void =>
                                    setFormInputData("organizationType", {
                                        ...formData,
                                        organizationType: e.target.value as OrganizationType,
                                    })
                                }
                            >
                                {organizationTypesSelectOptions}
                            </Select>
                            {formErrors.has("organizationType") ? (
                                <FormHelperText>{errorHelperText("organizationType")}</FormHelperText>
                            ) : null}
                        </FormControl>
                    </Grid>
                </>
            ) : (
                <h1 className={classes.organizationName}>{organizationInState.name}</h1>
            )}
            <Grid item xs={12}>
                <TextField
                    id="description"
                    label={t("organization.description")}
                    value={formData.description || ""}
                    fullWidth
                    multiline
                    className={classes.spaceAfter}
                    onChange={(e): void =>
                        setFormInputData("description", {
                            ...formData,
                            description: e.target.value,
                        })
                    }
                    error={formErrors.has("description")}
                    helperText={errorHelperText("description")}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="web"
                    label={t("organization.web")}
                    value={formData.web || ""}
                    fullWidth
                    required
                    className={classes.spaceAfter}
                    onChange={(e): void => setFormInputData("web", {...formData, web: e.target.value})}
                    error={formErrors.has("web")}
                    helperText={errorHelperText("web")}
                />
            </Grid>
            {formData.organizationType === municipalityOrganizationType ? (
                <div>
                    {isAdmin(keycloak) ? (
                        <Grid item xs={12}>
                            <FormControl required style={{width: "100%"}} error={formErrors.has("municipalityType")}>
                                <InputLabel id="municipalityType-label">
                                    {t("organization.municipalityType")}
                                </InputLabel>
                                <Select
                                    id="municipalityType"
                                    labelId="municipalityType-label"
                                    value={formData.municipalityType}
                                    required
                                    className={classes.spaceAfter}
                                    fullWidth
                                    onChange={(e): void =>
                                        setFormInputData("municipalityType", {
                                            ...formData,
                                            municipalityType: e.target.value as MunicipalityType,
                                            level:
                                                (e.target.value as MunicipalityType) === "CAPITAL_CITY"
                                                    ? 4
                                                    : formData.level,
                                        })
                                    }
                                >
                                    {municipalityTypesSelectOptions}
                                </Select>
                                {formErrors.has("municipalityType") ? (
                                    <FormHelperText>{errorHelperText("municipalityType")}</FormHelperText>
                                ) : null}
                            </FormControl>
                        </Grid>
                    ) : null}
                    {isAdmin(keycloak) ? (
                        <>
                            <Grid item xs={12}>
                                <TextField
                                    id="lau2"
                                    label={t("organization.lau2")}
                                    value={formData.lau2 || ""}
                                    fullWidth
                                    required
                                    className={classes.spaceAfter}
                                    onChange={(e): void =>
                                        setFormInputData("lau2", {
                                            ...formData,
                                            lau2: e.target.value,
                                        })
                                    }
                                    error={formErrors.has("lau2")}
                                    helperText={errorHelperText("lau2")}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="region"
                                    label={t("type.REGION")}
                                    value={
                                        formData.lau2?.length && formData.lau2?.length >= 5
                                            ? i18n.exists("organization:region." + formData.lau2?.substring(0, 5))
                                                ? t("region." + formData.lau2?.substring(0, 5))
                                                : t("placeholders.unknown")
                                            : t("type.REGION") + " " + t("placeholders.loads_from_lau2")
                                    }
                                    disabled={!i18n.exists("organization:region." + formData.lau2?.substring(0, 5))}
                                    fullWidth
                                    className={classes.spaceAfter}
                                    inputProps={{readOnly: true}}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="region"
                                    label={t("type.COUNTY")}
                                    value={
                                        formData.lau2?.length && formData.lau2?.length >= 6
                                            ? i18n.exists("organization:county." + formData.lau2?.substring(0, 6))
                                                ? t("county." + formData.lau2?.substring(0, 6))
                                                : t("placeholders.unknown")
                                            : t("type.COUNTY") + " " + t("placeholders.loads_from_lau2")
                                    }
                                    disabled={!i18n.exists("organization:county." + formData.lau2?.substring(0, 6))}
                                    fullWidth
                                    className={classes.spaceAfter}
                                    inputProps={{readOnly: true}}
                                />
                            </Grid>
                        </>
                    ) : null}
                    <Grid item xs={12}>
                        <TextField
                            id="population"
                            label={t("organization.population")}
                            value={formData.population || ""}
                            fullWidth
                            required
                            className={classes.spaceAfter}
                            onChange={(e): void => {
                                const number = parseInt(e.target.value.replace(" ", ""));
                                setFormInputData("population", {
                                    ...formData,
                                    population: number >= 0 ? number : null,
                                });
                            }}
                            error={formErrors.has("population")}
                            helperText={errorHelperText("population")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="populationYear"
                            label={t("organization.populationYear")}
                            value={formData.populationYear || ""}
                            fullWidth
                            className={classes.spaceAfter}
                            onChange={(e): void => {
                                const number = parseInt(e.target.value.replace(" ", ""));
                                setFormInputData("populationYear", {
                                    ...formData,
                                    populationYear: number >= 0 ? number : null,
                                });
                            }}
                            error={formErrors.has("populationYear")}
                            helperText={errorHelperText("populationYear")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="area"
                            label={
                                <span>
                                    {t("organization.area") + " (km"}
                                    <span style={{verticalAlign: "super"}}>2</span>)
                                </span>
                            }
                            value={formData.areaKm2}
                            fullWidth
                            required
                            className={classes.spaceAfter}
                            onChange={(e): void => {
                                setFormInputData("areaKm2", {...formData, areaKm2: e.target.value});
                            }}
                            error={formErrors.has("areaKm2")}
                            helperText={errorHelperText("areaKm2")}
                        />
                    </Grid>
                    {isAdmin(keycloak) && (!formData.level || formData.level <= 3) ? (
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={!!formData.level && formData.level === 3}
                                        disabled={!!formData.level && formData.level >= 4}
                                        onChange={(e): void =>
                                            setFormInputData("level", {
                                                ...formData,
                                                level: e.target.checked ? 3 : 2,
                                            })
                                        }
                                        name="selected"
                                    />
                                }
                                label={t("municipalityLevel.3")}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={!!formData.level && (formData.level === 2 || formData.level === 3)}
                                        disabled={!!formData.level && formData.level >= 3}
                                        onChange={(e): void =>
                                            setFormInputData("level", {
                                                ...formData,
                                                level: e.target.checked ? 2 : 1,
                                            })
                                        }
                                        name="selected"
                                    />
                                }
                                label={t("municipalityLevel.2")}
                            />
                        </Grid>
                    ) : null}
                    {isAdmin(keycloak) && (!formData.level || formData.level <= 2) ? (
                        <Grid item xs={12}>
                            <MunicipalitySelect
                                label={t("organization.parentMunicipality")}
                                value={formData.parentMunicipality}
                                fullWidth
                                required
                                className={classes.spaceAfter}
                                onChange={(e, value): void => {
                                    console.log("Select changed:");
                                    console.log(e);
                                    setFormInputData("parentMunicipality", {
                                        ...formData,
                                        parentMunicipality: value,
                                    });
                                }}
                                error={formErrors.has("parentMunicipality")}
                                helperText={errorHelperText("parentMunicipality")}
                            />
                        </Grid>
                    ) : null}
                </div>
            ) : null}

            <Grid item xs={12}>
                <TextField
                    id="stateID"
                    label={t("organization.stateID")}
                    value={formData.stateID || ""}
                    fullWidth
                    required
                    className={classes.spaceAfter}
                    onChange={(e): void => setFormInputData("stateID", {...formData, stateID: e.target.value})}
                    error={formErrors.has("stateID")}
                    helperText={errorHelperText("stateID")}
                />
            </Grid>
            <ContactPersonFormPart
                contactPerson={formData.contactPerson}
                onChange={(contactPerson) => setFormData({...formData, contactPerson: contactPerson})}
                onFormDataChange={(fieldName) => clearFormError(fieldName)}
                formErrors={formErrors}
            />
        </form>
    );
    const fullMembershipRequestControl = () => {
        const content = (
            <ProfileMenuItem key="request-menu-item">
                <Box className={classes.profileMenuItemContent}>
                    <ProfileMenuItemIcon>
                        <ContactMailIcon fontSize="small" />
                    </ProfileMenuItemIcon>
                    {t("ui.requestFullMembership")}
                </Box>
            </ProfileMenuItem>
        );
        console.log("[OrganizationProfile] fullMembershipControl - isUser: " + isUser(keycloak));
        return [
            isUser(keycloak) ? (
                <FullMembershipRequestControl key="request-control" organizationProfile={state.organizationProfile}>
                    {content}
                </FullMembershipRequestControl>
            ) : (
                <AnonymousFullMembershipRequestControl
                    key="anonymous-request-control"
                    organizationProfile={state.organizationProfile}
                >
                    {content}
                </AnonymousFullMembershipRequestControl>
            ),
        ];
    };
    const menu =
        props.organizationPermissions.profilePermissionLevel === "READWRITE" ||
        props.organizationPermissions.profilePermissionLevel === "FULL"
            ? state.name === "edit"
                ? [
                      <ProfileMenuItem onClick={cancelEdit} key="edit-cancel">
                          <Box className={classes.profileMenuItemContent}>
                              <ProfileMenuItemIcon>
                                  <CancelIcon fontSize="small" />
                              </ProfileMenuItemIcon>
                              {t("common:actions.cancel")}
                          </Box>
                      </ProfileMenuItem>,
                      <ProfileMenuItem onClick={saveEdit} key="edit-save">
                          <Box className={classes.profileMenuItemContent}>
                              <ProfileMenuItemIcon>
                                  <SaveIcon fontSize="small" />
                              </ProfileMenuItemIcon>
                              {t("common:actions.save")}
                          </Box>
                      </ProfileMenuItem>,
                  ]
                : [
                      <ProfileMenuItem onClick={startEdit} key="edit-start">
                          <Box className={classes.profileMenuItemContent}>
                              <ProfileMenuItemIcon>
                                  <EditIcon fontSize="small" />
                              </ProfileMenuItemIcon>
                              {t("common:actions.edit")}
                          </Box>
                      </ProfileMenuItem>,
                  ]
            : fullMembershipRequestControl();

    if (state.name !== "edit" && organizationInState.publicId) {
        if (props.organizationPermissions.profilePermissionLevel === "FULL")
            menu.push(
                <ProfileMenuItem key="members">
                    <ReactLink
                        className={classes.profileMenuItemContent}
                        to={ROUTES.organizationMembers.create(organizationInState.publicId)}
                    >
                        <ProfileMenuItemIcon>
                            <PeopleIcon fontSize="small" />
                        </ProfileMenuItemIcon>
                        {t("organization.members")}
                    </ReactLink>
                </ProfileMenuItem>
            );
        if (
            state.organizationProfile.organization.municipality &&
            (state.organizationProfile.publicTrackedIndicatorGroups.length > 0 ||
                props.organizationPermissions.trackedIndicatorsPermissionLevel === "READONLY" ||
                props.organizationPermissions.trackedIndicatorsPermissionLevel === "READWRITE")
        )
            menu.push(
                <ProfileMenuItem key="trackedIndicatorGroups">
                    <ReactLink
                        className={classes.profileMenuItemContent}
                        to={ROUTES.trackedIndicatorGroups.create(organizationInState.publicId)}
                    >
                        <ProfileMenuItemIcon>
                            <TimelineIcon fontSize="small" />
                        </ProfileMenuItemIcon>
                        {t("organization.trackedIndicatorGroups")}
                    </ReactLink>
                </ProfileMenuItem>
            );
        menu.push(
            <ProfileMenuItem key="dashboard">
                <ReactLink
                    className={classes.profileMenuItemContent}
                    to={ROUTES.municipalityDashboard.create(organizationInState.publicId)}
                >
                    <ProfileMenuItemIcon>
                        <DashboardIcon fontSize="small" />
                    </ProfileMenuItemIcon>
                    {t("organization.dashboard")}
                </ReactLink>
            </ProfileMenuItem>
        );
        if (state.organizationProfile.canReadValues)
            menu.push(
                <ProfileMenuItem key="valuesInput">
                    <ReactLink
                        className={classes.profileMenuItemContent}
                        to={ROUTES.valuesInput.create(organizationInState.publicId)}
                    >
                        <ProfileMenuItemIcon>
                            <KeyboardIcon fontSize="small" />
                        </ProfileMenuItemIcon>
                        {t("ui.valuesInput")}
                    </ReactLink>
                </ProfileMenuItem>
            );
        menu.push(
            <ProfileMenuItem key="libraries">
                <ReactLink
                    className={classes.profileMenuItemContent}
                    to={ROUTES.organizationLibraries.create(organizationInState.publicId)}
                >
                    <ProfileMenuItemIcon>
                        <AccountTreeIcon fontSize="small" />
                    </ProfileMenuItemIcon>
                    {t("ui.libraries")}
                </ReactLink>
            </ProfileMenuItem>
        );
    }
    const canEditProfile =
        props.organizationPermissions.profilePermissionLevel === "READWRITE" ||
        props.organizationPermissions.profilePermissionLevel === "FULL";
    return (
        <>
            <ManualSidebar
                section={
                    canEditProfile
                        ? state.organizationProfile.organization.municipality
                            ? csMunicipalityProfileSection
                            : csOrganizationProfileSection
                        : csPublicProfileSection
                }
            />
            <Container>
                <Grid container className={classes.organizationContent}>
                    <Grid item container md={4} sm={5} xs={8}>
                        <Grid item className={classes.leftPanel}>
                            {organizationInState.id ? (
                                <>
                                    {organizationInState.logo || canEditProfile ? (
                                        <UploadableImage
                                            key={organizationInState.logo?.updatedAt ?? "empty"}
                                            src={
                                                organizationInState.logo
                                                    ? API.defaults.baseURL +
                                                      "/organizations/" +
                                                      organizationInState.publicId +
                                                      "/logo?timestamp=" +
                                                      organizationInState.logo.updatedAt
                                                    : null
                                            }
                                            alt={t("organization.logo")}
                                            wrapperClass={classes.logoWrapper}
                                            imageClass={classes.logo}
                                            editable={canEditProfile}
                                            onConfirm={handleLogoUpload}
                                            emptyImage={SccLogo}
                                            editButtonText={
                                                t(organizationInState.logo ? "ui.edit" : "ui.upload") + t("ui._logo")
                                            }
                                        />
                                    ) : null}
                                    {organizationInState.municipality &&
                                    (organizationInState.municipality.photo || canEditProfile) ? (
                                        <UploadableImage
                                            key={organizationInState.municipality?.photo?.updatedAt ?? "empty"}
                                            src={
                                                organizationInState.municipality?.photo
                                                    ? API.defaults.baseURL +
                                                      "/organizations/" +
                                                      organizationInState.publicId +
                                                      "/photo?timestamp=" +
                                                      organizationInState.municipality.photo.updatedAt
                                                    : null
                                            }
                                            alt={t("organization.photo")}
                                            wrapperClass={classes.photoWrapper}
                                            imageClass={classes.photo}
                                            editable={canEditProfile}
                                            onConfirm={handlePhotoUpload}
                                            emptyImage={SccLogo}
                                            editButtonText={
                                                t(organizationInState.municipality.photo ? "ui.edit" : "ui.upload") +
                                                t("ui._photo")
                                            }
                                        />
                                    ) : null}
                                </>
                            ) : null}
                            <MenuList>{menu}</MenuList>
                        </Grid>
                    </Grid>
                    <Grid container item md={8} sm={7}>
                        {state.name === "edit" ? organizationForm : organizationInfo}
                    </Grid>
                    {state.name !== "edit" && organizationInState.municipality ? (
                        <MapyCzMap
                            name={organizationInState.name}
                            county={t("county." + organizationInState.municipality.lau2.substring(0, 6))}
                            wgs84e={organizationInState.municipality.wgs84e}
                            wgs84n={organizationInState.municipality.wgs84n}
                            height="600px"
                            style={{maxHeight: "60vh"}}
                            width="100%"
                            zoom={
                                organizationInState.municipality.type === "CAPITAL_CITY"
                                    ? 9
                                    : organizationInState.municipality.type === "STATUTE_CITY"
                                    ? 10
                                    : organizationInState.municipality.type === "CITY"
                                    ? 11
                                    : organizationInState.municipality.type === "MARKET_TOWN" ||
                                      (organizationInState.municipality.level &&
                                          organizationInState.municipality.level > 1)
                                    ? 12
                                    : 13
                            }
                        />
                    ) : null}
                </Grid>
            </Container>
        </>
    );
};

export default OrganizationProfile;
