import React from "react";
import {useKeycloak} from "@react-keycloak/web";
import {isDeveloper} from "../../utils/auth";

interface DevelopersOnlyBlockProps {
    children: React.ReactNode;
}

export const DevelopersOnlyBlock: React.FC<DevelopersOnlyBlockProps> = (props: DevelopersOnlyBlockProps) => {
    const {keycloak, initialized} = useKeycloak();
    if (initialized && isDeveloper(keycloak)) {
        return <>{props.children}</>;
    }
    return null;
};
