import React from "react";
import {InputVariableDTO} from "../../../models/library";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {Alert} from "@material-ui/lab";
import {ValueUtils} from "../../../utils";
import {MarkdownRender} from "../../common/markdown";
import {compareByAttr} from "../../../utils/compare";
import {Tags} from "../../common/tags";
import {InputVariableDataAcquisitions} from "./parts/InputVariableDataAcquisitions";

interface InputVariableDialogProps {
    inputVariable: InputVariableDTO;
    open: boolean;
    handleClose: () => void;
}

export const InputVariableDialog: React.FC<InputVariableDialogProps> = (props: InputVariableDialogProps) => {
    //== Init ===============================================================
    const [t] = useTranslation(["values"]);
    //== Render ===============================================================
    return (
        <Dialog open={props.open} onClose={props.handleClose} scroll="paper" maxWidth="md" fullWidth>
            <DialogTitle>{props.inputVariable.name}</DialogTitle>
            <DialogContent>
                <h3>{t("input_variable.description")}</h3>
                {props.inputVariable.description ? (
                    <MarkdownRender value={props.inputVariable.description} />
                ) : (
                    <Alert severity="info">{t("values_input.dialog.missing.description")}</Alert>
                )}
                <h3>{t("input_variable.definition")}</h3>
                {props.inputVariable.definition ? (
                    <MarkdownRender value={props.inputVariable.definition} />
                ) : (
                    <Alert severity="info">{t("values_input.dialog.missing.definition")}</Alert>
                )}
                <h3>{t("input_variable.tags")}</h3>
                {props.inputVariable.tags.length ? (
                    <Tags tags={props.inputVariable.tags} />
                ) : (
                    <Alert severity="info">{t("values_input.dialog.missing.tags")}</Alert>
                )}
                {props.inputVariable.type === "quantitative" && (
                    <>
                        <h3>{t("input_variable.value_limits")}</h3>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell variant="head">{t("input_variable.minimum_value")}</TableCell>
                                    <TableCell>
                                        {props.inputVariable.minimumValue === null
                                            ? t("input_variable.unlimited")
                                            : ValueUtils.formatValue(props.inputVariable.minimumValue)}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell variant="head">{t("input_variable.maximum_value")}</TableCell>
                                    <TableCell>
                                        {props.inputVariable.maximumValue === null
                                            ? t("input_variable.unlimited")
                                            : ValueUtils.formatValue(props.inputVariable.maximumValue)}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </>
                )}
                {props.inputVariable.type === "qualitative" && props.inputVariable.options.length > 0 && (
                    <>
                        <h3>{t("input_variable.options")}</h3>
                        <ul>
                            {props.inputVariable.options.sort(compareByAttr("value", "ascending")).map((option) => (
                                <li key={option.id}>
                                    {option.value.value} = {option.name}
                                </li>
                            ))}
                        </ul>
                    </>
                )}
                <InputVariableDataAcquisitions inputVariableId={props.inputVariable.id} />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>{t("input_variable.dialog.close")}</Button>
            </DialogActions>
        </Dialog>
    );
};
