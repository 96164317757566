import React from "react";
import {Link, makeStyles} from "@material-ui/core";
import ROUTES from "../../routes/routes";
import {createStyles, Theme} from "@material-ui/core/styles";
import csManual from "../content/cs/CsManual";
import {manuals} from "./ManualDisplay";
import {useTranslation} from "react-i18next";

export const manualLinkStyles = makeStyles((theme: Theme) =>
    createStyles({
        manualLink: {},
        manualLinkNumber: {
            display: "none",
            marginRight: "1em",
        },
        manualLinkPrintedNumber: {
            marginRight: "0.4em",
        },
        manualLinkTitle: {},
    })
);

interface ManualLinkProps {
    chapter: string;
    section?: string;
    subsection?: string;
    subSubsection?: string;
    number?: string;
    title?: string;
}

export const ManualQueryLink: (props: ManualLinkProps) => JSX.Element = (props: ManualLinkProps) => {
    const [t] = useTranslation("common");
    const classes = manualLinkStyles();
    let query = "?chapter=" + props.chapter;
    if (props.section) {
        query = query + "&section=" + props.section;
        if (props.subsection) {
            query = query + "&subsection=" + props.subsection;
            if (props.subSubsection) {
                query = query + "&subsubsection=" + props.subSubsection;
            }
        }
    }
    const link = ROUTES.manual.path + query;
    const [state, setState] = React.useState({
        number: props.number || null,
        title: props.title || link,
    });

    const manual = manuals.get(t("language.code")) || csManual;

    React.useEffect(() => {
        if (state.number !== null && state.title !== link) return;
        const chapter = manual.chapters.get(props.chapter);
        let number = "";
        let title = manual.title;
        if (chapter) {
            if (chapter.index) number = number + chapter.index;
            if (chapter.title) title = chapter.title;
            const section = props.section && chapter.sections.get(props.section);
            if (section) {
                if (section.index) number = number + "." + section.index;
                if (section.title) title = section.title;
                const subsection = props.subsection && section.subsections.get(props.subsection);
                if (subsection) {
                    if (subsection.index) number = number + "." + subsection.index;
                    if (subsection.title) title = subsection.title;
                    const subSubsection = props.subSubsection && subsection.subSubsections.get(props.subSubsection);
                    if (subSubsection) {
                        if (subSubsection.index) number = number + "." + subSubsection.index;
                        if (subSubsection.title) title = subSubsection.title;
                    }
                }
            }
        }
        setState({number: number, title: props.title || title});
    }, [state, manuals]);

    return (
        <Link href={link} className={classes.manualLink} target="_blank">
            <span className={classes.manualLinkNumber}>{state.number}</span>
            <span className={classes.manualLinkTitle}>{state.title}</span>
        </Link>
    );
};

export default ManualQueryLink;
