import React from "react";
import {useTranslation} from "react-i18next";
// Project imports
import {CMSPageRTE} from "../common/forms";
import {TagsInput} from "../common/tags";
import {TagDTO, WebpageDTO} from "../../models/admin";
import {useStyles} from "../../styles";
// Material UI imports
import {Button, FormControl, Grid, TextField} from "@material-ui/core";

interface CMSPageFormProps {
    webpage: WebpageDTO;

    onChange(webpage: WebpageDTO): void;

    onSubmit(): void;
}

export const CMSPageForm: React.FC<CMSPageFormProps> = (props: CMSPageFormProps) => {
    const [t] = useTranslation("webpages");
    const classes = useStyles();
    return (
        <Grid container spacing={10}>
            <Grid item container lg={6}>
                <FormControl fullWidth>
                    <TextField
                        label={t("webpage.title")}
                        value={props.webpage.title}
                        onChange={(e): void => {
                            props.onChange({...props.webpage, title: e.target.value});
                        }}
                        fullWidth
                        variant="outlined"
                        className={classes.spaceAfter}
                    />
                    <TextField
                        label={t("webpage.publicId")}
                        value={props.webpage.publicId}
                        onChange={(e): void => {
                            props.onChange({...props.webpage, publicId: e.target.value});
                        }}
                        fullWidth
                        variant="outlined"
                        className={classes.spaceAfter}
                    />
                    <TextField
                        label={t("webpage.description")}
                        value={props.webpage.description}
                        onChange={(e): void => {
                            props.onChange({...props.webpage, description: e.target.value});
                        }}
                        fullWidth
                        multiline
                        variant="outlined"
                        className={classes.spaceAfter}
                    />
                    <TextField
                        label={t("webpage.keywords")}
                        value={props.webpage.keywords}
                        onChange={(e): void => {
                            props.onChange({...props.webpage, keywords: e.target.value});
                        }}
                        fullWidth
                        multiline
                        variant="outlined"
                        className={classes.spaceAfter}
                    />
                    <TagsInput
                        label={t("webpage.tags")}
                        value={props.webpage.tags}
                        onChange={(tags: TagDTO[]): void => {
                            props.onChange({...props.webpage, tags: tags});
                        }}
                        fullWidth
                        variant="outlined"
                        className={classes.spaceAfter}
                    />
                    <Button onClick={props.onSubmit} variant="contained" color="primary">
                        {t("admin.save")}
                    </Button>
                </FormControl>
            </Grid>
            <Grid item container lg={6}>
                <CMSPageRTE
                    value={props.webpage.content}
                    label={t("webpage.content")}
                    onChange={(value) => props.onChange({...props.webpage, content: value})}
                />
            </Grid>
        </Grid>
    );
};

export default CMSPageForm;
