import React from "react";
import {useTranslation} from "react-i18next";
import {useKeycloak} from "@react-keycloak/web";
// Material UI imports
import {Box, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, TextField} from "@material-ui/core";
// Project imports
import {
    CreateActionButton,
    DeleteActionButton,
    EditActionButton,
    RefreshActionButton,
    SaveActionButton,
} from "../common/buttons";
import {Loading, LoadingRow} from "../common/Loading";
import {ErrorNotification, InfoNotification, SuccessNotification} from "../common/notifications";
import {OptionCreateDTO, OptionDTO} from "../../models/library";
import {API, createApiConfig} from "../../utils";
import {AxiosError, AxiosResponse} from "axios";
import {compareByAttr} from "../../utils/compare";

interface OptionRowProps {
    index: number;
    variableUUID: string;
    option: OptionDTO;
    onChange: (index: number, option: OptionDTO) => void;
    onDelete: (index: number) => void;
}

interface OptionNewRowProps {
    variableUUID: string;
    usedValues: Set<number>;

    onSave(option: OptionDTO): void;
}

const findFirstAllowedNumber = (usedValues: Set<number>): number => {
    let num = 0;
    while (usedValues.has(num)) {
        num++;
    }
    return num;
};

const OptionRow: React.FC<OptionRowProps> = (props: OptionRowProps) => {
    //== Init ===================================================================
    const [t] = useTranslation("libraries");
    const {keycloak, initialized} = useKeycloak();
    const [state, setState] = React.useState({
        option: props.option,
        formName: props.option.name,
        formValue: props.option.value.value,
        formDescription: props.option.description,
        name: "init",
        action: "init",
    });
    //== Effects ================================================================
    React.useEffect(() => {
        if (state.name === "deleting") {
            API.delete<void>(`/options/${state.option.id}`, createApiConfig(keycloak, initialized))
                .then(() => {
                    setState({...state, name: "deleted", action: "delete"});
                    props.onDelete(props.index);
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed", action: "delete"});
                });
        } else if (state.name === "saving") {
            const option: OptionCreateDTO = {
                name: state.formName,
                value: state.formValue,
                description: state.formDescription,
                variableUUID: props.variableUUID,
            };
            API.put<OptionDTO>(`/options/${state.option.id}`, option, createApiConfig(keycloak, initialized))
                .then((res: AxiosResponse<OptionDTO>) => {
                    setState({...state, option: res.data, name: "saved", action: "edit"});
                    props.onChange(props.index, res.data);
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed", action: "edit"});
                });
        }
    }, [keycloak, initialized, state, setState, props]);
    //== Handlers ===============================================================
    const handleDelete = (): void => {
        setState({...state, name: "deleting"});
    };
    const handleEdit = (): void => {
        setState({...state, name: "editing"});
    };
    const handleSave = (): void => {
        if (
            state.formName === state.option.name &&
            state.formValue === state.option.value.value &&
            state.formDescription === state.option.description
        ) {
            setState({...state, name: "saved"});
        } else {
            setState({...state, name: "saving"});
        }
    };
    //== Render =================================================================
    if (state.name === "deleted") {
        return <SuccessNotification message={t(`definitions.options.notifications.delete_ok`)} />;
    }
    if (state.name === "saving" || state.name === "deleting") {
        return <LoadingRow />;
    }
    if (state.name === "editing") {
        return (
            <TableRow>
                <TableCell align="center">
                    <TextField
                        label={t("definitions.options.value")}
                        value={state.formValue}
                        fullWidth
                        type="number"
                        onChange={(e): void => setState({...state, formValue: parseFloat(e.target.value)})}
                    />
                </TableCell>
                <TableCell align="center">
                    <TextField
                        label={t("definitions.options.name")}
                        value={state.formName}
                        fullWidth
                        required
                        onChange={(e): void => setState({...state, formName: e.target.value})}
                    />
                </TableCell>
                <TableCell align="center">
                    <TextField
                        label={t("definitions.options.description")}
                        value={state.formDescription}
                        fullWidth
                        required
                        multiline
                        onChange={(e): void => setState({...state, formDescription: e.target.value})}
                    />
                </TableCell>
                <TableCell align="right">
                    <SaveActionButton onClick={handleSave} />
                    <DeleteActionButton onClick={handleDelete} />
                </TableCell>
            </TableRow>
        );
    }
    // - default
    let notification: JSX.Element | null = null;
    if (state.name === "failed") {
        const a = state.action;
        notification = <ErrorNotification message={t(`definitions.options.notifications.${a}_fail`)} />;
    }
    if (state.name === "saved") {
        notification = <SuccessNotification message={t(`definitions.options.notifications.edit_ok`)} />;
    }
    return (
        <TableRow>
            <TableCell align="center">{state.option.value.value}</TableCell>
            <TableCell align="center">{state.option.name}</TableCell>
            <TableCell align="center">{state.option.description}</TableCell>
            <TableCell align="right">
                <EditActionButton onClick={handleEdit} />
                <DeleteActionButton onClick={handleDelete} />
                {notification}
            </TableCell>
        </TableRow>
    );
};

const OptionNewRow: React.FC<OptionNewRowProps> = (props: OptionNewRowProps) => {
    //== Init ===================================================================
    const [t] = useTranslation("libraries");
    const {keycloak, initialized} = useKeycloak();
    const initState = {
        formName: "",
        formValue: findFirstAllowedNumber(props.usedValues),
        formDescription: "",
        name: "init",
    };
    const [state, setState] = React.useState(initState);
    //== Effects ================================================================
    React.useEffect(() => {
        if (state.name === "saving") {
            const option: OptionCreateDTO = {
                name: state.formName,
                value: state.formValue,
                description: state.formDescription,
                variableUUID: props.variableUUID,
            };
            API.post<OptionDTO>(`/options`, option, createApiConfig(keycloak, initialized))
                .then((res) => {
                    setState({...state, name: "saved"});
                    props.onSave(res.data);
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [keycloak, initialized, state, setState, initState, props]);
    //== Handlers ===============================================================
    const handleCreate = (): void => {
        setState({...state, name: "saving"});
    };
    //== Render =================================================================
    if (state.name === "saving") {
        return <LoadingRow />;
    }
    let notification: JSX.Element | null = null;
    if (state.name === "failed") {
        notification = <ErrorNotification message={t("definitions.options.notifications.create_fail")} />;
    }
    // - default
    return (
        <TableRow>
            <TableCell align="center">
                <TextField
                    label={t("definitions.options.value")}
                    value={state.formValue}
                    fullWidth
                    required
                    type="number"
                    onChange={(e): void => setState({...state, formValue: parseFloat(e.target.value)})}
                />
            </TableCell>
            <TableCell align="center">
                <TextField
                    label={t("definitions.options.name")}
                    value={state.formName}
                    fullWidth
                    required
                    onChange={(e): void => setState({...state, formName: e.target.value})}
                />
            </TableCell>
            <TableCell align="center">
                <TextField
                    label={t("definitions.options.description")}
                    value={state.formDescription}
                    fullWidth
                    multiline
                    onChange={(e): void => setState({...state, formDescription: e.target.value})}
                />
            </TableCell>
            <TableCell align="right">
                <CreateActionButton onClick={handleCreate} />
                {notification}
            </TableCell>
        </TableRow>
    );
};

interface VariableOptionsTableProps {
    variableUUID: string;
    options: OptionDTO[];
    onChange?: (options: OptionDTO[]) => void;
}

export const VariableOptionsTable: React.FC<VariableOptionsTableProps> = (props: VariableOptionsTableProps) => {
    //== Init =================================================================
    const [t] = useTranslation("libraries");
    const initState = {
        name: "loaded",
        action: "init",
        options: props.options.sort(compareByAttr("value", "ascending")),
    };
    const [state, setState] = React.useState(initState);
    const usedValues = new Set(props.options.map((option: OptionDTO) => option.value.value));
    const {keycloak, initialized} = useKeycloak();
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<OptionDTO[]>(`/options/of-variable/${props.variableUUID}`, createApiConfig(keycloak, initialized))
                .then((r: AxiosResponse<OptionDTO[]>) => {
                    const options = r.data.sort(compareByAttr("value", "ascending"));
                    setState({...state, options: options, name: "loaded"});
                    if (props.onChange) {
                        props.onChange(options);
                    }
                })
                .catch((e: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [state, setState, props.variableUUID, props.onChange]);
    //== Handlers =============================================================
    const handleChange = (index: number, option: OptionDTO) => {
        setState({...state, name: "loading"});
    };
    const handleDelete = (index: number) => {
        setState({...state, name: "loading"});
    };
    //== Render ===============================================================
    if (state.name === "loading") {
        return <Loading />;
    }
    // - default
    let notification: JSX.Element | null = null;
    if (state.name === "loaded" && state.action === "refresh") {
        notification = <InfoNotification message={t("definitions.options.notifications.refresh_ok")} />;
    }
    if (state.name === "loaded" && state.action === "new") {
        notification = <SuccessNotification message={t("definitions.options.notifications.create_ok")} />;
    }
    const rows = state.options.map((option: OptionDTO, index: number) => (
        <OptionRow
            index={index}
            key={option.id}
            option={option}
            variableUUID={props.variableUUID}
            onChange={handleChange}
            onDelete={handleDelete}
        />
    ));
    return (
        <Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">{t("definitions.options.value")}</TableCell>
                        <TableCell align="center">{t("definitions.options.name")}</TableCell>
                        <TableCell align="center">{t("definitions.options.description")}</TableCell>
                        <TableCell align="right">
                            <RefreshActionButton
                                onClick={(): void =>
                                    setState({
                                        ...initState,
                                        name: "loading",
                                        action: "refresh",
                                    })
                                }
                            />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{rows}</TableBody>
                <TableFooter>
                    <OptionNewRow
                        usedValues={usedValues}
                        variableUUID={props.variableUUID}
                        onSave={(): void => setState({...initState, name: "loading", action: "new"})}
                    />
                </TableFooter>
            </Table>
            {notification}
        </Box>
    );
};

export default VariableOptionsTable;
