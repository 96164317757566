import React from "react";
import {useTranslation} from "react-i18next";
import {Checkbox, FormControl, Input, ListItemText} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {MembershipPositionSimpleDTO} from "../../../../models/members";

interface PositionsSelectProps {
    currentPositions: MembershipPositionSimpleDTO[];
    availablePositions: MembershipPositionSimpleDTO[];

    handlePositionsChange(positionIds: string[]): void;
}

export const PositionsSelect: React.FC<PositionsSelectProps> = (props: PositionsSelectProps) => {
    const [t] = useTranslation("organizationMembers");
    return (
        <FormControl required style={{width: "100%"}}>
            <InputLabel id="member-positions-select-label">{t("position.positions")}</InputLabel>
            <Select
                labelId="member-positions-select-label"
                id="member-positions-select"
                multiple
                value={props.currentPositions.map((position) => position.id)}
                onChange={(e): void => props.handlePositionsChange(e.target.value as string[])}
                input={<Input />}
                renderValue={(selected) =>
                    (selected as string[])
                        .map((id) => props.availablePositions.find((position) => position.id === id)?.name)
                        .join(", ")
                }
            >
                {props.availablePositions.map((position) => (
                    <MenuItem key={position.id} value={position.id}>
                        <Checkbox
                            checked={props.currentPositions.map((position) => position.id).indexOf(position.id) > -1}
                        />
                        <ListItemText primary={position.name} />
                    </MenuItem>
                ))}
                <MenuItem value="" disabled={true} style={{fontStyle: "italic"}}>
                    {t("ui.youCanAddPositions") + " " + t("ui.onTab").toLowerCase() + " " + t("position.positions")}
                </MenuItem>
            </Select>
        </FormControl>
    );
};
