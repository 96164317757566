import React from "react";
import {AxiosError} from "axios";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
import {ErrorDeleteDTO, rectifyErrorDelete} from "../../models/error";
import {API, createApiConfig} from "../../utils";
import {LoadingBackdrop} from "./Loading";
import {ConfirmationDialog, DeleteConfirmationDialog, InformationDialog} from "./dialogs";

interface DeleteEntity {
    id: string;
    name: string;
    extra?: string;
}

export interface GenericEntityDeleteContentProps {
    entity: DeleteEntity;
    error: ErrorDeleteDTO;
}

interface GenericEntityForceDeleteProps {
    entity: DeleteEntity | null;
    tNs: string;
    tPrefix: string;
    urlSlug: string;
    onDeleted: () => void;
    onCancel: () => void;
    onFailed: () => void;
    RenderContent: React.FC<GenericEntityDeleteContentProps>;
}

interface GenericEntityForceDeleteState {
    activeId: string | null;
    error: ErrorDeleteDTO | null;
    name:
        | "hidden"
        | "confirm"
        | "deleting"
        | "deleted"
        | "force-confirm"
        | "force-deleting"
        | "force-deleted"
        | "failed";
}

export const GenericEntityForceDelete: React.FC<GenericEntityForceDeleteProps> = (
    props: GenericEntityForceDeleteProps
) => {
    //== Init =================================================================
    const initState: GenericEntityForceDeleteState = {
        activeId: props.entity?.id ?? null,
        error: null,
        name: props.entity === null ? "hidden" : "confirm",
    };
    const [state, setState] = React.useState(initState);
    const {keycloak, initialized} = useKeycloak();
    const [t] = useTranslation(props.tNs);
    //== Effects ==============================================================
    React.useEffect(() => {
        const id = props.entity?.id ?? null;
        if (id !== state.activeId) {
            setState({...state, activeId: id, error: null, name: props.entity === null ? "hidden" : "confirm"});
        } else if (state.name === "deleting") {
            API.delete<void>(`/${props.urlSlug}/${state.activeId}`, createApiConfig(keycloak, initialized))
                .then(() => {
                    setState({...state, name: "deleted"});
                    props.onDeleted();
                })
                .catch((err: AxiosError) => {
                    if (err.response?.status === 400) {
                        const error: ErrorDeleteDTO = rectifyErrorDelete(err.response.data);
                        setState({...state, name: "force-confirm", error: error});
                    } else {
                        setState({...state, name: "failed", activeId: null});
                        props.onFailed();
                    }
                });
        } else if (state.name === "force-deleting") {
            API.delete<void>(`/${props.urlSlug}/${state.activeId}?mode=force`, createApiConfig(keycloak, initialized))
                .then(() => {
                    setState({...state, name: "force-deleted"});
                    props.onDeleted();
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed", activeId: null});
                    props.onFailed();
                });
        }
    }, [props.entity, state.name]);
    //== Handlers =============================================================
    const handleDeleteConfirm = () => {
        setState({...state, name: "deleting"});
    };
    const handleDeleteForceConfirm = () => {
        setState({...state, name: "force-deleting"});
    };
    const handleCancel = () => {
        setState({...state, activeId: null, error: null, name: "hidden"});
        props.onCancel();
    };
    //== Render ===============================================================
    if (state.name === "deleting" || state.name === "force-deleting") {
        return <LoadingBackdrop />;
    }
    return (
        <>
            <ConfirmationDialog
                id="delete-confirm"
                title={t(`${props.tPrefix}.confirm_delete.title`)}
                text={t(`${props.tPrefix}.confirm_delete.text`, {title: props.entity?.name ?? ""})}
                onAgree={handleDeleteConfirm}
                onDisagree={handleCancel}
                open={state.name === "confirm"}
            />
            <DeleteConfirmationDialog
                id="delete-confirm-force"
                title={t(`${props.tPrefix}.confirm_delete.force.title`)}
                text={t(`${props.tPrefix}.confirm_delete.force.text`, {
                    title: props.entity?.name ?? "",
                    count: state.error?.relatedEntities.length ?? 0,
                })}
                checkbox={t(`${props.tPrefix}.confirm_delete.force.checkbox`)}
                open={state.name === "force-confirm" && state.error !== null}
                onDelete={handleDeleteForceConfirm}
                onCancel={handleCancel}
            >
                {state.error !== null && props.entity !== null && (
                    <props.RenderContent entity={props.entity} error={state.error} />
                )}
            </DeleteConfirmationDialog>
        </>
    );
};

interface GenericEntityNoLinkedDeleteState {
    activeId: string | null;
    error: ErrorDeleteDTO | null;
    name: "hidden" | "confirm" | "deleting" | "deleted" | "inform" | "failed";
}

export const GenericEntityNoLinkedDelete: React.FC<GenericEntityForceDeleteProps> = (
    props: GenericEntityForceDeleteProps
) => {
    //== Init =================================================================
    const initState: GenericEntityNoLinkedDeleteState = {
        activeId: props.entity?.id ?? null,
        error: null,
        name: props.entity === null ? "hidden" : "confirm",
    };
    const [state, setState] = React.useState(initState);
    const {keycloak, initialized} = useKeycloak();
    const [t] = useTranslation(props.tNs);
    //== Effects ==============================================================
    React.useEffect(() => {
        const id = props.entity?.id ?? null;
        if (id !== state.activeId) {
            setState({...state, activeId: id, error: null, name: props.entity === null ? "hidden" : "confirm"});
        } else if (state.name === "deleting") {
            API.delete<void>(`/${props.urlSlug}/${state.activeId}`, createApiConfig(keycloak, initialized))
                .then(() => {
                    setState({...state, name: "deleted"});
                    props.onDeleted();
                })
                .catch((err: AxiosError) => {
                    if (err.response?.status === 400) {
                        const error: ErrorDeleteDTO = rectifyErrorDelete(err.response.data);
                        setState({...state, name: "inform", error: error});
                    } else {
                        setState({...state, name: "failed", activeId: null});
                        props.onFailed();
                    }
                });
        }
    }, [props.entity, state.name]);
    //== Handlers =============================================================
    const handleDeleteConfirm = () => {
        setState({...state, name: "deleting"});
    };
    const handleCancel = () => {
        setState({...state, activeId: null, error: null, name: "hidden"});
        props.onCancel();
    };
    //== Render ===============================================================
    if (state.name === "deleting") {
        return <LoadingBackdrop />;
    }
    return (
        <>
            <ConfirmationDialog
                id="delete-confirm"
                title={t(`${props.tPrefix}.confirm_delete.title`)}
                text={t(`${props.tPrefix}.confirm_delete.text`, {title: props.entity?.name ?? ""})}
                onAgree={handleDeleteConfirm}
                onDisagree={handleCancel}
                open={state.name === "confirm"}
            />
            <InformationDialog
                id="delete-inform"
                title={t(`${props.tPrefix}.confirm_delete.inform.title`)}
                text={t(`${props.tPrefix}.confirm_delete.inform.text`, {
                    title: props.entity?.name ?? "",
                    count: state.error?.relatedEntities.length ?? 0,
                })}
                open={state.name === "inform" && state.error !== null}
                onClose={handleCancel}
            >
                {state.error !== null && props.entity !== null && (
                    <props.RenderContent entity={props.entity} error={state.error} />
                )}
            </InformationDialog>
        </>
    );
};
