import React from "react";
import {InputVariableDTO, UnitSimpleDTO} from "../../models/library";
import {InputValueCreateDTO} from "../../models/values";
import {Grid, TextField} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {SaveActionButton} from "../common/buttons";
import {OptionsSelect, UnitsSelect} from "../common/values";

interface InputValueFormProps {
    disabled?: boolean;
    municipalityInputVariableUUID: string;
    inputVariable: InputVariableDTO;
    preferredUnit?: UnitSimpleDTO | null;
    possibleUnits?: UnitSimpleDTO[];
    value: InputValueCreateDTO;

    onSubmit(inputValue: InputValueCreateDTO): void;
}

export const InputValueForm: React.FC<InputValueFormProps> = (props: InputValueFormProps) => {
    // TODO: solve problem with min/max value with respect to units!
    // TODO: nice loading overlay for buttons when disabled?
    //== Init =================================================================
    const disabled = props.disabled ?? false;
    const hasOptions = props.inputVariable.options.length > 0;
    const hasUnits = props.possibleUnits && props.possibleUnits.length > 0;
    const [t] = useTranslation(["values"]);
    const [state, setState] = React.useState({value: props.value});
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm({mode: "onBlur"});
    const minValue = props.inputVariable.minimumValue?.value;
    const maxValue = props.inputVariable.maximumValue?.value;
    //== Handlers =============================================================
    const handleValueSubmit = (): void => {
        props.onSubmit(state.value);
    };
    //== Validators ===========================================================
    const validateDate = (date: string): boolean => {
        return Date.parse(date) <= Date.now();
    };
    //== Render ===============================================================
    console.log("[InputValueForm] state:");
    console.log(state);
    const valueRegister = register("value", {required: true, min: minValue, max: maxValue});
    const gatheredAtRegister = register("gatheredAt", {required: true, validate: {notFuture: validateDate}});
    const noteRegister = register("note");
    return (
        <form onSubmit={handleSubmit(handleValueSubmit)}>
            <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                <Grid item>
                    {hasOptions ? (
                        <OptionsSelect
                            options={props.inputVariable.options}
                            value={state.value.value}
                            onChange={(xvalue): void =>
                                setState({
                                    ...state,
                                    value: {
                                        ...state.value,
                                        value: xvalue,
                                    },
                                })
                            }
                            disabled={disabled}
                        />
                    ) : (
                        <TextField
                            {...valueRegister}
                            label={t("values_input." + valueRegister.name)}
                            value={isNaN(state.value.value) ? "" : state.value.value}
                            type="number"
                            variant="outlined"
                            InputProps={{inputProps: {min: minValue, max: maxValue}}}
                            onChange={(e): void => {
                                setState({
                                    ...state,
                                    value: {...state.value, value: parseFloat(e.target.value)},
                                });
                            }}
                            disabled={disabled}
                            inputRef={valueRegister.ref}
                            error={errors.value !== undefined}
                            helperText={errors.value && errors.value.message}
                        />
                    )}
                </Grid>
                {hasUnits && (
                    <Grid item>
                        <UnitsSelect
                            unitUUID={state.value.unitUUID}
                            units={props.possibleUnits ?? []}
                            onChange={(unitUUID): void => {
                                setState({
                                    ...state,
                                    value: {
                                        ...state.value,
                                        unitUUID: unitUUID,
                                    },
                                });
                            }}
                            disabled={disabled}
                        />
                    </Grid>
                )}
                <Grid item>
                    <TextField
                        {...gatheredAtRegister}
                        label={t("values_input." + gatheredAtRegister.name)}
                        value={state.value.gatheredAt}
                        type="date"
                        variant="outlined"
                        required
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputRef={gatheredAtRegister.ref}
                        onChange={(e): void => {
                            setState({
                                ...state,
                                value: {...state.value, gatheredAt: e.target.value},
                            });
                        }}
                        disabled={disabled}
                        error={errors.gatheredAt !== undefined}
                        helperText={errors.gatheredAt && errors.gatheredAt.message}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        {...noteRegister}
                        label={t("values_input." + noteRegister.name)}
                        value={state.value.note}
                        variant="outlined"
                        inputRef={noteRegister.ref}
                        onChange={(e): void => {
                            setState({value: {...state.value, note: e.target.value}});
                        }}
                        disabled={disabled}
                        error={errors.gatheredAt !== undefined}
                        helperText={errors.gatheredAt && errors.gatheredAt.message}
                    />
                </Grid>
                <Grid item>
                    <SaveActionButton disabled={disabled} key="submit" onClick={handleSubmit(handleValueSubmit)} />
                </Grid>
            </Grid>
        </form>
    );
};
