import React from "react";
import {CustomizableConfirmationDialog} from "../../common/dialogs";
import {useTranslation} from "react-i18next";
import {InputValueSimpleDTO, InputVariableTaskDTO} from "../../../models/values";
import {Table, TableBody, TableCell, TableRow} from "@material-ui/core";
import {DateUtils} from "../../../utils";
import {makeFullName} from "../../../models/user";

interface InvalidateConfirmDialogProps {
    open: boolean;
    task: InputVariableTaskDTO;
    valueFn: (v: InputValueSimpleDTO) => string;
    value: InputValueSimpleDTO;
    onAgree: () => void;
    onDisagree: () => void;
}

export const InvalidateConfirmDialog: React.FC<InvalidateConfirmDialogProps> = (
    props: InvalidateConfirmDialogProps
) => {
    // TODO: show affected indicators?
    // TODO: allow to adjust note on the value?
    //== Init =================================================================
    const [t] = useTranslation("values");
    //== Render ===============================================================
    return (
        <CustomizableConfirmationDialog
            id={`invalidate-value-${props.value.id}`}
            agree={t("values_input.invalidate.dialog.agree")}
            disagree={t("values_input.invalidate.dialog.disagree")}
            onAgree={props.onAgree}
            onDisagree={props.onDisagree}
            text={t("values_input.invalidate.dialog.text")}
            title={t("values_input.invalidate.dialog.title")}
            open={props.open}
        >
            <Table size="small">
                <TableBody>
                    <TableRow>
                        <TableCell>{t("input_variable.name")}</TableCell>
                        <TableCell>{props.task.inputVariable.name}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>{t("values_input.gatheredAt")}</TableCell>
                        <TableCell>{DateUtils.dateString(props.value.gatheredAt)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>{t("values_input.value")}</TableCell>
                        <TableCell>{props.valueFn(props.value)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>{t("values_input.note")}</TableCell>
                        <TableCell>{props.value.note ?? ""}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>{t("values_input.enteredBy")}</TableCell>
                        <TableCell>{makeFullName(props.value.person)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>{t("values_input.enteredAt")}</TableCell>
                        <TableCell>{DateUtils.datetimeString(props.value.createdAt)}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </CustomizableConfirmationDialog>
    );
};
