import React from "react";
import {useTranslation} from "react-i18next";
// Material UI imports
import {Button, FormControl, TextField} from "@material-ui/core";
// Project imports
import {DataSourceCreateDTO} from "../../../models/library";
import {useStyles} from "../../../styles";

interface DataSourceFormProps {
    dataSource: DataSourceCreateDTO;

    onChange(dataSource: DataSourceCreateDTO): void;

    onSubmit(): void;
}

const DataSourceForm: React.FC<DataSourceFormProps> = (props: DataSourceFormProps) => {
    const [t] = useTranslation("libraries");
    const classes = useStyles();
    return (
        <FormControl fullWidth>
            <TextField
                label={t("definitions.data_sources.name")}
                value={props.dataSource.name}
                onChange={(e): void => {
                    props.onChange({...props.dataSource, name: e.target.value});
                }}
                fullWidth
                variant="outlined"
                className={classes.spaceAfter}
            />
            <TextField
                label={t("definitions.data_sources.description")}
                value={props.dataSource.description}
                onChange={(e): void => {
                    props.onChange({...props.dataSource, description: e.target.value});
                }}
                fullWidth
                multiline
                variant="outlined"
                className={classes.spaceAfter}
            />
            <Button onClick={props.onSubmit} variant="contained" color="primary">
                {t("admin.save")}
            </Button>
        </FormControl>
    );
};

export default DataSourceForm;
