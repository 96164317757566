import React from "react";
import {useTranslation} from "react-i18next";
// Material UI imports
import {Box, Button, Container, Divider, FormControl, TextField} from "@material-ui/core";
// Project imports
import {UnitInput} from "../../common/libraries/units";
import {useStyles} from "../../../styles";
import {UnitCreateDTO} from "../../../models/library";
import {ConversionFormulaDisplay} from "./conversions/ConversionFormulaDisplay";
import {ConversionDialog} from "./conversions/ConversionDialog";

interface UnitFormProps {
    unitUUID?: string;
    unit: UnitCreateDTO;

    onChange(unit: UnitCreateDTO): void;

    onSubmit(): void;
}

const UnitForm: React.FC<UnitFormProps> = (props: UnitFormProps) => {
    //== Init =================================================================
    const [t] = useTranslation("libraries");
    const classes = useStyles();
    const excludes = props.unitUUID !== undefined ? [props.unitUUID] : [];
    const [formulaFromOpen, setFormulaFromOpen] = React.useState(false);
    const [formulaToOpen, setFormulaToOpen] = React.useState(false);
    //== Handlers =============================================================
    const handleFormulaFromChange = (formula: string) => {
        props.onChange({...props.unit, formulaFromBase: formula});
        setFormulaFromOpen(false);
    };
    const handleFormulaToChange = (formula: string) => {
        props.onChange({...props.unit, formulaToBase: formula});
        setFormulaToOpen(false);
    };
    //== Render ===============================================================
    return (
        <Container>
            <ConversionDialog
                open={formulaFromOpen}
                formula={props.unit.formulaFromBase}
                onSubmit={handleFormulaFromChange}
                onClose={() => setFormulaFromOpen(false)}
            />
            <ConversionDialog
                open={formulaToOpen}
                formula={props.unit.formulaToBase}
                onSubmit={handleFormulaToChange}
                onClose={() => setFormulaToOpen(false)}
            />
            <FormControl fullWidth>
                <TextField
                    label={t("definitions.units.name")}
                    value={props.unit.name}
                    onChange={(e): void => {
                        props.onChange({...props.unit, name: e.target.value});
                    }}
                    required
                    fullWidth
                    variant="outlined"
                    className={classes.spaceAfter}
                />
                <TextField
                    label={t("definitions.units.abbreviation")}
                    value={props.unit.abbreviation}
                    onChange={(e): void => {
                        props.onChange({...props.unit, abbreviation: e.target.value});
                    }}
                    required
                    fullWidth
                    variant="outlined"
                    className={classes.spaceAfter}
                />
                <TextField
                    label={t("definitions.units.description")}
                    value={props.unit.description}
                    onChange={(e): void => {
                        props.onChange({...props.unit, description: e.target.value});
                    }}
                    fullWidth
                    multiline
                    variant="outlined"
                    className={classes.spaceAfter}
                />
                <UnitInput
                    query="base"
                    excludeUuids={excludes}
                    label={t("definitions.units.base_unit")}
                    valueUuid={props.unit.baseUnitUUID}
                    onChange={(unit): void => {
                        props.onChange({...props.unit, baseUnitUUID: unit.id});
                    }}
                    fullWidth
                    variant="outlined"
                    className={classes.spaceAfter}
                />
                <Divider style={{margin: "1em"}} />
                <Box>
                    <h4>{t("definitions.units.formula_from")}</h4>
                    <ConversionFormulaDisplay formula={props.unit.formulaToBase} />
                    <Box display="flex" justifyContent="flex-end">
                        <Button onClick={() => setFormulaToOpen(true)}>{t("formula.actions.edit")}</Button>
                    </Box>
                </Box>
                <Divider style={{margin: "1em"}} />
                <Box>
                    <h4>{t("definitions.units.formula_from")}</h4>
                    <ConversionFormulaDisplay formula={props.unit.formulaFromBase} />
                    <Box display="flex" justifyContent="flex-end">
                        <Button onClick={() => setFormulaFromOpen(true)}>{t("formula.actions.edit")}</Button>
                    </Box>
                </Box>
                <Button onClick={props.onSubmit} variant="contained" color="primary">
                    {t("admin.save")}
                </Button>
            </FormControl>
        </Container>
    );
};

export default UnitForm;
