import React, {useState} from "react";
import {useTranslation} from "react-i18next";
// Material UI imports
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
// Project imports
import {FullMembershipRequestCreateDTO, FullMembershipRequestDTO} from "../../../../models/members";
import {OrganizationProfileDTO} from "../../../../models/organization";
import {useKeycloak} from "@react-keycloak/web";
import {ErrorNotification, SuccessNotification} from "../../../common/notifications";
import {API, createApiConfig} from "../../../../utils";
import {AxiosError} from "axios";
import {useTheme} from "@material-ui/core/styles";
import Loading from "../../../common/Loading";

interface FullMembershipRequestDialogProps {
    organizationProfile: OrganizationProfileDTO;
    open: boolean;

    onSend(request: FullMembershipRequestDTO): void;

    onCancel(): void;
}

const FullMembershipRequestDialog: React.FC<FullMembershipRequestDialogProps> = (
    props: FullMembershipRequestDialogProps
) => {
    //== Init ===================================================================
    const theme = useTheme();
    const {keycloak, initialized} = useKeycloak();
    const [t] = useTranslation(["organizationMembers", "common"]);
    const [state, setState] = useState("init" as "init" | "canceling" | "sending" | "sent" | "failed");
    const [positionName, setPositionName] = useState<string | null>(null);

    if (!props.organizationProfile.organization.municipality?.id) return null;

    React.useEffect(() => {
        if (state === "sending") {
            if (!props.organizationProfile.organization.municipality?.id) return setState("failed");
            const createDTO: FullMembershipRequestCreateDTO = {
                municipalityId: props.organizationProfile.organization.municipality.id,
                positionName: positionName || null,
            };
            API.post<FullMembershipRequestDTO>(
                `/full-membership-request`,
                createDTO,
                createApiConfig(keycloak, initialized)
            )
                .then((res) => {
                    setState("sent");
                    props.onSend(res.data);
                })
                .catch((err: AxiosError) => {
                    setState("failed");
                });
        } else if (state === "canceling") {
            props.onCancel();
            if (positionName) setPositionName(null);
            setState("init");
        }
    }, [props.open, state, setState]);
    const handleSend = (): void => {
        setState("sending");
    };
    const handleCancel = (): void => {
        setState("canceling");
    };
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
    //== Render =================================================================
    if (state === "sent") return <SuccessNotification message={t(`notifications.requests.create_ok`)} />;
    let notification: JSX.Element | null = null;
    if (state === "failed") notification = <ErrorNotification message={t(`notifications.requests.create_fail`)} />;

    return (
        <Dialog open={props.open} fullScreen={fullScreen} onClose={handleCancel}>
            <DialogTitle id="responsive-dialog-title">{t("request.fullMembershipRequest")}</DialogTitle>
            {state === "sending" || state === "canceling" ? (
                <DialogContent>
                    <p>{t("ui.requestCreationDialogInfo")}</p>
                    <Loading />
                </DialogContent>
            ) : (
                <>
                    <DialogContent>
                        <p>{t("ui.requestCreationDialogInfo")}</p>
                        <TextField
                            id="position-name"
                            label={t("request.positionName")}
                            value={positionName || ""}
                            fullWidth
                            onChange={(e): void => setPositionName(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancel} color="primary">
                            {t("common:actions.cancel")}
                        </Button>
                        <Button onClick={handleSend} color="primary">
                            {t("common:actions.send")}
                        </Button>
                    </DialogActions>
                </>
            )}
            {notification}
        </Dialog>
    );
};

export default FullMembershipRequestDialog;
