import React from "react";
import {useTranslation} from "react-i18next";
// Material UI imports
import {Dialog, DialogTitle, useMediaQuery} from "@material-ui/core";
import {DashboardIndicatorsAddDialogProps} from "./DashboardIndicatorsAddProps";
import {useTheme} from "@material-ui/core/styles";
import DashboardIndicatorsAddFromGroupsContent from "./DashboardIndicatorsAddFromGroupsContent";
// Project imports

const DashboardIndicatorsAddFromGroupsDialog: React.FC<DashboardIndicatorsAddDialogProps> = (
    props: DashboardIndicatorsAddDialogProps
) => {
    //== Init =================================================================
    const theme = useTheme();
    const [t] = useTranslation(["trackedIndicators", "common"]);
    //== Render ===============================================================
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    return (
        <Dialog
            fullScreen={fullScreen}
            open={props.open}
            onClose={() => {
                props.onCancel();
            }}
            maxWidth="md"
            fullWidth={true}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{t(`dashboard.ui.addingIndicators`)}</DialogTitle>
            <DashboardIndicatorsAddFromGroupsContent {...props} />
        </Dialog>
    );
};

export default DashboardIndicatorsAddFromGroupsDialog;
