import React from "react";
import {Box, Checkbox, FormControlLabel} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {InfoActionButton} from "../../../../common/buttons";
import {GoalDialog} from "../../../../values/dialogs/GoalDialog";
import {
    TrackedIndicatorGroupEditorAddingGoal,
    TrackedIndicatorGroupEditorAddingIndicator,
} from "./TrackedIndicatorGroupEditorAddingInterfaces";
import TrackedIndicatorGroupEditorIndicatorAdd from "./TrackedIndicatorGroupEditorIndicatorAdd";
import classNames from "classnames";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        row: {
            borderWidth: 0,
            borderStyle: "solid",
            borderBottomWidth: 1,
            borderColor: "rgb(145,145,145)",
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(0.5),
            marginLeft: theme.spacing(2),
            paddingLeft: theme.spacing(1),
            display: "flex",
        },
        alreadyAdded: {
            backgroundColor: "rgb(215,250,215)",
        },
        selectionCheckbox: {},
        itemName: {
            fontSize: "120%",
            fontWeight: "bold",
            marginTop: theme.spacing(1.5),
        },
        infoButton: {
            flexGrow: 1,
        },
    })
);

interface TrackedIndicatorGroupEditorGoalAddProps {
    index: number; //TODO temporary solution until libraryXXXUse are not added to LibraryDTO,
    goal: TrackedIndicatorGroupEditorAddingGoal;

    handleCheckChanged(goal: TrackedIndicatorGroupEditorAddingGoal): void;
}

export const propagateAddingGoalCheck = (
    goal: TrackedIndicatorGroupEditorAddingGoal
): TrackedIndicatorGroupEditorAddingGoal => {
    console.log("Propagating check of goal:");
    console.log(goal);
    return {
        ...goal,
        indicators: goal.indicators.map((checkedIndicator) =>
            checkedIndicator.checked === goal.checked
                ? checkedIndicator
                : {
                      ...checkedIndicator,
                      checked: goal.checked,
                  }
        ),
    };
};

const TrackedIndicatorGroupEditorGoalAdd: React.FC<TrackedIndicatorGroupEditorGoalAddProps> = (
    props: TrackedIndicatorGroupEditorGoalAddProps
) => {
    const classes = useStyles();
    const [t] = useTranslation("trackedIndicators");
    const [state, setState] = React.useState({infoOpen: false});
    //== Handlers ===============================================================
    const handleInfoOpen = (): void => {
        setState({...state, infoOpen: true});
    };
    const handleInfoClose = (): void => {
        setState({...state, infoOpen: false});
    };
    const handleCheckChanged = (checked: boolean) =>
        props.handleCheckChanged(
            propagateAddingGoalCheck({
                ...props.goal,
                checked,
            })
        );
    const handleContentCheckChanged = (addingIndicator: TrackedIndicatorGroupEditorAddingIndicator) => {
        props.handleCheckChanged({
            ...props.goal,
            checked: props.goal.checked || addingIndicator.checked,
            indicators: props.goal.indicators.map((eachAddingIndicator) =>
                eachAddingIndicator.id === addingIndicator.id ? addingIndicator : eachAddingIndicator
            ),
        });
    };
    return (
        <Box>
            <Box className={classNames({[classes.row]: true, [classes.alreadyAdded]: props.goal.alreadyAdded})}>
                <Box className={classes.selectionCheckbox}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={props.goal.checked}
                                disabled={props.goal.alreadyAdded}
                                onChange={(e): void => handleCheckChanged(e.target.checked)}
                                name="selected"
                            />
                        }
                        label=""
                    />
                </Box>
                <Box className={classes.itemName}>{t("library.goal") + ": " + props.goal.name}</Box>
                <Box className={classes.infoButton}>
                    <InfoActionButton onClick={handleInfoOpen} />
                </Box>
            </Box>
            <GoalDialog goal={props.goal} handleClose={handleInfoClose} open={state.infoOpen} />
            <Box>
                {props.goal.indicators.map((indicator: TrackedIndicatorGroupEditorAddingIndicator, index) => (
                    <TrackedIndicatorGroupEditorIndicatorAdd
                        key={indicator.id}
                        index={index}
                        indicator={indicator}
                        handleCheckChanged={handleContentCheckChanged}
                    />
                ))}
            </Box>
        </Box>
    );
};

export default TrackedIndicatorGroupEditorGoalAdd;
