import React from "react";

import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
// Local imports
import PrivateRoute from "./utils";
import {ROUTES} from "./routes";
import {ROLES} from "../utils/auth";
// Errors
import {NotFound} from "../components/common/errors";
// Routed components
import Profile, {OwnProfile} from "../components/user/Profile";
import Users, {UsersEmails, UsersPeople} from "../components/user/Users/Users";
import Keycloak from "../components/Keycloak";
import Login from "../components/Login";
import SCCompassApp, {SCCompassSimpleLayout} from "../components/layout";
import Dashboard from "../components/user/Dashboard";
import Organizations from "../components/organization/Admin/Organizations";
import Person from "../components/user/Users/Person";
import {CMSAdmin, CMSPageEdit, CMSPageView} from "../components/webpage";
import {LibrariesPublic, LibraryDetail, LibraryEdit, LibraryEditor, LibraryNew} from "../components/library";
import {OrganizatonMembershipsMembers, OrganizatonMembershipsPositions} from "../components/organization/members";
import {
    Definitions,
    DefinitionsDataSources,
    DefinitionsInputVariables,
    DefinitionsUnits,
} from "../components/definitions/Definitions";
import {DataSourceDetail} from "../components/definitions/datasources";
import {UnitDetail} from "../components/definitions/units";
import {InputVariableDetail} from "../components/definitions/inputvariables";
import TrackedIndicatorGroupEditor from "../components/organization/TrackedIndicators/editor/TrackedIndicatorsEditor";
import {MunicipalityValuesInput} from "../components/values/ValuesInput";
import {TagsAdminCategories, TagsAdminTags} from "../components/admin/TagsAdmin";
import {TrackedIndicatorsBrowser} from "../components/organization/TrackedIndicators/browser/TrackedIndicatorsBrowser";
import Municipalities from "../components/organization/search/Municipalities";
import {EmailVerification} from "../components/user/Emails/EmailVerification";
import {TrackedIndicatorsSummary} from "../components/organization/TrackedIndicators/browser/TrackedIndicatorsSummary";
import {LibrariesAdmin} from "../components/library/LibrariesAdmin";
import {LibraryAdmin} from "../components/library/LibraryAdmin";
import {LibraryNewOrg} from "../components/library/LibraryNewOrg";
import {LibraryNewAdmin} from "../components/library/LibraryNewAdmin";
import {LibrariesOrg} from "../components/library/LibrariesOrg";
import TrackedIndicatorGroups from "../components/organization/TrackedIndicators/groups/TrackedIndicatorGroups";
import {OrganizationProfileIndex} from "../components/organization/OrganizationProfile";
import LibraryClone from "../components/library/LibraryClone";
import Landing from "../components/static-pages/Landing";
import Partners from "../components/static-pages/Partners";
import Contacts from "../components/static-pages/Contacts";
import {ManualPage} from "../manual/components/ManualPage";
import {InputVariablesOverview} from "../components/definitions/inputvariables/overview/InputVariablesOverview";
import {InputValuesSummary} from "../components/values/summary/InputValuesSummary";
import {MunicipalityIndicatorsDashboard} from "../components/organization/TrackedIndicators/dashboard/MunicipalityIndicatorsDashboard";

const AppRouter: React.FC = () => {
    return (
        <Router>
            <Switch>
                <Route
                    exact
                    path={ROUTES.landing.path}
                    render={(props) => (
                        <SCCompassSimpleLayout>
                            <Landing />
                        </SCCompassSimpleLayout>
                    )}
                />
                <SCCompassApp>
                    <Switch>
                        <PrivateRoute exact path="/keycloak" component={Keycloak} />
                        {/* Organization */}
                        <PrivateRoute
                            exact
                            path={ROUTES.organizations.path}
                            role={ROLES.admin}
                            component={Organizations}
                        />
                        <Route exact path={ROUTES.organization.path} component={OrganizationProfileIndex} />
                        <PrivateRoute
                            exact
                            path={ROUTES.organizationPositions.path}
                            component={OrganizatonMembershipsPositions}
                        />
                        <PrivateRoute
                            exact
                            path={ROUTES.organizationMembers.path}
                            component={OrganizatonMembershipsMembers}
                        />
                        <PrivateRoute
                            exact
                            path={ROUTES.trackedIndicatorsEditor.path}
                            component={TrackedIndicatorGroupEditor}
                        />
                        {/* Libraries */}
                        <PrivateRoute exact path={ROUTES.libraryNew.path} role={ROLES.user} component={LibraryNew} />
                        <PrivateRoute
                            exact
                            path={ROUTES.libraryEditor.path}
                            role={ROLES.user}
                            component={LibraryEditor}
                        />
                        <PrivateRoute exact path={ROUTES.libraryEdit.path} role={ROLES.user} component={LibraryEdit} />
                        <PrivateRoute
                            exact
                            path={ROUTES.libraryClone.path}
                            role={ROLES.user}
                            component={LibraryClone}
                        />
                        <PrivateRoute
                            exact
                            path={ROUTES.organizationLibraries.path}
                            role={ROLES.user}
                            component={LibrariesOrg}
                        />
                        <PrivateRoute
                            exact
                            path={ROUTES.organizationLibraryNew.path}
                            role={ROLES.user}
                            component={LibraryNewOrg}
                        />
                        <PrivateRoute
                            exact
                            path={ROUTES.libraryAdmin.path}
                            role={ROLES.admin}
                            component={LibraryAdmin}
                        />
                        <PrivateRoute
                            exact
                            path={ROUTES.librariesAdmin.path}
                            role={ROLES.admin}
                            component={LibrariesAdmin}
                        />
                        <PrivateRoute
                            exact
                            path={ROUTES.libraryNewAdmin.path}
                            role={ROLES.admin}
                            component={LibraryNewAdmin}
                        />
                        <Route exact path={ROUTES.library.path} component={LibraryDetail} />
                        <Route exact path={ROUTES.libraries.path} component={LibrariesPublic} />
                        {/* User Management */}
                        <PrivateRoute exact path={ROUTES.ownProfile.path} role={ROLES.user} component={OwnProfile} />
                        <PrivateRoute exact path={ROUTES.profile.path} role={ROLES.user} component={Profile} />
                        <PrivateRoute exact path={ROUTES.dashboard.path} role={ROLES.user} component={Dashboard} />
                        <PrivateRoute
                            exact
                            path={ROUTES.emailVerification.path}
                            role={ROLES.user}
                            component={EmailVerification}
                        />
                        {/* Administration */}
                        <PrivateRoute exact path={ROUTES.users.path} role={ROLES.admin} component={Users} />
                        <PrivateRoute exact path={ROUTES.people.path} role={ROLES.admin} component={UsersPeople} />
                        <PrivateRoute exact path={ROUTES.emails.path} role={ROLES.admin} component={UsersEmails} />
                        <PrivateRoute exact path={ROUTES.person.path} role={ROLES.admin} component={Person} />
                        <PrivateRoute exact path={ROUTES.tags.path} role={ROLES.admin} component={TagsAdminTags} />
                        <PrivateRoute
                            exact
                            path={ROUTES.tagCategories.path}
                            role={ROLES.admin}
                            component={TagsAdminCategories}
                        />
                        <PrivateRoute exact path={ROUTES.cms.path} role={ROLES.admin} component={CMSAdmin} />
                        <PrivateRoute exact path={ROUTES.cmsPageEdit.path} role={ROLES.admin} component={CMSPageEdit} />
                        <PrivateRoute exact path={ROUTES.definitions.path} role={ROLES.admin} component={Definitions} />
                        <PrivateRoute
                            exact
                            path={ROUTES.dataSource.path}
                            role={ROLES.admin}
                            component={DataSourceDetail}
                        />
                        <PrivateRoute
                            exact
                            path={ROUTES.dataSources.path}
                            role={ROLES.admin}
                            component={DefinitionsDataSources}
                        />
                        <PrivateRoute exact path={ROUTES.unit.path} role={ROLES.admin} component={UnitDetail} />
                        <PrivateRoute exact path={ROUTES.units.path} role={ROLES.admin} component={DefinitionsUnits} />
                        <PrivateRoute
                            exact
                            path={ROUTES.inputVariable.path}
                            role={ROLES.admin}
                            component={InputVariableDetail}
                        />
                        <PrivateRoute
                            exact
                            path={ROUTES.inputVariables.path}
                            role={ROLES.admin}
                            component={DefinitionsInputVariables}
                        />
                        {/* Values */}
                        <PrivateRoute exact path={ROUTES.valuesInput.path} component={MunicipalityValuesInput} />
                        <PrivateRoute exact path={ROUTES.valuesInputSummary.path} component={InputValuesSummary} />
                        {/* Public (or custom handled) */}
                        <Route exact path={ROUTES.trackedIndicatorGroups.path} component={TrackedIndicatorGroups} />
                        <Route exact path={ROUTES.trackedIndicatorsBrowser.path} component={TrackedIndicatorsBrowser} />
                        <Route
                            exact
                            path={ROUTES.municipalityDashboard.path}
                            component={MunicipalityIndicatorsDashboard}
                        />
                        <Route exact path={ROUTES.trackedIndicatorSummary.path} component={TrackedIndicatorsSummary} />
                        <Route exact path={ROUTES.cmsPageView.path} component={CMSPageView} />
                        <Route exact path={ROUTES.login.path} component={Login} />
                        <Route exact path={ROUTES.municipalities.path} component={Municipalities} />
                        <Route exact path={ROUTES.partners.path} component={Partners} />
                        <Route exact path={ROUTES.contacts.path} component={Contacts} />
                        <Route exact path={ROUTES.manual.path} component={ManualPage} />
                        <Route exact path={ROUTES.inputVariablesOverview.path} component={InputVariablesOverview} />
                        <Route component={NotFound} />
                    </Switch>
                </SCCompassApp>
            </Switch>
        </Router>
    );
};

export default AppRouter;
