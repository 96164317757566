import React from "react";
import {InputValueDTO, InputValuesHistoryItemDTO} from "../../../models/values";
import {InputVariableDTO} from "../../../models/library";
import {
    Box,
    Button,
    Container,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from "@material-ui/core";
import Utils, {DateUtils} from "../../../utils";
import {useTranslation} from "react-i18next";
import {CSVUtils} from "../../../utils/CSV";
import GetAppIcon from "@material-ui/icons/GetApp";
import {makeFullName} from "../../../models/user";
import {compareByAttr} from "../../../utils/compare";

interface InputValuesSummaryTableProps {
    items: InputValuesHistoryItemDTO[];
    desiredDate: Date;
    onDateChange: (desiredDate: Date) => void;
}

interface InputValuesTableRow {
    sortKey: string;
    inputVariable: InputVariableDTO;
    value: InputValueDTO | null;
}

function item2row(item: InputValuesHistoryItemDTO): InputValuesTableRow {
    return {
        sortKey: item.inputVariable.name.toLowerCase(),
        inputVariable: item.inputVariable,
        value: item.value,
    };
}

export const InputValuesSummaryTable: React.FC<InputValuesSummaryTableProps> = (
    props: InputValuesSummaryTableProps
) => {
    //== Init =================================================================
    const [t] = useTranslation(["values"]);
    const rows = props.items.map(item2row).sort(compareByAttr("sortKey", "ascending"));
    //== Helpers ==============================================================
    const csvConvert = (v: InputValuesTableRow): string[] => {
        if (v.value !== null) {
            return [
                v.inputVariable.name,
                `${v.value?.value ?? "N/A"}`,
                v.value?.unit?.abbreviation ?? "",
                DateUtils.datetimeString(v.value?.gatheredAt),
                makeFullName(v.value?.person),
                DateUtils.datetimeString(v.value?.createdAt),
            ];
        } else {
            return [v.inputVariable.name, "N/A", "", "", "", ""];
        }
    };
    const csvHeaders = [
        t("values_input.summary.headers.inputVariable"),
        t("values_input.summary.headers.value"),
        t("values_input.summary.headers.unit"),
        t("values_input.summary.headers.gatheredAt"),
        t("values_input.summary.headers.person"),
        t("values_input.summary.headers.createdAt"),
    ];
    //== Handlers =============================================================
    const handleDateChange = (value: string) => {
        const newDate = new Date(value);
        props.onDateChange(newDate);
    };
    const handleExport = () => {
        const csv = CSVUtils.generateCSV(rows, csvConvert, csvHeaders);
        Utils.download("summary.csv", "text/csv;charset=utf-8", [csv]).click();
    };
    //== Render ===============================================================
    return (
        <Container>
            <Box display="flex" alignItems="center" style={{margin: "0.5em"}}>
                <Box flexGrow={1}>
                    <p>{t("values_input.summary.dateIntro")}</p>
                </Box>
                <TextField
                    label={t("values_input.summary.referenceDate")}
                    type="date"
                    variant="outlined"
                    value={DateUtils.dateFormString(props.desiredDate.toISOString())}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e): void => {
                        handleDateChange(e.target.value);
                    }}
                    style={{marginRight: "1em"}}
                />
                <Button onClick={handleExport} variant="contained" startIcon={<GetAppIcon />}>
                    {t("values_input.summary.download")}
                </Button>
            </Box>
            <Divider />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t("values_input.summary.headers.inputVariable")}</TableCell>
                        <TableCell align="right">{t("values_input.summary.headers.value")}</TableCell>
                        <TableCell align="right">{t("values_input.summary.headers.unit")}</TableCell>
                        <TableCell align="right">{t("values_input.summary.headers.gatheredAt")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((r) => (
                        <TableRow key={r.inputVariable.id}>
                            <TableCell>{r.inputVariable.name}</TableCell>
                            {r.value === null ? (
                                <TableCell colSpan={3} style={{textAlign: "center", fontStyle: "italic"}}>
                                    {t("values_input.summary.noValue")}
                                </TableCell>
                            ) : (
                                <>
                                    <TableCell align="right">{r.value.value}</TableCell>
                                    <TableCell align="right">{r.value.unit?.abbreviation ?? ""}</TableCell>
                                    <TableCell align="right">{DateUtils.dateString(r.value.gatheredAt)}</TableCell>
                                </>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Container>
    );
};
