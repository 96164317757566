import React from "react";
import {Box} from "@material-ui/core";
import {TrackedIndicatorValueSimpleDTO} from "../../models/values";
import {useTranslation} from "react-i18next";
import {useTheme} from "@material-ui/core/styles";
import {ResponsiveLine} from "@nivo/line";
import {ResponsiveBullet} from "@nivo/bullet";
import {BasicTooltip} from "@nivo/tooltip";
import {UnitSimpleDTO} from "../../models/library";

interface DisplayRanges {
    min: number;
    max: number;
}

function displayRanges(
    values: TrackedIndicatorValueSimpleDTO[],
    targetValue: number | null,
    indicatorMin: number | null,
    indicatorMax: number | null
): DisplayRanges {
    if (indicatorMin && indicatorMax) return {min: indicatorMin, max: indicatorMax};
    const valuesToConsider = values.map((dto) => dto.value).concat(targetValue != null ? [targetValue] : []);
    const lowestValue = Math.min(...valuesToConsider);
    const highestValue = Math.max(...valuesToConsider);
    const magnitude = Math.max(
        Math.floor(Math.log10(Math.abs(indicatorMax != null ? indicatorMax : (highestValue * 2) / 3))),
        Math.floor(Math.log10(Math.abs(indicatorMin != null ? indicatorMin : (lowestValue * 2) / 3)))
    );
    const numberOfMagnitude = Math.pow(10, magnitude);
    const max =
        indicatorMax != null
            ? indicatorMax
            : Math.ceil((highestValue + (highestValue - lowestValue) * 0.1) / numberOfMagnitude) * numberOfMagnitude;
    const min =
        indicatorMin != null
            ? indicatorMin
            : Math.floor((lowestValue - (highestValue - lowestValue) * 0.05) / numberOfMagnitude) * numberOfMagnitude;
    console.log(
        "[displayRanges]" +
            " lowestValue: " +
            lowestValue +
            ", highestValue: " +
            highestValue +
            ", magnitude: " +
            magnitude +
            ", numberOfMagnitude: " +
            numberOfMagnitude +
            ", min: " +
            min +
            ", max: " +
            max
    );
    return {min, max};
}

interface IndicatorHistoryGraphPropsWithoutValues {
    valueDisplayFn: (v: number) => string;
    targetValue: number | null;
    indicatorMinimum: number | null;
    indicatorMaximum: number | null;
    unit: UnitSimpleDTO | null;
}

interface IndicatorHistoryGraphProps extends IndicatorHistoryGraphPropsWithoutValues {
    values: TrackedIndicatorValueSimpleDTO[];
}

const IndicatorHistoryLineGraph: React.FC<IndicatorHistoryGraphProps> = (props: IndicatorHistoryGraphProps) => {
    //== Init =================================================================
    const [t] = useTranslation(["trackedIndicators"]);
    //== Render ===============================================================
    const ranges = displayRanges(props.values, props.targetValue, props.indicatorMinimum, props.indicatorMaximum);
    return (
        <Box style={{width: "min(100%, 600px)", height: "400px"}}>
            <ResponsiveLine
                data={[
                    {
                        id: "values",
                        color: useTheme().palette.primary,
                        data: props.values.map((value) => {
                            const date = new Date(value.relevantSince);
                            const dateString = date.toISOString().slice(0, 10);
                            console.log(
                                "[IndicatorHistoryGraph] relevantSince: " + dateString + ", value: " + value.value
                            );
                            return {x: dateString, y: value.value};
                        }),
                    },
                ]}
                margin={{top: 20, right: 50, bottom: 50, left: 50}}
                xScale={{type: "time", precision: "day", format: "%Y-%m-%d"}}
                xFormat={(value) => new Date(value).toLocaleDateString("cs-CZ")}
                yScale={{
                    type: "linear",
                    min: ranges.min,
                    max: ranges.max,
                    stacked: true,
                    reverse: false,
                }}
                yFormat={(value) => (props.unit ? value + " " + props.unit : value.toString())}
                curve="monotoneX"
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    format: "%y/%m",
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                }}
                enableGridX={false}
                colors={{scheme: "category10"}}
                pointSize={10}
                pointColor={{theme: "background"}}
                pointBorderWidth={2}
                pointBorderColor={{from: "serieColor", modifiers: []}}
                pointLabelYOffset={-12}
                useMesh={true}
                legends={[]}
                markers={
                    props.targetValue != null
                        ? [
                              {
                                  axis: "y",
                                  value: props.targetValue,
                                  lineStyle: {stroke: useTheme().palette.info.main, strokeWidth: 2},
                                  legend: t("editor.targetValue") + ": " + props.valueDisplayFn(props.targetValue),
                              },
                          ]
                        : []
                }
                tooltip={({point}) => {
                    return (
                        <BasicTooltip
                            id={
                                <span>
                                    <span>{new Date(point.data.x).toLocaleDateString() + ": "}</span>
                                    <strong>
                                        {props.unit
                                            ? point.data.y + " " + props.unit.abbreviation
                                            : point.data.y.toString()}
                                    </strong>
                                </span>
                            }
                            enableChip={true}
                        />
                    );
                }}
            />
        </Box>
    );
};

interface IndicatorHistoryBulletGraphProps extends IndicatorHistoryGraphPropsWithoutValues {
    value: TrackedIndicatorValueSimpleDTO;
}

const IndicatorHistoryBulletGraph: React.FC<IndicatorHistoryBulletGraphProps> = (
    props: IndicatorHistoryBulletGraphProps
) => {
    const [t] = useTranslation(["trackedIndicators"]);
    //== Init =================================================================
    console.log("[IndicatorHistoryBulletGraph] graph start");
    //== Render ===============================================================
    console.log("[IndicatorHistoryBulletGraph] Current targetValue: ");
    console.log(props.targetValue);
    const ranges = displayRanges([props.value], props.targetValue, props.indicatorMinimum, props.indicatorMaximum);
    return (
        <Box style={{width: "min(100%, 600px)", height: "110px"}}>
            <ResponsiveBullet
                data={[
                    {
                        id: "",
                        ranges: [ranges.max],
                        measures: [props.value.value],
                        markers: props.targetValue != null ? [props.targetValue] : [],
                    },
                ]}
                margin={{top: 20, right: 50, bottom: 50, left: 50}}
                minValue={ranges.min}
                maxValue={ranges.max}
                markerSize={0.8}
                rangeColors={[useTheme().palette.divider]}
                measureColors={[useTheme().palette.primary.main]}
                markerColors={[useTheme().palette.info.main]}
                tooltip={({v0, v1, color}: {color: string; v0: number; v1?: number}) => {
                    if (v1 != undefined) return null;
                    const valueDateParts = props.value.relevantSince.split("-");
                    const valueDate = new Date(
                        parseInt(valueDateParts[0]),
                        parseInt(valueDateParts[1]) - 1,
                        parseInt(valueDateParts[2])
                    );
                    return (
                        <BasicTooltip
                            id={
                                <span>
                                    <span>
                                        {props.targetValue == v0 && props.value.value != v0
                                            ? t("editor.targetValue") + ": "
                                            : props.value.value == v0
                                            ? valueDate.toLocaleDateString() + ": "
                                            : null}
                                    </span>
                                    <strong>{props.unit ? v0 + " " + props.unit.abbreviation : v0.toString()}</strong>
                                </span>
                            }
                            enableChip={true}
                            color={color}
                        />
                    );
                }}
            />
        </Box>
    );
};

export const IndicatorHistoryGraph: React.FC<IndicatorHistoryGraphProps> = (props: IndicatorHistoryGraphProps) => {
    const values = props.values
        .filter((value) => value.state === "valid")
        .sort((a, b) => (a.relevantSince < b.relevantSince ? -1 : 1));
    if (!values.length) return null;
    if (values.length == 1) return <IndicatorHistoryBulletGraph {...props} value={values[0]} />;
    return <IndicatorHistoryLineGraph {...props} />;
};
