import React from "react";
import {useTranslation} from "react-i18next";
// Material UI imports
import {Dialog, DialogTitle, useMediaQuery} from "@material-ui/core";
import {TrackedIndicatorGroupEditorAddDialogProps} from "./TrackedIndicatorGroupEditorAddProps";
import {useTheme} from "@material-ui/core/styles";
import TrackedIndicatorGroupEditorAddFromLibrariesContent from "./TrackedIndicatorGroupEditorAddFromLibrariesContent";
// Project imports

const TrackedIndicatorGroupEditorAddFromLibrariesDialog: React.FC<TrackedIndicatorGroupEditorAddDialogProps> = (
    props: TrackedIndicatorGroupEditorAddDialogProps
) => {
    //== Init =================================================================
    const theme = useTheme();
    const [t] = useTranslation(["trackedIndicators", "common"]);
    //== Render ===============================================================
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    return (
        <Dialog
            fullScreen={fullScreen}
            open={props.open}
            onClose={() => {
                props.onCancel();
            }}
            maxWidth="md"
            fullWidth={true}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{t(`ui.addingIndicators.${props.type}`)}</DialogTitle>
            <TrackedIndicatorGroupEditorAddFromLibrariesContent
                open={props.open}
                type={props.type}
                onCancel={props.onCancel}
                trackedIndicatorGroup={props.trackedIndicatorGroup}
                onAdd={props.onAdd}
            />
        </Dialog>
    );
};

export default TrackedIndicatorGroupEditorAddFromLibrariesDialog;
