import React from "react";
import {GoalCreateDTO} from "../../../../models/library";
import {Container, TextField} from "@material-ui/core";
import {TagsInput} from "../../../common/tags";
import {TagDTO} from "../../../../models/admin";
import {useTranslation} from "react-i18next";
import {MarkdownTextField} from "../../../common/markdown";
import {Alert} from "@material-ui/lab";

interface GoalFormProps {
    goal: GoalCreateDTO;
    published: boolean;
    onChange: (goal: GoalCreateDTO) => void;
}

export const GoalForm: React.FC<GoalFormProps> = (props: GoalFormProps) => {
    //TODO: validations
    //== Init =================================================================
    const [t] = useTranslation("libraries");
    //== Render ===============================================================
    return (
        <Container>
            {props.published && <Alert severity="warning">{t("goal.published_info")}</Alert>}
            <TextField
                label={t("goal.name")}
                value={props.goal.name}
                onChange={(e): void => {
                    props.onChange({...props.goal, name: e.target.value});
                }}
                required
                fullWidth
                variant="outlined"
                margin="normal"
            />
            <TagsInput
                label={t("goal.tags")}
                value={props.goal.tags}
                onChange={(tags: TagDTO[]): void => {
                    props.onChange({...props.goal, tags: tags});
                }}
                fullWidth
                variant="outlined"
                margin="normal"
            />
            <MarkdownTextField
                label={t("goal.description")}
                value={props.goal.description}
                onChange={(value): void => {
                    props.onChange({...props.goal, description: value});
                }}
                required
                margin="normal"
            />
            <MarkdownTextField
                label={t("goal.methodicalInstruction")}
                value={props.goal.methodicalInstruction}
                onChange={(value): void => {
                    props.onChange({...props.goal, methodicalInstruction: value});
                }}
                required
                margin="normal"
            />
        </Container>
    );
};
