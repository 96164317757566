import React from "react";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
import {Redirect, useParams} from "react-router-dom";
// Material UI imports
import {Box, Container} from "@material-ui/core";
// Local imports
import DataSourceForm from "./DataSourceForm";
// Project imports
import {NotFound} from "../../common/errors";
import {BackLinkActionButton, DeleteActionButton} from "../../common/buttons";
import Loading from "../../common/Loading";
import {ErrorNotification, SuccessNotification} from "../../common/notifications";
import {DataSourceCreateDTO, DataSourceDTO} from "../../../models/library";
import ROUTES from "../../../routes/routes";
import Utils, {API, createApiConfig} from "../../../utils";
import {AxiosError} from "axios";
import {DataSourceDelete} from "./DataSourceDelete";

interface DataSourceDetailState {
    dataSource: DataSourceDTO | null;
    dataSourceEdit: DataSourceCreateDTO;
    name: string;
    action: string;
}

interface DataSourceParams {
    dataSourceId: string;
}

export const DataSourceDetail: React.FC = () => {
    //== Init =================================================================
    const [t] = useTranslation("libraries");
    const {keycloak, initialized} = useKeycloak();
    const {dataSourceId} = useParams<DataSourceParams>();
    const initState: DataSourceDetailState = {
        dataSource: null,
        dataSourceEdit: {name: "", description: ""},
        name: "loading",
        action: "none",
    };
    const [state, setState] = React.useState(initState);
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<DataSourceDTO>(`/data-sources/${dataSourceId}`, createApiConfig(keycloak, initialized))
                .then((res) => {
                    const edit = {name: res.data?.name ?? "", description: res.data?.description ?? ""};
                    setState({...state, dataSource: res.data, dataSourceEdit: edit, name: "loaded"});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
        if (state.name === "saving") {
            API.put<DataSourceDTO>(
                `/data-sources/${dataSourceId}`,
                state.dataSourceEdit,
                createApiConfig(keycloak, initialized)
            )
                .then((res) => {
                    const edit = {name: res.data?.name ?? "", description: res.data?.description ?? ""};
                    setState({...state, dataSource: res.data, dataSourceEdit: edit, name: "saved"});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [keycloak, initialized, state.name, dataSourceId]);
    //== Handlers =============================================================
    const act: DataSourceCreateDTO = {
        name: state.dataSource?.name ?? "",
        description: state.dataSource?.description ?? "",
    };
    const isNotChanged = Utils.objEq(act, state.dataSourceEdit, ["name", "description"]);
    const handleChange = (dataSource: DataSourceCreateDTO): void => {
        setState({...state, dataSourceEdit: dataSource});
    };
    const handleSubmit = (): void => {
        if (!isNotChanged) {
            setState({...state, name: "saving", action: "edit"});
        }
    };
    const handleDeleteFailed = (): void => {
        setState({...state, name: "failed", action: "delete"});
    };
    const handleDeleteCancel = (): void => {
        setState({...state, name: "loaded", action: "none"});
    };
    const handleDeleteDone = (): void => {
        setState({...state, name: "deleted", action: "none"});
    };
    const handleDelete = (): void => {
        setState({...state, name: "deleting", action: "delete"});
    };
    //== Render ===============================================================
    let appendix: JSX.Element | null = null;
    if (state.name === "loading") {
        return <Loading />;
    }
    if (state.name === "failed") {
        if (state.action === "none") {
            return <NotFound />;
        }
        appendix = <ErrorNotification message={t(`definitions.data_sources.notifications.${state.action}_fail`)} />;
    }
    if (state.name === "saved") {
        appendix = <SuccessNotification message={t(`definitions.data_sources.notifications.edit_ok`)} />;
    }
    if (state.name === "deleted") {
        return <Redirect to={ROUTES.dataSources.path} />;
    }
    return (
        <Container maxWidth="md">
            <Box style={{clear: "both"}}>
                <Box style={{float: "left"}}>
                    <h1>{state.dataSource?.name}</h1>
                </Box>
                <Box style={{float: "right"}}>
                    <BackLinkActionButton to={ROUTES.dataSources.path} />
                    <DeleteActionButton onClick={handleDelete} />
                </Box>
            </Box>
            <DataSourceForm dataSource={state.dataSourceEdit} onChange={handleChange} onSubmit={handleSubmit} />
            {appendix}
            <DataSourceDelete
                entity={state.name === "deleting" ? state.dataSource : null}
                onDeleted={handleDeleteDone}
                onCancel={handleDeleteCancel}
                onFailed={handleDeleteFailed}
            />
        </Container>
    );
};

export default DataSourceDetail;
