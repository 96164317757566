import {VariableSimpleDTO} from "./library";

export interface EquationMapping {
    table: Map<string, string>;
}

export enum OperationType {
    ADD = "ADD",
    DIV = "DIV",
    MUL = "MUL",
    SUB = "SUB",
    LOG = "LOG",
    POW = "POW",
    SQRT = "SQRT",
    ROUND = "ROUND",
    CEIL = "CEIL",
    FLOOR = "FLOOR",
    CONST = "CONST",
    VAR = "VAR",
}

type OperandType = "node" | "value" | "ref";

export interface FormulaOperation {
    type: OperationType;
    operands: number;
    operandType: OperandType;
}

export const OPERATIONS: FormulaOperation[] = [
    {
        type: OperationType.ADD,
        operands: -1,
        operandType: "node",
    },
    {
        type: OperationType.SUB,
        operands: -1,
        operandType: "node",
    },
    {
        type: OperationType.MUL,
        operands: -1,
        operandType: "node",
    },
    {
        type: OperationType.DIV,
        operands: 2,
        operandType: "node",
    },
    {
        type: OperationType.LOG,
        operands: 1,
        operandType: "node",
    },
    {
        type: OperationType.POW,
        operands: 1,
        operandType: "node",
    },
    {
        type: OperationType.CEIL,
        operands: 1,
        operandType: "node",
    },
    {
        type: OperationType.FLOOR,
        operands: 1,
        operandType: "node",
    },
    {
        type: OperationType.ROUND,
        operands: -2,
        operandType: "node",
    },
    {
        type: OperationType.CONST,
        operands: 1,
        operandType: "value",
    },
    {
        type: OperationType.VAR,
        operands: 1,
        operandType: "ref",
    },
];

const OPERATIONS_MAP: Map<string, OperationType> = new Map([
    ["ADD", OperationType.ADD],
    ["DIV", OperationType.DIV],
    ["MUL", OperationType.MUL],
    ["SUB", OperationType.SUB],
    ["LOG", OperationType.LOG],
    ["POW", OperationType.POW],
    ["SQRT", OperationType.SQRT],
    ["ROUND", OperationType.ROUND],
    ["CEIL", OperationType.CEIL],
    ["FLOOR", OperationType.FLOOR],
    ["CONST", OperationType.CONST],
    ["VAR", OperationType.VAR],
]);

export const OPERATIONS_SET = new Set(OPERATIONS_MAP.keys());

type ErrorType = "operandsNumber" | "constNaN" | "varUndefined" | "invalidFormula";
type ErrorFields = Record<string, string>;

class FormulaProcessingError extends Error {
    opType: OperationType | null;
    errType: ErrorType;
    fields: ErrorFields;

    constructor(opType: OperationType | null, errType: ErrorType, fields: ErrorFields) {
        super(`Encountered error while processing formula with ${opType}: ${errType} (${fields})`);
        this.name = "FormulaProcessingError";
        this.opType = opType;
        this.errType = errType;
        this.fields = fields;
    }
}

interface OperationData {
    operation: OperationType;
    start: number;
    end: number;
}

class OperationItem {
    operationType: OperationType;

    constructor(operationType: OperationType) {
        this.operationType = operationType;
    }

    toKaTeX(mapping: EquationMapping): string {
        return "";
    }

    needBracket(): boolean {
        return true;
    }

    throwError(type: ErrorType, fields: ErrorFields) {
        throw new FormulaProcessingError(this.operationType, type, fields);
    }

    collectVariables(): string[] {
        return [];
    }
}

class OperationItemWithInner extends OperationItem {
    inner: OperationItem[];

    constructor(operationType: OperationType, inner: OperationItem[]) {
        super(operationType);
        this.inner = inner;
    }

    collectVariables(): string[] {
        return this.inner.map((i) => i.collectVariables()).flat();
    }
}

class AddOperation extends OperationItemWithInner {
    constructor(inner: OperationItem[]) {
        super(OperationType.ADD, inner);
    }

    toKaTeX(mapping: EquationMapping): string {
        if (this.inner.length < 2) {
            this.throwError("operandsNumber", {expect: "2", has: `${this.inner.length}`});
        }
        const fragments: string[] = this.inner.map((item) => {
            if (item.needBracket()) {
                return `(${item.toKaTeX(mapping)})`;
            } else {
                return item.toKaTeX(mapping);
            }
        });
        return fragments.join(" + ");
    }
}

class SubtractOperation extends OperationItemWithInner {
    constructor(inner: OperationItem[]) {
        super(OperationType.SUB, inner);
    }

    toKaTeX(mapping: EquationMapping): string {
        if (this.inner.length < 2) {
            this.throwError("operandsNumber", {expect: "2", has: `${this.inner.length}`});
        }
        const fragments: string[] = this.inner.map((item) => {
            if (item.needBracket()) {
                return `(${item.toKaTeX(mapping)})`;
            } else {
                return item.toKaTeX(mapping);
            }
        });
        return fragments.join(" - ");
    }
}

class MultiplyOperation extends OperationItemWithInner {
    constructor(inner: OperationItem[]) {
        super(OperationType.MUL, inner);
    }

    toKaTeX(mapping: EquationMapping): string {
        if (this.inner.length < 2) {
            this.throwError("operandsNumber", {expect: "2", has: `${this.inner.length}`});
        }
        const fragments: string[] = this.inner.map((item) => {
            if (item.needBracket()) {
                return `(${item.toKaTeX(mapping)})`;
            } else {
                return item.toKaTeX(mapping);
            }
        });
        return fragments.join(" \\cdot ");
    }
}

class DivideOperation extends OperationItemWithInner {
    constructor(inner: OperationItem[]) {
        super(OperationType.DIV, inner);
    }

    toKaTeX(mapping: EquationMapping): string {
        if (this.inner.length !== 2) {
            this.throwError("operandsNumber", {expect: "2", has: `${this.inner.length}`});
        }
        const numerator = this.inner[0].toKaTeX(mapping);
        const denominator = this.inner[1].toKaTeX(mapping);
        return `\\frac{${numerator}}{${denominator}}`;
    }

    needBracket(): boolean {
        return false;
    }
}

class LogOperation extends OperationItemWithInner {
    constructor(inner: OperationItem[]) {
        super(OperationType.LOG, inner);
    }

    toKaTeX(mapping: EquationMapping): string {
        if (this.inner.length !== 1) {
            this.throwError("operandsNumber", {expect: "1", has: `${this.inner.length}`});
        }
        const value = this.inner[0].toKaTeX(mapping);
        return `\\log_{10}{${value}}`;
    }

    needBracket(): boolean {
        return false;
    }
}

class SqrtOperation extends OperationItemWithInner {
    constructor(inner: OperationItem[]) {
        super(OperationType.SQRT, inner);
    }

    toKaTeX(mapping: EquationMapping): string {
        if (this.inner.length !== 1) {
            this.throwError("operandsNumber", {expect: "1", has: `${this.inner.length}`});
        }
        const value = this.inner[0].toKaTeX(mapping);
        return `\\sqrt{${value}}`;
    }

    needBracket(): boolean {
        return false;
    }
}

class PowOperation extends OperationItemWithInner {
    constructor(inner: OperationItem[]) {
        super(OperationType.POW, inner);
    }

    toKaTeX(mapping: EquationMapping): string {
        if (this.inner.length !== 2) {
            this.throwError("operandsNumber", {expect: "2", has: `${this.inner.length}`});
        }
        const fragments: string[] = this.inner.map((item) => {
            if (item.needBracket()) {
                return `\\left(${item.toKaTeX(mapping)}\\right)`;
            } else {
                return item.toKaTeX(mapping);
            }
        });
        const base = fragments[0];
        const exp = fragments[1];
        return `${base}^{${exp}}`;
    }

    needBracket(): boolean {
        return false;
    }
}

class CeilOperation extends OperationItemWithInner {
    constructor(inner: OperationItem[]) {
        super(OperationType.CEIL, inner);
    }

    toKaTeX(mapping: EquationMapping): string {
        if (this.inner.length !== 1) {
            this.throwError("operandsNumber", {expect: "1", has: `${this.inner.length}`});
        }
        const value = this.inner[0].toKaTeX(mapping);
        return `\\left\\lceil{${value}}\\right\\rceil`;
    }

    needBracket(): boolean {
        return false;
    }
}

class FloorOperation extends OperationItemWithInner {
    constructor(inner: OperationItem[]) {
        super(OperationType.FLOOR, inner);
    }

    toKaTeX(mapping: EquationMapping): string {
        if (this.inner.length !== 1) {
            this.throwError("operandsNumber", {expect: "1", has: `${this.inner.length}`});
        }
        const value = this.inner[0].toKaTeX(mapping);
        return `\\left\\lfloor{${value}}\\right\\rfloor`;
    }

    needBracket(): boolean {
        return false;
    }
}

class RoundOperation extends OperationItemWithInner {
    constructor(inner: OperationItem[]) {
        super(OperationType.ROUND, inner);
    }

    toKaTeX(mapping: EquationMapping): string {
        if (this.inner.length === 1) {
            const value = this.inner[0].toKaTeX(mapping);
            return `\\text{round}\\left(${value}\\right)`;
        } else if (this.inner.length === 2) {
            const value = this.inner[0].toKaTeX(mapping);
            const precision = this.inner[1].toKaTeX(mapping);
            return `\\text{round}\\left(${value}, ${precision}\\right)`;
        } else {
            this.throwError("operandsNumber", {expect: "1|2", has: `${this.inner.length}`});
        }
        return "";
    }

    needBracket(): boolean {
        return false;
    }
}

class ConstantOperation extends OperationItem {
    value: string;

    constructor(value: string) {
        super(OperationType.CONST);
        this.value = value;
    }

    toKaTeX(mapping: EquationMapping): string {
        const parsed = parseFloat(this.value);
        if (Number.isNaN(parsed)) {
            this.throwError("constNaN", {value: this.value});
        }
        return this.value.toString();
    }

    needBracket(): boolean {
        return false;
    }
}

class VariableOperation extends OperationItem {
    reference: string;

    constructor(reference: string) {
        super(OperationType.VAR);
        this.reference = reference;
    }

    toKaTeX(mapping: EquationMapping): string {
        const repr = mapping.table.get(this.reference);
        if (repr === undefined) {
            this.throwError("varUndefined", {ref: this.reference});
        }
        return `${repr}`;
    }

    needBracket(): boolean {
        return false;
    }

    collectVariables(): string[] {
        return [this.reference];
    }
}

export function cleanseFormula(formula: string): string {
    return formula.replace(/\s/g, "").replace(",", "");
}

export function resolveFormulaOperation(formula: string): OperationData {
    const opStartIndex = formula.indexOf("(");
    if (opStartIndex === -1) {
        console.log("Formula does not contain '('.");
        throw new FormulaProcessingError(null, "invalidFormula", {});
    }
    const opString = formula.substring(0, opStartIndex);
    const operation: OperationType | undefined = OPERATIONS_MAP.get(opString);
    if (operation === undefined) {
        console.log(`Unknown operation ${opString}.`);
        throw new FormulaProcessingError(null, "invalidFormula", {});
    } else {
        let opening = 0;
        let opEndIndex = -1;
        for (let i = opStartIndex; i < formula.length; i++) {
            switch (formula[i]) {
                case "(":
                    opening++;
                    break;
                case ")":
                    if (--opening === 0) {
                        opEndIndex = i;
                    }
                    break;
            }
            if (opEndIndex !== -1) {
                return {
                    operation: operation,
                    start: opStartIndex,
                    end: opEndIndex,
                };
            }
        }
    }

    console.log("Formula is not ended with ')'.");
    throw new FormulaProcessingError(null, "invalidFormula", {});
}

function parseFormulaInner(formula: string): OperationItem[] {
    let toParse = cleanseFormula(formula);
    const items: OperationItem[] = [];

    while (toParse.length > 0) {
        const opCtx = resolveFormulaOperation(toParse);
        const opLen = opCtx.operation.length;
        const current = toParse.substring(opLen + 1, opCtx.end);
        toParse = cleanseFormula(toParse.substring(opCtx.end + 1));

        switch (opCtx.operation) {
            case OperationType.VAR:
                items.push(new VariableOperation(current));
                break;
            case OperationType.CONST:
                items.push(new ConstantOperation(current));
                break;
            case OperationType.ADD:
                items.push(new AddOperation(parseFormulaInner(current)));
                break;
            case OperationType.SUB:
                items.push(new SubtractOperation(parseFormulaInner(current)));
                break;
            case OperationType.MUL:
                items.push(new MultiplyOperation(parseFormulaInner(current)));
                break;
            case OperationType.LOG:
                items.push(new LogOperation(parseFormulaInner(current)));
                break;
            case OperationType.SQRT:
                items.push(new SqrtOperation(parseFormulaInner(current)));
                break;
            case OperationType.POW:
                items.push(new PowOperation(parseFormulaInner(current)));
                break;
            case OperationType.CEIL:
                items.push(new CeilOperation(parseFormulaInner(current)));
                break;
            case OperationType.FLOOR:
                items.push(new FloorOperation(parseFormulaInner(current)));
                break;
            case OperationType.ROUND:
                items.push(new RoundOperation(parseFormulaInner(current)));
                break;
            case OperationType.DIV:
                items.push(new DivideOperation(parseFormulaInner(current)));
                break;
        }
    }

    return items;
}

function validateFormulaBrackets(formula: string): void {
    let openedBrackets = 0;
    let hasBrackets = false;
    for (let i = 0; i < formula.length; i++) {
        switch (formula[i]) {
            case "(":
                openedBrackets++;
                hasBrackets = true;
                break;
            case ")":
                openedBrackets--;
        }
        if (openedBrackets < 0) {
            console.log("Closing bracket appeared before opening in given formula.");
            throw new FormulaProcessingError(null, "invalidFormula", {});
        }
    }
    if (!hasBrackets) {
        console.log("Formula does not have any brackets.");
        throw new FormulaProcessingError(null, "invalidFormula", {});
    }
    if (openedBrackets > 0) {
        console.log("Some brackets are not closed in given formula.");
        throw new FormulaProcessingError(null, "invalidFormula", {});
    }
}

function parseFormula(formula: string): OperationItem {
    validateFormulaBrackets(formula);
    const parsed = parseFormulaInner(formula);
    if (parsed.length !== 1) {
        console.log(`Formula does not have one root operation, has ${parsed.length} instead.`);
        throw new FormulaProcessingError(null, "invalidFormula", {});
    }
    return parsed[0];
}

interface FormulaResult {
    katex: string;
    error: FormulaProcessingError | null;
    isOk: boolean;
}

export function formulaToKaTeX(formula: string, mapping: EquationMapping): FormulaResult {
    try {
        return {
            katex: parseFormula(formula).toKaTeX(mapping),
            error: null,
            isOk: true,
        };
    } catch (e) {
        if (e instanceof FormulaProcessingError) {
            return {
                katex: "",
                error: e,
                isOk: false,
            };
        } else {
            throw e;
        }
    }
}

export function variablesToEquationMapping(variables: VariableSimpleDTO[]): EquationMapping {
    return {
        table: new Map<string, string>(variables.map((v) => [v.id, v.name])),
    };
}

export function isFormulaValid(formula: string, variables: VariableSimpleDTO[]): boolean {
    try {
        parseFormula(formula).toKaTeX(variablesToEquationMapping(variables));
        return true;
    } catch (e) {
        return false;
    }
}

export function isConversionValid(formula: string): boolean {
    const conversionVars: EquationMapping = {
        table: new Map<string, string>([["X", "X"]]),
    };
    try {
        parseFormula(formula).toKaTeX(conversionVars);
        return true;
    } catch (e) {
        return false;
    }
}

export function extractVariables(formula: string): string[] {
    try {
        return parseFormula(formula).collectVariables();
    } catch (e) {
        return [];
    }
}
