import React, {CSSProperties} from "react";
import {convertToRaw, EditorState, RawDraftContentState} from "draft-js";
import {draftToMarkdown, markdownToDraft} from "markdown-draft-js";
import {Box, TextField} from "@material-ui/core";
import MUIRichTextEditor from "mui-rte";
import {useTranslation} from "react-i18next";

const descriptionControls: string[] = [
    "bold",
    "italic",
    "underline",
    "link",
    "numberList",
    "bulletList",
    "undo",
    "redo",
];

interface MarkdownTextFieldState {
    content: RawDraftContentState;
}

interface MarkdownTextFieldProps {
    variant?: "outlined" | "standard" | "filled";
    label: string;
    helperText?: string;
    value: string;
    className?: string;
    style?: CSSProperties;
    fullWidth?: boolean;
    required?: boolean;
    disabled?: boolean;
    error?: boolean;
    margin?: "normal" | "dense" | "none";

    onChange(value: string): void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const InputComponent = ({inputRef, ...other}: any) => <div {...other} />;

export const MarkdownTextField: React.FC<MarkdownTextFieldProps> = (props: MarkdownTextFieldProps) => {
    //== Init =================================================================
    const initState: MarkdownTextFieldState = {
        content: markdownToDraft(props.value),
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [state, setState] = React.useState(initState);
    const [t] = useTranslation("common");
    //== Handlers =============================================================
    const handleChange = (editorState: EditorState): void => {
        props.onChange(draftToMarkdown(convertToRaw(editorState.getCurrentContent())));
    };
    //== Render ===============================================================
    const editor = (
        <MUIRichTextEditor
            label={t("rte.placeholder")}
            value={JSON.stringify(state.content)}
            controls={descriptionControls}
            onChange={handleChange}
        />
    );
    return (
        <TextField
            variant={props.variant ?? "outlined"}
            label={props.label}
            helperText={props.helperText}
            fullWidth={props.fullWidth ?? true}
            required={props.required}
            margin={props.margin}
            disabled={props.disabled}
            error={props.error}
            style={props.style}
            className={props.className}
            multiline
            InputLabelProps={{shrink: true}}
            InputProps={{
                inputComponent: InputComponent,
                style: {padding: "0"},
            }}
            inputProps={{children: editor}}
        />
    );
};

export interface MarkdownRenderProps {
    value: string;
    className?: string;
    style?: CSSProperties;
}

export const MarkdownRender: React.FC<MarkdownRenderProps> = (props: MarkdownRenderProps) => {
    return (
        <Box className={props.className} style={props.style}>
            <MUIRichTextEditor
                readOnly
                value={JSON.stringify(markdownToDraft(props.value))}
                controls={[]}
                toolbar={false}
            />
        </Box>
    );
};
