import React from "react";
import {extractVariables, formulaToKaTeX} from "../../../models/formulas";
import "katex/dist/katex.min.css";
import TeX from "@matejmazur/react-katex";
import {VariableSimpleDTO} from "../../../models/library";
import {useTranslation} from "react-i18next";
import {Alert} from "@material-ui/lab";
import Utils from "../../../utils";
import {Box} from "@material-ui/core";
import {FormulaVariables} from "./FormulaVariables";

interface FormulaDisplayProps {
    formula: string;
    variables: VariableSimpleDTO[];
    errorInfo?: boolean;
    showVariables?: boolean;
}

export const FormulaDisplay: React.FC<FormulaDisplayProps> = (props: FormulaDisplayProps) => {
    //== Init =================================================================
    const variables = extractVariables(props.formula);
    const variablesOrder = Utils.uniq(variables);
    const mappingTable = new Map<string, string>(variablesOrder.map((id, index) => [id, `x_{${index + 1}}`]));
    const result = formulaToKaTeX(props.formula, {table: mappingTable});
    const [t] = useTranslation("libraries");
    //== Render ===============================================================
    if (result.isOk) {
        return (
            <Box>
                <TeX math={result.katex} block />
                {props.showVariables && <FormulaVariables formula={props.formula} variables={props.variables} />}
            </Box>
        );
    } else if (result.error !== null && props.errorInfo === true) {
        const err = `formula.error.${result.error.errType}.${result.error.opType ?? "parse"}`;
        return <Alert severity="warning">{t(err, result.error.fields)}</Alert>;
    } else {
        return <Alert severity="error">{t("formula.error.generic")}</Alert>;
    }
};
