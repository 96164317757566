import React, {PropsWithChildren, ReactNode} from "react";
import {LibraryDTO, LibrarySimpleDTO} from "../../../models/library";
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {useTranslation} from "react-i18next";
import {Box, Button} from "@material-ui/core";
import {LibraryAdder} from "./LibraryPartsAdder";
import Loading from "../../common/Loading";
import {CommunicationError} from "../../common/errors";
import {API, createApiConfig} from "../../../utils";
import {AxiosError} from "axios";
import {useKeycloak} from "@react-keycloak/web";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

interface FormDialogProps {
    id: string;
    open: boolean;
    maxWidth: "sm" | "md";
    actions?: ReactNode[];
    children?: ReactNode;
    onClose: () => void;
}

export const LibraryAddingDialog: React.FC<FormDialogProps> = (props: PropsWithChildren<FormDialogProps>) => {
    const [t] = useTranslation("libraries");
    return (
        <Dialog onClose={props.onClose} open={props.open} fullWidth maxWidth={props.maxWidth}>
            <DialogTitle id={props.id} onClose={props.onClose}>
                {t("library.actions.adding")}
            </DialogTitle>
            {props.children}
            {props.actions && <DialogActions>{props.actions}</DialogActions>}
        </Dialog>
    );
};

interface LibraryUseDialogProps {
    id: string;
    open: boolean;
    targetLibrary: LibraryDTO;
    onClose: () => void;
    onUpdate: (library: LibraryDTO) => void;
}

interface LibraryUseDialogState {
    name: "loading" | "pick_library" | "select_parts" | "failed";
    libraries: LibrarySimpleDTO[];
    selectedLibrary: LibrarySimpleDTO | null;
}

export const LibraryUseDialog: React.FC<LibraryUseDialogProps> = (props: LibraryUseDialogProps) => {
    //== Init =================================================================
    const [t] = useTranslation("libraries");
    const {keycloak, initialized} = useKeycloak();
    const initState: LibraryUseDialogState = {
        name: "loading",
        libraries: [],
        selectedLibrary: null,
    };
    const [state, setState] = React.useState(initState);
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<LibrarySimpleDTO[]>("/libraries/shared", createApiConfig(keycloak, initialized))
                .then((res) => {
                    setState({...state, libraries: res.data, name: "pick_library"});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [state, setState, keycloak, initialized]);
    //== Handlers =============================================================
    const handleBack = () => {
        setState({...state, selectedLibrary: null, name: "pick_library"});
    };
    const handleClose = () => {
        props.onClose();
    };
    const handleUpdate = (library: LibraryDTO) => {
        props.onUpdate(library);
    };
    const handlePick = (library: LibrarySimpleDTO) => {
        setState({...state, selectedLibrary: library, name: "select_parts"});
    };
    //== Render ===============================================================
    if (state.name === "loading") {
        const actions = [
            <Button key="close" onClick={handleClose}>
                {t("library.add_dialog.close")}
            </Button>,
        ];
        return (
            <LibraryAddingDialog id={props.id} open={props.open} onClose={handleClose} actions={actions} maxWidth="sm">
                <Loading />
            </LibraryAddingDialog>
        );
    }
    if (state.name === "failed") {
        const actions = [
            <Button key="close" onClick={handleClose}>
                {t("library.add_dialog.close")}
            </Button>,
        ];
        return (
            <LibraryAddingDialog id={props.id} open={props.open} onClose={handleClose} actions={actions} maxWidth="sm">
                <CommunicationError />
            </LibraryAddingDialog>
        );
    }
    if (state.name === "select_parts" && state.selectedLibrary !== null) {
        return (
            <LibraryAddingDialog id={props.id} open={props.open} onClose={handleClose} maxWidth="md">
                <LibraryAdder
                    sourceLibraryUUID={state.selectedLibrary.id}
                    targetLibrary={props.targetLibrary}
                    onUpdate={handleUpdate}
                    onBack={handleBack}
                />
            </LibraryAddingDialog>
        );
    }
    // default
    const actions = [
        <Button key="close" onClick={handleClose}>
            {t("library.add_dialog.close")}
        </Button>,
    ];
    const selectableLibs = state.libraries.filter((lib) => lib.id !== props.targetLibrary.id);
    return (
        <LibraryAddingDialog id={props.id} open={props.open} onClose={handleClose} actions={actions} maxWidth="sm">
            <DialogContent>
                <Box style={{marginBottom: "0.5em"}}>{t("library.add_dialog.title.pick_library")}</Box>
                {selectableLibs.map((lib) => {
                    return (
                        <Box key={lib.id} display="flex" alignItems="center" style={{margin: "1em"}}>
                            <Box flexGrow={1}>{lib.name}</Box>
                            <Button onClick={() => handlePick(lib)}>{t("library.add_dialog.continue")}</Button>
                        </Box>
                    );
                })}
                {selectableLibs.length === 0 && <p>{t("library.add_dialog.empty")}</p>}
            </DialogContent>
        </LibraryAddingDialog>
    );
};
