import React from "react";
import {Redirect, Route, RouteProps} from "react-router";

import {useKeycloak} from "@react-keycloak/web";
import {NotAuthorized} from "../components/common/errors";
import ROUTES from "./routes";
import {hasUserRole, ROLES} from "../utils/auth";

interface PrivateRouteProps extends RouteProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: any;
    role?: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = (props: PrivateRouteProps) => {
    //== Init =================================================================
    const {component: Component, role, ...rest} = props;
    const {keycloak, initialized} = useKeycloak();
    //== Render ===============================================================
    const renderPrivate: React.FC<RouteProps> = (props) => <Component {...props} />;
    const renderLoginRedirect: React.FC<RouteProps> = (props: RouteProps) => (
        <Redirect
            to={{
                pathname: ROUTES.login.path,
                state: {from: props.location, referrer: window.location.href},
            }}
        />
    );
    if (!initialized || !keycloak) {
        return null; // TODO: display error if not refresh after some time
    } else if (!keycloak.authenticated) {
        return <Route {...rest} render={renderLoginRedirect} />;
    } else if (role !== undefined && hasUserRole(keycloak, role)) {
        return <Route {...rest} render={renderPrivate} />;
    } else {
        return <NotAuthorized />;
    }
};

PrivateRoute.defaultProps = {
    role: ROLES.user,
};

export default PrivateRoute;
