import React, {CSSProperties} from "react";
import {Box, Tabs, TabsActions} from "@material-ui/core";
import {AppContext, TAppContext} from "../layout/context";
import {useTheme} from "@material-ui/core/styles";

interface TabProps {
    children?: React.ReactNode;
    value: number;
    index: number;
}

export const TabContent: React.FC<TabProps> = (tabProps: TabProps) => {
    if (tabProps.index !== tabProps.value) return null;
    return <Box>{tabProps.children}</Box>;
};

interface CustomTabsProps {
    value: number;
    //eslint-disable-next-line @typescript-eslint/ban-types
    onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
    style?: CSSProperties;
    className?: string;
    indicatorColor?: "secondary" | "primary";
    textColor?: "secondary" | "primary" | "inherit";
    variant?: "standard" | "scrollable" | "fullWidth";
    centered?: boolean;
    children: React.ReactNode;
}

export const CustomTabs: React.FC<CustomTabsProps> = (props: CustomTabsProps) => {
    //== Init =================================================================
    const tabsActions = React.useRef<TabsActions>(null);
    const ctx: TAppContext = React.useContext(AppContext);
    const theme = useTheme();
    //== Effects ==============================================================
    React.useLayoutEffect(() => {
        const duration = ctx.drawer
            ? theme.transitions.duration.enteringScreen
            : theme.transitions.duration.leavingScreen;
        setTimeout(() => {
            if (tabsActions.current !== null && tabsActions.current !== undefined) {
                tabsActions.current.updateIndicator();
            }
        }, duration + 50);
    }, [ctx.drawer]);
    //== Render ===============================================================
    return (
        <Tabs action={tabsActions} {...props}>
            {props.children}
        </Tabs>
    );
};
