import React from "react";
import {ProfileDTO} from "../../models/user";

export interface TAppContext {
    profile: ProfileDTO | null;
    drawer: boolean;
}

const APP_CONTEXT_DEFAULT: TAppContext = {
    profile: null,
    drawer: false,
};

export const AppContext = React.createContext(APP_CONTEXT_DEFAULT);

interface AppContextProps {
    profile: ProfileDTO | null;
    drawer: boolean;
    children: React.ReactNode;
}

export const AppContextProvider: React.FC<AppContextProps> = (props: AppContextProps) => {
    //== Init =================================================================
    const ctx: TAppContext = {
        profile: props.profile,
        drawer: props.drawer,
    };
    //== Render ===============================================================
    return <AppContext.Provider value={ctx}>{props.children}</AppContext.Provider>;
};
