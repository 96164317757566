import React, {ChangeEvent} from "react";
import {useKeycloak} from "@react-keycloak/web";
// Project imports
import Loading from "./Loading";
import {ServerCommunicationAlert} from "./errors";
import {OrganizationDTO} from "../../models/organization";
import {API, createApiConfig} from "../../utils";
import {AxiosError, AxiosResponse} from "axios";
// Material-UI imports
import Autocomplete from "@material-ui/lab/Autocomplete";
import {TextField} from "@material-ui/core";
import {useTranslation} from "react-i18next";

interface OrganizationInputProps {
    query: "all" | "own";
    value?: OrganizationDTO | null;
    variant?: "outlined" | "standard" | "filled";
    margin?: "normal" | "dense" | "none";
    label: string;
    placeholder?: string;
    fullWidth?: boolean;
    className?: string;
    disabled?: boolean;

    onChange?(organization: OrganizationDTO): void;
}

interface OrganizationInputState {
    organizations: OrganizationDTO[];
    value: OrganizationDTO | null;
    name: string;
}

export const OrganizationInput: React.FC<OrganizationInputProps> = (props: OrganizationInputProps) => {
    const initState: OrganizationInputState = {
        organizations: [],
        value: props.value ?? null,
        name: "loading",
    };
    const [state, setState] = React.useState(initState);
    const {keycloak, initialized} = useKeycloak();
    const [t] = useTranslation("organization");
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<OrganizationDTO[]>(`/organizations/list/${props.query}`, createApiConfig(keycloak, initialized))
                .then((res: AxiosResponse<OrganizationDTO[]>) => {
                    setState({...state, organizations: res.data || [], name: "loaded"});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [state, setState, keycloak, initialized]);
    //== Handlers =============================================================
    //eslint-disable-next-line @typescript-eslint/ban-types
    const handleChange = (event: ChangeEvent<{}>, value: OrganizationDTO | null): void => {
        if (props.onChange && value != null) {
            props.onChange(value);
        }
        setState({...state, value: value});
    };
    //== Render ===============================================================
    if (state.name === "loading") {
        return <Loading />;
    }
    if (state.name === "failed") {
        return <ServerCommunicationAlert />;
    }
    return (
        <Autocomplete
            options={state.organizations}
            getOptionLabel={(organization: OrganizationDTO): string => organization.name}
            getOptionSelected={(option, value): boolean => {
                return option.id === value.id;
            }}
            value={state.value}
            filterSelectedOptions
            onChange={handleChange}
            renderInput={(params): JSX.Element => (
                <TextField
                    {...params}
                    variant={props.variant}
                    label={props.label}
                    placeholder={props.placeholder}
                    fullWidth={props.fullWidth}
                    className={props.className}
                    disabled={props.disabled}
                    margin={props.margin}
                />
            )}
            loadingText={t("organization.select.loading")}
            noOptionsText={t("organization.select.empty")}
            openText={t("organization.select.open")}
            closeText={t("organization.select.close")}
            clearText={t("organization.select.clear")}
        />
    );
};
