import i18n from "i18next";
import {initReactI18next} from "react-i18next";

import commonCS from "./translations/cs/common.json";
import staticPagesCS from "./translations/cs/static-pages.json";
import userCS from "./translations/cs/user.json";
import organizationCS from "./translations/cs/organization.json";
import organizationMembersCS from "./translations/cs/organization-members.json";
import trackedIndicatorsCS from "./translations/cs/tracked-indicators.json";
import webpagesCS from "./translations/cs/webpages.json";
import librariesCS from "./translations/cs/libraries.json";
import valuesCS from "./translations/cs/values.json";

i18n.use(initReactI18next).init({
    defaultNS: "common",
    lng: "cs",
    fallbackLng: "cs",
    debug: true,

    interpolation: {
        escapeValue: false,
    },
    resources: {
        cs: {
            common: commonCS,
            staticPages: staticPagesCS,
            user: userCS,
            organization: organizationCS,
            organizationMembers: organizationMembersCS,
            trackedIndicators: trackedIndicatorsCS,
            webpages: webpagesCS,
            libraries: librariesCS,
            values: valuesCS,
        },
    },
});
