import React from "react";
// Material UI imports
import {Backdrop, CircularProgress, TableCell, TableRow} from "@material-ui/core";
import {useStyles} from "../../styles";

export const LoadingRow: React.FC = () => {
    return (
        <TableRow>
            <TableCell colSpan={10}>
                <Loading />
            </TableCell>
        </TableRow>
    );
};

export const LoadingBackdrop: React.FC = () => {
    const classes = useStyles();
    return (
        <Backdrop className={classes.backdrop} open>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};

export const Loading: React.FC = () => {
    const classes = useStyles();
    return (
        <div className={classes.centerFlex}>
            <CircularProgress />
        </div>
    );
};

export default Loading;
