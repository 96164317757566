import React from "react";
import {useKeycloak} from "@react-keycloak/web";

const Keycloak: React.FC = () => {
    const {keycloak, initialized} = useKeycloak();
    return (
        <div>
            <h1>Keycloak info</h1>
            <p>Keycloak is {initialized ? "" : "NOT"} initialized</p>
            <pre>{JSON.stringify(keycloak, null, 2)}</pre>
        </div>
    );
};

export default Keycloak;
