import React from "react";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
// Material UI imports
import {Box, Checkbox, Divider, FormControlLabel} from "@material-ui/core";
// Project imports
import {InfoNotification, SuccessNotification} from "../../../common/notifications";
import {ServerCommunicationAlert} from "../../../common/errors";
import {Loading} from "../../../common/Loading";
import API, {createApiConfig} from "../../../../utils/API";
import {AxiosError} from "axios";
import {
    TrackedDomainInGroupDTO,
    TrackedDomainInGroupUpdateDTO,
    TrackedGoalInGroupDTO,
    TrackedGoalInGroupUpdateDTO,
    TrackedIndicatorGroupWithContentDTO,
    TrackedIndicatorGroupWithContentUpdateDTO,
    TrackedIndicatorInGroupDTO,
    TrackedIndicatorInGroupUpdateDTO,
} from "../../../../models/trackedIndicators";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {DomainDTO, GoalDTO, IndicatorDTO} from "../../../../models/library";
import TrackedDomain, {containsDomainIndicators, trackedDomainWithPublicContent} from "./TrackedDomain";
import TrackedIndicatorGroupEditorAdd from "./adding/TrackedIndicatorGroupEditorAdd";
import {DomainWithContent, GoalWithContent} from "./adding/TrackedIndicatorGroupEditorAddingInterfaces";
import ItemPositionInLibrary from "../../../library/editor/util/LibraryItemPosition";
import LibraryItemMoveTarget from "../../../library/editor/util/LibraryItemMoveTarget";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        trackedIndicators: {
            minHeight: theme.spacing(5),
            width: "100%",
        },
        trackedItemRow: {
            display: "flex",
            alignItems: "center",
        },
        domainShiftTarget: {
            marginLeft: theme.spacing(1) + 48,
        },
    })
);

interface TrackedIndicatorGroupRowProps {
    trackedIndicatorGroup: TrackedIndicatorGroupWithContentDTO;

    handlePublicSwitch(): void;
}

const TrackedIndicatorGroupRow: React.FC<TrackedIndicatorGroupRowProps> = (props: TrackedIndicatorGroupRowProps) => {
    //== Init ===================================================================
    const classes = useStyles();
    const [t] = useTranslation("trackedIndicators");
    return (
        <Box>
            <Box className={classes.trackedItemRow} style={{margin: "1em"}}>
                <Box flexGrow={1}>
                    <p style={{fontStyle: "italic"}}>
                        {t("editor.explanationAbout")}
                        <br />
                        {t("editor.explanationAdding") + " " + t("ui.addIndicators")}.
                    </p>
                </Box>
                <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            checked={props.trackedIndicatorGroup.public}
                            disabled={!containsGroupIndicators(props.trackedIndicatorGroup)}
                            onChange={() => props.handlePublicSwitch()}
                            name="public"
                        />
                    }
                    label={t("use.public_group")}
                />
            </Box>
        </Box>
    );
};

const containsGroupIndicators = (group: TrackedIndicatorGroupWithContentDTO): boolean => {
    return group.trackedDomains.some((domainUse) => containsDomainIndicators(domainUse));
};

interface TrackedIndicatorGroupProps {
    trackedIndicatorGroup: TrackedIndicatorGroupWithContentDTO | string;

    onChange(trackedIndicatorGroup: TrackedIndicatorGroupWithContentDTO): void;
}

interface TrackedItemMoveBase {
    sourcePosition?: ItemPositionInLibrary | null;
    targetPosition?: ItemPositionInLibrary | null;

    libraryItem?: DomainWithContent | GoalWithContent | IndicatorDTO | null;
}

interface TrackedItemShift extends TrackedItemMoveBase {
    sourcePosition: ItemPositionInLibrary;
    targetPosition: ItemPositionInLibrary;

    libraryItem?: null;
}

interface TrackedItemAdd extends TrackedItemMoveBase {
    sourcePosition?: null;
    targetPosition: ItemPositionInLibrary;

    libraryItem: DomainWithContent | GoalWithContent | IndicatorDTO;
}

interface TrackedItemRemove extends TrackedItemMoveBase {
    sourcePosition: ItemPositionInLibrary;
    targetPosition?: null;

    libraryItem?: null;
}

type TrackedItemMove = TrackedItemAdd | TrackedItemRemove | TrackedItemShift;

interface TrackedIndicatorGroupEditorContentState {
    trackedIndicatorGroup: TrackedIndicatorGroupWithContentDTO;
    name: "loading" | "ready" | "failed";
    action: "init" | "refresh";
    shiftingItemPosition: ItemPositionInLibrary | null;
}

const TrackedIndicatorsEditorContent: React.FC<TrackedIndicatorGroupProps> = (props: TrackedIndicatorGroupProps) => {
    //== Init ===================================================================
    const classes = useStyles();
    const [t] = useTranslation("trackedIndicators");
    const {keycloak, initialized} = useKeycloak();
    const providedFullTrackedIndicatorGroup = (props.trackedIndicatorGroup as TrackedIndicatorGroupWithContentDTO)
        .trackedDomains;
    const initState: TrackedIndicatorGroupEditorContentState = providedFullTrackedIndicatorGroup
        ? {
              trackedIndicatorGroup: props.trackedIndicatorGroup as TrackedIndicatorGroupWithContentDTO,
              name: "ready",
              action: "init",
              shiftingItemPosition: null,
          }
        : {
              trackedIndicatorGroup: {} as TrackedIndicatorGroupWithContentDTO,
              name: "loading",
              action: "init",
              shiftingItemPosition: null,
          };
    const initSaveState = {
        name: "init" as "init" | "changed" | "saved",
        savedTrackedIndicatorGroup: providedFullTrackedIndicatorGroup
            ? (props.trackedIndicatorGroup as TrackedIndicatorGroupWithContentDTO)
            : null,
        lastSave: new Date().getTime() - 5000,
    };
    const [state, setState] = React.useState(initState);
    const [saveState, setSaveState] = React.useState(initSaveState);
    const domainUsesInGroup = state.trackedIndicatorGroup.trackedDomains
        ? state.trackedIndicatorGroup.trackedDomains
        : [];
    const domainIdsInGroup = new Set(domainUsesInGroup.map((domainUseInGroup) => domainUseInGroup.domain.id));
    const goalUsesInGroup = domainUsesInGroup.flatMap((domainUseInGroup) => domainUseInGroup.trackedGoals);
    const goalIdsInGroup = new Set(goalUsesInGroup.map((goalUseInGroup) => goalUseInGroup.goal.id));
    const indicatorUsesInGroup = goalUsesInGroup.flatMap((goalUseInGroup) => goalUseInGroup.trackedIndicators);
    const indicatorIdsInGroup = new Set(
        indicatorUsesInGroup.map((indicatorUseInGroup) => indicatorUseInGroup.indicator.id)
    );
    //== Effects ================================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<TrackedIndicatorGroupWithContentDTO>(
                `/tracked-indicator-groups/with-content/${props.trackedIndicatorGroup}`,
                createApiConfig(keycloak, initialized)
            )
                .then((trackedIndicatorGroupRes) => {
                    const newTrackedIndicatorGroup = trackedIndicatorGroupRes.data;
                    console.log("changing state to ready");
                    setState({
                        ...state,
                        trackedIndicatorGroup: newTrackedIndicatorGroup,
                        name: "ready",
                    });
                    setSaveState({
                        ...initSaveState,
                        savedTrackedIndicatorGroup: newTrackedIndicatorGroup,
                    });
                    props.onChange(newTrackedIndicatorGroup);
                })
                .catch((err: AxiosError) => {
                    console.log("changing state to failed");
                    setState({...state, name: "failed"});
                });
        }
    }, [keycloak, initialized, state, setState, setSaveState, initSaveState, props.trackedIndicatorGroup]);
    React.useEffect(() => {
        console.log("TrackedIndicatorsEditor: useEffect - state.name:");
        console.log(state.name);
        console.log("TrackedIndicatorsEditor: useEffect - saveState.name:");
        console.log(saveState.name);
        console.log("TrackedIndicatorsEditor: useEffect - saveState.savedTrackedIndicatorGroup:");
        console.log(saveState.savedTrackedIndicatorGroup);
        console.log("TrackedIndicatorsEditor: useEffect - state.trackedIndicatorGroup:");
        console.log(state.trackedIndicatorGroup);
        console.log(
            "TrackedIndicatorsEditor: useEffect - saveState.savedTrackedIndicatorGroup !== state.trackedIndicatorGroup:"
        );
        console.log(saveState.savedTrackedIndicatorGroup !== state.trackedIndicatorGroup);
        if (providedFullTrackedIndicatorGroup) {
            const newTrackedIndicatorGroup = props.trackedIndicatorGroup as TrackedIndicatorGroupWithContentDTO;
            if (newTrackedIndicatorGroup.name !== state.trackedIndicatorGroup.name) {
                setState({
                    ...state,
                    trackedIndicatorGroup: {
                        ...state.trackedIndicatorGroup,
                        name: newTrackedIndicatorGroup.name,
                    },
                });
                if (saveState.savedTrackedIndicatorGroup)
                    setSaveState({
                        ...saveState,
                        savedTrackedIndicatorGroup: {
                            ...saveState.savedTrackedIndicatorGroup,
                            name: newTrackedIndicatorGroup.name,
                        },
                    });
            }
            if (newTrackedIndicatorGroup.description !== state.trackedIndicatorGroup.description) {
                setState({
                    ...state,
                    trackedIndicatorGroup: {
                        ...state.trackedIndicatorGroup,
                        description: newTrackedIndicatorGroup.description,
                    },
                });
                if (saveState.savedTrackedIndicatorGroup)
                    setSaveState({
                        ...saveState,
                        savedTrackedIndicatorGroup: {
                            ...saveState.savedTrackedIndicatorGroup,
                            description: newTrackedIndicatorGroup.description,
                        },
                    });
            }
        }
        if (
            saveState.name !== "changed" &&
            saveState.savedTrackedIndicatorGroup &&
            saveState.savedTrackedIndicatorGroup !== state.trackedIndicatorGroup &&
            state.name !== "failed"
        ) {
            console.log("TrackedIndicatorsEditor: useEffect - switching state to changed");
            setSaveState({...saveState, name: "changed"});
        } else if (state.name !== "failed" && saveState.name === "changed") {
            console.log("TrackedIndicatorsEditor: useEffect - state is changed");
            const save = () => {
                console.log("TrackedIndicatorsEditor: useEffect - saving...");
                const trackedIndicatorGroupUpdate = {
                    ...state.trackedIndicatorGroup,
                    trackedDomains: state.trackedIndicatorGroup.trackedDomains.map((trackedDomain) => {
                        return {
                            ...trackedDomain,
                            domain: trackedDomain.domain.id,
                            trackedGoals: trackedDomain.trackedGoals.map((trackedGoal) => {
                                return {
                                    ...trackedGoal,
                                    goal: trackedGoal.goal.id,
                                    trackedIndicators: trackedGoal.trackedIndicators.map((trackedIndicator) => {
                                        return {
                                            ...trackedIndicator,
                                            indicator: trackedIndicator.indicator.id,
                                        } as TrackedIndicatorInGroupUpdateDTO;
                                    }),
                                } as TrackedGoalInGroupUpdateDTO;
                            }),
                        } as TrackedDomainInGroupUpdateDTO;
                    }),
                } as TrackedIndicatorGroupWithContentUpdateDTO;
                API.put<TrackedIndicatorGroupWithContentDTO>(
                    `/tracked-indicator-groups/with-content/${state.trackedIndicatorGroup.publicId}`,
                    trackedIndicatorGroupUpdate,
                    createApiConfig(keycloak, initialized)
                )
                    .then(() => {
                        console.log("TrackedIndicatorsEditor: useEffect - saved, changing setSaveState");
                        setSaveState({
                            ...saveState,
                            name: "saved",
                            savedTrackedIndicatorGroup: state.trackedIndicatorGroup,
                            lastSave: new Date().getTime(),
                        });
                        props.onChange(state.trackedIndicatorGroup);
                    })
                    .catch((err: AxiosError) => {
                        setState({...state, name: "failed"});
                    });
            };
            const timeToWait = Math.max(5000 - new Date().getTime() + saveState.lastSave, 1000);
            console.log("waiting to save for " + timeToWait + " ms");
            const timer = setTimeout(() => {
                if (saveState.savedTrackedIndicatorGroup !== state.trackedIndicatorGroup) {
                    save();
                }
            }, timeToWait);
            return () => clearTimeout(timer);
        }
    }, [initialized, keycloak, state, saveState, setSaveState]);

    //== Handlers ===============================================================

    const newItems = <ItemType extends TrackedDomainInGroupDTO | TrackedGoalInGroupDTO | TrackedIndicatorInGroupDTO>(
        sourceIndex: number | null,
        targetIndex: number | null,
        oldItems: ItemType[],
        newItem: ItemType
    ) => {
        console.log("TrackedIndicatorsEditor - newItems - sourceIndex:");
        console.log(sourceIndex);
        console.log("TrackedIndicatorsEditor - newItems - targetIndex:");
        console.log(targetIndex);
        console.log("TrackedIndicatorsEditor - newItems - oldItems:");
        console.log(oldItems);
        console.log("TrackedIndicatorsEditor - newItems - newItem:");
        console.log(newItem);
        const isDragWithinSameContainer = targetIndex !== null && sourceIndex !== null;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const targetIndexBeforeSourceIndex = targetIndex !== null && sourceIndex !== null && targetIndex < sourceIndex!;
        const isTargetContainerFirst = targetIndexBeforeSourceIndex || (targetIndex !== null && sourceIndex === null);

        console.log("TrackedIndicatorsEditor - newItems - isDragWithinSameContainer:");
        console.log(isDragWithinSameContainer);
        console.log("TrackedIndicatorsEditor - newItems - targetIndexBeforeSourceIndex:");
        console.log(targetIndexBeforeSourceIndex);
        console.log("TrackedIndicatorsEditor - newItems - isTargetContainerFirst:");
        console.log(isTargetContainerFirst);
        let newItems = oldItems
            .slice(
                0,
                isTargetContainerFirst
                    ? targetIndex ??
                          (() => {
                              throw new Error("not existing targetIndex");
                          })()
                    : sourceIndex ??
                          (() => {
                              throw new Error("not existing sourceIndex");
                          })()
            )
            .concat(isTargetContainerFirst ? [newItem] : [])
            .concat(
                oldItems
                    .slice(
                        isTargetContainerFirst
                            ? targetIndex ??
                                  (() => {
                                      throw new Error("not existing targetIndex");
                                  })()
                            : (sourceIndex ??
                                  (() => {
                                      throw new Error("not existing sourceIndex");
                                  })()) + 1,
                        !isDragWithinSameContainer
                            ? oldItems.length
                            : targetIndexBeforeSourceIndex
                            ? sourceIndex ??
                              (() => {
                                  throw new Error("not existing sourceIndex");
                              })()
                            : (targetIndex ??
                                  (() => {
                                      throw new Error("not existing targetIndex");
                                  })()) + 1
                    )
                    .map((item) => {
                        return {
                            ...item,
                            orderNumber: item.orderNumber + (isTargetContainerFirst ? 1 : -1),
                        };
                    })
            );
        if (isDragWithinSameContainer) {
            newItems = newItems.concat(isTargetContainerFirst ? [] : [newItem]).concat(
                oldItems.slice(
                    isTargetContainerFirst
                        ? (sourceIndex ??
                              (() => {
                                  throw new Error("not existing sourceIndex");
                              })()) + 1
                        : (targetIndex ??
                              (() => {
                                  throw new Error("not existing targetIndex");
                              })()) + 1
                )
            );
        }
        console.log("TrackedIndicatorsEditor - newItems - returned:");
        console.log(newItems);
        return newItems;
    };
    const trackedIndicatorGroupAfterMoveDomain = (
        move: TrackedItemMove,
        trackedIndicatorGroup: TrackedIndicatorGroupWithContentDTO
    ) => {
        console.log("TrackedIndicatorsEditor - trackedIndicatorGroupAfterMove - domain - !move.targetPosition:");
        console.log(!move.targetPosition);
        console.log("TrackedIndicatorsEditor - trackedIndicatorGroupAfterMove - domain - move.sourcePosition:");
        console.log(move.sourcePosition);
        const targetIndex =
            move.targetPosition?.domainIndex === undefined
                ? null
                : Math.min(move.targetPosition.domainIndex, trackedIndicatorGroup.trackedDomains.length);
        const newTrackedDomain = (
            !move.targetPosition
                ? {}
                : move.sourcePosition
                ? {
                      ...trackedIndicatorGroup.trackedDomains[move.sourcePosition.domainIndex],
                      orderNumber: targetIndex,
                  }
                : {
                      id: "null",
                      trackedGoals: (move.libraryItem as DomainWithContent).goals
                          .filter((goal) => !goalIdsInGroup.has(goal.id))
                          .map((goal, index) => {
                              return {
                                  id: "null",
                                  trackedIndicators: goal.indicators
                                      .filter((indicator) => !indicatorIdsInGroup.has(indicator.id))
                                      .map((indicator, index) => {
                                          return {
                                              id: "null",
                                              indicator: indicator,
                                              orderNumber: index,
                                              priority: 0,
                                              public: false,
                                              targetValue: null,
                                          };
                                      }),
                                  goal: goal,
                                  orderNumber: index,
                                  priority: 0,
                                  public: false,
                              };
                          }),
                      domain: move.libraryItem as DomainDTO,
                      orderNumber: targetIndex,
                      priority: 0,
                      public: false,
                  }
        ) as TrackedDomainInGroupDTO;
        console.log("TrackedIndicatorsEditor - trackedIndicatorGroupAfterMove - domain - newTrackedDomain:");
        console.log(newTrackedDomain);
        console.log(
            "TrackedIndicatorsEditor - trackedIndicatorGroupAfterMove - domain - move.sourcePosition?.domainIndex:"
        );
        console.log(move.sourcePosition?.domainIndex);
        console.log("TrackedIndicatorsEditor - trackedIndicatorGroupAfterMove - domain - new trackedDomains:");
        console.log(
            newItems<TrackedDomainInGroupDTO>(
                move.sourcePosition?.domainIndex === undefined ? null : move.sourcePosition.domainIndex,
                targetIndex,
                trackedIndicatorGroup.trackedDomains,
                newTrackedDomain
            )
        );
        const newDomains = newItems<TrackedDomainInGroupDTO>(
            move.sourcePosition?.domainIndex === undefined ? null : move.sourcePosition.domainIndex,
            targetIndex,
            trackedIndicatorGroup.trackedDomains,
            newTrackedDomain
        );
        return {
            ...trackedIndicatorGroup,
            public: trackedIndicatorGroup.public && newDomains.some((it) => it.public),
            trackedDomains: newDomains,
        };
    };

    const trackedIndicatorGroupAfterMoveGoal = (
        move: TrackedItemMove,
        trackedIndicatorGroup: TrackedIndicatorGroupWithContentDTO,
        existingDomainIndex: number
    ) => {
        console.log("TrackedIndicatorsEditor - trackedIndicatorGroupAfterMove - goal - !move.targetPosition:");
        console.log(!move.targetPosition);
        console.log(
            "TrackedIndicatorsEditor - trackedIndicatorGroupAfterMove - goal - " +
                "!move.targetPosition || move.targetPosition.goalIndex === null:"
        );
        console.log(!move.targetPosition || move.targetPosition.goalIndex === null);
        console.log("TrackedIndicatorsEditor - trackedIndicatorGroupAfterMove - goal - move.sourcePosition:");
        console.log(move.sourcePosition);
        const targetIndex =
            move.targetPosition?.goalIndex === undefined || move.targetPosition.goalIndex === null
                ? null
                : Math.min(
                      move.targetPosition.goalIndex,
                      trackedIndicatorGroup.trackedDomains[move.targetPosition.domainIndex].trackedGoals.length
                  );
        const newTrackedGoal = (
            !move.targetPosition || move.targetPosition.goalIndex === null
                ? {}
                : move.sourcePosition && move.sourcePosition.goalIndex !== null
                ? {
                      ...trackedIndicatorGroup.trackedDomains[move.sourcePosition.domainIndex].trackedGoals[
                          move.sourcePosition.goalIndex
                      ],
                      orderNumber: targetIndex,
                  }
                : {
                      id: "null",
                      trackedIndicators: (move.libraryItem as GoalWithContent).indicators
                          .filter((indicator) => !indicatorIdsInGroup.has(indicator.id))
                          .map((indicator, index) => {
                              return {
                                  id: "null",
                                  indicator: indicator,
                                  orderNumber: index,
                                  priority: 0,
                                  public: false,
                                  targetValue: null,
                              };
                          }),
                      goal: move.libraryItem as GoalDTO,
                      orderNumber: targetIndex,
                      priority: 0,
                      public: false,
                  }
        ) as TrackedGoalInGroupDTO;
        console.log("TrackedIndicatorsEditor - trackedIndicatorGroupAfterMove - goal - newTrackedGoal:");
        console.log(newTrackedGoal);
        console.log(
            "TrackedIndicatorsEditor - trackedIndicatorGroupAfterMove - goal - " +
                "move.sourcePosition?.goalIndex === undefined:"
        );
        console.log(move.sourcePosition?.goalIndex === undefined);
        const newDomains = trackedIndicatorGroup.trackedDomains.map((trackedDomain, index) => {
            const isSourceDomain = move.sourcePosition && index === move.sourcePosition.domainIndex;
            const isTargetDomain =
                trackedDomain.orderNumber ===
                (existingDomainIndex >= 0 ? existingDomainIndex : move.targetPosition?.domainIndex);

            console.log(
                "TrackedIndicatorsEditor - trackedIndicatorGroupAfterMove - goal - " +
                    "!isSourceDomain && !isTargetDomain:"
            );
            console.log(!isSourceDomain && !isTargetDomain);
            if (!isSourceDomain && !isTargetDomain) return trackedDomain;
            console.log("TrackedIndicatorsEditor - trackedIndicatorGroupAfterMove - goal - new trackedGoals:");
            const newGoals = newItems<TrackedGoalInGroupDTO>(
                move.sourcePosition?.goalIndex === undefined || !isSourceDomain ? null : move.sourcePosition?.goalIndex,
                isTargetDomain ? targetIndex : null,
                trackedDomain.trackedGoals,
                newTrackedGoal
            );
            return {
                ...trackedDomain,
                public: trackedDomain.public && newGoals.some((it) => it.public),
                trackedGoals: newGoals,
            } as TrackedDomainInGroupDTO;
        });
        return {
            ...trackedIndicatorGroup,
            public: trackedIndicatorGroup.public && newDomains.some((it) => it.public),
            trackedDomains: newDomains,
        };
    };
    const trackedIndicatorGroupAfterMoveIndicator = (
        move: TrackedItemMove,
        trackedIndicatorGroup: TrackedIndicatorGroupWithContentDTO,
        existingDomainIndex: number,
        existingGoalIndex: number
    ) => {
        console.log("TrackedIndicatorsEditor - trackedIndicatorGroupAfterMove - indicator - move.sourcePosition:");
        console.log(move.sourcePosition);
        console.log("TrackedIndicatorsEditor - trackedIndicatorGroupAfterMove - indicator - move.targetPosition:");
        console.log(move.targetPosition);
        const targetIndex =
            move.targetPosition?.indicatorIndex === undefined ||
            move.targetPosition.indicatorIndex === null ||
            move.targetPosition.goalIndex === null
                ? null
                : Math.min(
                      move.targetPosition.indicatorIndex,
                      trackedIndicatorGroup.trackedDomains[move.targetPosition.domainIndex].trackedGoals[
                          move.targetPosition.goalIndex
                      ].trackedIndicators.length
                  );
        console.log("TrackedIndicatorsEditor - trackedIndicatorGroupAfterMove - indicator - targetIndex:");
        console.log(targetIndex);
        const newTrackedIndicator = (
            !move.targetPosition ||
            move.targetPosition.goalIndex === null ||
            move.targetPosition.indicatorIndex === null
                ? {}
                : move.sourcePosition &&
                  move.sourcePosition.goalIndex !== null &&
                  move.sourcePosition.indicatorIndex !== null
                ? {
                      ...trackedIndicatorGroup.trackedDomains[move.sourcePosition.domainIndex].trackedGoals[
                          move.sourcePosition.goalIndex
                      ].trackedIndicators[move.sourcePosition.indicatorIndex],
                      orderNumber: targetIndex,
                  }
                : {
                      id: "null",
                      indicator: move.libraryItem as IndicatorDTO,
                      orderNumber: targetIndex,
                      priority: 0,
                      public: false,
                      targetValue: null,
                  }
        ) as TrackedIndicatorInGroupDTO;
        console.log("TrackedIndicatorsEditor - trackedIndicatorGroupAfterMove - indicator - newTrackedIndicator:");
        console.log(newTrackedIndicator);
        const newDomains = trackedIndicatorGroup.trackedDomains.map((trackedDomain, index) => {
            const isSourceDomain = move.sourcePosition && index === move.sourcePosition.domainIndex;
            const isTargetDomain =
                move.targetPosition &&
                index === (existingDomainIndex >= 0 ? existingDomainIndex : move.targetPosition?.domainIndex);
            if (!isSourceDomain && !isTargetDomain) return trackedDomain;
            const newGoals = trackedDomain.trackedGoals.map((trackedGoal, index) => {
                const isSourceGoal = isSourceDomain && index === move.sourcePosition?.goalIndex;
                const isTargetGoal =
                    isTargetDomain &&
                    index === (existingGoalIndex >= 0 ? existingGoalIndex : move.targetPosition?.goalIndex);
                if (!isSourceGoal && !isTargetGoal) return trackedGoal;
                const newIndicators = newItems<TrackedIndicatorInGroupDTO>(
                    move.sourcePosition?.indicatorIndex === undefined || !isSourceGoal
                        ? null
                        : move.sourcePosition?.indicatorIndex,
                    isTargetGoal ? targetIndex : null,
                    trackedGoal.trackedIndicators,
                    newTrackedIndicator
                );
                console.log("newIndicators:");
                console.log(newIndicators);
                console.log("newIndicators.some((it) => it.public):");
                console.log(newIndicators.some((it) => it.public));
                return {
                    ...trackedGoal,
                    public: trackedGoal.public && newIndicators.some((it) => it.public),
                    trackedIndicators: newIndicators,
                } as TrackedGoalInGroupDTO;
            });
            console.log("newGoals:");
            console.log(newGoals);
            console.log("newGoals.some((it) => it.public):");
            console.log(newGoals.some((it) => it.public));
            return {
                ...trackedDomain,
                public: trackedDomain.public && newGoals.some((it) => it.public),
                trackedGoals: newGoals,
            };
        });
        return {
            ...trackedIndicatorGroup,
            public: trackedIndicatorGroup.public && newDomains.some((it) => it.public),
            trackedDomains: newDomains,
        };
    };

    const trackedIndicatorGroupAfterMove = (
        move: TrackedItemMove,
        trackedIndicatorGroup: TrackedIndicatorGroupWithContentDTO
    ): TrackedIndicatorGroupWithContentDTO => {
        console.log("TrackedIndicatorsEditor - trackedIndicatorGroupAfterMove - move:");
        console.log(move);
        console.log("TrackedIndicatorsEditor - trackedIndicatorGroupAfterMove - trackedIndicatorGroup:");
        console.log(trackedIndicatorGroup);

        const notNullPosition = move.targetPosition || move.sourcePosition;
        const existingDomainIndex = move.libraryItem
            ? trackedIndicatorGroup.trackedDomains.findIndex(
                  (trackedDomain) => trackedDomain.domain.id === move.libraryItem.id
              )
            : -1;
        const existingGoalIndex =
            existingDomainIndex >= 0 && move.libraryItem
                ? trackedIndicatorGroup.trackedDomains[existingDomainIndex].trackedGoals.findIndex(
                      (trackedGoal) => trackedGoal.goal.id === move.libraryItem.id
                  )
                : -1;
        if (
            existingGoalIndex >= 0 &&
            move.libraryItem &&
            trackedIndicatorGroup.trackedDomains[existingDomainIndex].trackedGoals[
                existingGoalIndex
            ].trackedIndicators.findIndex(
                (trackedIndicator) => trackedIndicator.indicator.id === move.libraryItem.id
            ) >= 0
        )
            return trackedIndicatorGroup;

        console.log("TrackedIndicatorsEditor - trackedIndicatorGroupAfterMove - notNullPosition:");
        console.log(notNullPosition);
        console.log("TrackedIndicatorsEditor - trackedIndicatorGroupAfterMove - existingDomainIndex:");
        console.log(existingDomainIndex);
        console.log("TrackedIndicatorsEditor - trackedIndicatorGroupAfterMove - existingGoalIndex:");
        console.log(existingGoalIndex);

        return notNullPosition.goalIndex === null && existingDomainIndex < 0
            ? trackedIndicatorGroupAfterMoveDomain(move, trackedIndicatorGroup)
            : notNullPosition.indicatorIndex === null && existingGoalIndex < 0
            ? trackedIndicatorGroupAfterMoveGoal(move, trackedIndicatorGroup, existingDomainIndex)
            : trackedIndicatorGroupAfterMoveIndicator(
                  move,
                  trackedIndicatorGroup,
                  existingDomainIndex,
                  existingGoalIndex
              );
    };

    const handleMove = (move: TrackedItemMove) => {
        console.log("TrackedIndicatorsEditor: handleMove");
        const newTrackedIndicatorGroup = trackedIndicatorGroupAfterMove(move, state.trackedIndicatorGroup);
        if (newTrackedIndicatorGroup === state.trackedIndicatorGroup) return;
        setState({
            ...state,
            trackedIndicatorGroup: trackedIndicatorGroupAfterMove(move, state.trackedIndicatorGroup),
            shiftingItemPosition: null,
        });
    };

    const handleShiftStart = (position: ItemPositionInLibrary) => {
        console.log("[TrackedIndicatorsEditorContent] handleShiftStart - position:");
        console.log(position);
        setState({...state, shiftingItemPosition: state.shiftingItemPosition ? null : position});
    };

    const handleShiftEnd = (position: ItemPositionInLibrary) => {
        console.log("[TrackedIndicatorsEditorContent] handleShiftEnd - state.shiftingItemPosition:");
        console.log(state.shiftingItemPosition);
        console.log("[TrackedIndicatorsEditorContent] handleShiftEnd - position:");
        console.log(position);
        if (!state.shiftingItemPosition) return;
        const isShiftingDomainDown =
            position.goalIndex === null && state.shiftingItemPosition.domainIndex < position.domainIndex;
        const isShiftingGoalDown =
            !isShiftingDomainDown &&
            position.indicatorIndex === null &&
            position.goalIndex !== null &&
            state.shiftingItemPosition.goalIndex !== null &&
            state.shiftingItemPosition.domainIndex === position.domainIndex &&
            state.shiftingItemPosition.goalIndex < position.goalIndex;
        const isShiftingIndicatorDown =
            !isShiftingGoalDown &&
            position.indicatorIndex !== null &&
            state.shiftingItemPosition.indicatorIndex !== null &&
            state.shiftingItemPosition.domainIndex === position.domainIndex &&
            state.shiftingItemPosition.goalIndex === position.goalIndex &&
            state.shiftingItemPosition.indicatorIndex < position.indicatorIndex;
        handleMove({
            sourcePosition: state.shiftingItemPosition,
            targetPosition: {
                domainIndex: isShiftingDomainDown ? position.domainIndex - 1 : position.domainIndex,
                goalIndex: isShiftingGoalDown && position.goalIndex ? position.goalIndex - 1 : position.goalIndex,
                indicatorIndex:
                    isShiftingIndicatorDown && position.indicatorIndex
                        ? position.indicatorIndex - 1
                        : position.indicatorIndex,
            },
        });
    };

    const handleAdd = (domains: DomainWithContent[]) => {
        console.log("TrackedIndicatorsEditor - handleAdd - domains:");
        console.log(domains);

        if (!domains.length) return;

        const movesFromAddedIndicator = (indicator: IndicatorDTO, existingParentGoal: GoalDTO): TrackedItemAdd[] => {
            console.log("[TrackedIndicatorsEditorContent] movesFromAddedIndicator - indicator:");
            console.log(indicator);
            console.log("[TrackedIndicatorsEditorContent] movesFromAddedIndicator - existingParentGoal:");
            console.log(existingParentGoal);
            if (indicatorIdsInGroup.has(indicator.id)) return [];
            let trackedGoalOrder = -1;
            const trackedDomainOrder = state.trackedIndicatorGroup.trackedDomains.findIndex((trackedDomain) => {
                const currentFoundIndex = trackedDomain.trackedGoals.findIndex(
                    (trackedGoal) => trackedGoal.goal.id === existingParentGoal.id
                );
                console.log("[TrackedIndicatorsEditorContent] movesFromAddedIndicator - findIndex - trackedDomain:");
                console.log(trackedDomain);
                console.log(
                    "[TrackedIndicatorsEditorContent] movesFromAddedIndicator - findIndex - currentFoundIndex:"
                );
                console.log(currentFoundIndex);
                if (currentFoundIndex < 0) return false;
                trackedGoalOrder = currentFoundIndex;
                return true;
            });
            console.log("[TrackedIndicatorsEditorContent] movesFromAddedIndicator - trackedDomainOrder:");
            console.log(trackedDomainOrder);
            console.log("[TrackedIndicatorsEditorContent] movesFromAddedIndicator - trackedGoalOrder:");
            console.log(trackedGoalOrder);
            if (trackedDomainOrder < 0 || trackedGoalOrder < 0)
                throw "Failed to find goal of indicator that should already be tracked";
            return [
                {
                    targetPosition: {
                        domainIndex: trackedDomainOrder,
                        goalIndex: trackedGoalOrder,
                        indicatorIndex: Infinity,
                    },
                    libraryItem: indicator,
                },
            ];
        };

        const movesFromAddedGoal = (
            goal: GoalWithContent,
            existingParentDomain: DomainWithContent
        ): TrackedItemAdd[] => {
            if (goalIdsInGroup.has(goal.id)) {
                return goal.indicators.flatMap((indicator) => movesFromAddedIndicator(indicator, goal));
            }
            const newIndicators = goal.indicators.filter((indicator) => !indicatorIdsInGroup.has(indicator.id));
            const trackedDomainOrder = state.trackedIndicatorGroup.trackedDomains.findIndex(
                (trackedDomain) => trackedDomain.domain.id === existingParentDomain.id
            );
            if (trackedDomainOrder < 0) throw "Failed to find domain of goal that should already be tracked";
            return [
                {
                    targetPosition: {
                        domainIndex: trackedDomainOrder,
                        goalIndex: Infinity,
                        indicatorIndex: null,
                    },
                    libraryItem: {...goal, indicators: newIndicators},
                },
            ];
        };

        const movesFromAddedDomain = (domain: DomainWithContent): TrackedItemAdd[] => {
            if (domainIdsInGroup.has(domain.id)) {
                return domain.goals.flatMap((goal) => movesFromAddedGoal(goal, domain));
            }
            const existingGoals: GoalWithContent[] = [];
            const newGoalsWithNewIndicators = domain.goals
                .filter((goal) => {
                    const doesExist = goalIdsInGroup.has(goal.id);
                    if (doesExist) existingGoals.push(goal);
                    return !doesExist;
                })
                .map((goal) => {
                    const newIndicators = goal.indicators.filter((indicator) => !indicatorIdsInGroup.has(indicator.id));
                    return goal.indicators.length === newIndicators.length
                        ? goal
                        : {...goal, indicators: newIndicators};
                });
            const movesFromIndicatorsInExistingGoals: TrackedItemAdd[] = existingGoals.flatMap(
                (goal: GoalWithContent) =>
                    goal.indicators.flatMap((indicator: IndicatorDTO) => movesFromAddedIndicator(indicator, goal))
            );
            const movesFromDomain: TrackedItemAdd[] = [
                {
                    targetPosition: {
                        domainIndex: Infinity,
                        goalIndex: null,
                        indicatorIndex: null,
                    },
                    libraryItem: {...domain, goals: newGoalsWithNewIndicators},
                },
            ];
            return movesFromDomain.concat(movesFromIndicatorsInExistingGoals);
        };
        let newTrackedIndicatorGroup = state.trackedIndicatorGroup;
        domains.forEach((domain) =>
            movesFromAddedDomain(domain).forEach(
                (move) => (newTrackedIndicatorGroup = trackedIndicatorGroupAfterMove(move, newTrackedIndicatorGroup))
            )
        );

        setState({...state, trackedIndicatorGroup: newTrackedIndicatorGroup});
    };
    //== Render =================================================================
    if (state.name === "loading") {
        return <Loading />;
    }
    if (state.name === "failed") {
        return <ServerCommunicationAlert />;
    }
    // - default
    let notification: JSX.Element | null = null;
    if (state.name === "ready" && state.action === "refresh") {
        notification = <InfoNotification message={t("trackedIndicatorGroupEditorNotifications.refresh_ok")} />;
    }
    if (saveState.name === "saved") {
        notification = <SuccessNotification message={t(`trackedIndicatorGroupEditorNotifications.edit_ok`)} />;
    }
    const handleRemoveItem = (itemPosition: ItemPositionInLibrary) => {
        handleMove({
            sourcePosition: {
                domainIndex: itemPosition.domainIndex,
                goalIndex: itemPosition.goalIndex,
                indicatorIndex: itemPosition.indicatorIndex,
            },
        });
    };

    const trackedIndicatorGroupWithPublicContent = (
        trackedIndicatorGroup: TrackedIndicatorGroupWithContentDTO,
        publicValue: boolean
    ): TrackedIndicatorGroupWithContentDTO => {
        return {
            ...trackedIndicatorGroup,
            public: publicValue,
            trackedDomains: trackedIndicatorGroup.trackedDomains.map((trackedDomain) =>
                trackedDomain.public === publicValue || (publicValue && !containsDomainIndicators(trackedDomain))
                    ? trackedDomain
                    : trackedDomainWithPublicContent(trackedDomain, publicValue)
            ),
        };
    };

    const handlePublicSwitch = () => {
        console.log("TrackedIndicatorsEditor: handlePublicSwitch");
        if (!containsGroupIndicators(state.trackedIndicatorGroup))
            return console.log("[TrackedIndicatorsEditor] handlePublicSwitch: no indicators to publish");
        setState({
            ...state,
            trackedIndicatorGroup: trackedIndicatorGroupWithPublicContent(
                state.trackedIndicatorGroup,
                !state.trackedIndicatorGroup.public
            ),
        });
    };
    const handleContentPublicSwitch = (modifiedItem: TrackedDomainInGroupDTO) => {
        console.log("TrackedIndicatorsEditor: handleContentPublicSwitch");
        let trackedIndicatorGroupPublic = state.trackedIndicatorGroup.public;
        if (modifiedItem.public && !trackedIndicatorGroupPublic) {
            trackedIndicatorGroupPublic = true;
        } else if (
            !modifiedItem.public &&
            trackedIndicatorGroupPublic &&
            state.trackedIndicatorGroup.trackedDomains.every(
                (domainUse) => domainUse.domain.id === modifiedItem.domain.id || !domainUse.public
            )
        ) {
            trackedIndicatorGroupPublic = false;
        }
        const newTrackedDomains = state.trackedIndicatorGroup.trackedDomains.map((trackedDomain) =>
            trackedDomain.domain.id === modifiedItem.domain.id ? modifiedItem : trackedDomain
        );
        console.log("TrackedIndicatorsEditor: handleContentPublicSwitch - trackedIndicatorGroupPublic:");
        console.log(trackedIndicatorGroupPublic);
        console.log("TrackedIndicatorsEditor: handleContentPublicSwitch - newTrackedDomains:");
        console.log(newTrackedDomains);
        setState({
            ...state,
            trackedIndicatorGroup: {
                ...state.trackedIndicatorGroup,
                public: trackedIndicatorGroupPublic,
                trackedDomains: newTrackedDomains,
            },
        });
    };

    const isShiftingDomain = state.shiftingItemPosition !== null && state.shiftingItemPosition.goalIndex === null;

    return (
        <Box>
            <div className={classes.trackedIndicators}>
                <TrackedIndicatorGroupRow
                    trackedIndicatorGroup={state.trackedIndicatorGroup}
                    handlePublicSwitch={handlePublicSwitch}
                />
                <TrackedIndicatorGroupEditorAdd
                    type="public"
                    trackedIndicatorGroup={state.trackedIndicatorGroup}
                    onAdd={handleAdd}
                />
                <Divider style={{margin: "1em"}} />
                {state.trackedIndicatorGroup.trackedDomains
                    .map((trackedDomain: TrackedDomainInGroupDTO, index) => {
                        const position = {
                            domainIndex: index,
                            goalIndex: null,
                            indicatorIndex: null,
                        };
                        const isShiftingAdjacentDomain =
                            state.shiftingItemPosition &&
                            (state.shiftingItemPosition.domainIndex == index ||
                                state.shiftingItemPosition.domainIndex + 1 == index);
                        return (
                            <>
                                <LibraryItemMoveTarget
                                    key={trackedDomain.id + "-move-target"}
                                    className={classes.domainShiftTarget}
                                    active={isShiftingDomain && !isShiftingAdjacentDomain}
                                    onAction={() => handleShiftEnd(position)}
                                />
                                <TrackedDomain
                                    key={trackedDomain.id}
                                    trackedDomain={trackedDomain}
                                    itemPosition={position}
                                    shiftingItemPosition={state.shiftingItemPosition}
                                    handleShiftStart={handleShiftStart}
                                    handleShiftEnd={handleShiftEnd}
                                    handleRemoveItem={handleRemoveItem}
                                    handlePublicSwitch={handleContentPublicSwitch}
                                />
                            </>
                        );
                    })
                    .concat([
                        <LibraryItemMoveTarget
                            key={state.trackedIndicatorGroup.id + "-end-move-target"}
                            className={classes.domainShiftTarget}
                            active={
                                isShiftingDomain &&
                                !(
                                    state.shiftingItemPosition &&
                                    state.shiftingItemPosition.domainIndex + 1 ==
                                        state.trackedIndicatorGroup.trackedDomains.length
                                )
                            }
                            onAction={() =>
                                handleShiftEnd({
                                    domainIndex: state.trackedIndicatorGroup.trackedDomains.length,
                                    goalIndex: null,
                                    indicatorIndex: null,
                                })
                            }
                        />,
                    ])}
            </div>
            {notification}
        </Box>
    );
};

export default TrackedIndicatorsEditorContent;
