import React from "react";
import {TagDTO} from "../../models/admin";
import {GenericEntityDeleteContentProps, GenericEntityForceDelete} from "../common/delete";
import {extractEntitiesByType} from "../../models/error";
import {Link} from "@material-ui/core";
import ROUTES from "../../routes/routes";
import {useTranslation} from "react-i18next";

const TagRenderDelete: React.FC<GenericEntityDeleteContentProps> = (props: GenericEntityDeleteContentProps) => {
    //== Init =================================================================
    const [t] = useTranslation("common");
    const libraries = extractEntitiesByType(props.error, "Library");
    const projects = extractEntitiesByType(props.error, "Project");
    const webpages = extractEntitiesByType(props.error, "Webpage");
    const inputVariables = extractEntitiesByType(props.error, "InputVariable");
    const domains = extractEntitiesByType(props.error, "Library-Domain");
    const goals = extractEntitiesByType(props.error, "Library-Goal");
    const indicators = extractEntitiesByType(props.error, "Library-Indicator");
    //== Render ===============================================================
    return (
        <ul>
            {libraries.length > 0 && (
                <li>
                    {t("tags.confirm_delete.render.libraries", {count: libraries.length})}
                    <ul>
                        {libraries.map((item) => (
                            <li key={item.id}>
                                <Link href={ROUTES.library.create(item.id)} target="_blank">
                                    {item.displayName}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </li>
            )}
            {projects.length > 0 && (
                <li>
                    {t("tags.confirm_delete.render.projects", {count: projects.length})}
                    <ul>
                        {projects.map((item) => (
                            <li key={item.id}>{item.displayName}</li>
                        ))}
                    </ul>
                </li>
            )}
            {webpages.length > 0 && (
                <li>
                    {t("tags.confirm_delete.render.webpages", {count: webpages.length})}
                    <ul>
                        {webpages.map((item) => (
                            <li key={item.id}>
                                <Link href={ROUTES.cmsPageEdit.create(item.id)} target="_blank">
                                    {item.displayName}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </li>
            )}
            {inputVariables.length > 0 && (
                <li>
                    {t("tags.confirm_delete.render.inputVariables", {count: inputVariables.length})}
                    <ul>
                        {inputVariables.map((item) => (
                            <li key={item.id}>
                                <Link href={ROUTES.inputVariable.create(item.id)} target="_blank">
                                    {item.displayName}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </li>
            )}
            {domains.length > 0 && <li>{t("tags.confirm_delete.render.domains", {count: domains.length})}</li>}
            {goals.length > 0 && <li>{t("tags.confirm_delete.render.goals", {count: goals.length})}</li>}
            {indicators.length > 0 && <li>{t("tags.confirm_delete.render.indicators", {count: indicators.length})}</li>}
        </ul>
    );
};

interface TagDeleteProps {
    entity: TagDTO | null;
    onDeleted: () => void;
    onCancel: () => void;
    onFailed: () => void;
}

export const TagDelete: React.FC<TagDeleteProps> = (props: TagDeleteProps) => {
    return (
        <GenericEntityForceDelete
            tNs="common"
            tPrefix="tags"
            urlSlug="tags"
            RenderContent={TagRenderDelete}
            {...props}
        />
    );
};
