import React from "react";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
// Material UI imports
import {Box, IconButton, Table, TableBody, TableCell, TableFooter, TableHead, TableRow} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import TextField from "@material-ui/core/TextField";
// Project imports
import {
    CopyButton,
    CreateActionButton,
    DeleteActionButton,
    EditActionButton,
    RefreshActionButton,
    SaveActionButton,
} from "../../common/buttons";
import {ErrorNotification, InfoNotification, SuccessNotification} from "../../common/notifications";
import {ServerCommunicationAlert} from "../../common/errors";
import {Loading, LoadingRow} from "../../common/Loading";
import {UserDTO} from "../../../models/user";
import Utils from "../../../utils";
import API, {createApiConfig} from "../../../utils/API";
import {AxiosError} from "axios";
import {emptyPage, Page} from "../../../models/pagination";
import {DeleteConfirmationDialog} from "../../common/dialogs";
import SearchIcon from "@material-ui/icons/Search";

interface AccountRowProps {
    user: UserDTO;
}

interface AccountNewRowProps {
    onSave: (user: UserDTO) => void;
}

const AccountRow: React.FC<AccountRowProps> = (props: AccountRowProps) => {
    //== Init ===================================================================
    const [t] = useTranslation("user");
    const {keycloak, initialized} = useKeycloak();
    const [state, setState] = React.useState({
        user: props.user,
        formUsername: props.user.username,
        formPersonUuid: Utils.nullToEmpty(props.user.personUuid),
        name: "init",
        action: "init",
    });
    //== Effects ================================================================
    React.useEffect(() => {
        if (state.name === "deleting") {
            API.delete<void>(`/users/${state.user.id}`, createApiConfig(keycloak, initialized))
                .then(() => {
                    setState({...state, name: "deleted", action: "delete"});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed", action: "delete"});
                });
        } else if (state.name === "saving") {
            const user: UserDTO = {
                id: state.user.id,
                username: state.formUsername,
                personUuid: Utils.emptyToNull(state.formPersonUuid),
            };
            API.put<UserDTO>(`/users/${state.user.id}`, user, createApiConfig(keycloak, initialized))
                .then((res) => {
                    setState({...state, user: res.data, name: "saved", action: "edit"});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed", action: "edit"});
                });
        }
    }, [keycloak, initialized, state, setState]);
    //== Handlers ===============================================================
    const handleCancelDelete = (): void => {
        setState({...state, name: "init"});
    };
    const handleConfirmDelete = (): void => {
        setState({...state, name: "deleting"});
    };
    const handleDelete = (): void => {
        setState({...state, name: "confirmingDelete"});
    };
    const handleEdit = (): void => {
        setState({...state, name: "editing"});
    };
    const handleSave = (): void => {
        if (state.formUsername === state.user.username && state.formPersonUuid === state.user.personUuid) {
            setState({...state, name: "saved"});
        } else {
            setState({...state, name: "saving"});
        }
    };
    //== Render =================================================================
    if (state.name === "deleted") {
        return <SuccessNotification message={t(`users.notifications.delete_ok`)} />;
    }
    if (state.name === "saving" || state.name === "deleting") {
        return <LoadingRow key={state.user.id} />;
    }
    if (state.name === "editing") {
        return (
            <TableRow key={state.user.id}>
                <TableCell align="center">{state.user.id}</TableCell>
                <TableCell align="center">
                    <TextField
                        id="username"
                        label={t("users.username")}
                        value={state.formUsername}
                        fullWidth
                        required
                        onChange={(e): void => setState({...state, formUsername: e.target.value})}
                    />
                </TableCell>
                <TableCell align="center">
                    <TextField
                        id="personUuid"
                        label={t("users.personUuid")}
                        value={state.formPersonUuid}
                        fullWidth
                        onChange={(e): void => setState({...state, formPersonUuid: e.target.value})}
                    />
                </TableCell>
                <TableCell align="right">
                    <SaveActionButton onClick={handleSave} />
                    <DeleteActionButton onClick={handleDelete} />
                </TableCell>
            </TableRow>
        );
    }
    // - default
    let notification: JSX.Element | null = null;
    if (state.name === "failed") {
        const a = state.action;
        notification = <ErrorNotification message={t(`users.notifications.${a}_fail`)} />;
    }
    if (state.name === "saved") {
        notification = <SuccessNotification message={t(`users.notifications.edit_ok`)} />;
    }
    return (
        <TableRow key={state.user.id}>
            <TableCell align="center">
                {state.user.id}
                <CopyButton text={state.user.id} size="small" />
            </TableCell>
            <TableCell align="center">{state.user.username}</TableCell>
            <TableCell align="center">
                {state.user.personUuid}
                {Utils.notEmpty(state.user.personUuid) && (
                    <CopyButton text={Utils.nullToEmpty(state.user.personUuid)} size="small" />
                )}
            </TableCell>
            <TableCell align="right">
                <EditActionButton onClick={handleEdit} size="small" />
                <DeleteActionButton onClick={handleDelete} size="small" />
                <DeleteConfirmationDialog
                    title={t("ui.accounts.deleteConfirmDialog.title")}
                    text={t("ui.accounts.deleteConfirmDialog.text", {username: state.user.username})}
                    checkbox={t("ui.accounts.deleteConfirmDialog.checkbox")}
                    open={state.name === "confirmingDelete"}
                    id={"delete-dialog-" + props.user.id}
                    onDelete={handleConfirmDelete}
                    onCancel={handleCancelDelete}
                />
                {notification}
            </TableCell>
        </TableRow>
    );
};

const AccountNewRow: React.FC<AccountNewRowProps> = (props: AccountNewRowProps) => {
    //== Init ===================================================================
    const [t] = useTranslation("user");
    const {keycloak, initialized} = useKeycloak();
    const initState = {
        formId: "",
        formUsername: "",
        formPersonUuid: "",
        name: "init",
        onSave: props.onSave,
    };
    const [state, setState] = React.useState(initState);
    //== Effects ================================================================
    React.useEffect(() => {
        if (state.name === "saving") {
            const user: UserDTO = {
                id: state.formId,
                username: state.formUsername,
                personUuid: state.formPersonUuid,
            };
            API.post<UserDTO>(`/users`, user, createApiConfig(keycloak, initialized))
                .then((res) => {
                    state.onSave(res.data);
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [keycloak, initialized, state, setState, initState]);
    //== Handlers ===============================================================
    const handleCreate = (): void => {
        setState({...state, name: "saving"});
    };
    //== Render =================================================================
    if (state.name === "saving") {
        return <LoadingRow />;
    }
    let notification: JSX.Element | null = null;
    if (state.name === "failed") {
        notification = <ErrorNotification message={t("users.notifications.create_fail")} />;
    }
    // - default
    return (
        <TableRow key="new">
            <TableCell align="center">
                <TextField
                    id="newId"
                    label={t("users.id")}
                    value={state.formId}
                    fullWidth
                    required
                    onChange={(e): void => setState({...state, formId: e.target.value})}
                />
            </TableCell>
            <TableCell align="center">
                <TextField
                    id="newUsername"
                    label={t("users.username")}
                    value={state.formUsername}
                    fullWidth
                    required
                    onChange={(e): void => setState({...state, formUsername: e.target.value})}
                />
            </TableCell>
            <TableCell align="center">
                <TextField
                    id="newPersonUuid"
                    label={t("users.personUuid")}
                    value={state.formPersonUuid}
                    fullWidth
                    onChange={(e): void => setState({...state, formPersonUuid: e.target.value})}
                />
            </TableCell>
            <TableCell align="right">
                <CreateActionButton onClick={handleCreate} />
                {notification}
            </TableCell>
        </TableRow>
    );
};

interface AccountsState {
    page: Page<UserDTO>;
    query: string;
    name: "loading" | "loaded" | "failed";
    action: "init" | "refresh" | "new";
}

const Accounts: React.FC = () => {
    //== Init ===================================================================
    const [t] = useTranslation("user");
    const {keycloak, initialized} = useKeycloak();
    const initState: AccountsState = {
        page: emptyPage<UserDTO>([]),
        query: "",
        name: "loading",
        action: "init",
    };
    const [state, setState] = React.useState(initState);
    //== Effects ================================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            let url = `/users?page=${state.page.number}&size=${state.page.size}&sort=username,asc`;
            if (state.query !== "") {
                url += `&query=${state.query}`;
            }
            API.get<Page<UserDTO>>(url, createApiConfig(keycloak, initialized))
                .then((res) => {
                    setState({...state, page: res.data, name: "loaded"});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [keycloak, initialized, state, setState]);
    //== Handlers ===============================================================
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number): void => {
        setState({
            ...state,
            page: {...state.page, number: value - 1},
            name: "loading",
            action: "init",
        });
    };
    const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setState({...state, query: event.target.value});
    };
    const handleSearchSubmit = (): void => {
        setState({...state, name: "loading"});
    };
    //== Render =================================================================
    if (state.name === "loading") {
        return <Loading />;
    }
    if (state.name === "failed") {
        return <ServerCommunicationAlert />;
    }
    // - default
    let notification: JSX.Element | null = null;
    if (state.name === "loaded" && state.action === "refresh") {
        notification = <InfoNotification message={t("users.notifications.refresh_ok")} />;
    }
    if (state.name === "loaded" && state.action === "new") {
        notification = <SuccessNotification message={t("users.notifications.create_ok")} />;
    }
    const rows = state.page.content.map((user: UserDTO) => <AccountRow key={user.id} user={user} />);
    return (
        <Box>
            <Box display="flex" justifyContent="center">
                <Box flexGrow={1}>
                    {state.page.totalPages > 1 && (
                        <Pagination
                            style={{marginTop: "1em"}}
                            count={state.page.totalPages}
                            page={state.page.number + 1}
                            onChange={handlePageChange}
                        />
                    )}
                </Box>
                <form onSubmit={handleSearchSubmit} style={{width: "auto"}}>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <TextField
                            value={state.query}
                            onChange={handleQueryChange}
                            label={t("users.query")}
                            margin="dense"
                            variant="outlined"
                            style={{minWidth: "20em", marginRight: "1em"}}
                        />
                        <IconButton onClick={handleSearchSubmit} size="small">
                            <SearchIcon />
                        </IconButton>
                    </Box>
                </form>
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">{t("users.id")}</TableCell>
                        <TableCell align="center">{t("users.username")}</TableCell>
                        <TableCell align="center">{t("users.personUuid")}</TableCell>
                        <TableCell align="right">
                            <RefreshActionButton
                                onClick={(): void =>
                                    setState({
                                        ...initState,
                                        action: "refresh",
                                    })
                                }
                            />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{rows}</TableBody>
                <TableFooter>
                    <AccountNewRow onSave={(): void => setState({...initState, action: "new"})} />
                </TableFooter>
            </Table>
            {state.page.totalPages > 1 && (
                <Pagination
                    style={{marginTop: "1em"}}
                    count={state.page.totalPages}
                    page={state.page.number + 1}
                    onChange={handlePageChange}
                />
            )}
            {notification}
        </Box>
    );
};

export default Accounts;
