import manualSubSubsection, {ManualSubSubsection} from "../../model/ManualSubSubsection";
import manualSubsection, {ManualSubsection} from "../../model/ManualSubsection";
import manualSection, {ManualSection} from "../../model/ManualSection";
import manualChapter, {ManualChapter} from "../../model/ManualChapter";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import InfoIcon from "@material-ui/icons/Info";
import SendIcon from "@material-ui/icons/Send";
import SaveIcon from "@material-ui/icons/Save";
import VisibilityIcon from "@material-ui/icons/Visibility";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import InputIcon from "@material-ui/icons/Input";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import {KeyboardIcon} from "@material-ui/pickers/_shared/icons/KeyboardIcon";
import {ManualQueryLink} from "../../components/ManualQueryLink";
import ManualLink from "../../components/ManualLink";
import {csOrganizationLibrariesSection} from "./CsOrganizationManualChapter";

const csMunicipalityProfileEditSubsection: ManualSubsection = manualSubsection({
    key: "uprava",
    title: "Úprava profilových informací",
    intro: (
        <>
            <p>
                Do režimu úprav profilových informací je možné se dostat použitím tlačítka „Upravit“ na profilu obce.
                Pokud zde tlačítko nevidíte, ujistěte se, že máte nastavená dostatečná oprávnění.
            </p>
            <p>
                V&nbsp;režimu úprav můžete nastavit popis obce, změnit základní údaje jako aktuální rozlohu a počet
                obyvatel nebo nastavit kontaktní údaje.
            </p>
            <p>
                Po provedení úprav uložíte změny tlačítkem „Uložit“. Vrátit se bez uložení změn můžete tlačítkem
                „Zrušit“. Poté se dostanete zpět do režimu běžného prohlížení profilu a můžete zde zkontrolovat, jak
                provedené změny na profilu vypadají.
            </p>
            <p>
                Chcete upravit status obce nebo LAU 2, případně zvolit ORP? Kontaktujte administrátora Smart City
                Compass.
            </p>
        </>
    ),
});

const csMunicipalityProfileImagesSubsection: ManualSubsection = manualSubsection({
    key: "logo",
    title: "Logo/znak a fotografie obce",
    intro: (
        <p>
            Obrázky můžete nahrát najetím myší na stávající obrázek (nebo ikonu Smart City Compass, pokud žádný obrázek
            zatím nemáte) a klepnutím na tlačítko, které se zobrazí uprostřed obrázku. Následně přetáhněte nový obrázek
            do dialogového okna nebo do něj klepněte a soubor vyberte v&nbsp;tradičním systémovém okně. Nakonec klikněte
            na „Potvrdit“.
        </p>
    ),
});

export const csMunicipalityProfileSection: ManualSection = manualSection({
    key: "profil",
    title: "Profil obce",
    intro: (
        <p>
            Profil obce slouží nejen jako přehled informací pro návštěvníky, ale i k&nbsp;přechodu na další informace a
            nastavení obce. Můžete zde přes jednotlivá tlačítka přejít na přehled sledovaných indikátorů a jejich
            hodnot, podívat se na nabízené indikátorové sady a oprávnění členové obce zde najdou tlačítka pro úpravu
            profilu, správu členů, volbu sledovaných indikátorů, zadávání hodnot apod.
        </p>
    ),
    subsections: [csMunicipalityProfileEditSubsection, csMunicipalityProfileImagesSubsection],
});

const csMunicipalityPositionsEditSubSubsection: ManualSubSubsection = manualSubSubsection({
    key: "uprava",
    title: "Přidání a úprava pozice",
    content: (
        <>
            <p>
                Pro vytvoření nové pozice použijte tlačítko&nbsp;
                <AddIcon style={{verticalAlign: "bottom"}} /> nebo pro upravení stávající pozice použijte ikonku&nbsp;
                <EditIcon style={{verticalAlign: "bottom"}} />u dané pozice. Tím se otevře dialogové okno
                s&nbsp;nastavením vlastností a zejména oprávnění dané pozice. Význam jednotlivých oprávnění vysvětluje
                následující část návodu.
            </p>
            <p>Po nastavení oprávnění potvrďte tlačítkem „Uložit“.</p>
        </>
    ),
});

const csMunicipalityPositionsPermissionsSubSubsection: ManualSubSubsection = manualSubSubsection({
    key: "opravneni",
    title: "Význam oprávnění a možné hodnoty",
    content: (
        <>
            <p>Skrz pozice jsou nastavována různá oprávnění v&nbsp;rámci obce. Tato oprávnění jsou:</p>
            <ul>
                <li>
                    Správce (včetně členů): Správce má plnou kontrolu nad obcí včetně přidávání a odebírání členů. Může
                    tedy upravit libovolné údaje, přidat nebo i odstranit sledované indikátory nebo zadávat jednotlivé
                    vstupní hodnoty. Kromě toho může z&nbsp;obce odstranit i jiné správce. Toto oprávnění nastavte v
                    souladu s vnitřními pravidly platnými ve vaší obci. Pokud zaškrtnete, že pozice má oprávnění
                    správce, všechny další položky oprávnění se automaticky nastaví na nejvyšší stupeň oprávnění a není
                    možné je měnit.
                </li>
                <li>
                    Profil obce: Nastavuje oprávnění k&nbsp;úpravě profilových informací obce. Možné hodnoty jsou:
                    <ul>
                        <li>
                            Zobrazení (včetně neveřejných): Člen s&nbsp;touto pozicí může profil pouze zobrazit. Nemá
                            tedy žádná oprávnění (týkající se profilu) navíc oproti běžnému návštěvníkovi.
                        </li>
                        <li>Úpravy: Člen s&nbsp;touto pozicí může upravovat profilové informace obce.</li>
                    </ul>
                </li>
                <li>
                    Vlastní indikátorové sady: Nastavuje oprávnění k&nbsp;zobrazení a&nbsp;vytváření vlastních
                    indikátorů nebo celých indikátorových sad a jejich zveřejňování. Možné hodnoty jsou:
                    <ul>
                        <li>
                            Zobrazení pouze veřejných: Člen s&nbsp;touto pozicí může vidět jen to, co běžný návštěvník.
                            Žádná oprávnění navíc tedy nemá.
                        </li>
                        <li>
                            Zobrazení (včetně neveřejných): Člen s&nbsp;touto pozicí může vidět nejen to, co běžný
                            návštěvník, ale i rozpracované a neveřejné indikátory a indikátorové sady. Nic z&nbsp;toho
                            však nemůže upravit.
                        </li>
                        <li>
                            Úpravy: Člen s&nbsp;touto pozicí může indikátory a indikátorové sady nejen zobrazit, ale i
                            upravovat, odstraňovat nebo vytvářet nové.
                        </li>
                    </ul>
                </li>
                <li>
                    Sledované indikátory: Nastavuje oprávnění k&nbsp;zobrazení a&nbsp;výběru obcí sledovaných
                    indikátorů. Možné hodnoty jsou:
                    <ul>
                        <li>
                            Zobrazení pouze veřejných: Člen s&nbsp;touto pozicí může vidět jen ty indikátory, které vidí
                            běžný návštěvník. Žádná oprávnění navíc tedy nemá.
                        </li>
                        <li>
                            Zobrazení (včetně neveřejných): Člen s&nbsp;touto pozicí může vidět nejen to, co běžný
                            návštěvník, ale i neveřejné sledované indikátory a neveřejné skupiny sledovaných indikátorů.
                            Nic z&nbsp;toho však nemůže upravit.
                        </li>
                        <li>
                            Úpravy: Člen s&nbsp;touto pozicí může sledované indikátory nejen zobrazit, ale i přidávat
                            další, odebírat stávající, měnit pořadí a přiřazení do cílů a oblastí a tyto položky
                            zveřejňovat nebo rušit zveřejnění.
                        </li>
                    </ul>
                </li>
                <li>
                    Vstupní hodnoty: Nastavuje oprávnění k&nbsp;zobrazení a&nbsp;zadávání vstupních hodnot, ze kterých
                    se počítají hodnoty sledovaných indikátorů obce. Možné hodnoty jsou:
                    <ul>
                        <li>
                            Zobrazení pouze veřejných: Člen s&nbsp;touto pozicí může vidět jen výsledné hodnoty
                            indikátorů, ale ne vstupní hodnoty. Stejně, jako běžný návštěvník. Žádná oprávnění navíc
                            tedy nemá.
                        </li>
                        <li>
                            Zobrazení (včetně neveřejných): Člen s&nbsp;touto pozicí může vidět i veškeré vstupní
                            hodnoty obce.
                        </li>
                        <li>
                            Úpravy: Člen s&nbsp;touto pozicí může vstupní hodnoty i vkládat a do určité míry upravovat
                            (není možné hodnotu zcela odstranit nebo změnit, jen označit jako neplatnou).
                        </li>
                    </ul>
                </li>
                <li>
                    Vkládání konkrétních oblastí: Tato položka se zobrazí pouze v&nbsp;případě, že položka „Vstupní
                    hodnoty“ není nastavena pro úpravy. Zde můžete vybrat konkrétní oblasti sledovaných indikátorů, ke
                    kterým může člen s&nbsp;touto pozicí vkládat vstupní hodnoty. Můžete tedy nastavit, že může vkládat
                    např. jen vstupní hodnoty používané v&nbsp;oblasti dopravy, ale ne energetiky. Výčet těchto oblastí
                    závisí na výběru sledovaných indikátorů vaší obce.
                </li>
            </ul>
        </>
    ),
});

const csMunicipalityPositionsRemovalSubSubsection: ManualSubSubsection = manualSubSubsection({
    key: "odstraneni",
    title: "Odstranění pozice",
    content: (
        <p>
            Pozici je možné odstranit kliknutím na ikonku&nbsp;
            <DeleteIcon style={{verticalAlign: "bottom"}} />. Odstranění je možné jen u nepoužívaných pozic. Pokud je
            pozice používaná, tj. přiřazená členovi nebo nabídnutá v&nbsp;pozvánce, odstranit ji není možné a ikonka
            koše bude zašedlá. V&nbsp;takovém případě je nutné nejdříve odebrat danou pozici stávajícím členům nebo
            odebrat samotné členy s&nbsp;touto pozicí, případně zkontrolovat, zda neexistuje pozvánka, která by tuto
            pozici nabízela a případně zrušit ji.
        </p>
    ),
});

export const csMunicipalityPositionsSubsection: ManualSubsection = manualSubsection({
    key: "pozice",
    title: "Pozice",
    intro: (
        <p>
            Na záložce „Pozice“ se nachází přehled relevantních pracovních pozic v&nbsp;obci a jejich oprávnění
            v&nbsp;rámci Smart City Compass. Je možné zde jednotlivé pozice upravit, odstranit nebo pomocí tlačítka{" "}
            <AddIcon style={{verticalAlign: "bottom"}} /> přidat pozici novou.
        </p>
    ),
    subSubsections: [
        csMunicipalityPositionsEditSubSubsection,
        csMunicipalityPositionsPermissionsSubSubsection,
        csMunicipalityPositionsRemovalSubSubsection,
    ],
});

const csMunicipalityMembershipsInvitationSubSubsection: ManualSubSubsection = manualSubSubsection({
    key: "pozvanky",
    title: "Pozvání nového člena",
    content: (
        <>
            <p>
                Přidávání členů funguje na principu pozvánek. Před pozváním nového člena se ujistěte, že pro něj máte
                již vytvořenou odpovídající pozici na záložce „Pozice“.
            </p>
            <p>
                Pro odeslání pozvánky do obce použijte řádek s&nbsp;textovými poli nad seznamem stávajících členů. Zde
                vyberte pozice a vyplňte e-mailovou adresu příjemce, případně volitelně příjemci napište zprávu, která
                mu pomůže se zorientovat v&nbsp;tom, proč tuto pozvánku posíláte. Nakonec odeslání potvrďte kliknutím na
                ikonu&nbsp;
                <SendIcon style={{verticalAlign: "bottom"}} /> vpravo.
            </p>
            <p>
                Tím dojde k&nbsp;odeslání pozvánky na e-mailovou adresu příjemce. Pokud již je registrovaný v Smart City
                Compass, uvidí na svém přehledu po přihlášení tuto pozvánku a bude tam mít možnost pozvánku přijmout
                nebo odmítnout. Pokud registrovaný není, bude nejprve v&nbsp;e-mailu vyzván k&nbsp;registraci.
            </p>
        </>
    ),
});

const csMunicipalityMembershipsPositionsSubSubsection: ManualSubSubsection = manualSubSubsection({
    key: "pozice",
    title: "Volba pozic stávajícího člena",
    content: (
        <p>
            Pokud si přejete změnit pozici stávajícího člena, klikněte na ikonku&nbsp;
            <EditIcon style={{verticalAlign: "bottom"}} /> vpravo od řádku s&nbsp;daným členem. Po výběru pozic ze
            seznamu tyto pozice potvrďte kliknutím na ikonku&nbsp;
            <SaveIcon style={{verticalAlign: "bottom"}} />.
        </p>
    ),
});

const csMunicipalityMembershipsRemovalSubSubsection: ManualSubSubsection = manualSubSubsection({
    key: "odebrani",
    title: "Odebrání člena obce a opuštění obce",
    content: (
        <p>
            Člena je možné odebrat kliknutím na ikonku&nbsp;
            <DeleteIcon style={{verticalAlign: "bottom"}} />. Stejným způsobem můžete odstranit i sebe a tím obec
            v&nbsp;rámci užívání nástroje opustit. Pozor, pokud odeberete sebe a tím obec v Smart City Compass opustíte
            a poté se budete chtít vrátit, bude vás muset znovu pozvat jiný člen s&nbsp;oprávněním správce. Navíc, pokud
            by došlo k&nbsp;opuštění obce posledním správce obce, ztratí obec možnost přidávat členy a nastavovat
            oprávnění. V&nbsp;takovém případě by bylo nutné se obrátit na administrátora Smart City Compass.
        </p>
    ),
});

export const csMunicipalityMembershipsSubsection: ManualSubsection = manualSubsection({
    key: "clenove",
    title: "Členové",
    intro: (
        <p>
            Na záložce „Členové“ se nachází seznam členů obce s&nbsp;jejich pozicemi a možnost členy odebírat, volit
            jejich pozice nebo odesílat pozvánky novým členům.
        </p>
    ),
    subSubsections: [
        csMunicipalityMembershipsInvitationSubSubsection,
        csMunicipalityMembershipsPositionsSubSubsection,
        csMunicipalityMembershipsRemovalSubSubsection,
    ],
});

export const csMunicipalityMembersSection: ManualSection = manualSection({
    key: "clenove",
    title: "Správa členů obce",
    intro: (
        <>
            <p>
                Správa členů obce se skládá ze dvou částí – správa členů a správa pozic. Přepnout mezi těmito dvěma
                částmi můžete pomocí záložek uprostřed horní poloviny okna.
            </p>
            <p>
                Základní myšlenkou tohoto rozdělení je možnost nezávisle spravovat pozice v&nbsp;obci s jejich
                oprávněními a přiřazovat pozice konkrétním členům. Pro přidání nového člena s&nbsp;nějakými oprávněními
                – například spravovat profil obce a vyplňovat hodnoty – je nutné nejdříve vytvořit odpovídající pozici
                na záložce „Pozice“ a poté pozvat samotného člena na záložce „Členové“.
            </p>
        </>
    ),
    subsections: [csMunicipalityPositionsSubsection, csMunicipalityMembershipsSubsection],
});

const csMunicipalityTrackedIndicatorGroupsCreationSubsection: ManualSubsection = manualSubsection({
    key: "vytvoreni",
    title: "Vytvoření skupiny",
    intro: (
        <p>
            Novou skupinu vytvoříte kliknutím na tlačítko&nbsp;
            <AddIcon style={{verticalAlign: "bottom"}} />. Po jejím vytvoření dojde automaticky k&nbsp;jejímu otevření
            v&nbsp;režimu výběru indikátorů. Popis stránky s výběrem sledovaných indikátorů se nachází
            v&nbsp;kapitole&nbsp;
            <ManualQueryLink chapter="obec" section="vyber-indikatoru" />.
        </p>
    ),
});

const csMunicipalityTrackedIndicatorGroupsBrowsingSubsection: ManualSubsection = manualSubsection({
    key: "zobrazeni",
    title: "Zobrazení sledovaných indikátorů",
    intro: (
        <p>
            Pro zobrazení sledovaných indikátorů klikněte na ikonku&nbsp;
            <VisibilityIcon style={{verticalAlign: "bottom"}} /> u příslušené skupiny. Popis stránky zobrazení
            sledovaných indikátorů se nachází v&nbsp;kapitole&nbsp;
            <ManualQueryLink chapter="obec" section="hodnoty-indikatoru" />.
        </p>
    ),
});

const csMunicipalityTrackedIndicatorGroupsEditSubsection: ManualSubsection = manualSubsection({
    key: "uprava",
    title: "Úprava vlastností skupiny",
    intro: (
        <p>
            Pro úpravu vlastností skupiny je nutné ji otevřít v&nbsp;režimu výběru indikátorů. To můžete udělat
            kliknutím na ikonku&nbsp;
            <EditIcon style={{verticalAlign: "bottom"}} />. Popis stránky s výběrem sledovaných indikátorů se nachází
            v&nbsp;kapitole&nbsp;
            <ManualQueryLink chapter="obec" section="vyber-indikatoru" />.
        </p>
    ),
});

const csMunicipalityTrackedIndicatorGroupsSelectionSubsection: ManualSubsection = manualSubsection({
    key: "vyber",
    title: "Výběr sledovaných indikátorů ve skupině",
    intro: (
        <p>
            Pro výběr sledovaných indikátorů ve skupině je nutné ji otevřít v&nbsp;režimu výběru indikátorů. To můžete
            udělat kliknutím na ikonku&nbsp;
            <EditIcon style={{verticalAlign: "bottom"}} />. Popis stránky s výběrem sledovaných indikátorů se nachází
            v&nbsp;kapitole&nbsp;
            <ManualQueryLink chapter="obec" section="vyber-indikatoru" />.
        </p>
    ),
});

const csMunicipalityTrackedIndicatorGroupsRemovalSubsection: ManualSubsection = manualSubsection({
    key: "odstraneni",
    title: "Odstranění skupiny",
    intro: (
        <p>
            Skupinu můžete odstranit kliknutím na ikonku&nbsp;
            <DeleteIcon style={{verticalAlign: "bottom"}} /> a následným potvrzením.
        </p>
    ),
});

export const csMunicipalityTrackedIndicatorGroupsSection: ManualSection = manualSection({
    key: "skupiny-indikatoru",
    title: "Skupiny sledovaných indikátorů",
    intro: (
        <p>
            Sledovat indikátory je možné v&nbsp;jednotlivých skupinách. Skupiny je možné využít pro sledování indikátorů
            z&nbsp;různých indikátorových sad nebo oddělení indikátorů veřejných a neveřejných. Ve většině případů ale
            může postačit skupina jediná. Pro zahájení výběru sledovaných indikátorů je nutné alespoň jednu skupinu
            vytvořit.
        </p>
    ),
    subsections: [
        csMunicipalityTrackedIndicatorGroupsCreationSubsection,
        csMunicipalityTrackedIndicatorGroupsBrowsingSubsection,
        csMunicipalityTrackedIndicatorGroupsEditSubsection,
        csMunicipalityTrackedIndicatorGroupsSelectionSubsection,
        csMunicipalityTrackedIndicatorGroupsRemovalSubsection,
    ],
});

const csMunicipalityIndicatorSelectionPropertiesSubsection: ManualSubsection = manualSubsection({
    key: "uprava-vlastnosti",
    title: "Úprava vlastností skupiny",
    intro: (
        <p>
            Název nebo popis můžete upravit kliknutím na ikonu&nbsp;
            <EditIcon style={{verticalAlign: "bottom"}} />, přepsáním hodnoty a následně kliknutím na ikonu &nbsp;
            <SaveIcon style={{verticalAlign: "bottom"}} /> pro uložení.
        </p>
    ),
});

const csMunicipalityIndicatorSelectionAddingFromLibrarySubSubsection: ManualSubSubsection = manualSubSubsection({
    key: "ze-sady",
    title: "Přidání z veřejně dostupné nebo vlastní sady",
    content: (
        <>
            <p>
                Po volbě přidání z&nbsp;veřejně dostupné nebo vlastní indikátorové sady u příslušné sady zvolte, zda ji
                chcete přidat celou nebo zda se chcete podívat na její obsah a vybrat poté jen některé části pro vás
                relevantní.
            </p>
            <p>
                U každé položky se nachází tlačítko&nbsp;
                <InfoIcon style={{verticalAlign: "bottom"}} />, které můžete použít pro zobrazení detailů.
            </p>
            <p>
                Při výběru konkrétních částí zaškrtněte ty položky, které chcete přidat a výběr nakonec potvrďte
                tlačítkem v&nbsp;dolní části okna.
            </p>
        </>
    ),
});

const csMunicipalityIndicatorSelectionAddingNewSubSubsection: ManualSubSubsection = manualSubSubsection({
    key: "novy",
    title: "Vytvoření nového vlastního indikátoru",
    content: (
        <p>
            Obec může, stejně jako jakákoliv organizace, tvořit vlastní indikátorové sady. Této problematice se věnuje
            sekce&nbsp;
            <ManualLink section={csOrganizationLibrariesSection} />.
        </p>
    ),
});

const csMunicipalityIndicatorSelectionPublishingSubsection: ManualSubsection = manualSubsection({
    key: "zverejneni",
    title: "Zveřejnění indikátorů",
    intro: (
        <>
            <p>
                Zveřejnit můžete buď celou skupinu indikátorů s&nbsp;veškerým obsahem, nebo konkrétní položky.
                K&nbsp;tomu slouží zaškrtávátko „Zveřejnit skupinu“ nahoře na stránce a zaškrtávátka u jednotlivých
                položek.
            </p>
            <p>Zaškrtnutím „Zveřejnit skupinu“ dojde ke zveřejnění veškerého obsahu skupinu.</p>
            <p>
                Zaškrtnutím „Zveřejnit“ u konkrétní oblasti nebo cíle dojde ke zveřejnění veškerých položek uvnitř, ale
                ne v&nbsp;jiných oblastech, resp. cílech.
            </p>
            <p>
                Zaškrtnutím „Zveřejnit“ u indikátoru zveřejníte právě jen tento jeden indikátor. S&nbsp;tím se vždy
                zveřejní i nadřazený cíl a oblast, ale už ne další indikátory v&nbsp;nich.
            </p>
        </>
    ),
});

const csMunicipalityIndicatorSelectionAddingSubsection: ManualSubsection = manualSubsection({
    key: "pridani",
    title: "Přidání nových indikátorů",
    intro: (
        <>
            <p>
                Indikátory je možné přidávat z&nbsp;veřejných indikátorových sad od jiných obcí a organizací na
                platformě Smart City Compass nebo je možné vytvářet a spravovat vlastní indikátorové sady.
            </p>
            <p>
                Začněte kliknutím na tlačítko „Přidat indikátory“ a poté vyberte, zda si přejete přidat indikátory
                z&nbsp;veřejně dostupných sad, z&nbsp;vlastních sad nebo zda si přejete vytvořit indikátor nový.
            </p>
            <p>
                Nově přidané položky se nejprve přidaní neveřejně. Poté, co jste s&nbsp;výběrem spokojeni, je můžete
                zveřejnit. Více informace najdete v&nbsp;sekci&nbsp;
                <ManualLink subsection={csMunicipalityIndicatorSelectionPublishingSubsection} />.
            </p>
        </>
    ),
    subSubsections: [
        csMunicipalityIndicatorSelectionAddingFromLibrarySubSubsection,
        csMunicipalityIndicatorSelectionAddingNewSubSubsection,
    ],
});

const csMunicipalityIndicatorSelectionDetailsSubsection: ManualSubsection = manualSubsection({
    key: "detaily",
    title: "Zobrazení detailů oblasti, cíle nebo indikátoru",
    intro: (
        <p>
            U každé položky se nachází tlačítko&nbsp;
            <InfoIcon style={{verticalAlign: "bottom"}} />, které můžete použít pro zobrazení detailů.
        </p>
    ),
});

const csMunicipalityIndicatorSelectionOrderingSubsection: ManualSubsection = manualSubsection({
    key: "poradi",
    title: "Změna pořadí",
    intro: (
        <p>
            Indikátory jsou členěny do oblastí a v&nbsp;nich spadají pod konkrétní cíle. Již přidané indikátory, cíle i
            oblasti můžete libovolně přesouvat kliknutím na ikonku&nbsp;
            <SwapVertIcon style={{verticalAlign: "bottom"}} /> u příslušné položky a poté kliknutím na ikonku&nbsp;
            <InputIcon style={{verticalAlign: "bottom"}} /> pro výběr, kam se má přesunout.
        </p>
    ),
});

const csMunicipalityIndicatorSelectionRemovalSubsection: ManualSubsection = manualSubsection({
    key: "odstraneni",
    title: "Odstranění indikátoru",
    intro: (
        <p>
            Indikátor můžete odstranit po kliknutí na ikonku&nbsp;
            <DeleteIcon style={{verticalAlign: "bottom"}} />. Odstraněním indikátorů nedojde k&nbsp;odstranění jejich
            příslušných zadaných vstupních hodnot, pouze přijdete o vyplněné cílové hodnoty.
        </p>
    ),
});

export const csMunicipalityIndicatorSelectionSection: ManualSection = manualSection({
    key: "vyber-indikatoru",
    title: "Výběr sledovaných indikátorů",
    intro: (
        <p>
            Na této stránce můžete upravit vlastnosti skupiny sledovaných indikátorů a zejména sledované indikátory
            vybírat, odebírat, měnit jejich pořadí a nastavovat jejich zveřejnění.
        </p>
    ),
    subsections: [
        csMunicipalityIndicatorSelectionPropertiesSubsection,
        csMunicipalityIndicatorSelectionAddingSubsection,
        csMunicipalityIndicatorSelectionDetailsSubsection,
        csMunicipalityIndicatorSelectionOrderingSubsection,
        csMunicipalityIndicatorSelectionRemovalSubsection,
        csMunicipalityIndicatorSelectionPublishingSubsection,
    ],
});

const csMunicipalityIndicatorBrowsingInputSubsection: ManualSubsection = manualSubsection({
    key: "vyplneni-hodnot",
    title: "Vyplňování vstupních hodnot",
    intro: (
        <>
            <p>
                Všechny hodnoty indikátorů jsou vypočítané z&nbsp;jednotlivých vstupních hodnot. Například indikátor
                řešící podíl parků k&nbsp;rozloze města může mít vstupní hodnoty rozlohu města a rozlohu všech parků.
            </p>
            <p>
                Pro vyplnění hodnot indikátorů je proto nutné vyplnit jejich příslušné vstupní hodnoty. To je možné buď
                pro každý indikátor zvlášť dle instrukcí v&nbsp;sekci&nbsp;
                <ManualQueryLink
                    chapter="obec"
                    section="hodnoty-indikatoru"
                    subsection="vstupni-hodnoty-indikatoru"
                />{" "}
                nebo společně pro všechny indikátory v&nbsp;sekci&nbsp;
                <ManualQueryLink chapter="obec" section="vstupni-hodnoty" />. Výhodou společného vyplňování je, že pokud
                mají dva indikátory stejnou vstupní hodnotu, například zastavěnou plochu, u společného vyplňování
                položku „Zastavěná plocha“ najdete jen 1x, kdežto u separátního vyplňování ji najdete u každého
                indikátoru, který ji používá. Není navíc nutné rozklikávat jednotlivé indikátory.
            </p>
        </>
    ),
});

const csMunicipalityIndicatorBrowsingDisplaySubsection: ManualSubsection = manualSubsection({
    key: "zobrazeni",
    title: "Zobrazení hodnot indikátorů",
    intro: (
        <>
            <p>
                Hodnoty indikátorů je možné zobrazit buď souhrnně k&nbsp;aktuálnímu (či jinému konkrétnímu) datu, nebo
                včetně historických hodnot pro jednotlivé indikátory.
            </p>
            <p>
                Pro souhrnné zobrazení k&nbsp;aktuálnímu datu použijte tlačítko „Souhrnné hodnoty indikátorů“. Tam
                najdete možnost volby data, ke kterému mají hodnoty být (na začátku je vybráno aktuální datum) a možnost
                tento přehled stáhnout ve formátu csv.
            </p>
            <p>
                Pro zobrazení všech hodnot konkrétního indikátoru rozbalte pomocí ikonky&nbsp;
                <ExpandMoreIcon style={{verticalAlign: "bottom"}} /> oblast a poté cíl, ve kterém se indikátor nachází a
                poté rozbalte daný indikátor. Na počátku jsou zobrazeny všechny historické hodnoty (při větším počtu
                hodnot jsou rozdělené na stránky) a je možné je omezit na konkrétní data pomocí přepnutí na „Vlastní
                rozmezí“.
            </p>
        </>
    ),
});

const csMunicipalityIndicatorBrowsingTargetValuesSubsection: ManualSubsection = manualSubsection({
    key: "cilove-hodnoty",
    title: "Nastavení cílových hodnot sledovaných indikátorů",
    intro: (
        <>
            <p>
                Cílovou hodnotu indikátoru můžete vyplnit po rozbalení daného indikátoru stejným způsobem jako při
                zobrazení jeho hodnot, kliknutím na ikonku&nbsp;
                <EditIcon style={{verticalAlign: "bottom"}} />, nastavením příslušné hodnoty (u některých indikátorů je
                možné zvolit jednotku, ve které hodnotu vyplňujete) a potvrzením kliknutím na ikonku&nbsp;
                <SaveIcon style={{verticalAlign: "bottom"}} />.
            </p>
            <p>
                Odstranit cílovou hodnotu můžete pomocí ikonky&nbsp;
                <DeleteIcon style={{verticalAlign: "bottom"}} />.
            </p>
        </>
    ),
});

const csMunicipalityIndicatorBrowsingIndicatorInputValuesSubsection: ManualSubsection = manualSubsection({
    key: "vstupni-hodnoty-indikatoru",
    title: "Vyplnění vstupních hodnot konkrétního indikátoru",
    intro: (
        <>
            <p>
                Vyplnění vstupních hodnot pro jeden konkrétní indikátor (oproti systematickému vyplňování všech hodnot
                společně, viz sekce&nbsp;
                <ManualLink subsection={csMunicipalityIndicatorBrowsingInputSubsection} />
                ) můžete vyplnit po rozbalení daného indikátoru stejným způsobem jako při zobrazení jeho hodnot a
                kliknutím na ikonku&nbsp;
                <KeyboardIcon style={{verticalAlign: "bottom"}} />. Dojde tím k&nbsp;otevření seznamu všech vstupních
                hodnot, ze kterých se počítá hodnota indikátoru. V&nbsp;tomto seznamu postupujte stejně jako při
                společném vyplňování všech vstupních hodnot dle sekce&nbsp;
                <ManualQueryLink chapter="obec" section="vstupni-hodnoty" />. Na rozdíl od společného vyplňování je
                nutné sledovat, zda nevyplňujete podruhé vstupní hodnotu, kterou jste již vyplnili předtím, u jiného
                indikátoru.
            </p>
            <p>
                Po vyplnění všech vstupních hodnot daného indikátoru klikněte na „Zpět na indikátor“ pro návrat na
                přehled indikátorů.
            </p>
        </>
    ),
});

export const csMunicipalityIndicatorBrowsingSection: ManualSection = manualSection({
    key: "hodnoty-indikatoru",
    title: "Sledování hodnot indikátorů a export",
    intro: (
        <p>
            Na této stránce se nachází přehled všech sledovaných oblastí, jejich cílů a indikátorů a jejich historické
            hodnoty. Také je zde možné nastavit cílové hodnoty indikátorů.
        </p>
    ),
    subsections: [
        csMunicipalityIndicatorBrowsingInputSubsection,
        csMunicipalityIndicatorBrowsingDisplaySubsection,
        csMunicipalityIndicatorBrowsingTargetValuesSubsection,
        csMunicipalityIndicatorBrowsingIndicatorInputValuesSubsection,
    ],
});

const csMunicipalityInputValuesDisplaySubsection: ManualSubsection = manualSubsection({
    key: "zobrazeni",
    title: "Zobrazení stávající historie hodnot",
    intro: (
        <p>
            Pro zobrazení historie hodnot rozbalte vstupní proměnnou kliknutím na ikonku&nbsp;
            <ExpandMoreIcon style={{verticalAlign: "bottom"}} />. Na počátku jsou zobrazeny všechny historické hodnoty
            (při větším počtu hodnot jsou rozdělené na stránky) a je možné je omezit na konkrétní data pomocí přepnutí
            na „Vlastní rozmezí“
        </p>
    ),
});

const csMunicipalityInputValuesAddingSubsection: ManualSubsection = manualSubsection({
    key: "pridani",
    title: "Přidání nové hodnoty",
    intro: (
        <>
            <p>
                Pro přidání nové hodnoty ke vstupní proměnné klikněte na ikonku{" "}
                <AddIcon style={{verticalAlign: "bottom"}} />. Zobrazí se pole pro vyplnění samotné hodnoty, u některých
                proměnných je zde také možnost volby jednotek, ve kterých hodnotu zadáváte a posledním políčkem je
                datum. Jedná se o datum, ke kterému tato hodnota je nebo byla platná. Je tedy možné přidávat hodnoty i
                zpětně.
            </p>
            <p>
                Po zapsání správné hodnoty a data potvrďte hodnotu kliknutím na ikonku&nbsp;
                <SaveIcon style={{verticalAlign: "bottom"}} />. Pokud si vyplňování rozmyslíte, ikonkou&nbsp;
                <DeleteIcon style={{verticalAlign: "bottom"}} /> můžete vyplňování této hodnoty zrušit.
            </p>
        </>
    ),
});

export const csMunicipalityInputValuesSection: ManualSection = manualSection({
    key: "vstupni-hodnoty",
    title: "Vyplňování vstupních hodnot",
    intro: (
        <p>
            Všechny hodnoty indikátorů jsou vypočítané z&nbsp;jednotlivých vstupních hodnot. Například indikátor řešící
            podíl parků k&nbsp;rozloze města může mít vstupní proměnné rozlohu města a rozlohu všech parků. Tato stránka
            umožňuje vyplnění hodnot všech proměnných pro všechny indikátory společně. Duplicitní vstupní proměnné
            z&nbsp;více indikátorů jsou zde sloučeny do jediné položky.
        </p>
    ),
    subsections: [csMunicipalityInputValuesDisplaySubsection, csMunicipalityInputValuesAddingSubsection],
});

export const csMunicipalityManualChapter: ManualChapter = manualChapter({
    key: "obec",
    title: "Užívání Smart City Compass vedením obcí, řídícím pracovníky či pracovníky úřadu",
    intro: (
        <>
            <p>
                Obce jsou hlavní cílovou skupinou nástroje. Smart City Compass jim pomáhá zorientovat se v základních
                cílech a principech udržitelných chytrých měst, využít je pro vlastní plánování&nbsp;a začít s jejich
                vyhodnocováním. Nástroj umožňuje obcím vybrat indikátory z&nbsp;připravených sad, či sestavit indikátory
                vlastní a sledovat jejich aktuální hodnoty za účelem vyhodnocování efektivity opatření i pro strategické
                rozhodování a plánování.
            </p>
            <p>
                Nástroj podporuje efektivní práci s&nbsp;daty i rozdělení kompetencí uvnitř úřadu při jejich zadávání a
                využívání.
            </p>
        </>
    ),
    sections: [
        csMunicipalityProfileSection,
        csMunicipalityMembersSection,
        csMunicipalityTrackedIndicatorGroupsSection,
        csMunicipalityIndicatorSelectionSection,
        csMunicipalityIndicatorBrowsingSection,
        csMunicipalityInputValuesSection,
    ],
    requiresAdmin: false,
});

export default csMunicipalityManualChapter;
