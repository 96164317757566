import React from "react";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
// Material UI imports
import {Box, Table, TableBody, TableCell, TableHead, TableRow, Tooltip} from "@material-ui/core";
import CheckboxIcon from "@material-ui/icons/CheckBox";
import BusinessIcon from "@material-ui/icons/Business";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import TimelineIcon from "@material-ui/icons/Timeline";
import KeyboardIcon from "@material-ui/icons/Keyboard";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Pagination from "@material-ui/lab/Pagination";
// Project imports
import {
    AddCircleActionButton,
    DeleteActionButton,
    EditActionButton,
    RefreshActionButton,
} from "../../../common/buttons";
import {ErrorNotification, InfoNotification, SuccessNotification} from "../../../common/notifications";
import {ServerCommunicationAlert} from "../../../common/errors";
import {Loading, LoadingRow} from "../../../common/Loading";
import API, {createApiConfig} from "../../../../utils/API";
import {
    MembershipPositionCreateDTO,
    MembershipPositionMaintainerDTO,
    MembershipPositionUpdateDTO,
} from "../../../../models/members";
import {OrganizationDTO} from "../../../../models/organization";
import {TrackedDomainSimpleDTO} from "../../../../models/trackedIndicators";
import PermissionIcon from "./PermissionIcon";
import PositionEditDialog from "./PositionEditDialog";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {ConfirmationDialog, DeleteConfirmationDialog} from "../../../common/dialogs";
import {makeFullName} from "../../../../models/user";
import {AxiosError} from "axios";
import {Page} from "../../../../models/pagination";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        iconCell: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    })
);

interface PositionRowProps {
    isMunicipality: boolean;
    position: MembershipPositionMaintainerDTO;
    trackedDomains: TrackedDomainSimpleDTO[];
    isOnlyUsedOneWithFullPermissions: boolean;
}

const PositionRow: React.FC<PositionRowProps> = (props: PositionRowProps) => {
    //== Init ===================================================================
    const [t] = useTranslation("organizationMembers");
    const {keycloak, initialized} = useKeycloak();
    const classes = useStyles();
    const [state, setState] = React.useState({
        position: props.position,
        savingPosition: null as MembershipPositionUpdateDTO | null,
        name: "init" as
            | "init"
            | "confirmingSave"
            | "saving"
            | "saved"
            | "confirmingDelete"
            | "deleting"
            | "deleted"
            | "editing"
            | "failed",
        action: "init" as "init" | "edit" | "delete",
    });
    //== Effects ================================================================
    React.useEffect(() => {
        if (state.name === "deleting") {
            API.delete<void>(`/positions/${props.position.id}`, createApiConfig(keycloak, initialized))
                .then(() => {
                    setState({...state, name: "deleted", action: "delete"});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed", action: "delete"});
                });
        } else if (state.name === "saving") {
            if (!state.savingPosition) {
                console.log("nothing to save");
                setState({...state, name: "failed"});
                return;
            }
            const stateValueReadWriteTrackedDomainIds = new Set(
                state.position.valueReadWriteTrackedDomains.map((domainUse) => domainUse.id)
            );
            console.log("!state.savingPosition:");
            console.log(!state.savingPosition);
            console.log("state.savingPosition && state.savingPosition.valuesPermissionLevel:");
            console.log(state.savingPosition && state.savingPosition.valuesPermissionLevel);
            console.log("state.position.valuesPermissionLevel:");
            console.log(state.position.valuesPermissionLevel);
            if (
                state.savingPosition.name === state.position.name &&
                state.savingPosition.description === state.position.description &&
                state.savingPosition.profilePermissionLevel === state.position.profilePermissionLevel &&
                state.savingPosition.librariesPermissionLevel === state.position.librariesPermissionLevel &&
                (!props.isMunicipality ||
                    (state.savingPosition.trackedIndicatorsPermissionLevel ===
                        state.position.trackedIndicatorsPermissionLevel &&
                        state.savingPosition.valuesPermissionLevel === state.position.valuesPermissionLevel)) &&
                state.savingPosition.valueReadWriteTrackedDomains.length ===
                    state.position.valueReadWriteTrackedDomains.length &&
                state.savingPosition.valueReadWriteTrackedDomains.every((domainUse) =>
                    stateValueReadWriteTrackedDomainIds.has(domainUse.id)
                )
            ) {
                console.log("nothing to save");
                setState({...state, name: "saved"});
            } else {
                console.log("saving");
                API.put<MembershipPositionMaintainerDTO>(
                    `/positions/` + props.position.id,
                    state.savingPosition,
                    createApiConfig(keycloak, initialized)
                )
                    .then((res) => {
                        setState({
                            ...state,
                            position: res.data,
                            savingPosition: null,
                            name: "saved",
                            action: "edit",
                        });
                    })
                    .catch((err: AxiosError) => {
                        setState({...state, name: "failed", savingPosition: null, action: "edit"});
                    });
            }
        }
    }, [keycloak, initialized, state, setState, props.position]);
    //== Handlers ===============================================================

    const handleCancelDelete = (): void => {
        setState({...state, name: "init"});
    };
    const handleConfirmDelete = (): void => {
        setState({...state, name: "deleting"});
    };
    const handleDelete = (): void => {
        setState({...state, name: "confirmingDelete"});
    };
    const handleEdit = (): void => {
        setState({...state, name: "editing"});
    };
    const handleCancelSave = (): void => {
        setState({...state, savingPosition: null, name: "editing"});
    };
    const handleConfirmSave = (): void => {
        setState({...state, name: "saving"});
    };
    const handleSave = (editedPosition: MembershipPositionUpdateDTO): void => {
        setState({
            ...state,
            savingPosition: editedPosition,
            name:
                state.position.profilePermissionLevel === "FULL" &&
                editedPosition.profilePermissionLevel !== "FULL" &&
                state.position.memberships.length
                    ? "confirmingSave"
                    : "saving",
        });
    };
    const handleCancel = (): void => {
        setState({...state, name: "init"});
    };
    //== Render =================================================================
    if (state.name === "deleted") {
        return <SuccessNotification message={t(`notifications.delete_ok`)} />;
    }
    if (state.name === "saving" || state.name === "deleting") {
        return <LoadingRow key={state.position.id} />;
    }
    // - default
    let notification: JSX.Element | null = null;
    if (state.name === "failed") {
        const a = state.action;
        notification = <ErrorNotification message={t(`notifications.${a}_fail`)} />;
    }
    if (state.name === "saved") {
        notification = <SuccessNotification message={t(`notifications.edit_ok`)} />;
    }
    const membershipsThatWouldLoseMaintainer = props.position.memberships.filter(
        (membership) =>
            !membership.positions.some(
                (ownPosition) => ownPosition.profilePermissionLevel === "FULL" && ownPosition.id !== props.position.id
            )
    );
    const wouldILoseMaintainer = membershipsThatWouldLoseMaintainer.some((membership) => membership.isOwn);
    const listOfMembersThatWouldLoseMaintainer = membershipsThatWouldLoseMaintainer
        .map(
            (membership) =>
                makeFullName(membership.person) +
                (membership.isOwn ? " (" + t("ui.positions.editConfirmDialog.yourself") + ")" : "")
        )
        .join(", ");
    return (
        <TableRow key={state.position.id ?? ""}>
            <TableCell>{state.position.name}</TableCell>
            <TableCell>{state.position.description}</TableCell>
            <TableCell align="center">
                {state.position.profilePermissionLevel === "FULL" ? <CheckboxIcon /> : null}
            </TableCell>
            <TableCell className={classes.iconCell}>
                <PermissionIcon
                    level={state.position.profilePermissionLevel}
                    itemName={t("position.profilePermissionLevel")}
                >
                    <BusinessIcon />
                </PermissionIcon>
            </TableCell>
            <TableCell className={classes.iconCell}>
                <PermissionIcon
                    level={state.position.librariesPermissionLevel}
                    itemName={t("position.librariesPermissionLevel")}
                >
                    <AccountTreeIcon />
                </PermissionIcon>
            </TableCell>
            <TableCell className={classes.iconCell}>
                {state.position.trackedIndicatorsPermissionLevel ? (
                    <PermissionIcon
                        level={state.position.trackedIndicatorsPermissionLevel}
                        itemName={t("position.trackedIndicatorsPermissionLevel")}
                    >
                        <TimelineIcon />
                    </PermissionIcon>
                ) : null}
            </TableCell>
            <TableCell className={classes.iconCell}>
                {state.position.valuesPermissionLevel ? (
                    <PermissionIcon
                        level={state.position.valuesPermissionLevel}
                        itemName={t("position.valuesPermissionLevel")}
                    >
                        <KeyboardIcon />
                    </PermissionIcon>
                ) : null}
            </TableCell>
            <TableCell className={classes.iconCell}>
                {state.position.valuesPermissionLevel && state.position.valueReadWriteTrackedDomains.length ? (
                    <PermissionIcon level={"READWRITE"} itemName={t("position.valueTrackedDomains")}>
                        <MoreHorizIcon />
                    </PermissionIcon>
                ) : null}
            </TableCell>
            <TableCell align="right">
                <EditActionButton onClick={handleEdit} />
                <ConfirmationDialog
                    {...(props.isOnlyUsedOneWithFullPermissions
                        ? {
                              title: t("ui.positions.editConfirmDialog.onlyWithFullPermissions.title"),
                              text: t("ui.positions.editConfirmDialog.onlyWithFullPermissions.text", {
                                  name: state.position.name,
                              }),
                              checkbox: t("ui.positions.editConfirmDialog.onlyWithFullPermissions.checkbox"),
                          }
                        : wouldILoseMaintainer
                        ? {
                              title: t("ui.positions.editConfirmDialog.own.title"),
                              text:
                                  t("ui.positions.editConfirmDialog.own.text", {
                                      name: state.position.name,
                                  }) + listOfMembersThatWouldLoseMaintainer,
                              checkbox: t("ui.positions.editConfirmDialog.own.checkbox"),
                          }
                        : {
                              title: t("ui.positions.editConfirmDialog.title"),
                              text:
                                  t("ui.positions.editConfirmDialog.text", {name: state.position.name}) +
                                  listOfMembersThatWouldLoseMaintainer,
                          })}
                    open={state.name === "confirmingSave"}
                    id={"delete-dialog-" + props.position.id}
                    onAgree={handleConfirmSave}
                    onDisagree={handleCancelSave}
                />
                {state.position.memberships.length + state.position.invitations.length ? (
                    <Tooltip
                        title={
                            (state.position.memberships.length
                                ? t("ui.positions.hasMemberships")
                                : t("ui.positions.hasInvitations")) as string
                        }
                    >
                        <span>
                            <DeleteActionButton disabled={true} onClick={handleDelete} />
                        </span>
                    </Tooltip>
                ) : (
                    <DeleteActionButton onClick={handleDelete} />
                )}
                <DeleteConfirmationDialog
                    title={t("ui.positions.deleteConfirmDialog.title")}
                    text={t("ui.positions.deleteConfirmDialog.text", {name: state.position.name})}
                    open={state.name === "confirmingDelete"}
                    id={"delete-dialog-edit-" + props.position.id}
                    onDelete={handleConfirmDelete}
                    onCancel={handleCancelDelete}
                />
                <PositionEditDialog
                    position={state.position}
                    isMunicipality={props.isMunicipality}
                    trackedDomains={props.trackedDomains}
                    onSave={handleSave}
                    onCancel={handleCancel}
                    open={state.name === "editing"}
                />
                {notification}
            </TableCell>
        </TableRow>
    );
};

interface NewPositionHandlerProps {
    organization: OrganizationDTO;
    trackedDomains: TrackedDomainSimpleDTO[];

    onSave(position: MembershipPositionMaintainerDTO): void;
}

const NewPositionHandler: React.FC<NewPositionHandlerProps> = (props: NewPositionHandlerProps) => {
    //== Init ===================================================================
    const [t] = useTranslation("organizationMembers");
    const {keycloak, initialized} = useKeycloak();
    const [state, setState] = React.useState({
        savingPosition: null as MembershipPositionCreateDTO | null,
        name: "init" as "init" | "saving" | "saved" | "editing" | "failed",
    });
    //== Effects ================================================================
    React.useEffect(() => {
        if (state.name === "saving") {
            if (!state.savingPosition) {
                console.log("nothing to save");
                setState({...state, name: "failed"});
            } else {
                console.log("saving");
                API.post<MembershipPositionMaintainerDTO>(
                    `/positions`,
                    state.savingPosition,
                    createApiConfig(keycloak, initialized)
                )
                    .then((res) => {
                        setState({...state, savingPosition: null, name: "saved"});
                        props.onSave(res.data);
                    })
                    .catch((err: AxiosError) => {
                        setState({...state, name: "failed", savingPosition: null});
                    });
            }
        }
    }, [keycloak, initialized, state, setState]);
    //== Handlers ===============================================================
    const handleEdit = (): void => {
        setState({...state, name: "editing"});
    };
    const handleCancel = (): void => {
        setState({...state, name: "init"});
    };
    const handleSave = (position: MembershipPositionUpdateDTO): void => {
        setState({...state, savingPosition: {...position, organizationId: props.organization.id}, name: "saving"});
    };
    //== Render =================================================================

    // - default
    let notification: JSX.Element | null = null;
    if (state.name === "failed") {
        notification = <ErrorNotification message={t(`notifications.create_fail`)} />;
    }
    if (state.name === "saved") {
        notification = <SuccessNotification message={t(`notifications.create_ok`)} />;
    }
    return (
        <Box style={{float: "right"}}>
            {state.name === "saving" ? (
                <Loading />
            ) : (
                <>
                    <Box style={{margin: "1em"}}>
                        <AddCircleActionButton onClick={handleEdit} />
                    </Box>
                    <PositionEditDialog
                        isMunicipality={!!props.organization.municipality}
                        trackedDomains={props.trackedDomains}
                        onSave={handleSave}
                        onCancel={handleCancel}
                        open={state.name === "editing"}
                    />
                    {notification}
                </>
            )}
        </Box>
    );
};

interface PositionsProps {
    organization: OrganizationDTO;
}

const Positions: React.FC<PositionsProps> = (props: PositionsProps) => {
    //== Init ===================================================================
    const [t] = useTranslation("organizationMembers");
    const {keycloak, initialized} = useKeycloak();
    const initState = {
        page: {
            size: 100,
            totalElements: 0,
            totalPages: 0,
            number: 0,
        },
        positions: [] as MembershipPositionMaintainerDTO[],
        trackedDomains: [] as TrackedDomainSimpleDTO[],
        name: "loading",
        action: "init",
    };
    const [state, setState] = React.useState(initState);
    //== Effects ================================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<Page<MembershipPositionMaintainerDTO>>(
                `/positions?organization=${props.organization.publicId}` +
                    `&page=${state.page.number}&size=${state.page.size}`,
                createApiConfig(keycloak, initialized)
            )
                .then((positionsRes) => {
                    if (state.name === "loading") {
                        API.get<TrackedDomainSimpleDTO[]>(
                            `/tracked-domains/simple/organization/${props.organization.publicId}`,
                            createApiConfig(keycloak, initialized)
                        )
                            .then((domainUsesRes) => {
                                setState({
                                    ...state,
                                    page: positionsRes.data,
                                    trackedDomains: domainUsesRes.data === undefined ? [] : domainUsesRes.data,
                                    positions: positionsRes.data === undefined ? [] : positionsRes.data.content,
                                    name: "loaded",
                                });
                            })
                            .catch((err: AxiosError) => {
                                setState({...state, name: "failed"});
                            });
                    }
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [keycloak, initialized, state, setState, props.organization]);
    //== Handlers ===============================================================
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number): void => {
        setState({
            ...state,
            page: {...state.page, number: value - 1},
            name: "loading",
            action: "init",
        });
    };
    const handleSave = (position: MembershipPositionMaintainerDTO) => {
        setState({...state, positions: state.positions.concat([position])});
    };
    //== Render =================================================================
    if (state.name === "loading") {
        return <Loading />;
    }
    if (state.name === "failed") {
        return <ServerCommunicationAlert />;
    }
    // - default
    let notification: JSX.Element | null = null;
    if (state.name === "loaded" && state.action === "refresh") {
        notification = <InfoNotification message={t("notifications.refresh_ok")} />;
    }
    const isMunicipality = props.organization.municipality !== null;
    const usedPositionsWithFullPermissions = state.positions.filter(
        (position) => position.profilePermissionLevel === "FULL" && position.memberships.length
    );
    const rows = state.positions.map((position: MembershipPositionMaintainerDTO) => (
        <PositionRow
            key={position.id}
            position={position}
            isMunicipality={isMunicipality}
            trackedDomains={state.trackedDomains}
            isOnlyUsedOneWithFullPermissions={
                usedPositionsWithFullPermissions.length === 1 && usedPositionsWithFullPermissions[0].id === position.id
            }
        />
    ));
    console.log(state.positions);
    return (
        <Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t("position.name")}</TableCell>
                        <TableCell>{t("position.description")}</TableCell>
                        <TableCell align="center">
                            <span>{t("permissionLevels.full")} </span>
                            <span style={{whiteSpace: "nowrap"}}>{t("ui.includingMembers")}</span>
                        </TableCell>
                        <TableCell align="center" colSpan={5}>
                            {t("position.permissions")}
                        </TableCell>
                        <TableCell align="right">
                            <RefreshActionButton
                                onClick={(): void =>
                                    setState({
                                        ...initState,
                                        action: "refresh",
                                    })
                                }
                            />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{rows}</TableBody>
            </Table>
            {state.page.totalPages > 1 && (
                <Pagination
                    style={{marginTop: "1em"}}
                    count={state.page.totalPages}
                    page={state.page.number + 1}
                    onChange={handlePageChange}
                />
            )}
            <NewPositionHandler
                organization={props.organization}
                onSave={handleSave}
                trackedDomains={state.trackedDomains}
            />
            {notification}
        </Box>
    );
};

export default Positions;
