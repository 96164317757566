import {ProjectDTO} from "./projects";
import {TrackedIndicatorGroupSimpleDTO} from "./trackedIndicators";
import {ImageDTO} from "./images";

export const municipalityOrganizationType = "MUNICIPALITY";
export const nonMunicipalityOrganizationTypes = ["GOVERNMENT", "RESEARCH", "NONPROFIT", "COMPANY", "OTHER"];
export const organizationTypes = [municipalityOrganizationType].concat(nonMunicipalityOrganizationTypes);
export const municipalityTypes = ["TOWN", "MARKET_TOWN", "CITY", "STATUTE_CITY", "CAPITAL_CITY", "CITY_DISTRICT"];

export type OrganizationType = "MUNICIPALITY" | "GOVERNMENT" | "RESEARCH" | "NONPROFIT" | "COMPANY" | "OTHER";
export type MunicipalityType = "TOWN" | "CITY" | "MARKET_TOWN" | "STATUTE_CITY" | "CAPITAL_CITY" | "CITY_DISTRICT";

interface OrganizationBaseDTO {
    name: string;
    description: string | null;
    stateID: string | null;
    type: OrganizationType;
    web: string | null;
    municipality: MunicipalityDetailDTO | null;
}

export interface OrganizationCreateDTO extends OrganizationBaseDTO {
    contactPerson: ContactPersonCreateDTO | null;
}

export interface OrganizationDTO extends OrganizationBaseDTO {
    id: string;
    publicId: string;
    logo: ImageDTO | null;
    contactPerson: ContactPersonDTO | null;
}

export interface MunicipalityDetailDTO {
    id: string | null;
    lau2: string;
    areaKm2: number;
    level: number | null;
    population: number;
    populationYear: number | null;
    type: MunicipalityType;
    wgs84e: number | null;
    wgs84n: number | null;
    parentMunicipality: SimpleMunicipalityDTO | null;
    photo: ImageDTO | null;
}

export interface OrganizationProfileDTO {
    organization: OrganizationDTO;
    subjectMunicipalities: OrganizationDTO[];
    projects: ProjectDTO[];
    publicTrackedIndicatorGroups: TrackedIndicatorGroupSimpleDTO[];
    canReadValues: boolean;
}

export interface SimpleMunicipalityDTO {
    organizationId: string;
    municipalityId: string;
    publicId: string;
    name: string;
    type: MunicipalityType;
    lau2: string;
    hasLogo: boolean;
}

export function simpleMunicipalityFromOrganization(organization: OrganizationDTO): SimpleMunicipalityDTO | null {
    if (!organization.municipality || !organization.municipality.id) return null;
    return {
        organizationId: organization.id,
        municipalityId: organization.municipality.id,
        publicId: organization.publicId,
        name: organization.name,
        type: organization.municipality.type,
        lau2: organization.municipality.lau2,
        hasLogo: !!organization.logo,
    };
}

export interface MunicipalityListItemDTO extends SimpleMunicipalityDTO {
    population: number;
    areaKm2: number;
    level: number | null;
    active: boolean;
}

interface ContactPersonBaseDTO {
    fullName: string;
    email: string | null;
    phoneNumber: string | null;
    role: string | null;
}

export interface ContactPersonCreateDTO extends ContactPersonBaseDTO {}

export interface ContactPersonDTO extends ContactPersonBaseDTO {
    id: string;
}
