import React from "react";
// Material-UI imports
import {Box, Container} from "@material-ui/core";
import TrackedIndicatorsEditorContent from "./TrackedIndicatorsEditorContent";
import {useParams} from "react-router-dom";
import {LinkActionButton} from "../../../common/buttons";
import {ROUTES} from "../../../../routes/routes";
import BusinessIcon from "@material-ui/icons/Business";
import KeyboardIcon from "@material-ui/icons/Keyboard";
import {useTranslation} from "react-i18next";
import {useKeycloak} from "@react-keycloak/web";
import {
    TrackedIndicatorGroupSimpleDTO,
    TrackedIndicatorGroupWithContentDTO,
} from "../../../../models/trackedIndicators";
import API, {createApiConfig} from "../../../../utils/API";
import {AxiosError} from "axios";
import GroupDetailsEdit from "./GroupDetailsEdit";
import {Loading} from "../../../common/Loading";
import {BreadcrumbItem, BreadcrumbsRow} from "../../../common/breadcrumbs";
import {PageHeader} from "../../../common/headers";
import {ManualSidebar} from "../../../../manual/components/ManualSidebar";
import {csMunicipalityIndicatorSelectionSection} from "../../../../manual/content/cs/CsMunicipalityManualChapter";

export interface TrackedIndicatorGroupEditorParams {
    organizationId: string;
    trackedIndicatorGroupId: string;
}

const TrackedIndicatorGroupEditor: React.FC = () => {
    const [t] = useTranslation("trackedIndicators");
    const {organizationId, trackedIndicatorGroupId} = useParams<TrackedIndicatorGroupEditorParams>();
    const {keycloak, initialized} = useKeycloak();
    const [state, setState] = React.useState({
        trackedIndicatorGroup: null as TrackedIndicatorGroupWithContentDTO | null,
        name: "loading" as "loading" | "loaded" | "failed",
        action: "init" as "init" | "refresh",
    });
    //== Effects ================================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<TrackedIndicatorGroupWithContentDTO>(
                `/tracked-indicator-groups/with-content/${trackedIndicatorGroupId}`,
                createApiConfig(keycloak, initialized)
            )
                .then((res) => {
                    setState({
                        ...state,
                        trackedIndicatorGroup: res.data,
                        name: "loaded",
                    });
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [keycloak, initialized, state, setState, trackedIndicatorGroupId]);

    const handleChangeFromContent = (trackedIndicatorGroup: TrackedIndicatorGroupSimpleDTO) => {
        if (!state.trackedIndicatorGroup) return setState({...state, name: "loading"});
        setState({
            ...state,
            name: "loaded",
            trackedIndicatorGroup: {...state.trackedIndicatorGroup, ...trackedIndicatorGroup},
        });
    };

    return (
        <Container>
            <ManualSidebar section={csMunicipalityIndicatorSelectionSection} />
            <BreadcrumbsRow>
                <BreadcrumbItem
                    name={
                        state.trackedIndicatorGroup?.organization?.name || t("organization:organization.municipality")
                    }
                    route={ROUTES.organization.create(organizationId)}
                />
                <BreadcrumbItem
                    name={t("trackedIndicatorGroup.trackedIndicatorGroups")}
                    route={ROUTES.trackedIndicatorGroups.create(organizationId)}
                />
                <BreadcrumbItem
                    name={state.trackedIndicatorGroup?.name || t("browser.simpleTitle")}
                    route={ROUTES.trackedIndicatorsBrowser.create(organizationId, trackedIndicatorGroupId)}
                />
                <BreadcrumbItem
                    name={t("editor.editor")}
                    route={ROUTES.trackedIndicatorsEditor.create(organizationId, trackedIndicatorGroupId)}
                />
            </BreadcrumbsRow>
            <PageHeader title={t("editor.title")}>
                <LinkActionButton
                    to={ROUTES.organization.create(organizationId)}
                    icon={<BusinessIcon />}
                    title={t("actions.organization")}
                />
                <LinkActionButton
                    to={ROUTES.valuesInput.create(organizationId)}
                    icon={<KeyboardIcon />}
                    title={t("actions.valuesInput")}
                />
            </PageHeader>
            <Container>
                {state.name === "loading" || !state.trackedIndicatorGroup ? (
                    <Loading />
                ) : (
                    <Box>
                        <GroupDetailsEdit
                            trackedIndicatorGroup={state.trackedIndicatorGroup}
                            onChange={handleChangeFromContent}
                        />
                        <TrackedIndicatorsEditorContent
                            trackedIndicatorGroup={state.trackedIndicatorGroup}
                            onChange={handleChangeFromContent}
                        />
                    </Box>
                )}
            </Container>
        </Container>
    );
};

export default TrackedIndicatorGroupEditor;
