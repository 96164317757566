import React from "react";
import {DataAcquisitionDTO} from "../../../../models/library";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {useTranslation} from "react-i18next";
import {useKeycloak} from "@react-keycloak/web";
import {API, createApiConfig} from "../../../../utils/API";
import {AxiosError, AxiosResponse} from "axios";
import {comparator} from "../../../../utils/compare";
import InfoIcon from "@material-ui/icons/Info";

interface DataAcquisitionRowProps {
    index: number;
    item: DataAcquisitionDTO;
    onOpen: (index: number) => void;
}

const DataAcquisitionRow: React.FC<DataAcquisitionRowProps> = (props: DataAcquisitionRowProps) => {
    //== Init =================================================================
    const [t] = useTranslation(["values"]);
    //== Render ===============================================================
    return (
        <TableRow>
            <TableCell>
                <span>{props.item.dataSource.name}</span>
                {props.item.dataSource.description !== "" && (
                    <Tooltip title={`${t("input_variable.data_acquisition_items.sourceOpen")}`}>
                        <IconButton onClick={() => props.onOpen(props.index)} size="small">
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </TableCell>
            <TableCell>{props.item.description}</TableCell>
        </TableRow>
    );
};

interface InputVariableDataAcquisitionsProps {
    inputVariableId: string;
}

interface InputVariableDataAcquisitionsState {
    items: DataAcquisitionDTO[];
    openIndex: number;
    name: "loading" | "loaded" | "failed";
}

export const InputVariableDataAcquisitions: React.FC<InputVariableDataAcquisitionsProps> = (
    props: InputVariableDataAcquisitionsProps
) => {
    //== Init =================================================================
    const initState: InputVariableDataAcquisitionsState = {
        items: [],
        openIndex: -1,
        name: "loading",
    };
    const [state, setState] = React.useState(initState);
    const [t] = useTranslation(["values"]);
    const {keycloak, initialized} = useKeycloak();
    const daComparator = (x: DataAcquisitionDTO, y: DataAcquisitionDTO): number => {
        return comparator<string>(x.dataSource.name, y.dataSource.name);
    };
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<DataAcquisitionDTO[]>(
                `/data-acquisitions/list/input-variable/${props.inputVariableId}`,
                createApiConfig(keycloak, initialized)
            )
                .then((res: AxiosResponse<DataAcquisitionDTO[]>) => {
                    setState({...state, items: res.data.sort(daComparator), name: "loaded"});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [state.name, keycloak, initialized, props]);
    //== Handlers =============================================================
    const handleOpenInfo = (index: number) => {
        setState({...state, openIndex: index});
    };
    const handleCloseInfo = () => {
        setState({...state, openIndex: -1});
    };
    //== Render ===============================================================
    if (state.name === "loading") {
        return null;
    }
    return (
        <Box>
            <h3>{t("input_variable.data_acquisitions")}</h3>
            {state.name === "failed" && (
                <Alert severity="error">{t("input_variable.data_acquisition_items.failed")}</Alert>
            )}
            {state.items.length === 0 && (
                <Alert severity="info">{t("input_variable.data_acquisition_items.empty")}</Alert>
            )}
            {state.items.length > 0 && (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("input_variable.data_acquisition_items.sourceName")}</TableCell>
                            <TableCell>{t("input_variable.data_acquisition_items.description")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.items.map((item, index) => (
                            <DataAcquisitionRow key={index} index={index} item={item} onOpen={handleOpenInfo} />
                        ))}
                    </TableBody>
                </Table>
            )}
            <Dialog open={state.openIndex !== -1} onClose={handleCloseInfo} maxWidth="sm" fullWidth>
                <DialogTitle>{state.openIndex !== -1 ? state.items[state.openIndex].dataSource.name : ""}</DialogTitle>
                <DialogContent>
                    {state.openIndex !== -1 ? state.items[state.openIndex].dataSource.description : ""}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseInfo}>{t("input_variable.dialog.close")}</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};
