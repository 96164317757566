import React from "react";
import {useTranslation} from "react-i18next";
// Material UI imports
import {Box, Button, DialogActions, DialogContent} from "@material-ui/core";
// Project imports
import {
    IndicatorDTO,
    LibraryDomainUseDTO,
    LibraryDTO,
    LibraryGoalUseDTO,
    LibraryIndicatorUseDTO,
} from "../../../../../models/library";
import TrackedIndicatorGroupEditorDomainAdd from "./TrackedIndicatorGroupEditorDomainAdd";
import {
    DomainWithContent,
    GoalWithContent,
    TrackedIndicatorGroupEditorAddingDomain,
} from "./TrackedIndicatorGroupEditorAddingInterfaces";
import {TrackedIndicatorGroupEditorAddDialogProps} from "./TrackedIndicatorGroupEditorAddProps";

interface TrackedIndicatorGroupEditorAddFromLibraryProps extends TrackedIndicatorGroupEditorAddDialogProps {
    library: LibraryDTO;
    domainIdsInGroup?: Set<string>;
    goalIdsInGroup?: Set<string>;
    indicatorIdsInGroup?: Set<string>;
}

const TrackedIndicatorGroupEditorAddFromLibrary: React.FC<TrackedIndicatorGroupEditorAddFromLibraryProps> = (
    props: TrackedIndicatorGroupEditorAddFromLibraryProps
) => {
    const [t] = useTranslation(["trackedIndicators", "common"]);
    const addingDomains = (domains: LibraryDomainUseDTO[]): TrackedIndicatorGroupEditorAddingDomain[] => {
        console.log("!!! adding domains executed !!!");
        let domainIdsInGroupTemp = props.domainIdsInGroup;
        let goalIdsInGroupTemp = props.goalIdsInGroup;
        let indicatorIdsInGroupTemp = props.indicatorIdsInGroup;
        if (!domainIdsInGroupTemp || !goalIdsInGroupTemp || !indicatorIdsInGroupTemp) {
            const domainUsesInGroup = props.trackedIndicatorGroup.trackedDomains;
            domainIdsInGroupTemp =
                props.domainIdsInGroup ||
                new Set(domainUsesInGroup.map((domainUseInGroup) => domainUseInGroup.domain.id));
            if (!goalIdsInGroupTemp || !indicatorIdsInGroupTemp) {
                const goalUsesInGroup = domainUsesInGroup.flatMap((domainUseInGroup) => domainUseInGroup.trackedGoals);
                goalIdsInGroupTemp = props.goalIdsInGroup
                    ? props.goalIdsInGroup
                    : new Set(goalUsesInGroup.map((goalUseInGroup) => goalUseInGroup.goal.id));
                if (!indicatorIdsInGroupTemp) {
                    const indicatorUsesInGroup = goalUsesInGroup.flatMap(
                        (goalUseInGroup) => goalUseInGroup.trackedIndicators
                    );
                    indicatorIdsInGroupTemp = new Set(
                        indicatorUsesInGroup.map((indicatorUseInGroup) => indicatorUseInGroup.indicator.id)
                    );
                }
            }
        }
        const domainIdsInGroup = domainIdsInGroupTemp;
        const goalIdsInGroup = goalIdsInGroupTemp;
        const indicatorIdsInGroup = indicatorIdsInGroupTemp;

        return domains.map((libraryDomainUse: LibraryDomainUseDTO) => {
            const domain = libraryDomainUse.domain;
            const domainAlreadyAdded = domainIdsInGroup.has(domain.id);
            return {
                ...domain,
                checked: domainAlreadyAdded,
                alreadyAdded: domainAlreadyAdded,
                goals: libraryDomainUse.libraryGoalUses.map((libraryGoalUse: LibraryGoalUseDTO) => {
                    const goal = libraryGoalUse.goal;
                    const goalAlreadyAdded = goalIdsInGroup.has(goal.id);
                    return {
                        ...goal,
                        checked: goalAlreadyAdded,
                        alreadyAdded: goalAlreadyAdded,
                        indicators: libraryGoalUse.libraryIndicatorUses.map(
                            (libraryIndicatorUse: LibraryIndicatorUseDTO) => {
                                const indicator = libraryIndicatorUse.indicator;
                                const indicatorAlreadyAdded = indicatorIdsInGroup.has(indicator.id);
                                return {
                                    ...indicator,
                                    checked: indicatorAlreadyAdded,
                                    alreadyAdded: indicatorAlreadyAdded,
                                };
                            }
                        ),
                    };
                }),
            };
        });
    };
    console.log("TrackedIndicatorGroupEditorAddFromLibrary - domains:");
    console.log(props.library.libraryDomainUses);
    const initState = {
        page: {
            size: 50,
            totalElements: 0,
            totalPages: 0,
            number: 0,
        },
        domains: addingDomains(props.library.libraryDomainUses),
    };
    console.log("TrackedIndicatorGroupEditorAddFromLibrary - addingDomains:");
    console.log(initState.domains);
    const [state, setState] = React.useState(initState);
    const handleContentCheckChanged = (addingDomain: TrackedIndicatorGroupEditorAddingDomain) => {
        setState({
            ...state,
            domains: state.domains.map((eachAddingDomain) =>
                eachAddingDomain.id === addingDomain.id ? addingDomain : eachAddingDomain
            ),
        });
    };
    const handleConfirm = () => {
        const domainsToAdd: DomainWithContent[] = [];
        state.domains.forEach((domain) => {
            if (!domain.checked) return;
            const goalsToAdd: GoalWithContent[] = [];
            domain.goals.forEach((goal) => {
                if (!goal.checked) return;
                const indicatorsToAdd: IndicatorDTO[] = goal.indicators.filter(
                    (indicator) => indicator.checked && !indicator.alreadyAdded
                );
                if (indicatorsToAdd.length === 0 && goal.alreadyAdded) return;
                goalsToAdd.push({...goal, indicators: indicatorsToAdd});
            });
            if (goalsToAdd.length === 0 && domain.alreadyAdded) return;
            domainsToAdd.push({...domain, goals: goalsToAdd});
        });
        props.onAdd(domainsToAdd);
    };
    //== Render =================================================================
    const notification: JSX.Element | null = null;
    console.log("Rendering TrackedIndicatorGroupEditorAddFromLibrary - domains:");
    console.log(state.domains);
    return (
        <>
            <h2 style={{marginLeft: "1em", marginRight: "1em"}}>
                {t("ui.pickFromLibrary") + (props.library?.name ? " " + props.library.name : null)}
            </h2>
            <DialogContent>
                {/* TODO no content in library*/}
                <Box>
                    {state.domains.map((domain: TrackedIndicatorGroupEditorAddingDomain, index) => (
                        <TrackedIndicatorGroupEditorDomainAdd
                            key={domain.id}
                            domain={domain}
                            index={index}
                            handleCheckChanged={handleContentCheckChanged}
                        />
                    ))}

                    {notification}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                        props.onCancel();
                    }}
                    style={{margin: "0.5em"}}
                >
                    {t("actions.backToLibraries")}
                </Button>
                <Button variant="contained" color="primary" onClick={handleConfirm}>
                    {t("common:actions.accept")}
                </Button>
            </DialogActions>
        </>
    );
};

export default TrackedIndicatorGroupEditorAddFromLibrary;
