import {ReactChild, ReactPortal} from "react";
import {ManualSection} from "./ManualSection";
import {ManualSubSubsection} from "./ManualSubSubsection";

export interface ManualSubsection {
    key: string;
    title: string;
    intro?: ReactChild | ReactPortal;
    index?: number;
    section?: ManualSection;
    subSubsections: Map<string, ManualSubSubsection>;
}

interface ManualSubsectionProps {
    key: string;
    title: string;
    intro?: ReactChild | ReactPortal;
    subSubsections?: ManualSubSubsection[];
}

export const manualSubsection = (props: ManualSubsectionProps): ManualSubsection => {
    const subsection = {
        key: props.key,
        title: props.title,
        intro: props.intro || undefined,
        subSubsections: new Map(),
    };
    subsection.subSubsections = new Map(
        (props.subSubsections || []).map((subSubsection, index) => {
            subSubsection.index = index + 1;
            subSubsection.subsection = subsection;
            return [subSubsection.key, subSubsection];
        })
    );
    return subsection;
};

export default manualSubsection;
