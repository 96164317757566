import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import React from "react";
import AddIcon from "@material-ui/icons/Add";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ROUTES from "../../../routes/routes";
import EmailIcon from "@material-ui/icons/Email";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import EditIcon from "@material-ui/icons/Edit";
import {Link} from "@material-ui/core";
import manualSection, {ManualSection} from "../../model/ManualSection";
import manualSubsection, {ManualSubsection} from "../../model/ManualSubsection";
import manualChapter, {ManualChapter} from "../../model/ManualChapter";

export const csAccountRegistrationSection: ManualSection = manualSection({
    key: "registrace",
    title: "Registrace",
    intro: (
        <p>
            Na úvodní nebo přihlašovací obrazovce klikněte na „Registrovat se“. Vyplňte vaše jméno, e-mailovou adresu a
            heslo a potvrďte formulář kliknutím na tlačítko „Registrovat se“.
        </p>
    ),
});
const csAccountLoginSubsection: ManualSubsection = manualSubsection({
    key: "prihlaseni",
    title: "Přihlášení do aplikace",
    intro: (
        <p>
            Pokud nejste přihlášeni, klikněte v&nbsp;pravém horním roku na tlačítko „Přihlásit se“. Budete přesměrováni
            na přihlašovací obrazovku, kde můžete zadat své přihlašovací údaje – e-mailovou adresu a heslo. Po úspěšném
            přihlášení budete přesměrováni zpět do aplikace na stránku s&nbsp;přehledem pro váš účet.
        </p>
    ),
});
const csAccountLogoutSubsection: ManualSubsection = manualSubsection({
    key: "odhlaseni",
    title: "Odhlášení z aplikace",
    intro: (
        <p>
            Pokud jste už přihlášeni, v&nbsp;pravém horním rohu se můžete odhlásit kliknutím na ikonu&nbsp;
            <ExitToAppIcon style={{verticalAlign: "bottom"}} />.
        </p>
    ),
});
const csAccountRecoverySubsection: ManualSubsection = manualSubsection({
    key: "zapomenute-heslo",
    title: "Obnova zapomenutého hesla",
    intro: <p>V&nbsp;případě, že jste zapomněli vaše heslo, můžete jej obnovit pomocí odkazu „Zapomenuté heslo“.</p>,
});
export const csAccountLoginSection: ManualSection = manualSection({
    key: "prihlasovani",
    title: "Přihlášení a odhlášení",
    subsections: [csAccountLoginSubsection, csAccountLogoutSubsection, csAccountRecoverySubsection],
});
const csAccountProfileEditSubsection: ManualSubsection = manualSubsection({
    key: "uprava",
    title: "Úprava základních údajů",
    intro: (
        <p>
            Do režimu úprav se můžete přepnout kliknutím na tlačítko&nbsp;
            <EditIcon style={{verticalAlign: "bottom"}} />. Poté je možné upravit své jméno, tituly a informace o vaší
            osobě (profesní či jiné). Tyto změny profilu můžete uložit nebo zrušit.
        </p>
    ),
});
const csAccountProfilePhotoSubsection: ManualSubsection = manualSubsection({
    key: "obrazek",
    title: "Profilový obrázek",
    intro: (
        <p>
            Profilový obrázek můžete nahrát najetím myší na stávající obrázek a klepnutím na „Upravit“. Následně
            přetáhněte nový obrázek do dialogového okna nebo do něj klepněte a soubor vyberte v&nbsp;tradičním
            systémovém okně. Nakonec klikněte na „Potvrdit“.
        </p>
    ),
});
const csAccountProfileEmailsSubsection: ManualSubsection = manualSubsection({
    key: "emaily",
    title: "E-mailové adresy a jejich ověřování",
    intro: (
        <>
            <p>
                Aby vás ostatní mohli přidávat do organizací a obcí, musí použít vaši e-mailovou adresu. Pro zajištění
                bezpečnosti, musíte takovou e-mailovou adresu mít přidanou a ověřenou ve svém profilu. Pro přidání nové
                adresy ji zadejte do pole „Nová e-mailová adresa“ a klepněte na ikonu&nbsp;
                <AddIcon style={{verticalAlign: "bottom"}} />. Do příslušné e-mailové schránky bude odeslána ověřovací
                e-mailová zpráva.
            </p>
            <p>
                Pro ověření buď klikněte na odkaz v&nbsp;e-mailové zprávě nebo z&nbsp;ní zkopírujte ověřovací kód a
                zadejte jej u příslušné adresy ve správě vašeho profilu. Pokud e-mail nedorazil, můžete si jej nechat
                poslat znovu kliknutím na tlačítko&nbsp;
                <EmailIcon style={{verticalAlign: "bottom"}} /> ve správě profilu.
            </p>
        </>
    ),
});
const csAccountProfilePasswordSubsection: ManualSubsection = manualSubsection({
    key: "zmena-hesla",
    title: "Změna hesla a přihlašovacího e-mailu",
    intro: (
        <p>
            Pro změnu hesla nebo přihlašovacího e-mailu klikněte na tlačítko&nbsp;
            <VpnKeyIcon style={{verticalAlign: "bottom"}} /> ve správě vašeho profilu. Změnit heslo můžete na stránce
            „Heslo“, přihlašovací e-mail se nachází na stránce „Účet“.
        </p>
    ),
});
export const csAccountProfileSection: ManualSection = manualSection({
    key: "profil",
    title: "Správa uživatelského profilu",
    intro: (
        <p>
            Do správy svého osobního profilu se můžete dostat po přihlášení přes ikonu&nbsp;
            <AccountCircle style={{verticalAlign: "bottom"}} /> na horním panelu nebo z&nbsp;levého rozbalovacího menu
            položkou „Můj profil“.
        </p>
    ),
    subsections: [
        csAccountProfileEditSubsection,
        csAccountProfilePhotoSubsection,
        csAccountProfileEmailsSubsection,
        csAccountProfilePasswordSubsection,
    ],
});
export const csAccountRemovalSection: ManualSection = manualSection({
    key: "odstraneni",
    title: "Zrušení uživatelského účtu",
    intro: (
        <p>
            Pro trvalé zrušení vašeho účtu, kontaktujte správce aplikace (viz stránka{" "}
            <Link href={ROUTES.contacts.path} target="_blank">
                Kontakt
            </Link>
            ). Zrušení účtu je možné jen, pokud vám před ním budou odebrány všechna oprávnění do organizací a obcí.
        </p>
    ),
    subsections: [],
});
export const csAccountManualChapter: ManualChapter = manualChapter({
    key: "ucet",
    title: "Registrace a přihlášení",
    intro: (
        <p>
            Pokud chcete aplikaci používat v roli obce nebo organizace, je nutné mít osobní uživatelský účet. Vytváření
            obce či organizace však nyní předchází komunikace se správci nástroje Smart City Compass (viz stránka{" "}
            <Link href={ROUTES.contacts.path} target="_blank">
                Kontakt
            </Link>
            ).
        </p>
    ),
    sections: [csAccountRegistrationSection, csAccountLoginSection, csAccountProfileSection, csAccountRemovalSection],
    requiresAdmin: false,
});

export default csAccountManualChapter;
