import React from "react";
import {useTranslation} from "react-i18next";
// Project imports
import {CustomTabs, TabContent} from "../../common/tabs";
// Local imports
import Accounts from "./Accounts";
import Emails from "./Emails";
import People from "./People";
// Material-UI imports
import {Box, Tab} from "@material-ui/core";

interface UsersProps {
    tab?: "accounts" | "people" | "emails";
}

const tabToValue = (str?: string): number => {
    if (str === "people") return 1;
    if (str === "emails") return 2;
    return 0;
};

export const Users: React.FC<UsersProps> = (props: UsersProps) => {
    //== Init ===================================================================
    const [t] = useTranslation("user");
    const [value, setValue] = React.useState(tabToValue(props.tab));
    //== Handlers ===============================================================
    //eslint-disable-next-line @typescript-eslint/ban-types
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number): void => {
        setValue(newValue);
    };
    //== Render =================================================================
    return (
        <Box>
            <CustomTabs
                style={{marginBottom: "2em"}}
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
                <Tab label={t("users.accounts")} />
                <Tab label={t("users.people")} />
                <Tab label={t("users.emails")} />
            </CustomTabs>
            <TabContent index={0} value={value}>
                <Accounts />
            </TabContent>
            <TabContent index={1} value={value}>
                <People />
            </TabContent>
            <TabContent index={2} value={value}>
                <Emails />
            </TabContent>
        </Box>
    );
};

export const UsersPeople: React.FC = () => {
    return <Users tab="people" />;
};

export const UsersEmails: React.FC = () => {
    return <Users tab="emails" />;
};

export default Users;
