import React from "react";
import {useKeycloak} from "@react-keycloak/web";
import {useParams} from "react-router-dom";
// Project imports
import API, {createApiConfig} from "../../../utils/API";
import {AxiosError} from "axios";
import Loading from "../../common/Loading";
import {CommunicationError} from "../../common/errors";
import {OrganizationProfileDTO} from "../../../models/organization";
import OrganizationProfile from "./OrganizationProfile";
// Material-UI imports
import {Box} from "@material-ui/core";
import {
    adminOrganizationPermissions,
    anonymousOrganizationPermissions,
    OrganizationPermissionsDTO,
} from "../../../models/members";
import {hasUserRole, isUser, ROLES} from "../../../utils/auth";

type OrganizationProfileIndexParams = {
    organizationId: string;
};

export const OrganizationProfileLoader: React.FC<OrganizationProfileIndexParams> = (
    props: OrganizationProfileIndexParams
) => {
    const {keycloak, initialized} = useKeycloak();
    const [state, setState] = React.useState({
        name: "loading",
        organizationProfile: null as OrganizationProfileDTO | null,
        organizationPermissions: null as OrganizationPermissionsDTO | null,
    });
    console.log("state.name: " + state.name);
    console.log("state.organizationProfile:");
    console.log(state.organizationProfile);
    console.log("state.organizationPermissions:");
    console.log(state.organizationPermissions);
    console.log("initialized:");
    console.log(initialized);
    React.useEffect(() => {
        if (state.name !== "loading") return;
        API.get<OrganizationProfileDTO>(
            `/organizations/profile/${props.organizationId}`,
            createApiConfig(keycloak, initialized)
        )
            .then((profileRes) => {
                if (!isUser(keycloak)) {
                    return setState({
                        name: "loaded",
                        organizationProfile: profileRes.data,
                        organizationPermissions: anonymousOrganizationPermissions(props.organizationId),
                    });
                }
                if (hasUserRole(keycloak, ROLES.admin)) {
                    return setState({
                        name: "loaded",
                        organizationProfile: profileRes.data,
                        organizationPermissions: adminOrganizationPermissions(props.organizationId),
                    });
                }
                API.get<OrganizationPermissionsDTO>(
                    `/organization-permissions/${props.organizationId}/current-user`,
                    createApiConfig(keycloak, initialized)
                ).then((permissionsRes) => {
                    setState({
                        name: "loaded",
                        organizationProfile: profileRes.data,
                        organizationPermissions: permissionsRes.data,
                    });
                });
            })
            .catch((err: AxiosError) => {
                setState({name: "failed", organizationProfile: null, organizationPermissions: null});
            });
    }, [state, keycloak, initialized, props.organizationId]);

    if (state.name === "loading") {
        return <Loading />;
    } else if (state.name === "failed" || state.organizationProfile === null || state.organizationPermissions === null)
        return <CommunicationError />;
    return (
        <Box>
            <OrganizationProfile
                organizationProfile={state.organizationProfile}
                organizationPermissions={state.organizationPermissions}
            />
        </Box>
    );
};

export const OrganizationProfileIndex: React.FC = () => {
    const {organizationId} = useParams<OrganizationProfileIndexParams>();
    return <OrganizationProfileLoader organizationId={organizationId} />;
};
