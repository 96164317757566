import {PersonDTO} from "./user";

export interface TagSimpleDTO {
    id: string;
    name: string;
    description: string;
}

export interface TagDTO {
    id: string;
    createdAt: string;
    updatedAt: string;
    name: string;
    description: string;
    category: TagCategorySimpleDTO | null;
}

export interface TagCreateDTO {
    name: string;
    description: string;
    categoryUUID: string | null;
}

export interface TagCategorySimpleDTO {
    id: string;
    name: string;
    description: string;
    priority: number;
    style: string;
    icon: string;
}

export interface TagCategoryDTO {
    id: string;
    createdAt: string;
    updatedAt: string;
    name: string;
    description: string;
    priority: number;
    style: string;
    icon: string;
    tags: TagSimpleDTO[];
}

export interface TagCategoryCreateDTO {
    name: string;
    description: string;
    priority: number;
    style: string;
    icon: string;
}

export interface WebpageDTO {
    id?: string;
    createdAt?: string;
    updatedAt?: string;
    publicId: string;
    title: string;
    description: string | null;
    keywords: string | null;
    content: string;
    author: PersonDTO | null;
    tags: TagDTO[];
}

export interface TagStyle {
    background: string;
    textColor: string;
    iconColor: string;
}

export const defaultTagStyle: TagStyle = {
    background: "",
    textColor: "",
    iconColor: "",
};

export const defaultTagIcon = "";

export function loadTagStyle(cssStyle: string): TagStyle {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const loadStyle = (style: string): any => {
        try {
            const res = JSON.parse(style);
            if (typeof res === "object" && res !== null) {
                return res;
            }
        } catch {
            console.log("Ignoring invalid tag style");
        }
        return {};
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const loadStringProp = (obj: any, prop: string, defaultValue: string): string => {
        if (obj === null || obj === undefined) return defaultValue;
        return typeof obj[prop] === "string" ? obj[prop] : defaultValue;
    };
    const cssObj = loadStyle(cssStyle);
    return {
        background: loadStringProp(cssObj, "background", ""),
        textColor: loadStringProp(cssObj, "color", ""),
        iconColor: loadStringProp(cssObj["& > $MuiChipIcon"], "color", ""),
    };
}

export function getCssTagStyle(tagStyle: TagStyle): string {
    const cssObj = {
        background: tagStyle.background,
        color: tagStyle.textColor,
        "& > $MuiChipIcon": {
            color: tagStyle.iconColor,
        },
    };
    return JSON.stringify(cssObj);
}

export function compareTagsByAlphabet(a: TagDTO, b: TagDTO): number {
    const aName = a.name.toLowerCase();
    const bName = b.name.toLowerCase();
    if (aName < bName) {
        return -1;
    }
    if (aName > bName) {
        return 1;
    }
    return 0;
}

export function compareTagsByCategory(a: TagDTO, b: TagDTO): number {
    const ac = a.category?.priority ?? -1;
    const aName = a.category?.name ?? "";
    const bc = b.category?.priority ?? -1;
    const bName = b.category?.name ?? "";
    if (ac === bc) {
        if (aName < bName) {
            return -1;
        }
        if (aName > bName) {
            return 1;
        }
        return compareTagsByAlphabet(a, b);
    }
    return bc - ac;
}
