import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {DomainDTO} from "../../../models/library";
import {Tags} from "../../common/tags";
import {Alert} from "@material-ui/lab";
import {LibrariesWithElement} from "./LibrariesWithElement";
import {MarkdownRender} from "../../common/markdown";

interface DomainDialogProps {
    domain: DomainDTO;
    open: boolean;

    handleClose(): void;
}

export const DomainDialog: React.FC<DomainDialogProps> = (props: DomainDialogProps) => {
    const [t] = useTranslation(["values"]);
    //== Render ===============================================================
    return (
        <Dialog open={props.open} onClose={props.handleClose} maxWidth="md" fullWidth>
            <DialogTitle>{t("domain.dialog.title", {name: props.domain.name})}</DialogTitle>
            <DialogContent>
                <h3>{t("domain.description")}</h3>
                {props.domain.description ? (
                    <MarkdownRender value={props.domain.description} />
                ) : (
                    <Alert severity="info">{t("domain.dialog.missing.description")}</Alert>
                )}
                <LibrariesWithElement elementType="domain" id={props.domain.id} />
                <h3>{t("domain.tags")}</h3>
                {props.domain.tags.length > 0 ? (
                    <Tags tags={props.domain.tags} />
                ) : (
                    <Alert severity="info">{t("domain.dialog.missing.tags")}</Alert>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>{t("domain.dialog.close")}</Button>
            </DialogActions>
        </Dialog>
    );
};
