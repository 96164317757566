import React from "react";
import {LibraryDomainUseDTO, LibraryDTO} from "../../../models/library";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";
import {ExpandLessActionButton, ExpandMoreActionButton, InfoActionButton} from "../../common/buttons";
import {DomainDialog} from "../../values/dialogs/DomainDialog";
import {LibraryGoalBrowser} from "./LibraryGoalBrowser";
import {compareByAttr} from "../../../utils/compare";

interface LibraryDomainBrowserProps {
    library: LibraryDTO;
    libraryDomainUse: LibraryDomainUseDTO;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        domainRow: {
            borderWidth: 0,
            borderStyle: "solid",
            borderBottomWidth: 2,
            borderColor: "rgb(0,125,180)",
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(0.5),
            paddingLeft: theme.spacing(1),
            display: "flex",
            alignItems: "center",
        },
        domainItemName: {
            marginLeft: theme.spacing(0.5),
            fontSize: "140%",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
        },
        infoButton: {
            flexGrow: 1,
        },
    })
);

export const LibraryDomainBrowser: React.FC<LibraryDomainBrowserProps> = (props: LibraryDomainBrowserProps) => {
    //== Init =================================================================
    const classes = useStyles();
    const [infoOpen, setInfoOpen] = React.useState(false);
    const [childOpen, setChildOpen] = React.useState(false);
    //== Handlers =============================================================
    const handleInfoOpen = () => {
        setInfoOpen(true);
    };
    const handleInfoClose = () => {
        setInfoOpen(false);
    };
    const handleChildOpen = () => {
        setChildOpen(true);
    };
    const handleChildClose = () => {
        setChildOpen(false);
    };
    //== Render ===============================================================
    return (
        <Box>
            <Box className={classes.domainRow}>
                <Box>
                    {childOpen ? (
                        <ExpandLessActionButton onClick={handleChildClose} />
                    ) : (
                        <ExpandMoreActionButton onClick={handleChildOpen} />
                    )}
                </Box>
                <Box className={classes.domainItemName}>{props.libraryDomainUse.domain.name}</Box>
                <Box className={classes.infoButton}>
                    <InfoActionButton onClick={handleInfoOpen} />
                </Box>
                <DomainDialog domain={props.libraryDomainUse.domain} handleClose={handleInfoClose} open={infoOpen} />
            </Box>
            {childOpen && (
                <Box>
                    {props.libraryDomainUse.libraryGoalUses.sort(compareByAttr("order", "ascending")).map((goal) => (
                        <LibraryGoalBrowser key={goal.id} libraryGoalUse={goal} {...props} />
                    ))}
                </Box>
            )}
        </Box>
    );
};
