import React from "react";
import {Box, Divider, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {DashboardWidgetProps} from "./index";
import AdminFullMembershipRequests from "../../organization/members/Members/AdminFullMembershipRequests";

const AdminFullMembershipRequestsWidget: React.FC<DashboardWidgetProps> = (props: DashboardWidgetProps) => {
    const [t] = useTranslation("organizationMembers");
    return (
        <Box style={{paddingBottom: "0.5em"}}>
            <Typography variant="h5" component="h2" style={{marginBottom: "1.5em"}}>
                {t("request.fullMembershipRequests")}
            </Typography>
            <Divider />
            <AdminFullMembershipRequests />
        </Box>
    );
};

export default AdminFullMembershipRequestsWidget;
