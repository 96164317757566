import React, {useRef, useState} from "react";
import {Link as ReactLink} from "react-router-dom";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
// Material UI imports
import {
    Box,
    Container,
    IconButton,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
// Project imports
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import SearchIcon from "@material-ui/icons/Search";
import {ServerCommunicationAlert} from "../../common/errors";
import {Loading} from "../../common/Loading";
import {MunicipalityListItemDTO, municipalityTypes} from "../../../models/organization";
import API, {createApiConfig} from "../../../utils/API";
import {AxiosError} from "axios";
import Link from "@material-ui/core/Link";
import ROUTES from "../../../routes/routes";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Alert} from "@material-ui/lab";
import {CenteredImage} from "../../common/images";
import {OrganizationProfileLoader} from "../OrganizationProfile";
import {Page} from "../../../models/pagination";
import {ManualSidebar} from "../../../manual/components/ManualSidebar";
import {csPublicSearchSection} from "../../../manual/content/cs/CsPublicManualChapter";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        profileContent: {
            margin: "2em",
            border: "1px solid black",
        },
        visuallyHidden: {
            border: 0,
            clip: "rect(0 0 0 0)",
            height: 1,
            margin: -1,
            overflow: "hidden",
            padding: 0,
            position: "absolute",
            top: 20,
            width: 1,
        },
        logo: {
            width: "6em",
            height: "3em",
        },
    })
);

type SortableProperties = "name" | "type" | "county_cz" | "region_cz" | "area" | "population" | "active";

const propertiesWithDefaultDesc = ["type", "area", "population"] as SortableProperties[];

interface MunicipalityRowProps {
    municipality: MunicipalityListItemDTO;
}

const Municipality: React.FC<MunicipalityRowProps> = (props: MunicipalityRowProps) => {
    //== Init ===================================================================
    const [t] = useTranslation("organization");
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    //== Render =================================================================
    return (
        <>
            <TableRow key={props.municipality.publicId}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell align="center">
                    <div className={classes.logo}>
                        {props.municipality.hasLogo ? (
                            <CenteredImage
                                src={API.defaults.baseURL + "/organizations/" + props.municipality.publicId + "/logo"}
                                alt={t("organization.logo")}
                            />
                        ) : null}
                    </div>
                </TableCell>
                <TableCell align="center">
                    <Link component={ReactLink} to={ROUTES.organization.create(props.municipality.publicId)}>
                        {props.municipality.name}
                    </Link>
                </TableCell>
                <TableCell align="center"> {t("municipalityType." + props.municipality.type.toUpperCase())} </TableCell>
                <TableCell align="center">
                    {i18n.exists("organization:county." + props.municipality?.lau2.substring(0, 6))
                        ? t("county." + props.municipality.lau2.substring(0, 6))
                        : t("placeholders.unknown")}
                </TableCell>
                <TableCell align="center">
                    {i18n.exists("organization:region." + props.municipality?.lau2.substring(0, 5))
                        ? t("region." + props.municipality?.lau2.substring(0, 5))
                        : t("placeholders.unknown")}
                </TableCell>
                <TableCell align="right">{props.municipality.population.toLocaleString("cs-CZ")}</TableCell>
                <TableCell align="right">
                    <span>
                        <span>{props.municipality.areaKm2.toLocaleString("cs-CZ")}</span>
                        <span>
                            &nbsp;km<span style={{verticalAlign: "super"}}>2</span>
                        </span>
                    </span>
                </TableCell>
                <TableCell align="right">
                    <span>
                        <span>{t("common:common." + props.municipality.active)}</span>
                    </span>
                </TableCell>
            </TableRow>
            {open ? (
                <TableRow>
                    <TableCell colSpan={9}>
                        <Box className={classes.profileContent}>
                            <OrganizationProfileLoader organizationId={props.municipality.organizationId} />
                        </Box>
                    </TableCell>
                </TableRow>
            ) : null}
        </>
    );
};

interface SortableHeaderProps {
    label: string;
    orderedBy: SortableProperties;
    orderedDirection: "asc" | "desc";
    align?: "left" | "center" | "right" | "justify" | "inherit";
    property: SortableProperties;
    handler: (property: SortableProperties) => void;
    classOfHidden?: string;
}

function SortableHeader(props: SortableHeaderProps) {
    const orderedByThisColumn = props.orderedBy === props.property;
    return (
        <TableCell align="center">
            <TableSortLabel
                active={orderedByThisColumn}
                direction={
                    orderedByThisColumn
                        ? props.orderedDirection
                        : propertiesWithDefaultDesc.includes(props.property)
                        ? "desc"
                        : "asc"
                }
                onClick={() => props.handler(props.property)}
            >
                {props.label}
                {orderedByThisColumn ? (
                    <span className={props.classOfHidden}>{props.orderedDirection === "desc" ? "▼" : "▲"}</span>
                ) : null}
            </TableSortLabel>
        </TableCell>
    );
}

const Municipalities: React.FC = () => {
    //== Init ===================================================================
    const [t] = useTranslation("organization");
    const {keycloak, initialized} = useKeycloak();
    const initState = {
        page: {
            size: 50,
            totalElements: 0,
            totalPages: 0,
            number: 0,
        },
        searchString: "",
        municipalities: [] as MunicipalityListItemDTO[],
        orderBy: "active" as SortableProperties,
        orderDirection: "desc" as "asc" | "desc",
        name: "loading" as "loading" | "loaded" | "failed" | "empty",
    };
    const classes = useStyles();
    const [state, setState] = React.useState(initState);
    const searchedString = useRef("");
    //== Effects ================================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            searchedString.current = state.searchString.trim();
            API.get<Page<MunicipalityListItemDTO>>(
                `/organizations/search?name=${encodeURIComponent(
                    state.searchString.trim()
                )}&types=${municipalityTypes.join(",")}${
                    state.orderBy ? "&sorted=" + state.orderBy + "&order=" + state.orderDirection : ""
                }&page=${state.page.number}&size=${state.page.size}`,
                createApiConfig(keycloak, initialized)
            )
                .then((res) => {
                    if (searchedString.current != state.searchString.trim()) return;
                    if (!res.data) return setState({...state, name: "empty"});
                    setState({
                        ...state,
                        page: res.data,
                        municipalities: res.data.content,
                        name: "loaded",
                    });
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [keycloak, initialized, state, setState]);
    //== Handlers ===============================================================
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number): void => {
        setState({
            ...state,
            page: {...state.page, number: value - 1},
            name: "loading",
        });
    };
    const handleSort = (property: SortableProperties) => {
        console.log("handleSort:");
        console.log(state);
        console.log(property);
        setState({
            ...state,
            orderBy: property,
            orderDirection:
                (state.orderBy === property && state.orderDirection === "asc") ||
                (state.orderBy !== property && property !== null && propertiesWithDefaultDesc.includes(property))
                    ? "desc"
                    : "asc",
            name: "loading",
        });
    };
    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log('handleSearch: Setting state.searchString = "' + e.target.value + '"');
        setState({
            ...state,
            name: "loading",
            searchString: e.target.value,
            orderBy: state.orderBy === "active" ? "name" : state.orderBy,
            orderDirection: state.orderBy === "active" ? "asc" : state.orderDirection,
        });
    };
    //== Render =================================================================
    const content = () => {
        if (state.name === "loading") {
            return <Loading />;
        }
        if (state.name === "failed") {
            return <ServerCommunicationAlert />;
        }
        if (state.name === "empty") {
            return <Alert severity="warning">{t("notifications.search_empty")}</Alert>;
        }
        const defaultHeaderProperties = {
            orderedBy: state.orderBy,
            orderedDirection: state.orderDirection,
            align: "center" as "left" | "center" | "right" | "justify" | "inherit" | undefined,
            handler: handleSort,
            classOfHidden: classes.visuallyHidden,
        };
        return (
            <>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" />
                            <TableCell align="center">{t("organization.logo")}</TableCell>
                            <SortableHeader
                                label={t("organization.name")}
                                property="name"
                                {...defaultHeaderProperties}
                            />
                            <SortableHeader
                                label={t("organization.municipalityTypeShort")}
                                property="type"
                                {...defaultHeaderProperties}
                            />
                            <SortableHeader
                                label={t("type.COUNTY")}
                                property="county_cz"
                                {...defaultHeaderProperties}
                            />
                            <SortableHeader
                                label={t("type.REGION")}
                                property="region_cz"
                                {...defaultHeaderProperties}
                            />
                            <SortableHeader
                                label={t("organization.population")}
                                property="population"
                                {...defaultHeaderProperties}
                            />
                            <SortableHeader
                                label={t("organization.area")}
                                property="area"
                                {...defaultHeaderProperties}
                            />
                            <SortableHeader
                                label={t("ui.activeOnCompass")}
                                property="active"
                                {...defaultHeaderProperties}
                                align="right"
                            />
                        </TableRow>
                    </TableHead>
                    <TableBody>{rows}</TableBody>
                </Table>
                <div style={{margin: "1em"}}>
                    {state.page.totalPages > 1 && (
                        <Pagination
                            style={{margin: "auto", marginTop: "1em"}}
                            count={state.page.totalPages}
                            page={state.page.number + 1}
                            onChange={handlePageChange}
                        />
                    )}
                </div>
            </>
        );
    };
    // - default
    const rows = state.municipalities.map((municipality: MunicipalityListItemDTO) => (
        <Municipality key={municipality.publicId} municipality={municipality} />
    ));

    return (
        <Container maxWidth="md">
            <ManualSidebar section={csPublicSearchSection} />
            <Box display="flex" justifyContent="center">
                <Container maxWidth="sm">
                    <TextField
                        label={t("ui.search")}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        fullWidth
                        onChange={handleSearch}
                    />
                </Container>
            </Box>
            <Box style={{marginTop: "2em"}}>{content()}</Box>
        </Container>
    );
};

export default Municipalities;
