import React from "react";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
// Material UI imports
import {Box, Card, CardActionArea, CardActions, CardContent, Container, Grid, Typography} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
// Project imports
import {DeleteActionButton, EditLinkActionButton, ShowLinkActionButton} from "../../../common/buttons";
import {ErrorNotification, InfoNotification, SuccessNotification} from "../../../common/notifications";
import {ServerCommunicationAlert} from "../../../common/errors";
import {Loading} from "../../../common/Loading";
import API, {createApiConfig} from "../../../../utils/API";
import {AxiosError} from "axios";
import {TrackedIndicatorGroupCreateDTO, TrackedIndicatorGroupSimpleDTO} from "../../../../models/trackedIndicators";
import ROUTES from "../../../../routes/routes";
import {Redirect} from "react-router";
import {Link, useParams} from "react-router-dom";
import {isAdmin, isUser} from "../../../../utils/auth";
import {
    adminOrganizationPermissions,
    anonymousOrganizationPermissions,
    MembershipPermissionLevel,
    OrganizationPermissionsDTO,
} from "../../../../models/members";
import WorkingSVG from "../../../../graphics/undraw/factory_dy0a.svg";
import {BreadcrumbItem, BreadcrumbsRow} from "../../../common/breadcrumbs";
import {PageHeader, PrimaryPageAction} from "../../../common/headers";
import AddIcon from "@material-ui/icons/Add";
import {Page} from "../../../../models/pagination";
import {ManualSidebar} from "../../../../manual/components/ManualSidebar";
import {csMunicipalityTrackedIndicatorGroupsSection} from "../../../../manual/content/cs/CsMunicipalityManualChapter";
import {csPublicIndicatorsSection} from "../../../../manual/content/cs/CsPublicManualChapter";
import {TrackedIndicatorGroupDelete} from "./TrackedIndicatorGroupDelete";

interface TrackedIndicatorGroupCardProps {
    trackedIndicatorGroup: TrackedIndicatorGroupSimpleDTO;
    organizationId: string;
    editable: boolean;
    onDelete: () => void;
}

type TrackedIndicatorGroupCardState = "init" | "deleting" | "deleted" | "deleteFailed";

const TrackedIndicatorGroupCard: React.FC<TrackedIndicatorGroupCardProps> = (props: TrackedIndicatorGroupCardProps) => {
    //== Init =================================================================
    const [t] = useTranslation("trackedIndicators");
    const [state, setState] = React.useState<TrackedIndicatorGroupCardState>("init");
    //== Handlers ==============================================================
    const handleDeleteCancel = (): void => {
        setState("init");
    };
    const handleDeleteFailed = (): void => {
        setState("deleteFailed");
    };
    const handleDeleteDone = (): void => {
        setState("deleted");
    };
    const handleDelete = (): void => {
        setState("deleting");
    };
    //== Render ===============================================================
    let notification: JSX.Element | null = null;
    if (state === "deleteFailed") {
        notification = <ErrorNotification message={t(`trackedIndicatorGroupsNotifications.delete_fail`)} />;
    } else if (state === "deleted") {
        notification = <SuccessNotification message={t(`trackedIndicatorGroupsNotifications.delete_ok`)} />;
    }
    console.log("TrackedIndicatorGroupCard - editable:");
    console.log(props.editable);
    const actionAreaUrl = props.trackedIndicatorGroup.hasIndicators
        ? ROUTES.trackedIndicatorsBrowser.create(props.organizationId, props.trackedIndicatorGroup.publicId)
        : ROUTES.trackedIndicatorsEditor.create(props.organizationId, props.trackedIndicatorGroup.publicId);
    return (
        <Card style={{height: "100%"}}>
            <Grid container direction="column" style={{height: "100%"}}>
                <Grid item style={{flexGrow: 1}}>
                    {state === "deleting" ? (
                        <CardContent>
                            <Typography variant="body2" color="textSecondary" component="p">
                                {t("trackedIndicatorGroupsNotifications.deleting") + "..."}
                            </Typography>
                        </CardContent>
                    ) : state === "deleted" ? (
                        <CardContent>
                            <Typography variant="body2" color="textSecondary" component="p">
                                {t("trackedIndicatorGroupsNotifications.deleted")}
                            </Typography>
                        </CardContent>
                    ) : (
                        <CardActionArea component={Link} to={actionAreaUrl}>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {props.trackedIndicatorGroup.name}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    {props.trackedIndicatorGroup.description}
                                    {/* TODO: shorten text if too long */}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    )}
                </Grid>
                <Grid item>
                    {state === "deleted" ? null : (
                        <CardActions>
                            {props.trackedIndicatorGroup.hasIndicators ? (
                                <ShowLinkActionButton
                                    to={ROUTES.trackedIndicatorsBrowser.create(
                                        props.organizationId,
                                        props.trackedIndicatorGroup.publicId
                                    )}
                                />
                            ) : null}
                            {props.editable ? (
                                <>
                                    <EditLinkActionButton
                                        to={ROUTES.trackedIndicatorsEditor.create(
                                            props.organizationId,
                                            props.trackedIndicatorGroup.publicId
                                        )}
                                    />
                                    <DeleteActionButton onClick={handleDelete} />
                                    <TrackedIndicatorGroupDelete
                                        entity={state === "deleting" ? props.trackedIndicatorGroup : null}
                                        onDeleted={handleDeleteDone}
                                        onCancel={handleDeleteCancel}
                                        onFailed={handleDeleteFailed}
                                    />
                                </>
                            ) : null}
                        </CardActions>
                    )}
                </Grid>
            </Grid>
            {notification}
        </Card>
    );
};

interface TrackedIndicatorGroupsDeckProps {
    trackedIndicatorGroups: TrackedIndicatorGroupSimpleDTO[];
    organizationId: string;
    permissionlevel: MembershipPermissionLevel;

    onDelete?: (trackedIndicatorGroup: TrackedIndicatorGroupSimpleDTO) => void;
}

export const TrackedIndicatorGroupsDeck: React.FC<TrackedIndicatorGroupsDeckProps> = (
    props: TrackedIndicatorGroupsDeckProps
) => {
    const [t] = useTranslation("trackedIndicators");
    console.log("TrackedIndicatorGroupsDeckProps - permissionlevel:");
    console.log(props.permissionlevel);
    if (!props.trackedIndicatorGroups.length)
        return (
            <Box style={{textAlign: "center", width: "100%"}}>
                <h2>{t("browser.no_tracked_indicator_group")}</h2>
                <img src={WorkingSVG} style={{maxWidth: "40em", margin: "3em"}} alt="no-tracked-indicator-groups" />
            </Box>
        );

    return (
        <Grid container spacing={4} direction="row" justifyContent="center" alignItems="stretch">
            {props.trackedIndicatorGroups.map((trackedIndicatorGroup: TrackedIndicatorGroupSimpleDTO) => {
                return (
                    <Grid item md={4} xs={12} key={trackedIndicatorGroup.id}>
                        <TrackedIndicatorGroupCard
                            trackedIndicatorGroup={trackedIndicatorGroup}
                            organizationId={props.organizationId}
                            editable={props.permissionlevel === "READWRITE"}
                            onDelete={() => {
                                const onDeleteFunction = props.onDelete;
                                if (onDeleteFunction) onDeleteFunction(trackedIndicatorGroup);
                            }}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
};

interface TrackedIndicatorGroupsParams {
    organizationId: string;
}

interface SimplePage {
    size: number;
    totalElements: number;
    totalPages: number;
    number: number;
}

const TrackedIndicatorGroups: React.FC = () => {
    // TODO: DISPLAY ORG NAME IN BREADCRUMBS!
    //== Init ===================================================================
    const [t] = useTranslation(["trackedIndicators", "organization"]);
    const {organizationId} = useParams<TrackedIndicatorGroupsParams>();
    const {keycloak, initialized} = useKeycloak();
    const initState = {
        page: {
            size: 12,
            totalElements: 0,
            totalPages: 0,
            number: 0,
        },
        permissions: anonymousOrganizationPermissions(organizationId),
        trackedIndicatorGroups: [] as TrackedIndicatorGroupSimpleDTO[],
        name: "loading" as "loading" | "loaded" | "failed" | "added",
        action: "init" as "init" | "add" | "refresh",
    };
    const [state, setState] = React.useState(initState);
    //== Effects ================================================================
    const setPermissionsAndContent = (
        trackedIndicatorGroups: TrackedIndicatorGroupSimpleDTO[],
        permissions: OrganizationPermissionsDTO,
        page: SimplePage
    ) => {
        setState({
            ...state,
            page: page,
            permissions: permissions,
            trackedIndicatorGroups: trackedIndicatorGroups,
            name: "loaded",
        });
    };
    React.useEffect(() => {
        if (state.name !== "added" && state.action === "add") {
            const newTrackedIndicatorGroup: TrackedIndicatorGroupCreateDTO = {
                organizationId: organizationId,
                publicId: null,
                name:
                    t("trackedIndicatorGroup.trackedIndicatorGroup") + " " + (state.trackedIndicatorGroups.length + 1),
                description: null,
            };
            API.post<TrackedIndicatorGroupSimpleDTO>(
                `/tracked-indicator-groups`,
                newTrackedIndicatorGroup,
                createApiConfig(keycloak, initialized)
            )
                .then((res) => {
                    if (!res.data) {
                        console.log("adding: failed to read POST /tracked-indicator-groups");
                        return setState({...state, name: "failed"});
                    }
                    setState({
                        ...state,
                        name: "added",
                        trackedIndicatorGroups: state.trackedIndicatorGroups.concat([res.data]),
                    });
                })
                .catch((err: AxiosError) => {
                    console.log("adding: exception of POST /tracked-indicator-groups");
                    setState({...state, name: "failed"});
                });
        } else if (state.name === "loading") {
            const params = `page=${state.page.number}&size=${state.page.size}`;
            API.get<Page<TrackedIndicatorGroupSimpleDTO>>(
                `/tracked-indicator-groups/simple/organization/${organizationId}?${params}`,
                createApiConfig(keycloak, initialized)
            )
                .then((res) => {
                    console.log(res);
                    const trackedIndicatorGroups: TrackedIndicatorGroupSimpleDTO[] = res.data?.content ?? [];
                    if (isAdmin(keycloak)) {
                        console.log("permissions: admin");
                        setPermissionsAndContent(
                            trackedIndicatorGroups,
                            adminOrganizationPermissions(organizationId),
                            res.data
                        );
                    } else if (isUser(keycloak)) {
                        console.log("permissions: asking server...");
                        API.get<OrganizationPermissionsDTO>(
                            `/organization-permissions/${organizationId}/current-user`,
                            createApiConfig(keycloak, initialized)
                        )
                            .then((permissionsRes) => {
                                if (!permissionsRes.data) return setState({...state, name: "failed"});
                                setPermissionsAndContent(trackedIndicatorGroups, permissionsRes.data, res.data);
                            })
                            .catch((err) => {
                                console.log("permissions get error:");
                                console.log(err);
                                return setState({...state, name: "failed"});
                            });
                    } else {
                        console.log("permissions: anonymous");
                        setPermissionsAndContent(
                            trackedIndicatorGroups,
                            anonymousOrganizationPermissions(organizationId),
                            res.data
                        );
                    }
                })
                .catch((err: AxiosError) => {
                    console.log("J");
                    console.log("loading: exception of GET /tracked-indicator-groups/simple/organization");
                    setState({...state, name: "failed"});
                });
        }
    }, [keycloak, initialized, state, setState, organizationId]);
    //== Handlers ===============================================================
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number): void => {
        setState({
            ...state,
            page: {...state.page, number: value - 1},
            name: "loading",
            action: "init",
        });
    };
    const handleAdd = () => {
        setState({...state, action: "add"});
    };
    //== Render =================================================================
    console.log("TrackedIndicatorGroups - permissions:");
    console.log(state.permissions);
    if (state.name === "loading") {
        return <Loading />;
    }
    if (state.name === "failed") {
        return <ServerCommunicationAlert />;
    }
    if (state.name === "added") {
        return (
            <Redirect
                to={ROUTES.trackedIndicatorsEditor.create(
                    organizationId,
                    state.trackedIndicatorGroups[state.trackedIndicatorGroups.length - 1].publicId
                )}
            />
        );
    }
    // - default
    let notification: JSX.Element | null = null;
    if (state.name === "loaded" && state.action === "refresh") {
        notification = <InfoNotification message={t("trackedIndicatorGroupsNotifications.refresh_ok")} />;
    }
    console.log(state.trackedIndicatorGroups);
    return (
        <Container>
            <ManualSidebar
                section={
                    state.permissions.trackedIndicatorsPermissionLevel === "NONE"
                        ? csPublicIndicatorsSection
                        : csMunicipalityTrackedIndicatorGroupsSection
                }
            />
            <BreadcrumbsRow>
                <BreadcrumbItem
                    name={t("organization:organization.municipality")}
                    route={ROUTES.organization.create(organizationId)}
                />
                <BreadcrumbItem
                    name={t("trackedIndicatorGroup.trackedIndicatorGroups")}
                    route={ROUTES.trackedIndicatorGroups.create(organizationId)}
                />
            </BreadcrumbsRow>
            <PageHeader title={t("trackedIndicatorGroup.trackedIndicatorGroups")}>
                {state.permissions.trackedIndicatorsPermissionLevel === "READWRITE" ? (
                    <PrimaryPageAction title={t("actions.addGroup")} onClick={handleAdd}>
                        <AddIcon />
                    </PrimaryPageAction>
                ) : null}
            </PageHeader>
            <Container>
                <Box>
                    <TrackedIndicatorGroupsDeck
                        trackedIndicatorGroups={state.trackedIndicatorGroups}
                        permissionlevel={state.permissions.trackedIndicatorsPermissionLevel || "NONE"}
                        organizationId={organizationId}
                    />
                    {state.page.totalPages > 1 && (
                        <Pagination
                            style={{marginTop: "1em"}}
                            count={state.page.totalPages}
                            page={state.page.number + 1}
                            onChange={handlePageChange}
                        />
                    )}
                    {notification}
                </Box>
            </Container>
        </Container>
    );
};

export default TrackedIndicatorGroups;
