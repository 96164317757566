import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {LibraryDomainUseDTO, LibraryDTO, LibraryGoalUseDTO} from "../../../../models/library";
import React from "react";
import {useTranslation} from "react-i18next";
import {API, createApiConfig} from "../../../../utils/API";
import {Box} from "@material-ui/core";
import {DeleteActionButton, InfoActionButton, SwapVerticallyActionButton} from "../../../common/buttons";
import {LoadingBackdrop} from "../../../common/Loading";
import {useKeycloak} from "@react-keycloak/web";
import {ConfirmationDialog, InformationDialog} from "../../../common/dialogs";
import {AxiosError} from "axios";
import {GoalDialog} from "../../../values/dialogs/GoalDialog";
import ItemPositionInLibrary, {GoalPositionInLibrary} from "../util/LibraryItemPosition";
import {ErrorDeleteDTO, rectifyErrorDelete} from "../../../../models/error";
import {LibraryGoalRenderDelete} from "./delete/LibraryGoalRenderDelete";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        goalRow: {
            borderWidth: 0,
            borderStyle: "solid",
            borderBottomWidth: 1,
            borderColor: "rgb(0,125,180)",
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(0.5),
            paddingLeft: theme.spacing(1),
            marginLeft: theme.spacing(2),
            display: "flex",
            alignItems: "center",
        },
        dragHandle: {
            marginRight: theme.spacing(0.5),
            display: "flex",
            cursor: "move",
        },
        goalItemName: {
            marginLeft: theme.spacing(0.5),
            fontSize: "140%",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
        },
        infoButton: {
            flexGrow: 1,
        },
    })
);

interface LibraryGoalUseRowProps {
    library: LibraryDTO;
    libraryDomainUse: LibraryDomainUseDTO;
    libraryGoalUse: LibraryGoalUseDTO;
    onUpdate: (library: LibraryDTO) => void;
    shiftingItemPosition: ItemPositionInLibrary | null;
    handleShiftStart: (itemPosition: GoalPositionInLibrary) => void;
}

interface LibraryGoalUseRowState {
    name: "info" | "init" | "edit" | "saving" | "delete_confirm" | "delete_inform" | "deleting" | "failed" | "deleted";
    error: ErrorDeleteDTO | null;
}

export const LibraryGoalUseRow: React.FC<LibraryGoalUseRowProps> = (props: LibraryGoalUseRowProps) => {
    //== Init =================================================================
    const classes = useStyles();
    const [t] = useTranslation("libraries");
    const {keycloak, initialized} = useKeycloak();
    const initState: LibraryGoalUseRowState = {
        name: "init",
        error: null,
    };
    const [state, setState] = React.useState(initState);
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "deleting") {
            API.delete<LibraryDTO>(
                `/goals/library-use/${props.libraryGoalUse.id}`,
                createApiConfig(keycloak, initialized)
            )
                .then((res) => {
                    props.onUpdate(res.data);
                    //setState({...state, name: "deleted"});
                })
                .catch((err: AxiosError) => {
                    if (err.response?.status === 400) {
                        const error: ErrorDeleteDTO = rectifyErrorDelete(err.response.data);
                        setState({...state, name: "delete_inform", error: error});
                    } else {
                        setState({...state, name: "failed"});
                    }
                });
        }
    }, [state, setState, props]);
    //== Handlers =============================================================
    const handleInfoOpen = () => {
        setState({...state, name: "info"});
    };
    const handleDelete = () => {
        setState({...state, name: "delete_confirm"});
    };
    const handleDeleteConfirm = () => {
        setState({...state, name: "deleting"});
    };
    const handleReset = () => {
        setState({...state, name: "init"});
    };
    const isShiftingOtherThanThisOne =
        props.shiftingItemPosition &&
        (props.shiftingItemPosition.domainIndex !== props.libraryDomainUse.orderNumber ||
            props.shiftingItemPosition.goalIndex !== props.libraryGoalUse.orderNumber ||
            props.shiftingItemPosition.indicatorIndex != null);
    //== Render ===============================================================
    return (
        <Box className={classes.goalRow}>
            <div className={classes.dragHandle} style={isShiftingOtherThanThisOne ? {visibility: "hidden"} : {}}>
                <SwapVerticallyActionButton
                    onClick={() =>
                        props.handleShiftStart({
                            domainIndex: props.libraryDomainUse.orderNumber,
                            goalIndex: props.libraryGoalUse.orderNumber,
                            indicatorIndex: null,
                        })
                    }
                />
            </div>
            <Box className={classes.goalItemName}>{props.libraryGoalUse.goal.name}</Box>
            <Box className={classes.infoButton}>
                <InfoActionButton onClick={handleInfoOpen} />
            </Box>
            <Box>
                <DeleteActionButton onClick={handleDelete} />
            </Box>
            <GoalDialog goal={props.libraryGoalUse.goal} handleClose={handleReset} open={state.name === "info"} />
            <ConfirmationDialog
                title={t("goal.confirm_delete_use.title")}
                text={t("goal.confirm_delete_use.text", {title: props.libraryGoalUse.goal.name})}
                open={state.name === "delete_confirm"}
                onAgree={handleDeleteConfirm}
                onDisagree={handleReset}
                id={"confirm-delete-dialog-" + props.libraryGoalUse.id}
            />
            {state.error !== null && (
                <InformationDialog
                    id={`delete-inform-dialog-${props.libraryGoalUse.id}`}
                    title={t(`goal.confirm_delete.inform.title`)}
                    text={t(`goal.confirm_delete.inform.text`, {
                        title: props.libraryGoalUse.goal.name ?? "",
                        count: state.error?.relatedEntities.length ?? 0,
                    })}
                    open={state.name === "delete_inform"}
                    onClose={handleReset}
                >
                    <LibraryGoalRenderDelete
                        entity={{name: props.libraryGoalUse.goal.name, id: props.libraryGoalUse.id}}
                        error={state.error}
                    />
                </InformationDialog>
            )}
            {state.name.endsWith("ing") && <LoadingBackdrop />}
        </Box>
    );
};
