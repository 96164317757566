import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {ValuesInput} from "../../../values/ValuesInput";
import {TrackedIndicatorInGroupDTO} from "../../../../models/trackedIndicators";
import {useTranslation} from "react-i18next";
import KeyboardIcon from "@material-ui/icons/Keyboard";
import {CustomActionButton} from "../../../common/buttons";

interface IndicatorValuesInputProps {
    organizationId: string;
    trackedIndicator: TrackedIndicatorInGroupDTO;

    onClose?(): void;
}

export const IndicatorValuesInput: React.FC<IndicatorValuesInputProps> = (props: IndicatorValuesInputProps) => {
    //== Init =================================================================
    const {organizationId, trackedIndicator} = props;
    const [t] = useTranslation("values");
    const [open, setOpen] = React.useState(false);

    //== Handlers =============================================================

    const handleOpen = (): void => {
        setOpen(true);
    };
    const handleClose = (): void => {
        setOpen(false);
        props.onClose?.();
    };

    //== Render ===============================================================
    return (
        <>
            {open ? null : (
                // <Button startIcon={<KeyboardIcon />} variant="outlined" color="primary" onClick={handleOpen}>
                //     {t("values_input.valuesInput")}
                // </Button>
                <CustomActionButton
                    title={t("values_input.valuesInput")}
                    icon={<KeyboardIcon />}
                    onClick={handleOpen}
                />
            )}
            <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="lg">
                <DialogTitle>
                    {t("values_input.indicatorValuesInput", {indicator: trackedIndicator.indicator.name})}
                </DialogTitle>
                <DialogContent>
                    <ValuesInput
                        organizationId={organizationId}
                        taskUrl={`/values-input/task/tracked-indicator/${trackedIndicator.id}`}
                        simpleLoadingIndicator={true}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={handleClose}>
                        {t("values_input.dialog.close")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default IndicatorValuesInput;
