import React from "react";
import {Box, Checkbox, FormControlLabel} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {InfoActionButton} from "../../../../common/buttons";
import {IndicatorDialog} from "../../../../values/dialogs/IndicatorDialog";
import {DashboardIndicatorsAddingIndicator} from "./DashboardIndicatorsAddingInterfaces";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import {TrackedIndicatorInGroupDTO} from "../../../../../models/trackedIndicators";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        row: {
            borderWidth: 0,
            borderStyle: "solid",
            borderBottomWidth: 1,
            borderColor: "rgb(224,224,224)",
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(0.5),
            marginLeft: theme.spacing(6),
            paddingLeft: theme.spacing(1),
            display: "flex",
        },
        alreadyAdded: {
            backgroundColor: "rgb(215,250,215)",
        },
        selectionCheckbox: {},
        itemName: {
            fontSize: "110%",
            marginTop: theme.spacing(1.5),
        },
        infoButton: {
            flexGrow: 1,
        },
    })
);

interface DashboardIndicatorsIndicatorAddProps {
    index: number; //TODO temporary solution until libraryXXXUse are not added to LibraryDTO,
    trackedIndicator: DashboardIndicatorsAddingIndicator;

    handleChecked(trackedIndicator: TrackedIndicatorInGroupDTO): void;

    handleUnchecked(trackedIndicator: TrackedIndicatorInGroupDTO): void;
}

const DashboardIndicatorsIndicatorAdd: React.FC<DashboardIndicatorsIndicatorAddProps> = (
    props: DashboardIndicatorsIndicatorAddProps
) => {
    const classes = useStyles();
    const [t] = useTranslation("trackedIndicators");
    const [state, setState] = React.useState({infoOpen: false, checked: props.trackedIndicator.alreadyAdded});
    //== Handlers ===============================================================
    const handleInfoOpen = (): void => {
        setState({...state, infoOpen: true});
    };
    const handleInfoClose = (): void => {
        setState({...state, infoOpen: false});
    };
    const handleCheckChanged = (checked: boolean) => {
        console.log("[DashboardIndicatorsIndicatorAdd] handleCheckChanged - checked:" + checked);
        checked ? props.handleChecked(props.trackedIndicator) : props.handleUnchecked(props.trackedIndicator);
        setState({...state, checked: props.trackedIndicator.alreadyAdded || checked});
    };
    console.log("[DashboardIndicatorsIndicatorAdd] render - state.checked:" + state.checked);
    return (
        <Box>
            <Box
                className={classNames({
                    [classes.row]: true,
                    [classes.alreadyAdded]: props.trackedIndicator.alreadyAdded,
                })}
            >
                <Box className={classes.selectionCheckbox}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={props.trackedIndicator.alreadyAdded || state.checked}
                                disabled={props.trackedIndicator.alreadyAdded}
                                onChange={(e): void => handleCheckChanged(e.target.checked)}
                                name="selected"
                            />
                        }
                        label=""
                    />
                </Box>
                <Box className={classes.itemName}>
                    {t("library.indicator") + ": " + props.trackedIndicator.indicator.name}
                </Box>
                <Box className={classes.infoButton}>
                    <InfoActionButton onClick={handleInfoOpen} />
                </Box>
            </Box>
            <IndicatorDialog
                indicator={props.trackedIndicator.indicator}
                handleClose={handleInfoClose}
                open={state.infoOpen}
            />
        </Box>
    );
};

export default DashboardIndicatorsIndicatorAdd;
