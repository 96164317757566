import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {DomainCreateDTO, LibraryDomainUseDTO, LibraryDTO} from "../../../../models/library";
import React from "react";
import {useTranslation} from "react-i18next";
import {API, createApiConfig} from "../../../../utils/API";
import {Box, Button} from "@material-ui/core";
import {
    DeleteActionButton,
    EditActionButton,
    InfoActionButton,
    SwapVerticallyActionButton,
} from "../../../common/buttons";
import {LoadingBackdrop} from "../../../common/Loading";
import {DomainDialog} from "../../../values/dialogs/DomainDialog";
import {useKeycloak} from "@react-keycloak/web";
import {DomainForm} from "../forms/DomainForm";
import {FormDialog} from "../forms/FormDialog";
import {ConfirmationDialog, InformationDialog} from "../../../common/dialogs";
import {AxiosError} from "axios";
import ItemPositionInLibrary, {DomainPositionInLibrary} from "../util/LibraryItemPosition";
import {HomeIndicator} from "./common/HomeIndicator";
import {ErrorDeleteDTO, rectifyErrorDelete} from "../../../../models/error";
import {LibraryDomainRenderDelete} from "./delete/LibraryDomainRenderDelete";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        domainRow: {
            borderWidth: 0,
            borderStyle: "solid",
            borderBottomWidth: 2,
            borderColor: "rgb(0,125,180)",
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(0.5),
            paddingLeft: theme.spacing(1),
            display: "flex",
            alignItems: "center",
        },
        dragHandle: {
            marginRight: theme.spacing(0.5),
            display: "flex",
            cursor: "move",
        },
        domainItemName: {
            marginLeft: theme.spacing(0.5),
            fontSize: "140%",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
        },
        infoButton: {
            flexGrow: 1,
        },
        ownedIcon: {
            marginLeft: theme.spacing(1),
            fontSize: "80%",
        },
    })
);

interface LibraryDomainOwnRowProps {
    library: LibraryDTO;
    libraryDomainUse: LibraryDomainUseDTO;
    onUpdate: (library: LibraryDTO) => void;
    shiftingItemPosition: ItemPositionInLibrary | null;
    handleShiftStart: (itemPosition: DomainPositionInLibrary) => void;
}

interface LibraryDomainOwnRowState {
    name: "info" | "init" | "edit" | "saving" | "delete_confirm" | "delete_inform" | "deleting" | "failed" | "deleted";
    editDomain: DomainCreateDTO;
    error: ErrorDeleteDTO | null;
}

export const LibraryDomainOwnRow: React.FC<LibraryDomainOwnRowProps> = (props: LibraryDomainOwnRowProps) => {
    //== Init =================================================================
    const classes = useStyles();
    const [t] = useTranslation("libraries");
    const {keycloak, initialized} = useKeycloak();
    const initState: LibraryDomainOwnRowState = {
        name: "init",
        error: null,
        editDomain: {
            name: props.libraryDomainUse.domain.name,
            tags: props.libraryDomainUse.domain.tags,
            description: props.libraryDomainUse.domain.description,
            libraryUUID: props.libraryDomainUse.domain.libraryUUID,
            orderNumber: props.libraryDomainUse.orderNumber,
        },
    };
    const [state, setState] = React.useState(initState);
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "saving") {
            const url = `/domains/in-library/${props.libraryDomainUse.id}`;
            API.put<LibraryDTO>(url, state.editDomain, createApiConfig(keycloak, initialized))
                .then((res) => {
                    props.onUpdate(res.data);
                    setState({...state, name: "init"});
                })
                .catch((err: AxiosError) => {
                    console.log(err);
                    setState({...state, name: "failed"});
                });
        } else if (state.name === "deleting") {
            API.delete<LibraryDTO>(
                `/domains/in-library/${props.libraryDomainUse.id}`,
                createApiConfig(keycloak, initialized)
            )
                .then((res) => {
                    props.onUpdate(res.data);
                    //setState({...state, name: "deleted"});
                })
                .catch((err: AxiosError) => {
                    if (err.response?.status === 400) {
                        const error: ErrorDeleteDTO = rectifyErrorDelete(err.response.data);
                        setState({...state, name: "delete_inform", error: error});
                    } else {
                        setState({...state, name: "failed"});
                    }
                });
        }
    }, [state, setState, props]);
    //== Handlers =============================================================
    const handleInfoOpen = () => {
        setState({...state, name: "info"});
    };
    const handleEditOpen = () => {
        setState({...state, name: "edit"});
    };
    const handleEditChange = (domain: DomainCreateDTO) => {
        setState({...state, editDomain: domain, name: "edit"});
    };
    const handleEditSubmit = () => {
        setState({...state, name: "saving"});
    };
    const handleDelete = () => {
        setState({...state, name: "delete_confirm"});
    };
    const handleDeleteConfirm = () => {
        setState({...state, name: "deleting"});
    };
    const handleReset = () => {
        setState({...state, name: "init"});
    };
    const isShiftingOtherThanThisOne =
        props.shiftingItemPosition &&
        (props.shiftingItemPosition.domainIndex !== props.libraryDomainUse.orderNumber ||
            props.shiftingItemPosition.goalIndex !== null);
    const libraryPublished = props.library.public || props.library.shared;
    //== Render ===============================================================
    return (
        <Box className={classes.domainRow}>
            <div className={classes.dragHandle} style={isShiftingOtherThanThisOne ? {visibility: "hidden"} : {}}>
                <SwapVerticallyActionButton
                    onClick={() =>
                        props.handleShiftStart({
                            domainIndex: props.libraryDomainUse.orderNumber,
                            goalIndex: null,
                            indicatorIndex: null,
                        })
                    }
                />
            </div>
            <Box className={classes.domainItemName}>
                {props.libraryDomainUse.domain.name}
                <HomeIndicator />
            </Box>
            <Box className={classes.infoButton}>
                <InfoActionButton onClick={handleInfoOpen} />
            </Box>
            <Box>
                <EditActionButton onClick={handleEditOpen} />
                {!libraryPublished && <DeleteActionButton onClick={handleDelete} />}
            </Box>
            <DomainDialog
                domain={props.libraryDomainUse.domain}
                handleClose={handleReset}
                open={state.name === "info"}
            />
            <FormDialog
                id={`edit-domain-${props.libraryDomainUse.domain.id}`}
                title={t("domain.edit.name")}
                open={state.name === "edit"}
                onClose={handleReset}
                actions={[
                    <Button key="submit" onClick={handleEditSubmit}>
                        {t("domain.edit.button")}
                    </Button>,
                ]}
            >
                <DomainForm domain={state.editDomain} published={libraryPublished} onChange={handleEditChange} />
            </FormDialog>
            <ConfirmationDialog
                id={`delete-confirm-dialog-${props.libraryDomainUse.id}`}
                title={t("domain.confirm_delete_own.title")}
                text={t("domain.confirm_delete_own.text", {title: props.libraryDomainUse.domain.name})}
                open={state.name === "delete_confirm"}
                onAgree={handleDeleteConfirm}
                onDisagree={handleReset}
            />
            {state.error !== null && (
                <InformationDialog
                    id={`delete-inform-dialog-${props.libraryDomainUse.id}`}
                    title={t(`domain.confirm_delete.inform.title`)}
                    text={t(`domain.confirm_delete.inform.text`, {
                        title: props.libraryDomainUse.domain.name ?? "",
                        count: state.error?.relatedEntities.length ?? 0,
                    })}
                    open={state.name === "delete_inform"}
                    onClose={handleReset}
                >
                    <LibraryDomainRenderDelete
                        entity={{name: props.libraryDomainUse.domain.name, id: props.libraryDomainUse.id}}
                        error={state.error}
                    />
                </InformationDialog>
            )}
            {state.name.endsWith("ing") && <LoadingBackdrop />}
        </Box>
    );
};
