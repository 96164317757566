import React from "react";
import {useTranslation} from "react-i18next";
// Material UI imports
import {Box, Button, Dialog, DialogContent, DialogTitle, useMediaQuery, useTheme} from "@material-ui/core";
// Project imports
import {TrackedIndicatorGroupEditorAddDialogProps} from "./TrackedIndicatorGroupEditorAddProps";
import TrackedIndicatorGroupEditorAddFromLibrariesDialog from "./TrackedIndicatorGroupEditorAddFromLibrariesDialog";
import ROUTES from "../../../../../routes/routes";
import {DomainWithContent} from "./TrackedIndicatorGroupEditorAddingInterfaces";

const TrackedIndicatorGroupEditorAddDialog: React.FC<TrackedIndicatorGroupEditorAddDialogProps> = (
    props: TrackedIndicatorGroupEditorAddDialogProps
) => {
    // TODO: allow to go back to "root" state?
    //== Init =================================================================
    const theme = useTheme();
    const [t] = useTranslation(["trackedIndicators", "common"]);
    const initState = "root" as "root" | "libraries" | "own" | "create";
    const [state, setState] = React.useState(initState);

    const handleCancel = () => {
        props.onCancel();
        setState("root");
    };
    const handleAdd = (domains: DomainWithContent[]) => {
        props.onAdd(domains);
        setState("root");
    };

    //== Render ===============================================================
    // - default
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
    if (state === "libraries") {
        return (
            <TrackedIndicatorGroupEditorAddFromLibrariesDialog
                {...props}
                onAdd={handleAdd}
                onCancel={handleCancel}
                type="public"
            />
        );
    }
    if (state === "own") {
        return (
            <TrackedIndicatorGroupEditorAddFromLibrariesDialog
                {...props}
                onAdd={handleAdd}
                onCancel={handleCancel}
                type="owned"
            />
        );
    }
    if (state === "create") {
        window.open(ROUTES.organizationLibraries.createForMun(props.trackedIndicatorGroup.organization.publicId));
        handleCancel();
    }
    // state is root
    return (
        <Dialog
            fullScreen={fullScreen}
            open={props.open}
            onClose={handleCancel}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{t("ui.addingIndicators.init")}</DialogTitle>
            <DialogContent>
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setState("libraries")}
                        style={{margin: "0.5em", width: "100%"}}
                    >
                        {t("ui.fromLibraries")}
                    </Button>
                </Box>
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setState("own")}
                        style={{margin: "0.5em", width: "100%"}}
                    >
                        {t("ui.fromOwn")}
                    </Button>
                </Box>
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setState("create")}
                        style={{margin: "0.5em", width: "100%"}}
                    >
                        {t("ui.createNew")}
                    </Button>
                </Box>
                <Box>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => props.onAdd([])}
                        style={{margin: "0.5em", width: "100%"}}
                    >
                        {t("common:actions.back")}
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default TrackedIndicatorGroupEditorAddDialog;
