import React from "react";
import {useTranslation} from "react-i18next";
// Material UI imports
import {Box, Button, FormControl, TextField} from "@material-ui/core";
// Project imports
import {InputVariableCreateDTO, UnitSimpleDTO, VariableType} from "../../../models/library";
import {useStyles} from "../../../styles";
import {ValueSimpleForm} from "../../common/values";
import {UnitInput} from "../../common/libraries/units";
import {MarkdownTextField} from "../../common/markdown";
import {Alert} from "@material-ui/lab";
import {VariableTypeInput} from "../../common/variables";
import {TagsInput} from "../../common/tags";
import {TagDTO} from "../../../models/admin";
import {valueToUpdate} from "../../../models/values";

interface InputVariableFormProps {
    inputVariable: InputVariableCreateDTO;
    qualitativeInfo: string;

    onChange(inputVariable: InputVariableCreateDTO): void;

    onSubmit(): void;
}

const InputVariableForm: React.FC<InputVariableFormProps> = (props: InputVariableFormProps) => {
    //== Init =================================================================
    const [t] = useTranslation("libraries");
    const classes = useStyles();
    //== Handlers =============================================================
    const handlePreferredUnitChange = (unit: UnitSimpleDTO) => {
        props.onChange({
            ...props.inputVariable,
            preferredUnitUUID: unit.id,
            minimumValueUpdate:
                props.inputVariable.minimumValueUpdate !== null
                    ? {...props.inputVariable.minimumValueUpdate, unitUUID: unit.id}
                    : null,
            maximumValueUpdate:
                props.inputVariable.minimumValueUpdate !== null
                    ? {...props.inputVariable.minimumValueUpdate, unitUUID: unit.id}
                    : null,
        });
    };
    const handleTypeChange = (value: VariableType) => {
        props.onChange({...props.inputVariable, type: value});
    };
    //== Render ===============================================================
    return (
        <FormControl fullWidth>
            <TextField
                label={t("definitions.input_variables.name")}
                value={props.inputVariable.name}
                onChange={(e): void => {
                    props.onChange({...props.inputVariable, name: e.target.value});
                }}
                fullWidth
                required
                variant="outlined"
                className={classes.spaceAfter}
            />
            <MarkdownTextField
                label={t("definitions.input_variables.description")}
                value={props.inputVariable.description}
                onChange={(value): void => {
                    props.onChange({...props.inputVariable, description: value});
                }}
                margin="normal"
            />
            <MarkdownTextField
                label={t("definitions.input_variables.definition")}
                value={props.inputVariable.definition}
                onChange={(value): void => {
                    props.onChange({...props.inputVariable, definition: value});
                }}
                margin="normal"
            />
            <VariableTypeInput value={props.inputVariable.type} onChange={handleTypeChange} />
            {props.inputVariable.type === "quantitative" && (
                <>
                    <UnitInput
                        query="all"
                        label={t("definitions.input_variables.preferred_unit")}
                        helperText={t("definitions.input_variables.unit_helper")}
                        valueUuid={props.inputVariable.preferredUnitUUID}
                        onChange={handlePreferredUnitChange}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                    />
                    <ValueSimpleForm
                        label={t("definitions.input_variables.minimum_value")}
                        fullWidth
                        variant="outlined"
                        className={classes.spaceAfter}
                        present={props.inputVariable.minimumValueUpdate !== null}
                        value={props.inputVariable.minimumValueUpdate?.value ?? 0}
                        unitUUID={
                            props.inputVariable.minimumValueUpdate?.unitUUID ?? props.inputVariable.preferredUnitUUID
                        }
                        onChange={(value) =>
                            props.onChange({
                                ...props.inputVariable,
                                minimumValueUpdate: valueToUpdate(value),
                            })
                        }
                    />
                    <ValueSimpleForm
                        label={t("definitions.input_variables.maximum_value")}
                        fullWidth
                        variant="outlined"
                        className={classes.spaceAfter}
                        present={props.inputVariable.maximumValueUpdate !== null}
                        value={props.inputVariable.maximumValueUpdate?.value ?? 0}
                        unitUUID={
                            props.inputVariable.maximumValueUpdate?.unitUUID ?? props.inputVariable.preferredUnitUUID
                        }
                        onChange={(value) =>
                            props.onChange({
                                ...props.inputVariable,
                                maximumValueUpdate: valueToUpdate(value),
                            })
                        }
                    />
                </>
            )}
            <TagsInput
                label={t("definitions.input_variables.tags")}
                value={props.inputVariable.tags}
                onChange={(tags: TagDTO[]): void => {
                    props.onChange({...props.inputVariable, tags: tags});
                }}
                fullWidth
                variant="outlined"
                className={classes.spaceAfter}
            />
            {props.inputVariable.type === "qualitative" && (
                <Box padding="1em">
                    <Alert severity="info">{props.qualitativeInfo}</Alert>
                </Box>
            )}
            <Button onClick={props.onSubmit} variant="contained" color="primary">
                {t("admin.save")}
            </Button>
        </FormControl>
    );
};

export default InputVariableForm;
