import React from "react";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import {DropzoneDialog} from "material-ui-dropzone";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";
import classNames from "classnames";
import {API, createApiConfig} from "../../utils";
import {AxiosError, AxiosRequestConfig} from "axios";
import {useKeycloak} from "@react-keycloak/web";
import Loading from "./Loading";

const useImageUploadStyles = makeStyles((theme: Theme) =>
    createStyles({
        editButton: {
            transition: ".5s ease",
            opacity: 0,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            msTransform: "translate(-50%, -50%)",
        },
        image: {
            transition: ".5s ease",
            opacity: 1,
        },
        wrapper: {
            position: "relative",
        },
        editableWrapper: {
            position: "relative",
            "&:hover": {
                "& img": {
                    opacity: 0.6,
                },
                "& button": {
                    opacity: 1,
                },
            },
        },
        loader: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
    })
);

interface ImageUploadDialogProps {
    open: boolean;

    onConfirm(file: File): void;

    onCancel(): void;
}

export const ImageUploadDialog: React.FC<ImageUploadDialogProps> = (props: ImageUploadDialogProps) => {
    //== Init =================================================================
    const [t] = useTranslation("common");
    //== Render ===============================================================
    return (
        <DropzoneDialog
            acceptedFiles={["image/jpg", "image/jpeg", "image/png"]}
            cancelButtonText={t("actions.cancel")}
            submitButtonText={t("actions.accept")}
            open={props.open}
            maxFileSize={2097152}
            onClose={() => props.onCancel()}
            onSave={(files) => {
                files.length === 0 ? props.onCancel() : props.onConfirm(files[0]);
            }}
            filesLimit={1}
            showPreviews={true}
            showFileNamesInPreview={true}
            initialFiles={[]}
            dialogTitle={t("imageUpload.imageUpload")}
            dropzoneText={t("imageUpload.dragAndDropOrClickHere")}
            previewText={t("imageUpload.preview")}
            getFileLimitExceedMessage={(filesLimit) =>
                t("imageUpload.getFileLimitExceedMessage", {filesLimit: filesLimit})
            }
            getFileAddedMessage={(fileName) => t("imageUpload.getFileAddedMessage", {fileName: fileName})}
            getFileRemovedMessage={(fileName) => t("imageUpload.getFileRemovedMessage", {fileName: fileName})}
            getDropRejectMessage={(rejectedFile) =>
                t("imageUpload.getDropRejectMessage", {rejectedFileName: rejectedFile.name})
            }
        />
    );
};

interface UploadableImageProps {
    src: string | null;
    wrapperClass?: string;
    imageClass?: string;
    buttonClass?: string;
    editable: boolean;
    alt: string;
    editButtonText?: string;
    emptyImage: string;

    onConfirm(file: File): void;
}

interface UploadableImageState {
    name: "closed" | "open" | "saving" | "loading";
    data: Blob | null;
}

export const UploadableImage: React.FC<UploadableImageProps> = (props: UploadableImageProps) => {
    //== Init =================================================================
    const classes = useImageUploadStyles();
    const [t] = useTranslation("common");
    const initState: UploadableImageState = {
        name: props.src === null ? "closed" : "loading",
        data: null,
    };
    const [state, setState] = React.useState(initState);
    const {keycloak, initialized} = useKeycloak();
    //== Effect ===============================================================
    React.useEffect(() => {
        if (state.name === "loading" && props.src !== null) {
            const config: AxiosRequestConfig = {
                ...createApiConfig(keycloak, initialized),
                responseType: "blob",
            };
            API.get<Blob>(props.src, config)
                .then((res) => {
                    console.log(res);
                    setState({
                        ...state,
                        data: res.data,
                        name: "closed",
                    });
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "closed"});
                });
        }
    });
    //== Render ===============================================================
    if (state.name === "loading") {
        return (
            <Box className={classNames(classes.wrapper, props.wrapperClass)}>
                <Box className={classNames(classes.loader, props.imageClass)}>
                    <Loading />
                </Box>
            </Box>
        );
    }
    const image = state.data === null ? props.emptyImage : URL.createObjectURL(state.data);
    return (
        <Box className={classNames(props.editable ? classes.editableWrapper : classes.wrapper, props.wrapperClass)}>
            <img className={classNames(classes.image, props.imageClass)} alt={props.alt} src={image} />
            {props.editable ? (
                <>
                    <Button
                        className={classNames(classes.editButton, props.buttonClass)}
                        variant="contained"
                        color="primary"
                        onClick={() => setState({...state, name: "open"})}
                    >
                        {props.editButtonText || t("actions.edit")}
                    </Button>
                    <ImageUploadDialog
                        open={state.name === "open"}
                        onConfirm={(file: File) => {
                            setState({...state, name: "closed"});
                            props.onConfirm(file);
                        }}
                        onCancel={() => setState({...state, name: "closed"})}
                    />
                </>
            ) : null}
        </Box>
    );
};

UploadableImage.defaultProps = {
    editable: true,
    wrapperClass: "",
    imageClass: "",
    buttonClass: "",
};

const useCenteredImageStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            display: "block",
            overflow: "hidden",
            position: "relative",
            textAlign: "center",
            width: "100%",
            height: "100%",
        },
        image: {
            bottom: 0,
            left: 0,
            margin: "auto",
            maxHeight: "100%",
            maxWidth: "100%",
            position: "absolute",
            right: 0,
            top: 0,
        },
    })
);

interface CenteredImageProps
    extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
    src: string;
    alt: string;
}

export const CenteredImage: React.FC<CenteredImageProps> = (props: CenteredImageProps) => {
    //== Init =================================================================
    const classes = useCenteredImageStyles();
    //== Render ===============================================================
    return (
        <div className={classes.wrapper}>
            <img {...props} className={classes.image} alt={props.alt} />
        </div>
    );
};
