import {PersonDTO, ProfileDTO} from "./user";
import {TrackedDomainSimpleDTO} from "./trackedIndicators";
import {OrganizationDTO} from "./organization";

export interface MembershipDTO {
    id: string;
    createdAt: string;
    positions: MembershipPositionPublicDTO[];
    person: PersonDTO;
    isOwn: boolean;
}

export interface MembershipUpdateDTO {
    positionIds: string[];
}

export interface MembershipCreateDTO extends MembershipUpdateDTO {
    email: string;
}

interface MembershipInvitationBaseDTO {
    id: string;
    publicId: string;
    positions: MembershipPositionPublicDTO[];
}

export interface MembershipInvitationSimpleDTO extends MembershipInvitationBaseDTO {
    email: string;
}

export interface MembershipInvitationReceiverDTO extends MembershipInvitationBaseDTO {
    message: string | null;
    inviter: PersonDTO;
    organization: OrganizationDTO;
}

export interface MembershipInvitationDTO extends MembershipInvitationReceiverDTO {
    email: string;
    message: string | null;
}

export interface MembershipInvitationUpdateDTO {
    message: string | null;
    positionIds: string[];
}

export interface MembershipInvitationCreateDTO {
    email: string;
    message: string | null;
    positionIds: string[];
}

export interface MembershipInvitationAdminDTO extends MembershipInvitationDTO {
    person: PersonDTO;
}

export interface FullMembershipRequestUpdateDTO {
    positionName: string | null;
}

export interface FullMembershipRequestCreateDTO extends FullMembershipRequestUpdateDTO {
    municipalityId: string;
}

export interface FullMembershipRequestDTO extends FullMembershipRequestUpdateDTO {
    id: string;
    publicId: string;
    createdAt: string;
    organization: OrganizationDTO;
    profile: ProfileDTO;
}

export interface FullMembershipRequestAcceptDTO {
    positionName: string;
}

export type ProfileMembershipPermissionLevel = "READONLY" | "READWRITE" | "FULL";
export type MembershipPermissionLevel = "NONE" | "READONLY" | "READWRITE";

interface OrganizationPermissionsBaseDTO {
    profilePermissionLevel: ProfileMembershipPermissionLevel | null;
    librariesPermissionLevel: MembershipPermissionLevel | null;
    trackedIndicatorsPermissionLevel: MembershipPermissionLevel | null;
    valuesPermissionLevel: MembershipPermissionLevel | null;
    valueReadWriteTrackedDomains: TrackedDomainSimpleDTO[];
}

export interface MembershipPositionUpdateDTO extends OrganizationPermissionsBaseDTO {
    description: string | null;
    name: string;
    profilePermissionLevel: ProfileMembershipPermissionLevel;
    librariesPermissionLevel: MembershipPermissionLevel;
}

export interface MembershipPositionCreateDTO extends MembershipPositionUpdateDTO {
    organizationId: string;
}

export interface MembershipPositionPublicDTO extends MembershipPositionUpdateDTO {
    id: string;
}

export interface MembershipPositionSimpleDTO {
    id: string;
    name: string;
    profilePermissionLevel: ProfileMembershipPermissionLevel;
}

export interface MembershipPositionMaintainerDTO extends MembershipPositionPublicDTO {
    memberships: MembershipDTO[];
    invitations: MembershipInvitationSimpleDTO[];
}

export interface OrganizationPermissionsDTO extends OrganizationPermissionsBaseDTO {
    organizationId: string;
    personId: string | null;
}

export const anonymousOrganizationPermissions = (organizationid: string): OrganizationPermissionsDTO => {
    return {
        personId: null,
        organizationId: organizationid,
        profilePermissionLevel: "READONLY",
        librariesPermissionLevel: "NONE",
        trackedIndicatorsPermissionLevel: "NONE",
        valuesPermissionLevel: "NONE",
        valueReadWriteTrackedDomains: [],
    };
};

export const adminOrganizationPermissions = (organizationid: string): OrganizationPermissionsDTO => {
    return {
        personId: null,
        organizationId: organizationid,
        profilePermissionLevel: "FULL",
        librariesPermissionLevel: "READWRITE",
        trackedIndicatorsPermissionLevel: "READWRITE",
        valuesPermissionLevel: "READWRITE",
        valueReadWriteTrackedDomains: [],
    };
};
