import React from "react";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
// Material-UI imports
import {Box, Button, Container, Table, TableBody, TableCell, TableHead, TableRow, TextField} from "@material-ui/core";
// Project imports
import {DeleteActionButton, EditActionButton, RefreshActionButton} from "../common/buttons";
import {ErrorNotification, SuccessNotification} from "../common/notifications";
import {Loading} from "../common/Loading";
import {ServerCommunicationAlert} from "../common/errors";
import API, {createApiConfig} from "../../utils/API";
import {TagCategoryDTO, TagCreateDTO, TagDTO} from "../../models/admin";
import {compareTags, Tag, TagCategoryInput} from "../common/tags";
import {useForm} from "react-hook-form";
import {useStyles} from "../../styles";
import {AxiosError} from "axios";
import {TagDelete} from "./TagDelete";

interface TagRowProps {
    tag: TagDTO;

    onEdit: (tag: TagDTO) => void;
    onDelete: (tag: TagDTO) => void;
}

const TagRow: React.FC<TagRowProps> = (props: TagRowProps) => {
    //== Handlers ===============================================================
    const handleEdit = (): void => {
        props.onEdit(props.tag);
    };
    const handleDelete = (): void => {
        props.onDelete(props.tag);
    };
    //== Render =================================================================
    return (
        <TableRow>
            <TableCell align="center">
                <Tag tag={props.tag} />
            </TableCell>
            <TableCell align="left">{props.tag.description}</TableCell>
            <TableCell align="left">{props.tag?.category?.name}</TableCell>
            <TableCell align="right">
                <EditActionButton onClick={handleEdit} />
                <DeleteActionButton onClick={handleDelete} />
            </TableCell>
        </TableRow>
    );
};

interface TagFormProps {
    tag: TagCreateDTO;

    onSubmit: (tag: TagCreateDTO) => void;
    onCancel?: () => void;
}

const TagForm: React.FC<TagFormProps> = (props: TagFormProps) => {
    //== Init =================================================================
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<TagCreateDTO>();
    const [t] = useTranslation("common");
    const classes = useStyles();
    const [state, setState] = React.useState(props.tag);
    //== Handlers =============================================================
    const handleXSubmit = () => {
        props.onSubmit(state);
    };
    //== Render ===============================================================
    const nameRegister = register("name", {
        required: {value: true, message: t("validation.required")},
        maxLength: {
            value: 250,
            message: t("validation.maxLength", {name: t("tags.categories.name"), value: "250"}),
        },
    });
    const descriptionRegister = register("description");
    return (
        <form onSubmit={handleSubmit(handleXSubmit)} noValidate>
            <TextField
                {...nameRegister}
                label={t("tags.categories." + nameRegister.name)}
                value={state.name}
                inputRef={nameRegister.ref}
                fullWidth
                required
                variant="outlined"
                className={classes.spaceAfter}
                onChange={(e): void => setState({...state, name: e.target.value})}
                error={errors.name !== undefined}
                helperText={errors.name && errors.name.message}
            />
            <TextField
                {...descriptionRegister}
                label={t("tags.categories." + descriptionRegister.name)}
                value={state.description}
                inputRef={descriptionRegister.ref}
                fullWidth
                multiline
                variant="outlined"
                className={classes.spaceAfter}
                onChange={(e): void => setState({...state, description: e.target.value})}
                error={errors.description !== undefined}
                helperText={errors.description && errors.description.message}
            />
            <TagCategoryInput
                label={t("tags.category")}
                categoryUUID={state.categoryUUID}
                fullWidth
                variant="outlined"
                className={classes.spaceAfter}
                onChange={(e): void => setState({...state, categoryUUID: e.id})}
            />
            <Button color="primary" variant="contained" type="submit" className={classes.spaceAround}>
                {t("tags.categories.save")}
            </Button>
            {props.onCancel && (
                <Button
                    color="secondary"
                    variant="contained"
                    type="button"
                    className={classes.spaceAround}
                    onClick={props.onCancel}
                >
                    {t("tags.categories.cancel")}
                </Button>
            )}
        </form>
    );
};

interface TagsState {
    tags: TagDTO[];
    inForm: TagCreateDTO;
    active: TagDTO | null;
    name: "editing" | "done" | "failed" | "loading" | "creating" | "saving" | "deleting";
    notification: string | null;
    newId: number;
    notificationId: string;
    categoryFilter: string;
}

export const Tags: React.FC = () => {
    //== Init ===================================================================
    const [t] = useTranslation("common");
    const {keycloak, initialized} = useKeycloak();
    const formRef = React.useRef<HTMLDivElement>(null);
    const emptyTag: TagCreateDTO = {
        name: "",
        description: "",
        categoryUUID: null,
    };
    const initState: TagsState = {
        tags: [],
        inForm: emptyTag,
        active: null,
        name: "loading",
        notification: null,
        newId: 1,
        notificationId: "none",
        categoryFilter: "all",
    };
    const [state, setState] = React.useState(initState);
    //== Effects ================================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<TagDTO[]>(`/tags/list/${state.categoryFilter}`, createApiConfig(keycloak, initialized))
                .then((res) => {
                    const tags: TagDTO[] = res.data;
                    setState({
                        ...state,
                        tags: tags.sort(compareTags),
                        name: "done",
                    });
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
        if (state.name === "creating") {
            API.post<TagDTO>(`/tags`, state.inForm, createApiConfig(keycloak, initialized))
                .then((res) => {
                    setState({
                        ...state,
                        inForm: emptyTag,
                        newId: state.newId + 1,
                        name: "loading",
                        notification: "create_ok",
                        notificationId: new Date().toISOString(),
                    });
                })
                .catch((err: AxiosError) => {
                    setState({
                        ...state,
                        name: "failed",
                        notification: "create_fail",
                        notificationId: new Date().toISOString(),
                    });
                });
        }
        if (state.name === "saving" && state.active !== null) {
            API.put<TagDTO>(`/tags/${state.active.id}`, state.inForm, createApiConfig(keycloak, initialized))
                .then((res) => {
                    setState({
                        ...state,
                        name: "loading",
                        inForm: emptyTag,
                        newId: state.newId + 1,
                        notification: "edit_ok",
                        notificationId: new Date().toISOString(),
                    });
                })
                .catch((err: AxiosError) => {
                    setState({
                        ...state,
                        name: "failed",
                        notification: "edit_fail",
                        notificationId: new Date().toISOString(),
                    });
                });
        }
    }, [keycloak, initialized, state, setState, emptyTag]);
    //== Handlers ===============================================================
    const handleCreateSubmit = (tag: TagCreateDTO) => {
        setState({...state, inForm: tag, name: "creating"});
    };
    const handleEditSubmit = (tag: TagCreateDTO) => {
        console.log(tag);
        setState({...state, inForm: tag, name: "saving"});
    };
    const handleEditCancel = (): void => {
        setState({...state, inForm: emptyTag, newId: state.newId + 1, name: "done"});
    };
    const handleEdit = (tag: TagDTO) => {
        const form: TagCreateDTO = {
            name: tag.name,
            description: tag.description,
            categoryUUID: tag.category?.id ?? null,
        };
        setState({...state, active: tag, inForm: form, name: "editing"});
    };
    const handleDelete = (tag: TagDTO) => {
        setState({...state, active: tag, name: "deleting"});
    };
    const handleDeleteFailed = (): void => {
        setState({
            ...state,
            name: "failed",
            active: null,
            notification: "delete_fail",
            notificationId: new Date().toISOString(),
        });
    };
    const handleDeleteCancel = (): void => {
        setState({...state, active: null, name: "done"});
    };
    const handleDeleteDone = (): void => {
        setState({
            ...state,
            active: null,
            name: "loading",
            inForm: emptyTag,
            newId: state.newId + 1,
            notification: "delete_ok",
            notificationId: new Date().toISOString(),
        });
    };
    //== Render =================================================================
    if (state.name === "loading") {
        return <Loading />;
    }
    if (state.name === "editing" && formRef && formRef.current) {
        formRef.current.scrollIntoView({behavior: "smooth"});
    }
    let notification = null;
    if (state.notification !== null) {
        const msg = t(`tags.notifications.${state.notification}`);
        if (state.notification.endsWith("fail")) {
            notification = <ErrorNotification key={state.notificationId} message={msg} />;
        } else {
            notification = <SuccessNotification key={state.notificationId} message={msg} />;
        }
    } else if (state.name === "failed") {
        return <ServerCommunicationAlert />;
    }
    const rows = state.tags.map((tag: TagDTO) => (
        <TagRow key={tag.id} tag={tag} onEdit={handleEdit} onDelete={handleDelete} />
    ));
    const allCategory: TagCategoryDTO = {
        id: "all",
        name: t("tags.allCategories"),
        createdAt: "",
        updatedAt: "",
        description: "",
        priority: 0,
        style: "",
        icon: "",
        tags: [],
    };
    const emptyRow = (
        <TableRow>
            <TableCell colSpan={4} align="center">
                {t("tags.noTags")}
            </TableCell>
        </TableRow>
    );
    return (
        <Box>
            <Container maxWidth="sm">
                <TagCategoryInput
                    label={t("tags.category")}
                    categoryUUID={state.categoryFilter}
                    fullWidth
                    variant="outlined"
                    extraItems={[allCategory]}
                    onChange={(e): void => setState({...state, categoryFilter: e.id, name: "loading"})}
                />
            </Container>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">{t("tags.tag")}</TableCell>
                        <TableCell align="left">{t("tags.description")}</TableCell>
                        <TableCell align="left">{t("tags.category")}</TableCell>
                        <TableCell align="right">
                            <RefreshActionButton
                                onClick={(): void =>
                                    setState({
                                        ...initState,
                                        name: "loading",
                                    })
                                }
                            />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody key={state.categoryFilter}>{rows.length > 0 ? rows : emptyRow}</TableBody>
            </Table>
            <div ref={formRef}>
                {state.name === "editing" && state.active !== null ? (
                    <Container maxWidth="sm">
                        <h2>{t("tags.edit")}</h2>
                        <TagForm
                            key={state.active.id}
                            tag={state.inForm}
                            onSubmit={handleEditSubmit}
                            onCancel={handleEditCancel}
                        />
                    </Container>
                ) : (
                    <Container maxWidth="sm">
                        <h2>{t("tags.new")}</h2>
                        <TagForm key={`new-${state.newId}`} tag={state.inForm} onSubmit={handleCreateSubmit} />
                    </Container>
                )}
            </div>
            <TagDelete
                entity={state.name === "deleting" ? state.active : null}
                onDeleted={handleDeleteDone}
                onCancel={handleDeleteCancel}
                onFailed={handleDeleteFailed}
            />
            {notification}
        </Box>
    );
};
