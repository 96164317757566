import React from "react";

import {useKeycloak} from "@react-keycloak/web";

import {Link} from "react-router-dom";

import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";

import AccountCircle from "@material-ui/icons/AccountCircle";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
//import Brightness4Icon from "@material-ui/icons/Brightness4";
//import Brightness7Icon from "@material-ui/icons/Brightness7";
import Tooltip from "@material-ui/core/Tooltip";

import {ROUTES} from "../../routes/routes";
import {Box} from "@material-ui/core";
import {DevelopersOnlyBlock} from "../common/DevelopersOnlyBlock";
import {VersionInfoNavButton} from "./dev/VersionInfoNavButton";

interface TopMenuProps {
    activeTheme: "dark" | "light";
    changeTheme: (theme: "dark" | "light") => void;
}

/*
interface ThemeSwitcherProps {
    activeTheme: "dark" | "light";
    changeTheme: (theme: "dark" | "light") => void;
}
*/

const baseUrl = (): string => {
    const getUrl = window.location;
    return getUrl.protocol + "//" + getUrl.host;
};

/*
const ThemeSwitcher: React.FC<ThemeSwitcherProps> = (props: ThemeSwitcherProps) => {
    const [t] = useTranslation("common");
    const nextTheme = props.activeTheme === "light" ? "dark" : "light";
    return (
        <Tooltip title={t(`menu.theme.${nextTheme}`) as string}>
            <IconButton color="inherit" onClick={() => props.changeTheme(nextTheme)}>
                {props.activeTheme === "light" ? <Brightness4Icon /> : <Brightness7Icon />}
            </IconButton>
        </Tooltip>
    );
};
*/

const UserTopMenu: React.FC<TopMenuProps> = (props: TopMenuProps) => {
    const [t] = useTranslation();
    const {keycloak, initialized} = useKeycloak();

    const logout = (): void => {
        if (keycloak && keycloak.authenticated && initialized) {
            keycloak.logout({redirectUri: `${baseUrl()}${ROUTES.landing.path}`});
        }
    };

    return (
        <Box>
            <DevelopersOnlyBlock>
                <VersionInfoNavButton title={t("menu.versionInfo")} />
            </DevelopersOnlyBlock>
            {/*<ThemeSwitcher {...props} />*/}
            <Tooltip title={t("menu.dashboard") as string}>
                <IconButton color="inherit" component={Link} to={ROUTES.dashboard.path}>
                    <DashboardIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title={t("menu.profile") as string}>
                <IconButton color="inherit" component={Link} to={ROUTES.ownProfile.path}>
                    <AccountCircle />
                </IconButton>
            </Tooltip>
            <Tooltip title={t("auth.logout") as string}>
                <IconButton color="inherit" onClick={logout}>
                    <ExitToAppIcon />
                </IconButton>
            </Tooltip>
        </Box>
    );
};

const LoginTopMenu: React.FC<TopMenuProps> = (props: TopMenuProps) => {
    const [t] = useTranslation();
    const {keycloak, initialized} = useKeycloak();
    const login = (): void => {
        if (initialized && keycloak) {
            keycloak.login({redirectUri: `${baseUrl()}${ROUTES.dashboard.path}`});
        } else {
            alert(t("auth.cannot_login"));
        }
    };

    return (
        <Box>
            {/*<ThemeSwitcher {...props} />*/}
            <Button color="primary" variant="contained" onClick={login} disabled={!initialized}>
                {t("auth.login")}
            </Button>
        </Box>
    );
};

export const TopMenu: React.FC<TopMenuProps> = (props: TopMenuProps) => {
    const {keycloak, initialized} = useKeycloak();

    if (initialized && keycloak.authenticated) {
        return <UserTopMenu {...props} />;
    } else {
        return <LoginTopMenu {...props} />;
    }
};
