import React from "react";
import {useTranslation} from "react-i18next";
import {Box, Container} from "@material-ui/core";
import {DeleteActionButton} from "../../../common/buttons";
import {DashboardIndicatorDTO} from "../../../../models/trackedIndicators";
import WorkingSVG from "../../../../graphics/undraw/factory_dy0a.svg";
import {IndicatorHistory} from "../../../values/IndicatorHistory";
import {OrganizationPermissionsDTO} from "../../../../models/members";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {ReactSortable} from "react-sortablejs";

//=============================================================================

interface DashboardIndicatorsGridProps {
    dashboardIndicators: DashboardIndicatorDTO[];
    organizationId: string | null;
    permissions: OrganizationPermissionsDTO | null;

    onMove(dashboardIndicator: DashboardIndicatorDTO, targetIndex: number): void;

    onRemove(dashboardIndicator: DashboardIndicatorDTO): void;
}

const useDashboardIndicatorsStyles = makeStyles((theme: Theme) =>
    createStyles({
        filterRow: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            alignItems: "flex-end",
        },
        filterString: {
            padding: theme.spacing(1),
        },
        filterTags: {
            padding: theme.spacing(1),
        },
        indicatorTile: {
            border: "1px solid " + theme.palette.divider,
        },
    })
);

export const DashboardIndicatorsGrid: React.FC<DashboardIndicatorsGridProps> = (
    props: DashboardIndicatorsGridProps
) => {
    //== Init =================================================================
    const [t] = useTranslation(["trackedIndicators"]);
    const classes = useDashboardIndicatorsStyles();
    //== Render ===============================================================
    const empty = props.dashboardIndicators.length === 0;
    console.log("[DashboardIndicators] props:");
    console.log(props);
    const permissionsToEdit =
        props.dashboardIndicators.length &&
        props.dashboardIndicators.every((di) => di.addedByUser) &&
        (!props.organizationId || props.permissions?.trackedIndicatorsPermissionLevel === "READWRITE");
    console.log("[DashboardIndicators] permissionsToEdit: " + permissionsToEdit);
    const items = props.dashboardIndicators.map((d) => (
        <Box
            key={d.trackedIndicator.id}
            className={classes.indicatorTile}
            style={{
                width: "calc(552px - 2%)",
                minWidth: "49%",
                minHeight: 217,
                margin: "0.5%",
                cursor: permissionsToEdit ? "grab" : "initial",
            }}
        >
            <Box style={{display: "flex", justifyContent: "space-between", marginLeft: "1em", height: "5.5em"}}>
                <h2 style={{marginRight: "1em"}}>{d.trackedIndicator.indicator.name} </h2>
                {permissionsToEdit ? (
                    <Box style={{float: "right"}}>
                        <DeleteActionButton onClick={() => props.onRemove(d)} />
                    </Box>
                ) : null}
            </Box>
            <Box
                style={{
                    minHeight: "calc(100% - 5.5em)",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                }}
            >
                <Box style={{width: "100%"}}>
                    <IndicatorHistory
                        indicator={d.trackedIndicator}
                        organizationId={d.municipality.organizationId}
                        permissions={props.permissions || undefined}
                        graphOnly={true}
                    />
                </Box>
            </Box>
        </Box>
    ));
    return (
        <Container maxWidth="xl">
            {permissionsToEdit ? (
                <ReactSortable
                    style={{display: "flex", flexWrap: "wrap"}}
                    list={props.dashboardIndicators}
                    setList={(newItems) => {
                        console.log("[DashboardIndicators] drag setList:");
                        console.log(newItems);
                    }}
                    onEnd={(evt) => {
                        console.log("[DashboardIndicators] drag onEnd:");
                        console.log(evt);
                        console.log(evt.oldIndex + " => " + evt.newIndex);
                        if (evt.oldIndex != undefined && evt.newIndex != undefined)
                            props.onMove(props.dashboardIndicators[evt.oldIndex], evt.newIndex);
                    }}
                >
                    {items}
                </ReactSortable>
            ) : (
                <Box style={{display: "flex", flexWrap: "wrap"}}>{items}</Box>
            )}
            {empty && (
                <Box style={{textAlign: "center"}}>
                    <h2>{t("dashboard.no_data")}</h2>
                    <img src={WorkingSVG} style={{maxWidth: "40em", margin: "3em"}} alt="no-domains" />
                </Box>
            )}
        </Container>
    );
};
