import React from "react";
import {useTranslation} from "react-i18next";
// Material UI imports
import {Box, Button} from "@material-ui/core";
// Project imports
import TrackedIndicatorGroupEditorAddDialog from "./TrackedIndicatorGroupEditorAddDialog";
import {DomainWithContent} from "./TrackedIndicatorGroupEditorAddingInterfaces";
import {TrackedIndicatorGroupEditorAddProps} from "./TrackedIndicatorGroupEditorAddProps";

const TrackedIndicatorGroupEditorAdd: React.FC<TrackedIndicatorGroupEditorAddProps> = (
    props: TrackedIndicatorGroupEditorAddProps
) => {
    const [t] = useTranslation(["trackedIndicators", "common"]);
    const [open, setOpen] = React.useState(false);
    //== Render =================================================================
    const onAdd = (domains: DomainWithContent[]) => {
        setOpen(false);
        if (domains.length > 0) props.onAdd(domains);
    };
    const onCancel = () => {
        setOpen(false);
    };
    return (
        <Box>
            <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    setOpen(true);
                }}
            >
                {t("ui.addIndicators")}
            </Button>
            <TrackedIndicatorGroupEditorAddDialog
                {...props}
                onAdd={onAdd}
                onCancel={onCancel}
                open={open}
                type={props.type}
            />
        </Box>
    );
};

export default TrackedIndicatorGroupEditorAdd;
