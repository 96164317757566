import React from "react";
// Material-UI icons imports
import SearchIcon from "@material-ui/icons/Search";
import HomeIcon from "@material-ui/icons/Home";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import BusinessIcon from "@material-ui/icons/Business";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import ShareIcon from "@material-ui/icons/Share";
import HelpIcon from "@material-ui/icons/Help";
import MailIcon from "@material-ui/icons/Mail";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
// Project imports
import ROUTES from "../../routes/routes";

export interface MenuItem {
    icon: JSX.Element;
    id: string;
    route: string;
}

const MENU_ITEMS = {
    public: [
        {
            icon: <HomeIcon />,
            id: "landing",
            route: ROUTES.landing.path,
        },
        {
            icon: <SearchIcon />,
            id: "municipalities",
            route: ROUTES.municipalities.path,
        },
        {
            icon: <AccountTreeIcon />,
            id: "libraries",
            route: ROUTES.libraries.path,
        },
        {
            icon: <HelpIcon />,
            id: "manual",
            route: ROUTES.manual.path,
        },
        {
            icon: <EmojiPeopleIcon />,
            id: "partners",
            route: ROUTES.partners.path,
        },
        {
            icon: <MailIcon />,
            id: "contacts",
            route: ROUTES.contacts.path,
        },
    ],
    user: [
        {
            icon: <DashboardIcon />,
            id: "dashboard",
            route: ROUTES.dashboard.path,
        },
        {
            icon: <AccountCircleIcon />,
            id: "profile",
            route: ROUTES.ownProfile.path,
        },
    ],
    admin: [
        {
            icon: <AccountTreeIcon />,
            id: "libraries",
            route: ROUTES.librariesAdmin.path,
        },
        {
            icon: <SupervisorAccountIcon />,
            id: "users",
            route: ROUTES.users.path,
        },
        {
            icon: <BusinessIcon />,
            id: "organizations",
            route: ROUTES.organizations.path,
        },
        {
            icon: <ShareIcon />,
            id: "definitions",
            route: ROUTES.definitions.path,
        },
        {
            icon: <LocalOfferIcon />,
            id: "tags",
            route: ROUTES.tags.path,
        },
        {
            icon: <MenuBookIcon />,
            id: "cms",
            route: ROUTES.cms.path,
        },
    ],
};

export default MENU_ITEMS;
