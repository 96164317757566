import React from "react";
import {compareLibraryUseAsc, LibraryDomainUseDTO, LibraryDTO, LibraryGoalUseDTO} from "../../../models/library";
import {Box} from "@material-ui/core";
import {LibraryGoalCreateRow} from "./rows/LibraryGoalCreateRow";
import {LibraryGoalUseRow} from "./rows/LibraryGoalUseRow";
import {IndicatorLevel} from "./IndicatorLevel";
import {LibraryGoalOwnRow} from "./rows/LibraryGoalOwnRow";
import {useKeycloak} from "@react-keycloak/web";
import {API, createApiConfig} from "../../../utils/API";
import {AxiosError} from "axios";
import ItemPositionInLibrary, {GoalPositionInLibrary} from "./util/LibraryItemPosition";
import LibraryItemMoveTarget from "./util/LibraryItemMoveTarget";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {LoadingBackdrop} from "../../common/Loading";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        goalShiftTarget: {
            marginLeft: theme.spacing(2),
        },
    })
);

interface LibraryGoalRowProps {
    library: LibraryDTO;
    libraryDomainUse: LibraryDomainUseDTO;
    libraryGoalUse: LibraryGoalUseDTO;
    onUpdate: (library: LibraryDTO) => void;
    shiftingItemPosition: ItemPositionInLibrary | null;
    handleShiftStart: (itemPosition: GoalPositionInLibrary) => void;
    handleShiftEnded: () => void;
}

const LibraryGoalRow: React.FC<LibraryGoalRowProps> = (props: LibraryGoalRowProps) => {
    if (props.library.id === props.libraryGoalUse.goal.libraryUUID) {
        return <LibraryGoalOwnRow {...props} />;
    } else {
        return <LibraryGoalUseRow {...props} />;
    }
};

interface GoalLevelBlockProps extends GoalLevelProps {
    libraryGoalUse: LibraryGoalUseDTO;
}

const GoalLevelBlock: React.FC<GoalLevelBlockProps> = (props: GoalLevelBlockProps) => {
    return (
        <div>
            <LibraryGoalRow {...props} />
            <IndicatorLevel {...props} />
        </div>
    );
};

interface GoalLevelProps {
    library: LibraryDTO;
    libraryDomainUse: LibraryDomainUseDTO;
    onReload: (cause: string) => void;
    onUpdate: (library: LibraryDTO) => void;
    shiftingItemPosition: ItemPositionInLibrary | null;
    handleShiftStart: (itemPosition: GoalPositionInLibrary) => void;
    handleShiftEnded: () => void;
}

interface GoalLevelState {
    name: "init" | "failed" | "shifting";
    shiftingItemPosition: number | null;
    shiftingTargetIndex: number | null;
}

export const GoalLevel: React.FC<GoalLevelProps> = (props: GoalLevelProps) => {
    //== Init =================================================================
    const classes = useStyles();
    const goals = props.libraryDomainUse.libraryGoalUses.sort(compareLibraryUseAsc);
    const initState: GoalLevelState = {
        name: "init",
        shiftingItemPosition: null,
        shiftingTargetIndex: null,
    };
    const [state, setState] = React.useState(initState);
    const {keycloak, initialized} = useKeycloak();
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name == "shifting" && state.shiftingTargetIndex !== null) {
            let shiftedItem = null;
            if (props.shiftingItemPosition && props.shiftingItemPosition.goalIndex !== null)
                shiftedItem =
                    props.library.libraryDomainUses[props.shiftingItemPosition.domainIndex].libraryGoalUses[
                        props.shiftingItemPosition.goalIndex
                    ];
            else if (state.shiftingItemPosition)
                shiftedItem = props.libraryDomainUse.libraryGoalUses[state.shiftingItemPosition];
            if (!shiftedItem) return;
            const dto = {
                goalUseUUID: shiftedItem.id,
                targetDomainUseUUID: props.libraryDomainUse.id,
                targetIndex: state.shiftingTargetIndex,
            };
            API.post<LibraryDTO>("/goals/library-use/move", dto, createApiConfig(keycloak, initialized))
                .then((res) => {
                    handleShiftEnded();
                    props.onReload("shift");
                })
                .catch((err: AxiosError) => {
                    console.log(err);
                    setState({...state, shiftingItemPosition: null, shiftingTargetIndex: null, name: "failed"});
                });
        }
    }, [state, setState, props]);
    //== Handlers =============================================================
    const handleShiftEnd = (targetIndex: number) => {
        console.log("[GoalLevel] handleShiftEnd - props.shiftingItemPosition:");
        console.log(props.shiftingItemPosition);
        console.log("[GoalLevel] handleShiftEnd - targetIndex:");
        console.log(targetIndex);
        if (props.shiftingItemPosition === null || props.shiftingItemPosition.goalIndex === null) {
            return;
        }
        const isShiftingDownInSameDomain =
            props.shiftingItemPosition.domainIndex === props.libraryDomainUse.orderNumber &&
            props.shiftingItemPosition.goalIndex < targetIndex;
        setState({
            ...state,
            shiftingTargetIndex: isShiftingDownInSameDomain ? targetIndex - 1 : targetIndex,
            name: "shifting",
        });
    };
    const handleShiftEnded = () => {
        if (state.shiftingItemPosition !== null || state.shiftingTargetIndex !== null)
            setState({
                ...state,
                shiftingItemPosition: null,
                shiftingTargetIndex: null,
                name: "init",
            });
        props.handleShiftEnded();
    };
    const isShiftingGoal =
        props.shiftingItemPosition !== null &&
        props.shiftingItemPosition.goalIndex !== null &&
        props.shiftingItemPosition.indicatorIndex === null;
    const libraryPublished = props.library.public || props.library.shared;
    //== Render ===============================================================
    return (
        <Box>
            {goals
                .map((goalUse, index) => {
                    const isShiftingAdjacentGoal =
                        props.shiftingItemPosition &&
                        props.shiftingItemPosition.domainIndex === props.libraryDomainUse.orderNumber &&
                        props.shiftingItemPosition.goalIndex !== null &&
                        (props.shiftingItemPosition.goalIndex == index ||
                            props.shiftingItemPosition.goalIndex + 1 == index);

                    return (
                        <Box key={goalUse.id}>
                            <LibraryItemMoveTarget
                                key={goalUse.id + "-move-target"}
                                className={classes.goalShiftTarget}
                                active={isShiftingGoal && !isShiftingAdjacentGoal}
                                onAction={() => handleShiftEnd(index)}
                            />
                            <GoalLevelBlock
                                {...props}
                                libraryGoalUse={goalUse}
                                shiftingItemPosition={props.shiftingItemPosition}
                                handleShiftStart={props.handleShiftStart}
                                handleShiftEnded={handleShiftEnded}
                            />
                        </Box>
                    );
                })
                .concat([
                    <LibraryItemMoveTarget
                        key={props.library.id + "-end-move-target"}
                        className={classes.goalShiftTarget}
                        active={
                            isShiftingGoal &&
                            !(
                                props.shiftingItemPosition &&
                                props.shiftingItemPosition.domainIndex === props.libraryDomainUse.orderNumber &&
                                props.shiftingItemPosition.goalIndex !== null &&
                                props.shiftingItemPosition.goalIndex + 1 === goals.length
                            )
                        }
                        onAction={() => handleShiftEnd(goals.length)}
                    />,
                ])}
            {props.shiftingItemPosition === null && !libraryPublished && <LibraryGoalCreateRow {...props} />}
            {state.name === "shifting" && <LoadingBackdrop />}
        </Box>
    );
};
