import React from "react";
import {useTranslation} from "react-i18next";
// Material UI imports
import Alert from "@material-ui/lab/Alert";
// Graphics
import NotFoundSVG from "../../graphics/undraw/not_found_60pq.svg";
import NotImplementedSVG from "../../graphics/undraw/work_in_progress_uhmv.svg";
import NotAuthorizedSVG from "../../graphics/undraw/cancel_u1it.svg";
import CommErrorSVG from "../../graphics/undraw/signal_searching_bhpc.svg";

export const KeycloakNotConnectedError: React.FC = () => {
    const [t] = useTranslation();

    return <Alert severity="error">{t("auth.server_down")}</Alert>;
};

export const ServerCommunicationAlert: React.FC = () => {
    const [t] = useTranslation();

    return <Alert severity="error">{t("app.comm_error")}</Alert>;
};

interface ErrorPageProps {
    title: string;
    description: string;
    image: string;
}

export const ErrorPage: React.FC<ErrorPageProps> = (props: ErrorPageProps) => {
    return (
        <div className="error-page">
            <h1>{props.title}</h1>
            <p>{props.description}</p>
            <img style={{maxWidth: 600, width: "100%", margin: "auto"}} src={props.image} alt={props.title} />
        </div>
    );
};

export const NotImplemented: React.FC = () => {
    const [t] = useTranslation("staticPages");
    return (
        <ErrorPage
            title={t("error.not_implemented.title")}
            description={t("error.not_implemented.description")}
            image={NotImplementedSVG}
        />
    );
};

export const NotFound: React.FC = () => {
    const [t] = useTranslation("staticPages");
    return (
        <ErrorPage
            title={t("error.not_found.title")}
            description={t("error.not_found.description")}
            image={NotFoundSVG}
        />
    );
};

export const NotAuthorized: React.FC = () => {
    const [t] = useTranslation("staticPages");
    return (
        <ErrorPage
            title={t("error.not_authorized.title")}
            description={t("error.not_authorized.description")}
            image={NotAuthorizedSVG}
        />
    );
};

export const CommunicationError: React.FC = () => {
    const [t] = useTranslation("staticPages");
    return (
        <ErrorPage
            title={t("error.comm_error.title")}
            description={t("error.comm_error.description")}
            image={CommErrorSVG}
        />
    );
};
