import {
    EMPTY_CONST_NODE,
    FormulaNode,
    getDefaultOperands,
    getDefaultOperationValue,
} from "../../../../../models/formulaEditor";
import {VariableSimpleDTO} from "../../../../../models/library";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import {OperationType} from "../../../../../models/formulas";
import {Box} from "@material-ui/core";
import {FormulaConstForm} from "./FormulaConstForm";
import {FormulaVariableForm} from "./FormulaVariableForm";
import {FormulaOperationSelect} from "./FormulaOperationSelect";
import {useTranslation} from "react-i18next";

interface FormulaNodeFormProps {
    index: number;
    node: FormulaNode;
    usedVariables: VariableSimpleDTO[];
    variables: VariableSimpleDTO[];
    onChange: (index: number, node: FormulaNode) => void;
}

const useStyles = makeStyles((theme) => ({
    formRow: {
        marginLeft: "1em",
        marginTop: "0.4em",
    },
}));

export const FormulaNodeForm: React.FC<FormulaNodeFormProps> = (props: FormulaNodeFormProps) => {
    // TODO: support ROUND with 2 operands (low priority)
    // TODO: support ADD, MUL with 2+ operands (low priority)
    //== Init =================================================================
    const classes = useStyles();
    const [t] = useTranslation("libraries");
    //== Handlers =============================================================
    const handleOperationChange = (operation: OperationType) => {
        const defaultOperands = getDefaultOperands(operation);
        props.onChange(props.index, {
            ...props.node,
            opType: operation,
            value: getDefaultOperationValue(operation),
            operands: [...props.node.operands, ...defaultOperands].slice(0, defaultOperands.length),
        });
    };
    const handleChildChange = (index: number, childNode: FormulaNode) => {
        const children = [...props.node.operands];
        children[index] = childNode;
        if (childNode.opType === OperationType.VAR && childNode.value === "") {
            children[index].value = props.variables.length === 0 ? "" : props.variables[0].id;
        }
        props.onChange(props.index, {
            ...props.node,
            operands: children,
        });
    };
    const handleNodeChange = (node: FormulaNode) => {
        props.onChange(props.index, node);
    };
    //== Render ===============================================================
    if (props.node.type === "empty" || props.node.opType === undefined) {
        props.onChange(props.index, EMPTY_CONST_NODE);
        return null;
    } else if (props.node.type === "invalid") {
        return <Box className={classes.formRow}>{t("formula.error.invalid")}</Box>;
    } else if (props.node.opType === OperationType.CONST) {
        return (
            <Box className={classes.formRow}>
                <FormulaConstForm node={props.node} onChange={handleNodeChange} />
            </Box>
        );
    } else if (props.node.opType === OperationType.VAR) {
        return (
            <Box className={classes.formRow}>
                <FormulaVariableForm
                    node={props.node}
                    usedVariables={props.usedVariables}
                    variables={props.variables}
                    onChange={handleNodeChange}
                />
            </Box>
        );
    } else {
        return (
            <Box className={classes.formRow}>
                <FormulaOperationSelect value={props.node.opType} onChange={handleOperationChange} />
                {props.node.operands.map((o, i) => (
                    <FormulaNodeForm
                        key={i}
                        index={i}
                        node={o}
                        usedVariables={props.usedVariables}
                        variables={props.variables}
                        onChange={handleChildChange}
                    />
                ))}
            </Box>
        );
    }
};
