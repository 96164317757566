import React from "react";
import {ServerCommunicationAlert} from "../../../common/errors";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
import Loading from "../../../common/Loading";
import {EditActionButton} from "../../../common/buttons";
import {API, createApiConfig} from "../../../../utils";
import {AxiosError} from "axios";
import {TrackedIndicatorInGroupDTO} from "../../../../models/trackedIndicators";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {ValueDTO, ValueSimpleDTO, valueToUpdate} from "../../../../models/values";
import ValueForm from "../../../values/ValueForm";
import {UnitSimpleDTO} from "../../../../models/library";
import {OrganizationPermissionsDTO} from "../../../../models/members";

interface IndicatorTargetValueProps {
    trackedIndicator: TrackedIndicatorInGroupDTO;
    permissions: OrganizationPermissionsDTO;
    organizationId: string;

    onChange(targetValue: ValueSimpleDTO | null): void;
}

interface IndicatorTargetValueState {
    name: "closed" | "open" | "saving" | "failed";
    savedTargetValue: ValueSimpleDTO | null;
    formTargetValue: ValueSimpleDTO | null;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {},
        label: {
            marginRight: "0.5em",
        },
        form: {
            width: "auto",
        },
    })
);

const IndicatorTargetValue: React.FC<IndicatorTargetValueProps> = (props: IndicatorTargetValueProps) => {
    //== Init =================================================================
    const initState: IndicatorTargetValueState = {
        name: "closed",
        savedTargetValue: props.trackedIndicator.targetValue,
        formTargetValue: props.trackedIndicator.targetValue,
    };
    const classes = useStyles();
    const [state, setState] = React.useState(initState);
    const {keycloak, initialized} = useKeycloak();
    const [t] = useTranslation(["trackedIndicators"]);
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "saving") {
            if (!state.formTargetValue) {
                //target value removed
                API.delete<void>(
                    "/values/target-indicator-value/" + props.trackedIndicator.id,
                    createApiConfig(keycloak, initialized)
                )
                    .then((res) => {
                        setState({...state, name: "closed", savedTargetValue: null});
                        props.onChange(null);
                        console.log("[IndicatorTargetValue] Changed targetValue to: null");
                    })
                    .catch((err: AxiosError) => {
                        setState({...state, name: "failed"});
                    });
            } else {
                //target value modified or created
                API.post<ValueDTO>(
                    "/values/target-indicator-value/" + props.trackedIndicator.id,
                    valueToUpdate(state.formTargetValue),
                    createApiConfig(keycloak, initialized)
                )
                    .then((res) => {
                        setState({...state, name: "closed", savedTargetValue: res.data, formTargetValue: res.data});
                        props.onChange(res.data);
                        console.log("[IndicatorTargetValue] Changed targetValue to:");
                        console.log(res.data);
                    })
                    .catch((err: AxiosError) => {
                        setState({...state, name: "failed"});
                    });
            }
        }
    }, [state, setState, keycloak, initialized, props.trackedIndicator]);

    //== Handlers =============================================================

    const handleOpen = (): void => {
        setState({...state, name: "open"});
    };
    const handleCancel = (): void => {
        setState({...state, name: "closed"});
    };
    const handleDelete = (): void => {
        setState({...state, name: "saving", formTargetValue: null});
    };
    const handleSave = (value: number, unit: UnitSimpleDTO | null): void => {
        setState({...state, name: "saving", formTargetValue: {...state.formTargetValue, value: value, unit: unit}});
    };

    //== Render ===============================================================

    const content =
        state.name === "saving" ? (
            <Loading />
        ) : state.name === "failed" ? (
            <ServerCommunicationAlert />
        ) : state.name === "closed" || props.permissions.trackedIndicatorsPermissionLevel !== "READWRITE" ? (
            <>
                <span>
                    {state.savedTargetValue === null || state.savedTargetValue.value === undefined
                        ? t("editor.notSet")
                        : state.savedTargetValue.value}
                </span>
                {state.savedTargetValue?.unit?.abbreviation ? (
                    <span>&nbsp;{state.savedTargetValue.unit.abbreviation}</span>
                ) : null}
                {props.permissions.trackedIndicatorsPermissionLevel === "READWRITE" ? (
                    <EditActionButton onClick={handleOpen} />
                ) : null}
            </>
        ) : (
            <ValueForm
                options={props.trackedIndicator.indicator.options}
                preferredUnit={props.trackedIndicator.indicator.preferredUnit}
                minimumValue={props.trackedIndicator.indicator.minimumValue}
                maximumValue={props.trackedIndicator.indicator.maximumValue}
                formClassName={classes.form}
                value={state.formTargetValue?.value || null}
                unit={state.formTargetValue?.unit || null}
                onSubmit={handleSave}
                onDelete={state.savedTargetValue ? handleDelete : undefined}
                onCancel={handleCancel}
            />
        );
    return (
        <>
            <span className={classes.label}>{t("editor.targetValue") + ":"}</span>
            {content}
        </>
    );
};

export default IndicatorTargetValue;
