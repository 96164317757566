export interface Page<T> {
    content: T[];
    empty: boolean;
    first: boolean;
    last: boolean;
    number: number;
    numberOfElements: number;
    size: number;
    totalElements: number;
    totalPages: number;
}

export function emptyPage<T>(arr: T[]): Page<T> {
    return {
        content: arr,
        empty: true,
        first: true,
        last: true,
        number: 0,
        numberOfElements: 0,
        size: 0,
        totalElements: 0,
        totalPages: 0,
    };
}

export interface PaginationInfo {
    size: number;
    totalElements: number;
    totalPages: number;
    number: number;
}
