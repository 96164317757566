import {ReactChild, ReactPortal} from "react";
import {ManualChapter} from "./ManualChapter";

export interface Manual {
    language: string;
    title: string;
    intro?: ReactChild | ReactPortal;
    chapters: Map<string, ManualChapter>;
}

interface ManualProps {
    language: string;
    title: string;
    intro?: ReactChild | ReactPortal;
    chapters?: ManualChapter[];
}

export const manual = (props: ManualProps): Manual => {
    const manualRoot = {
        language: props.language,
        title: props.title,
        intro: props.intro || undefined,
        chapters: new Map(),
    };
    manualRoot.chapters = new Map(
        (props.chapters || []).map((chapter, index) => {
            chapter.index = index + 1;
            chapter.manual = manualRoot;
            return [chapter.key, chapter];
        })
    );
    return manualRoot;
};

export default manual;
