import React from "react";
import {
    IndicatorCreateDTO,
    InputVariableDTO,
    inputVariableToSimple,
    VariableSimpleDTO,
} from "../../../../models/library";
import Dialog from "@material-ui/core/Dialog";
import {Box, Button, DialogActions, DialogContent, DialogTitle, Divider} from "@material-ui/core";
import {FormulaDisplay} from "../../formulas/FormulaDisplay";
import {useTranslation} from "react-i18next";
import {extractVariables, isFormulaValid} from "../../../../models/formulas";
import {API, createApiConfig} from "../../../../utils/API";
import {AxiosError, AxiosResponse} from "axios";
import {useKeycloak} from "@react-keycloak/web";
import Loading from "../../../common/Loading";
import {CommunicationError} from "../../../common/errors";
import {FormulaField} from "./FormulaField";
import {FormulaVariables} from "../../formulas/FormulaVariables";
import {compareByAttr} from "../../../../utils/compare";

interface FormulaDialogProps {
    open: boolean;
    indicator: IndicatorCreateDTO;
    usedVariables: VariableSimpleDTO[];
    onSubmit: (formula: string, usedVariables: VariableSimpleDTO[]) => void;
    onClose: () => void;
}

interface FormulaDialogState {
    formula: string;
    variables: VariableSimpleDTO[];
    usedVariables: VariableSimpleDTO[];
    name: "loading" | "loaded" | "failed";
    advanced: boolean;
}

export const FormulaDialog: React.FC<FormulaDialogProps> = (props: FormulaDialogProps) => {
    //== Init =================================================================
    const initState: FormulaDialogState = {
        formula: props.indicator.formula,
        variables: [],
        usedVariables: props.usedVariables,
        name: "loading",
        advanced: false,
    };
    const [state, setState] = React.useState(initState);
    const [t] = useTranslation("libraries");
    const {keycloak, initialized} = useKeycloak();
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<InputVariableDTO[]>(`/input-variables/list/all`, createApiConfig(keycloak, initialized))
                .then((res: AxiosResponse<InputVariableDTO[]>) => {
                    const usedIds = new Set<string>(state.usedVariables.map((v) => v.id));
                    const variables = res.data
                        .sort(compareByAttr("name", "ascending"))
                        .map((v) => inputVariableToSimple(v));
                    const usedVariables = variables.filter((v) => usedIds.has(v.id));
                    setState({...state, variables: variables, usedVariables: usedVariables, name: "loaded"});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    });
    //== Handlers =============================================================
    const handleClose = () => {
        setState({
            ...state,
            formula: props.indicator.formula,
            usedVariables: props.usedVariables,
        });
        props.onClose();
    };
    const handleSubmit = () => {
        props.onSubmit(state.formula, state.usedVariables);
    };
    const handleAdvancedOn = () => {
        setState({...state, advanced: true});
    };
    const handleAdvancedOff = () => {
        setState({...state, advanced: false});
    };
    const handleFormulaChange = (formula: string) => {
        const usedVariablesIds = new Set<string>(extractVariables(formula));
        setState({
            ...state,
            formula: formula,
            usedVariables: state.variables.filter((v) => usedVariablesIds.has(v.id)),
        });
    };
    //== Render ===============================================================
    const isValid = isFormulaValid(state.formula, state.usedVariables);
    return (
        <Dialog onClose={handleClose} open={props.open} fullWidth maxWidth="md">
            <DialogTitle>{t("formula.dialog.title")}</DialogTitle>
            <DialogContent>
                {state.name === "loading" && <Loading />}
                {state.name === "failed" && <CommunicationError />}
                {state.name === "loaded" && (
                    <Box>
                        <FormulaDisplay formula={state.formula} variables={state.usedVariables} errorInfo />
                        <Divider style={{margin: "1em"}} />
                        <FormulaVariables formula={state.formula} variables={state.usedVariables} showUnused />
                        <Divider style={{margin: "1em"}} />
                        <FormulaField
                            formula={state.formula}
                            variables={state.variables}
                            usedVariables={state.usedVariables}
                            onChange={handleFormulaChange}
                            showField={state.advanced}
                        />
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={handleClose}>
                    {t("formula.dialog.actions.close")}
                </Button>
                {state.advanced ? (
                    <Button color="secondary" onClick={handleAdvancedOff}>
                        {t("formula.dialog.actions.advancedOff")}
                    </Button>
                ) : (
                    <Button color="secondary" onClick={handleAdvancedOn}>
                        {t("formula.dialog.actions.advancedOn")}
                    </Button>
                )}
                <Button color="primary" disabled={!isValid} onClick={handleSubmit}>
                    {t("formula.dialog.actions.save")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
