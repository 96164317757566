import React from "react";
import {useTranslation} from "react-i18next";
import {useKeycloak} from "@react-keycloak/web";
// Project imports
import {
    AddCircleActionButton,
    BackActionButton,
    EditLinkActionButton,
    RefreshActionButton,
    URLCopyButton,
    VisitLinkActionButton,
} from "../common/buttons";
import {ServerCommunicationAlert} from "../common/errors";
import {Loading} from "../common/Loading";
import {InfoNotification} from "../common/notifications";
import {WebpageDTO} from "../../models/admin";
import CMSPageNew from "./CMSPageNew";
import {useStyles} from "../../styles";
import {API, createApiConfig, DateUtils} from "../../utils";
import {AxiosError} from "axios";
import {WEBPAGES} from "../../routes/webpages";
// Material-UI imports
import {Box, Table, TableBody, TableCell, TableHead, TableRow, Tooltip} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import StarsIcon from "@material-ui/icons/Stars";
import {Page} from "../../models/pagination";

interface WebpageRowProps {
    webpage: WebpageDTO;
}

const WebpageRow: React.FC<WebpageRowProps> = (props: WebpageRowProps) => {
    const [t] = useTranslation("webpages");
    const viewPath = WEBPAGES.getViewPath(props.webpage);
    const editPath = WEBPAGES.getEditPath(props.webpage);
    const viewLink = `${window.origin}${viewPath}`;
    return (
        <TableRow>
            <TableCell align="left">
                <URLCopyButton text={viewLink} />
                {props.webpage.publicId}
                {WEBPAGES.isSpecial(props.webpage) ? (
                    <Tooltip title={t(`special.${props.webpage.publicId}`) as string}>
                        <StarsIcon fontSize="small" color="disabled" />
                    </Tooltip>
                ) : null}
            </TableCell>
            <TableCell align="left">{props.webpage.title}</TableCell>
            <TableCell align="right">{DateUtils.datetimeString(props.webpage.updatedAt)}</TableCell>
            <TableCell align="right">
                <VisitLinkActionButton to={viewPath} />
                <EditLinkActionButton to={editPath} />
            </TableCell>
        </TableRow>
    );
};

export const CMSAdmin: React.FC = () => {
    //== Init ===================================================================
    const [t] = useTranslation("webpages");
    const {keycloak, initialized} = useKeycloak();
    const classes = useStyles();
    const initState = {
        page: {
            size: 100,
            totalElements: 0,
            totalPages: 0,
            number: 0,
        },
        webpages: [] as WebpageDTO[],
        name: "loading",
        action: "init",
    };
    const [state, setState] = React.useState(initState);
    //== Effects ================================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<Page<WebpageDTO>>(
                `/cms/webpages?page=${state.page.number}&size=${state.page.size}`,
                createApiConfig(keycloak, initialized)
            )
                .then((res) => {
                    setState({
                        ...state,
                        page: res.data,
                        webpages: res.data?.content ?? [],
                        name: "loaded",
                    });
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [keycloak, initialized, state, setState]);
    //== Handlers ===============================================================
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number): void => {
        setState({
            ...state,
            page: {...state.page, number: value - 1},
            name: "loading",
            action: "init",
        });
    };
    //== Render =================================================================
    if (state.name === "loading") {
        return <Loading />;
    }
    if (state.name === "failed") {
        return <ServerCommunicationAlert />;
    }
    if (state.name === "creating") {
        return (
            <Box>
                <BackActionButton onClick={(): void => setState({...state, name: "loaded"})} />
                <CMSPageNew />
            </Box>
        );
    }
    let notification = null;
    if (state.name === "loaded" && state.action === "refresh") {
        notification = <InfoNotification message={t("admin.notifications.refresh_ok")} />;
    }
    const rows = state.webpages.map((webpage: WebpageDTO) => <WebpageRow key={webpage.publicId} webpage={webpage} />);
    return (
        <Box>
            <h1>{t("admin.title")}</h1>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">{t("webpage.publicId")}</TableCell>
                        <TableCell align="center">{t("webpage.title")}</TableCell>
                        <TableCell align="center">{t("webpage.updatedAt")}</TableCell>
                        <TableCell align="right">
                            <RefreshActionButton
                                onClick={(): void =>
                                    setState({
                                        ...initState,
                                        action: "refresh",
                                    })
                                }
                            />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{rows}</TableBody>
            </Table>
            {state.page.totalPages > 1 && (
                <Pagination
                    style={{marginTop: "1em"}}
                    count={state.page.totalPages}
                    page={state.page.number + 1}
                    onChange={handlePageChange}
                />
            )}
            {notification}
            <div className={classes.fabWrapper}>
                <AddCircleActionButton onClick={(): void => setState({...state, name: "creating"})} />
            </div>
        </Box>
    );
};

export default CMSAdmin;
