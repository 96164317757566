import React from "react";
import {
    Box,
    Checkbox,
    Container,
    FormControlLabel,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from "@material-ui/core";
import Utils, {API, createApiConfig, DateUtils, ValueUtils} from "../../utils";
import {AxiosError, AxiosResponse} from "axios";
import {useKeycloak} from "@react-keycloak/web";
import Loading from "../common/Loading";
import {InputValueDTO, InputValueHistoryDTO, InputValueSimpleDTO, InputVariableTaskDTO} from "../../models/values";
import {makeFullName} from "../../models/user";
import {useTranslation} from "react-i18next";
import {Alert} from "@material-ui/lab";
import {DataTablePagination} from "../common/tables";
import {SinceUntilPicker} from "../common/forms";
import {CustomActionButton} from "../common/buttons";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import {CSVUtils} from "../../utils/CSV";
import {UnitsSelect} from "../common/values";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import {InputValueInvalidator} from "./InputValueInvalidator";

interface InputValueHistoryTableProps {
    task: InputVariableTaskDTO;
    valueFn: (v: InputValueSimpleDTO) => string;
    values: InputValueSimpleDTO[];
    options?: Map<number, string>;
    newValue?: InputValueDTO;
}

interface InputValueHistoryTableState {
    invalidated: Set<string>;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        validRow: {},
        invalidRow: {
            textDecoration: "line-through",
        },
    })
);

const InputValueHistoryTable: React.FC<InputValueHistoryTableProps> = (props: InputValueHistoryTableProps) => {
    //== Init =================================================================
    const [t] = useTranslation(["values"]);
    const initState: InputValueHistoryTableState = {
        invalidated: new Set(props.values.filter((v) => v.state === "invalid").map((v) => v.id)),
    };
    const [state, setState] = React.useState(initState);
    const classes = useStyles();
    //== Handlers =============================================================
    const handleInvalidate = (valueId: string) => {
        state.invalidated.add(valueId);
        setState({...state, invalidated: state.invalidated});
    };
    //== Helpers ==============================================================
    const isInvalid = (v: InputValueSimpleDTO) => {
        return v.state === "invalid" || state.invalidated.has(v.id);
    };
    const invalidNote: string = t("values_input.invalidNote");
    const invalidIndicator = (
        <Tooltip title={invalidNote}>
            <div>
                <IconButton disabled size="small">
                    <ErrorOutlineIcon />
                </IconButton>
            </div>
        </Tooltip>
    );
    //== Render ===============================================================
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell align="center">{t("values_input.gatheredAt")}</TableCell>
                        <TableCell align="center">{t("values_input.value")}</TableCell>
                        <TableCell align="center">{t("values_input.note")}</TableCell>
                        <TableCell align="center">{t("values_input.enteredBy")}</TableCell>
                        <TableCell align="center">{t("values_input.enteredAt")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.values.map((v: InputValueSimpleDTO) => (
                        <TableRow
                            key={v.id}
                            selected={v.id === props.newValue?.id}
                            className={isInvalid(v) ? classes.invalidRow : classes.validRow}
                        >
                            <TableCell align="left">
                                {isInvalid(v) ? (
                                    invalidIndicator
                                ) : (
                                    <InputValueInvalidator
                                        task={props.task}
                                        valueFn={props.valueFn}
                                        value={v}
                                        onInvalidation={handleInvalidate}
                                    />
                                )}
                            </TableCell>
                            <TableCell align="right">{DateUtils.dateString(v.gatheredAt)}</TableCell>
                            <TableCell align="right">{props.valueFn(v)}</TableCell>
                            <TableCell align="left">{v.note ?? ""}</TableCell>
                            <TableCell align="right">{makeFullName(v.person)}</TableCell>
                            <TableCell align="right">{DateUtils.datetimeString(v.createdAt)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

interface InputValueHistoryProps {
    task: InputVariableTaskDTO;
    newValue?: InputValueDTO;
}

interface InputValueHistoryState {
    name: "loading" | "loaded" | "failed";
    history: InputValueHistoryDTO | null;
    pageNumber: number;
    pageSize: number;
    newValue?: InputValueDTO;
    unitUUID: string | undefined;
    sinceDate: string | undefined;
    untilDate: string | undefined;
    validOnly: boolean;
}

function filterValues(
    history: InputValueHistoryDTO,
    sinceDate: string | undefined,
    untilDate: string | undefined,
    validOnly: boolean
): InputValueSimpleDTO[] {
    const filterFn = (v: InputValueSimpleDTO) => {
        if (sinceDate && v.gatheredAt < sinceDate) {
            return false;
        }
        if (untilDate && v.gatheredAt > untilDate) {
            return false;
        }
        return !(validOnly && v.state !== "valid");
    };
    return history.values.filter(filterFn);
}

export const InputValueHistory: React.FC<InputValueHistoryProps> = (props: InputValueHistoryProps) => {
    // TODO: chart of value (need units selection first)
    //== Init =================================================================
    const initState: InputValueHistoryState = {
        name: "loading",
        pageNumber: 0,
        pageSize: 5,
        history: null,
        newValue: props.newValue,
        unitUUID: undefined,
        sinceDate: undefined,
        untilDate: undefined,
        validOnly: true,
    };
    const [state, setState] = React.useState(initState);
    const {keycloak, initialized} = useKeycloak();
    const [t] = useTranslation(["values"]);
    const hasOptions = props.task.inputVariable.options.length > 0;
    const optionsMap = new Map(props.task.inputVariable.options.map((o) => [o.value.value, o.name]));
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            const id = props.task.municipalityInputVariableUUID;
            let url = `/values-input/history/${id}`;
            if (state.unitUUID) {
                url = url + `?unit=${state.unitUUID}`;
            }
            API.get(url, createApiConfig(keycloak, initialized))
                .then((res: AxiosResponse<InputValueHistoryDTO>) => {
                    setState({
                        ...state,
                        name: "loaded",
                        history: res.data,
                        unitUUID: res.data.unit == null ? undefined : res.data.unit?.id,
                    });
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [props, state, setState, keycloak, initialized]);
    //== Helpers ==============================================================
    const unit = state.history?.unit ?? null;
    const displayValue = (v: InputValueSimpleDTO): string => {
        if (hasOptions) {
            return optionsMap.get(v.value) ?? ValueUtils.formatValueUnit(v.value, unit);
        }
        return ValueUtils.formatValueUnit(v.value, unit);
    };
    const csvConvert = (v: InputValueSimpleDTO): string[] => {
        return [
            DateUtils.dateString(v.gatheredAt),
            v.value.toString(),
            v.note ?? "",
            unit?.abbreviation ?? "",
            makeFullName(v.person),
            DateUtils.datetimeString(v.createdAt),
            t(`values_input.export.validity.${v.state}`),
        ];
    };
    const csvHeaders = [
        t("values_input.export.gatheredAt"),
        t("values_input.export.value"),
        t("values_input.export.note"),
        t("values_input.export.unit_name"),
        t("values_input.export.person"),
        t("values_input.export.createdAt"),
        t("values_input.export.valid"),
    ];
    //== Handlers =============================================================
    console.log(`UNIT UUID = ${state.unitUUID}`);
    const values =
        state.history == null ? [] : filterValues(state.history, state.sinceDate, state.untilDate, state.validOnly);
    const handleChangePage = (event: unknown, newPage: number) => {
        setState({...state, pageNumber: newPage});
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newSize = parseInt(event.target.value, 10);
        setState({...state, pageSize: newSize, pageNumber: 0});
    };
    const handleDownload = (): void => {
        // TODO: better filename?
        const csv = CSVUtils.generateCSV(values, csvConvert, csvHeaders);
        Utils.download("export.csv", "text/csv;charset=utf-8", [csv]).click();
    };
    const handleSinceUntilChange = (since: string | undefined, until: string | undefined): void => {
        setState({...state, sinceDate: since, untilDate: until});
    };
    const handleUnitChange = (unitUUID: string): void => {
        setState({...state, unitUUID: unitUUID, name: "loading"});
    };
    const handleValidOnlyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({...state, validOnly: event.target.checked});
    };
    //== Render ===============================================================
    if (state.name === "failed") {
        return (
            <Box style={{margin: "1em"}}>
                <Alert severity="error">{t("values_input.errors.history")}</Alert>
            </Box>
        );
    }
    if (state.name === "loading" || state.history == null) {
        return (
            <Box style={{margin: "1em"}}>
                <Loading />
            </Box>
        );
    }
    const isFiltered = state.sinceDate !== undefined || state.untilDate !== undefined;
    const actPage = Utils.createPage(values, state.pageNumber, state.pageSize);
    return (
        <Box style={{margin: "1em"}}>
            <Box display="flex" alignItems="flex-start">
                <Box display="flex" flexDirection="column" alignItems="center">
                    <FormControlLabel
                        control={
                            <Checkbox checked={state.validOnly} onChange={handleValidOnlyChange} color="secondary" />
                        }
                        label={t("indicator.validOnly")}
                    />
                    <SinceUntilPicker
                        since={state.sinceDate}
                        until={state.untilDate}
                        onChange={handleSinceUntilChange}
                    />
                </Box>
                {state.unitUUID !== undefined && (
                    <Box style={{marginLeft: "1em"}}>
                        <UnitsSelect
                            units={state.history?.possibleUnits}
                            unitUUID={state.unitUUID}
                            onChange={handleUnitChange}
                        />
                    </Box>
                )}
                <Box flexGrow={1} justifyContent="flex-end" display="flex">
                    <CustomActionButton
                        title={t("indicator.download_csv")}
                        icon={<SaveAltIcon />}
                        onClick={handleDownload}
                    />
                </Box>
            </Box>
            {values.length > 0 ? (
                <Container maxWidth="lg" style={{marginTop: "1em"}}>
                    <InputValueHistoryTable
                        task={props.task}
                        valueFn={displayValue}
                        values={actPage.content}
                        options={hasOptions ? optionsMap : undefined}
                        newValue={state.newValue}
                    />
                    <DataTablePagination
                        page={actPage}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Container>
            ) : (
                <Container maxWidth="lg" style={{margin: "1em"}}>
                    <Alert severity="info">
                        {isFiltered ? t("values_input.no_values_date") : t("values_input.no_values")}
                    </Alert>
                </Container>
            )}
        </Box>
    );
};
