/* eslint-disable @typescript-eslint/ban-ts-comment */
export type SortDirection = "ascending" | "descending";
export type Comparator<T> = (a: T, b: T) => number;

const CZECH_CHARS = ":-_,.!? 0123456789aábcčdďeéěfghiíjklľmnňoópqrřsštťuúůvwxyýzž";
const CHAR_ORDER = {};

for (const i in CZECH_CHARS.split("")) {
    // @ts-ignore
    CHAR_ORDER[CZECH_CHARS[i]] = parseInt(i);
}

function stringCompare(a: string, b: string): number {
    const s1 = a.toLowerCase().replace(/ch/g, "hzz").toLowerCase();
    const s2 = b.toLowerCase().replace(/ch/g, "hzz").toLowerCase();

    let idx = 0;
    // @ts-ignore
    while (idx < s1.length && idx < s2.length && CHAR_ORDER[s1[idx]] == CHAR_ORDER[s2[idx]]) {
        idx++;
    }
    if (idx == s1.length && idx == s2.length) return 0;
    if (idx == s1.length) return 1;
    if (idx == s2.length) return -1;
    // @ts-ignore
    return CHAR_ORDER[s1[idx]] > CHAR_ORDER[s2[idx]] ? 1 : CHAR_ORDER[s1[idx]] < CHAR_ORDER[s2[idx]] ? -1 : 0;
}

export function comparator<T>(x: T, y: T): number {
    if (typeof x === "string" && typeof y === "string") {
        return stringCompare(x, y);
    }
    if (x < y) {
        return -1;
    } else if (y < x) {
        return 1;
    }
    return 0;
}

export function compareByAttr<T>(attr: string, dir: SortDirection): Comparator<T> {
    if (dir === "ascending") {
        // @ts-ignore
        return (a: T, b: T) => comparator(a[attr], b[attr]);
    } else {
        // @ts-ignore
        return (a: T, b: T) => -1 * comparator(a[attr], b[attr]);
    }
}
