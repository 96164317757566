import {ReactChild, ReactPortal} from "react";
import ManualChapter from "./ManualChapter";
import {ManualSection} from "./ManualSection";
import {Manual} from "./Manual";

export interface ManualChapter {
    key: string;
    title: string;
    intro?: ReactChild | ReactPortal;
    index?: number;
    manual?: Manual;
    sections: Map<string, ManualSection>;
    requiresAdmin: boolean; //note that it just hides the chapter, but is still accessible in code
}

interface ManualChapterProps {
    key: string;
    title: string;
    intro?: ReactChild | ReactPortal;
    sections?: ManualSection[];
    requiresAdmin: boolean; //note that it just hides the chapter, but is still accessible in code
}

export const manualChapter = (props: ManualChapterProps): ManualChapter => {
    const chapter = {
        key: props.key,
        title: props.title,
        intro: props.intro || undefined,
        sections: new Map(),
        requiresAdmin: props.requiresAdmin,
    };
    chapter.sections = new Map(
        (props.sections || []).map((section, index) => {
            section.index = index + 1;
            section.chapter = chapter;
            return [section.key, section];
        })
    );
    return chapter;
};

export default manualChapter;
