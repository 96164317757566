import React from "react";
import {Redirect, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useKeycloak} from "@react-keycloak/web";
// Project imports
import CMSPageForm from "./CMSPageForm";
import Loading from "../common/Loading";
import {WebpageDTO} from "../../models/admin";
import Utils, {API, createApiConfig} from "../../utils";
import {AxiosError} from "axios";
// Material-UI imports
import {Box} from "@material-ui/core";
import {BackLinkActionButton, DeleteActionButton, VisitLinkActionButton} from "../common/buttons";
import ROUTES from "../../routes/routes";
import {ConfirmationDialog} from "../common/dialogs";
import {NotFound} from "../common/errors";
import {ErrorNotification, SuccessNotification} from "../common/notifications";

interface WebpageParams {
    webpageId: string;
}

export const CMSPageEdit: React.FC = () => {
    const [t] = useTranslation("webpages");
    const {keycloak, initialized} = useKeycloak();
    const {webpageId} = useParams<WebpageParams>();
    const emptyWebpage: WebpageDTO = {
        publicId: "",
        title: "",
        description: "",
        keywords: "",
        content: "",
        author: null,
        tags: [],
    };
    const [state, setState] = React.useState({
        saved: emptyWebpage,
        webpage: emptyWebpage,
        name: "loading",
        action: "none",
    });
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<WebpageDTO>(`/cms/webpages/${webpageId}`, createApiConfig(keycloak, initialized))
                .then((res) => {
                    setState({...state, saved: res.data, webpage: res.data, name: "loaded"});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
        if (state.name === "saving") {
            API.put<WebpageDTO>(
                `/cms/webpages/${state.webpage.id}`,
                state.webpage,
                createApiConfig(keycloak, initialized)
            )
                .then((res) => {
                    setState({...state, saved: res.data, webpage: res.data, name: "saved"});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
        if (state.name === "deleting") {
            API.delete<void>(`/cms/webpages/${state.webpage.id}`, createApiConfig(keycloak, initialized))
                .then(() => {
                    setState({...state, name: "deleted", action: "none"});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [keycloak, initialized, state, setState, webpageId]);
    //== Handlers =============================================================
    const isNotChanged = Utils.objEq(state.saved, state.webpage, [
        "publicId",
        "title",
        "description",
        "keywords",
        "content",
        "tags",
    ]);
    const handleChange = (webpage: WebpageDTO): void => {
        setState({...state, webpage: webpage});
    };
    const handleSubmit = (): void => {
        if (!isNotChanged) {
            setState({...state, name: "saving", action: "edit"});
        }
    };
    const handlePreDelete = (): void => {
        setState({...state, name: "confirm_delete"});
    };
    const handleNoDelete = (): void => {
        setState({...state, name: "loaded", action: "none"});
    };
    const handleDelete = (): void => {
        setState({...state, name: "deleting", action: "delete"});
    };
    //== Render ===============================================================
    let appendix: JSX.Element | null = null;
    if (state.name === "loading") {
        return <Loading />;
    }
    if (state.name === "failed") {
        if (state.action === "none") {
            return <NotFound />;
        }
        appendix = <ErrorNotification message={t(`notifications.${state.action}_fail`)} />;
    }
    if (state.name === "saved") {
        appendix = <SuccessNotification message={t(`notifications.edit_ok`)} />;
    }
    if (state.name === "deleted") {
        return <Redirect to={ROUTES.cms.path} />;
    }
    if (state.name === "confirm_delete") {
        appendix = (
            <ConfirmationDialog
                title={t("confirm_delete.title")}
                text={t("confirm_delete.text", {title: state.webpage.title})}
                onAgree={handleDelete}
                onDisagree={handleNoDelete}
                open={true}
                id={"confirm-delete-dialog-" + webpageId}
            />
        );
    }
    return (
        <Box>
            <Box style={{clear: "both"}}>
                <Box style={{float: "left"}}>
                    <h1>{t("admin.edit")}</h1>
                </Box>
                <Box style={{float: "right"}}>
                    <BackLinkActionButton to={ROUTES.cms.path} />
                    <DeleteActionButton onClick={handlePreDelete} />
                    <VisitLinkActionButton to={ROUTES.cmsPageView.create(webpageId ?? "")} />
                </Box>
            </Box>
            <CMSPageForm webpage={state.webpage} onChange={handleChange} onSubmit={handleSubmit} />
            {appendix}
        </Box>
    );
};

export default CMSPageEdit;
