import React from "react";
import {List, ListItem, ListItemIcon} from "@material-ui/core";
import {ArrowForward} from "@material-ui/icons";
import TargetGroupsImage from "./img/target-groups.png";
import manualSection, {ManualSection} from "../../model/ManualSection";
import manualChapter, {ManualChapter} from "../../model/ManualChapter";

export const csIntroductionMotivationSection: ManualSection = manualSection({
    key: "koncept",
    title: "Základní myšlenky Smart City Compass",
    intro: (
        <>
            <div
                style={{
                    marginTop: "3em",
                    marginBottom: "3em",
                    width: "100%",
                    textAlign: "center",
                }}
            >
                <div style={{}}>
                    <em>„Když něco nemůžete změřit, nemůžete to ani řídit.“</em>
                </div>
                <div
                    style={{
                        marginLeft: "auto",
                        marginTop: "2em",
                    }}
                >
                    <div
                        style={{
                            display: "inline-block",
                            width: "8em",
                        }}
                    />
                    <div
                        style={{
                            display: "inline-block",
                            marginLeft: "auto",
                        }}
                    >
                        <div
                            style={{
                                display: "block",
                                marginLeft: "auto",
                            }}
                        >
                            <div
                                style={{
                                    display: "inline-block",
                                    width: "8em",
                                }}
                            />
                            <div
                                style={{
                                    display: "inline-block",
                                    marginLeft: "auto",
                                }}
                            >
                                Peter Drucker
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p>Hlavním cílem nástroje je podpořit řízení založené na faktech a datech (tzv. evidence-based policy).</p>
            <p>
                Dlouholetá spolupráce ČVUT UCEEB s českými obcemi ukázala na několik okruhů problémů řízení, které se
                pravidelně opakují. Vyřešení těchto problémů představuje klíčové potřeby, na které Smart City Compass
                reaguje. Následující grafika názorně představuje problémy, se kterými se obce při řízení potýkají, a
                jejich řešení, které Smart City Compass nabízí:
            </p>
            <ul>
                <li>
                    Větší množství strategií s neprovázanými cíli a indikátory
                    <p>
                        <em>
                            Strategie existují jako samostatné dokumenty, které žijí vlastním životem. Cíle a indikátory
                            obsažené ve strategických dokumentech nejsou na stejném místě. Mezi jednotlivými cíli a
                            indikátory není často zřejmá vazba a časem se může vytratit účel, proč je daný indikátor
                            sledován.
                        </em>
                    </p>
                    <List style={{marginTop: 0, paddingTop: 0}}>
                        <ListItem style={{paddingLeft: 0, width: "auto"}}>
                            <ListItemIcon style={{minWidth: "3em"}}>
                                <ArrowForward />
                            </ListItemIcon>
                            Smart City Compass umožňuje převést strategické dokumenty do formy jednotných přehledů.
                            Struktura nástroje předpokládá navázání indikátorů na konkrétní cíl, a nao-pak vytvoření
                            indikátorů pro stanovené cíle.
                        </ListItem>
                    </List>
                </li>
                <li>
                    Nevyužívání poměrových indikátorů, které umožňují interpretovat rozvoj obce v kontextu
                    <p>
                        <em>
                            Volené indikátory lze obtížně posuzovat v kontextu. Nejsou sledovány v přepočtu na
                            obyva-tele, rozlohu nebo jinou referenční veličinu. V čase nebo mezi územními celky je
                            obtížné je-jich porovnání.
                        </em>
                    </p>
                    <List style={{marginTop: 0, paddingTop: 0}}>
                        <ListItem style={{paddingLeft: 0, width: "auto"}}>
                            <ListItemIcon style={{minWidth: "3em"}}>
                                <ArrowForward />
                            </ListItemIcon>
                            Smart City Compass nabízí základní sadu poměrových indikátorů, které zohledňují kon-textové
                            parametry obce a umožňují porovnatelnost v čase i mezi městy.
                        </ListItem>
                    </List>
                </li>
                <li>
                    Nekonzistentní časové řady
                    <p>
                        <em>
                            Metodika výpočtu není nijak zakotvena v dokumentaci a je ponecháno na konkrétních
                            pra-covnících, jak ke stanovení indikátoru přistoupí. Při změně metodiky může být problémem
                            odhalit změny v konkrétním způsobu výpočtu a ve výsledku dochází k neporovnatelnosti hodnot
                            indikátorů v čase.
                        </em>
                    </p>
                    <List style={{marginTop: 0, paddingTop: 0}}>
                        <ListItem style={{paddingLeft: 0, width: "auto"}}>
                            <ListItemIcon style={{minWidth: "3em"}}>
                                <ArrowForward />
                            </ListItemIcon>
                            Smart City Compass vyžaduje pro definované indikátory jednotnou metodiku výpočtu, aby byla
                            zajištěna konzistence v časových řadách.
                        </ListItem>
                    </List>
                </li>
                <li>
                    Nestandardizované přehledové tabulky
                    <p>
                        <em>
                            Běžným jevem je existence různých formátů a struktur tabulek, kterými disponují různé
                            od-bory města.
                        </em>
                    </p>
                    <List style={{marginTop: 0, paddingTop: 0}}>
                        <ListItem style={{paddingLeft: 0, width: "auto"}}>
                            <ListItemIcon style={{minWidth: "3em"}}>
                                <ArrowForward />
                            </ListItemIcon>
                            Smart City Compass má definovanou strukturu a umožňuje export do csv.
                        </ListItem>
                    </List>
                </li>
                <li>
                    Nedostupnost důležitých dat a informací online
                    <p>
                        <em>
                            V některých případech jsou data a informace dostupná jen v listinné podobě, případně na
                            lokálních discích pracovníků. I v případě využívání online úložišť jsou data a informace
                            po-třebné pro výpočet indikátorů ojediněle rozptýlena mezi více interních a externích
                            serverů.
                        </em>
                    </p>
                    <List style={{marginTop: 0, paddingTop: 0}}>
                        <ListItem style={{paddingLeft: 0, width: "auto"}}>
                            <ListItemIcon style={{minWidth: "3em"}}>
                                <ArrowForward />
                            </ListItemIcon>
                            Smart City Compass je umístěn na bezpečných zálohovaných serverech, které zajišťují
                            spolehlivou dostupnost online prostřednictvím jednoho přístupu.
                        </ListItem>
                    </List>
                </li>
                <li>
                    Nevyjasněné role s ohledem na sběr a zadávání dat a informací a „privatizace“ datasetů ze strany
                    dílčích částí úřadu
                    <p>
                        <em>
                            Mezi pracovníky úřadu mohou existovat „bílá místa“ z pohledu rolí a odpovědnosti za sběr dat
                            a informací pro sledování. Ve výsledku pak mohou některá data chybět nebo postrádat
                            strukturu. V ojedinělých případech dochází ke vzájemné nekomunikaci a někteří pracovníci
                            dále neposkytují relevantní data a informace z lokálních úložišť kolegům.
                        </em>
                    </p>
                    <List style={{marginTop: 0, paddingTop: 0}}>
                        <ListItem style={{paddingLeft: 0, width: "auto"}}>
                            <ListItemIcon style={{minWidth: "3em"}}>
                                <ArrowForward />
                            </ListItemIcon>
                            Smart City Compass umožňuje řídícímu pracovníkovi jasně přidělit role a oprávnění ko-legům,
                            čímž usnadňuje vyjasnění kompetencí za sběr potřebných dat.
                        </ListItem>
                    </List>
                </li>
                <li>
                    Nepublikování dosažených hodnot indikátorů veřejnosti
                    <p>
                        <em>
                            Obce si chtějí z pochopitelných důvodů ponechat kontrolu nad tím, kdy a jaká data publikují
                            veřejnosti. Hodnoty indikátorů, které nemají citlivou povahu a mohou přinášet užitek
                            veřej-nosti a partnerům, však často rovněž nejsou publikovány, a to například z důvodu
                            doda-tečné zátěže při jejich zveřejňování na webu města nebo chybějící vnitřní metodiky pro
                            jejich publikaci.
                        </em>
                    </p>
                    <List style={{marginTop: 0, paddingTop: 0}}>
                        <ListItem style={{paddingLeft: 0, width: "auto"}}>
                            <ListItemIcon style={{minWidth: "3em"}}>
                                <ArrowForward />
                            </ListItemIcon>
                            Smart City Compass umožňuje jednoduše zvolit, které hodnoty mají být veřejně publi-kovány.
                            Volba je na úrovni konkrétního indikátoru, dává tedy obci svobodu volby.
                        </ListItem>
                    </List>
                </li>
                <li>
                    Nedodržování standardů otevřených dat
                    <p>
                        <em>
                            Stále relativně nízké povědomí obcí o standardech otevřených dat vede často k nižší
                            využi-telnosti zveřejňovaných datasetů.
                        </em>
                    </p>
                    <List style={{marginTop: 0, paddingTop: 0}}>
                        <ListItem style={{paddingLeft: 0, width: "auto"}}>
                            <ListItemIcon style={{minWidth: "3em"}}>
                                <ArrowForward />
                            </ListItemIcon>
                            Smart City Compass umožňuje export ve standardu Open Data ***.
                        </ListItem>
                    </List>
                </li>
            </ul>
        </>
    ),
});
export const csIntroductionTargetGroupSection: ManualSection = manualSection({
    key: "cilova-skupina",
    title: "Komu je Smart City Compass určen",
    intro: (
        <>
            <p>
                Smart City Compass je určen několika skupinám uživatelů: (1) vedením a řídícím pracovníkům obcí, (2)
                pracovníkům úřadu, (3) organizacím poskytujícím podporu obcím, (4) veřejnosti a partnerům obcí. Každé
                skupině přináší nástroj specifické možnosti.
            </p>
            <img
                style={{maxWidth: "100%"}}
                src={TargetGroupsImage}
                alt="Vedení obcí, pracovnící úřadu, veřejnost a další organizace"
            />
            <p>
                <strong>
                    Vedením obcí a&nbsp;řídícím pracovníkům, kteří jsou zodpovědní za&nbsp;strategický&nbsp;rozvoj obce
                </strong>
                ,&nbsp;Smart City Compass umožňuje či nabízí:
            </p>
            <ul>
                <li>Stanovení a sledování kvantifikovaných cílů města&nbsp;</li>
                <li>Podporu informovaného rozhodování na základě přehledných dat&nbsp;</li>
                <li>Analýzu dat o dlouhodobém rozvoji obce&nbsp;</li>
                <li>Sdílení informací a dat s&#8239;veřejností a s&#8239;partnery&nbsp;</li>
            </ul>
            <p>
                <strong>Pracovníkům úřadu</strong>,&nbsp;Smart City Compass umožňuje či nabízí:
            </p>
            <ul>
                <li>Sdílení vybraných dat a ukazatelů mezi odbory, s řídícími pracovníky a s dalšími partnery</li>
                <li>Sledování dopadů projektů a opatření na celkové ukazatele obce</li>
                <li>Vzájemnou inspiraci s&nbsp;dalšími městy</li>
            </ul>
            <p>
                <strong>Organizacím, které poskytují podporu obcím při strategickém řízení</strong>,&nbsp;Smart City
                Compass umožňuje či nabízí:
            </p>
            <ul>
                <li>Tvorbu indikátorů na míru obcím</li>
                <li>Nastavení jednotné struktury zjednodušující práci napříč obcemi</li>
            </ul>
            <p>
                <strong>Veřejnosti a partnerům obce</strong>,&nbsp;Smart City Compass umožňuje či nabízí:
            </p>
            <ul>
                <li>Prohlížení indikátorů obcí v&nbsp;přehledné formě</li>
                <li>Možnost vlastního posouzení atraktivity obce pro bydlení a investice</li>
            </ul>
        </>
    ),
});
export const csIntroductionAdvantagesSection: ManualSection = manualSection({
    key: "vyhody",
    title: "Výhody nástroje",
    intro: (
        <>
            <p>
                Kromě toho, že Smart City Compass reaguje na výše popsané potřeby obcí, přináší také několik klíčových
                funkcí:
            </p>
            <ul>
                <li>Rostoucí výběr předdefinovaných indikátorů ověřených&nbsp;experty</li>
                <li>Možnost úpravy strukturovaných indikátorových sad na míru potřebám obce</li>
                <li>Grafickou vizualizaci naplňování cílů</li>
                <li>Možnost procházení&nbsp;publikovaných indikátorů&nbsp;jiných měst&nbsp;</li>
            </ul>
        </>
    ),
});

export const csIntroductionManualChapter: ManualChapter = manualChapter({
    key: "uvod",
    title: "Úvod",
    intro: (
        <>
            <p>
                <em>Smart City Compass</em> je online nástroj pro podporu plánování a rozhodování obcí. Nástroj reaguje
                na potřebu systematicky pracovat s daty, jejichž sledování je klíčem k rozvoji udržitelného chytrého
                města. Umožňuje obcím zorientovat se v základních cílech a principech udržitelných chytrých měst, využít
                je pro vlastní plánování a začít s jejich vyhodnocováním. To vše nabízí v uživatelsky přívětivé a
                srozumitelné formě.
            </p>
            <ul>
                <li>
                    Nástroj nabízí obcím možnost <strong>sledování indikátorů městského</strong> rozvoje a{" "}
                    <strong>stanovení cílových hodnot</strong> pro libovolné období.
                </li>
                <li>
                    Nástroj <strong>předchází dvojí práci</strong> při zadávání a správě dat a{" "}
                    <strong>pomáhá sjednotit přístup k práci s indikátory</strong> uvnitř konkrétní obce i napříč obcemi
                    a organizacemi veřejné správy.
                </li>
                <li>
                    Nástroj nabízí <strong>základní sady indikátorů</strong> udržitelných chytrých měst, které budou
                    postupně doplňovány a rozvíjeny dle potřeb obcí.
                </li>
                <li>
                    Nástroj má <strong>záštitu Ministerstva pro místní rozvoj ČR</strong>, které bude nabízet obcím
                    metodickou podporu při jeho užívání a dále rozvíjet jeho funkce.
                </li>
                <li>
                    Do budoucna se připravuje rozšíření o další sady indikátorů a{" "}
                    <strong>o funkci pro vyhodnocování jednotlivých projektů</strong>.
                </li>
            </ul>
            <p>
                Místním samosprávám je nástroj <em>Smart City Compass</em> poskytován bezplatně v rámci podpory
                Ministerstva pro místní rozvoj ČR, pro které byl vyvinut Uni-verzitním centrem energeticky efektivních
                budov ČVUT (ČVUT UCEEB) a Fakultou informačních technologií ČVUT (ČVUT FIT). Vývoj nástroje byl
                financován Technologickou agenturou České re-publiky, programem Zéta.
            </p>
            <p>
                Nástroj nenahrazuje stávající softwarová řešení využívaná pro strategické plánování nebo projek-tový
                management. Představuje dosud chybějící doplněk, který umožňuje obcím systematicky sle-dovat a hodnotit
                naplňování nadřazených cílů udržitelných chytrých měst. Tyto cíle a jejich indikáto-ry vznikly a jsou
                průběžně doplňovány ve spolupráci s experty na danou problematiku.
            </p>
        </>
    ),
    sections: [csIntroductionMotivationSection, csIntroductionTargetGroupSection, csIntroductionAdvantagesSection],
    requiresAdmin: false,
});

export default csIntroductionManualChapter;
