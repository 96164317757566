import {createStyles, createTheme, makeStyles, Theme} from "@material-ui/core/styles";

export const defaultPalette = {
    primary: {
        main: "#007DB4",
    } /*
    primary: {
        main: "#bad1c7",
    },
    secondary: {
        main: "#78a399",
    },*/,
    background: {
        default: "#fff",
    },
};

export const lightTheme = createTheme({
    palette: defaultPalette,
});

export const darkTheme = createTheme({
    palette: {
        type: "dark",
        primary: {
            main: "#007DB4",
        } /*
        primary: {
            main: "#bad1c7",
        },
        secondary: {
            main: "#78a399",
        },*/,
    },
});

const muiRteStyle = {
    root: {
        height: "100%",
    },
    container: {
        height: "100%",
    },
    editor: {
        height: "100%",
        minHeight: "3em",
        maxHeight: "50vh",
        padding: "14px",
        overflow: "auto",
    },
    placeHolder: {
        height: "100%",
        padding: "14px",
    },
    toolbar: {
        borderBottomStyle: "solid",
        borderBottom: "1px",
        borderBottomColor: "rgba(0, 0, 0, 0.23)",
    },
};

const commonOverrides = {
    MUIRichTextEditor: muiRteStyle,
    MuiDialog: {
        paperScrollPaper: {
            overflowY: "hidden",
        },
    },
};

Object.assign(lightTheme, {
    overrides: commonOverrides,
});
Object.assign(darkTheme, {
    overrides: commonOverrides,
});

export const THEMES = {
    light: lightTheme,
    dark: darkTheme,
};

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        centerFlex: {
            display: "flex",
            justifyContent: "center",
        },
        radioGroup: {
            display: "flex",
        },
        fullWidth: {
            width: "100% !important",
        },
        spaceAfter: {
            marginBottom: "1em !important",
        },
        spaceBefore: {
            marginTop: "1em !important",
        },
        spaceAround: {
            margin: "1em !important",
        },
        spaceBeforeAfter: {
            marginTop: "1em !important",
            marginBottom: "1em !important",
        },
        narrow: {
            maxWidth: "700px !important",
            margin: "0 auto !important",
        },
        minTableCell: {
            width: "1% !important",
            whiteSpace: "nowrap",
        },
        deletedRowOrCell: {
            height: 0,
            padding: 0,
            position: "absolute",
            borderBottom: "none",
        },
        hidden: {
            display: "none",
        },
        strikeThrough: {
            textDecoration: "line-through",
            color: theme.palette.grey.A400,
        },
        clickable: {
            cursor: "pointer",
        },
        fabWrapper: {
            float: "right",
            marginTop: "1em",
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: "#fff",
        },
        floatLeft: {
            float: "left",
        },
        floatRight: {
            float: "right",
        },
        wrapperClear: {
            clear: "both",
        },
        icon: {
            marginRight: theme.spacing(1),
            width: 24,
            height: 24,
        },
    })
);
