import React from "react";
import {LibraryDomainUseDTO, LibraryDTO, LibraryGoalUseDTO} from "../../../models/library";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";
import {ExpandLessActionButton, ExpandMoreActionButton, InfoActionButton} from "../../common/buttons";
import {GoalDialog} from "../../values/dialogs/GoalDialog";
import {compareByAttr} from "../../../utils/compare";
import {LibraryIndicatorBrowser} from "./LibraryIndicatorBrowser";

interface LibraryGoalBrowserProps {
    library: LibraryDTO;
    libraryDomainUse: LibraryDomainUseDTO;
    libraryGoalUse: LibraryGoalUseDTO;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        goalRow: {
            borderWidth: 0,
            borderStyle: "solid",
            borderBottomWidth: 1,
            borderColor: "rgb(0,125,180)",
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(0.5),
            paddingLeft: theme.spacing(1),
            marginLeft: theme.spacing(2),
            display: "flex",
            alignItems: "center",
        },
        goalItemName: {
            marginLeft: theme.spacing(0.5),
            fontSize: "140%",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
        },
        infoButton: {
            flexGrow: 1,
        },
    })
);

export const LibraryGoalBrowser: React.FC<LibraryGoalBrowserProps> = (props: LibraryGoalBrowserProps) => {
    //== Init =================================================================
    const classes = useStyles();
    const [infoOpen, setInfoOpen] = React.useState(false);
    const [childOpen, setChildOpen] = React.useState(false);
    //== Handlers =============================================================
    const handleInfoOpen = () => {
        setInfoOpen(true);
    };
    const handleInfoClose = () => {
        setInfoOpen(false);
    };
    const handleChildOpen = () => {
        setChildOpen(true);
    };
    const handleChildClose = () => {
        setChildOpen(false);
    };
    //== Render ===============================================================
    return (
        <Box>
            <Box className={classes.goalRow}>
                <Box>
                    {childOpen ? (
                        <ExpandLessActionButton onClick={handleChildClose} />
                    ) : (
                        <ExpandMoreActionButton onClick={handleChildOpen} />
                    )}
                </Box>
                <Box className={classes.goalItemName}>{props.libraryGoalUse.goal.name}</Box>
                <Box className={classes.infoButton}>
                    <InfoActionButton onClick={handleInfoOpen} />
                </Box>
                <GoalDialog goal={props.libraryGoalUse.goal} handleClose={handleInfoClose} open={infoOpen} />
            </Box>
            {childOpen && (
                <Box>
                    {props.libraryGoalUse.libraryIndicatorUses
                        .sort(compareByAttr("order", "ascending"))
                        .map((indicator) => (
                            <LibraryIndicatorBrowser key={indicator.id} libraryIndicatorUse={indicator} {...props} />
                        ))}
                </Box>
            )}
        </Box>
    );
};
