import React from "react";
import {TableCell, TablePagination, TableRow} from "@material-ui/core";
import {useStyles} from "../../styles";
import {CopyButton} from "./buttons";
import {Page} from "../../models/pagination";
import {useTranslation} from "react-i18next";

interface BasicInfoRowProps {
    title: string;
    value?: string;
    copyable?: boolean;
}

export const BasicInfoRow: React.FC<BasicInfoRowProps> = (props: BasicInfoRowProps) => {
    //== Init ===================================================================
    const classes = useStyles();
    //== Render =================================================================
    let value = <TableCell />;
    if (props.value !== undefined) {
        value = (
            <TableCell>
                {props.value}
                {props.copyable && <CopyButton text={props.value} size="small" />}
            </TableCell>
        );
    }
    return (
        <TableRow>
            <TableCell component="th" scope="row" className={classes.minTableCell}>
                {props.title}
            </TableCell>
            {value}
        </TableRow>
    );
};

interface DataTablePaginationProps {
    rowsOptions?: number[];
    page: Page<unknown>;
    onChangePage: (event: unknown, newPage: number) => void;
    onChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const DataTablePagination: React.FC<DataTablePaginationProps> = (props: DataTablePaginationProps) => {
    const [t] = useTranslation(["common"]);
    const defaultRowOptions = [5, 10, 50, 100];
    return (
        <TablePagination
            rowsPerPageOptions={props.rowsOptions ?? defaultRowOptions}
            component="div"
            count={props.page.totalElements}
            rowsPerPage={props.page.size}
            page={props.page.number}
            onPageChange={props.onChangePage}
            onRowsPerPageChange={props.onChangeRowsPerPage}
            backIconButtonText={t("pagination.back")}
            nextIconButtonText={t("pagination.next")}
            labelRowsPerPage={t("pagination.rowsPerPage")}
            labelDisplayedRows={({from, to, count}) => `${from}-${to} ${t("pagination.of")} ${count}`}
        />
    );
};
