import React from "react";
import {useTranslation} from "react-i18next";
import ROUTES from "../../routes/routes";
import {Redirect} from "react-router";
// Material-UI imports
import {Container} from "@material-ui/core";
import {BreadcrumbItem, BreadcrumbsRow} from "../common/breadcrumbs";
import {PageHeader} from "../common/headers";
import {emptyLibraryCreateDTO, LibraryCreateDTO, LibraryDTO, toLibraryCreateDTO} from "../../models/library";
import {useParams} from "react-router-dom";
import {useKeycloak} from "@react-keycloak/web";
import {LoadingBackdrop} from "../common/Loading";
import {CommunicationError, NotFound} from "../common/errors";
import {API, createApiConfig} from "../../utils/API";
import {AxiosError, AxiosResponse} from "axios";
import {LibraryForm} from "./LibraryForm";
import {ErrorNotification} from "../common/notifications";

interface LibraryCloneState {
    library: LibraryDTO | null;
    newLibrary: LibraryCreateDTO;
    newId: string;
    name: "loading" | "loaded" | "failed" | "not_found" | "cloning" | "cloned";
}

interface LibraryParams {
    libraryId: string;
}

export const LibraryClone: React.FC = () => {
    // TODO: permissions
    //== Init =================================================================
    const [t] = useTranslation("libraries");
    const {libraryId} = useParams<LibraryParams>();
    const {keycloak, initialized} = useKeycloak();
    const initState: LibraryCloneState = {
        library: null,
        newLibrary: emptyLibraryCreateDTO,
        newId: "",
        name: "loading",
    };
    const [state, setState] = React.useState(initState);
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<LibraryDTO>(`/libraries/${libraryId}`, createApiConfig(keycloak, initialized))
                .then((res: AxiosResponse<LibraryDTO>) => {
                    const newLibrary: LibraryCreateDTO = {
                        ...toLibraryCreateDTO(res.data),
                        name: t("library.clone.cloneName", {name: res.data.name}),
                    };
                    setState({...state, library: res.data, newLibrary: newLibrary, name: "loaded"});
                })
                .catch((err: AxiosError) => {
                    if (err?.response?.status === 404) {
                        setState({...state, name: "not_found"});
                    } else {
                        setState({...state, name: "failed"});
                    }
                });
        } else if (state.name === "cloning") {
            API.post(`/libraries/${libraryId}/clone`, state.newLibrary, createApiConfig(keycloak, initialized))
                .then((res: AxiosResponse<LibraryDTO>) => {
                    setState({...state, newId: res.data.id, name: "cloned"});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [state, setState, libraryId, keycloak, initialized, t]);
    //== Handlers =============================================================
    const handleChange = (library: LibraryCreateDTO): void => {
        setState({...state, newLibrary: library});
    };
    const handleSubmit = (): void => {
        setState({...state, name: "cloning"});
    };
    //== Render ===============================================================
    if (state.name === "loading") {
        return <LoadingBackdrop />;
    }
    if (state.name === "not_found") {
        return <NotFound />;
    }
    if (state.name === "cloned") {
        return <Redirect to={ROUTES.library.create(state.newId)} />;
    }
    if (state.library !== null) {
        return (
            <Container>
                <BreadcrumbsRow>
                    <BreadcrumbItem name={t("library.titles.libraries")} route={ROUTES.libraries.path} />
                    <BreadcrumbItem name={state.library.name} route={ROUTES.library.create(state.library.id)} />
                    <BreadcrumbItem name={t("library.actions.clone")} />
                </BreadcrumbsRow>
                <PageHeader title={t("library.titles.clone_library")} />
                <p>{t("library.clone.explanation", {name: state.library.name})}</p>
                <LibraryForm
                    library={state.newLibrary}
                    onChange={handleChange}
                    onSubmit={handleSubmit}
                    allowOrganization
                />
                {state.name === "failed" && <ErrorNotification message={t("library.clone.failed")} />}
            </Container>
        );
    }
    return <CommunicationError />;
};

export default LibraryClone;
