import {OrganizationDTO, SimpleMunicipalityDTO} from "./organization";
import {InputVariableDTO, OptionDTO, UnitDTO, UnitSimpleDTO} from "./library";
import {PersonDTO} from "./user";

export type ValueState = "valid" | "invalid";

export interface InputVariableTaskDTO {
    municipalityInputVariableUUID: string;
    isUsedInGroup: boolean;
    inputVariable: InputVariableDTO;
    preferredUnit: UnitSimpleDTO | null;
    possibleUnits: UnitSimpleDTO[];
    options: OptionDTO[];
    lastGathered: string | null;
}

export interface ValuesInputTaskDTO {
    municipality: SimpleMunicipalityDTO;
    tasks: InputVariableTaskDTO[];
}

export interface InputValueResultDTO {
    value: InputValueDTO;
    relatedValues: ValueDTO[];
}

export interface InputValueCreateDTO {
    gatheredAt: string;
    value: number;
    unitUUID: string | null;
    note: string;
    municipalityInputVariableUUID: string;
}

export interface ValueSimpleDTO {
    value: number;
    unit: UnitSimpleDTO | null;
}

export interface ValueUnitDTO extends ValueSimpleDTO {
    unit: UnitSimpleDTO;
    preferred: boolean;
    base: boolean;
    original: boolean;
}

export interface InputValueDTO extends ValueSimpleDTO {
    id: string;
    createdAt: string;
    updatedAt: string;
    gatheredAt: string;
    state: ValueState;
    person: PersonDTO;
    note: string;
    municipalityInputVariableUUID: string;
}

export interface InputValueWithUnitsDTO extends ValueSimpleDTO {
    id: string;
    createdAt: string;
    updatedAt: string;
    gatheredAt: string;
    state: ValueState;
    values: ValueUnitDTO[];
    person: PersonDTO;
    note: string;
    municipalityInputVariableUUID: string;
}

export interface InputValueSimpleDTO {
    id: string;
    createdAt: string;
    updatedAt: string;
    gatheredAt: string;
    state: ValueState;
    value: number;
    person: PersonDTO;
    note: string;
}

export interface InputValueHistoryDTO {
    createdAt: string;
    unit: UnitSimpleDTO | null;
    possibleUnits: UnitSimpleDTO[];
    values: InputValueSimpleDTO[];
}

export interface TrackedIndicatorRecomputeAnswerDTO {
    taskUuid: string;
    newlyCreated: string;
    createdAt: string;
}

export interface TrackedIndicatorValueInfoDTO {
    trackedIndicatorId: string;
    hasCalculationTask: boolean;
    recomputeEligible: boolean;
    infoAt: string;
}

export interface TrackedIndicatorValueSimpleDTO {
    id: string;
    createdAt: string;
    updatedAt: string;
    relevantSince: string;
    state: ValueState;
    value: number;
}

export interface TrackedIndicatorHistoryDTO {
    info: TrackedIndicatorValueInfoDTO;
    unit: UnitSimpleDTO | null;
    possibleUnits: UnitSimpleDTO[];
    values: TrackedIndicatorValueSimpleDTO[];
    targetValue: number | null;
    indicatorMinimum: number | null;
    indicatorMaximum: number | null;
}

export interface IndicatorValueWithUnitsDTO extends ValueSimpleDTO {
    id: string;
    createdAt: string;
    updatedAt: string;
    relevantSince: string;
    state: ValueState;
    values: ValueUnitDTO[];
}

export type ValueTarget =
    | "option"
    | "contribution"
    | "computedValueProjectIndicatorUse"
    | "computedValueTrackedIndicator"
    | "goalValueProjectIndicatorUse"
    | "goalValueTrackedIndicator"
    | "maximumValueVariable"
    | "minimumValueVariable";

export interface ValueUpdateDTO {
    value: number;
    unitUUID: string | null;
}

export function valueToUpdate(value?: ValueDTO | ValueSimpleDTO | null): ValueUpdateDTO | null {
    console.log(value);
    if (value === null || value === undefined) return null;
    return {
        value: value.value,
        unitUUID: value.unit?.id ?? null,
    };
}

export function valueToSimple(value?: ValueDTO | null): ValueSimpleDTO | null {
    if (value === null || value === undefined) return null;
    return {
        value: value.value,
        unit: value.unit ? {id: value.unit.id, abbreviation: value.unit.abbreviation, name: value.unit.name} : null,
    };
}

export function unitFromValue(value: ValueSimpleDTO | ValueUpdateDTO | null): UnitSimpleDTO | undefined {
    if (!value) return undefined;
    const possibleSimpleUnit: UnitSimpleDTO | null | undefined = (value as ValueSimpleDTO).unit;
    const possibleUnitUUID: string | null | undefined = (value as ValueUpdateDTO).unitUUID;
    return possibleSimpleUnit || (possibleUnitUUID ? {abbreviation: "(?)", id: possibleUnitUUID, name: ""} : undefined);
}

export interface ValueCreateDTO {
    value: number;
    unit: UnitDTO | null;
    // VALUE USED AS:
    usedAs: ValueTarget;
    target: string;
}

export interface ValueDTO extends ValueSimpleDTO {
    id: string;
    unit: UnitDTO | null;
    // VALUE USED AS:
    usedAs?: string;
    target?: string;
    optionUUID?: string | null;
    contributionUUID?: string | null;
    computedValueProjectIndicatorUseUUID?: string | null;
    computedValueTrackedIndicatorUUID?: string | null;
    projectIndicatorUseUUID?: string | null;
    trackedIndicatorUUID?: string | null;
    maximumValueVariableUUID?: string | null;
    minimumValueVariableUUID?: string | null;
}

export interface InputValuesHistoryItemDTO {
    inputVariable: InputVariableDTO;
    value: InputValueDTO | null;
}

export interface InputValuesHistoryDTO {
    municipality: OrganizationDTO;
    desiredDate: string;
    items: InputValuesHistoryItemDTO[];
}
