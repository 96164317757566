import React from "react";
import manualSubSubsection, {ManualSubSubsection} from "../../model/ManualSubSubsection";
import manualSubsection, {ManualSubsection} from "../../model/ManualSubsection";
import manualSection, {ManualSection} from "../../model/ManualSection";
import manualChapter, {ManualChapter} from "../../model/ManualChapter";
import EquationSymbolImageX from "./img/math/x.png";
import EquationSymbolImageY from "./img/math/y.png";
import EquationSymbolImageXplusY from "./img/math/xplusy.png";
import EquationSymbolImageXminusY from "./img/math/xminusy.png";
import EquationSymbolImageXtimesY from "./img/math/xtimesy.png";
import EquationSymbolImageXdivY from "./img/math/xdivy.png";
import EquationSymbolImageLogX from "./img/math/logx.png";
import EquationSymbolXraisedToY from "./img/math/xraisedtoy.png";
import EquationSymbolXfloor from "./img/math/floorx.png";
import EquationSymbolXceil from "./img/math/ceilx.png";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SendIcon from "@material-ui/icons/Send";
import SaveIcon from "@material-ui/icons/Save";
import VpnKeyIcon from "@material-ui/icons/VpnKey";

const csOrganizationProfileEditSubsection: ManualSubsection = manualSubsection({
    key: "uprava",
    title: "Úprava profilových informací",
    intro: (
        <>
            <p>
                Do režimu úpravy profilových informací je možné se dostat použitím tlačítka „Upravit“ na profilu
                organizace. Pokud zde tlačítko nevidíte, ujistěte se, že máte v&nbsp;organizaci dostatečná oprávnění.
            </p>
            <p>
                V&nbsp;režimu úprav můžete nastavit popis organizace, změnit základní údaje jako IČO nebo nastavit
                kontaktní údaje.
            </p>
            <p>
                Po provedení úprav uložíte změny tlačítkem „Uložit“. Vrátit se bez uložení změn můžete tlačítkem
                „Zrušit“. Poté se dostanete zpět do režimu běžného prohlížení profilu a můžete zde zkontrolovat, jak
                provedené změny na profilu vypadají.
            </p>
            <p>Chcete změnit typ organizace na obec? Kontaktujte administrátora Smart City Compass.</p>
        </>
    ),
});

const csOrganizationProfileImagesSubsection: ManualSubsection = manualSubsection({
    key: "logo",
    title: "Logo organizace",
    intro: (
        <p>
            Logo můžete nahrát najetím myší na stávající logo (nebo ikonu Smart City Compass, pokud žádné logo zatím
            nahrané nemáte) a klepnutím na tlačítko, které se zobrazí uprostřed obrázku. Následně přetáhněte nový
            obrázek do dialogového okna nebo do něj klepněte a soubor vyberte v&nbsp;tradičním systémovém okně. Nakonec
            klikněte na „Potvrdit“.
        </p>
    ),
});

export const csOrganizationProfileSection: ManualSection = manualSection({
    key: "profil",
    title: "Profil organizace",
    intro: (
        <p>
            Profil organizace slouží nejen jako přehled informací pro návštěvníky, ale i k&nbsp;přechodu na další
            informace a nastavení organizace. Můžete zde přes tlačítko přejít na nabízené indikátorové sady a oprávnění
            členové organizace zde najdou tlačítka pro úpravu profilu a správu členů.
        </p>
    ),
    subsections: [csOrganizationProfileEditSubsection, csOrganizationProfileImagesSubsection],
});

const csOrganizationPositionsEditSubSubsection: ManualSubSubsection = manualSubSubsection({
    key: "uprava",
    title: "Přidání a úprava pozice",
    content: (
        <>
            <p>
                Pro vytvoření nové pozice použijte tlačítko&nbsp;
                <AddIcon style={{verticalAlign: "bottom"}} /> nebo pro upravení stávající pozice použijte ikonku&nbsp;
                <EditIcon style={{verticalAlign: "bottom"}} />u dané pozice. Tím se otevře dialogové okno
                s&nbsp;nastavením vlastností a zejména oprávnění dané pozice. Význam jednotlivých oprávnění vysvětluje
                následující část návodu.
            </p>
            <p>Po nastavení oprávnění potvrďte tlačítkem „Uložit“.</p>
        </>
    ),
});

const csOrganizationPositionsPermissionsSubSubsection: ManualSubSubsection = manualSubSubsection({
    key: "opravneni",
    title: "Význam oprávnění a možné hodnoty",
    content: (
        <>
            <p>Skrz pozice jsou nastavována různá oprávnění v&nbsp;rámci organizace. Tato oprávnění jsou:</p>
            <ul>
                <li>
                    Správce (včetně členů): Správce má plnou kontrolu nad organizací včetně přidávání a odebírání členů.
                    Může tedy upravit libovolné údaje, přidat nebo i odstranit nabízené indikátorové sady a jejich
                    obsah. Kromě toho může z&nbsp;organizace odstranit i jiné správce. Toto oprávnění nastavte v souladu
                    s vnitřními pravidly platnými ve vaší organizaci. Pokud zaškrtnete, že pozice má oprávnění správce,
                    všechny další položky oprávnění se automaticky nastaví na nejvyšší stupeň oprávnění a není možné je
                    měnit.
                </li>
                <li>
                    Profil organizace: Nastavuje oprávnění k&nbsp;úpravě profilových informací organizace. Možné hodnoty
                    jsou:
                    <ul>
                        <li>
                            Zobrazení (včetně neveřejných): Člen s&nbsp;touto pozicí může profil pouze zobrazit. Nemá
                            tedy žádná oprávnění (týkající se profilu) navíc oproti běžnému návštěvníkovi.
                        </li>
                        <li>Úpravy: Člen s&nbsp;touto pozicí může upravovat profilové informace organizace.</li>
                    </ul>
                </li>
                <li>
                    Vlastní indikátorové sady: Nastavuje oprávnění k&nbsp;zobrazení a&nbsp;vytváření vlastních
                    indikátorů nebo celých indikátorových sad a jejich zveřejňování. Možné hodnoty jsou:
                    <ul>
                        <li>
                            Zobrazení pouze veřejných: Člen s&nbsp;touto pozicí může vidět jen to, co běžný návštěvník.
                            Žádná oprávnění navíc tedy nemá.
                        </li>
                        <li>
                            Zobrazení (včetně neveřejných): Člen s&nbsp;touto pozicí může vidět nejen to, co běžný
                            návštěvník, ale i rozpracované a neveřejné indikátory a indikátorové sady. Nic z&nbsp;toho
                            však nemůže upravit.
                        </li>
                        <li>
                            Úpravy: Člen s&nbsp;touto pozicí může indikátory a indikátorové sady nejen zobrazit, ale i
                            upravovat, odstraňovat nebo vytvářet nové.
                        </li>
                    </ul>
                </li>
            </ul>
        </>
    ),
});

const csOrganizationPositionsRemovalSubSubsection: ManualSubSubsection = manualSubSubsection({
    key: "odstraneni",
    title: "Odstranění pozice",
    content: (
        <p>
            Pozici je možné odstranit kliknutím na ikonku&nbsp;
            <DeleteIcon style={{verticalAlign: "bottom"}} />. Odstranění je možné jen u nepoužívaných pozic. Pokud je
            pozice používaná, tj. přiřazená členovi nebo nabídnutá v&nbsp;pozvánce, odstranit ji není možné a ikonka
            koše bude zašedlá. V&nbsp;takovém případě je nutné nejdříve odebrat danou pozici stávajícím členům nebo
            odebrat samotné členy s&nbsp;touto pozicí, případně zkontrolovat, zda neexistuje pozvánka, která by tuto
            pozici nabízela a případně zrušit ji.
        </p>
    ),
});

const csOrganizationPositionsSubsection: ManualSubsection = manualSubsection({
    key: "pozice",
    title: "Pozice",
    intro: (
        <p>
            Na záložce „Pozice“ se nachází přehled relevantních pracovních pozic v&nbsp;organizaci a jejich oprávnění
            v&nbsp;rámci Smart City Compass. Je možné zde jednotlivé pozice upravit, odstranit nebo pomocí tlačítka{" "}
            <AddIcon style={{verticalAlign: "bottom"}} />
            přidat pozici novou.
        </p>
    ),
    subSubsections: [
        csOrganizationPositionsEditSubSubsection,
        csOrganizationPositionsPermissionsSubSubsection,
        csOrganizationPositionsRemovalSubSubsection,
    ],
});

const csOrganizationMembershipsInvitationSubSubsection: ManualSubSubsection = manualSubSubsection({
    key: "pozvanky",
    title: "Pozvání nového člena",
    content: (
        <>
            <p>
                Přidávání členů funguje na principu pozvánek. Před pozváním nového člena se ujistěte, že pro něj máte
                již vytvořenou odpovídající pozici na záložce „Pozice“.
            </p>
            <p>
                Pro odeslání pozvánky do organizace použijte řádek s&nbsp;textovými poli nad seznamem stávajících členů.
                Zde vyberte pozice a vyplňte e-mailovou adresu příjemce, případně volitelně příjemci napište zprávu,
                která mu pomůže se zorientovat v&nbsp;tom, proč tuto pozvánku posíláte. Nakonec odeslání potvrďte
                kliknutím na ikonu&nbsp;
                <SendIcon style={{verticalAlign: "bottom"}} /> vpravo.
            </p>
            <p>
                Tím dojde k&nbsp;odeslání pozvánky na e-mailovou adresu příjemce. Pokud již je registrovaný v Smart City
                Compass, uvidí na svém přehledu po přihlášení tuto pozvánku a bude tam mít možnost pozvánku přijmout
                nebo odmítnout. Pokud registrovaný není, bude nejprve v&nbsp;e-mailu vyzván k&nbsp;registraci.
            </p>
        </>
    ),
});

const csOrganizationMembershipsPositionsSubSubsection: ManualSubSubsection = manualSubSubsection({
    key: "pozice",
    title: "Volba pozic stávajícího člena",
    content: (
        <p>
            Pokud si přejete změnit pozici stávajícího člena, klikněte na ikonku&nbsp;
            <EditIcon style={{verticalAlign: "bottom"}} /> vpravo od řádku s&nbsp;daným členem. Po výběru pozic ze
            seznamu tyto pozice potvrďte kliknutím na ikonku&nbsp;
            <SaveIcon style={{verticalAlign: "bottom"}} />.
        </p>
    ),
});

const csOrganizationMembershipsRemovalSubSubsection: ManualSubSubsection = manualSubSubsection({
    key: "odebrani",
    title: "Odebrání člena organizace a opuštění organizace",
    content: (
        <p>
            Člena je možné odebrat kliknutím na ikonku&nbsp;
            <DeleteIcon style={{verticalAlign: "bottom"}} />. Stejným způsobem můžete odstranit i sebe a tím organizaci
            v&nbsp;rámci užívání nástroje opustit. Pozor, pokud odeberete sebe a tím organizaci v Smart City Compass
            opustíte a poté se budete chtít vrátit, bude vás muset znovu pozvat jiný člen s&nbsp;oprávněním správce.
            Navíc, pokud by došlo k&nbsp;opuštění organizace posledním správce organizace, ztratí organizace možnost
            přidávat členy a nastavovat oprávnění. V&nbsp;takovém případě by bylo nutné se obrátit na administrátora
            Smart City Compass.
        </p>
    ),
});

const csOrganizationMembershipsSubsection: ManualSubsection = manualSubsection({
    key: "clenove",
    title: "Členové",
    intro: (
        <p>
            Na záložce „Členové“ se nachází seznam členů organizace s&nbsp;jejich pozicemi a možnost členy odebírat,
            volit jejich pozice nebo odesílat pozvánky novým členům.
        </p>
    ),
    subSubsections: [
        csOrganizationMembershipsInvitationSubSubsection,
        csOrganizationMembershipsPositionsSubSubsection,
        csOrganizationMembershipsRemovalSubSubsection,
    ],
});

export const csOrganizationMembersSection: ManualSection = manualSection({
    key: "clenove",
    title: "Správa členů organizace",
    intro: (
        <>
            <p>
                Správa členů organizace se skládá ze dvou částí – správa členů a správa pozic. Přepnout mezi těmito
                dvěma částmi můžete pomocí záložek uprostřed horní poloviny okna.
            </p>
            <p>
                Základní myšlenkou tohoto rozdělení je možnost nezávisle spravovat pozice v&nbsp;organizaci s jejich
                oprávněními a přiřazovat pozice konkrétním členům. Pro přidání nového člena s&nbsp;nějakými oprávněními
                – například spravovat profil organizace nebo nabízené indikátorové sady – je nutné nejdříve vytvořit
                odpovídající pozici na záložce „Pozice“ a poté pozvat samotného člena na záložce „Členové“.
            </p>
        </>
    ),
    subsections: [csOrganizationPositionsSubsection, csOrganizationMembershipsSubsection],
});

const csOrganizationLibrariesCustomSubsection: ManualSubsection = manualSubsection({
    key: "vlastni",
    title: "Vlastní indikátorová sada",
    intro: (
        <>
            <p>
                Pro vytváření a úpravy vlastní indikátorové sady zvolte na profilu organizace/obce položku „Indikátorové
                sady“. Tlačítkem <AddIcon style={{verticalAlign: "bottom"}} /> můžete vytvořit novou. U stávajících
                můžete kliknutím na ikonu&nbsp;
                <EditIcon style={{verticalAlign: "bottom"}} /> přejít na úpravu základních údajů sady (název, popis,
                licence apod.). Dále můžete zobrazit detail sady anebo procházet její obsah.
            </p>
            <p>
                Pro úpravu obsahu sady, klikněte na ikonu&nbsp;
                <VpnKeyIcon style={{verticalAlign: "bottom"}} /> „Otevřít editor“. V&nbsp;editoru můžete spravovat
                jednotlivé oblasti, cíle a indikátory (upravovat, odstraňovat či vytvářet nové). Pro vytváření a úpravy
                se zobrazí příslušný formulář v&nbsp;dialogovém okně. Odstranit lze jen nepoužívanou oblast (resp. cíl
                nebo indikátor), který rovněž nemá žádné podřazené prvky (například oblast nemá cíle). Jednotlivé části
                lze také přesouvat a měnit jejich pořadí klepnutím na tlačítko se šipkami v&nbsp;levé části.
            </p>
        </>
    ),
});

const widthOfSymbolX = 0.7;

const csOrganizationLibrariesEquationsSubsection: ManualSubsection = manualSubsection({
    key: "vzorce",
    title: "Sestavení vzorce indikátoru",
    intro: (
        <>
            <p>
                Při úpravě a vytváření vzorce je nutné nastavit vzorec pro výpočet indikátoru. Vzorec lze sestavit
                z&nbsp;následujících matematických operací, konstant a vstupních proměnných. Editor vzorce nabízí
                jednoduché rozhraní pro vytváření a úpravy vzorců, lze ale také přepnout na pokročilý režim, kde
                uživatel může vzorec zadávat v&nbsp;textové podobě.
            </p>
            <ul>
                <li>Konstanta (CONST) = zadaná číselná hodnota, která se nemění (např. 100)</li>
                <li>
                    Proměnná (VAR) = vstupní proměnná, představující hodnotu v&nbsp;daném okamžiku pro výpočet (např.
                    počet obyvatel obce)
                </li>
                <li>
                    Součet (ADD) = sečtení dvou podvýrazů{" "}
                    <img
                        alt="x"
                        src={EquationSymbolImageX}
                        style={{
                            width: widthOfSymbolX + "em",
                            verticalAlign: "bottom",
                        }}
                    />
                    &nbsp;a&nbsp;
                    <img
                        alt="y"
                        src={EquationSymbolImageY}
                        style={{
                            width: widthOfSymbolX + "em",
                            verticalAlign: "bottom",
                        }}
                    />{" "}
                    (operace{" "}
                    <img
                        alt="x + y"
                        src={EquationSymbolImageXplusY}
                        style={{
                            width: widthOfSymbolX * 4.25 + "em",
                            verticalAlign: "bottom",
                        }}
                    />
                    )
                </li>
                <li>
                    Rozdíl (SUB) = odečtení dvou podvýrazů{" "}
                    <img
                        alt="x"
                        src={EquationSymbolImageX}
                        style={{
                            width: widthOfSymbolX + "em",
                            verticalAlign: "bottom",
                        }}
                    />
                    &nbsp;a&nbsp;
                    <img
                        alt="y"
                        src={EquationSymbolImageY}
                        style={{
                            width: widthOfSymbolX + "em",
                            verticalAlign: "bottom",
                        }}
                    />{" "}
                    (operace{" "}
                    <img
                        alt="x - y"
                        src={EquationSymbolImageXminusY}
                        style={{
                            width: widthOfSymbolX * 4.25 + "em",
                            verticalAlign: "bottom",
                        }}
                    />
                    )
                </li>
                <li>
                    Součin (SUB) = odečtení dvou podvýrazů{" "}
                    <img
                        alt="x"
                        src={EquationSymbolImageX}
                        style={{
                            width: widthOfSymbolX + "em",
                            verticalAlign: "bottom",
                        }}
                    />
                    &nbsp;a&nbsp;
                    <img
                        alt="y"
                        src={EquationSymbolImageY}
                        style={{
                            width: widthOfSymbolX + "em",
                            verticalAlign: "bottom",
                        }}
                    />{" "}
                    (operace&nbsp;
                    <img
                        alt="x * y"
                        src={EquationSymbolImageXtimesY}
                        style={{width: widthOfSymbolX * 3.375 + "em", verticalAlign: "bottom"}}
                    />
                    )
                </li>
                <li>
                    Podíl (SUB) = odečtení dvou podvýrazů{" "}
                    <img
                        alt="x"
                        src={EquationSymbolImageX}
                        style={{
                            width: widthOfSymbolX + "em",
                            verticalAlign: "bottom",
                        }}
                    />
                    &nbsp;a&nbsp;
                    <img
                        alt="y"
                        src={EquationSymbolImageY}
                        style={{
                            width: widthOfSymbolX + "em",
                            verticalAlign: "bottom",
                        }}
                    />{" "}
                    (operace&nbsp;
                    <img
                        alt="x / y"
                        src={EquationSymbolImageXdivY}
                        style={{width: widthOfSymbolX * 4.25 + "em", verticalAlign: "bottom"}}
                    />
                    )
                </li>
                <li>
                    Logaritmus (LOG) = dekadický logaritmus jednoho podvýrazu{" "}
                    <img
                        alt="log10 (x)"
                        src={EquationSymbolImageLogX}
                        style={{
                            width: widthOfSymbolX * 5.375 + "em",
                            verticalAlign: "bottom",
                        }}
                    />
                    ; pokud je nutný jiný základ logaritmu, lze zkombinovat pomocí dělení a matematických pravidel
                    operace logaritmus
                </li>
                <li>
                    Mocnina (POW) = mocnina dvou podvýrazů{" "}
                    <img
                        alt="x"
                        src={EquationSymbolImageX}
                        style={{
                            width: widthOfSymbolX + "em",
                            verticalAlign: "bottom",
                        }}
                    />
                    &nbsp;a&nbsp;
                    <img
                        alt="y"
                        src={EquationSymbolImageY}
                        style={{
                            width: widthOfSymbolX + "em",
                            verticalAlign: "bottom",
                        }}
                    />{" "}
                    (operace&nbsp;
                    <img
                        alt="x^y"
                        src={EquationSymbolXraisedToY}
                        style={{width: widthOfSymbolX * 2.125 + "em", verticalAlign: "bottom"}}
                    />
                    )
                </li>
                <li>
                    Horní celá část (CEIL) = zaokrouhlení desetinného čísla{" "}
                    <img
                        alt="x"
                        src={EquationSymbolImageX}
                        style={{
                            width: widthOfSymbolX + "em",
                            verticalAlign: "bottom",
                        }}
                    />{" "}
                    dolů (operace&nbsp;
                    <img
                        alt="floor(x)"
                        src={EquationSymbolXfloor}
                        style={{width: widthOfSymbolX * 2.375 + "em", verticalAlign: "bottom"}}
                    />
                    )
                </li>
                <li>
                    Dolní celá část (FLOOR) = zaokrouhlení desetinného čísla{" "}
                    <img
                        alt="x"
                        src={EquationSymbolImageX}
                        style={{
                            width: widthOfSymbolX + "em",
                            verticalAlign: "bottom",
                        }}
                    />{" "}
                    nahorů (operace&nbsp;
                    <img
                        alt="ceil(x)"
                        src={EquationSymbolXceil}
                        style={{width: widthOfSymbolX * 2.375 + "em", verticalAlign: "bottom"}}
                    />
                    )
                </li>
                <li>Zaokrouhlení (ROUND) = zaokrouhlení desetinného čísla dle pravidel (tj. od 0,5 nahoru)</li>
            </ul>
        </>
    ),
});

const csOrganizationLibrariesCombiningSubsection: ManualSubsection = manualSubsection({
    key: "kombinovani",
    title: "Kombinování s jinými indikátorovými sadami",
    intro: (
        <>
            <p>
                Z&nbsp;existujících sdílených indikátorových sad můžete přidat části (oblasti, cíle, indikátory) do
                svojí sady. V&nbsp;editoru sady klepněte v&nbsp;horní části na tlačítko se symbolem{" "}
                <AddIcon style={{verticalAlign: "bottom"}} /> „Přidat obsah z&nbsp;jiných sad“. Poté vyberte požadovanou
                indikátorovou sadu a následně zaškrtněte její části, které chcete přidat. Po potvrzení se vybrané části
                přidají a můžete měnit pořadí a přesouvat je v&nbsp;rámci sady.
            </p>
            <p>
                Takto použité části nelze měnit, neboť se jedná pouze o odkaz do jiné sady. Na druhou stranu, pokud se
                změní (například text popisů) v&nbsp;původní sadě, změny se projeví i v&nbsp;této vaší.
            </p>
        </>
    ),
});

const csOrganizationLibrariesCloningSubsection: ManualSubsection = manualSubsection({
    key: "klonovani",
    title: "Klonování indikátorové sady",
    intro: (
        <>
            <p>
                Indikátorovou sadu můžete také začít vytvářet kopií jiné. Například pokud chcete mít možnost cizí sadu
                upravovat nebo chcete vytvořit novou verzi vlastní knihovny bez nutnosti změn předchozí. V&nbsp;seznamu
                veřejných indikátorových sad najděte požadovanou sadu a přejděte na procházení jejího obsahu
                „Navštívit“. Poté klikněte v&nbsp;horní části na tlačítko se symbolem{" "}
                <AddIcon style={{verticalAlign: "bottom"}} /> „Klonovat“.
            </p>
            <p>
                Po vyplnění a potvrzení formuláře se vám vytvoří kopie indikátorové sady ve vybrané organizaci/obci.
                Následně můžete tuto sadu upravovat dle vašich potřeb.
            </p>
        </>
    ),
});

const csOrganizationLibrariesPublishingSubsection: ManualSubsection = manualSubsection({
    key: "zverejneni",
    title: "Zveřejnění a sdílení indikátorové sady",
    intro: (
        <>
            <p>
                Pokud chcete vaši indikátorovou sadu nabídnout ostatním, kontaktujte administrátora aplikace. Před
                zveřejněním by sada měla být finální, neboť některé typy úprav nelze po zveřejnění provádět (např.
                odstraňovat indikátory nebo měnit jejich vzorce). Jsou dva nezávislé režimy:
            </p>
            <ul>
                <li>
                    Zveřejnění = kdokoliv může procházet obsah sady a obce ji mohou použít pro sestavování skupin
                    sledovaných indikátorů.
                </li>
                <li>
                    Sdílení = jakákoliv organizace i obec může použít části této sady s&nbsp;nějakou svojí (například
                    přidat si z&nbsp;této sady cíl a jeho indikátory).
                </li>
            </ul>
        </>
    ),
});

const csOrganizationLibrariesEditingSubsection: ManualSubsection = manualSubsection({
    key: "zmeny",
    title: "Změny a odstranění indikátorové sady",
    intro: (
        <>
            <p>
                Změny ve zveřejněných a sdílených indikátorových sadách je nutné konzultovat s&nbsp;administrátory
                aplikace. Obecně je možné měnit jen popisné texty (například dopřesnit či opravit překlepy), nelze měnit
                význam indikátorů nebo jiných částí, vzorce indikátorů, či cokoliv odstraňovat.
            </p>
            <p>
                U nezveřejněných sad lze měnit cokoliv, ale opět by se nemělo měnit nic, co je již použito v&nbsp;nějaké
                skupině sledovaných indikátorů. V&nbsp;tomto případě je odpovědnost na straně organizace (resp. obce).
                V&nbsp;rámci sady lze odstraňovat část, která nemá žádné podčásti (oblast bez cílů, cíl bez indikátorů).
                Současně lze odstranit jen části, které nejsou použity v&nbsp;žádné skupině sledovaných indikátorů.
                Celou sadu lze odstranit, pokud žádná z&nbsp;jejích částí není použita – při odstranění sady dojde
                k&nbsp;odstranění všech jejích částí.
            </p>
        </>
    ),
});

export const csOrganizationLibrariesSection: ManualSection = manualSection({
    key: "indikatorove-sady",
    title: "Tvorba indikátorové sady",
    intro: (
        <p>
            V&nbsp;rámci organizací (včetně obcí) lze vytvářet vlastní indikátorové sady. Organizace mohou poté své sady
            sdílet a zveřejňovat, aby je mohly obce využít. Naopak obce si mohou vytvořit vlastní sady čistě pro použití
            ve svých skupinách sledovaných indikátorů. Stejně tak je mohou ale také sdílet s&nbsp;ostatními obcemi.
        </p>
    ),
    subsections: [
        csOrganizationLibrariesCustomSubsection,
        csOrganizationLibrariesEquationsSubsection,
        csOrganizationLibrariesCombiningSubsection,
        csOrganizationLibrariesCloningSubsection,
        csOrganizationLibrariesPublishingSubsection,
        csOrganizationLibrariesEditingSubsection,
    ],
});

export const csOrganizationManualChapter: ManualChapter = manualChapter({
    key: "organizace",
    title: "Užívání Smart City Compass organizacemi, které poskytují podporu obcím při strategickém řízení",
    intro: (
        <p>
            Organizace veřejné správy (ministerstva, krajská samospráva), organizace nabízející metodickou podporu obcím
            (inovační centra, asociace, svazy), výzkumné organizace a soukromé poradenské organizace mohou využívat
            Smart City Compass jako nástroj digitalizace metodik či jako pomocníka při práci s&nbsp;obcemi. Umožňuje jim
            vytvářet nové indikátorové sady nebo využívat existující obsah nástroje v&nbsp;rámci svých aktivit a služeb
            orientovaných na obce.
        </p>
    ),
    sections: [csOrganizationProfileSection, csOrganizationMembersSection, csOrganizationLibrariesSection],
    requiresAdmin: false,
});

export default csOrganizationManualChapter;
