import React from "react";
import {Link, useParams} from "react-router-dom";
import {API, createApiConfig} from "../../../utils";
import {AxiosError} from "axios";
import {useTranslation} from "react-i18next";
import {useKeycloak} from "@react-keycloak/web";
import {EmailDTO, EmailVerificationDTO} from "../../../models/user";
import {Box, Button, Container, Divider, LinearProgress} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
// SVG
import SearchingSVG from "../../../graphics/undraw/searching_3evy.svg";
import SecuritySVG from "../../../graphics/undraw/security_on_ff2u.svg";
import AlertSVG from "../../../graphics/undraw/alert_mc7b.svg";
import ROUTES from "../../../routes/routes";

interface EmailVerificationBoxProps {
    title: string;
    text: string;
    image: string;
    children?: React.ReactNode;
}

const EmailVerificationBox: React.FC<EmailVerificationBoxProps> = (props: EmailVerificationBoxProps) => {
    return (
        <Container maxWidth="md">
            <Box display="flex" justifyItems="center" flexDirection="column" textAlign="center">
                <h1>{props.title}</h1>
                <p>{props.text}</p>
                <img style={{maxWidth: 400, width: "100%", margin: "1em auto"}} src={props.image} alt={props.title} />
                <Divider style={{margin: "2em"}} />
                <Box display="flex" justifyContent="center">
                    {props.children}
                </Box>
            </Box>
        </Container>
    );
};

interface EmailVerificationParams {
    emailId: string;
    code: string;
}

interface EmailVerificationState {
    name: "verifying" | "verified" | "invalid";
    email: EmailDTO | null;
}

export const EmailVerification: React.FC = () => {
    //== Init =================================================================
    const {emailId, code} = useParams<EmailVerificationParams>();
    const [t] = useTranslation("user");
    const {keycloak, initialized} = useKeycloak();
    const initState: EmailVerificationState = {
        name: "verifying",
        email: null,
    };
    const [state, setState] = React.useState(initState);
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "verifying") {
            const dto: EmailVerificationDTO = {
                code: code,
            };
            API.post<EmailDTO>(`/emails/${emailId}/verify`, dto, createApiConfig(keycloak, initialized))
                .then((res) => {
                    setState({
                        ...state,
                        name: "verified",
                        email: res.data,
                    });
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "invalid"});
                });
        }
    }, [state, setState, emailId, code]);
    //== Render ===============================================================
    if (state.name === "invalid") {
        return (
            <EmailVerificationBox
                title={t("emails.emailVerification.invalid.title")}
                text={t("emails.emailVerification.invalid.text")}
                image={AlertSVG}
            >
                <Button
                    component={Link}
                    to={ROUTES.ownProfile.path}
                    variant="outlined"
                    color="primary"
                    startIcon={<AccountCircleIcon />}
                >
                    {t("emails.emailVerification.button")}
                </Button>
            </EmailVerificationBox>
        );
    }
    if (state.name === "verified") {
        return (
            <EmailVerificationBox
                title={t("emails.emailVerification.verified.title")}
                text={t("emails.emailVerification.verified.text")}
                image={SecuritySVG}
            >
                <Button
                    component={Link}
                    to={ROUTES.ownProfile.path}
                    variant="outlined"
                    color="primary"
                    startIcon={<AccountCircleIcon />}
                >
                    {t("emails.emailVerification.button")}
                </Button>
            </EmailVerificationBox>
        );
    }
    return (
        <EmailVerificationBox
            title={t("emails.emailVerification.verifying.title")}
            text={t("emails.emailVerification.verifying.text")}
            image={SearchingSVG}
        >
            <LinearProgress variant="query" style={{width: "100%"}} />
        </EmailVerificationBox>
    );
};
