import React from "react";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
// Material UI imports
import {Box, Link, Table, TableBody, TableCell, TableHead, TableRow, Tooltip} from "@material-ui/core";
// Project imports
import {AcceptActionButton, DeclineActionButton, RefreshActionButton} from "../../../common/buttons";
import {ErrorNotification, InfoNotification, SuccessNotification} from "../../../common/notifications";
import {ServerCommunicationAlert} from "../../../common/errors";
import {Loading, LoadingRow} from "../../../common/Loading";
import API, {createApiConfig} from "../../../../utils/API";
import {AxiosError} from "axios";
import {FullMembershipRequestDTO, MembershipDTO} from "../../../../models/members";
import Pagination from "@material-ui/lab/Pagination";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {makeFullName} from "../../../../models/user";
import ROUTES from "../../../../routes/routes";
import TextField from "@material-ui/core/TextField";
import {Page} from "../../../../models/pagination";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            tableLayout: "fixed",
        },
        actionsColumnCell: {
            width: "130px",
            textAlign: "right",
        },
        emailCell: {},
        noEmailsCell: {
            color: theme.palette.error.main,
        },
    })
);

interface AdminFullMembershipRequestRowProps {
    request: FullMembershipRequestDTO;

    onAccept?(membership: MembershipDTO): void;

    onDecline?(request: FullMembershipRequestDTO): void;
}

const AdminFullMembershipRequestRow: React.FC<AdminFullMembershipRequestRowProps> = (
    props: AdminFullMembershipRequestRowProps
) => {
    //== Init ===================================================================
    const classes = useStyles();
    const [t] = useTranslation("organizationMembers");
    const {keycloak, initialized} = useKeycloak();
    const [state, setState] = React.useState({
        name: "shown" as "shown" | "processing" | "processed" | "failed",
        action: "init" as "init" | "decline" | "accept",
    });
    const [positionName, setPositionName] = React.useState(props.request.positionName);
    //== Effects ================================================================
    React.useEffect(() => {
        if (state.name === "processing" && initialized) {
            if (state.action === "accept") {
                API.post<MembershipDTO>(
                    `/full-membership-request/accept/${props.request.publicId}`,
                    {positionName: positionName || t("permissionLevels.full")},
                    createApiConfig(keycloak, initialized)
                )
                    .then((res) => {
                        props.onAccept?.(res.data);
                        setState({
                            ...state,
                            name: "processed",
                        });
                    })
                    .catch((err: AxiosError) => {
                        setState({...state, name: "failed"});
                    });
            } else if (state.action === "decline") {
                API.post<void>(
                    `/full-membership-request/decline/${props.request.id}`,
                    null,
                    createApiConfig(keycloak, initialized)
                )
                    .then((res) => {
                        props.onDecline?.(props.request);
                        setState({
                            ...state,
                            name: "processed",
                        });
                    })
                    .catch((err: AxiosError) => {
                        setState({...state, name: "failed"});
                        return;
                    });
            }
        }
    }, [keycloak, initialized, state, setState, props.request]);
    //== Handlers ===============================================================
    const handleDecline = (): void => {
        setState({...state, name: "processing", action: "decline"});
    };
    const handleAccept = (): void => {
        setState({...state, name: "processing", action: "accept"});
    };
    //== Render =================================================================
    if (state.name === "processed") {
        if (state.action === "accept") return <SuccessNotification message={t(`notifications.requests.accept_ok`)} />;
        else if (state.action === "decline")
            return <SuccessNotification message={t(`notifications.requests.decline_ok`)} />;
    } else if (state.name === "processing") {
        return <LoadingRow key={props.request.id} />;
    }
    let notification: JSX.Element | null = null;
    if (state.name === "failed") {
        notification = <ErrorNotification message={t(`notifications.requests.${state.action}_fail`)} />;
    }
    const verifiedEmails = props.request.profile.emails
        .filter((emailDTO) => emailDTO.verified)
        .map((emailDTO) => emailDTO.email);
    return (
        <TableRow key={props.request.id}>
            <TableCell>
                <Link href={ROUTES.person.create(props.request.profile.person.publicId)}>
                    {makeFullName(props.request.profile.person)}
                </Link>
            </TableCell>
            <TableCell className={verifiedEmails.length ? classes.emailCell : classes.noEmailsCell}>
                {verifiedEmails.length ? (
                    <Tooltip title={verifiedEmails.join(", ")} aria-label="emails">
                        <span>
                            {verifiedEmails[0]}
                            {verifiedEmails.length > 1 ? " (+" + (verifiedEmails.length - 1) + ")" : ""}
                        </span>
                    </Tooltip>
                ) : (
                    t("request.noVerifiedEmails")
                )}
            </TableCell>
            <TableCell>
                <Link href={ROUTES.organization.create(props.request.organization.publicId)}>
                    {props.request.organization.name}
                </Link>
            </TableCell>
            <TableCell>
                <TextField
                    id="position-name"
                    label={t("request.positionName")}
                    value={positionName || ""}
                    fullWidth
                    required
                    onChange={(e): void => setPositionName(e.target.value || null)}
                />
            </TableCell>
            <TableCell className={classes.actionsColumnCell}>
                <AcceptActionButton disabled={!positionName || !verifiedEmails.length} onClick={handleAccept} />
                <DeclineActionButton onClick={handleDecline} />
                {notification}
            </TableCell>
        </TableRow>
    );
};

interface AdminFullMembershipRequestsState {
    page: {
        size: number;
        totalElements: number;
        totalPages: number;
        number: number;
    };
    requests: FullMembershipRequestDTO[];
    name: "loading" | "loaded" | "failed";
    action: "init" | "refresh";
}

interface AdminFullMembershipRequestsProps {
    requests?: FullMembershipRequestDTO[];

    onAccept?(membership: MembershipDTO): void;

    onDecline?(request: FullMembershipRequestDTO): void;
}

const AdminFullMembershipRequests: React.FC<AdminFullMembershipRequestsProps> = (
    props: AdminFullMembershipRequestsProps
) => {
    //== Init ===================================================================
    const classes = useStyles();
    const [t] = useTranslation("organizationMembers");
    const {keycloak, initialized} = useKeycloak();
    const initState: AdminFullMembershipRequestsState = props.requests
        ? {
              page: {
                  size: 10,
                  totalElements: props.requests.length,
                  totalPages: Math.ceil(props.requests.length / 10),
                  number: 0,
              },
              requests: props.requests,
              name: "loaded",
              action: "init",
          }
        : {
              page: {
                  size: 10,
                  totalElements: 0,
                  totalPages: 0,
                  number: 0,
              },
              requests: [],
              name: "loading",
              action: "init",
          };
    const [state, setState] = React.useState(initState);
    //== Effects ================================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<Page<FullMembershipRequestDTO>>(
                `/full-membership-request?page=${state.page.number}&size=${state.page.size}`,
                createApiConfig(keycloak, initialized)
            )
                .then((res) => {
                    setState({
                        ...state,
                        page: res.data,
                        requests: res.data?.content ?? [],
                        name: "loaded",
                    });
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [keycloak, initialized, state, setState, props.requests]);
    //== Handlers ===============================================================
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number): void => {
        setState({
            ...state,
            page: {...state.page, number: value - 1},
            name: "loading",
            action: "init",
        });
    };
    //== Render =================================================================
    if (state.name === "loading") {
        return <Loading />;
    }
    if (state.name === "failed") {
        return <ServerCommunicationAlert />;
    }
    // - default
    let notification: JSX.Element | null = null;
    if (state.name === "loaded" && state.action === "refresh") {
        notification = <InfoNotification message={t("notifications.requests.refresh_ok")} />;
    }
    const rows = state.requests.map((request: FullMembershipRequestDTO) => (
        <AdminFullMembershipRequestRow
            key={request.id}
            request={request}
            onAccept={props.onAccept}
            onDecline={props.onDecline}
        />
    ));
    return (
        <Box>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>{t("request.person")}</TableCell>
                        <TableCell>{t("request.verifiedEmail")}</TableCell>
                        <TableCell>{t("request.organization")}</TableCell>
                        <TableCell>{t("request.positionName")}</TableCell>
                        <TableCell className={classes.actionsColumnCell}>
                            <RefreshActionButton
                                onClick={(): void =>
                                    setState({
                                        ...initState,
                                        action: "refresh",
                                    })
                                }
                            />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{rows}</TableBody>
            </Table>
            {state.page.totalPages > 1 && (
                <Pagination
                    style={{marginTop: "1em"}}
                    count={state.page.totalPages}
                    page={state.page.number + 1}
                    onChange={handlePageChange}
                />
            )}
            {notification}
        </Box>
    );
};

export default AdminFullMembershipRequests;
