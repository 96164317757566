import manualSection, {ManualSection} from "../../model/ManualSection";
import manualChapter, {ManualChapter} from "../../model/ManualChapter";
import InfoIcon from "@material-ui/icons/Info";
import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import VisibilityIcon from "@material-ui/icons/Visibility";

export const csPublicSearchSection: ManualSection = manualSection({
    key: "vyhledavani",
    title: "Vyhledání obce",
    intro: (
        <p>
            V&nbsp;levém rozbalovacím menu vyberte položku „Obce“. Na stránce jsou zobrazeny obce včetně základních
            údajů a seřazené dle poslední aktivity. Pomocí vyhledávacího pole můžete zadáním části názvu obce seznam
            vyfiltrovat. Pro zobrazení náhledu dané obce klikněte na rozbalovací ikonu&nbsp;
            <ExpandMoreIcon style={{verticalAlign: "bottom"}} /> vlevo na řádku dané obce. Přechod na kompletní profil
            obce je možný kliknutím na název obce.
        </p>
    ),
});
export const csPublicProfileSection: ManualSection = manualSection({
    key: "profil-obce",
    title: "Profil obce",
    intro: (
        <p>
            Profil obce shrnuje základní údaje o obci, zobrazuje její polohu na mapě a tvoří rozcestník do skupin
            sledovaných indikátorů obce i vlastních zveřejněných indikátorů (pokud je obec má).
        </p>
    ),
});
export const csPublicIndicatorsSection: ManualSection = manualSection({
    key: "indikatory",
    title: "Procházení sledovaných indikátorů obce",
    intro: (
        <>
            <p>
                Na profilu obce klikněte na položku „Sledované indikátory“. Na stránce skupin sledovaných indikátorů
                vyberte požadovanou skupinu klepnutím na kartu s&nbsp;názvem skupiny (např. <i>Smart City koncepce</i>).
                V&nbsp;otevřené skupině sledovaných indikátorů můžete procházet jednotlivými úrovněmi od oblasti přes
                cíle až po indikátory. U každé položky je možné zobrazit detail s&nbsp;popisem kliknutím vlevo na symbol
                &nbsp;
                <InfoIcon style={{verticalAlign: "bottom"}} />.
            </p>
            <p>
                Po otevření úrovně indikátoru můžete procházet historii hodnot a porovnávat je s&nbsp;cílovou hodnotou,
                kterou si obec stanovila. Rovněž hodnoty daného indikátoru můžete exportovat kliknutím na ikonu stažení.
                Export souhrnných hodnot indikátorů k&nbsp;zadanému datu je možný přes tlačítko „Export hodnot“. Zde
                vyberte požadované datum a klepněte na „Stáhnout datový soubor“.
            </p>
        </>
    ),
});
export const csPublicLibrariesSection: ManualSection = manualSection({
    key: "indikatorove-sady",
    title: "Procházení veřejných indikátorových sad",
    intro: (
        <p>
            Na profilu obce klikněte na položku „Indikátorové sady“. Tato položka je skrytá, pokud obec nemá žádnou
            zveřejněnou vlastní indikátorovou sadu. Na seznamu indikátorových sad obce vyberte požadovanou sadu
            klepnutím na ikonu&nbsp;
            <VisibilityIcon style={{verticalAlign: "bottom"}} />. Obdobně jako u skupin sledovaných indikátorů zde
            můžete procházet jednotlivými úrovněmi od oblasti přes cíle až po indikátory s&nbsp;možností zobrazení
            detailních informací přes kliknutí na symbol&nbsp;
            <InfoIcon style={{verticalAlign: "bottom"}} />.
        </p>
    ),
});

export const csPublicManualChapter: ManualChapter = manualChapter({
    key: "verejne",
    title: "Užívání Smart City Compass veřejností",
    intro: (
        <>
            <p>
                Veřejnost představuje nejširší uživatelskou skupinu nástroje. Kdokoli může využívat nástroj
                k&nbsp;procházení cílů obcí a ke zjišťování publikovaných hodnot indikátorů.
            </p>
            <p>
                S&nbsp;rostoucím počtem obcí využívajících Smart City Compass poroste hodnota nástroje pro veřejnost.
                Lidé a organizace se zájmem o dění v&nbsp;obci získají možnost rychlého vyhledání vybraných ukazatelů o
                jejím rozvoji.
            </p>
        </>
    ),
    sections: [csPublicSearchSection, csPublicProfileSection, csPublicIndicatorsSection, csPublicLibrariesSection],
    requiresAdmin: false,
});

export default csPublicManualChapter;
