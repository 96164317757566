import React from "react";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
// Material UI imports
import {Box, Link, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
// Project imports
import {AcceptActionButton, DeclineActionButton, RefreshActionButton} from "../../../common/buttons";
import {ErrorNotification, InfoNotification, SuccessNotification} from "../../../common/notifications";
import {ServerCommunicationAlert} from "../../../common/errors";
import {Loading, LoadingRow} from "../../../common/Loading";
import API, {createApiConfig} from "../../../../utils/API";
import {AxiosError} from "axios";
import {MembershipDTO, MembershipInvitationReceiverDTO} from "../../../../models/members";
import Pagination from "@material-ui/lab/Pagination";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {makeFullName} from "../../../../models/user";
import ROUTES from "../../../../routes/routes";
import {Page} from "../../../../models/pagination";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            tableLayout: "fixed",
        },
        actionsColumnCell: {
            width: "130px",
            textAlign: "right",
        },
    })
);

interface ReceivedMembershipInvitationRowProps {
    membershipInvitation: MembershipInvitationReceiverDTO;

    onAccept?(membership: MembershipDTO): void;

    onDecline?(membershipInvitation: MembershipInvitationReceiverDTO): void;
}

const ReceivedMembershipInvitationRow: React.FC<ReceivedMembershipInvitationRowProps> = (
    props: ReceivedMembershipInvitationRowProps
) => {
    //== Init ===================================================================
    const classes = useStyles();
    const [t] = useTranslation("organizationMembers");
    const {keycloak, initialized} = useKeycloak();
    const [state, setState] = React.useState({
        name: "shown" as "shown" | "processing" | "processed" | "failed",
        action: "init" as "init" | "decline" | "accept",
    });
    //== Effects ================================================================
    React.useEffect(() => {
        if (state.name === "processing") {
            if (state.action === "accept") {
                API.post<MembershipDTO>(
                    `/membership-invitations/accept/${props.membershipInvitation.publicId}`,
                    null,
                    createApiConfig(keycloak, initialized)
                )
                    .then((res) => {
                        console.log("res:");
                        console.log(res);
                        props.onAccept?.(res.data);
                        setState({
                            ...state,
                            name: "processed",
                        });
                    })
                    .catch((err: AxiosError) => {
                        setState({...state, name: "failed"});
                    });
            } else if (state.action === "decline") {
                API.delete<void>(
                    `/membership-invitations/${props.membershipInvitation.id}`,
                    createApiConfig(keycloak, initialized)
                )
                    .then((res) => {
                        props.onDecline?.(props.membershipInvitation);
                        setState({
                            ...state,
                            name: "processed",
                        });
                    })
                    .catch((err: AxiosError) => {
                        setState({...state, name: "failed"});
                        return;
                    });
            }
        }
    }, [keycloak, initialized, state, setState, props.membershipInvitation]);
    //== Handlers ===============================================================
    const handleDecline = (): void => {
        setState({...state, name: "processing", action: "decline"});
    };
    const handleAccept = (): void => {
        setState({...state, name: "processing", action: "accept"});
    };
    //== Render =================================================================
    if (state.name === "processed") {
        if (state.action === "accept")
            return <SuccessNotification message={t(`notifications.invitations.accept_ok`)} />;
        else if (state.action === "decline")
            return <InfoNotification message={t(`notifications.invitations.decline_ok`)} />;
    } else if (state.name === "processing") {
        return <LoadingRow key={props.membershipInvitation.id} />;
    }
    let notification: JSX.Element | null = null;
    if (state.name === "failed") {
        notification = <ErrorNotification message={t(`notifications.invitations.${state.action}_fail`)} />;
    }
    const inviter = props.membershipInvitation.inviter;
    return (
        <TableRow key={props.membershipInvitation.id}>
            <TableCell>
                <Link href={ROUTES.organization.create(props.membershipInvitation.organization.publicId)}>
                    {props.membershipInvitation.organization.name}
                </Link>
            </TableCell>
            <TableCell>
                {props.membershipInvitation.positions.map((position) => position.name).join(", ")}
                {notification}
            </TableCell>
            <TableCell>
                <Link href={ROUTES.profile.create(inviter.publicId)}>{makeFullName(inviter)}</Link>
            </TableCell>
            <TableCell>{props.membershipInvitation.message || ""}</TableCell>
            <TableCell className={classes.actionsColumnCell}>
                <AcceptActionButton onClick={handleAccept} />
                <DeclineActionButton onClick={handleDecline} />
            </TableCell>
        </TableRow>
    );
};

interface ReceivedMembershipInvitationsState {
    page: {
        size: number;
        totalElements: number;
        totalPages: number;
        number: number;
    };
    membershipInvitations: MembershipInvitationReceiverDTO[];
    name: "loading" | "loaded" | "failed";
    action: "init" | "refresh";
}

interface ReceivedMembershipInvitationsProps {
    membershipInvitations?: MembershipInvitationReceiverDTO[];

    onAccept?(membership: MembershipDTO): void;

    onDecline?(membershipInvitation: MembershipInvitationReceiverDTO): void;
}

const ReceivedMembershipInvitations: React.FC<ReceivedMembershipInvitationsProps> = (
    props: ReceivedMembershipInvitationsProps
) => {
    //== Init ===================================================================
    const classes = useStyles();
    const [t] = useTranslation("organizationMembers");
    const {keycloak, initialized} = useKeycloak();
    const initState: ReceivedMembershipInvitationsState = props.membershipInvitations
        ? {
              page: {
                  size: 10,
                  totalElements: props.membershipInvitations.length,
                  totalPages: Math.ceil(props.membershipInvitations.length / 10),
                  number: 0,
              },
              membershipInvitations: props.membershipInvitations,
              name: "loaded",
              action: "init",
          }
        : {
              page: {
                  size: 10,
                  totalElements: 0,
                  totalPages: 0,
                  number: 0,
              },
              membershipInvitations: [],
              name: "loading",
              action: "init",
          };
    const [state, setState] = React.useState(initState);
    //== Effects ================================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<Page<MembershipInvitationReceiverDTO>>(
                `/membership-invitations/own?page=${state.page.number}&size=${state.page.size}`,
                createApiConfig(keycloak, initialized)
            )
                .then((res) => {
                    setState({
                        ...state,
                        page: res.data,
                        membershipInvitations: res.data?.content ?? [],
                        name: "loaded",
                    });
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [keycloak, initialized, state, setState, props.membershipInvitations]);
    //== Handlers ===============================================================
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number): void => {
        setState({
            ...state,
            page: {...state.page, number: value - 1},
            name: "loading",
            action: "init",
        });
    };
    //== Render =================================================================
    if (state.name === "loading") {
        return <Loading />;
    }
    if (state.name === "failed") {
        return <ServerCommunicationAlert />;
    }
    // - default
    let notification: JSX.Element | null = null;
    if (state.name === "loaded" && state.action === "refresh") {
        notification = <InfoNotification message={t("notifications.invitations.refresh_ok")} />;
    }
    const rows = state.membershipInvitations.map((membershipInvitation: MembershipInvitationReceiverDTO) => (
        <ReceivedMembershipInvitationRow
            key={membershipInvitation.id}
            membershipInvitation={membershipInvitation}
            onAccept={props.onAccept}
            onDecline={props.onDecline}
        />
    ));
    return (
        <Box>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>{t("membershipInvitation.organization")}</TableCell>
                        <TableCell>{t("membership.positions")}</TableCell>
                        <TableCell>{t("membershipInvitation.inviter")}</TableCell>
                        <TableCell>{t("membershipInvitation.messageFromSender")}</TableCell>
                        <TableCell className={classes.actionsColumnCell}>
                            <RefreshActionButton
                                onClick={(): void =>
                                    setState({
                                        ...initState,
                                        action: "refresh",
                                    })
                                }
                            />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{rows}</TableBody>
            </Table>
            {state.page.totalPages > 1 && (
                <Pagination
                    style={{marginTop: "1em"}}
                    count={state.page.totalPages}
                    page={state.page.number + 1}
                    onChange={handlePageChange}
                />
            )}
            {notification}
        </Box>
    );
};

export default ReceivedMembershipInvitations;
