import React from "react";
import {useTranslation} from "react-i18next";
import {Box, Divider, Table, TableBody, TableCell, TableRow, Typography} from "@material-ui/core";
import {LinkActionButton} from "../../common/buttons";
import ROUTES from "../../../routes/routes";
import BusinessIcon from "@material-ui/icons/Business";
import TimelineIcon from "@material-ui/icons/Timeline";
import KeyboardIcon from "@material-ui/icons/Keyboard";
import {Alert} from "@material-ui/lab";
import {DashboardWidgetProps} from "./index";

export const MunicipalitiesWidget: React.FC<DashboardWidgetProps> = (props: DashboardWidgetProps) => {
    const [t] = useTranslation("user");
    const memberships = props.dashboard.memberships.filter((m) => m.organization.municipality !== null);
    return (
        <Box style={{paddingBottom: "0.5em"}}>
            <Typography variant="h5" component="h2" style={{marginBottom: "1.5em"}}>
                {memberships.length > 1
                    ? t("dashboard.municipalities.title.many")
                    : t("dashboard.municipalities.title.one")}
            </Typography>
            <Divider />
            {memberships.length > 0 ? (
                <Table>
                    <TableBody>
                        {memberships.map((m) => (
                            <TableRow key={m.organization.id}>
                                <TableCell>{m.organization.name}</TableCell>
                                <TableCell>{m.organization.municipality?.lau2 ?? ""}</TableCell>
                                <TableCell align="right">
                                    <LinkActionButton
                                        title={t("dashboard.municipalities.go_profile")}
                                        to={ROUTES.organization.create(m.organization.publicId)}
                                        icon={<BusinessIcon />}
                                    />
                                    <LinkActionButton
                                        title={t("dashboard.municipalities.go_tracked_indicators")}
                                        to={ROUTES.trackedIndicatorGroups.create(m.organization.publicId)}
                                        icon={<TimelineIcon />}
                                    />
                                    <LinkActionButton
                                        title={t("dashboard.municipalities.go_values")}
                                        to={ROUTES.valuesInput.create(m.organization.publicId)}
                                        icon={<KeyboardIcon />}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <Alert style={{marginTop: "0.5em"}} severity="info">
                    {t("dashboard.municipalities.no_municipality")}
                </Alert>
            )}
        </Box>
    );
};
