import React from "react";
import {InputVariableDTO} from "../../../../models/library";
import {useTranslation} from "react-i18next";
import {useKeycloak} from "@react-keycloak/web";
import {API, createApiConfig} from "../../../../utils";
import {filterInputVariables, InputVariablesFilter, InputVariablesFilterContainer} from "./InputVariablesFilter";
import {AxiosError} from "axios";
import {LoadingBackdrop} from "../../../common/Loading";
import {CommunicationError} from "../../../common/errors";
import {BreadcrumbItem, BreadcrumbsRow} from "../../../common/breadcrumbs";
import {PageHeader} from "../../../common/headers";
import {Box, Container} from "@material-ui/core";
import {InputVariableItem} from "./InputVariableItem";
import {InputVariableInfo} from "../../../values/dialogs/InputVariableInfo";
import ROUTES from "../../../../routes/routes";

interface InputVariablesOverviewState {
    inputVariables: InputVariableDTO[];
    items: InputVariableDTO[];
    filter: InputVariablesFilterContainer;
    info: string | null;
    name: "loading" | "loaded" | "failed";
}

export const InputVariablesOverview: React.FC = () => {
    //== Init =================================================================
    const initState: InputVariablesOverviewState = {
        inputVariables: [],
        items: [],
        filter: {
            name: "",
            tags: [],
            tagIds: new Set<string>(),
        },
        info: null,
        name: "loading",
    };
    const [state, setState] = React.useState(initState);
    const [t] = useTranslation("values");
    const [t2] = useTranslation("libraries");
    const {keycloak, initialized} = useKeycloak();
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<InputVariableDTO[]>("/input-variables/list/all", createApiConfig(keycloak, initialized))
                .then((res) => {
                    setState({
                        ...state,
                        inputVariables: res.data,
                        items: filterInputVariables(res.data, state.filter),
                        name: "loaded",
                    });
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [keycloak, state.name]);
    //== Handlers =============================================================
    const handleChangeFilter = (filter: InputVariablesFilterContainer) => {
        setState({
            ...state,
            items: filterInputVariables(state.inputVariables, filter),
            filter: filter,
        });
    };
    const handleOpenInfo = (variableId: string) => {
        setState({...state, info: variableId});
    };
    const handleCloseInfo = () => {
        setState({...state, info: null});
    };
    //== Render ===============================================================
    if (state.name === "loading") {
        return <LoadingBackdrop />;
    }
    if (state.name === "failed") {
        return <CommunicationError />;
    }
    return (
        <Container>
            <BreadcrumbsRow>
                <BreadcrumbItem name={t2("library.titles.libraries")} route={ROUTES.libraries.path} />
                <BreadcrumbItem name={t("input_variable.overview.titles.input_variables")} />
            </BreadcrumbsRow>
            <PageHeader title={t("input_variable.overview.titles.input_variables")} />
            <InputVariablesFilter filter={state.filter} onChange={handleChangeFilter} />
            <Box>
                {state.items.map((item) => (
                    <InputVariableItem key={item.id} inputVariable={item} onInfoOpen={handleOpenInfo} />
                ))}
            </Box>
            <InputVariableInfo variableId={state.info} handleClose={handleCloseInfo} />
        </Container>
    );
};
