import React from "react";
import {
    Box,
    Button,
    Container,
    Divider,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from "@material-ui/core";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {ROUTES} from "../../../../routes/routes";
import {LinkActionButton, RefreshActionButton} from "../../../common/buttons";
import {TrackedIndicatorHistoryDTO, TrackedIndicatorValuesHistoryDTO} from "../../../../models/trackedIndicators";
import Utils, {API, createApiConfig, DateUtils, ValueUtils} from "../../../../utils";
import {AxiosError} from "axios";
import {CommunicationError, NotFound} from "../../../common/errors";
import {IndicatorDTO, UnitSimpleDTO} from "../../../../models/library";
import GetAppIcon from "@material-ui/icons/GetApp";
import {CSVUtils} from "../../../../utils/CSV";
import {BreadcrumbItem, BreadcrumbsRow} from "../../../common/breadcrumbs";
import {PageHeader} from "../../../common/headers";
import BusinessIcon from "@material-ui/icons/Business";
import TimelineIcon from "@material-ui/icons/Timeline";
import KeyboardIcon from "@material-ui/icons/Keyboard";
import {compareByAttr} from "../../../../utils/compare";

interface TrackedIndicatorValuesTableProps {
    items: TrackedIndicatorValuesHistoryDTO[];
    desiredDate: Date;
    onDateChange: (desiredDate: Date) => void;
}

interface TrackedIndicatorValuesRow {
    sortKey: string;
    indicator: IndicatorDTO;
    value: number | undefined;
    unit: UnitSimpleDTO | null;
    relevantSince: string | undefined;
}

const item2row = (item: TrackedIndicatorValuesHistoryDTO): TrackedIndicatorValuesRow => {
    let value = item.value?.value;
    let unit = item.value?.unit ?? null;
    item.value?.values.forEach((v) => {
        if (v.preferred) {
            value = v.value;
            unit = v.unit;
        }
    });
    return {
        sortKey: item.indicator.name.toLowerCase(),
        indicator: item.indicator,
        value: value,
        unit: unit,
        relevantSince: item.value?.relevantSince,
    };
};

const TrackedIndicatorValuesTable: React.FC<TrackedIndicatorValuesTableProps> = (
    props: TrackedIndicatorValuesTableProps
) => {
    //== Init =================================================================
    const [t] = useTranslation(["trackedIndicators"]);
    const rows = props.items.map(item2row).sort(compareByAttr("sortKey", "ascending"));
    //== Helpers ==============================================================
    const csvConvert = (v: TrackedIndicatorValuesRow): string[] => {
        return [
            v.indicator.name,
            `${v.value ?? "N/A"}`,
            v.unit?.abbreviation ?? "",
            DateUtils.dateString(v.relevantSince),
        ];
    };
    const csvHeaders = [
        t("summary.headers.indicator"),
        t("summary.headers.value"),
        t("summary.headers.unit"),
        t("summary.headers.relevantSince"),
    ];
    //== Handlers =============================================================
    const handleDateChange = (value: string) => {
        const newDate = new Date(value);
        props.onDateChange(newDate);
    };
    const handleExport = () => {
        const csv = CSVUtils.generateCSV(rows, csvConvert, csvHeaders);
        Utils.download("summary.csv", "text/csv;charset=utf-8", [csv]).click();
    };
    //== Render ===============================================================
    return (
        <Container>
            <Box display="flex" alignItems="center" style={{margin: "0.5em"}}>
                <Box flexGrow={1}>
                    <p>{t("summary.dateIntro")}</p>
                </Box>
                <TextField
                    label={t("summary.referenceDate")}
                    type="date"
                    variant="outlined"
                    value={DateUtils.dateFormString(props.desiredDate.toISOString())}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e): void => {
                        handleDateChange(e.target.value);
                    }}
                    style={{marginRight: "1em"}}
                />
                <Button onClick={handleExport} variant="contained" startIcon={<GetAppIcon />}>
                    {t("summary.download")}
                </Button>
            </Box>
            <Divider />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t("summary.headers.indicator")}</TableCell>
                        <TableCell align="right">{t("summary.headers.value")}</TableCell>
                        <TableCell align="right">{t("summary.headers.unit")}</TableCell>
                        <TableCell align="right">{t("summary.headers.relevantSince")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((r) => (
                        <TableRow key={r.indicator.id}>
                            <TableCell>{r.indicator.name}</TableCell>
                            {r.value === null ? (
                                <TableCell colSpan={3} style={{textAlign: "center", fontStyle: "italic"}}>
                                    {t("summary.noValue")}
                                </TableCell>
                            ) : (
                                <>
                                    <TableCell align="right">
                                        {r.value == undefined ? "N/A" : ValueUtils.formatNumber(r.value)}
                                    </TableCell>
                                    <TableCell align="right">{r.unit?.abbreviation ?? ""}</TableCell>
                                    <TableCell align="right">{DateUtils.dateString(r.relevantSince)}</TableCell>
                                </>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Container>
    );
};

interface TrackedIndicatorSummaryParams {
    organizationId: string;
    trackedIndicatorGroupId: string;
}

interface TrackedIndicatorSummaryState {
    name: "loading" | "loaded" | "failed" | "not_found";
    data: TrackedIndicatorHistoryDTO | null;
    desiredDate: Date;
}

export const TrackedIndicatorsSummary: React.FC = () => {
    //== Init =================================================================
    const {organizationId, trackedIndicatorGroupId} = useParams<TrackedIndicatorSummaryParams>();
    const {keycloak, initialized} = useKeycloak();
    const [t] = useTranslation(["trackedIndicators"]);
    const initState: TrackedIndicatorSummaryState = {
        name: "loading",
        data: null,
        desiredDate: new Date(),
    };
    const [state, setState] = React.useState(initState);
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            const dateParam = `referenceDate=${DateUtils.toDateISOString(state.desiredDate)}`;
            API.get<TrackedIndicatorHistoryDTO>(
                `/tracked-indicator-values/${trackedIndicatorGroupId}/all?${dateParam}`,
                createApiConfig(keycloak, initialized)
            )
                .then((res) => {
                    setState({...state, data: res.data, name: "loaded"});
                })
                .catch((err: AxiosError) => {
                    if (err.response?.status === 404) {
                        setState({...state, name: "not_found"});
                    } else {
                        setState({...state, name: "failed"});
                    }
                });
        }
    }, [state.name, setState, trackedIndicatorGroupId]);
    //== Handlers =============================================================
    const handleRefresh = () => {
        setState({...state, name: "loading"});
    };
    const handleDateChange = (desiredDate: Date) => {
        setState({...state, name: "loading", desiredDate: desiredDate});
    };
    //== Render ===============================================================
    return (
        <Container maxWidth="lg">
            <BreadcrumbsRow>
                <BreadcrumbItem
                    name={state.data?.municipality?.name || t("organization:organization.municipality")}
                    route={ROUTES.organization.create(organizationId)}
                />
                <BreadcrumbItem
                    name={t("trackedIndicatorGroup.trackedIndicatorGroups")}
                    route={ROUTES.trackedIndicatorGroups.create(organizationId)}
                />
                <BreadcrumbItem
                    name={state.data?.group?.name || t("browser.simpleTitle")}
                    route={ROUTES.trackedIndicatorsBrowser.create(organizationId, trackedIndicatorGroupId)}
                />
                <BreadcrumbItem name={t("summary.title")} />
            </BreadcrumbsRow>
            <PageHeader title={t("summary.title")}>
                <LinkActionButton
                    to={ROUTES.organization.create(organizationId)}
                    icon={<BusinessIcon />}
                    title={t("actions.organization")}
                />
                <LinkActionButton
                    title={t("actions.trackedIndicatorsBrowser")}
                    to={ROUTES.trackedIndicatorGroups.create(organizationId)}
                    icon={<TimelineIcon />}
                />
                <LinkActionButton
                    to={ROUTES.valuesInput.create(organizationId)}
                    icon={<KeyboardIcon />}
                    title={t("actions.valuesInput")}
                />
                <RefreshActionButton onClick={handleRefresh} />
            </PageHeader>
            {state.name === "loading" && (
                <Container maxWidth="sm" style={{textAlign: "center"}}>
                    <h2>{t("summary.loading")}</h2>
                    <LinearProgress style={{margin: "1em", height: "1em"}} />
                </Container>
            )}
            {state.name === "not_found" && <NotFound />}
            {state.name === "failed" && <CommunicationError />}
            {state.name === "loaded" && state.data !== null && (
                <TrackedIndicatorValuesTable
                    items={state.data.items}
                    desiredDate={state.desiredDate}
                    onDateChange={handleDateChange}
                />
            )}
        </Container>
    );
};
