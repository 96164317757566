import React from "react";
import {
    compareLibraryUseAsc,
    LibraryDomainUseDTO,
    LibraryDTO,
    LibraryGoalUseDTO,
    LibraryIndicatorUseDTO,
} from "../../../models/library";
import {Box} from "@material-ui/core";
import {LibraryIndicatorCreateRow} from "./rows/LibraryIndicatorCreateRow";
import {LibraryIndicatorUseRow} from "./rows/LibraryIndicatorUseRow";
import {LibraryIndicatorOwnRow} from "./rows/LibraryIndicatorOwnRow";
import {useKeycloak} from "@react-keycloak/web";
import {API, createApiConfig} from "../../../utils/API";
import {AxiosError} from "axios";
import ItemPositionInLibrary, {IndicatorPositionInLibrary} from "./util/LibraryItemPosition";
import LibraryItemMoveTarget from "./util/LibraryItemMoveTarget";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {LoadingBackdrop} from "../../common/Loading";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        indicatorShiftTarget: {
            marginLeft: theme.spacing(4),
        },
    })
);

interface LibraryIndicatorRowProps {
    library: LibraryDTO;
    libraryDomainUse: LibraryDomainUseDTO;
    libraryGoalUse: LibraryGoalUseDTO;
    libraryIndicatorUse: LibraryIndicatorUseDTO;
    onUpdate: (library: LibraryDTO) => void;
    shiftingItemPosition: ItemPositionInLibrary | null;
    handleShiftStart: (itemPosition: IndicatorPositionInLibrary) => void;
    handleShiftEnded: () => void;
}

const LibraryIndicatorRow: React.FC<LibraryIndicatorRowProps> = (props: LibraryIndicatorRowProps) => {
    if (props.library.id === props.libraryGoalUse.goal.libraryUUID) {
        return <LibraryIndicatorOwnRow {...props} />;
    } else {
        return <LibraryIndicatorUseRow {...props} />;
    }
};

interface IndicatorLevelBlockProps extends IndicatorLevelProps {
    libraryIndicatorUse: LibraryIndicatorUseDTO;
}

const IndicatorLevelBlock: React.FC<IndicatorLevelBlockProps> = (props: IndicatorLevelBlockProps) => {
    return (
        <div>
            <LibraryIndicatorRow {...props} />
        </div>
    );
};

interface IndicatorLevelProps {
    library: LibraryDTO;
    libraryDomainUse: LibraryDomainUseDTO;
    libraryGoalUse: LibraryGoalUseDTO;
    onReload: (cause: string) => void;
    onUpdate: (library: LibraryDTO) => void;
    shiftingItemPosition: ItemPositionInLibrary | null;
    handleShiftStart: (itemPosition: IndicatorPositionInLibrary) => void;
    handleShiftEnded: () => void;
}

interface IndicatorLevelState {
    name: "init" | "failed" | "shifting";
    localShiftingSourceIndex: number | null;
    shiftingTargetIndex: number | null;
}

export const IndicatorLevel: React.FC<IndicatorLevelProps> = (props: IndicatorLevelProps) => {
    //== Init =================================================================
    const classes = useStyles();
    const indicators = props.libraryGoalUse.libraryIndicatorUses.sort(compareLibraryUseAsc);
    const initState: IndicatorLevelState = {
        name: "init",
        localShiftingSourceIndex: null,
        shiftingTargetIndex: null,
    };
    const [state, setState] = React.useState(initState);
    const {keycloak, initialized} = useKeycloak();
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "shifting" && state.shiftingTargetIndex !== null) {
            let shiftedItem = null;
            if (
                props.shiftingItemPosition &&
                props.shiftingItemPosition.goalIndex !== null &&
                props.shiftingItemPosition.indicatorIndex !== null
            )
                shiftedItem =
                    props.library.libraryDomainUses[props.shiftingItemPosition.domainIndex].libraryGoalUses[
                        props.shiftingItemPosition.goalIndex
                    ].libraryIndicatorUses[props.shiftingItemPosition.indicatorIndex];
            else if (state.localShiftingSourceIndex)
                shiftedItem = props.libraryGoalUse.libraryIndicatorUses[state.localShiftingSourceIndex];
            if (!shiftedItem) return;
            const dto = {
                indicatorUseUUID: shiftedItem.id,
                targetDomainUseUUID: props.libraryDomainUse.id,
                targetGoalUseUUID: props.libraryGoalUse.id,
                targetIndex: state.shiftingTargetIndex,
            };
            API.post<LibraryDTO>("/indicators/library-use/move", dto, createApiConfig(keycloak, initialized))
                .then((res) => {
                    handleShiftEnded();
                    props.onReload("shift");
                })
                .catch((err: AxiosError) => {
                    console.log(err);
                    setState({...state, localShiftingSourceIndex: null, shiftingTargetIndex: null, name: "failed"});
                });
        }
    }, [state, setState, props]);
    //== Handlers =============================================================
    const handleShiftEnd = (targetIndex: number) => {
        console.log("[IndicatorLevel] handleShiftEnd - props.shiftingItemPosition:");
        console.log(props.shiftingItemPosition);
        console.log("[IndicatorLevel] handleShiftEnd - targetIndex:");
        console.log(targetIndex);
        if (
            !props.shiftingItemPosition ||
            state.shiftingTargetIndex !== null ||
            props.shiftingItemPosition.goalIndex === null ||
            props.shiftingItemPosition.indicatorIndex === null
        ) {
            return;
        }
        const isShiftingDownInSameGoal =
            props.shiftingItemPosition.domainIndex === props.libraryDomainUse.orderNumber &&
            props.shiftingItemPosition.goalIndex === props.libraryGoalUse.orderNumber &&
            props.shiftingItemPosition.indicatorIndex < targetIndex;
        setState({
            ...state,
            shiftingTargetIndex: isShiftingDownInSameGoal ? targetIndex - 1 : targetIndex,
            name: "shifting",
        });
    };
    const handleShiftEnded = () => {
        if (state.localShiftingSourceIndex !== null || state.shiftingTargetIndex !== null)
            setState({
                ...state,
                localShiftingSourceIndex: null,
                shiftingTargetIndex: null,
                name: "init",
            });
        props.handleShiftEnded();
    };
    const isShiftingIndicator =
        props.shiftingItemPosition !== null && props.shiftingItemPosition.indicatorIndex !== null;
    const libraryPublished = props.library.public || props.library.shared;
    //== Render ===============================================================
    return (
        <Box>
            {indicators
                .map((indicatorUse, index) => {
                    const isShiftingAdjacentIndicator =
                        props.shiftingItemPosition &&
                        props.shiftingItemPosition.domainIndex === props.libraryDomainUse.orderNumber &&
                        props.shiftingItemPosition.goalIndex === props.libraryGoalUse.orderNumber &&
                        props.shiftingItemPosition.indicatorIndex !== null &&
                        (props.shiftingItemPosition.indicatorIndex == index ||
                            props.shiftingItemPosition.indicatorIndex + 1 == index);

                    return (
                        <Box key={indicatorUse.id}>
                            <LibraryItemMoveTarget
                                key={indicatorUse.id + "-move-target"}
                                className={classes.indicatorShiftTarget}
                                active={isShiftingIndicator && !isShiftingAdjacentIndicator}
                                onAction={() => handleShiftEnd(index)}
                            />
                            <IndicatorLevelBlock
                                {...props}
                                libraryIndicatorUse={indicatorUse}
                                shiftingItemPosition={props.shiftingItemPosition}
                                handleShiftStart={props.handleShiftStart}
                                handleShiftEnded={handleShiftEnded}
                            />
                        </Box>
                    );
                })
                .concat([
                    <LibraryItemMoveTarget
                        key={props.library.id + "-end-move-target"}
                        className={classes.indicatorShiftTarget}
                        active={
                            isShiftingIndicator &&
                            !(
                                props.shiftingItemPosition &&
                                props.shiftingItemPosition.domainIndex === props.libraryDomainUse.orderNumber &&
                                props.shiftingItemPosition.goalIndex === props.libraryGoalUse.orderNumber &&
                                props.shiftingItemPosition.indicatorIndex !== null &&
                                props.shiftingItemPosition.indicatorIndex + 1 === indicators.length
                            )
                        }
                        onAction={() => handleShiftEnd(indicators.length)}
                    />,
                ])}
            {props.shiftingItemPosition === null && !libraryPublished && <LibraryIndicatorCreateRow {...props} />}
            {state.name === "shifting" && <LoadingBackdrop />}
        </Box>
    );
};
