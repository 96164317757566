import React, {ChangeEvent} from "react";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
// Material UI imports
import {TextField} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
// Project imports
import {ServerCommunicationAlert} from "../errors";
import {DataSourceDTO} from "../../../models/library";
import {API, createApiConfig} from "../../../utils";
import {AxiosError} from "axios";

interface DataSourceInputProps {
    relatedTo?: string;
    value?: DataSourceDTO | null;
    valueUuid?: string | null;
    excludeUuids?: string[];
    variant?: "outlined" | "standard" | "filled";
    label: string;
    placeholder?: string;
    fullWidth?: boolean;
    className?: string;

    onChange?(value: DataSourceDTO): void;
}

interface DataSourceInputState {
    values: DataSourceDTO[];
    value: DataSourceDTO | null;
    valueUuid: string | null;
    name: string;
}

export const DataSourceInput: React.FC<DataSourceInputProps> = (props: DataSourceInputProps) => {
    //== Init =================================================================
    const initState: DataSourceInputState = {
        values: [],
        value: props.value ?? null,
        valueUuid: props.valueUuid ?? null,
        name: "loading",
    };
    const [t] = useTranslation("libraries");
    const [state, setState] = React.useState(initState);
    const {keycloak, initialized} = useKeycloak();
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<DataSourceDTO[]>(`/data-sources/list/all`, createApiConfig(keycloak, initialized))
                .then((res) => {
                    const values: DataSourceDTO[] = res.data ?? [];
                    const excludes = new Set(props.excludeUuids ?? []);
                    const filteredValues = values.filter((value) => !excludes.has(value.id));
                    if (typeof state.valueUuid === "string") {
                        const value = filteredValues.find((value) => value.id === state.valueUuid) ?? null;
                        setState({...state, value: value, values: filteredValues, name: "loaded"});
                    } else {
                        setState({...state, values: filteredValues, name: "loaded"});
                    }
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [state, setState, keycloak, initialized, props]);
    //== Handlers =============================================================
    //eslint-disable-next-line @typescript-eslint/ban-types
    const handleChange = (event: ChangeEvent<{}>, value: DataSourceDTO | null): void => {
        if (props.onChange && value != null) {
            props.onChange(value);
        }
        setState({...state, value: value, valueUuid: value?.id ?? null});
    };
    //== Render ===============================================================
    if (state.name === "failed") {
        return <ServerCommunicationAlert />;
    }
    return (
        <Autocomplete
            loading={state.name === "loading"}
            loadingText={t("definitions.data_sources.select.loading")}
            noOptionsText={t("definitions.data_sources.select.empty")}
            openText={t("definitions.data_sources.select.open")}
            closeText={t("definitions.data_sources.select.close")}
            clearText={t("definitions.data_sources.select.clear")}
            options={state.values}
            getOptionLabel={(value: DataSourceDTO): string => value.name}
            getOptionSelected={(option, value): boolean => {
                return option.id === value.id;
            }}
            value={state.value}
            filterSelectedOptions
            onChange={handleChange}
            renderInput={(params): JSX.Element => (
                <TextField
                    {...params}
                    variant={props.variant}
                    label={props.label}
                    placeholder={props.placeholder}
                    fullWidth={props.fullWidth}
                    className={props.className}
                />
            )}
        />
    );
};
