import React from "react";
import {useTranslation} from "react-i18next";
// Material UI imports
import {Box, Button, DialogActions, DialogContent, Typography} from "@material-ui/core";
// Project imports
import {DashboardIndicatorsAddDialogProps} from "./DashboardIndicatorsAddProps";
import {
    TrackedDomainInGroupDTO,
    TrackedGoalInGroupDTO,
    TrackedIndicatorGroupWithContentDTO,
    TrackedIndicatorInGroupDTO,
} from "../../../../../models/trackedIndicators";
import {
    DashboardIndicatorsAddingGroup,
    DashboardIndicatorsAddingIndicator,
} from "./DashboardIndicatorsAddingInterfaces";
import DashboardIndicatorsGroupAdd from "./DashboardIndicatorsGroupAdd";
import Loading from "../../../../common/Loading";

interface DashboardIndicatorsAddFromGroupsProps extends DashboardIndicatorsAddDialogProps {
    groups: TrackedIndicatorGroupWithContentDTO[];
}

const DashboardIndicatorsAddFromGroups: React.FC<DashboardIndicatorsAddFromGroupsProps> = (
    props: DashboardIndicatorsAddFromGroupsProps
) => {
    const [t] = useTranslation(["trackedIndicators", "common"]);
    const initState = {
        existingIndicatorIds: new Set() as Set<string>,
        groups: [] as DashboardIndicatorsAddingGroup[],
        added: [] as TrackedIndicatorInGroupDTO[],
    };
    const [state, setState] = React.useState(initState);
    const needsLoading = state && !state.groups.length && props.groups.length;
    React.useEffect(() => {
        if (needsLoading) {
            const existingIndicatorIds = new Set(
                props.currentDashboardIndicators
                    .filter((di) => di.addedByUser)
                    .map((di) => di.trackedIndicator.indicator.id)
            );
            setState({
                ...state,
                existingIndicatorIds: existingIndicatorIds,
                groups: props.groups.map((group: TrackedIndicatorGroupWithContentDTO) => {
                    return {
                        ...group,
                        trackedDomains: group.trackedDomains.map((trackedDomain: TrackedDomainInGroupDTO) => {
                            return {
                                ...trackedDomain,
                                trackedGoals: trackedDomain.trackedGoals.map((trackedGoal: TrackedGoalInGroupDTO) => {
                                    return {
                                        ...trackedGoal,
                                        trackedIndicators: trackedGoal.trackedIndicators.map(
                                            (trackedIndicator: TrackedIndicatorInGroupDTO) => {
                                                const alreadyAdded = existingIndicatorIds.has(
                                                    trackedIndicator.indicator.id
                                                );
                                                return {
                                                    ...trackedIndicator,
                                                    alreadyAdded: alreadyAdded,
                                                };
                                            }
                                        ),
                                    };
                                }),
                            };
                        }),
                    };
                }),
            });
        }
    }, [state, props]);
    const handleChecked = (trackedIndicator: DashboardIndicatorsAddingIndicator) => {
        console.log("[DashboardIndicatorsAddFromGroups] handleChecked - trackedIndicator:");
        console.log(trackedIndicator);

        if (state.added.filter((each) => each.indicator.id === trackedIndicator.indicator.id).length) return;
        setState({
            ...state,
            added: state.added.concat([trackedIndicator]),
        });
    };
    const handleUnchecked = (trackedIndicator: DashboardIndicatorsAddingIndicator) => {
        console.log("[DashboardIndicatorsAddFromGroups] handleUnchecked - trackedIndicator:");
        console.log(trackedIndicator);

        setState({
            ...state,
            added: state.added.filter((each) => each.id !== trackedIndicator.id),
        });
    };

    const handleConfirm = () => {
        props.onAdd(state.added);
    };
    //== Render =================================================================
    const notification: JSX.Element | null = null;
    console.log("[DashboardIndicatorsAddFromGroups] render - state:");
    console.log(state);
    return (
        <>
            <h2 style={{marginLeft: "1em", marginRight: "1em"}}>{t("dashboard.ui.pick")}</h2>
            <DialogContent>
                <Box>
                    {needsLoading ? (
                        <Loading />
                    ) : state.groups.length ? (
                        state.groups.map((group: DashboardIndicatorsAddingGroup, index) => (
                            <DashboardIndicatorsGroupAdd
                                key={group.id}
                                group={group}
                                index={index}
                                handleChecked={handleChecked}
                                handleUnchecked={handleUnchecked}
                            />
                        ))
                    ) : (
                        <Typography>{t("dashboard.ui.nothingToAdd")}</Typography>
                    )}
                    {notification}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                        props.onCancel();
                    }}
                    style={{margin: "0.5em"}}
                >
                    {t("common:actions.cancel")}
                </Button>
                <Button variant="contained" color="primary" onClick={handleConfirm} disabled={!state.groups.length}>
                    {t("common:actions.accept")}
                </Button>
            </DialogActions>
        </>
    );
};

export default DashboardIndicatorsAddFromGroups;
