import React from "react";
import {Container, createStyles, Grid, Paper, Theme} from "@material-ui/core";
import {DashboardDTO} from "../../../models/user";
import {CommunicationError} from "../../common/errors";
import {LoadingBackdrop} from "../../common/Loading";
import {useKeycloak} from "@react-keycloak/web";
import {API, createApiConfig} from "../../../utils";
import {AxiosError} from "axios";
import {makeStyles} from "@material-ui/core/styles";
import UserMembershipInvitationsWidget from "./UserMembershipInvitationsWidget";
import {MunicipalitiesWidget} from "./MunicipalitiesWidget";
import {PageHeader} from "../../common/headers";
import {useTranslation} from "react-i18next";
import AdminFullMembershipRequestsWidget from "./AdminFullMembershipRequestsWidget";
import {isAdmin} from "../../../utils/auth";
import {IndicatorsDashboardWidget} from "./IndicatorsDashboardWidget";

interface DashboardState {
    name: "loading" | "loaded" | "failed";
    dashboard: DashboardDTO | null;
    widgets: DashboardWidget[];
}

interface DashboardWidget {
    size: 3 | 4 | 6 | 8 | 9 | 12;
    type: "municipalities" | "userMembershipInvitations" | "adminFullMembershipRequests" | "indicatorsDashboard";
}

export interface DashboardWidgetProps {
    order: number;
    dashboard: DashboardDTO;
    doReload: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
        },
    })
);

const Dashboard: React.FC = () => {
    // TODO: new widgets
    //== Init =================================================================
    const initState: DashboardState = {
        name: "loading",
        dashboard: null,
        widgets: [
            {size: 12, type: "adminFullMembershipRequests"},
            {size: 8, type: "municipalities"},
            {size: 8, type: "userMembershipInvitations"},
            {size: 12, type: "indicatorsDashboard"},
        ],
    };
    const classes = useStyles();
    const [state, setState] = React.useState(initState);
    const [t] = useTranslation("user");
    const {keycloak, initialized} = useKeycloak();
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<DashboardDTO>("/dashboard", createApiConfig(keycloak, initialized))
                .then((res) => {
                    console.log(res);
                    setState({...state, dashboard: res.data, name: "loaded"});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [state, setState, keycloak, initialized]);
    //== Handlers =============================================================
    const handleReload = (): void => {
        setState({...state, name: "loading"});
    };
    //== Helpers ==============================================================
    const makeWidgetComponent = (
        widget: DashboardWidget,
        index: number,
        dashboard: DashboardDTO
    ): JSX.Element | null => {
        const xprops: DashboardWidgetProps = {
            order: index,
            dashboard: dashboard,
            doReload: handleReload,
        };
        let w = null;
        if (widget.type === "municipalities") {
            w = <MunicipalitiesWidget {...xprops} />;
        } else if (widget.type === "userMembershipInvitations") {
            w = <UserMembershipInvitationsWidget {...xprops} />;
        } else if (widget.type === "adminFullMembershipRequests" && isAdmin(keycloak)) {
            w = <AdminFullMembershipRequestsWidget {...xprops} />;
        } else if (widget.type === "indicatorsDashboard" && state.dashboard?.memberships.length) {
            w = <IndicatorsDashboardWidget {...xprops} />;
        }
        if (!w) return null;
        return (
            <Grid item key={`widget_${widget}_${index}`} lg={widget.size}>
                <Paper className={classes.paper}>{w}</Paper>
            </Grid>
        );
    };
    //== Render ===============================================================
    if (state.name === "loading") {
        return <LoadingBackdrop />;
    }
    if (state.dashboard !== null) {
        const d: DashboardDTO = state.dashboard;
        return (
            <Container maxWidth="lg">
                <PageHeader title={t("dashboard.title")} />
                <Grid container spacing={2}>
                    {state.widgets.map((w, i) => makeWidgetComponent(w, i, d))}
                </Grid>
            </Container>
        );
    }
    return <CommunicationError />;
};

export default Dashboard;
