import React, {useEffect, useState} from "react";
import {useSMap} from "./hooks";

export interface MapyCzMapProps {
    name: string;
    county: string;
    wgs84n: number | null;
    wgs84e: number | null;
    height: string;
    width?: string;
    maxWidth?: string;
    zoom?: number;
    children?: React.ReactNode;
    style?: React.CSSProperties;
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
declare global {
    interface Window {
        SMap: any;
        JAK: any;
    }
}

const MapyCzMap: React.FC<MapyCzMapProps> = (props: MapyCzMapProps) => {
    const mapNode = document.getElementById("mapa");
    const [map, setMap] = useState<any>(null);
    const [isLoading, setLoading] = useState(true);
    const [reloadState, setReloadState] = useState("ready" as "ready" | "changed" | "waiting");
    useSMap(() => setLoading(false));

    const handleResize = () => {
        if (reloadState === "ready") setReloadState("changed");
    };
    React.useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    });

    React.useEffect(() => {
        if (reloadState !== "changed") return;
        setTimeout(() => {
            setReloadState("ready");
            setMap(null);
        }, 2000);
        setReloadState("waiting");
    }, [reloadState]);

    const drawMap = (coords: any) => {
        if (!coords) return;
        console.log("mapy.cz: drawing map...");
        const sMap = new window.SMap(mapNode, coords, props.zoom || 12);
        sMap.addDefaultLayer(window.SMap.DEF_BASE).enable();
        sMap.addDefaultControls();
        const layer = new window.SMap.Layer.Marker();
        sMap.addLayer(layer);
        layer.enable();
        const card = new window.SMap.Card();
        card.getHeader().innerHTML = "<strong>" + props.name + "</strong><br/>okres " + props.county;
        card.getBody().innerHTML =
            '<a target="_blank" href="https://mapy.cz/?q=' +
            props.name +
            ", okres " +
            props.county +
            '"><u>vyhledat na Mapy.cz</u></a>';
        const marker = new window.SMap.Marker(coords, "municipality", {title: props.name});
        marker.decorate(window.SMap.Marker.Feature.Card, card);
        layer.addMarker(marker);
        setMap(sMap);
    };

    useEffect(() => {
        if (isLoading || !mapNode || map) return;
        if (props.wgs84e != null && props.wgs84n != null)
            drawMap(window.SMap.Coords.fromWGS84(props.wgs84e, props.wgs84n));
        else
            new window.SMap.Geocoder(props.name + ", " + props.county, function (geocoder: any) {
                console.log("mapy.cz: no precise coords found, trying text query");
                if (!geocoder.getResults()[0].results.length) {
                    console.log("mapy.cz: no text query results");
                    return;
                }
                drawMap(geocoder.getResults()[0].results[0].coords);
            });
    }, [isLoading, window.innerWidth, map, mapNode, props.name, props.wgs84e, props.wgs84n, props.zoom]);

    return (
        <div
            id="mapa"
            style={{
                ...props.style,
                width: props.width,
                height: props.height,
                maxWidth: props.maxWidth,
                margin: "auto",
            }}
        >
            {map && props.children ? map && props.children : "..."}
        </div>
    );
};

export default MapyCzMap;
