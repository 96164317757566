import React from "react";
import {useTranslation} from "react-i18next";
import {useKeycloak} from "@react-keycloak/web";
import {Redirect, useParams} from "react-router-dom";
// Material-UI imports
import {Button, Container, Divider} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
// Project imports
import {LibraryForm} from "./LibraryForm";
import {LibraryCreateDTO, LibraryDTO, toLibraryCreateDTO} from "../../models/library";
import ROUTES from "../../routes/routes";
import {useStyles} from "../../styles";
import {API, createApiConfig} from "../../utils";
import {AxiosError} from "axios";
import {LoadingBackdrop} from "../common/Loading";
import {CommunicationError, NotFound} from "../common/errors";
import {ErrorNotification, SuccessNotification} from "../common/notifications";
import {OrganizationDTO} from "../../models/organization";
import {BreadcrumbItem, BreadcrumbsRow} from "../common/breadcrumbs";
import {PageHeader} from "../common/headers";
import {LibraryDelete} from "./LibraryDelete";

interface LibraryEditFormProps {
    library: LibraryDTO;

    onSubmit(library: LibraryCreateDTO): void;
}

interface LibraryEditFormState {
    library: LibraryCreateDTO;
    organization: OrganizationDTO;
}

export const LibraryEditForm: React.FC<LibraryEditFormProps> = (props: LibraryEditFormProps) => {
    //== Init =================================================================
    const initState: LibraryEditFormState = {
        library: toLibraryCreateDTO(props.library),
        organization: props.library.organization,
    };
    const [state, setState] = React.useState(initState);
    //== Handlers =============================================================
    const handleChange = (library: LibraryCreateDTO, organization: OrganizationDTO): void => {
        setState({...state, library: library, organization: organization});
    };
    const handleSubmit = (): void => {
        props.onSubmit(state.library);
    };
    //== Render ===============================================================
    return (
        <Container maxWidth="md">
            <LibraryForm
                library={state.library}
                organization={state.organization}
                onChange={handleChange}
                onSubmit={handleSubmit}
            />
        </Container>
    );
};

interface LibraryEditState {
    id: string;
    library: LibraryDTO | null;
    libraryUpdate: LibraryCreateDTO | null;
    name: string;
}

interface LibraryParams {
    libraryId: string;
}

export const LibraryEdit: React.FC = () => {
    //== Init =================================================================
    const [t] = useTranslation("libraries");
    const {keycloak, initialized} = useKeycloak();
    const classes = useStyles();
    const {libraryId} = useParams<LibraryParams>();
    const initState: LibraryEditState = {
        id: libraryId ?? "unknown",
        library: null,
        libraryUpdate: null,
        name: "loading",
    };
    const [state, setState] = React.useState(initState);
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<LibraryDTO>(`/libraries/${state.id}`, createApiConfig(keycloak, initialized))
                .then((res) => {
                    setState({...state, library: res.data, name: "loaded"});
                })
                .catch((err) => {
                    if (err?.response?.status === 404) {
                        setState({...state, name: "not_found"});
                    } else {
                        setState({...state, name: "failed"});
                    }
                });
        } else if (state.name === "saving") {
            API.put<LibraryDTO>(`/libraries/${state.id}`, state.libraryUpdate, createApiConfig(keycloak, initialized))
                .then((res) => {
                    setState({...state, library: res.data, name: "saved"});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "cannot_edit"});
                });
        }
    });
    //== Handlers =============================================================
    const handleSubmit = (library: LibraryCreateDTO): void => {
        setState({...state, libraryUpdate: library, name: "saving"});
    };
    const handleDeleteCancel = (): void => {
        setState({...state, name: "loaded"});
    };
    const handleDeleteFailed = (): void => {
        setState({...state, name: "cannot_edit"});
    };
    const handleDeleteDone = (): void => {
        setState({...state, name: "deleted"});
    };
    const handleDelete = (): void => {
        setState({...state, name: "deleting"});
    };
    //== Render ===============================================================
    if (state.name === "loading") {
        return <LoadingBackdrop />;
    }
    if (state.name === "deleted") {
        return <Redirect to={ROUTES.libraries.path} />;
    }
    if (state.name === "not_found") {
        return <NotFound />;
    }
    if (state.library === null || state.name === "failed") {
        return <CommunicationError />;
    }
    let notification = null;
    if (state.name === "cannot_edit") {
        notification = <ErrorNotification message={t("library.notifications.edit_fail")} />;
    }
    if (state.name === "saved") {
        notification = <SuccessNotification message={t("library.notifications.edit_ok")} />;
    }
    return (
        <Container maxWidth="lg">
            <BreadcrumbsRow>
                <BreadcrumbItem name={t("library.titles.libraries")} route={ROUTES.libraries.path} />
                <BreadcrumbItem name={state.library.name} route={ROUTES.library.create(state.library.id)} />
                <BreadcrumbItem name={t("library.titles.edit_library")} />
            </BreadcrumbsRow>
            <PageHeader title={t("library.titles.edit_library")} />
            <LibraryEditForm library={state.library} onSubmit={handleSubmit} />
            {notification}
            <Divider className={classes.spaceBeforeAfter} />
            <h2>{t("library.admin.other_actions")}</h2>
            <Button variant="contained" color="secondary" startIcon={<DeleteIcon />} onClick={handleDelete}>
                {t("library.admin.library_delete")}
            </Button>
            <LibraryDelete
                entity={state.name === "deleting" ? state.library : null}
                onDeleted={handleDeleteDone}
                onCancel={handleDeleteCancel}
                onFailed={handleDeleteFailed}
            />
        </Container>
    );
};

export default LibraryEdit;
