import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useKeycloak} from "@react-keycloak/web";
import {useParams} from "react-router-dom";
import {Redirect} from "react-router";
// Project imports
import Utils, {API, createApiConfig, DateUtils} from "../../../utils";
import {AxiosError} from "axios";
import {useStyles} from "../../../styles";
import {BasicInfoRow} from "../../common/tables";
import {ServerCommunicationAlert} from "../../common/errors";
import {makeFullName, PersonDTO, ProfileDTO} from "../../../models/user";
import {BackLinkActionButton} from "../../common/buttons";
import {Loading} from "../../common/Loading";
import {ErrorNotification, SuccessNotification} from "../../common/notifications";
import ROUTES from "../../../routes/routes";
import PersonEmails from "../Emails/PersonEmails";
// Material-UI imports
import {Box, Button, ButtonGroup, Grid, Link, Table, TableBody, TextField} from "@material-ui/core";
// Material-UI icons
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import BackspaceIcon from "@material-ui/icons/Backspace";

interface PersonState {
    personId?: string;
    profile: ProfileDTO | null;
    name: string;
}

interface PersonDetailsProps {
    profile: ProfileDTO;
}

interface PersonEditFormProps {
    person: PersonDTO;
    variant?: "filled" | "outlined";

    onSubmit(person: PersonDTO): void;

    onCancel(): void;
}

interface PersonInfoProps {
    person: PersonDTO;

    onEdit(): void;

    onDelete(): void;
}

const PersonForm: React.FC<PersonEditFormProps> = (props: PersonEditFormProps) => {
    //== Init ===================================================================
    const [t] = useTranslation("user");
    const classes = useStyles();
    const initState = {...props.person};
    const [state, setState] = React.useState(initState);
    //== Handlers ===============================================================
    const handleSubmit = (): void => {
        props.onSubmit(state);
    };
    const handleCancel = props.onCancel;
    //== Render =================================================================
    const isNotChanged = Utils.objEq(state, props.person, [
        "firstname",
        "lastname",
        "titlesBefore",
        "titlesAfter",
        "bio",
        "publicId",
        "userUuid",
    ]);
    return (
        <Box>
            <TextField
                label={t("people.firstname")}
                value={state.firstname}
                fullWidth
                required
                variant={props.variant}
                className={classes.spaceAfter}
                onChange={(e): void => {
                    setState({...state, firstname: e.target.value});
                }}
            />
            <TextField
                label={t("people.lastname")}
                value={state.lastname}
                fullWidth
                required
                variant={props.variant}
                className={classes.spaceAfter}
                onChange={(e): void => {
                    setState({...state, lastname: e.target.value});
                }}
            />
            <TextField
                label={t("people.titlesBefore")}
                value={state.titlesBefore}
                fullWidth
                variant={props.variant}
                className={classes.spaceAfter}
                onChange={(e): void => {
                    setState({...state, titlesBefore: e.target.value});
                }}
            />
            <TextField
                label={t("people.titlesAfter")}
                value={state.titlesAfter}
                fullWidth
                variant={props.variant}
                className={classes.spaceAfter}
                onChange={(e): void => {
                    setState({...state, titlesAfter: e.target.value});
                }}
            />
            <TextField
                label={t("people.bio")}
                value={state.bio}
                fullWidth
                multiline
                variant={props.variant}
                className={classes.spaceAfter}
                onChange={(e): void => {
                    setState({...state, bio: e.target.value});
                }}
            />
            <TextField
                label={t("people.publicId")}
                value={state.publicId}
                fullWidth
                required
                variant={props.variant}
                className={classes.spaceAfter}
                onChange={(e): void => {
                    setState({...state, publicId: e.target.value});
                }}
            />
            <TextField
                label={t("people.userUuid")}
                value={state.userUuid}
                fullWidth
                variant={props.variant}
                className={classes.spaceAfter}
                onChange={(e): void => {
                    setState({...state, userUuid: e.target.value});
                }}
            />
            <ButtonGroup className={classes.spaceBeforeAfter}>
                <Button color="primary" disabled={isNotChanged} onClick={handleSubmit} startIcon={<SaveIcon />}>
                    {t("people.edit_save")}
                </Button>
                <Button color="secondary" onClick={handleCancel} startIcon={<BackspaceIcon />}>
                    {t("people.edit_cancel")}
                </Button>
            </ButtonGroup>
        </Box>
    );
};

const PersonInfo: React.FC<PersonInfoProps> = (props: PersonInfoProps) => {
    //== Init ===================================================================
    const [t] = useTranslation("user");
    const classes = useStyles();
    //== Handlers ===============================================================
    const handleEdit = props.onEdit;
    const handleDelete = props.onDelete;
    //== Render =================================================================
    return (
        <Box>
            <Table>
                <TableBody>
                    <BasicInfoRow title={t("people.firstname")} value={props.person.firstname} />
                    <BasicInfoRow title={t("people.lastname")} value={props.person.lastname} />
                    <BasicInfoRow title={t("people.titlesBefore")} value={props.person.titlesBefore} />
                    <BasicInfoRow title={t("people.titlesAfter")} value={props.person.titlesAfter} />
                    <BasicInfoRow title={t("people.bio")} value={props.person.bio} />
                    <BasicInfoRow title={t("people.publicId")} value={props.person.publicId} copyable />
                    <BasicInfoRow title={t("people.userUuid")} value={props.person.userUuid} copyable />
                    <BasicInfoRow
                        title={t("people.createdAt")}
                        value={DateUtils.datetimeString(props.person.createdAt)}
                    />
                    <BasicInfoRow
                        title={t("people.updatedAt")}
                        value={DateUtils.datetimeString(props.person.updatedAt)}
                    />
                </TableBody>
            </Table>
            <ButtonGroup className={classes.spaceBeforeAfter}>
                <Button color="primary" onClick={handleEdit} startIcon={<EditIcon />}>
                    {t("people.edit_start")}
                </Button>
                <Button color="secondary" onClick={handleDelete} startIcon={<DeleteIcon />}>
                    {t("people.delete")}
                </Button>
            </ButtonGroup>
        </Box>
    );
};

const PersonDetails: React.FC<PersonDetailsProps> = (props: PersonDetailsProps) => {
    //== Init ===================================================================
    const [t] = useTranslation("user");
    const {keycloak, initialized} = useKeycloak();
    const initState = {
        person: props.profile.person,
        updatedPerson: props.profile.person,
        name: "init",
        action: "none",
    };
    const [state, setState] = React.useState(initState);
    //== Effects ================================================================
    useEffect(() => {
        if (state.name === "saving") {
            API.put<PersonDTO>(
                `/people/${state.person.id}`,
                state.updatedPerson,
                createApiConfig(keycloak, initialized)
            )
                .then((res) => {
                    setState({
                        ...state,
                        person: res.data,
                        name: "saved",
                        action: "edit",
                    });
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed", action: "edit"});
                });
        } else if (state.name === "deleting") {
            API.delete<void>(`/people/${state.person.id}`, createApiConfig(keycloak, initialized))
                .then((res) => {
                    setState({
                        ...state,
                        name: "deleted",
                        action: "delete",
                    });
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed", action: "delete"});
                });
        }
    }, [keycloak, initialized, state, setState]);
    //== Handlers ===============================================================
    const handleEditSubmit = (person: PersonDTO): void => {
        setState({...state, updatedPerson: person, name: "saving"});
    };
    const handleEditStart = (): void => {
        setState({...state, name: "editing"});
    };
    const handleEditCancel = (): void => {
        setState({...state, name: "saved"});
    };
    const handleDelete = (): void => {
        setState({...state, name: "deleting"});
    };
    //== Render =================================================================
    if (state.name === "editing") {
        return (
            <PersonForm
                variant="outlined"
                person={state.person}
                onSubmit={handleEditSubmit}
                onCancel={handleEditCancel}
            />
        );
    }
    if (state.name === "saving" || state.name === "deleting") {
        return <Loading />;
    }
    let notification = null;
    if (state.name === "deleted") {
        return (
            <Redirect
                to={{
                    pathname: ROUTES.people.path,
                }}
            />
        );
    }
    if (state.name === "saved" && state.action === "edit") {
        notification = <SuccessNotification message={t(`people.notifications.${state.action}_ok`)} />;
    } else if (state.name === "failed") {
        notification = <ErrorNotification message={t(`people.notifications.${state.action}_fail`)} />;
    }
    // - default
    return (
        <Box>
            <PersonInfo person={state.person} onEdit={handleEditStart} onDelete={handleDelete} />
            {notification}
        </Box>
    );
};

interface PersonParams {
    personId: string;
}

export const Person: React.FC = () => {
    //== Init ===================================================================
    const [t] = useTranslation("user");
    const {keycloak, initialized} = useKeycloak();
    const {personId} = useParams<PersonParams>();
    const classes = useStyles();
    const initState: PersonState = {
        personId: personId,
        profile: null,
        name: "loading",
    };
    const [state, setState] = React.useState(initState);
    //== Effects ================================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<ProfileDTO>(`/profiles/${state.personId}`, createApiConfig(keycloak, initialized))
                .then((res) => {
                    setState({...state, profile: res.data, name: "loaded"});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [keycloak, initialized, state, setState]);
    //== Handlers ===============================================================
    //== Render =================================================================
    if (state.name === "loading") {
        return <Loading />;
    }
    if (state.name === "failed" || state.profile === null) {
        return <ServerCommunicationAlert />;
    }
    // - default
    const profile: ProfileDTO = state.profile;
    return (
        <Box>
            <div className={classes.fullWidth}>
                <BackLinkActionButton to={ROUTES.people.path} />
            </div>
            <h1>
                {makeFullName(profile.person)} <small>({state.personId})</small>
            </h1>
            <Grid container spacing={10}>
                <Grid item lg={6}>
                    <h2>
                        <Link href={ROUTES.profile.create(profile.person.publicId)}>{t("profile.profile")}</Link>
                    </h2>
                    <PersonDetails profile={profile} />
                </Grid>
                <Grid item lg={6}>
                    <h2>{t("emails.emails")}</h2>
                    <PersonEmails personUuid={profile.person.id} emails={profile.emails} admin />
                </Grid>
            </Grid>
        </Box>
    );
};

export default Person;
