import React, {ChangeEvent} from "react";
import {LibrarySimpleDTO} from "../../../models/library";
import {Container, TextField} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {compareByAttr} from "../../../utils/compare";
import {Alert} from "@material-ui/lab";

interface LibrariesListProps {
    libraries: LibrarySimpleDTO[];
    children: (library: LibrarySimpleDTO) => React.ReactElement<HTMLElement>;
}

interface LibrariesListState {
    filter: string;
}

function libraryFilter(filter: string) {
    const lc = filter.toLowerCase();
    return (library: LibrarySimpleDTO): boolean => {
        return library.name.toLowerCase().includes(lc);
    };
}

export const LibrariesList: React.FC<LibrariesListProps> = (props: LibrariesListProps) => {
    //== Init =================================================================
    const initState: LibrariesListState = {
        filter: "",
    };
    const [state, setState] = React.useState(initState);
    const [t] = useTranslation("libraries");
    const comparator = compareByAttr("name", "ascending");
    //== Handlers =============================================================
    const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
        setState({...state, filter: event.target.value});
    };
    //== Render ===============================================================
    const nameFilter = libraryFilter(state.filter);
    const libraries = props.libraries.filter(nameFilter).sort(comparator);
    return (
        <Container>
            <Container maxWidth="sm">
                <TextField
                    label={t("library.list.filter")}
                    value={state.filter}
                    onChange={handleFilterChange}
                    margin="normal"
                    fullWidth
                />
                {libraries.length === 0 && <Alert severity="info">{t("library.list.empty")}</Alert>}
            </Container>
            {libraries.map((library) => {
                return props.children(library);
            })}
        </Container>
    );
};
