import React, {ReactElement, useState} from "react";
import {useTranslation} from "react-i18next";
// Material UI imports
// Project imports
import {OrganizationProfileDTO} from "../../../../models/organization";
import {useKeycloak} from "@react-keycloak/web";
import {API, createApiConfig} from "../../../../utils";
import {AxiosError} from "axios";
import {isUser} from "../../../../utils/auth";
import FullMembershipRequestDialog from "./FullMembershipRequestDialog";
import {MenuItem, MenuItemProps} from "@material-ui/core";
import {SuccessNotification} from "../../../common/notifications";

interface FullMembershipRequestControlProps {
    organizationProfile: OrganizationProfileDTO;

    children: ReactElement<MenuItemProps, typeof MenuItem>;
}

const FullMembershipRequestControl: React.FC<FullMembershipRequestControlProps> = (
    props: FullMembershipRequestControlProps
) => {
    //== Init ===================================================================
    const {keycloak, initialized} = useKeycloak();
    const [t] = useTranslation(["organizationMembers", "common"]);
    const isMunicipality = !!props.organizationProfile.organization.municipality?.id;
    const [state, setState] = useState({
        name: (isUser(keycloak) && isMunicipality ? "loading" : "loaded") as "loading" | "loaded" | "sent" | "failed",
        canCreate: false,
        isOpen: false,
    });

    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<boolean>(
                "/full-membership-request/can-create-for-municipality/" +
                    props.organizationProfile.organization.municipality?.id,
                createApiConfig(keycloak, initialized)
            )
                .then((res) => {
                    setState({...state, name: "loaded", canCreate: res.data});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [props.organizationProfile.organization, state, setState]);
    const handleOpen = (): void => {
        setState({...state, isOpen: true});
    };
    const handleSent = (): void => {
        setState({...state, name: "sent", canCreate: false, isOpen: false});
    };
    const handleCanceled = (): void => {
        setState({...state, isOpen: false});
    };
    //== Render =================================================================

    if (state.name === "sent" && !state.canCreate)
        return <SuccessNotification message={t(`notifications.requests.create_ok`)} />;

    const dialog = (
        <FullMembershipRequestDialog
            organizationProfile={props.organizationProfile}
            onCancel={handleCanceled}
            onSend={handleSent}
            open={state.isOpen}
            key={"request-dialog"}
        />
    );
    if (!state.canCreate || state.name === "failed") return dialog;

    return (
        <>
            {React.cloneElement(props.children, {
                onClick: handleOpen,
            })}
            {dialog}
        </>
    );
};

export default FullMembershipRequestControl;
