import React from "react";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import {VersionInfoDialog} from "./VersionInfoDialog";

interface VersionInfoNavButton {
    title: string;
}

export const VersionInfoNavButton: React.FC<VersionInfoNavButton> = (props: VersionInfoNavButton) => {
    //== Init =================================================================
    const [open, setOpen] = React.useState(false);
    //== Render ===============================================================
    return (
        <>
            <Tooltip title={props.title}>
                <IconButton color="inherit" onClick={() => setOpen(true)}>
                    <InfoIcon />
                </IconButton>
            </Tooltip>
            <VersionInfoDialog open={open} onClose={() => setOpen(false)} />
        </>
    );
};
