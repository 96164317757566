import React from "react";
import clsx from "clsx";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useKeycloak} from "@react-keycloak/web";

import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Box, ThemeProvider} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

//import SCCIconPNG from "../../graphics/logo/SCC_icon.png";
//import SCCInvertedIconPNG from "../../graphics/logo/SCC_favicon_COL_invert.png";
//import SCCLogoPNG from "../../graphics/logo/SCC_logo_invert.png";
import SCCInvertedLogoPNG from "../../graphics/logo/SCC_logo_COL_invert.png";
//import SCCNewLogo from "../../graphics/logo/logo-scc-min.svg";
import {ProfileDTO} from "../../models/user";
import {API, createApiConfig} from "../../utils";
import {AxiosError, AxiosResponse} from "axios";

import {AdminMenu, MunicipalitiesMenu, PublicMenu, UserMenu} from "./menus";
import {TopMenu} from "./navbar";
import {THEMES} from "../../styles";
import {LoadingBackdrop} from "../common/Loading";
import {AppContextProvider} from "./context";

interface SCCompassAppProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: any;
}

interface SCCompassAppState {
    drawerOpen: boolean;
    lastWidth: number;
    loggedIn: boolean;
    profile: ProfileDTO | null;
    theme: "light" | "dark";
    name: "loading" | "loaded" | "failed";
}

const drawerWidth = 240;

function shouldOpenDrawer(width: number): boolean {
    return width - drawerWidth > 800;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            transition: theme.transitions.create(["margin", "width"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: `${drawerWidth}px`,
            transition: theme.transitions.create(["margin", "width"], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        appLogo: {
            maxHeight: "50px",
            marginRight: "10px",
        },
        appTitle: {
            flexGrow: 1,
        },
        mainDrawer: {
            width: drawerWidth,
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            flexShrink: 0,
        },
        mainDrawerPaper: {
            width: drawerWidth,
        },
        mainDrawerHeader: {
            display: "flex",
            alignItems: "center",
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
            justifyContent: "flex-start",
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: -drawerWidth,
        },
        contentShift: {
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        },
        hidden: {
            display: "none",
        },
    })
);
export const SCCompassApp: React.FC<SCCompassAppProps> = (props: SCCompassAppProps) => {
    // TODO: make use of profile in customizing menu or something else...
    //== Init =================================================================
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const classes = useStyles();
    const {keycloak, initialized} = useKeycloak();
    const initState: SCCompassAppState = {
        drawerOpen: false,
        lastWidth: -1,
        loggedIn: keycloak.authenticated ?? false,
        profile: null,
        theme: prefersDarkMode ? "dark" : "light",
        name: "loading",
    };
    const [state, setState] = React.useState(initState);
    const [keycloakWaiting, setKeycloakWaiting] = React.useState("init" as "init" | "waiting" | "done");
    //== Effects ==============================================================
    React.useEffect(() => {
        if (keycloakWaiting === "init") {
            setTimeout(() => {
                setKeycloakWaiting("done");
            }, 8000);
            setKeycloakWaiting("waiting");
        } else if (keycloakWaiting === "done" && !initialized && state.name === "loading") {
            console.log("failed to load keycloak - treating user as anonymous");
            setState({...state, name: "loaded"});
        }
    }, [state, initialized, keycloakWaiting, setKeycloakWaiting]);
    React.useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        console.log("[SCCompassApp-layout] useEffect: started");
        if (state.name === "loading" && initialized) {
            if (keycloak.authenticated) {
                API.get<ProfileDTO>("/profile", createApiConfig(keycloak, initialized))
                    .then((res: AxiosResponse<ProfileDTO>) => {
                        // TODO: here we can detect if user is NEW
                        setState({...state, profile: res.data, name: "loaded"});
                    })
                    .catch((err: AxiosError) => {
                        setState({...state, name: "failed"});
                    });
            } else {
                setState({...state, name: "loaded"});
            }
        }
        if (keycloak.authenticated && !state.loggedIn) {
            setState({...state, loggedIn: true, name: "loading"});
        }
        if (window.innerWidth === -1) {
            setState({...state, lastWidth: window.innerWidth});
        }
        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, [state, setState, keycloak, initialized]);
    //== Handlers =============================================================
    const handleDrawerOpen = (): void => {
        setState({...state, drawerOpen: true});
    };
    const handleDrawerClose = (): void => {
        setState({...state, drawerOpen: false});
    };
    const handleThemeChange = (theme: "light" | "dark"): void => {
        setState({...state, theme: theme});
    };
    const handleWindowResize = (): void => {
        if (window.innerWidth !== state.lastWidth) {
            setState({...state, lastWidth: window.innerWidth});
        }
    };
    //== Render ===============================================================
    const isDrawerOpen = state.drawerOpen || shouldOpenDrawer(state.lastWidth);
    return (
        <ThemeProvider theme={THEMES[state.theme]}>
            <Box style={{display: "flex"}}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: isDrawerOpen,
                    })}
                >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            className={clsx(classes.menuButton, isDrawerOpen && classes.hidden)}
                            disabled={shouldOpenDrawer(state.lastWidth)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <img src={SCCInvertedLogoPNG} alt="logo" className={classes.appLogo} />
                        <Typography variant="h6" className={classes.appTitle} />
                        <TopMenu activeTheme={state.theme} changeTheme={handleThemeChange} />
                    </Toolbar>
                </AppBar>
                <Drawer
                    className={classes.mainDrawer}
                    variant="persistent"
                    anchor="left"
                    open={isDrawerOpen}
                    classes={{
                        paper: classes.mainDrawerPaper,
                    }}
                >
                    <div className={classes.mainDrawerHeader}>
                        <IconButton onClick={handleDrawerClose} disabled={shouldOpenDrawer(state.lastWidth)}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <Divider />
                    <PublicMenu />
                    <UserMenu />
                    <MunicipalitiesMenu />
                    <AdminMenu />
                </Drawer>
                {state.name === "loading" && <LoadingBackdrop />}
                {state.name === "loaded" && (
                    <AppContextProvider profile={state.profile} drawer={isDrawerOpen}>
                        <main className={clsx(classes.content, {[classes.contentShift]: isDrawerOpen})}>
                            <div className={classes.mainDrawerHeader} />
                            <Container maxWidth="xl">{props.children}</Container>
                        </main>
                    </AppContextProvider>
                )}
            </Box>
        </ThemeProvider>
    );
};

export const SCCompassSimpleLayout: React.FC<SCCompassAppProps> = (props: SCCompassAppProps) => {
    // TODO: make use of profile in customizing menu or something else...
    //== Init =================================================================
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const initState: {theme: "light" | "dark"} = {
        theme: prefersDarkMode ? "dark" : "light",
    };
    //const [state, setState] = React.useState(initState);
    //== Handlers =============================================================
    /*
    const handleThemeChange = (theme: "light" | "dark"): void => {
        setState({...state, theme: theme});
    };
    */
    //== Render ===============================================================
    return (
        <ThemeProvider theme={THEMES[initState.theme]}>
            <CssBaseline />
            <main>{props.children}</main>
        </ThemeProvider>
    );
};

export default SCCompassApp;
