import React from "react";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
// Material UI imports
import {
    Box,
    Container,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
// Local imports
import UnitNew from "./UnitNew";
// Project imports
import {AddCircleActionButton, EditLinkActionButton, IdCopyButton, RefreshActionButton} from "../../common/buttons";
import {ServerCommunicationAlert} from "../../common/errors";
import {Loading} from "../../common/Loading";
import {InfoNotification} from "../../common/notifications";
import {UnitDTO} from "../../../models/library";
import ROUTES from "../../../routes/routes";
import {useStyles} from "../../../styles";
import {API, createApiConfig} from "../../../utils";
import {AxiosError} from "axios";
import {Page} from "../../../models/pagination";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";

interface UnitRowProps {
    unit: UnitDTO;
}

const UnitRow: React.FC<UnitRowProps> = (props: UnitRowProps) => {
    return (
        <TableRow>
            <TableCell>{props.unit.name}</TableCell>
            <TableCell>{props.unit.abbreviation}</TableCell>
            <TableCell>
                {props.unit.baseUnit !== null && `${props.unit.baseUnit?.name} [${props.unit.baseUnit?.abbreviation}]`}
            </TableCell>
            <TableCell align="right">
                <IdCopyButton text={props.unit.id} size="small" />
                <EditLinkActionButton to={ROUTES.unit.create(props.unit.id)} size="small" />
            </TableCell>
        </TableRow>
    );
};

export const UnitsTable: React.FC = () => {
    //== Init ===================================================================
    const [t] = useTranslation("libraries");
    const classes = useStyles();
    const {keycloak, initialized} = useKeycloak();
    const initState = {
        page: {
            size: 20,
            totalElements: 0,
            totalPages: 0,
            number: 0,
        },
        query: "",
        units: [] as UnitDTO[],
        name: "loading",
        action: "init",
    };
    const [state, setState] = React.useState(initState);
    //== Effects ================================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            let url = `/units?page=${state.page.number}&size=${state.page.size}&sort=name,asc`;
            if (state.query !== "") {
                url += `&query=${state.query}`;
            }
            API.get<Page<UnitDTO>>(url, createApiConfig(keycloak, initialized))
                .then((res) => {
                    setState({
                        ...state,
                        page: res.data,
                        units: res.data?.content ?? [],
                        name: "loaded",
                    });
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [keycloak, initialized, state, setState]);
    //== Handlers ===============================================================
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number): void => {
        setState({
            ...state,
            page: {...state.page, number: value - 1},
            name: "loading",
            action: "init",
        });
    };
    const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setState({...state, query: event.target.value});
    };
    const handleSearchSubmit = (): void => {
        setState({...state, name: "loading"});
    };
    const handleCreateClick = (): void => setState({...state, name: "creating"});
    //== Render =================================================================
    if (state.name === "loading") {
        return <Loading />;
    }
    if (state.name === "creating") {
        return <UnitNew />;
    }
    if (state.name === "failed") {
        return <ServerCommunicationAlert />;
    }
    // - default
    let notification: JSX.Element | null = null;
    if (state.name === "loaded" && state.action === "refresh") {
        notification = <InfoNotification message={t("definitions.units.notifications.refresh_ok")} />;
    }
    const rows = state.units.map((unit: UnitDTO) => <UnitRow key={unit.id} unit={unit} />);
    return (
        <Container maxWidth="lg">
            <Box display="flex" justifyContent="center">
                <Box flexGrow={1}>
                    {state.page.totalPages > 1 && (
                        <Pagination
                            style={{marginTop: "1em"}}
                            count={state.page.totalPages}
                            page={state.page.number + 1}
                            onChange={handlePageChange}
                        />
                    )}
                </Box>
                <form onSubmit={handleSearchSubmit} style={{width: "auto"}}>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <TextField
                            value={state.query}
                            onChange={handleQueryChange}
                            label={t("definitions.units.query")}
                            margin="dense"
                            variant="outlined"
                            style={{minWidth: "20em", marginRight: "1em"}}
                        />
                        <IconButton onClick={handleSearchSubmit} size="small">
                            <SearchIcon />
                        </IconButton>
                        <IconButton onClick={handleCreateClick} size="small">
                            <AddIcon />
                        </IconButton>
                    </Box>
                </form>
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="left">{t("definitions.units.name")}</TableCell>
                        <TableCell align="center">{t("definitions.units.abbreviation")}</TableCell>
                        <TableCell align="center">{t("definitions.units.base_unit")}</TableCell>
                        <TableCell align="right" style={{minWidth: "8em"}}>
                            <RefreshActionButton
                                size="small"
                                onClick={(): void =>
                                    setState({
                                        ...initState,
                                        action: "refresh",
                                    })
                                }
                            />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{rows}</TableBody>
            </Table>
            {state.page.totalPages > 1 && (
                <Pagination
                    style={{marginTop: "1em"}}
                    count={state.page.totalPages}
                    page={state.page.number + 1}
                    onChange={handlePageChange}
                />
            )}
            {notification}
            <div className={classes.fabWrapper}>
                <AddCircleActionButton onClick={(): void => setState({...state, name: "creating"})} />
            </div>
        </Container>
    );
};

export default UnitsTable;
