import React, {ChangeEvent, useCallback} from "react";
import TextField, {BaseTextFieldProps} from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import {SimpleMunicipalityDTO} from "../../../models/organization";
import API, {createApiConfig} from "../../../utils/API";
import {AxiosError} from "axios";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
import {AutocompleteChangeDetails, AutocompleteChangeReason} from "@material-ui/lab/useAutocomplete/useAutocomplete";
import {makeStyles} from "@material-ui/core/styles";

export interface MunicipalitySelectProps extends BaseTextFieldProps {
    value?: SimpleMunicipalityDTO | null;
    variant?: "standard" | "filled" | "outlined";
    onChange: (
        //eslint-disable-next-line @typescript-eslint/ban-types
        event: ChangeEvent<{}>,
        value: SimpleMunicipalityDTO | null,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<SimpleMunicipalityDTO>
    ) => void;
}

const useStyles = makeStyles({
    option: {
        "& > span": {
            marginLeft: "auto",
            fontSize: "75%",
        },
    },
});

const MunicipalitySelect: React.FC<MunicipalitySelectProps> = (props: MunicipalitySelectProps) => {
    const classes = useStyles();
    const {keycloak, initialized} = useKeycloak();
    const [t] = useTranslation(["organization"]);

    const [valueToSearch, setValue] = React.useState(props.value?.name || "");
    const isValueTooShort = useCallback(
        (): boolean => !valueToSearch || (valueToSearch as string).length < 2,
        [valueToSearch]
    );
    const [state, setState] = React.useState("init");
    const [options, setOptions] = React.useState<SimpleMunicipalityDTO[]>(props.value ? [props.value] : []);
    const [optionsByText, setOptionsByText] = React.useState<Map<string, SimpleMunicipalityDTO[]>>(new Map());
    const [selectedOption, setSelectedOption] = React.useState<SimpleMunicipalityDTO | null | undefined>(props.value);

    React.useEffect(() => {
        let active = true;

        if (state !== "to_load") {
            return undefined;
        }

        if (isValueTooShort()) {
            setOptions([]);
            setState("not_loaded");
            return undefined;
        }

        (async () => {
            for (let i = valueToSearch.length; i >= 2; i--) {
                if (optionsByText.has(valueToSearch.substr(0, i)) && active) {
                    setOptions(optionsByText.get(valueToSearch.substr(0, i)) || []);
                    setState("loaded");
                    return;
                }
            }

            API.get<SimpleMunicipalityDTO[]>(
                `/organizations/search?name=` + encodeURI(valueToSearch),
                createApiConfig(keycloak, initialized)
            )
                .then((res) => {
                    const municipalities = res.data ?? [];
                    setOptionsByText(
                        new Map(
                            (function* () {
                                yield* optionsByText;
                                yield* new Map().set(valueToSearch, municipalities);
                            })()
                        )
                    );

                    if (active) {
                        setOptions(municipalities);
                        setState("loaded");
                    }
                })
                .catch((err: AxiosError) => {
                    setOptions([]);
                    setState("not_loaded");
                });
        })();

        return () => {
            active = false;
            setState("not_loaded");
        };
    }, [state, valueToSearch, keycloak, initialized, isValueTooShort, optionsByText]);

    React.useEffect(() => {
        if (state === "closed") {
            setOptions([]);
        }
    }, [state]);

    return (
        <Autocomplete
            id={props.id}
            style={props.style}
            classes={{
                option: classes.option,
            }}
            open={state !== "closed" && state !== "init"}
            onOpen={() => {
                setState("to_load");
            }}
            onClose={() => {
                setState("closed");
            }}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            value={selectedOption}
            options={options}
            loading={state === "to_load" && !isValueTooShort()}
            loadingText={t("notifications.loading") + "..."}
            onInputChange={(e, value): void => {
                if (state === "init") {
                    setState("closed");
                    return;
                }
                setValue(value);
                setState("to_load");
            }}
            noOptionsText={
                isValueTooShort()
                    ? t("notifications.fill_at_least_two_name_chars")
                    : t("notifications.no_municipality_found") +
                      " " +
                      t("notifications.starting_with") +
                      ' "' +
                      valueToSearch +
                      '"'
            }
            onChange={(
                //eslint-disable-next-line @typescript-eslint/ban-types
                event: ChangeEvent<{}>,
                value: SimpleMunicipalityDTO | null,
                reason: AutocompleteChangeReason,
                details?: AutocompleteChangeDetails<SimpleMunicipalityDTO>
            ) => {
                setSelectedOption(value);
                props.onChange(event, value, reason, details);
            }}
            renderOption={(option) => (
                <React.Fragment>
                    {option.name}
                    <span>{t("type.COUNTY").toLowerCase() + " " + t("county." + option.lau2.substring(0, 6))}</span>
                </React.Fragment>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.label}
                    variant={props.variant}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {state === "to_load" && !isValueTooShort() ? (
                                    <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
};

export default MunicipalitySelect;
