import React, {ChangeEvent} from "react";
import {OperationType} from "../../../../../models/formulas";
import {Box, TextField} from "@material-ui/core";
import {FormulaOperationSelect} from "./FormulaOperationSelect";
import {FormulaNode, getDefaultOperands, getDefaultOperationValue} from "../../../../../models/formulaEditor";
import {makeStyles} from "@material-ui/core/styles";

interface FormulaConstFormProps {
    node: FormulaNode;
    onChange: (node: FormulaNode) => void;
}

const useStyles = makeStyles((theme) => ({
    valueInput: {
        minWidth: "5em",
        textAlign: "right",
        paddingRight: "0.1em",
    },
}));

export const FormulaConstForm: React.FC<FormulaConstFormProps> = (props: FormulaConstFormProps) => {
    //== Init =================================================================
    const classes = useStyles();
    //== Handlers =============================================================
    const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
        props.onChange({...props.node, value: event.target.value});
    };
    const handleOperationChange = (operation: OperationType) => {
        props.onChange({
            ...props.node,
            opType: operation,
            value: getDefaultOperationValue(operation),
            operands: getDefaultOperands(operation),
        });
    };
    //== Render ===============================================================
    return (
        <Box>
            <FormulaOperationSelect value={OperationType.CONST} onChange={handleOperationChange} />
            <TextField
                value={props.node.value}
                onChange={handleValueChange}
                type="number"
                className={classes.valueInput}
                inputProps={{
                    className: classes.valueInput,
                }}
            />
        </Box>
    );
};
