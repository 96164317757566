import React from "react";
import {useTranslation} from "react-i18next";
// Project imports
import {CustomTabs, TabContent} from "../../common/tabs";
// Material-UI imports
import {Box, Tab} from "@material-ui/core";
import {RouteComponentProps} from "react-router-dom";
import OrganizationPositionsIndex from "./Positions";
import OrganizationMembersIndex from "./Members";
import {BackLinkActionButton} from "../../common/buttons";
import {ROUTES} from "../../../routes/routes";

type MemberManagementRouteParams = {
    organizationId: string;
};

export interface MemberManagementRouteProps
    extends RouteComponentProps<MemberManagementRouteParams>,
        React.ClassAttributes<MemberManagementRouteParams> {}

interface MemberManagementProps {
    organizationId: string;
    tab?: "positions" | "members";
}

const tabToValue = (str?: string): number => (str === "positions" ? 1 : 0);

export const OrganizatonMemberships: React.FC<MemberManagementProps> = (props: MemberManagementProps) => {
    //== Init ===================================================================
    const [t] = useTranslation("organizationMembers");
    const [value, setValue] = React.useState(tabToValue(props.tab));
    //== Handlers ===============================================================
    //eslint-disable-next-line @typescript-eslint/ban-types
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number): void => {
        setValue(newValue);
    };
    console.log("organizationId = " + props.organizationId);
    //== Render =================================================================
    return (
        <Box>
            <BackLinkActionButton to={ROUTES.organization.create(props.organizationId)} />
            <CustomTabs
                style={{marginBottom: "2em"}}
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
                <Tab label={t("position.members")} />
                <Tab label={t("position.positions")} />
            </CustomTabs>
            <TabContent index={0} value={value}>
                <OrganizationMembersIndex organizationId={props.organizationId} />
            </TabContent>
            <TabContent index={1} value={value}>
                <OrganizationPositionsIndex organizationId={props.organizationId} />
            </TabContent>
        </Box>
    );
};

export const OrganizatonMembershipsMembers: React.FC<MemberManagementRouteProps> = (
    props: MemberManagementRouteProps
) => {
    return <OrganizatonMemberships tab="members" organizationId={props.match.params.organizationId} />;
};

export const OrganizatonMembershipsPositions: React.FC<MemberManagementRouteProps> = (
    props: MemberManagementRouteProps
) => {
    return <OrganizatonMemberships tab="positions" organizationId={props.match.params.organizationId} />;
};

export default OrganizatonMemberships;
