import React from "react";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
// Material UI imports
import {Table, TableBody, TableCell, TableRow} from "@material-ui/core";
// Project imports
import API, {createApiConfig} from "../../../../utils/API";
import {AxiosError} from "axios";
import {TrackedIndicatorGroupSimpleDTO, TrackedIndicatorGroupUpdateDTO} from "../../../../models/trackedIndicators";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {EditActionButton, SaveActionButton} from "../../../common/buttons";
import Loading from "../../../common/Loading";
import {SuccessNotification} from "../../../common/notifications";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            maxWidth: "50em",
        },
        labelCell: {
            border: "0",
        },
        contentCell: {
            border: "0",
            width: "100%",
            paddingBottom: 0,
            paddingTop: 0,
        },
        actionsCell: {
            border: "0",
            paddingBottom: 0,
            paddingTop: 0,
        },
    })
);

export interface TrackedIndicatorGroupDetailsEditProps {
    trackedIndicatorGroup: TrackedIndicatorGroupSimpleDTO;

    onChange(trackedIndicatorGroup: TrackedIndicatorGroupSimpleDTO): void;
}

const GroupDetailsEdit: React.FC<TrackedIndicatorGroupDetailsEditProps> = (
    props: TrackedIndicatorGroupDetailsEditProps
) => {
    const classes = useStyles();
    const [t] = useTranslation("trackedIndicators");
    const {keycloak, initialized} = useKeycloak();
    const [state, setState] = React.useState({
        trackedIndicatorGroup: props.trackedIndicatorGroup,
        name: "init" as "init" | "saved" | "savingName" | "savingDescription" | "failed",
    });
    const [nameField, setNameField] = React.useState({
        edit: false,
        content: state.trackedIndicatorGroup.name,
    });
    const [descriptionField, setDescriptionField] = React.useState({
        edit: false,
        content: state.trackedIndicatorGroup.description,
    });
    //== Effects ================================================================
    React.useEffect(() => {
        if (state.name === "savingName" || state.name === "savingDescription") {
            const newTrackedIndicatorGroup: TrackedIndicatorGroupUpdateDTO = {
                ...state.trackedIndicatorGroup,
                name: state.name === "savingName" ? nameField.content : state.trackedIndicatorGroup.name,
                description:
                    state.name === "savingDescription"
                        ? descriptionField.content
                        : state.trackedIndicatorGroup.description,
            };
            API.put<TrackedIndicatorGroupSimpleDTO>(
                `/tracked-indicator-groups/simple/${props.trackedIndicatorGroup.id}`,
                newTrackedIndicatorGroup,
                createApiConfig(keycloak, initialized)
            )
                .then((res) => {
                    setState({
                        ...state,
                        trackedIndicatorGroup: res.data,
                        name: "saved",
                    });
                    if (state.name === "savingName") setNameField({edit: false, content: res.data.name});
                    else if (state.name === "savingDescription")
                        setDescriptionField({edit: false, content: res.data.description});
                    props.onChange(res.data);
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
        if (props.trackedIndicatorGroup !== state.trackedIndicatorGroup) {
            setState({...state, trackedIndicatorGroup: props.trackedIndicatorGroup});
            setNameField({edit: false, content: props.trackedIndicatorGroup.name});
            setDescriptionField({edit: false, content: props.trackedIndicatorGroup.description});
        }
    }, [keycloak, initialized, state, setState, props.trackedIndicatorGroup]);

    const handleNameEdit = () => {
        setNameField({...nameField, edit: true});
    };
    const handleNameSave = () => {
        setState({...state, name: "savingName"});
    };

    const handleDescriptionEdit = () => {
        setDescriptionField({...descriptionField, edit: true});
    };
    const handleDescriptionSave = () => {
        setState({...state, name: "savingDescription"});
    };

    let notification: JSX.Element | null = null;
    if (state.name === "saved") {
        notification = <SuccessNotification message={t(`trackedIndicatorGroupDetailEditNotifications.save_ok`)} />;
    }
    return (
        <>
            <Table className={classes.table}>
                <TableBody>
                    <TableRow>
                        <TableCell className={classes.labelCell}>{t("trackedIndicatorGroup.name")}:</TableCell>
                        <TableCell className={classes.contentCell}>
                            {state.name === "savingName" ? (
                                <Loading />
                            ) : nameField.edit ? (
                                <TextField
                                    id="name"
                                    value={nameField.content}
                                    fullWidth
                                    required
                                    onChange={(e): void =>
                                        setNameField({
                                            ...nameField,
                                            content: e.target.value,
                                        })
                                    }
                                />
                            ) : (
                                nameField.content
                            )}
                        </TableCell>
                        <TableCell className={classes.actionsCell} align="left">
                            {state.name === "savingName" ? null : nameField.edit ? (
                                <SaveActionButton onClick={() => handleNameSave()} />
                            ) : (
                                <EditActionButton onClick={() => handleNameEdit()} />
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.labelCell}>{t("trackedIndicatorGroup.description")}:</TableCell>
                        <TableCell className={classes.contentCell}>
                            {state.name === "savingDescription" ? (
                                <Loading />
                            ) : descriptionField.edit ? (
                                <TextField
                                    id="description"
                                    value={descriptionField.content === null ? "" : descriptionField.content}
                                    fullWidth
                                    required
                                    onChange={(e): void =>
                                        setDescriptionField({
                                            ...descriptionField,
                                            content: e.target.value === "" ? null : e.target.value,
                                        })
                                    }
                                />
                            ) : (
                                descriptionField.content
                            )}
                        </TableCell>
                        <TableCell className={classes.actionsCell} align="left">
                            {state.name === "savingDescription" ? null : descriptionField.edit ? (
                                <SaveActionButton onClick={() => handleDescriptionSave()} />
                            ) : (
                                <EditActionButton onClick={() => handleDescriptionEdit()} />
                            )}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            {notification}
        </>
    );
};

export default GroupDetailsEdit;
