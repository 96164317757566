import React from "react";
import {FormulaNode, parseFormulaNodes, reconstructFormula} from "../../../../models/formulaEditor";
import {Box, Divider, TextField} from "@material-ui/core";
import {ConversionNodeForm} from "./parts/ConversionNodeForm";

interface ConversionFieldProps {
    formula: string;
    showField: boolean;
    onChange: (formula: string) => void;
}

export const ConversionField: React.FC<ConversionFieldProps> = (props: ConversionFieldProps) => {
    //== Handlers =============================================================
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(event.target.value);
    };
    const handleChangeRootNode = (index: number, node: FormulaNode) => {
        if (index !== 0) {
            return;
        }
        props.onChange(reconstructFormula(node));
    };
    //== Render ===============================================================
    const rootNode: FormulaNode | undefined = parseFormulaNodes(props.formula, true)[0];
    return (
        <Box>
            {rootNode !== undefined && <ConversionNodeForm index={0} node={rootNode} onChange={handleChangeRootNode} />}
            {props.showField && (
                <Box>
                    <Divider style={{margin: "1em"}} />
                    <TextField value={props.formula} onChange={handleChange} variant="outlined" multiline fullWidth />
                </Box>
            )}
        </Box>
    );
};
