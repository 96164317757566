import React from "react";
import {CommunicationError, NotFound} from "../../../common/errors";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
import Loading from "../../../common/Loading";
import {Container} from "@material-ui/core";
import {RefreshActionButton} from "../../../common/buttons";
import {API, createApiConfig} from "../../../../utils";
import {
    DashboardIndicatorDTO,
    DashboardIndicatorUpdateDTO,
    TrackedIndicatorInGroupDTO,
} from "../../../../models/trackedIndicators";
import {InfoNotification} from "../../../common/notifications";
import {OrganizationPermissionsDTO} from "../../../../models/members";
import {PageHeader} from "../../../common/headers";
import {AxiosError, AxiosResponse} from "axios";
import DashboardIndicatorsAdd from "./adding/DashboardIndicatorsAdd";
import {DashboardIndicatorsGrid} from "./DashboardIndicatorsGrid";
import {SimpleMunicipalityDTO} from "../../../../models/organization";
import {DashboardDTO} from "../../../../models/user";

//=============================================================================

interface IndicatorsDashboardState {
    name: "loading" | "loaded" | "refreshing" | "refreshed" | "not_found" | "failed";
    dashboardIndicators: DashboardIndicatorDTO[];
}

interface PersonalIndicatorsDashboardProps {
    permissions: null;
    simpleMunicipality: null;
    dashboard: DashboardDTO;
}

interface OrganizationIndicatorsDashboardProps {
    permissions: OrganizationPermissionsDTO;
    simpleMunicipality: SimpleMunicipalityDTO;
    dashboard: null;
}

export const IndicatorsDashboard: React.FC<PersonalIndicatorsDashboardProps | OrganizationIndicatorsDashboardProps> = (
    props: PersonalIndicatorsDashboardProps | OrganizationIndicatorsDashboardProps
) => {
    //== Init =================================================================
    console.log("[IndicatorsDashboard] props:");
    console.log(props);
    const organizationId = props.permissions?.organizationId || null;
    const initState: IndicatorsDashboardState = {
        name: "loading",
        dashboardIndicators: [],
    };
    const [state, setState] = React.useState(initState);
    const {keycloak, initialized} = useKeycloak();
    const [t] = useTranslation(["trackedIndicators"]);

    //== Effects ==============================================================
    function update() {
        console.log("[IndicatorsDashboard] update - organizationId: " + organizationId);
        API.get<DashboardIndicatorDTO[]>(
            "/dashboard-indicator/" + (organizationId ? `organization/${organizationId}` : "personal"),
            createApiConfig(keycloak, initialized)
        )
            .then((getAllRes) => {
                setState({
                    ...state,
                    name: state.name == "refreshing" ? "refreshed" : "loaded",
                    dashboardIndicators: getAllRes.data,
                });
            })
            .catch((err: AxiosError) => {
                setState({...state, name: "failed"});
            });
    }

    React.useEffect(() => {
        if (state.name === "loading" || state.name === "refreshing") {
            update();
        }
    }, [state, setState, keycloak, initialized]);
    //== Permissions ==========================================================
    const canEditDashboard =
        (!props.simpleMunicipality && !props.permissions) ||
        props.permissions.trackedIndicatorsPermissionLevel === "READWRITE";
    //== Handlers =============================================================
    const handleRefresh = (): void => {
        setState({...state, name: "refreshing"});
    };
    const processChange = (promise: Promise<AxiosResponse<DashboardIndicatorDTO | null>>): void => {
        promise
            .then((res) => {
                setState({...state, name: "loading"});
            })
            .catch((err: AxiosError) => {
                setState({...state, name: "failed"});
            });
    };
    const handleRemove = (dashboardIndicator: DashboardIndicatorDTO): void => {
        processChange(
            API.delete<null>(`/dashboard-indicator/${dashboardIndicator.id}`, createApiConfig(keycloak, initialized))
        );
    };
    const handleAdd = (trackedIndicator: TrackedIndicatorInGroupDTO): void => {
        processChange(
            API.post<DashboardIndicatorDTO, AxiosResponse<DashboardIndicatorDTO>, DashboardIndicatorUpdateDTO>(
                `/dashboard-indicator`,
                {
                    orderNumber: state.dashboardIndicators.filter((each) => each.addedByUser).length,
                    trackedIndicatorId: trackedIndicator.id,
                    personal: !organizationId,
                },
                createApiConfig(keycloak, initialized)
            )
        );
    };
    const handleAddMultiple = (trackedIndicators: TrackedIndicatorInGroupDTO[]): void => {
        trackedIndicators.forEach((trackedIndicator) => handleAdd(trackedIndicator));
    };
    const handleMove = (dashboardIndicator: DashboardIndicatorDTO, targetIndex: number): void => {
        if (dashboardIndicator.orderNumber === targetIndex) return;
        const indicatorsWithoutMoved = state.dashboardIndicators
            .slice(0, dashboardIndicator.orderNumber)
            .concat(state.dashboardIndicators.slice(dashboardIndicator.orderNumber + 1));
        const indicatorsWithMoved = indicatorsWithoutMoved
            .slice(0, targetIndex)
            .concat([dashboardIndicator])
            .concat(indicatorsWithoutMoved.slice(targetIndex));
        setState({
            ...state,
            dashboardIndicators: indicatorsWithMoved,
        });
        processChange(
            API.post<DashboardIndicatorDTO, AxiosResponse<DashboardIndicatorDTO>, DashboardIndicatorUpdateDTO>(
                `/dashboard-indicator`,
                {
                    orderNumber: targetIndex,
                    trackedIndicatorId: dashboardIndicator.trackedIndicator.id,
                    personal: false,
                },
                createApiConfig(keycloak, initialized)
            )
        );
    };
    //== Render ===============================================================
    console.log("canEditDashboard:" + canEditDashboard);
    return (
        <Container maxWidth="xl">
            {state.name === "loading" || state.name === "refreshing" ? (
                <Loading />
            ) : state.name === "not_found" ? (
                <NotFound />
            ) : state.name === "failed" ? (
                <CommunicationError />
            ) : (
                <>
                    {canEditDashboard ? (
                        <PageHeader title="">
                            <RefreshActionButton onClick={handleRefresh} />
                            <span style={{minWidth: 5}}></span>
                            <DashboardIndicatorsAdd
                                organizationId={organizationId}
                                currentDashboardIndicators={state.dashboardIndicators}
                                dashboard={props.dashboard}
                                onAdd={handleAddMultiple}
                            />
                        </PageHeader>
                    ) : null}
                    <DashboardIndicatorsGrid
                        dashboardIndicators={state.dashboardIndicators}
                        organizationId={organizationId}
                        permissions={props?.permissions}
                        onRemove={handleRemove}
                        onMove={handleMove}
                    />
                </>
            )}
            {state.name === "refreshed" && <InfoNotification message={t("dashboard.notifications.refreshed")} />}
        </Container>
    );
};
