import React from "react";
import {LibraryDTO, LibrarySimpleDTO} from "../../../models/library";
import {useTranslation} from "react-i18next";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from "@material-ui/core";
import {MarkdownRender} from "../../common/markdown";
import {Tags} from "../../common/tags";
import {Link} from "react-router-dom";
import ROUTES from "../../../routes/routes";
import {useStyles} from "../../../styles";

interface LibraryDialogProps {
    library: LibrarySimpleDTO | LibraryDTO;
    open: boolean;

    handleClose(): void;
}

export const LibraryDialog: React.FC<LibraryDialogProps> = (props: LibraryDialogProps) => {
    //== Init =================================================================
    const [t] = useTranslation(["libraries"]);
    const classes = useStyles();
    //== Render ===============================================================
    return (
        <Dialog open={props.open} onClose={props.handleClose} scroll="paper" maxWidth="md" fullWidth>
            <DialogTitle>{t("library.dialog.title", {name: props.library.name})}</DialogTitle>
            <DialogContent>
                <MarkdownRender value={props.library.description ?? ""} />
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell className={classes.minTableCell}>{t("library.version")}</TableCell>
                            <TableCell>{props.library.version}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.minTableCell}>{t("library.license")}</TableCell>
                            <TableCell>{props.library.license}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Tags tags={props.library.tags} />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>{t("library.dialog.close")}</Button>
                <Button component={Link} to={ROUTES.library.create(props.library.id)}>
                    {t("library.dialog.visit")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
