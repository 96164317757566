import {
    DomainDTO,
    GoalDTO,
    IndicatorDTO,
    LibraryDomainUseDTO,
    LibraryDTO,
    LibraryGoalUseDTO,
    LibraryIndicatorUseDTO,
} from "../../../../../models/library";

export interface TrackedIndicatorGroupEditorAddingIndicator extends IndicatorDTO {
    checked: boolean;
    alreadyAdded: boolean;
}

export interface GoalWithContent extends GoalDTO {
    indicators: IndicatorDTO[];
}

export interface TrackedIndicatorGroupEditorAddingGoal extends GoalWithContent {
    checked: boolean;
    alreadyAdded: boolean;
    indicators: TrackedIndicatorGroupEditorAddingIndicator[];
}

export interface DomainWithContent extends DomainDTO {
    goals: GoalWithContent[];
}

export interface TrackedIndicatorGroupEditorAddingDomain extends DomainWithContent {
    checked: boolean;
    alreadyAdded: boolean;
    goals: TrackedIndicatorGroupEditorAddingGoal[];
}

export function fromLibraryIndicatorUseToAddingIndicator(
    libraryIndicatorUse: LibraryIndicatorUseDTO
): TrackedIndicatorGroupEditorAddingIndicator {
    return {
        ...libraryIndicatorUse.indicator,
        checked: false,
        alreadyAdded: false,
    };
}

export function fromLibraryGoalUseToAddingGoal(
    libraryGoalUse: LibraryGoalUseDTO
): TrackedIndicatorGroupEditorAddingGoal {
    return {
        ...libraryGoalUse.goal,
        checked: false,
        alreadyAdded: false,
        indicators: libraryGoalUse.libraryIndicatorUses.map((u) => fromLibraryIndicatorUseToAddingIndicator(u)),
    };
}

export function fromLibraryDomainUseToAddingDomain(
    libraryDomainUse: LibraryDomainUseDTO
): TrackedIndicatorGroupEditorAddingDomain {
    return {
        ...libraryDomainUse.domain,
        checked: false,
        alreadyAdded: false,
        goals: libraryDomainUse.libraryGoalUses.map((u) => fromLibraryGoalUseToAddingGoal(u)),
    };
}

export function fromLibraryUseToAddingDomains(library: LibraryDTO): TrackedIndicatorGroupEditorAddingDomain[] {
    return library.libraryDomainUses.map((u) => fromLibraryDomainUseToAddingDomain(u));
}
