import React from "react";
import {Box, Button, Container, Table, TableBody, TableCell, TableHead, TableRow, TextField} from "@material-ui/core";
import {DeleteActionButton, EditActionButton, RefreshActionButton} from "../common/buttons";
import {getCssTagStyle, loadTagStyle, TagCategoryCreateDTO, TagCategoryDTO, TagDTO} from "../../models/admin";
import Utils, {API, createApiConfig} from "../../utils";
import {AxiosError} from "axios";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
import {Tag} from "../common/tags";
import {useForm} from "react-hook-form";
import {useStyles} from "../../styles";
import {ErrorNotification, SuccessNotification} from "../common/notifications";
import {Loading} from "../common/Loading";
import {ServerCommunicationAlert} from "../common/errors";
import {comparator} from "../../utils/compare";
import {TagCategoryDelete} from "./TagCategoryDelete";

interface TagCategoryRowProps {
    category: TagCategoryDTO;

    onEdit: (category: TagCategoryDTO) => void;
    onDelete: (category: TagCategoryDTO) => void;
}

const TagCategoryRow: React.FC<TagCategoryRowProps> = (props: TagCategoryRowProps) => {
    //== Init =================================================================
    const [t] = useTranslation("common");
    //== Handlers =============================================================
    const handleEdit = () => {
        props.onEdit(props.category);
    };
    const handleDelete = () => {
        props.onDelete(props.category);
    };
    //== Render ===============================================================
    const exemplaryTag: TagDTO = {
        id: "exemplary",
        createdAt: "-",
        updatedAt: "-",
        name: t("tags.categories.exemplary.name"),
        description: t("tags.categories.exemplary.description"),
        category: props.category,
    };
    return (
        <TableRow>
            <TableCell align="left">{props.category.name}</TableCell>
            <TableCell align="left">{props.category.description}</TableCell>
            <TableCell align="right">{props.category.priority}</TableCell>
            <TableCell align="center">
                <Tag tag={exemplaryTag} />
            </TableCell>
            <TableCell align="right">
                <EditActionButton onClick={handleEdit} />
                <DeleteActionButton onClick={handleDelete} />
            </TableCell>
        </TableRow>
    );
};

interface TagCategoryFormProps {
    category: TagCategoryCreateDTO;

    onSubmit: (category: TagCategoryCreateDTO) => void;
    onCancel?: () => void;
}

interface TagCategoryFormModel {
    name: string;
    description: string;
    priority: number;
    styleBackground: string;
    styleTextColor: string;
    styleIconColor: string;
    icon: string;
}

function tagCategoryFormModel2Create(model: TagCategoryFormModel): TagCategoryCreateDTO {
    return {
        name: model.name,
        description: model.description,
        priority: model.priority,
        style: getCssTagStyle({
            background: model.styleBackground,
            textColor: model.styleTextColor,
            iconColor: model.styleIconColor,
        }),
        icon: model.icon,
    };
}

const TagCategoryForm: React.FC<TagCategoryFormProps> = (props: TagCategoryFormProps) => {
    //== Init =================================================================
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<TagCategoryFormModel>();
    const [t] = useTranslation("common");
    const classes = useStyles();
    const [state, setState] = React.useState(props.category);
    const stateHash = Utils.hashCode(JSON.stringify(state));
    const exampleTag: TagDTO = {
        id: "",
        createdAt: "",
        updatedAt: "",
        name: t("tags.categories.exemplary.name"),
        description: t("tags.categories.exemplary.description"),
        category: {
            id: "",
            name: state.name,
            description: state.description,
            priority: state.priority,
            style: state.style,
            icon: state.icon,
        },
    };
    //== Handlers =============================================================
    const actStyle = loadTagStyle(state.style);
    const handleBackgroundChange = (value: string): void => {
        const style = {...actStyle, background: value};
        setState({...state, style: getCssTagStyle(style)});
    };
    const handleTextColorChange = (value: string): void => {
        const style = {...actStyle, textColor: value};
        setState({...state, style: getCssTagStyle(style)});
    };
    const handleIconColorChange = (value: string): void => {
        const style = {...actStyle, iconColor: value};
        setState({...state, style: getCssTagStyle(style)});
    };
    const handleXSubmit = (model: TagCategoryFormModel): void => {
        props.onSubmit(tagCategoryFormModel2Create(model));
    };
    //== Render ===============================================================
    const nameRegister = register("name", {
        required: {value: true, message: t("validation.required")},
        maxLength: {
            value: 250,
            message: t("validation.maxLength", {name: t("tags.categories.name"), value: "250"}),
        },
    });
    const descriptionRegister = register("description");
    const priorityRegister = register("priority");
    const styleBackgroundRegister = register("styleBackground");
    const styleTextColorRegister = register("styleTextColor");
    const styleIconColorRegister = register("styleIconColor");
    const iconRegister = register("icon");
    return (
        <form onSubmit={handleSubmit(handleXSubmit)} noValidate>
            <TextField
                {...nameRegister}
                label={t("tags.categories." + nameRegister.name)}
                value={state.name}
                inputRef={nameRegister.ref}
                fullWidth
                required
                variant="outlined"
                className={classes.spaceAfter}
                onChange={(e): void => setState({...state, name: e.target.value})}
                error={errors.name !== undefined}
                helperText={errors.name && errors.name.message}
            />
            <TextField
                {...descriptionRegister}
                label={t("tags.categories." + descriptionRegister.name)}
                value={state.description}
                inputRef={descriptionRegister.ref}
                fullWidth
                multiline
                variant="outlined"
                className={classes.spaceAfter}
                onChange={(e): void => setState({...state, description: e.target.value})}
                error={errors.description !== undefined}
                helperText={errors.description && errors.description.message}
            />
            <TextField
                {...priorityRegister}
                label={t("tags.categories." + priorityRegister.name)}
                value={isNaN(state.priority) ? "" : state.priority}
                inputRef={priorityRegister.ref}
                type="number"
                fullWidth
                variant="outlined"
                className={classes.spaceAfter}
                onChange={(e): void => setState({...state, priority: parseInt(e.target.value)})}
                error={
                    errors.styleBackground !== undefined ||
                    errors.styleTextColor !== undefined ||
                    errors.styleIconColor !== undefined
                }
                helperText={t("tags.orderInfo")}
            />
            <TextField
                {...styleBackgroundRegister}
                label={t("tags.categories." + styleBackgroundRegister.name)}
                variant="outlined"
                value={actStyle.background}
                inputRef={styleBackgroundRegister.ref}
                fullWidth
                className={classes.spaceAfter}
                onChange={(e): void => handleBackgroundChange(e.target.value)}
            />
            <TextField
                {...styleTextColorRegister}
                label={t("tags.categories." + styleTextColorRegister.name)}
                variant="outlined"
                value={actStyle.textColor}
                inputRef={styleTextColorRegister.ref}
                fullWidth
                className={classes.spaceAfter}
                onChange={(e): void => handleTextColorChange(e.target.value)}
            />
            <TextField
                {...styleIconColorRegister}
                label={t("tags.categories." + styleIconColorRegister.name)}
                variant="outlined"
                value={actStyle.iconColor}
                inputRef={styleIconColorRegister.ref}
                fullWidth
                className={classes.spaceAfter}
                onChange={(e): void => handleIconColorChange(e.target.value)}
            />
            <TextField
                {...iconRegister}
                label={t("tags.categories." + iconRegister.name)}
                value={state.icon}
                inputRef={iconRegister.ref}
                fullWidth
                multiline
                variant="outlined"
                className={classes.spaceAfter}
                onChange={(e): void => setState({...state, icon: e.target.value})}
                error={errors.icon !== undefined}
                helperText={errors.icon && errors.icon.message}
            />
            <p>{t("tags.categories.styleInfo")}</p>
            <Box>
                <Button color="primary" variant="contained" type="submit" className={classes.spaceAround}>
                    {t("tags.categories.save")}
                </Button>
                {props.onCancel && (
                    <Button
                        color="secondary"
                        variant="contained"
                        type="button"
                        className={classes.spaceAround}
                        onClick={props.onCancel}
                    >
                        {t("tags.categories.cancel")}
                    </Button>
                )}
                <Tag key={stateHash} tag={exampleTag} />
            </Box>
        </form>
    );
};

interface TagCategoriesState {
    categories: TagCategoryDTO[];
    inForm: TagCategoryCreateDTO;
    active: TagCategoryDTO | null;
    name: "editing" | "done" | "failed" | "loading" | "creating" | "saving" | "deleting";
    notification: string | null;
    newId: number;
    notificationId: string;
}

function compareCategories(x: TagCategoryDTO, y: TagCategoryDTO): number {
    if (x.priority === y.priority) {
        return comparator<string>(x.name, y.name);
    }
    return -1 * comparator<number>(x.priority, y.priority);
}

export const TagCategories: React.FC = () => {
    //== Init =================================================================
    const emptyCategory: TagCategoryCreateDTO = {
        name: "",
        description: "",
        priority: 0,
        style: "",
        icon: "",
    };
    const initState: TagCategoriesState = {
        categories: [],
        inForm: emptyCategory,
        active: null,
        name: "loading",
        notification: null,
        newId: 1,
        notificationId: "none",
    };
    const formRef = React.useRef<HTMLDivElement>(null);
    const [state, setState] = React.useState(initState);
    const {keycloak, initialized} = useKeycloak();
    const [t] = useTranslation("common");
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<TagCategoryDTO[]>(`/tag-categories/all`, createApiConfig(keycloak, initialized))
                .then((res) => {
                    const categories: TagCategoryDTO[] = res.data;
                    categories.sort(compareCategories);
                    setState({
                        ...state,
                        categories: categories,
                        name: "done",
                    });
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
        if (state.name === "creating") {
            API.post<TagCategoryDTO>(`/tag-categories`, state.inForm, createApiConfig(keycloak, initialized))
                .then((res) => {
                    const newCategory: TagCategoryDTO = res.data;
                    const categories: TagCategoryDTO[] = state.categories;
                    categories.push(newCategory);
                    categories.sort(compareCategories);
                    setState({
                        ...state,
                        categories: categories,
                        inForm: emptyCategory,
                        newId: state.newId + 1,
                        name: "done",
                        notification: "create_ok",
                        notificationId: new Date().toISOString(),
                    });
                })
                .catch((err: AxiosError) => {
                    setState({
                        ...state,
                        name: "failed",
                        notification: "create_fail",
                        notificationId: new Date().toISOString(),
                    });
                });
        }
        if (state.name === "saving" && state.active !== null) {
            API.put<TagCategoryDTO>(
                `/tag-categories/${state.active.id}`,
                state.inForm,
                createApiConfig(keycloak, initialized)
            )
                .then((res) => {
                    setState({
                        ...state,
                        name: "loading",
                        inForm: emptyCategory,
                        newId: state.newId + 1,
                        notification: "edit_ok",
                        notificationId: new Date().toISOString(),
                    });
                })
                .catch((err: AxiosError) => {
                    setState({
                        ...state,
                        name: "failed",
                        notification: "edit_fail",
                        notificationId: new Date().toISOString(),
                    });
                });
        }
    }, [state, setState, keycloak, initialized, emptyCategory]);
    //== Handlers =============================================================
    const handleCreateSubmit = (category: TagCategoryCreateDTO) => {
        setState({...state, inForm: category, name: "creating"});
    };
    const handleEditSubmit = (category: TagCategoryCreateDTO) => {
        setState({...state, inForm: category, name: "saving"});
    };
    const handleEditCancel = (): void => {
        setState({...state, inForm: emptyCategory, newId: state.newId + 1, name: "done"});
    };
    const handleEdit = (category: TagCategoryDTO) => {
        const form: TagCategoryCreateDTO = {
            name: category.name,
            description: category.description,
            priority: category.priority,
            style: category.style,
            icon: category.icon,
        };
        setState({...state, active: category, inForm: form, name: "editing"});
    };
    const handleDelete = (category: TagCategoryDTO) => {
        setState({...state, active: category, name: "deleting"});
    };
    const handleDeleteFailed = (): void => {
        setState({
            ...state,
            name: "failed",
            active: null,
            notification: "delete_fail",
            notificationId: new Date().toISOString(),
        });
    };
    const handleDeleteCancel = (): void => {
        setState({...state, active: null, name: "done"});
    };
    const handleDeleteDone = (): void => {
        setState({
            ...state,
            active: null,
            name: "loading",
            inForm: emptyCategory,
            newId: state.newId + 1,
            notification: "delete_ok",
            notificationId: new Date().toISOString(),
        });
    };
    //== Render ===============================================================
    if (state.name === "loading") {
        return <Loading />;
    }
    if (state.name === "editing" && formRef && formRef.current) {
        formRef.current.scrollIntoView({behavior: "smooth"});
    }
    let notification = null;
    if (state.notification !== null) {
        const msg = t(`tags.categories.notifications.${state.notification}`);
        if (state.notification.endsWith("fail")) {
            notification = <ErrorNotification key={state.notificationId} message={msg} />;
        } else {
            notification = <SuccessNotification key={state.notificationId} message={msg} />;
        }
    } else if (state.name === "failed") {
        return <ServerCommunicationAlert />;
    }
    const rows = state.categories.map((category) => (
        <TagCategoryRow category={category} onDelete={handleDelete} onEdit={handleEdit} key={category.id} />
    ));
    return (
        <Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="left">{t("tags.categories.name")}</TableCell>
                        <TableCell align="left">{t("tags.categories.description")}</TableCell>
                        <TableCell align="right">{t("tags.categories.priority")}</TableCell>
                        <TableCell align="center">{t("tags.categories.example")}</TableCell>
                        <TableCell align="right">
                            <RefreshActionButton onClick={(): void => setState({...state, name: "loading"})} />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{rows}</TableBody>
            </Table>
            <div ref={formRef}>
                {state.name === "editing" && state.active !== null ? (
                    <Container maxWidth="sm">
                        <h2>{t("tags.categories.edit")}</h2>
                        <TagCategoryForm
                            key={state.active.id}
                            category={state.inForm}
                            onSubmit={handleEditSubmit}
                            onCancel={handleEditCancel}
                        />
                    </Container>
                ) : (
                    <Container maxWidth="sm">
                        <h2>{t("tags.categories.new")}</h2>
                        <TagCategoryForm
                            key={`new-${state.newId}`}
                            category={state.inForm}
                            onSubmit={handleCreateSubmit}
                        />
                    </Container>
                )}
            </div>
            <TagCategoryDelete
                entity={state.name === "deleting" ? state.active : null}
                onDeleted={handleDeleteDone}
                onCancel={handleDeleteCancel}
                onFailed={handleDeleteFailed}
            />
            {notification}
        </Box>
    );
};
