import React from "react";
import {useLocation} from "react-router-dom";
import ManualDisplay, {manuals} from "./ManualDisplay";
import csManual from "../content/cs/CsManual";
import {useTranslation} from "react-i18next";

export const ManualPage: React.FC = () => {
    const [t] = useTranslation("common");
    const queries = new URLSearchParams(useLocation().search);
    const manual = manuals.get(t("language.code")) || csManual;

    const chapterKey = queries.get("chapter");
    const chapter = chapterKey ? manual.chapters.get(chapterKey) : null;
    if (!chapter) return <ManualDisplay />;

    const sectionKey = queries.get("section");
    const section = sectionKey ? chapter.sections.get(sectionKey) : null;
    if (!section) return <ManualDisplay onlyChapter={chapter} />;

    const subsectionKey = queries.get("subsection");
    const subsection = subsectionKey ? section.subsections.get(subsectionKey) : null;
    if (!subsection) return <ManualDisplay onlySection={section} />;

    const subSubsectionKey = queries.get("subSubsection");
    const subSubsection = subSubsectionKey ? subsection.subSubsections.get(subSubsectionKey) : null;
    if (!subSubsection) return <ManualDisplay onlySubsection={subsection} />;
    return <ManualDisplay onlySubSubsection={subSubsection} />;
};
