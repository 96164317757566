import React, {useCallback} from "react";
import {Redirect, useLocation} from "react-router-dom";

import {useKeycloak} from "@react-keycloak/web";

import Button from "@material-ui/core/Button";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import {useTranslation} from "react-i18next";

import AuthenticationSVG from "../graphics/undraw/authentication_fsn5.svg";
import ROUTES from "../routes/routes";

const Login: React.FC = () => {
    const [t] = useTranslation();
    const {keycloak, initialized} = useKeycloak();
    const location = useLocation();
    const from = ROUTES.dashboard.path;
    const login = useCallback(() => {
        if (initialized && keycloak) {
            keycloak.login(location.state ? {redirectUri: document.referrer} : {});
        } else {
            alert(t("auth.cannot_login"));
        }
    }, [keycloak, initialized, t, location.state]);

    if (keycloak && keycloak.authenticated) {
        return <Redirect to={from} />;
    } else {
        return (
            <div className="error-page">
                <h1>{t("auth.need_login")}</h1>

                <div>
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={login}
                        startIcon={<LockOpenIcon />}
                    >
                        {t("auth.login")}
                    </Button>
                </div>
                <img
                    style={{maxWidth: 600, width: "100%", margin: "auto"}}
                    src={AuthenticationSVG}
                    alt={t("auth.need_login")}
                />
            </div>
        );
    }
};

export default Login;
