import {OPERATIONS, OperationType} from "../../../../../models/formulas";
import {makeStyles} from "@material-ui/core/styles";
import React, {ChangeEvent} from "react";
import {useTranslation} from "react-i18next";
import {MenuItem, Select} from "@material-ui/core";

interface ConversionOperationSelectProps {
    value: OperationType;
    onChange: (operation: OperationType) => void;
}

const useStyles = makeStyles((theme) => ({
    list: {
        minWidth: "5em",
        maxWidth: "30em",
    },
    listItem: {
        whiteSpace: "normal",
        maxWidth: "30em",
    },
}));

export const ConversionOperationSelect: React.FC<ConversionOperationSelectProps> = (
    props: ConversionOperationSelectProps
) => {
    //== Init =================================================================
    const classes = useStyles();
    const [t] = useTranslation("libraries");
    //== Handlers =============================================================
    const handleChange = (event: ChangeEvent<{name?: string; value: unknown}>) => {
        props.onChange(event.target.value as OperationType);
    };
    //== Render ===============================================================
    return (
        <Select value={props.value.valueOf()} onChange={handleChange} className={classes.list}>
            {OPERATIONS.map((o) => (
                <MenuItem key={o.type.valueOf()} value={o.type.valueOf()} className={classes.listItem}>
                    {t(`formula.conversion.${o.type.valueOf()}`)}
                </MenuItem>
            ))}
        </Select>
    );
};
