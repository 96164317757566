import React from "react";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
// Material UI imports
import {Box, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
// Local imports
// Project imports
import {PeriodCreateDTO, PeriodDTO} from "../../../models/library";
import {AddCircleActionButton, BackActionButton, DeleteActionButton, EditActionButton} from "../buttons";
import {useStyles} from "../../../styles";
import {API, createApiConfig, DateUtils} from "../../../utils";
import {PeriodForm} from "./PeriodForm";
import {ErrorNotification, SuccessNotification} from "../notifications";
import {AxiosError} from "axios";

interface PeriodRowProps {
    period: PeriodDTO;

    onEdit(period: PeriodDTO): void;

    onDelete(period: PeriodDTO): void;
}

const PeriodRow: React.FC<PeriodRowProps> = (props: PeriodRowProps) => {
    return (
        <TableRow>
            <TableCell align="right">{DateUtils.dateString(props.period.referenceDate)}</TableCell>
            <TableCell align="right">{props.period.repeatDay}</TableCell>
            <TableCell align="right">{props.period.repeatMonth}</TableCell>
            <TableCell align="right">{props.period.repeatYear}</TableCell>
            <TableCell align="right">
                <EditActionButton onClick={(): void => props.onEdit(props.period)} />
                <DeleteActionButton onClick={(): void => props.onDelete(props.period)} />
            </TableCell>
        </TableRow>
    );
};

interface PeriodsTableProps {
    parentUUID: string;
    periods: PeriodDTO[];
}

interface PeriodsTableState {
    periods: PeriodDTO[];
    periodForm: PeriodCreateDTO;
    editUUID: string | null;
    name: string;
}

export const PeriodsTable: React.FC<PeriodsTableProps> = (props: PeriodsTableProps) => {
    //== Init ===================================================================
    const [t] = useTranslation("libraries");
    const classes = useStyles();
    const {keycloak, initialized} = useKeycloak();
    const newPeriod: PeriodCreateDTO = {
        referenceDate: DateUtils.dateFormString(new Date().toISOString()),
        repeatDay: 0,
        repeatMonth: 0,
        repeatYear: 0,
        variableUUID: props.parentUUID,
    };
    const initState: PeriodsTableState = {
        periods: props.periods,
        periodForm: newPeriod,
        editUUID: null,
        name: "init",
    };
    const [state, setState] = React.useState(initState);
    //== Effects ================================================================
    React.useEffect(() => {
        if (state.name === "deleting") {
            API.delete<void>(`/periods/${state.editUUID}`, createApiConfig(keycloak, initialized))
                .then(() => {
                    const newPeriods = state.periods.filter((p) => p.id !== state.editUUID);
                    setState({...state, periods: newPeriods, name: "deleted"});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "delete_fail"});
                });
        }
        if (state.name === "createSubmitting") {
            API.post<PeriodDTO>(`/periods`, state.periodForm, createApiConfig(keycloak, initialized))
                .then((res) => {
                    const newPeriods = state.periods;
                    newPeriods.push(res.data);
                    setState({...state, periods: newPeriods, name: "created"});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "create_fail"});
                });
        }
        if (state.name === "editSubmitting") {
            API.put<PeriodDTO>(`/periods/${state.editUUID}`, state.periodForm, createApiConfig(keycloak, initialized))
                .then((res) => {
                    const newPeriods = state.periods.map((p) => (p.id === state.editUUID ? res.data : p));
                    setState({...state, periods: newPeriods, name: "edited"});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "edit_fail"});
                });
        }
    }, [keycloak, initialized, state, setState]);
    //== Handlers ===============================================================
    const handleCreate = (): void => {
        setState({...state, name: "creating", periodForm: newPeriod});
    };
    const handleEdit = (period: PeriodDTO): void => {
        const xperiod: PeriodCreateDTO = {
            referenceDate: period.referenceDate,
            repeatDay: period.repeatDay,
            repeatMonth: period.repeatMonth,
            repeatYear: period.repeatYear,
            variableUUID: props.parentUUID,
        };
        setState({...state, name: "editing", editUUID: period.id, periodForm: xperiod});
    };
    const handleDelete = (period: PeriodDTO): void => {
        setState({...state, name: "deleting", editUUID: period.id});
    };
    const handleFormChange = (period: PeriodCreateDTO): void => {
        setState({...state, periodForm: period});
    };
    const handleSubmitCreate = (): void => {
        setState({...state, name: "createSubmitting"});
    };
    const handleSubmitEdit = (): void => {
        setState({...state, name: "editSubmitting"});
    };
    const handleBack = (): void => {
        setState({...state, name: "table"});
    };
    //== Render =================================================================
    if (state.name === "creating") {
        return (
            <Box>
                <BackActionButton onClick={handleBack} />
                <PeriodForm period={state.periodForm} onChange={handleFormChange} onSubmit={handleSubmitCreate} />
            </Box>
        );
    }
    if (state.name === "editing") {
        return (
            <Box>
                <BackActionButton onClick={handleBack} />
                <PeriodForm period={state.periodForm} onChange={handleFormChange} onSubmit={handleSubmitEdit} />
            </Box>
        );
    }
    // - default
    let notification: JSX.Element | null = null;
    if (state.name === "deleted") {
        notification = <SuccessNotification message={t("period.notifications.delete_ok")} />;
    }
    if (state.name === "created") {
        notification = <SuccessNotification message={t("period.notifications.create_ok")} />;
    }
    if (state.name === "edited") {
        notification = <SuccessNotification message={t("period.notifications.edit_ok")} />;
    }
    if (state.name === "delete_fail") {
        notification = <ErrorNotification message={t("period.notifications.delete_fail")} />;
    }
    if (state.name === "create_fail") {
        notification = <ErrorNotification message={t("period.notifications.create_fail")} />;
    }
    if (state.name === "edit_fail") {
        notification = <ErrorNotification message={t("period.notifications.edit_fail")} />;
    }
    const rows = state.periods.map((period: PeriodDTO) => (
        <PeriodRow key={period.id} period={period} onEdit={handleEdit} onDelete={handleDelete} />
    ));
    return (
        <Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">{t("period.referenceDate")}</TableCell>
                        <TableCell align="center">{t("period.repeatDay")}</TableCell>
                        <TableCell align="center">{t("period.repeatMonth")}</TableCell>
                        <TableCell align="center">{t("period.repeatYear")}</TableCell>
                        <TableCell align="right" />
                    </TableRow>
                </TableHead>
                <TableBody>{rows}</TableBody>
            </Table>
            {notification}
            <div className={classes.fabWrapper}>
                <AddCircleActionButton onClick={handleCreate} />
            </div>
        </Box>
    );
};
