import React, {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
import {ContactPersonCreateDTO} from "../../../models/organization";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
import {useStyles} from "../../../styles";
import {Box, Checkbox, FormControlLabel, Grid} from "@material-ui/core";
import {FormValidationType, OrganizationProfileFormAttribute} from "./OrganizationProfile";

interface FormError {
    validationType: FormValidationType;
    referencedValue?: string | null;
}

type ContactPersonFormAttribute = OrganizationProfileFormAttribute | "checked";

interface FormErrors extends Map<ContactPersonFormAttribute, FormError> {}

export interface ContactPersonFormPartProps {
    contactPerson: ContactPersonCreateDTO | null;
    onChange: (contactPerson: ContactPersonCreateDTO | null) => void;
    onFormDataChange: (fieldName: OrganizationProfileFormAttribute) => void;
    formErrors: FormErrors;
}

interface ContactPersonFormData {
    checked: boolean;
    fullName: string;
    email: string;
    phoneNumber: string;
    role: string;
}

const ContactPersonFormPart: React.FC<ContactPersonFormPartProps> = (props: ContactPersonFormPartProps) => {
    const classes = useStyles();
    const {keycloak, initialized} = useKeycloak();
    const [t] = useTranslation(["organization"]);
    const [formData, setFormData] = useState({
        checked: !!props.contactPerson,
        fullName: props.contactPerson?.fullName || "",
        email: props.contactPerson?.email || "",
        phoneNumber: props.contactPerson?.phoneNumber || "",
        role: props.contactPerson?.role || "",
    });

    useEffect(() => {
        if (!formData.checked) {
            if (props.contactPerson) props.onChange(null);
            return;
        }
        const changed =
            !props.contactPerson ||
            formData.fullName !== props.contactPerson.fullName ||
            formData.email !== (props.contactPerson.email || "") ||
            formData.phoneNumber !== (props.contactPerson.phoneNumber || "") ||
            formData.role !== (props.contactPerson.role || "");
        console.log("ContactPersonFormPart - useEffect - changed: " + changed);
        if (!changed) return;
        console.log("ContactPersonFormPart - useEffect - triggering onChange");
        props.onChange({
            fullName: formData.fullName,
            email: formData.email || null,
            phoneNumber: formData.phoneNumber || null,
            role: formData.role || null,
        });
    }, [props, formData, keycloak, initialized]);

    function sanitizePhoneNumber(phoneNumber: string) {
        if (phoneNumber.length === 0) return phoneNumber;
        return (phoneNumber.charAt(0) === "+" ? "+" : "") + phoneNumber.replace(/[^\d]+/g, "");
    }

    const setFormInputData = (fieldName: ContactPersonFormAttribute, data: ContactPersonFormData) => {
        setFormData(data);
        if (fieldName === "checked") {
            props.onFormDataChange("contactPersonFullName");
            props.onFormDataChange("contactPersonEmail");
            props.onFormDataChange("contactPersonPhoneNumber");
            props.onFormDataChange("contactPersonRole");
        } else {
            props.onFormDataChange(fieldName);
        }
    };
    const errorHelperText = (fieldName: ContactPersonFormAttribute) => {
        const error = props.formErrors.get(fieldName);
        if (!error) return null;
        return t("validation." + error.validationType, {referencedValue: error.referencedValue});
    };

    return (
        <Box>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            checked={formData.checked}
                            onChange={(e): void =>
                                setFormInputData("checked", {...formData, checked: e.target.checked})
                            }
                            name="selected"
                        />
                    }
                    label={t("organization.contactPerson")}
                />
            </Grid>
            {formData.checked ? (
                <Box>
                    <Grid item xs={12}>
                        <TextField
                            id="fullName"
                            label={t("organization.fullName")}
                            value={formData.fullName || ""}
                            fullWidth
                            required
                            className={classes.spaceAfter}
                            onChange={(e): void =>
                                setFormInputData("contactPersonFullName", {
                                    ...formData,
                                    fullName: e.target.value,
                                })
                            }
                            error={props.formErrors.has("contactPersonFullName")}
                            helperText={errorHelperText("contactPersonFullName")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="email"
                            label={t("organization.email")}
                            value={formData.email || ""}
                            fullWidth
                            required
                            className={classes.spaceAfter}
                            onChange={(e): void =>
                                setFormInputData("contactPersonEmail", {
                                    ...formData,
                                    email: e.target.value,
                                })
                            }
                            error={props.formErrors.has("contactPersonEmail")}
                            helperText={errorHelperText("contactPersonEmail")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="phoneNumber"
                            label={t("organization.phoneNumber")}
                            value={formData.phoneNumber || ""}
                            fullWidth
                            required
                            className={classes.spaceAfter}
                            onChange={(e): void =>
                                setFormInputData("contactPersonPhoneNumber", {
                                    ...formData,
                                    phoneNumber: sanitizePhoneNumber(e.target.value),
                                })
                            }
                            error={props.formErrors.has("contactPersonPhoneNumber")}
                            helperText={errorHelperText("contactPersonPhoneNumber")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="role"
                            label={t("organization.role")}
                            value={formData.role || ""}
                            fullWidth
                            className={classes.spaceAfter}
                            onChange={(e): void =>
                                setFormInputData("contactPersonRole", {
                                    ...formData,
                                    role: e.target.value,
                                })
                            }
                            error={props.formErrors.has("contactPersonRole")}
                            helperText={errorHelperText("contactPersonRole")}
                        />
                    </Grid>
                </Box>
            ) : null}
        </Box>
    );
};

export default ContactPersonFormPart;
