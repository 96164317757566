import React from "react";
import {Box, Link} from "@material-ui/core";
import {Link as ReactLink} from "react-router-dom";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
// Project imports
import SccLandingLogo from "../../graphics/landing/logo-scc-min.svg";
import VillageSvg from "../../graphics/landing/landing-village-cut-min.svg";
import CommunicationSvg from "../../graphics/landing/landing-communication-min.svg";
import AboutButtonSvg from "../../graphics/landing/button-about.min.svg";
import SearchButtonSvg from "../../graphics/landing/button-search.min.svg";
import RegisterButtonSvg from "../../graphics/landing/button-register.min.svg";
import LoginButtonSvg from "../../graphics/landing/button-login.min.svg";
import FitLogo from "../../graphics/landing/logo-fit-min.svg";
import MmrLogo from "../../graphics/landing/logo-mmr-min.svg";
import TacrLogo from "../../graphics/landing/logo-tacr-min.svg";
import UceebLogo from "../../graphics/landing/logo-uceeb.min.svg";
import {useTranslation} from "react-i18next";
import {ROUTES} from "../../routes/routes";
import {useKeycloak} from "@react-keycloak/web";
import {isUser} from "../../utils/auth";
import {Redirect} from "react-router";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        parallaxScreen: {
            width: "100%",
            minHeight: "56vw",
            maxHeight: "84vw",
            height: "100vh",
            "@media(max-width: 720px)": {
                height: "100%",
                minHeight: "155vw",
                maxHeight: "190vw",
            },
            "@media(min-width: 2000px)": {
                minHeight: "auto",
                maxHeight: "none",
                height: 1080,
            },
            position: "relative",
        },
        parallaxShow: {
            clear: "both",
            maxWidth: 2000,
            margin: "auto",
            width: "100%",
            height: "100vh",
            wordSpacing: "0.1em",
        },
        topPage: {
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#bad1c7",
        },
        topLogo: {
            position: "absolute",
            left: "4%",
            top: "5%",
            "@media(max-width: 720px)": {
                top: "3%",
            },
            "@media(min-width: 2100px)": {
                left: 0,
            },
            width: "calc(85px + 16%)",
        },
        villageFrame: {
            backgroundColor: "#fff",
        },
        villageImage: {
            width: "100%",
            marginTop: "2%",
            "@media(min-width: 1950px)": {
                marginTop: "1%",
            },
            "@media(max-width: 720px)": {
                marginTop: "6%",
                width: "145%",
                marginLeft: "-45%",
            },
            "@media(max-width: 440px)": {
                marginTop: "10%",
            },
            marginBottom: -2,
            display: "block",
        },
        actionsWide: {
            width: "100%",
            "@media(max-width: 720px)": {
                display: "none",
            },
            flexGrow: 1,
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
            alignItems: "center",
            paddingLeft: 2,
            paddingRight: 2,
        },
        actionsNarrow: {
            display: "none",
            "@media(max-width: 720px)": {
                display: "flex",
            },
            justifyContent: "center",
            flexDirection: "column",
            height: "100%",
            width: "100%",
            padding: 5,
        },
        actionsNarrowBlock: {
            display: "flex",
            justifyContent: "space-evenly",
            width: "100%",
            paddingTop: "8%",
            paddingBottom: "12%",
        },
        actionLink: {
            width: "calc(8% + 80px)",
            textDecoration: "none",
            "@media(max-width: 720px)": {
                width: "auto",
            },
            display: "block",
            cursor: "pointer",
            "&:hover": {
                textDecoration: "none",
            },
            "&:active": {
                textDecoration: "none",
            },
            "&:link": {
                textDecoration: "none",
            },
        },
        action: {
            height: "100%",
            color: "#fff",
            paddingLeft: 2,
            paddingRight: 2,
        },
        actionIcon: {
            width: "66%",
            "@media(max-width: 720px)": {
                height: "auto",
                width: "calc(28px + 15vw)",
            },
            "@media(min-width: 1920px)": {
                width: "151px",
            },
            margin: "auto",
            display: "block",
            paddingBottom: "7%",
        },
        actionText: {
            fontWeight: "bold",
            fontSize: "calc(100% + 0.9vw)",
            "@media(max-width: 720px)": {
                fontSize: "calc(50% + 4vw)",
            },
            "@media(min-width: 2000px)": {
                fontSize: "calc(100% + 18px)",
            },
            textTransform: "uppercase",
            margin: "auto",
            display: "block",
            textAlign: "center",
        },
        overviewPage: {
            display: "flex",
            alignItems: "center",
            "@media(max-width: 720px)": {
                flexDirection: "column-reverse",
                justifyContent: "space-evenly",
                paddingBottom: "10%",
            },
        },
        overviewImage: {
            width: "50%",
            "@media(max-width: 720px)": {
                width: "90%",
            },
            paddingLeft: "calc(1.1em + 3%)",
            paddingRight: "calc(1.1em + 2%)",
        },
        overviewInfo: {
            width: "50%",
            "@media(max-width: 720px)": {
                width: "auto",
            },
            paddingLeft: "calc(1.1em + 2%)",
            paddingRight: "calc(1.1em + 2%)",
        },
        overviewInfoHeader: {
            fontSize: "calc(55% + 1.7vw)",
            paddingBottom: "calc(0.4em + 0.4vw)",
            "@media(max-width: 720px)": {
                fontSize: "calc(70% + 2vw)",
                paddingBottom: "calc(0.1em + 0.1vw)",
            },
            "@media(min-width: 2000px)": {
                fontSize: "calc(55% + 34px)",
                paddingBottom: "calc(0.4em + 8px)",
            },
        },
        overviewInfoList: {
            paddingInlineStart: "20px",
        },
        overviewInfoListItem: {
            fontSize: "calc(50% + 1.12vw)",
            marginTop: "calc(0.3em + 0.2vw)",
            marginBottom: "calc(0.3em + 0.2vw)",
            lineHeight: "calc(1em + 1.5vw)",
            "@media(max-width: 720px)": {
                marginTop: "calc(0.6em + 0.2vw)",
                marginBottom: "calc(0.6em + 0.2vw)",
                fontSize: "calc(50% + 1.5vw)",
            },
            "@media(min-width: 2000px)": {
                fontSize: "calc(50% + 22.4px)",
                marginTop: "calc(0.3em + 4px)",
                marginBottom: "calc(0.3em + 4px)",
                lineHeight: "calc(1em + 30px)",
            },
        },
        descriptionPage: {
            paddingLeft: "calc(1em + 3%)",
            paddingRight: "calc(1em + 2%)",
            display: "flex",
            flexDirection: "column",
        },
        descriptionPageHeader: {
            fontSize: "calc(55% + 1.7vw)",
            paddingBottom: "calc(0.4em + 0.4vw)",
            "@media(max-width: 720px)": {
                fontSize: "calc(70% + 2vw)",
                paddingBottom: "calc(0.1em + 0.1vw)",
            },
            "@media(min-width: 2000px)": {
                fontSize: "calc(55% + 34px)",
                paddingBottom: "calc(0.4em + 8px)",
            },
        },
        descriptionPageContent: {
            display: "flex",
            alignItems: "stretch",
            "@media(max-width: 720px)": {
                flexDirection: "column",
                flexGrow: 1,
            },
        },
        descriptionInfo: {
            width: "70%",
            paddingRight: "2%",
            fontSize: "calc(50% + 1.12vw)",
            textAlign: "justify",
            lineHeight: "calc(1em + 1.5vw)",
            "@media(max-width: 720px)": {
                width: "auto",
                fontSize: "calc(50% + 1.5vw)",
                paddingRight: 0,
            },
            "@media(max-width: 400px)": {
                fontSize: "calc(46% + 1.5vw)",
            },
            "@media(min-width: 2000px)": {
                fontSize: "calc(50% + 22.4px)",
                lineHeight: "calc(1em + 30px)",
            },
        },
        descriptionInfoParagraph: {
            marginTop: 0,
        },
        descriptionPartners: {
            width: "30%",
            paddingLeft: "2%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "space-between",
            paddingBottom: "min(4em, 10vh)",
            "@media(max-width: 720px)": {
                width: "auto",
                flexWrap: "wrap",
                paddingLeft: 0,
                flexDirection: "row",
                justifyContent: "space-evenly",
                paddingTop: "5vw",
                height: "100%",
                flexGrow: 1,
            },
        },
        widePartnerLogo: {
            width: "80%",
            "@media(max-width: 720px)": {
                width: "40%",
            },
        },
        widePartnerLogoImage: {
            width: "100%",
        },
        tallPartnerLogo: {
            height: "30%",
            "@media(max-width: 720px)": {
                height: "auto",
                width: "28%",
                display: "block",
            },
        },
        tallPartnerLogoImage: {
            height: "100%",
            margin: "auto",
            display: "block",
            "@media(max-width: 720px)": {
                height: "auto",
                width: "100%",
            },
        },
        footer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#bad1c7",
            padding: "5%",
        },
        footerLogo: {
            width: "calc(80px + 15%)",
        },
    })
);

interface ParallaxScreenProps {
    className?: string;
    id?: string;
    children: React.ReactNode;
}

const ParallaxScreen: React.FC<React.PropsWithChildren<ParallaxScreenProps>> = (props: ParallaxScreenProps) => {
    const classes = useStyles();
    return (
        <Box id={props.id} className={`${classes.parallaxScreen} ${props.className || ""}`}>
            {props.children}
        </Box>
    );
};

const baseUrl = (): string => {
    const getUrl = window.location;
    return getUrl.protocol + "//" + getUrl.host;
};

export const Landing: React.FC = () => {
    const {keycloak, initialized} = useKeycloak();

    if (isUser(keycloak)) return <Redirect to={ROUTES.dashboard.path} />;

    const classes = useStyles();
    const [t] = useTranslation(["staticPages", "common"]);

    const amountOfConceptBulletPoints = 6;
    const amountOfDescriptionParagraphs = 3;

    const login = (): void => {
        if (initialized && keycloak) {
            keycloak.login({redirectUri: `${baseUrl()}${ROUTES.dashboard.path}`});
        } else {
            alert(t("common:auth.cannot_login"));
        }
    };
    const register = (): void => {
        if (initialized && keycloak) {
            keycloak.register({redirectUri: `${baseUrl()}${ROUTES.dashboard.path}`});
        } else {
            alert(t("common:auth.cannot_register"));
        }
    };
    const actions12 = (
        <>
            <Link href="#description" className={classes.actionLink}>
                <Box className={classes.action}>
                    <img src={AboutButtonSvg} alt={t("landing.about")} className={classes.actionIcon} />
                    <Box className={classes.actionText}>{t("landing.about")}</Box>
                </Box>
            </Link>
            <ReactLink to={ROUTES.municipalities.path} className={classes.actionLink}>
                <Box className={classes.action}>
                    <img src={SearchButtonSvg} alt={t("landing.search")} className={classes.actionIcon} />
                    <Box className={classes.actionText}>{t("landing.search")}</Box>
                </Box>
            </ReactLink>
        </>
    );
    const actions34 = (
        <>
            <Link onClick={register} className={classes.actionLink}>
                <Box className={classes.action}>
                    <img src={RegisterButtonSvg} alt={t("landing.register")} className={classes.actionIcon} />
                    <Box className={classes.actionText}>{t("landing.register")}</Box>
                </Box>
            </Link>
            <Link onClick={login} className={classes.actionLink}>
                <Box className={classes.action}>
                    <img src={LoginButtonSvg} alt={t("landing.login")} className={classes.actionIcon} />
                    <Box className={classes.actionText}>{t("landing.login")}</Box>
                </Box>
            </Link>
        </>
    );

    return (
        <Box className={classes.parallaxShow}>
            <ParallaxScreen className={classes.topPage}>
                <Box className={classes.villageFrame}>
                    <img src={SccLandingLogo} alt={t("landing.title")} className={classes.topLogo} />
                    <img src={VillageSvg} alt="" className={classes.villageImage} />
                </Box>
                <Box className={classes.actionsWide}>
                    {actions12}
                    {actions34}
                </Box>
                <Box className={classes.actionsNarrow}>
                    <Box className={classes.actionsNarrowBlock}>{actions12}</Box>
                    <Box className={classes.actionsNarrowBlock}>{actions34}</Box>
                </Box>
            </ParallaxScreen>
            <ParallaxScreen id="overview" className={classes.overviewPage}>
                <img src={CommunicationSvg} alt="" className={classes.overviewImage} />
                <Box className={classes.overviewInfo}>
                    <h2 className={classes.overviewInfoHeader}>{t("landing.concept.header")}</h2>
                    <ul className={classes.overviewInfoList}>
                        {[...Array(amountOfConceptBulletPoints).keys()].map((index) => (
                            <li key={index} className={classes.overviewInfoListItem}>
                                {t("landing.concept.item" + (index + 1))}
                            </li>
                        ))}
                    </ul>
                </Box>
            </ParallaxScreen>
            <ParallaxScreen id="description" className={classes.descriptionPage}>
                <h2 className={classes.descriptionPageHeader}>{t("landing.description.header")}</h2>
                <Box className={classes.descriptionPageContent}>
                    <Box className={classes.descriptionInfo}>
                        {[...Array(amountOfDescriptionParagraphs).keys()].map((index) => (
                            <p key={index} className={classes.descriptionInfoParagraph}>
                                {t("landing.description.paragraph" + (index + 1))}
                            </p>
                        ))}
                    </Box>
                    <Box className={classes.descriptionPartners}>
                        <Link className={classes.widePartnerLogo} href="https://mmr.cz" target="_blank" rel="noopener">
                            <img
                                src={MmrLogo}
                                alt={t("landing.partners.mmr")}
                                className={classes.widePartnerLogoImage}
                            />
                        </Link>
                        <Link className={classes.tallPartnerLogo} href="https://tacr.cz" target="_blank" rel="noopener">
                            <img
                                src={TacrLogo}
                                alt={t("landing.partners.tacr")}
                                className={classes.tallPartnerLogoImage}
                            />
                        </Link>
                        <Link
                            className={classes.widePartnerLogo}
                            href="https://uceeb.cz"
                            target="_blank"
                            rel="noopener"
                        >
                            <img
                                src={UceebLogo}
                                alt={t("landing.partners.uceeb")}
                                className={classes.widePartnerLogoImage}
                            />
                        </Link>
                        <Link
                            className={classes.widePartnerLogo}
                            href="https://fit.cvut.cz"
                            target="_blank"
                            rel="noopener"
                        >
                            <img
                                src={FitLogo}
                                alt={t("landing.partners.fit")}
                                className={classes.widePartnerLogoImage}
                            />
                        </Link>
                    </Box>
                </Box>
            </ParallaxScreen>
            <Box className={classes.footer}>
                <Box></Box>
                <img src={SccLandingLogo} alt={t("landing.title")} className={classes.footerLogo} />
                <Box></Box>
            </Box>
        </Box>
    );
};

export default Landing;
