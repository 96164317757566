import React, {useState} from "react";
import ManualDisplay from "./ManualDisplay";
import {Box, Drawer} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {CustomActionButton, ManualActionButton, OpenInNewLinkActionButton} from "../../components/common/buttons";
import {ROUTES} from "../../routes/routes";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {ManualChapter} from "../model/ManualChapter";
import {ManualSection} from "../model/ManualSection";
import {ManualSubsection} from "../model/ManualSubsection";

interface ManualSidebarChapterProps {
    chapter: ManualChapter;
    section?: never;
    subsection?: never;
}

interface ManualSidebarSectionProps {
    chapter?: never;
    section: ManualSection;
    subsection?: never;
}

interface ManualSidebarSubsectionProps {
    chapter?: never;
    section?: never;
    subsection?: ManualSubsection;
}

type ManualSidebarProps = ManualSidebarChapterProps | ManualSidebarSectionProps | ManualSidebarSubsectionProps;

export const ManualSidebar: React.FC<ManualSidebarProps> = (props: ManualSidebarProps) => {
    const [t] = useTranslation("common");
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const subsection = props.subsection;
    const section = props.section || subsection?.section;
    const chapter = props.chapter || section?.chapter;

    let query = "";
    if (chapter) {
        query = query + "?chapter=" + chapter.key;
        if (section) {
            query = query + "&section=" + section.key;
            if (subsection) {
                query = query + "&subsection=" + subsection.key;
            }
        }
    }
    return (
        <>
            <Box style={{float: "right"}}>
                <ManualActionButton onClick={handleOpen}>{t("actions.manual")}</ManualActionButton>
            </Box>
            <Drawer onClose={handleClose} open={open} anchor="right">
                <Box style={{maxWidth: "50vw", padding: "1.5em"}}>
                    <Box style={{float: "right"}}>
                        <span onClick={handleClose}>
                            <OpenInNewLinkActionButton to={ROUTES.manual.path + query} target="_blank" />
                        </span>
                        <CustomActionButton
                            title={t("actions.close")}
                            onClick={handleClose}
                            icon={<ChevronRightIcon />}
                        />
                    </Box>
                    {subsection ? (
                        <ManualDisplay onlySubsection={subsection} embeddedView={true} />
                    ) : section ? (
                        <ManualDisplay onlySection={section} embeddedView={true} />
                    ) : chapter ? (
                        <ManualDisplay onlyChapter={chapter} embeddedView={true} />
                    ) : (
                        <ManualDisplay embeddedView={true} />
                    )}
                </Box>
            </Drawer>
        </>
    );
};
