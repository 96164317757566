import React, {ChangeEvent} from "react";
import {VariableSimpleDTO} from "../../../../models/library";
import {Box, Divider, TextField} from "@material-ui/core";
import {FormulaNode, parseFormulaNodes, reconstructFormula} from "../../../../models/formulaEditor";
import {FormulaNodeForm} from "./formula/FormulaNodeForm";

interface FormulaFieldProps {
    formula: string;
    usedVariables: VariableSimpleDTO[];
    variables: VariableSimpleDTO[];
    onChange: (formula: string) => void;
    showField?: boolean;
}

export const FormulaField: React.FC<FormulaFieldProps> = (props: FormulaFieldProps) => {
    //== Handlers =============================================================
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        props.onChange(event.target.value);
    };
    const handleChangeRootNode = (index: number, node: FormulaNode) => {
        if (index !== 0) {
            return;
        }
        props.onChange(reconstructFormula(node));
    };
    //== Render ===============================================================
    const rootNode: FormulaNode | undefined = parseFormulaNodes(props.formula, true)[0];
    return (
        <Box>
            {rootNode !== undefined && (
                <FormulaNodeForm
                    index={0}
                    node={rootNode}
                    usedVariables={props.usedVariables}
                    variables={props.variables}
                    onChange={handleChangeRootNode}
                />
            )}
            {props.showField && (
                <Box>
                    <Divider style={{margin: "1em"}} />
                    <TextField value={props.formula} onChange={handleChange} variant="outlined" multiline fullWidth />
                </Box>
            )}
        </Box>
    );
};
