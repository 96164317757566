import React from "react";
import {useTranslation} from "react-i18next";
import {LibrarySimpleDTO} from "../../../models/library";
import Loading from "../../common/Loading";
import {useKeycloak} from "@react-keycloak/web";
import {API, createApiConfig} from "../../../utils/API";
import {AxiosError} from "axios";
import {ErrorNotification} from "../../common/notifications";

interface LibraryWithElementProps {
    elementType: "domain" | "goal" | "indicator";
    id: string;
}

export const LibrariesWithElement: React.FC<LibraryWithElementProps> = (props: LibraryWithElementProps) => {
    const [t] = useTranslation(["values"]);
    const {keycloak, initialized} = useKeycloak();
    const initState = {
        name: "loading" as "loading" | "loaded" | "failed",
        libraries: [] as LibrarySimpleDTO[],
    };
    const [state, setState] = React.useState(initState);
    //== Effects ================================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<LibrarySimpleDTO[]>(
                `/libraries/public/containing-${props.elementType}/${props.id}`,
                createApiConfig(keycloak, initialized)
            )
                .then((res) => {
                    setState({
                        ...state,
                        libraries: res.data,
                        name: "loaded",
                    });
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [keycloak, initialized, state, setState, props]);
    //== Render ===============================================================
    if (state.name === "failed")
        return <ErrorNotification message={t("ui.notificationPartOfPublicLibrariesLoadingFailed")} />;
    const header = <h3>{t("ui.partOfPublicLibraries")}</h3>;
    if (state.name === "loading")
        return (
            <>
                {header}
                <Loading />
            </>
        );
    if (!state.libraries.length)
        return (
            <>
                {header}
                {t("ui.notPartOfAnyPublicLibraries")}
            </>
        );
    return (
        <>
            {header}
            <ul>
                {state.libraries.map((library) => (
                    <li key={library.id}>{library.name}</li>
                ))}
            </ul>
        </>
    );
};
