import React from "react";
import {CommunicationError, ErrorPage, NotAuthorized} from "../common/errors";
import {LibrarySimpleDTO} from "../../models/library";
import {useKeycloak} from "@react-keycloak/web";
import {API, createApiConfig} from "../../utils/API";
import {AxiosError} from "axios";
import {LoadingBackdrop} from "../common/Loading";
import {LibrariesList} from "./list/LibrariesList";
import {Box, Container} from "@material-ui/core";
import {PageHeader, PrimaryPageAction} from "../common/headers";
import AddIcon from "@material-ui/icons/Add";
import ROUTES from "../../routes/routes";
import {BreadcrumbItem, BreadcrumbsRow} from "../common/breadcrumbs";
import {
    EditLinkActionButton,
    EditorLinkActionButton,
    InfoActionButton,
    LinkActionButton,
    VisitLinkActionButton,
} from "../common/buttons";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import BusinessIcon from "@material-ui/icons/Business";
import {Link} from "react-router-dom";
import SettingsIcon from "@material-ui/icons/Settings";
import {useTranslation} from "react-i18next";
import EmptySVG from "../../graphics/undraw/empty_xct9.svg";
import {LibraryDialog} from "../values/dialogs/LibraryDialog";

const LibrariesEmpty: React.FC = () => {
    //== Init =================================================================
    const [t] = useTranslation("libraries");
    //== Render ===============================================================
    return (
        <ErrorPage
            title={t("library.empty.admin.title")}
            description={t("library.empty.admin.description")}
            image={EmptySVG}
        />
    );
};

interface LibrariesAdminItemProps {
    library: LibrarySimpleDTO;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        libraryRow: {
            borderWidth: 0,
            borderStyle: "solid",
            borderBottomWidth: 2,
            borderColor: theme.palette.primary.main,
            padding: theme.spacing(1),
            display: "flex",
            alignItems: "center",
        },
        libraryTitle: {
            fontSize: "125%",
            fontWeight: "bold",
        },
        libraryOrg: {
            fontSize: "110%",
            display: "flex",
            alignItems: "center",
        },
        libraryLink: {
            marginLeft: theme.spacing(1),
            padding: theme.spacing(0.5),
            color: theme.palette.grey.A400,
            textDecoration: "none",
        },
    })
);

const LibrariesAdminItem: React.FC<LibrariesAdminItemProps> = (props: LibrariesAdminItemProps) => {
    //== Init =================================================================
    const classes = useStyles();
    const [t] = useTranslation("libraries");
    const [infoOpen, setInfoOpen] = React.useState(false);
    //== Render ===============================================================
    return (
        <Box className={classes.libraryRow}>
            <LibraryDialog library={props.library} open={infoOpen} handleClose={() => setInfoOpen(false)} />
            <Box flexGrow="1">
                <Box className={classes.libraryTitle}>{props.library.name}</Box>
                <Box className={classes.libraryOrg}>
                    <BusinessIcon fontSize="small" />
                    <Link
                        className={classes.libraryLink}
                        to={ROUTES.organization.create(props.library.organization.publicId)}
                    >
                        {props.library.organization.name}
                    </Link>
                </Box>
            </Box>
            <Box>
                <InfoActionButton onClick={() => setInfoOpen(true)} />
                <VisitLinkActionButton to={ROUTES.library.create(props.library.id)} />
                <EditorLinkActionButton to={ROUTES.libraryEditor.create(props.library.id)} />
                <EditLinkActionButton to={ROUTES.libraryEdit.create(props.library.id)} />
                <LinkActionButton
                    title={t("library.actions.manage")}
                    icon={<SettingsIcon />}
                    to={ROUTES.libraryAdmin.create(props.library.id)}
                />
            </Box>
        </Box>
    );
};

interface LibrariesAdminState {
    libraries: LibrarySimpleDTO[];
    name: "loading" | "loaded" | "failed" | "not_found" | "unauthorized";
}

export const LibrariesAdmin: React.FC = () => {
    //== Init =================================================================
    const [t] = useTranslation("libraries");
    const {keycloak, initialized} = useKeycloak();
    const initState: LibrariesAdminState = {
        libraries: [],
        name: "loading",
    };
    const [state, setState] = React.useState(initState);
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<LibrarySimpleDTO[]>(`/libraries/all`, createApiConfig(keycloak, initialized))
                .then((res) => {
                    console.log(res.data);
                    setState({...state, libraries: res.data, name: "loaded"});
                })
                .catch((err: AxiosError) => {
                    if (err?.response?.status === 403 || err?.response?.status === 401) {
                        setState({...state, name: "unauthorized"});
                    } else {
                        setState({...state, name: "failed"});
                    }
                });
        }
    }, [state, setState, keycloak, initialized]);
    //== Render ===============================================================
    if (state.name === "loading") {
        return <LoadingBackdrop />;
    }
    if (state.name === "unauthorized") {
        return <NotAuthorized />;
    }
    if (state.name === "failed") {
        return <CommunicationError />;
    }
    return (
        <Container>
            <BreadcrumbsRow>
                <BreadcrumbItem name={t("library.actions.admin")} />
                <BreadcrumbItem name={t("library.titles.libraries")} />
            </BreadcrumbsRow>
            <PageHeader title={t("library.titles.manage_libraries")}>
                <PrimaryPageAction title={t("library.titles.new_library")} to={ROUTES.libraryNewAdmin.path}>
                    <AddIcon />
                </PrimaryPageAction>
            </PageHeader>
            {state.libraries.length > 0 && (
                <LibrariesList libraries={state.libraries}>
                    {(lib) => <LibrariesAdminItem key={lib.id} library={lib} />}
                </LibrariesList>
            )}
            {state.libraries.length === 0 && <LibrariesEmpty />}
        </Container>
    );
};
