import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import {MembershipPermissionLevel, ProfileMembershipPermissionLevel} from "../../../../models/members";
import {SvgIcon, SvgIconProps, Tooltip} from "@material-ui/core";

interface PermissionIconProps {
    level: MembershipPermissionLevel | ProfileMembershipPermissionLevel;
    itemName: string;
    children: ReactElement<SvgIconProps, typeof SvgIcon>;
}

const PermissionIcon: React.FC<PermissionIconProps> = (props: PermissionIconProps) => {
    //== Init ===================================================================
    const [t] = useTranslation("organizationMembers");
    const title: string = props.itemName + " - " + t("permissionLevels." + props.level.toLowerCase());
    return (
        <Tooltip title={title}>
            {React.cloneElement(props.children, {
                style: {
                    fill: props.level === "NONE" ? "#ff0000" : props.level === "READONLY" ? "#ed7d31" : "#70ad47",
                },
            })}
        </Tooltip>
    );
};
export default PermissionIcon;
