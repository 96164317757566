import React from "react";
import {Tooltip} from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ownedIcon: {
            marginLeft: theme.spacing(1),
            fontSize: "80%",
        },
    })
);

export const HomeIndicator: React.FC = () => {
    const classes = useStyles();
    const [t] = useTranslation("libraries");
    const title: string = t("library.owned_part");
    return (
        <Tooltip title={title}>
            <HomeIcon color="primary" fontSize="small" className={classes.ownedIcon} />
        </Tooltip>
    );
};
