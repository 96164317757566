import React from "react";
import {IconButton, Tooltip} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import {InputValueSimpleDTO, InputVariableTaskDTO} from "../../models/values";
import {API, createApiConfig} from "../../utils";
import {useKeycloak} from "@react-keycloak/web";
import {AxiosError, AxiosResponse} from "axios";
import {InvalidateConfirmDialog} from "./dialogs/InvalidateConfirmDialog";
import {useTranslation} from "react-i18next";

interface InputValueInvalidatorProps {
    task: InputVariableTaskDTO;
    valueFn: (v: InputValueSimpleDTO) => string;
    value: InputValueSimpleDTO;
    onInvalidation: (valueId: string) => void;
}

interface InputValueInvalidatorState {
    name: "init" | "confirm" | "invalidating" | "failed";
}

export const InputValueInvalidator: React.FC<InputValueInvalidatorProps> = (props: InputValueInvalidatorProps) => {
    //== Init =================================================================
    const initState: InputValueInvalidatorState = {
        name: "init",
    };
    const [state, setState] = React.useState(initState);
    const {keycloak, initialized} = useKeycloak();
    const [t] = useTranslation("values");
    const actionTitle: string = t("values_input.invalidate.action");
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "invalidating") {
            API.delete(`/values-input/${props.value.id}`, createApiConfig(keycloak, initialized))
                .then((r: AxiosResponse<null>) => {
                    setState({...state, name: "init"});
                    props.onInvalidation(props.value.id);
                })
                .catch((e: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [state, setState, props.value.id, props.onInvalidation]);
    //== Handlers =============================================================
    const handleInvalidateClick = () => {
        setState({...state, name: "confirm"});
    };
    const handleConfirm = () => {
        setState({...state, name: "invalidating"});
    };
    const handleCancel = () => {
        setState({...state, name: "init"});
    };
    //== Render ===============================================================
    return (
        <>
            <Tooltip title={actionTitle}>
                <div>
                    <IconButton
                        size="small"
                        onClick={handleInvalidateClick}
                        disabled={state.name !== "init" && props.value.state === "valid"}
                    >
                        <CancelIcon />
                    </IconButton>
                </div>
            </Tooltip>
            {state.name === "confirm" && (
                <InvalidateConfirmDialog
                    open
                    valueFn={props.valueFn}
                    value={props.value}
                    task={props.task}
                    onAgree={handleConfirm}
                    onDisagree={handleCancel}
                />
            )}
        </>
    );
};
