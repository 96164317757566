import React from "react";
import {useTranslation} from "react-i18next";
import ROUTES from "../../routes/routes";
// Material-UI imports
import {Container} from "@material-ui/core";
import {BreadcrumbItem, BreadcrumbsRow} from "../common/breadcrumbs";
import {PageHeader} from "../common/headers";
import {LibraryFormCreate} from "./LibraryFormCreate";

export const LibraryNewAdmin: React.FC = () => {
    const [t] = useTranslation("libraries");
    return (
        <Container>
            <BreadcrumbsRow>
                <BreadcrumbItem name={t("library.actions.admin")} />
                <BreadcrumbItem name={t("library.titles.libraries")} route={ROUTES.librariesAdmin.path} />
                <BreadcrumbItem name={t("library.titles.new_library")} />
            </BreadcrumbsRow>
            <PageHeader title={t("library.titles.new_library")} />
            <LibraryFormCreate />
        </Container>
    );
};
