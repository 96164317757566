import React from "react";
import {OptionDTO} from "../../../../models/library";
import {useTranslation} from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import {Button, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import VariableOptionsTable from "../../../definitions/Options";

interface OptionsDialogProps {
    open: boolean;
    variableUUID: string;
    options: OptionDTO[];
    onChange: (options: OptionDTO[]) => void;
    onClose: () => void;
}

export const OptionsDialog: React.FC<OptionsDialogProps> = (props: OptionsDialogProps) => {
    //== Init =================================================================
    const [t] = useTranslation("libraries");
    //== Render ===============================================================
    return (
        <Dialog onClose={props.onClose} open={props.open} fullWidth maxWidth="md">
            <DialogTitle>{t("indicator.actions.optionsEdit")}</DialogTitle>
            <DialogContent>
                <VariableOptionsTable
                    variableUUID={props.variableUUID}
                    options={props.options}
                    onChange={props.onChange}
                />
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={props.onClose}>
                    {t("indicator.actions.close")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
