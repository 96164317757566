import React from "react";
import {useKeycloak} from "@react-keycloak/web";
import {useParams} from "react-router-dom";
import {CommunicationError, NotFound} from "../common/errors";
import {LibraryDTO} from "../../models/library";
import {API, createApiConfig} from "../../utils/API";
import {LoadingBackdrop} from "../common/Loading";
import {Container} from "@material-ui/core";
import {PageHeader, SecondaryPageAction} from "../common/headers";
import {AxiosError} from "axios";
import {DomainLevel} from "./editor/DomainLevel";
import ROUTES from "../../routes/routes";
import {useTranslation} from "react-i18next";
import ListIcon from "@material-ui/icons/List";
import {LibraryUseDialog} from "./selector/LibraryUseDialog";
import EditIcon from "@material-ui/icons/Edit";
import {BreadcrumbItem, BreadcrumbsRow} from "../common/breadcrumbs";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import {Alert} from "@material-ui/lab";

interface LibraryEditorState {
    library: LibraryDTO | null;
    rev: number;
    action: string;
    name: "init" | "loading" | "loaded" | "not_found" | "failed" | "adding";
}

interface LibraryParams {
    libraryId: string;
}

export const LibraryEditor: React.FC = () => {
    // TODO: notifications on update
    //== Init =================================================================
    const {libraryId} = useParams<LibraryParams>();
    const {keycloak, initialized} = useKeycloak();
    const [t] = useTranslation("libraries");
    const initState: LibraryEditorState = {
        library: null,
        action: "init",
        name: "loading",
        rev: 0,
    };
    const [state, setState] = React.useState(initState);
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<LibraryDTO>(`/libraries/${libraryId}`, createApiConfig(keycloak, initialized))
                .then((res) => {
                    console.log(res.data);
                    setState({...state, library: res.data, name: "loaded"});
                })
                .catch((err: AxiosError) => {
                    if (err?.response?.status === 404) {
                        setState({...state, name: "not_found"});
                    } else {
                        setState({...state, name: "failed"});
                    }
                });
        }
    }, [state, setState, libraryId, keycloak, initialized]);
    //== Handlers =============================================================
    const handleReload = (): void => {
        setState({...state, name: "loading"});
    };
    const handleUpdatedLibrary = (library: LibraryDTO): void => {
        console.log(library);
        setState({...state, library: library, name: "loaded", rev: state.rev + 1});
    };
    const handleAddingStart = (): void => {
        setState({...state, name: "adding"});
    };
    const handleAddingStop = (): void => {
        setState({...state, name: "loaded"});
    };
    //== Render ===============================================================
    if (state.name === "loading") {
        return <LoadingBackdrop />;
    }
    if (state.name === "not_found") {
        return <NotFound />;
    }
    if (state.name === "failed" || state.library === null) {
        return <CommunicationError />;
    }
    const libraryPublished = state.library.public || state.library.shared;
    return (
        <Container maxWidth="lg">
            <BreadcrumbsRow>
                <BreadcrumbItem name={t("library.titles.libraries")} route={ROUTES.libraries.path} />
                <BreadcrumbItem name={state.library.name} route={ROUTES.library.create(state.library.id)} />
                <BreadcrumbItem name={t("library.titles.editor")} />
            </BreadcrumbsRow>
            <PageHeader title={state.library.name}>
                <SecondaryPageAction title={t("library.actions.to_list")} to={ROUTES.libraries.path}>
                    <ListIcon />
                </SecondaryPageAction>
                <SecondaryPageAction title={t("library.actions.edit")} to={ROUTES.libraryEdit.create(state.library.id)}>
                    <EditIcon />
                </SecondaryPageAction>
                <SecondaryPageAction
                    title={t("library.actions.adding")}
                    onClick={handleAddingStart}
                    disabled={libraryPublished}
                >
                    <LibraryAddIcon />
                </SecondaryPageAction>
            </PageHeader>
            {libraryPublished && <Alert severity="info">{t("library.published_info")}</Alert>}
            <DomainLevel
                key={`${state.library.id}:${state.library.updatedAt}:${state.rev}`}
                library={state.library}
                onReload={handleReload}
                onUpdate={handleUpdatedLibrary}
            />
            <LibraryUseDialog
                id={`library-adding-${state.library.id}`}
                targetLibrary={state.library}
                open={state.name === "adding"}
                onClose={handleAddingStop}
                onUpdate={handleReload}
            />
        </Container>
    );
};

export default LibraryEditor;
