import React, {Suspense} from "react";
import Keycloak from "keycloak-js";
import {ReactKeycloakProvider} from "@react-keycloak/web";

import AppRouter from "./routes";

import "./App.scss";
import {AuthClientError, AuthClientEvent, AuthClientTokens} from "@react-keycloak/core";

const keycloak = Keycloak("/keycloak.json");

const keycloakProviderInitOptions: Keycloak.KeycloakInitOptions = {
    checkLoginIframe: true,
};

const App: React.FC = () => {
    const onKeycloakEvent = (event: AuthClientEvent, error?: AuthClientError): void => {
        console.log("onKeycloakEvent", event, error);
    };

    const onKeycloakTokens = (tokens: AuthClientTokens): void => {
        console.log("onKeycloakTokens", tokens);
    };

    return (
        <ReactKeycloakProvider
            authClient={keycloak}
            initOptions={keycloakProviderInitOptions}
            onEvent={onKeycloakEvent}
            onTokens={onKeycloakTokens}
        >
            <Suspense fallback="loading">
                <AppRouter />
            </Suspense>
        </ReactKeycloakProvider>
    );
};

export default App;
