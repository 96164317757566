import React from "react";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
// Material-UI imports
import {
    Box,
    Checkbox,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TableRow,
    TextField,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
// Project imports
import {
    CopyButton,
    CreateActionButton,
    DeleteActionButton,
    EditActionButton,
    IdCopyButton,
    RefreshActionButton,
    SaveActionButton,
} from "../../common/buttons";
import {ErrorNotification, InfoNotification, SuccessNotification} from "../../common/notifications";
import {Loading, LoadingRow} from "../../common/Loading";
import {ServerCommunicationAlert} from "../../common/errors";
import {EmailDTO} from "../../../models/user";
import Utils from "../../../utils";
import API, {createApiConfig} from "../../../utils/API";
import {AxiosError} from "axios";
import {emptyPage, Page} from "../../../models/pagination";
import {DeleteConfirmationDialog} from "../../common/dialogs";
import SearchIcon from "@material-ui/icons/Search";

interface EmailRowProps {
    email: EmailDTO;
}

interface EmailNewRowProps {
    onSave: (email: EmailDTO) => void;
}

const EmailRow: React.FC<EmailRowProps> = (props: EmailRowProps) => {
    //== Init ===================================================================
    const [t] = useTranslation("user");
    const {keycloak, initialized} = useKeycloak();
    const [state, setState] = React.useState({
        email: props.email,
        formEmail: props.email.email,
        formVerified: props.email.verified,
        formPersonUuid: Utils.nullToEmpty(props.email.personUuid),
        name: "init",
        action: "init",
    });
    //== Effects ================================================================
    React.useEffect(() => {
        if (state.name === "deleting") {
            API.delete<void>(`/emails/${state.email.id}`, createApiConfig(keycloak, initialized))
                .then(() => {
                    setState({...state, name: "deleted", action: "delete"});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed", action: "delete"});
                });
        } else if (state.name === "saving") {
            const email: EmailDTO = {
                id: state.email.id,
                email: state.formEmail,
                verified: state.formVerified,
                personUuid: Utils.emptyToNull(state.formPersonUuid),
            };
            API.put<EmailDTO>(`/emails/${state.email.id}`, email, createApiConfig(keycloak, initialized))
                .then((res) => {
                    setState({...state, email: res.data, name: "saved", action: "edit"});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed", action: "edit"});
                });
        }
    }, [keycloak, initialized, state, setState]);
    //== Handlers ===============================================================
    const handleCancelDelete = (): void => {
        setState({...state, name: "init"});
    };
    const handleConfirmDelete = (): void => {
        setState({...state, name: "deleting"});
    };
    const handleDelete = (): void => {
        setState({...state, name: state.email.verified ? "confirmingDelete" : "deleting"});
    };
    const handleEdit = (): void => {
        setState({...state, name: "editing"});
    };
    const handleSave = (): void => {
        if (
            state.formEmail === state.email.email &&
            state.formVerified === state.email.verified &&
            state.formPersonUuid === state.email.personUuid
        ) {
            setState({...state, name: "saved"});
        } else {
            setState({...state, name: "saving"});
        }
    };
    //== Render =================================================================
    if (state.name === "deleted") {
        return <SuccessNotification message={t(`emails.notifications.delete_ok`)} />;
    }
    if (state.name === "saving" || state.name === "deleting") {
        return <LoadingRow key={Utils.nullToEmpty(state.email.id)} />;
    }
    if (state.name === "editing") {
        return (
            <TableRow>
                <TableCell align="center">
                    <TextField
                        id="email"
                        label={t("emails.email")}
                        value={state.formEmail}
                        fullWidth
                        required
                        onChange={(e): void => setState({...state, formEmail: e.target.value})}
                    />
                </TableCell>
                <TableCell align="center">
                    <Checkbox
                        id="verified"
                        title={t("emails.verified")}
                        checked={state.formVerified}
                        onChange={(e): void => setState({...state, formVerified: e.target.checked})}
                    />
                </TableCell>
                <TableCell align="center">
                    <TextField
                        id="personUuid"
                        label={t("emails.personUuid")}
                        value={state.formPersonUuid}
                        fullWidth
                        onChange={(e): void => setState({...state, formPersonUuid: e.target.value})}
                    />
                </TableCell>
                <TableCell align="right">
                    <IdCopyButton text={Utils.nullToEmpty(state.email.id)} size="small" />
                    <SaveActionButton onClick={handleSave} size="small" />
                    <DeleteActionButton onClick={handleDelete} size="small" />
                </TableCell>
            </TableRow>
        );
    }
    // - default
    let notification: JSX.Element | null = null;
    if (state.name === "failed") {
        const a = state.action;
        notification = <ErrorNotification message={t(`emails.notifications.${a}_fail`)} />;
    }
    if (state.name === "saved") {
        notification = <SuccessNotification message={t(`emails.notifications.edit_ok`)} />;
    }
    return (
        <TableRow>
            <TableCell align="center">{state.email.email}</TableCell>
            <TableCell align="center">
                <Checkbox disabled checked={state.email.verified} />
            </TableCell>
            <TableCell align="center">
                {state.email.personUuid}
                {Utils.notEmpty(state.email.personUuid) && (
                    <CopyButton text={Utils.nullToEmpty(state.email.personUuid)} size="small" />
                )}
            </TableCell>
            <TableCell align="right">
                <IdCopyButton text={Utils.nullToEmpty(state.email.id)} size="small" />
                <EditActionButton onClick={handleEdit} size="small" />
                <DeleteActionButton onClick={handleDelete} size="small" />
                <DeleteConfirmationDialog
                    title={t("ui.emails.deleteVerifiedConfirmDialog.title")}
                    text={t("ui.emails.deleteVerifiedConfirmDialog.text", {email: state.email})}
                    open={state.name === "confirmingDelete"}
                    id={"delete-dialog-" + props.email.id}
                    onDelete={handleConfirmDelete}
                    onCancel={handleCancelDelete}
                />
                {notification}
            </TableCell>
        </TableRow>
    );
};

const EmailNewRow: React.FC<EmailNewRowProps> = (props: EmailNewRowProps) => {
    //== Init ===================================================================
    const [t] = useTranslation("user");
    const {keycloak, initialized} = useKeycloak();
    const initState = {
        formEmail: "",
        formVerified: false,
        formPersonUuid: "",
        name: "init",
        onSave: props.onSave,
    };
    const [state, setState] = React.useState(initState);
    //== Effects ================================================================
    React.useEffect(() => {
        if (state.name === "saving") {
            const email: EmailDTO = {
                id: null,
                email: state.formEmail,
                verified: state.formVerified,
                personUuid: state.formPersonUuid,
            };
            API.post<EmailDTO>(`/emails`, email, createApiConfig(keycloak, initialized))
                .then((res) => {
                    state.onSave(res.data);
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [keycloak, initialized, state, setState, initState]);
    //== Handlers ===============================================================
    const handleCreate = (): void => {
        setState({...state, name: "saving"});
    };
    //== Render =================================================================
    if (state.name === "saving") {
        return <LoadingRow />;
    }
    let notification: JSX.Element | null = null;
    if (state.name === "failed") {
        notification = <ErrorNotification message={t("emails.notifications.create_fail")} />;
    }
    // - default
    return (
        <TableRow>
            <TableCell align="center">
                <TextField
                    id="newEmail"
                    label={t("emails.email")}
                    value={state.formEmail}
                    fullWidth
                    required
                    onChange={(e): void => setState({...state, formEmail: e.target.value})}
                />
            </TableCell>
            <TableCell align="center">
                <Checkbox
                    id="newVerified"
                    checked={state.formVerified}
                    onChange={(e): void => setState({...state, formVerified: e.target.checked})}
                />
            </TableCell>
            <TableCell align="center">
                <TextField
                    id="newPersonUuid"
                    label={t("emails.personUuid")}
                    value={state.formPersonUuid}
                    fullWidth
                    onChange={(e): void => setState({...state, formPersonUuid: e.target.value})}
                />
            </TableCell>
            <TableCell align="right">
                <CreateActionButton onClick={handleCreate} />
                {notification}
            </TableCell>
        </TableRow>
    );
};

interface EmailsState {
    page: Page<EmailDTO>;
    query: string;
    name: "loading" | "loaded" | "failed";
    action: "init" | "refresh" | "new";
}

const Emails: React.FC = () => {
    //== Init ===================================================================
    const [t] = useTranslation("user");
    const {keycloak, initialized} = useKeycloak();
    const initState: EmailsState = {
        page: emptyPage<EmailDTO>([]),
        query: "",
        name: "loading",
        action: "init",
    };
    const [state, setState] = React.useState(initState);
    //== Effects ================================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            let url = `/emails?page=${state.page.number}&size=${state.page.size}&sort=email,asc`;
            if (state.query !== "") {
                url += `&query=${state.query}`;
            }
            API.get<Page<EmailDTO>>(url, createApiConfig(keycloak, initialized))
                .then((res) => {
                    setState({...state, page: res.data, name: "loaded"});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [keycloak, initialized, state, setState]);
    //== Handlers ===============================================================
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number): void => {
        setState({
            ...state,
            page: {...state.page, number: value - 1},
            name: "loading",
            action: "init",
        });
    };
    const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setState({...state, query: event.target.value});
    };
    const handleSearchSubmit = (): void => {
        setState({...state, name: "loading"});
    };
    //== Render =================================================================
    if (state.name === "loading") {
        return <Loading />;
    }
    if (state.name === "failed") {
        return <ServerCommunicationAlert />;
    }
    // - default
    let notification: JSX.Element | null = null;
    if (state.name === "loaded" && state.action === "refresh") {
        notification = <InfoNotification message={t("emails.notifications.refresh_ok")} />;
    }
    if (state.name === "loaded" && state.action === "new") {
        notification = <SuccessNotification message={t("emails.notifications.create_ok")} />;
    }
    const rows = state.page.content.map((email: EmailDTO) => (
        <EmailRow key={Utils.nullToEmpty(email.id)} email={email} />
    ));
    return (
        <Box>
            <Box display="flex" justifyContent="center">
                <Box flexGrow={1}>
                    {state.page.totalPages > 1 && (
                        <Pagination
                            style={{marginTop: "1em"}}
                            count={state.page.totalPages}
                            page={state.page.number + 1}
                            onChange={handlePageChange}
                        />
                    )}
                </Box>
                <form onSubmit={handleSearchSubmit} style={{width: "auto"}}>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <TextField
                            value={state.query}
                            onChange={handleQueryChange}
                            label={t("emails.query")}
                            margin="dense"
                            variant="outlined"
                            style={{minWidth: "20em", marginRight: "1em"}}
                        />
                        <IconButton onClick={handleSearchSubmit} size="small">
                            <SearchIcon />
                        </IconButton>
                    </Box>
                </form>
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">{t("emails.email")}</TableCell>
                        <TableCell align="center">{t("emails.verified")}</TableCell>
                        <TableCell align="center">{t("emails.personUuid")}</TableCell>
                        <TableCell align="right">
                            <RefreshActionButton
                                onClick={(): void =>
                                    setState({
                                        ...initState,
                                        action: "refresh",
                                    })
                                }
                            />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{rows}</TableBody>
                <TableFooter>
                    <EmailNewRow onSave={(): void => setState({...initState, action: "new"})} />
                </TableFooter>
            </Table>
            {state.page.totalPages > 1 && (
                <Pagination
                    style={{marginTop: "1em"}}
                    count={state.page.totalPages}
                    page={state.page.number + 1}
                    onChange={handlePageChange}
                />
            )}
            {notification}
        </Box>
    );
};

export default Emails;
