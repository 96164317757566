import React, {PropsWithChildren} from "react";
import * as Router from "react-router-dom";
import {Breadcrumbs, IconButton, Link, Typography} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import HomeIcon from "@material-ui/icons/Home";

interface BreadcrumbItemProps {
    name: string;
    route?: string;
    onClick?: () => void;
}

export const BreadcrumbItem: React.FC<BreadcrumbItemProps> = (props: BreadcrumbItemProps) => {
    if (props.route !== undefined) {
        return (
            <Link color="inherit" component={Router.Link} to={props.route}>
                {props.name}
            </Link>
        );
    }
    if (props.onClick !== undefined) {
        return (
            <Link color="inherit" href="#" onClick={props.onClick}>
                {props.name}
            </Link>
        );
    }
    return (
        <Typography key={props.name} color="inherit">
            {props.name}
        </Typography>
    );
};

interface BreadcrumbsRowProps {
    children: React.ReactNode;
}

export const BreadcrumbsRow: React.FC<BreadcrumbsRowProps> = (props: PropsWithChildren<BreadcrumbsRowProps>) => {
    return (
        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
            <IconButton color="inherit" disabled>
                <HomeIcon /> {/* TODO: SCC ICON */}
            </IconButton>
            {props.children}
        </Breadcrumbs>
    );
};
