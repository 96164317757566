import React from "react";
import {useTranslation} from "react-i18next";
// Material UI imports
import {Box, Tab} from "@material-ui/core";
// Local imports
import {CustomTabs, TabContent} from "../common/tabs";
import {DataSourcesTable} from "./datasources";
import {InputVariablesTable} from "./inputvariables";
import {UnitsTable} from "./units";

interface DefinitionsProps {
    tab?: "input_variables" | "data_sources" | "units";
}

const tabToValue = (str?: string): number => {
    if (str === "data_sources") return 1;
    if (str === "units") return 2;
    return 0;
};

export const Definitions: React.FC<DefinitionsProps> = (props: DefinitionsProps) => {
    //== Init ===================================================================
    const [t] = useTranslation("libraries");
    const [value, setValue] = React.useState(tabToValue(props.tab));
    //== Handlers ===============================================================
    //eslint-disable-next-line @typescript-eslint/ban-types
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number): void => {
        setValue(newValue);
    };
    //== Render =================================================================
    return (
        <Box>
            <CustomTabs
                style={{marginBottom: "2em"}}
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
                <Tab label={t("definitions.input_variables.title")} />
                <Tab label={t("definitions.data_sources.title")} />
                <Tab label={t("definitions.units.title")} />
            </CustomTabs>
            <TabContent index={0} value={value}>
                <InputVariablesTable />
            </TabContent>
            <TabContent index={1} value={value}>
                <DataSourcesTable />
            </TabContent>
            <TabContent index={2} value={value}>
                <UnitsTable />
            </TabContent>
        </Box>
    );
};

export const DefinitionsDataSources: React.FC = () => {
    return <Definitions tab="data_sources" />;
};

export const DefinitionsInputVariables: React.FC = () => {
    return <Definitions tab="input_variables" />;
};

export const DefinitionsUnits: React.FC = () => {
    return <Definitions tab="units" />;
};

export default Definitions;
