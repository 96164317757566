import React from "react";
import {Box} from "@material-ui/core";
import {MoveHereActionButton} from "../../../common/buttons";

export interface LibraryItemMoveTargetProps {
    active: boolean;
    className?: string;

    onAction(): void;
}

const LibraryItemMoveTarget: React.FC<LibraryItemMoveTargetProps> = (props: LibraryItemMoveTargetProps) => {
    const handleAction = () => {
        if (!props.active) return;
        props.onAction();
    };

    if (!props.active) return null;
    return (
        <Box className={props.className}>
            <div style={{display: "inline-block", visibility: "hidden"}}>
                <MoveHereActionButton onClick={handleAction} />
            </div>
            <MoveHereActionButton onClick={handleAction} />
        </Box>
    );
};

export default LibraryItemMoveTarget;
