import React from "react";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
// Material UI imports
import {Box, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, TextField} from "@material-ui/core";
// Project imports
import {
    CreateActionButton,
    DeleteActionButton,
    EditActionButton,
    IdCopyButton,
    RefreshActionButton,
    SaveActionButton,
} from "../../common/buttons";
import {DataSourceInput} from "../../common/libraries/dataSources";
import {ServerCommunicationAlert} from "../../common/errors";
import {Loading, LoadingRow} from "../../common/Loading";
import {ErrorNotification, InfoNotification, SuccessNotification} from "../../common/notifications";
import {DataAcquisitionCreateDTO, DataAcquisitionDTO} from "../../../models/library";
import {API, createApiConfig} from "../../../utils";
import {AxiosError} from "axios";
import {DeleteConfirmationDialog} from "../../common/dialogs";

interface DataAcquisitionRowProps {
    dataAcquisition: DataAcquisitionDTO;
}

interface DataAcquisitionNewRowProps {
    inputVariableUUID: string;
    onSave: (dataAcquisition: DataAcquisitionDTO) => void;
}

const DataAcquisitionRow: React.FC<DataAcquisitionRowProps> = (props: DataAcquisitionRowProps) => {
    //== Init ===================================================================
    const [t] = useTranslation("libraries");
    const {keycloak, initialized} = useKeycloak();
    const [state, setState] = React.useState({
        dataAcquisition: props.dataAcquisition,
        formDescription: props.dataAcquisition.description,
        formDataSource: props.dataAcquisition.dataSource,
        name: "init",
        action: "init",
    });
    //== Effects ================================================================
    React.useEffect(() => {
        if (state.name === "deleting") {
            API.delete<void>(`/data-acquisitions/${state.dataAcquisition.id}`, createApiConfig(keycloak, initialized))
                .then(() => {
                    setState({...state, name: "deleted", action: "delete"});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed", action: "delete"});
                });
        } else if (state.name === "saving") {
            const dataAcquisition: DataAcquisitionCreateDTO = {
                description: state.formDescription,
                dataSourceUUID: state.formDataSource.id,
                inputVariableUUID: props.dataAcquisition.inputVariableUUID,
            };
            API.put<DataAcquisitionDTO>(
                `/data-acquisitions/${state.dataAcquisition.id}`,
                dataAcquisition,
                createApiConfig(keycloak, initialized)
            )
                .then((res) => {
                    setState({...state, dataAcquisition: res.data, name: "saved", action: "edit"});
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed", action: "edit"});
                });
        }
    }, [keycloak, initialized, state, setState, props]);
    //== Handlers ===============================================================
    const handleCancelDelete = (): void => {
        setState({...state, name: "init"});
    };
    const handleConfirmDelete = (): void => {
        setState({...state, name: "deleting"});
    };
    const handleDelete = (): void => {
        setState({...state, name: "confirmingDelete"});
    };
    const handleEdit = (): void => {
        setState({...state, name: "editing"});
    };
    const handleSave = (): void => {
        if (
            state.formDescription === state.dataAcquisition.description &&
            state.formDataSource.id === state.dataAcquisition.dataSource.id
        ) {
            setState({...state, name: "saved"});
        } else {
            setState({...state, name: "saving"});
        }
    };
    //== Render =================================================================
    if (state.name === "deleted") {
        return <SuccessNotification message={t(`definitions.data_acquisitions.notifications.delete_ok`)} />;
    }
    if (state.name === "saving" || state.name === "deleting") {
        return <LoadingRow />;
    }
    if (state.name === "editing") {
        return (
            <TableRow>
                <TableCell align="center">
                    <IdCopyButton text={state.dataAcquisition.id} size="small" />
                </TableCell>
                <TableCell align="center">
                    <DataSourceInput
                        label={t("definitions.data_acquisitions.data_source")}
                        value={state.formDataSource}
                        onChange={(value): void => setState({...state, formDataSource: value})}
                        fullWidth
                        variant="outlined"
                    />
                </TableCell>
                <TableCell align="center">
                    <TextField
                        label={t("definitions.data_acquisitions.description")}
                        value={state.formDescription}
                        fullWidth
                        multiline
                        onChange={(e): void => setState({...state, formDescription: e.target.value})}
                    />
                </TableCell>
                <TableCell align="right">
                    <SaveActionButton onClick={handleSave} />
                    <DeleteActionButton onClick={handleDelete} />
                </TableCell>
            </TableRow>
        );
    }
    // - default
    let notification: JSX.Element | null = null;
    if (state.name === "failed") {
        const a = state.action;
        notification = <ErrorNotification message={t(`definitions.data_acquisitions.notifications.${a}_fail`)} />;
    }
    if (state.name === "saved") {
        notification = <SuccessNotification message={t(`definitions.data_acquisitions.notifications.edit_ok`)} />;
    }
    return (
        <TableRow>
            <TableCell align="center">
                <IdCopyButton text={state.dataAcquisition.id} size="small" />
            </TableCell>
            <TableCell align="center">{state.dataAcquisition.dataSource.name}</TableCell>
            <TableCell align="center">{state.dataAcquisition.description}</TableCell>
            <TableCell align="right">
                <EditActionButton onClick={handleEdit} />
                <DeleteActionButton onClick={handleDelete} />
                <DeleteConfirmationDialog
                    title={t("ui.dataAcquisitions.deleteConfirmDialog.title")}
                    text={t("ui.dataAcquisitions.deleteConfirmDialog.text", {
                        name: state.dataAcquisition.dataSource.name,
                    })}
                    open={state.name === "confirmingDelete"}
                    id={"delete-dialog-" + props.dataAcquisition.id}
                    onDelete={handleConfirmDelete}
                    onCancel={handleCancelDelete}
                />
                {notification}
            </TableCell>
        </TableRow>
    );
};

const DataAcquisitionNewRow: React.FC<DataAcquisitionNewRowProps> = (props: DataAcquisitionNewRowProps) => {
    //== Init ===================================================================
    const [t] = useTranslation("libraries");
    const {keycloak, initialized} = useKeycloak();
    const initState = {
        formDescription: "",
        formDataSourceUUID: "",
        name: "init",
        onSave: props.onSave,
    };
    const [state, setState] = React.useState(initState);
    //== Effects ================================================================
    React.useEffect(() => {
        if (state.name === "saving") {
            const dataAcquisition: DataAcquisitionCreateDTO = {
                description: state.formDescription,
                dataSourceUUID: state.formDataSourceUUID,
                inputVariableUUID: props.inputVariableUUID,
            };
            API.post<DataAcquisitionDTO>(`/data-acquisitions`, dataAcquisition, createApiConfig(keycloak, initialized))
                .then((res) => {
                    state.onSave(res.data);
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [keycloak, initialized, state, setState, initState, props]);
    //== Handlers ===============================================================
    const handleCreate = (): void => {
        setState({...state, name: "saving"});
    };
    //== Render =================================================================
    if (state.name === "saving") {
        return <LoadingRow />;
    }
    let notification: JSX.Element | null = null;
    if (state.name === "failed") {
        notification = <ErrorNotification message={t("definitions.data_acquisitions.notifications.create_fail")} />;
    }
    // - default
    return (
        <TableRow key="new">
            <TableCell align="center" />
            <TableCell align="center">
                <DataSourceInput
                    label={t("definitions.data_acquisitions.data_source")}
                    valueUuid={state.formDataSourceUUID}
                    onChange={(value): void => setState({...state, formDataSourceUUID: value.id})}
                    fullWidth
                    variant="outlined"
                />
            </TableCell>
            <TableCell align="center">
                <TextField
                    label={t("definitions.data_acquisitions.description")}
                    value={state.formDescription}
                    fullWidth
                    multiline
                    onChange={(e): void => setState({...state, formDescription: e.target.value})}
                />
            </TableCell>
            <TableCell align="right">
                <CreateActionButton onClick={handleCreate} />
                {notification}
            </TableCell>
        </TableRow>
    );
};

interface DataAcquisitionsTableProps {
    inputVariableUUID: string;
}

export const DataAcquisitionsTable: React.FC<DataAcquisitionsTableProps> = (props: DataAcquisitionsTableProps) => {
    //== Init =================================================================
    const [t] = useTranslation("libraries");
    const {keycloak, initialized} = useKeycloak();
    const initState = {
        dataAcquisitions: [] as DataAcquisitionDTO[],
        name: "loading",
        action: "init",
    };
    const [state, setState] = React.useState(initState);
    //== Effects ==============================================================
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<DataAcquisitionDTO[]>(
                `/data-acquisitions/list/input-variable/${props.inputVariableUUID}`,
                createApiConfig(keycloak, initialized)
            )
                .then((res) => {
                    setState({
                        ...state,
                        dataAcquisitions: res.data ?? [],
                        name: "loaded",
                    });
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [keycloak, initialized, state, setState, props]);
    //== Render =================================================================
    if (state.name === "loading") {
        return <Loading />;
    }
    if (state.name === "failed") {
        return <ServerCommunicationAlert />;
    }
    // - default
    let notification: JSX.Element | null = null;
    if (state.name === "loaded" && state.action === "refresh") {
        notification = <InfoNotification message={t("definitions.data_acquisitions.notifications.refresh_ok")} />;
    }
    if (state.name === "loaded" && state.action === "new") {
        notification = <SuccessNotification message={t("definitions.data_acquisitions.notifications.create_ok")} />;
    }
    const rows = state.dataAcquisitions.map((dataAcquisition: DataAcquisitionDTO) => (
        <DataAcquisitionRow key={dataAcquisition.id} dataAcquisition={dataAcquisition} />
    ));
    return (
        <Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">{t("definitions.data_acquisitions.id")}</TableCell>
                        <TableCell align="center">{t("definitions.data_acquisitions.data_source")}</TableCell>
                        <TableCell align="center">{t("definitions.data_acquisitions.description")}</TableCell>
                        <TableCell align="right">
                            <RefreshActionButton
                                onClick={(): void =>
                                    setState({
                                        ...initState,
                                        action: "refresh",
                                    })
                                }
                            />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{rows}</TableBody>
                <TableFooter>
                    <DataAcquisitionNewRow
                        onSave={(): void => setState({...initState, action: "new"})}
                        inputVariableUUID={props.inputVariableUUID}
                    />
                </TableFooter>
            </Table>
            {notification}
        </Box>
    );
};
