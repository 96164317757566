import React from "react";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
// Project imports
import API, {createApiConfig, emptyState, failedState, successState} from "../../../../utils/API";
import {AxiosError} from "axios";
import Loading from "../../../common/Loading";
import {CommunicationError} from "../../../common/errors";
import {OrganizationDTO} from "../../../../models/organization";
// Material-UI imports
import {Box} from "@material-ui/core";
import Positions from "./Positions";
import {ManualSidebar} from "../../../../manual/components/ManualSidebar";
import {csMunicipalityMembersSection} from "../../../../manual/content/cs/CsMunicipalityManualChapter";
import {csOrganizationMembersSection} from "../../../../manual/content/cs/CsOrganizationManualChapter";

export interface OrganizationPositionsProps {
    organizationId: string;
}

const OrganizationPositionsIndexContent: React.FC<OrganizationPositionsProps> = (props: OrganizationPositionsProps) => {
    const {keycloak, initialized} = useKeycloak();
    const [data, setData] = React.useState(emptyState);
    React.useEffect(() => {
        API.get<OrganizationDTO>(`/organizations/${props.organizationId}`, createApiConfig(keycloak, initialized))
            .then((res) => {
                setData(successState(res.data));
            })
            .catch((err: AxiosError) => {
                setData(failedState);
            });
    }, [keycloak, props.organizationId, initialized]);

    if (data === emptyState) {
        return <Loading />;
    } else if (data === failedState) {
        return <CommunicationError />;
    } else {
        const organization: OrganizationDTO = data.data;
        return (
            <Box>
                <ManualSidebar
                    section={organization.municipality ? csMunicipalityMembersSection : csOrganizationMembersSection}
                />
                <Positions organization={organization} />
            </Box>
        );
    }
};

const OrganizationPositionsIndex: React.FC<OrganizationPositionsProps> = (props: OrganizationPositionsProps) => {
    const [t] = useTranslation("organizationMembers");
    return (
        <Box>
            <h1>{t("position.position")}</h1>
            <OrganizationPositionsIndexContent organizationId={props.organizationId} />
        </Box>
    );
};

export default OrganizationPositionsIndex;
