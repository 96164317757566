import React from "react";
import {GenericEntityDeleteContentProps} from "../../../../common/delete";
import {useTranslation} from "react-i18next";
import {extractEntitiesByType} from "../../../../../models/error";

export const LibraryIndicatorRenderDelete: React.FC<GenericEntityDeleteContentProps> = (
    props: GenericEntityDeleteContentProps
) => {
    //== Init =================================================================
    const [t] = useTranslation("libraries");
    const groups = extractEntitiesByType(props.error, "TrackedIndicatorGroup");
    const libraries = extractEntitiesByType(props.error, "Library");
    const projects = extractEntitiesByType(props.error, "Project");
    const isPublic = props.error.reasons.includes("published");
    const isShared = props.error.reasons.includes("shared");
    const tPrefix = "indicator.confirm_delete.render";
    //== Render ===============================================================
    return (
        <ul>
            {isPublic && <li>{t(`${tPrefix}.public`)}</li>}
            {isShared && <li>{t(`${tPrefix}.shared`)}</li>}
            {groups.length > 0 && <li>{t(`${tPrefix}.groups`, {count: groups.length})}</li>}
            {projects.length > 0 && <li>{t(`${tPrefix}.projects`, {count: projects.length})}</li>}
            {libraries.length > 0 && <li>{t(`${tPrefix}.libraries`, {count: libraries.length})}</li>}
        </ul>
    );
};
