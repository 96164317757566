import React from "react";
import Dialog from "@material-ui/core/Dialog";
import {Box, Button, DialogActions, DialogContent, DialogTitle, Divider} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {isConversionValid} from "../../../../models/formulas";
import {ConversionFormulaDisplay} from "./ConversionFormulaDisplay";
import {ConversionField} from "./ConversionField";

interface ConversionDialogProps {
    open: boolean;
    formula: string;
    onSubmit: (formula: string) => void;
    onClose: () => void;
}

interface ConversionDialogState {
    formula: string;
    advanced: boolean;
}

export const ConversionDialog: React.FC<ConversionDialogProps> = (props: ConversionDialogProps) => {
    //== Init =================================================================
    const initState: ConversionDialogState = {
        formula: props.formula,
        advanced: false,
    };
    const [state, setState] = React.useState(initState);
    const [t] = useTranslation("libraries");
    //== Handlers =============================================================
    const handleClose = () => {
        setState({
            ...state,
            formula: props.formula,
        });
        props.onClose();
    };
    const handleSubmit = () => {
        props.onSubmit(state.formula);
    };
    const handleAdvancedOn = () => {
        setState({...state, advanced: true});
    };
    const handleAdvancedOff = () => {
        setState({...state, advanced: false});
    };
    const handleFormulaChange = (formula: string) => {
        setState({...state, formula: formula});
    };
    //== Render ===============================================================
    const isValid = isConversionValid(state.formula);
    return (
        <Dialog onClose={handleClose} open={props.open} fullWidth maxWidth="md">
            <DialogTitle>{t("formula.dialog.title")}</DialogTitle>
            <DialogContent>
                <Box>
                    <ConversionFormulaDisplay formula={state.formula} errorInfo />
                    <Divider style={{margin: "1em"}} />
                    <ConversionField
                        formula={state.formula}
                        onChange={handleFormulaChange}
                        showField={state.advanced}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={handleClose}>
                    {t("formula.dialog.actions.close")}
                </Button>
                {state.advanced ? (
                    <Button color="secondary" onClick={handleAdvancedOff}>
                        {t("formula.dialog.actions.advancedOff")}
                    </Button>
                ) : (
                    <Button color="secondary" onClick={handleAdvancedOn}>
                        {t("formula.dialog.actions.advancedOn")}
                    </Button>
                )}
                <Button color="primary" disabled={!isValid} onClick={handleSubmit}>
                    {t("formula.dialog.actions.save")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
