import React, {ChangeEvent} from "react";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
import {Redirect, useParams} from "react-router-dom";
// Material UI imports
import {Container, Tab} from "@material-ui/core";
// Local imports
import {DataAcquisitionsTable} from "./DataAcquisitions";
import InputVariableForm from "./InputVariableForm";
// Project imports
import {CommunicationError, NotFound} from "../../common/errors";
import Loading from "../../common/Loading";
import {ErrorNotification, SuccessNotification} from "../../common/notifications";
import ROUTES from "../../../routes/routes";
import {InputVariableCreateDTO, InputVariableDTO} from "../../../models/library";
import Utils, {API, createApiConfig} from "../../../utils";
import {PeriodsTable} from "../../common/period/PeriodsTable";
import {valueToUpdate} from "../../../models/values";
import {AxiosError, AxiosResponse} from "axios";
import {PageHeader, SecondaryPageAction} from "../../common/headers";
import DeleteIcon from "@material-ui/icons/Delete";
import ListIcon from "@material-ui/icons/List";
import {CustomTabs, TabContent} from "../../common/tabs";
import EditIcon from "@material-ui/icons/Edit";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import AlarmIcon from "@material-ui/icons/Alarm";
import CollectionsBookmarkIcon from "@material-ui/icons/CollectionsBookmark";
import VariableOptionsTable from "../Options";
import {InputVariableDelete} from "./InputVariableDelete";

type InputVariableTab = "edit" | "options" | "sources" | "periods";

interface InputVariableDetailState {
    inputVariable: InputVariableDTO | null;
    inputVariableEdit: InputVariableCreateDTO;
    name: string;
    action: string;
    tab: InputVariableTab;
}

interface InputVariableParams {
    inputVariableId: string;
}

const TABS: InputVariableTab[] = ["edit", "options", "sources", "periods"];

export const InputVariableDetail: React.FC = () => {
    //== Init =================================================================
    const [t] = useTranslation("libraries");
    const {keycloak, initialized} = useKeycloak();
    const {inputVariableId} = useParams<InputVariableParams>();
    const initState: InputVariableDetailState = {
        inputVariable: null,
        inputVariableEdit: {
            name: "",
            type: "qualitative",
            description: "",
            definition: "",
            preferredUnitUUID: null,
            minimumValueUpdate: null,
            maximumValueUpdate: null,
            tags: [],
        },
        name: "loading",
        action: "none",
        tab: "edit",
    };
    const [state, setState] = React.useState(initState);
    //== Effects ==============================================================
    const createDTOFromResponse = (dto: InputVariableDTO): InputVariableCreateDTO => {
        return {
            name: dto.name,
            type: dto.type,
            description: dto.description ?? "",
            definition: dto.definition ?? "",
            preferredUnitUUID: dto.preferredUnit?.id ?? null,
            minimumValueUpdate: valueToUpdate(dto.minimumValue),
            maximumValueUpdate: valueToUpdate(dto.maximumValue),
            tags: dto.tags,
        };
    };
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<InputVariableDTO>(`/input-variables/${inputVariableId}`, createApiConfig(keycloak, initialized))
                .then((res: AxiosResponse<InputVariableDTO>) => {
                    setState({
                        ...state,
                        inputVariable: res.data,
                        inputVariableEdit: createDTOFromResponse(res.data),
                        name: "loaded",
                    });
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
        if (state.name === "saving") {
            API.put<InputVariableDTO>(
                `/input-variables/${inputVariableId}`,
                state.inputVariableEdit,
                createApiConfig(keycloak, initialized)
            )
                .then((res) => {
                    setState({
                        ...state,
                        inputVariable: res.data,
                        inputVariableEdit: createDTOFromResponse(res.data),
                        name: "saved",
                    });
                })
                .catch((err: AxiosError) => {
                    setState({...state, name: "failed"});
                });
        }
    }, [keycloak, initialized, state.name, inputVariableId]);
    //== Handlers =============================================================
    const act: InputVariableCreateDTO = {
        name: state.inputVariable?.name ?? "",
        type: state.inputVariable?.type ?? "qualitative",
        description: state.inputVariable?.description ?? "",
        definition: state.inputVariable?.definition ?? "",
        preferredUnitUUID: state.inputVariable?.preferredUnit?.id ?? null,
        minimumValueUpdate: valueToUpdate(state.inputVariable?.minimumValue),
        maximumValueUpdate: valueToUpdate(state.inputVariable?.maximumValue),
        tags: state.inputVariable?.tags || [],
    };
    const isNotChanged =
        Utils.objEq(act, state.inputVariableEdit, [
            "name",
            "type",
            "description",
            "definition",
            "preferredUnitUUID",
            "minimumValueUpdate",
            "maximumValueUpdate",
        ]) && Utils.entityArraysEq(act.tags, state.inputVariableEdit.tags);
    const handleChange = (inputVariable: InputVariableCreateDTO): void => {
        setState({...state, inputVariableEdit: inputVariable});
    };
    const handleSubmit = (): void => {
        if (!isNotChanged) {
            setState({...state, name: "saving", action: "edit"});
        }
    };
    const handleDelete = (): void => {
        setState({...state, name: "deleting", action: "delete"});
    };
    //eslint-disable-next-line @typescript-eslint/ban-types
    const handleTabChange = (event: ChangeEvent<{}>, newValue: number): void => {
        setState({...state, tab: TABS[newValue]});
    };
    const handleDeleteDone = (): void => {
        setState({...state, name: "deleted", action: "none"});
    };
    const handleDeleteCancel = (): void => {
        setState({...state, name: "loaded", action: "none"});
    };
    const handleDeleteFailed = (): void => {
        setState({...state, name: "failed"});
    };
    //== Render ===============================================================
    let appendix: JSX.Element | null = null;
    if (state.name === "loading") {
        return <Loading />;
    }
    if (state.name === "failed") {
        if (state.action === "none") {
            return <NotFound />;
        }
        appendix = <ErrorNotification message={t(`definitions.input_variables.notifications.${state.action}_fail`)} />;
    }
    if (state.name === "saved") {
        appendix = <SuccessNotification message={t(`definitions.input_variables.notifications.edit_ok`)} />;
    }
    if (state.name === "deleted") {
        return <Redirect to={ROUTES.inputVariables.path} />;
    }
    if (state.inputVariable !== null) {
        const tabValue: number = TABS.indexOf(state.tab);
        const inputVariable: InputVariableDTO = state.inputVariable;
        return (
            <Container maxWidth="lg">
                <PageHeader title={state.inputVariable.name}>
                    <SecondaryPageAction title={t("definitions.input_variables.actions.back")}>
                        <ListIcon />
                    </SecondaryPageAction>
                    <SecondaryPageAction title={t("definitions.input_variables.actions.delete")} onClick={handleDelete}>
                        <DeleteIcon />
                    </SecondaryPageAction>
                </PageHeader>
                <CustomTabs
                    style={{marginBottom: "2em"}}
                    value={tabValue}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    centered
                >
                    <Tab label={t("definitions.input_variables.tabs.edit")} icon={<EditIcon />} />
                    <Tab
                        label={t("definitions.input_variables.tabs.options")}
                        icon={<FormatListNumberedIcon />}
                        disabled={state.inputVariable.type !== "qualitative"}
                    />
                    <Tab label={t("definitions.input_variables.tabs.sources")} icon={<CollectionsBookmarkIcon />} />
                    <Tab label={t("definitions.input_variables.tabs.periods")} icon={<AlarmIcon />} />
                </CustomTabs>
                <TabContent index={0} value={tabValue}>
                    <Container maxWidth="md">
                        <InputVariableForm
                            inputVariable={state.inputVariableEdit}
                            qualitativeInfo={t("definitions.input_variables.qualitative.edit")}
                            onChange={handleChange}
                            onSubmit={handleSubmit}
                        />
                    </Container>
                </TabContent>
                <TabContent index={1} value={tabValue}>
                    <VariableOptionsTable variableUUID={inputVariable.variableUUID} options={inputVariable.options} />
                </TabContent>
                <TabContent index={2} value={tabValue}>
                    <DataAcquisitionsTable inputVariableUUID={inputVariable.id} />
                </TabContent>
                <TabContent index={3} value={tabValue}>
                    <PeriodsTable parentUUID={inputVariable.variableUUID} periods={inputVariable.periods} />
                </TabContent>
                {appendix}
                <InputVariableDelete
                    entity={state.name === "deleting" ? inputVariable : null}
                    onDeleted={handleDeleteDone}
                    onCancel={handleDeleteCancel}
                    onFailed={handleDeleteFailed}
                />
            </Container>
        );
    }
    return <CommunicationError />;
};

export default InputVariableDetail;
