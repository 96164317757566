import React from "react";
import {VariableSimpleDTO} from "../../../models/library";
import {extractVariables, formulaToKaTeX} from "../../../models/formulas";
import {useTranslation} from "react-i18next";
import {Alert} from "@material-ui/lab";
import TeX from "@matejmazur/react-katex";
import Utils from "../../../utils";
import {Box, IconButton} from "@material-ui/core";
import {InputVariableInfo} from "../../values/dialogs/InputVariableInfo";
import InfoIcon from "@material-ui/icons/Info";

interface FormulaDisplayProps {
    formula: string;
    variables: VariableSimpleDTO[];
    errorInfo?: boolean;
    showUnused?: boolean;
}

export const FormulaVariables: React.FC<FormulaDisplayProps> = (props: FormulaDisplayProps) => {
    //== Init =================================================================
    const variables = extractVariables(props.formula);
    const variablesOrder = Utils.uniq(variables);
    const mappingTable = new Map<string, string>(variablesOrder.map((id, index) => [id, `x_{${index + 1}}`]));
    const variablesMap = new Map<string, VariableSimpleDTO>(props.variables.map((variable) => [variable.id, variable]));
    const result = formulaToKaTeX(props.formula, {table: mappingTable});
    const [t] = useTranslation("libraries");
    const [state, setState] = React.useState<string | null>(null);
    //== Render ===============================================================
    if (result.isOk) {
        return (
            <Box>
                <ul>
                    {variablesOrder.map((id) => (
                        <li key={id}>
                            <TeX>{mappingTable.get(id)}</TeX> = {variablesMap.get(id)?.name}{" "}
                            <IconButton size="small" onClick={() => setState(id)}>
                                <InfoIcon />
                            </IconButton>
                        </li>
                    ))}
                </ul>
                <InputVariableInfo variableId={state} handleClose={() => setState(null)} />
            </Box>
        );
    } else if (result.error !== null && props.errorInfo === true) {
        const err = `formula.error.${result.error.errType}.${result.error.opType ?? "parse"}`;
        return <Alert severity="warning">{t(err, result.error.fields)}</Alert>;
    } else {
        return <Alert severity="error">{t("formula.error.generic")}</Alert>;
    }
};
