import React from "react";
import {useParams} from "react-router-dom";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
import {Loading} from "../../../common/Loading";
import {Container} from "@material-ui/core";
import {LinkActionButton} from "../../../common/buttons";
import {ROUTES} from "../../../../routes/routes";
import {API, createApiConfig} from "../../../../utils";
import BusinessIcon from "@material-ui/icons/Business";
import KeyboardIcon from "@material-ui/icons/Keyboard";
import {isUser} from "../../../../utils/auth";
import {anonymousOrganizationPermissions, OrganizationPermissionsDTO} from "../../../../models/members";
import {BreadcrumbItem, BreadcrumbsRow} from "../../../common/breadcrumbs";
import {PageHeader} from "../../../common/headers";
import {ManualSidebar} from "../../../../manual/components/ManualSidebar";
import {csPublicIndicatorsSection} from "../../../../manual/content/cs/CsPublicManualChapter";
import {csMunicipalityIndicatorBrowsingSection} from "../../../../manual/content/cs/CsMunicipalityManualChapter";
import TimelineIcon from "@material-ui/icons/Timeline";
import {IndicatorsDashboard} from "./IndicatorsDashboard";
import {SimpleMunicipalityDTO} from "../../../../models/organization";

//=============================================================================

interface MunicipalityDashboardState {
    name: "loading" | "loaded" | "not_found" | "failed";
    permissions: OrganizationPermissionsDTO;
    municipality: SimpleMunicipalityDTO | null;
}

interface MunicipalityDashboardParams {
    organizationId: string;
}

export const MunicipalityIndicatorsDashboard: React.FC = () => {
    //== Init =================================================================
    const {organizationId} = useParams<MunicipalityDashboardParams>();
    const initState: MunicipalityDashboardState = {
        name: "loading",
        permissions: anonymousOrganizationPermissions(organizationId),
        municipality: null,
    };
    const [state, setState] = React.useState(initState);
    const {keycloak, initialized} = useKeycloak();
    const [t] = useTranslation(["trackedIndicators"]);
    //== Effects ==============================================================
    const setMunicipalityAndPermissions = (
        municipality: SimpleMunicipalityDTO,
        permissions: OrganizationPermissionsDTO
    ) => {
        setState({
            ...state,
            name: "loaded",
            permissions: permissions,
            municipality: municipality,
        });
    };
    React.useEffect(() => {
        if (state.name === "loading") {
            API.get<SimpleMunicipalityDTO>(
                `/organizations/${organizationId}/simple-municipality`,
                createApiConfig(keycloak, initialized)
            )
                .then((res) => {
                    if (!res.data) return setState({...state, name: "failed"});
                    if (isUser(keycloak)) {
                        API.get<OrganizationPermissionsDTO>(
                            `/organization-permissions/${organizationId}/current-user`,
                            createApiConfig(keycloak, initialized)
                        )
                            .then((permissionsRes) => {
                                if (!permissionsRes.data) return setState({...state, name: "failed"});
                                setMunicipalityAndPermissions(res.data, permissionsRes.data);
                            })
                            .catch((err) => {
                                console.log("permissions get error:");
                                console.log(err);
                                return setState({...state, name: "failed"});
                            });
                    } else {
                        setState({
                            ...state,
                            name: "loaded",
                            permissions: anonymousOrganizationPermissions(organizationId),
                            municipality: res.data,
                        });
                    }
                })
                .catch((err) => {
                    console.log("municipality get error:");
                    console.log(err);
                    return setState({...state, name: "failed"});
                });
        }
    }, [state, setState, keycloak, initialized]);
    //== Permissions ==========================================================
    console.log("permissions:");
    console.log(state.permissions);
    const canEditDashboard = state.permissions && state.permissions.trackedIndicatorsPermissionLevel === "READWRITE";
    const canEnterValues = state.permissions && state.permissions.valuesPermissionLevel === "READWRITE";
    //== Render ===============================================================
    console.log("canEditDashboard:" + canEditDashboard);
    return (
        <Container>
            <ManualSidebar
                section={
                    state.permissions.trackedIndicatorsPermissionLevel === "NONE"
                        ? csPublicIndicatorsSection
                        : csMunicipalityIndicatorBrowsingSection
                }
            />
            <BreadcrumbsRow>
                <BreadcrumbItem
                    name={state.municipality?.name || t("organization:organization.municipality")}
                    route={ROUTES.organization.create(organizationId)}
                />
            </BreadcrumbsRow>

            <PageHeader
                title={
                    (state.municipality?.name || t("organization:organization.municipality")) +
                    " - " +
                    t("dashboard.simpleTitle")
                }
            >
                <LinkActionButton
                    to={ROUTES.organization.create(organizationId)}
                    icon={<BusinessIcon />}
                    title={t("actions.organization")}
                />
                {canEditDashboard && (
                    <LinkActionButton
                        to={ROUTES.trackedIndicatorGroups.create(organizationId)}
                        icon={<TimelineIcon />}
                        title={t("actions.trackedIndicatorsBrowser")}
                    />
                )}
                {canEnterValues && (
                    <LinkActionButton
                        to={ROUTES.valuesInput.create(organizationId)}
                        icon={<KeyboardIcon />}
                        title={t("actions.valuesInput")}
                    />
                )}
            </PageHeader>
            {state.municipality ? (
                <IndicatorsDashboard
                    permissions={state.permissions}
                    simpleMunicipality={state.municipality}
                    dashboard={null}
                />
            ) : (
                <Loading />
            )}
        </Container>
    );
};
